import {AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DispSalarySettingMode, DispSalaryTableMode} from '../../models';
import {DispSalaryService} from '../../services';

@Component({
  selector: 'utax-disp-salary-table',
  templateUrl: './disp-salary-table.component.html',
  styleUrls: ['./disp-salary-table.component.scss', '../columns.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispSalaryTableComponent implements OnInit, AfterContentChecked {

  readonly dispSalarySettingMode = DispSalarySettingMode;
  readonly dispSalaryTableMode = DispSalaryTableMode;

  constructor(public dispSalaryService: DispSalaryService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {}

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
}
