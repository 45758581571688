import { Directive } from '@angular/core';
import {UntypedFormControl, NG_VALIDATORS} from '@angular/forms';

@Directive({
  selector: '[utaxItnValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ItnValidatorDirective, multi: true }
  ]
})
export class ItnValidatorDirective {

  constructor() { }

  public validate(c: UntypedFormControl) {
    return this.is_valid_itn(c.value) ? null : {invalidItn: true};
  }


  private is_valid_itn(itn: string) {
    if (!itn || itn.length < 10) { return true; }
    if (itn.match(/\D/)) { return false; }

    const inn = itn.match(/(\d)/g);

    const hash = [-1, 5, 7, 9, 4, 6, 10, 5, 7];
    let sum = 0;
    for (let i = 0; i < (inn.length - 1); ++i) {
      sum += +inn[i] * hash[i];
    }
    let k = sum - (11 * (Math.floor(sum / 11)));
    if (k === 10) {
      k = 0;
    }

    return k === +inn[9];

  }

}
