import { HandleEventService } from '../../../services/handle-event-service.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'utax-toggle-sound',
  templateUrl: './toggle-sound.component.html',
  styleUrls: ['./toggle-sound.component.scss']
})
export class ToggleSoundComponent implements OnInit {
  @Input() module: string;
  logistSoundOn: boolean;
  constructor() {}

  ngOnInit() {
    switch (this.module) {
      case 'logist':
        if (localStorage.getItem('logistSound') === 'off') {
          this.logistSoundOn = false;
        } else if (localStorage.getItem('logistSound') === 'on') {
          this.logistSoundOn = true;
        } else {
          this.logistSoundOn = true;
          localStorage.setItem('logistSound', 'on');
        }
        break;

      default:
        break;
    }
  }

  toggleSound() {
    switch (this.module) {
      case 'logist':
        if (this.logistSoundOn) {
          localStorage.setItem('logistSound', 'off');
        } else {
          localStorage.setItem('logistSound', 'on');
        }
        this.logistSoundOn = !this.logistSoundOn;
        break;

      default:
        break;
    }
  }
}
