import { ClusterSort } from 'src/app/utils/cluster-sort';
import { LogistCabsService } from './logist-cabs.service';
import { Subject, interval, BehaviorSubject, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { WebsocketService } from '../../services';
import { pro } from '../../models/models';
import * as protobuf from 'protobufjs';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { HandleEventService } from 'src/app/services';

import * as models from '../../models/models';
import { uuidv4 } from 'src/app/utils/uuid';
import { getColorOfCab } from '../utils/status-color';

const amelia = pro.megakit.amelia;

pro.megakit.amelia.MessageProto.ViolationProperty;

@Injectable()
export class AmeliaService {
  ameliaConnection;

  activeDriverRequests;
  activeDriverRequests$ = new Subject<any[]>();
  workingShifts;
  workingShifts$ = new Subject<any[]>();
  driverRest;
  driverRest$ = new Subject<any[]>();
  finishedDriverRest;
  finishedDriverRest$ = new Subject<any[]>();
  driverPauses;
  driverPauses$ = new Subject<any[]>();

  restTypes = [];
  restTypes$ = new Subject<any[]>();

  userInfo;

  ameliaCabs = [];
  ameliaCabs$ = new BehaviorSubject([]);

  requestConfirmEvent$ = new BehaviorSubject(true);

  private pong = true;
  constructor(
    private ws: WebsocketService,
    private logistCabsService: LogistCabsService,
    private handleService: HandleEventService
  ) {
    this.logistCabsService.visibleCabsSub$ = combineLatest([
      this.logistCabsService.allActiveCabs$,
      this.ameliaCabs$
    ]).pipe(
      map(cabs => {
        return ClusterSort(
          [
            ...cabs[0].map(cab => {
              cab.color = getColorOfCab(cab);
              return cab;
            }),
            ...cabs[1].map(amCab => {
              amCab.color = getColorOfCab(amCab);
              return amCab;
            })
          ],
          this.logistCabsService.sortOptions
        );
      })
    ) as BehaviorSubject<any[]>;
  }

  private waitToDisconnect(): void {
    timer(4000).subscribe(() => {
      if (!this.pong) {
        this.ameliaConnection.error();
      }
    });
  }

  enableService() {
    this.ameliaConnection = this.ws.openConnection('amelia', this.ameliaSub);

    const ping = new Uint8Array(1);
    ping[0] = 1;

    interval(25000).subscribe(() => {
      this.ameliaConnection.sendSync(ping);
      this.pong = false;
      this.waitToDisconnect();
    });

    this.ameliaConnection.onBinary().subscribe(res => {
      const messageType = res[0];

      switch (messageType) {
        case 2:
          this.pong = true;
          // pong
          break;

        case 4:
          this.messageHandler(res.slice(1));
          break;

        case 5:
          this.eventHandler(res.slice(1));
          break;

        case 7:
          this.subResult();
          break;

        default:
          console.log('%c MessageType Default', 'color: black; font-size: 20px', messageType);
          break;
      }
    });

    // GetDriverRestTypes
    const writerReq = protobuf.Writer.create().uint32(3);
    const reqMessage = new amelia.MessageProto.RequestMessage({
      path: 5,
      id: 'restType'
    });
    const encodedReqMessage = amelia.MessageProto.RequestMessage.encode(reqMessage, writerReq).finish();
    this.ameliaConnection.sendSync(encodedReqMessage);
  }

  subResult() {}

  ameliaSub(): any {
    const writer = protobuf.Writer.create().uint32(6);
    const sub = new amelia.SubscriptionProto.Subscription({
      id: uuidv4(),
      name: 'main'
    });
    return amelia.SubscriptionProto.Subscription.encode(sub, writer).finish();
  }

  messageHandler(message) {
    const decodedMessage = amelia.MessageProto.ResponseMessage.decode(message);
    console.log('🚀 AMELIA messageHandler decoded ->', decodedMessage);
    if (decodedMessage.status === 0) {
      // ResponseStatus SUCCESS
      switch (decodedMessage.requestId) {
        case 'restType':
          const decodedBody = amelia.operator.DriverRestProto.GetDriverRestTypesResponse.decode(decodedMessage.body);
          this.restTypes = decodedBody.types;
          this.restTypes$.next(decodedBody.types);
          break;

        default:
          break;
      }
    } else {
      const errorStr = decodedMessage.properties
        .map(prop => {
          const decodedProperty = this.getType(prop.value.type_url).decode(prop.value.value);
          return decodedProperty.message
            ? decodedProperty.message
                .toUpperCase()
                .split(' ')
                .join('_')
            : 'AMELIA_ERROR';
        })
        .join('; /n');
      this.handleService.openSnackBar(errorStr, 5);
    }
  }

  getType(typeUrl: string): any {
    const types = typeUrl.replace('type.googleapis.com/', '').split('.');
    let obj = models;
    types.forEach(type => {
      obj = obj[type];
    });
    return obj;
  }

  eventHandler(message) {
    const event = amelia.EventProto.EventMessage.decode(message);

    console.log('AMELIA eventHandler event ->', event);

    switch (event.id) {
      case 4:
        this.gettingInfoAboutUser(event.payload);
        break;
      case 5:
        this.handleService.makeNotifiSound('amelia', `${event.id}`);
        this.requestForShiftStart(event.payload);
        break;

      case 6:
        this.handleService.makeNotifiSound('amelia', `${event.id}`);
        this.workingShiftRequest(event.payload);
        break;

      case 7:
        this.activeWorkingShift(event.payload);
        break;

      case 8:
      case 9:
        this.workingShiftEvent(event.payload, event.id);
        break;

      case 12:
        this.receiveWorkingShifts(event.payload);
        break;

      case 15:
        this.driverRequestConfirmed(event.payload);
        break;

      case 18:
      case 16:
        this.driverRequestCanceled(event.payload);
        break;
      case 19:
        this.vehicleWashingStarted(event.payload);
        break;
      case 20:
        this.vehicleWashingFinished(event.payload);
        break;
      case 21:
        this.vehicleWashingCanceled(event.payload);
        break;
      case 22:
        this.driverRestStarted(event.payload);
        break;
      case 23:
        this.driverRestFinished(event.payload);
        break;
      case 24:
        this.driverRefillFinish(event.payload);
        break;
      case 26:
        this.workingShiftReceived(event.payload);
        break;
      case 33:
      case 34:
      case 35:
        this.cabPause(event.payload, event.id);
        break;

      case 39:
      case 40:
        this.homeModeForceToggle(event.payload, event.id);
        break;
      default:
        console.log('%c Event Handle default', 'color: red; font-size: 20px', event.id);
        break;
    }
  }

  // 4 event id
  private gettingInfoAboutUser(message) {
    // logist id and logist name
    const decodedMessage = amelia.EventProto.OperatorContextEvent.decode(message);
    this.userInfo = decodedMessage;
    console.log('🚀 AMELIA 4 ~ userInfo', decodedMessage);
  }

  // 5 event id
  private requestForShiftStart(message) {
    const decodedMessage = amelia.operator.EventProto.WorkingShiftCreatedEvent.decode(message);

    this.workingShifts.push(decodedMessage.workingShift);
    this.workingShifts$.next(this.workingShifts);
    this.activeDriverRequests.push({
      ...decodedMessage.driverRequest,
      wshId: decodedMessage.workingShift.id,
      type: decodedMessage.driverRequest.type
    });
    this.activeDriverRequests$.next(this.activeDriverRequests);
    this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);
    const cabToActive = this.logistCabsService.disabledCabs.find(cab => {
      return cab.id === decodedMessage.workingShift.cabId;
    });
    console.log('cabToActive', cabToActive);
    if (cabToActive) {
      this.ameliaCabs.push(cabToActive);
      this.addRequestToCab(cabToActive.id, decodedMessage.driverRequest);
    }
    console.log('🚀 AMELIA 5 ~ requestForShiftStart decodedMessage', decodedMessage);
  }

  // 6 event id
  private workingShiftRequest(req: Uint8Array) {
    const decodedRequest = amelia.operator.EventProto.WorkingShiftRequestCreatedEvent.decode(req);
    console.log('Start of workingShiftRequest decodedRequest', decodedRequest);

    switch (decodedRequest.driverRequest.type) {
      case 1: // FINISH_WORKING_SHIFT
        const driverReq = {
          ...decodedRequest.driverRequest,
          payload: amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload.decode(
            decodedRequest.driverRequest.payload
          )
        };
        this.workingShifts$.next(this.workingShifts);
        const newDriverReq = {
          ...driverReq,
          wshId: decodedRequest.workingShiftId
        };
        this.activeDriverRequests.push(newDriverReq);
        const cabToUpdateId = this.workingShifts.find(shift => shift.id === newDriverReq.wshId).cabId;

        this.addRequestToCab(cabToUpdateId, newDriverReq);
        this.activeDriverRequests$.next(this.activeDriverRequests);

        this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);
        console.log('🚀 AMELIA 6.1 ~ requestForShiftStart decodedMessage', driverReq);
        break;

      case 2: // VEHICLE_REFILL_REQ
        console.log('DECODED REQUEST VEHICLE_REFILL', decodedRequest);
        const newReq = {
          ...decodedRequest.driverRequest,
          wshId: decodedRequest.workingShiftId
        };
        this.activeDriverRequests.push(newReq);
        this.activeDriverRequests$.next(this.activeDriverRequests);
        this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);
        const refillCabId = this.moveShiftToWaitingAndGetCabId(decodedRequest.workingShiftId);
        this.addRequestToCab(refillCabId, newReq);
        console.log('🚀 AMELIA 6.2', newReq);
        break;

      case 3: // START_VEHICLE_WASHING
        console.log('WASHING START REQ');
        const washingStartReq = {
          ...decodedRequest.driverRequest,
          payload: amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload.decode(
            decodedRequest.driverRequest.payload
          ),
          wshId: decodedRequest.workingShiftId
        };
        console.log('TCL: AmeliaService -> workingShiftRequest -> washingStartReq', washingStartReq);

        this.activeDriverRequests.push(washingStartReq);
        this.activeDriverRequests$.next(this.activeDriverRequests);
        this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);
        const washingCabId = this.moveShiftToWaitingAndGetCabId(decodedRequest.workingShiftId);
        this.addRequestToCab(washingCabId, washingStartReq);
        console.log('🚀 AMELIA 6.3 washingStartReq', washingStartReq);
        break;

      case 6: // START_DRIVER_REST
        const startRestReq = {
          ...decodedRequest.driverRequest,
          payload: amelia.driver.DriverRestProto.CreateDriverRestPayload.decode(decodedRequest.driverRequest.payload),
          wshId: decodedRequest.workingShiftId
        };
        console.log('START DRIVER REST', startRestReq);
        this.activeDriverRequests.push(startRestReq);
        this.activeDriverRequests$.next(this.activeDriverRequests);
        const restCabId = this.moveShiftToWaitingAndGetCabId(decodedRequest.workingShiftId);
        this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);
        this.addRequestToCab(restCabId, startRestReq);
        console.log('🚀 AMELIA 6.6 startRestReq', startRestReq);
        break;

      case 7: // CANCEL_DRIVER_REQUEST
        console.log('CANCEL_DRIVER_REQUEST', decodedRequest);
        const cancelReq = {
          ...decodedRequest.driverRequest,
          payload: amelia.driver.DriverRequestProto.CancelDriverRequestPayload.decode(
            decodedRequest.driverRequest.payload
          ),
          wshId: decodedRequest.workingShiftId
        };
        this.activeDriverRequests.push(cancelReq);
        this.activeDriverRequests = this.activeDriverRequests.map(actReq => {
          if (actReq.id === cancelReq.payload.requestId) {
            return {
              ...actReq,
              status: 'waiting_cancel'
            };
          } else {
            return actReq;
          }
        });
        this.activeDriverRequests$.next(this.activeDriverRequests);
        const cancelReqCabId = this.moveShiftToWaitingAndGetCabId(decodedRequest.workingShiftId);
        this.addRequestToCab(cancelReqCabId, cancelReq);

        this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);
        this.updateRequestOfCab(cancelReq.payload.requestId);
        console.log('🚀 AMELIA 6.7 startRestReq', cancelReq);
        break;

      default:
        console.log('DECODED REQUEST DEFAULT', decodedRequest);
        break;
    }
  }

  // 7 event id
  private activeWorkingShift(message: Uint8Array) {
    const decodeMes = amelia.operator.WorkingShift.ActiveWorkingShift.decode(message);

    console.log('🚀 AMELIA 7 startRestReq', decodeMes);
    this.workingShifts = this.workingShifts.map(shift => {
      if (shift.id === decodeMes.id) {
        return decodeMes;
      } else {
        return shift;
      }
    });
    this.removeAllRequestsFromCab(decodeMes.cabId);
    this.workingShifts$.next(this.workingShifts);
    this.activeDriverRequests = this.activeDriverRequests.filter(req => req.wshId !== decodeMes.id);
    this.activeDriverRequests$.next(this.activeDriverRequests);

    this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);
  }

  // 8, 9 event id
  private workingShiftEvent(message, eventId) {
    let cabIdToRemove;
    let reqId;
    const decodedMessage = amelia.operator.EventProto.WorkingShiftEvent.decode(message);
    console.log('AMELIA 8/9', decodedMessage, eventId);
    cabIdToRemove = this.workingShifts.find(shift => decodedMessage.id === shift.id).cabId;
    this.activeDriverRequests = this.activeDriverRequests.filter(req => {
      if (req.wshId !== decodedMessage.id) {
        return req;
      } else {
        reqId = req.id;
      }
    });
    this.workingShifts = this.workingShifts.filter(shift => shift.id !== decodedMessage.id);
    this.workingShifts$.next(this.workingShifts);
    this.activeDriverRequests$.next(this.activeDriverRequests);
    this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);
    this.removeRequestFromCab(reqId, cabIdToRemove);
    if (eventId === 8) {
      // deactivate
      this.deactivateCabById(cabIdToRemove);
    }
  }

  // 12 event id
  private receiveWorkingShifts(message) {
    const decodedMessage = amelia.operator.EventProto.ReceiveWorkingShiftsEvent.decode(message);
    console.log('AMELIA 12', decodedMessage);
    this.driverPauses = decodedMessage.driverPauses;
    this.logistCabsService.driverPauses = this.driverPauses;
    this.driverRest = decodedMessage.driverRest;
    this.driverRest$.next(this.driverRest);
    this.finishedDriverRest = decodedMessage.finishedDriverRest;
    this.finishedDriverRest$.next(this.finishedDriverRest);
    this.workingShifts = decodedMessage.workingShifts;
    this.workingShifts$.next(decodedMessage.workingShifts);
    this.activeDriverRequests = decodedMessage.activeDriverRequests.map(req => {
      switch (req.type) {
        case 1: // FINISH_WORKING_SHIFT
          console.log('AMELIA 12.1', {
            ...req,
            payload: amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload.decode(req.payload)
          });
          return {
            ...req,
            payload: amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload.decode(req.payload)
          };
        case 0: // START_WORKING_SHIFT
        case 2: // VEHICLE_REFILL
        case 4: // FINISH_VEHICLE_WASHING
        case 5: // STORE_DRIVER_EXPENSE
          console.log('AMELIA 12.0,2,4,5', req);
          return req;
        case 3: // START_VEHICLE_WASHING
          let vehicleWashing;
          if (decodedMessage.vehicleWashing.length > 0) {
            vehicleWashing = decodedMessage.vehicleWashing.find(washing => washing.requestId === req.id);
          }

          console.log('AMELIA 12.3', {
            ...req,
            payload: amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload.decode(req.payload),
            vehicleWashing
          });
          return {
            ...req,
            payload: amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload.decode(req.payload),
            vehicleWashing
          };
        case 6: // START_DRIVER_REST
          let driverRest;
          if (decodedMessage.driverRest.length > 0) {
            driverRest = decodedMessage.driverRest.find(rest => rest.requestId === req.id);
          }
          console.log('AMELIA 12.6', {
            ...req,
            payload: amelia.driver.DriverRestProto.CreateDriverRestPayload.decode(req.payload),
            driverRest
          });
          return {
            ...req,
            payload: amelia.driver.DriverRestProto.CreateDriverRestPayload.decode(req.payload),
            driverRest
          };
        case 7: // CANCEL_DRIVER_REQUEST
          console.log('AMELIA 12.7', {
            ...req,
            payload: amelia.driver.DriverRequestProto.CancelDriverRequestPayload.decode(req.payload)
          });
          return {
            ...req,
            payload: amelia.driver.DriverRequestProto.CancelDriverRequestPayload.decode(req.payload)
          };
        default:
          console.log('REQ, DEFAULT', req.type);
          return req;
      }
    });
    this.activeDriverRequests$.next(this.activeDriverRequests);

    // moving cab who want to start shift to active cabs
    this.logistCabsService.disabledCabsSub.subscribe(() => {
      this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);

      const cabIds = this.workingShifts.map(shift => shift.cabId);
      this.ameliaCabs = this.logistCabsService.disabledCabs
        .filter(cab => {
          return cabIds.find(id => id === cab.id);
        })
        .map(htbaCab => {
          let requestsOfCab;
          this.workingShifts.forEach(shift => {
            if (shift.cabId === htbaCab.id) {
              requestsOfCab = this.activeDriverRequests.filter(req => req.wshId === shift.id);
            }
          });
          return { ...htbaCab, requests: requestsOfCab };
        });
      this.ameliaCabs$.next(this.ameliaCabs);
    });
  }

  // @TODO refactor 15
  // 15
  private driverRequestConfirmed(message) {
    // request have been confirmed
    const decodedMessage = amelia.EventProto.DriverRequestEvent.decode(message);
    console.log('DRIVER REQUEST CONFIRM', decodedMessage);
    console.log('AMELIA 15', decodedMessage);
    const confirmedReq = this.activeDriverRequests.find(actReq => actReq.id === decodedMessage.id);

    console.log('confirmedReq', confirmedReq);
    if (confirmedReq) {
      switch (confirmedReq.type) {
        case 0: // start conf
        case 1: // finish conf
          console.log('AMELIA 15.1', decodedMessage);
          this.removeRequestFromActiveRequests(decodedMessage.id);
          break;
        case 2:
          this.activeDriverRequests = this.activeDriverRequests.map(actReq => {
            if (actReq.id === decodedMessage.id) {
              return {
                ...actReq,
                status: 'confirmed'
              };
            } else {
              return actReq;
            }
          });
          this.activeDriverRequests$.next(this.activeDriverRequests);
          console.log('AMELIA 15.2', decodedMessage);
          break;
        case 3: // washing conf
          switch (confirmedReq.status) {
            case 'waiting':
              this.activeDriverRequests = this.activeDriverRequests.map(actReq => {
                if (actReq.id === confirmedReq.id) {
                  return {
                    ...actReq,
                    status: 'confirmed'
                  };
                } else {
                  return actReq;
                }
              });
              break;

            case 'confirmed':
              this.activeDriverRequests = this.activeDriverRequests.map(actReq => {
                if (actReq.id === confirmedReq.id) {
                  return {
                    ...actReq,
                    status: 'in_progress'
                  };
                } else {
                  return actReq;
                }
              });
              break;

            case 'in_progress':
              this.removeRequestFromActiveRequests(decodedMessage.id); // ????
              break;

            default:
              break;
          }
          this.activeDriverRequests$.next(this.activeDriverRequests);
          console.log('AMELIA 15.3', decodedMessage);
          break;

        case 6: // rest conf
          if (confirmedReq.status === 'waiting') {
            this.activeDriverRequests = this.activeDriverRequests.map(actReq => {
              if (actReq.id === confirmedReq.id) {
                return {
                  ...actReq,
                  status: 'confirmed'
                };
              } else {
                return actReq;
              }
            });
            this.activeDriverRequests$.next(this.activeDriverRequests);
          }
          console.log('AMELIA 15.6', decodedMessage);

          break;

        case 7: // cancel confirmed
          console.log('AMELIA 15.7', decodedMessage);

          this.removeRequestFromActiveRequests(decodedMessage.id);
          this.removeRequestFromActiveRequests(confirmedReq.payload.requestId);
          break;

        default:
          break;
      }

      this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);
      this.updateRequestOfCab(decodedMessage.id);
    }

    this.requestConfirmEvent$.next(true);
  }

  // 16, 18
  private driverRequestCanceled(message) {
    const canceledReqId = amelia.EventProto.DriverRequestEvent.decode(message).id;
    const canceledReq = this.activeDriverRequests.find(actReq => actReq.id === canceledReqId);
    console.log('AMELIA 16,18', amelia.EventProto.DriverRequestEvent.decode(message));
    console.log('DRIVER REQUEST canceled');

    this.activeDriverRequests = this.activeDriverRequests.map(actReq => {
      if (canceledReq.type === 7 && actReq.id === canceledReq.payload.requestId) {
        return { ...actReq, status: 'confirmed' };
      } else {
        return actReq;
      }
    });
    this.activeDriverRequests$.next(this.activeDriverRequests);
    this.removeRequestFromActiveRequests(canceledReqId);

    this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);
  }

  // 19
  private vehicleWashingStarted(message) {
    const decoded = amelia.operator.VehicleWashingProto.ActiveVehicleWashing.decode(message);

    this.activeDriverRequests = this.activeDriverRequests.map(actReq => {
      if (actReq.id === decoded.requestId) {
        return {
          ...actReq,
          status: 'in_progress',
          vehicleWashing: decoded
        };
      } else {
        return actReq;
      }
    });
    this.activeDriverRequests$.next(this.activeDriverRequests);

    this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);
    this.updateRequestOfCab(decoded.requestId);
    console.log('AMELIA 19 vehicleWashingStarted', decoded);
  }

  // 20
  private vehicleWashingFinished(message) {
    const decoded = amelia.operator.EventProto.VehicleWashingEvent.decode(message);

    this.activeDriverRequests = this.activeDriverRequests.filter(actReq => {
      if (actReq.vehicleWashing && actReq.vehicleWashing.id === decoded.id) {
        const cabId = this.workingShifts.find(shift => shift.id === actReq.wshId).cabId;
        this.removeRequestFromCab(actReq.id, cabId);
      } else {
        return actReq;
      }
    });
    this.activeDriverRequests$.next(this.activeDriverRequests);
    this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);
    console.log('AMELIA 20 vehicleWashingFinished', decoded);
  }

  // 21
  private vehicleWashingCanceled(message) {
    const decoded = amelia.operator.EventProto.VehicleWashingEvent.decode(message);
    this.activeDriverRequests = this.activeDriverRequests.filter(actReq => {
      if (actReq.vehicleWashing && actReq.vehicleWashing.id === decoded.id) {
        const cabId = this.workingShifts.find(shift => shift.id === actReq.wshId).cabId;
        this.removeRequestFromCab(actReq.id, cabId);
      } else {
        return actReq;
      }
    });
    this.activeDriverRequests$.next(this.activeDriverRequests);
    this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);
    console.log('AMELIA 21 vehicleWashingCanceled', decoded);
  }

  // 22
  private driverRestStarted(message) {
    const decoded = amelia.operator.DriverRestProto.ActiveDriverRest.decode(message);

    this.activeDriverRequests = this.activeDriverRequests.map(actReq => {
      if (actReq.id === decoded.requestId) {
        this.driverRest.push({
          ...decoded,
          wshId: actReq.wshId
        });

        this.driverRest$.next(this.driverRest);
        return {
          ...actReq,
          status: 'in_progress',
          driverRest: decoded
        };
      } else {
        return actReq;
      }
    });
    this.activeDriverRequests$.next(this.activeDriverRequests);
    // on 22 event update cab
    this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);
    this.updateRequestOfCab(decoded.requestId);
    console.log('AMELIA 22 driverRestStarted', decoded);
  }

  // 23
  private driverRestFinished(message) {
    const decoded = amelia.operator.EventProto.DriverRestFinishedEvent.decode(message);

    this.activeDriverRequests = this.activeDriverRequests.filter(actReq => {
      if (actReq.driverRest && actReq.driverRest.id === decoded.id) {
        this.finishedDriverRest.push({
          ...this.driverRest.find(rest => rest.id === decoded.id),
          ...decoded,
          wshId: actReq.wshId
        });
        this.finishedDriverRest$.next(this.finishedDriverRest);

        const cabId = this.workingShifts.find(shift => shift.id === actReq.wshId).cabId;
        this.removeRequestFromCab(actReq.id, cabId);
      } else {
        return actReq;
      }
    });
    this.activeDriverRequests$.next(this.activeDriverRequests);
    this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);
    console.log('AMELIA 23 driverRestFinished', decoded);
  }

  // 24
  private driverRefillFinish(message) {
    const decoded = amelia.operator.EventProto.VehicleRefillFinishedEvent.decode(message);
    this.removeRequestFromActiveRequests(decoded.requestId);
    this.logistCabsService.setRequestsAndShifts(this.activeDriverRequests, this.workingShifts);
    console.log('AMELIA 24 driverRefillFinish', decoded);
  }

  // 26
  private workingShiftReceived(message) {
    const decoded: any = amelia.operator.EventProto.WorkingShiftReceivedEvent.decode(message);
    console.log('26 event message', decoded);
    console.log('AMELIA 26 workingShiftReceived', decoded);

    this.workingShifts.push(decoded.workingShift);
    this.workingShifts$.next(this.workingShifts);

    // push cab to active cabs
    const cabIdToAmelia = decoded.workingShift.cabId;
    const cab = this.logistCabsService.disabledCabs.find(item => item.id === cabIdToAmelia);
    if (cab) {
      if (!this.ameliaCabs.some(item => item.id === cab.id)) {
        this.ameliaCabs.push({ ...cab, requests: [] });
        this.ameliaCabs$.next(this.ameliaCabs);
        this.logistCabsService.selectCab({ id: 'all' });
      }
    }
  }

  // 33, 34, 35
  cabPause(message: any, pauseEventType: number): void {
    let decoded;
    let shiftId = '';
    console.log('AMELIA 33, 34, 35 cabPause');
    switch (pauseEventType) {
      case 33:
        decoded = amelia.operator.DriverPauseProto.ActiveDriverPause.decode(message);
        if (this.driverPauses.some(item => item.id === decoded.id)) {
          // update already existed pause
          this.driverPauses = this.driverPauses.map(drivePause => {
            if (drivePause.id === decoded.id) {
              shiftId = drivePause.wshId;
              return {
                ...drivePause,
                status: decoded.status,
                startedAt: decoded.startedAt
              };
            } else {
              return decoded;
            }
          });
        } else {
          // new pause
          this.driverPauses.push(decoded);
          shiftId = decoded.wshId;
        }
        console.log('AMELIA 33', decoded);
        break;
      case 34:
        decoded = amelia.operator.EventProto.DriverPauseFinishedEvent.decode(message);
        const pause = this.driverPauses.find(item => item.id === decoded.id);
        shiftId = pause ? pause.wshId : '';
        this.driverPauses = this.driverPauses.filter(pauseitem => pauseitem.id !== decoded.id);
        console.log('AMELIA 34', decoded);
        break;
      case 35:
        decoded = amelia.operator.EventProto.DriverPauseEvent.decode(message);
        const findedPause = this.driverPauses.find(psItm => pause.id === decoded.id);
        shiftId = findedPause ? pause.wshId : '';
        this.driverPauses = this.driverPauses.filter(driverPause => driverPause.id !== decoded.id);
        console.log('AMELIA 35', decoded);
        break;
      default:
        break;
    }

    console.log(`PC: cabPause -> ${pauseEventType} decoded`, decoded);
    // processingCabs
    this.logistCabsService.driverPauses = this.driverPauses;
    const cabId = this.getCabIdByShiftId(shiftId);
    const cab = this.logistCabsService.activeCabs.find(item => item.id === cabId);
    if (cab) {
      this.logistCabsService.processingCabs([
        {
          cabs: [
            {
              ...cab,
              pauses: (this.driverPauses || []).filter(pause => pause.wshId === shiftId)
            }
          ]
        }
      ]);
    }
    this.driverPauses$.next(this.driverPauses);
  }

  confirmDriverRequest(confirm: boolean, id: string) {
    const bodyReq = new amelia.operator.DriverRequest.ConfirmDriverRequest({
      id
    });
    const bodyMessage = amelia.operator.DriverRequest.ConfirmDriverRequest.encode(bodyReq).finish();
    this.sendRequest(bodyMessage, confirm ? 3 : 4);
  }

  openShift(cabId, startMileage) {
    const openShiftReq = new amelia.operator.WorkingShift.StartWorkingShiftRequest({ cabId, startMileage });
    const encodedReq = amelia.operator.WorkingShift.StartWorkingShiftRequest.encode(openShiftReq).finish();
    this.sendRequest(encodedReq, 6);
  }

  closeShift(cabId, finishMileage) {
    const id = this.workingShifts.find(shift => shift.cabId === cabId).id;
    const finishReq = new amelia.operator.WorkingShift.FinishWorkingShiftRequest({
      id,
      finishMileage
    });
    const encodedReq = amelia.operator.WorkingShift.FinishWorkingShiftRequest.encode(finishReq).finish();
    this.sendRequest(encodedReq, 7);
  }

  startRest(requestId) {
    const startRest = new amelia.operator.DriverRestProto.StartDriverRestRequest({
      requestId
    });
    const bodyMessage = amelia.operator.DriverRestProto.StartDriverRestRequest.encode(startRest).finish();
    this.sendRequest(bodyMessage, 8);
  }

  completeRest(requestId) {
    const startRest = new amelia.operator.DriverRestProto.FinishDriverRestRequest({
      id: requestId
    });
    const bodyMessage = amelia.operator.DriverRestProto.FinishDriverRestRequest.encode(startRest).finish();
    this.sendRequest(bodyMessage, 9);
  }

  startWashing(requestId) {
    const bodyMessage = amelia.operator.VehicleWashingProto.StartVehicleWashingResponse.encode(
      new amelia.operator.VehicleWashingProto.StartVehicleWashingResponse({
        id: requestId
      })
    ).finish();
    this.sendRequest(bodyMessage, 10);
  }

  completeWashing(requestId) {
    const bodyMessage = amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest.encode(
      new amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest({
        id: requestId
      })
    ).finish();
    this.sendRequest(bodyMessage, 11);
  }

  cancelWashing(requestId) {
    const bodyMessage = amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest.encode(
      new amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest({
        id: requestId
      })
    ).finish();
    this.sendRequest(bodyMessage, 12);
  }

  homeModeForceToggle(message, eventId: 39 | 40): void {
    let decoded;
    decoded = amelia.operator.EventProto.WorkingShiftEvent.decode(message); // DriverPauseProto.ActiveDriverPause.decode(message);
    this.workingShifts = this.workingShifts.map(shift => {
      return shift.id === decoded.id ? { ...shift, isParkingForceAllowed: eventId === 39 } : shift;
    });
    this.workingShifts$.next(this.workingShifts);
  }

  sendRequest(request, code) {
    const writer = protobuf.Writer.create().uint32(3);
    const message = new amelia.MessageProto.RequestMessage({
      path: code,
      body: request
    });
    const encodedMessage = amelia.MessageProto.RequestMessage.encode(message, writer).finish();
    this.ameliaConnection.sendSync(encodedMessage);
  }

  private addPauseToCab(): void {}

  private removePauseFromCab(): void {}

  private removeRequestFromActiveRequests(reqId) {
    this.activeDriverRequests = this.activeDriverRequests.filter(req => {
      if (req.id !== reqId) {
        return req;
      } else {
        const cabId = this.workingShifts.find(shift => shift.id === req.wshId).cabId;
        this.removeRequestFromCab(reqId, cabId);
      }
    });
    this.activeDriverRequests$.next(this.activeDriverRequests);
  }

  private moveShiftToWaitingAndGetCabId(shiftId: string): string {
    let cabId;
    this.workingShifts = this.workingShifts.map(shift => {
      if (shift.id === shiftId) {
        cabId = shift.cabId;
        return { ...shift, status: 'waiting' };
      } else {
        return shift;
      }
    });
    this.workingShifts$.next(this.workingShifts);
    return cabId;
  }

  private getCabIdByShiftId(shiftId: string): string {
    let cabId = '';
    this.workingShifts.forEach(shift => {
      if (shift.id === shiftId) {
        cabId = shift.cabId;
      }
    });
    return cabId;
  }

  disableService() {
    this.ameliaConnection.close();
  }

  private removeAllRequestsFromCab(cabId) {
    this.ameliaCabs = this.ameliaCabs.map(actCab => {
      if (cabId === actCab.id) {
        return { ...actCab, requests: [] };
      }
      return actCab;
    });
    this.ameliaCabs$.next(this.ameliaCabs);
  }

  private deactivateCabById(cabId) {
    console.log('deactivateCabById -> cabId', cabId);
    let cabToDeactivate = this.ameliaCabs.find(amCab => cabId === amCab.id);
    if (cabToDeactivate) {
      this.ameliaCabs = this.ameliaCabs.filter(amCab => cabId !== amCab.id);
      this.ameliaCabs$.next(this.ameliaCabs);
    } else {
      cabToDeactivate = this.logistCabsService.activeCabs.find(visCab => visCab.id === cabId);
      this.logistCabsService.processingCabs([
        {
          cabs: [{ ...cabToDeactivate, requests: [], is_active: false }]
        }
      ]);
    }
    this.logistCabsService.deactivatedCabIds.push(cabId);
    this.logistCabsService.selectCab({ id: 'all' });
  }

  private addRequestToCab(cabId: string, request: any): void {
    let isAmeliaCabUpdated = false;
    this.ameliaCabs = this.ameliaCabs.map(actCab => {
      if (actCab.id === cabId) {
        const requests = actCab.requests ? actCab.requests : [];
        requests.push(request);
        isAmeliaCabUpdated = true;
        return { ...actCab, requests };
      } else {
        return actCab;
      }
    });
    this.ameliaCabs$.next(this.ameliaCabs);
    if (!isAmeliaCabUpdated) {
      this.logistCabsService.activeCabs = this.logistCabsService.activeCabs.map(actCab => {
        if (actCab.id === cabId) {
          const requests = actCab.requests ? actCab.requests : [];
          requests.push(request);
          isAmeliaCabUpdated = true;
          return { ...actCab, requests };
        } else {
          return actCab;
        }
      });
      this.logistCabsService.activeCabsSub.next(this.logistCabsService.activeCabs);
    }
  }

  private updateRequestOfCab(reqId) {
    // getting new active req and update in cab
    const actReq = this.activeDriverRequests.find(req => req.id === reqId);
    if (actReq) {
      const cabId = this.workingShifts.find(shift => shift.id === actReq.wshId).cabId;

      this.logistCabsService.activeCabs.forEach(actCab => {
        if (cabId === actCab.id) {
          this.logistCabsService.processingCabs(
            [
              {
                cabs: [
                  {
                    ...actCab,
                    requests: actCab.requests.map(req => {
                      if (req.id === actReq.id) {
                        return actReq;
                      } else {
                        return req;
                      }
                    })
                  }
                ]
              }
            ],
            true
          );
        }
      });
    }
  }

  private removeRequestFromCab(reqId, cabId) {
    this.logistCabsService.activeCabs.forEach(actCab => {
      if (cabId === actCab.id) {
        if (actCab.requests && actCab.requests.length > 0) {
          this.logistCabsService.processingCabs(
            [
              {
                cabs: [
                  {
                    ...actCab,
                    requests: actCab.requests.filter(req => req.id !== reqId)
                  }
                ]
              }
            ],
            true
          );
        }
      }
    });
  }
}
