import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomLanguage, CustomTranslationService, TranslationItem, TranslationObj } from '../services';

@Pipe({
  name: 'customTranslate',
  pure: false
})
export class CustomTranslatePipe implements PipeTransform {
  constructor(private customTranslationService: CustomTranslationService, private translate: TranslateService) {}

  transform(value: any, notLockedValue = ''): string {
    if (!value) {
      return '';
    } else {
      const translations: TranslationObj[] = this.customTranslationService.translations;
      const translate = translations.find((trans: TranslationObj) => trans.key === value);
      const lang: CustomLanguage = this.getLocalLang(this.translate.currentLang);
      if (translate) {
        return translate.translations.find((tranItem: TranslationItem) => tranItem.locale_id === lang.id).value;
      } else {
        return notLockedValue;
      }
    }
  }

  private getLocalLang(newLang: string): CustomLanguage {
    if (newLang === 'ua') {
      return this.customTranslationService.langs.find(lang => lang.lang === 'uk');
    } else {
      return this.customTranslationService.langs.find(lang => lang.lang === newLang);
    }
  }
}
