import { ArchiveApiService } from './../../services/archive-api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'utax-archive-details',
  templateUrl: './archive-details.component.html',
  styleUrls: ['./archive-details.component.scss']
})
export class ArchiveDetailsComponent implements OnInit {

  constructor(public archiveApiService: ArchiveApiService) { }

  ngOnInit() {
  }

}
