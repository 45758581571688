import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { TimezoneService } from 'src/app/services';

@Pipe({
  name: 'timeAt',
  pure: false
})
export class TimeAtPipe implements PipeTransform {
  translatedWord;

  constructor(
    private translate: TranslateService,
    private ref: ChangeDetectorRef,
    private tzService: TimezoneService
  ) {}

  transform(time, service?: number): any {
    if (time) {
      this.translate.get('AT').subscribe(data => {
        this.translatedWord = data;
        this.ref.markForCheck();
      });

      return `${moment(time).format('DD.MM')} ${this.translatedWord} ${moment(time).format('HH:mm')}`;
    }
    return;
  }
}
