import { CabRefillModalComponent } from './../cab-refill-modal/cab-refill-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AmeliaService } from './../../services/amelia.service';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'utax-cab-rest-start-modal',
  templateUrl: './cab-rest-start-modal.component.html',
  styleUrls: ['./cab-rest-start-modal.component.scss']
})
export class CabRestStartModalComponent implements OnInit {
  minutesInWaiting;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<CabRefillModalComponent>,
              private amelia: AmeliaService) { }

  ngOnInit() {
    this.minutesInWaiting = moment().diff(moment.unix(this.data.req.createdAt.seconds), 'minutes');
  }

  close() {
    this.dialogRef.close('close');
  }

  cancel() {
    console.log('rest start cancel');
    this.amelia.confirmDriverRequest(false, this.data.req.id);
    this.dialogRef.close('cancel');
  }

  confirm() {
    console.log('rest start confirm');
    this.amelia.confirmDriverRequest(true, this.data.req.id);
    this.dialogRef.close('confirm');
  }
}
