import { BehaviorSubject } from 'rxjs';
import { BalanceModel } from '../models/balance.models';
import { Injectable } from '@angular/core';
import { BalanceApiService } from './balance-api.service';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {

  readonly balance$ = new BehaviorSubject<BalanceModel>(null);

  constructor(private balanceApiService: BalanceApiService) {
  }

  refreshBalance(): void {
    this.balanceApiService.getBalance()
      .subscribe(balance => {
        this.balance$.next(balance);
      });
  }
}
