import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserApiService, UserSecurityApiService } from '../../services';
import { AuthService } from '../../../auth/services';
import { flatMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'utax-phone-number-verification-dialog',
  templateUrl: './phone-number-verification-dialog.component.html',
  styleUrls: ['./phone-number-verification-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneNumberVerificationDialogComponent implements OnInit {

  verificationCode = '';
  loading = false;
  errorMessage: string | null = null;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<PhoneNumberVerificationDialogComponent, boolean>,
    private userApiService: UserApiService,
    private userSecurityApiService: UserSecurityApiService,
    private authService: AuthService,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.requestVerification();
  }

  requestVerification(): void {
    this.loading = true;
    this.errorMessage = null;
    this.changeDetectorRef.detectChanges();

    this.userApiService.syncGlobalPhoneNumber()
      .pipe(
        flatMap(() => this.userSecurityApiService.requestPhoneNumberVerification()),
      )
      .subscribe(response => {
        this.loading = false;
        this.errorMessage = null;

        if (response.headers.has('X-Verification-Code')) {
          this.verificationCode = response.headers.get('X-Verification-Code') || '';
        }

        this.changeDetectorRef.detectChanges();
      }, error => {
        this.loading = false;
        this.errorMessage = this.getErrorMessage(error);
        this.changeDetectorRef.detectChanges();
      });
  }

  onConfirm(): void {
    this.loading = true;
    this.errorMessage = null;
    this.changeDetectorRef.detectChanges();

    this.userSecurityApiService.passPhoneNumberVerification(this.verificationCode)
      .pipe(
        flatMap(() => this.authService.syncUser()),
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      }, error => {
        this.loading = false;
        this.errorMessage = this.getErrorMessage(error);
        this.changeDetectorRef.detectChanges();
      });
  }

  private getErrorMessage(error: any): string {
    if (error instanceof HttpErrorResponse) {
      switch (error.error?.code) {
        case 'too_many_attempts':
          return this.translateService.instant('PHONE_NUMBER_VERIFICATION_ERROR_TOO_MANY_ATTEMPTS');
        case 'phone_number_already_verified':
          return this.translateService.instant('PHONE_NUMBER_VERIFICATION_ERROR_ALREADY_VERIFIED');
        case 'phone_number_is_not_defined':
          return this.translateService.instant('PHONE_NUMBER_VERIFICATION_ERROR_IS_NOT_DEFINED');
        case 'unexpected_verification_code':
        case 'expired_verification_code':
        case 'invalid_verification_code':
          return this.translateService.instant('PHONE_NUMBER_VERIFICATION_ERROR_INVALID_CODE');
        case 'verification_throttle':
          return this.translateService.instant('PHONE_NUMBER_VERIFICATION_ERROR_THROTTLE');
      }
    }

    return this.translateService.instant('PHONE_NUMBER_VERIFICATION_ERROR_UNKNOWN');
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
