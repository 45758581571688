import { ClusterSort, SortOption } from 'src/app/utils/cluster-sort';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clusterSort',
  pure: false
})
export class ClusterSortPipe implements PipeTransform {
  transform(items: any[], options: SortOption<any>[]): any[] {
    return ClusterSort(items, options);
  }
}
