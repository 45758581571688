import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { RequestHistoryModel } from '../models/requests-history.model';

const homeModeForceAllowUrl = 'operator/driver/parking/force-allowed',
  workingShiftRequestshistory = 'operator/logistics/working-shifts/requests';

@Injectable()
export class AmeliaApiService {
  constructor(private http: HttpClient) {}

  toggleHomeMode(body: { wsh_id: string; is_force_allowed: boolean }): Observable<any> {
    return this.http.put(homeModeForceAllowUrl, body);
  }

  getWorkingShiftRequestsHistory(wshId: string): Observable<RequestHistoryModel[]> {
    return this.http.get(workingShiftRequestshistory, { params: { id: wshId } }).pipe(pluck('data'));
  }
}
