import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {OrderClass} from '@global-classes/order.class';
import {ChatApiService} from '../../services';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {filter, finalize, map, pluck, tap} from "rxjs/operators";
import {ChatHistoryDataInterface} from "../../interfaces/chat.interface";
import {
  ChatListenerObjectInterface,
  ChatUserActionEnum,
  ChatUserActionInterface
} from "../../../dispatcher/models/dispatcher";

@UntilDestroy()
@Component({
  selector: 'utax-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  private _order: OrderClass;
  @Input() set order(value: OrderClass) {
    this._order = value;
    if (this._order) {
      // this.getChatHistoryForOrder(this._order.id);
    }
  }
  get order(): OrderClass {
    return this._order;
  }
  @Input() allowKickInChat: boolean;

  @Output() messageCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() sendChatMessage: EventEmitter<ChatListenerObjectInterface> = new EventEmitter<ChatListenerObjectInterface>();

  public chatMessages: ChatHistoryDataInterface[] = [];
  public lastUpdate: number;
  public showSearchPreloader = false;
  public showDriverKick = false;
  public showPassengerKick = false;

  constructor(private chatApiService: ChatApiService,
              private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getChatHistoryForOrder();
    this.chatApiService.chatNewMessage$.pipe(
      tap((data) => {
        this.chatMessages = [...this.chatMessages, ...data];
        this.messageCount.emit(this.chatMessages.length);
        if (data.length) {
          const lastPassenger = this.chatMessages.slice().reverse().find((message) => message.user.user_type === 'passenger');
          const lastDriver = this.chatMessages.slice().reverse().find((message) => message.user.user_type === 'driver');
          this.showDriverKick = lastDriver && lastDriver.message.chat_action !== 'left';
          this.showPassengerKick = lastPassenger && lastPassenger.message.chat_action !== 'left';
        }
        this.cdr.detectChanges();
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  private getChatHistoryForOrder(): void {
    this.showSearchPreloader = true;
    this.chatApiService.chatOldMessages$
      .pipe(
        tap((data) => {
          this.chatMessages = data;
          this.messageCount.emit(data.length);
          setTimeout(() => {
            const elem = document.getElementById('chatContent');
            if (elem) {
              elem.scrollTop = elem.scrollHeight;
            }
          }, 0);
          if (data.length) {
            const lastPassenger = data.slice().reverse().find((message) => message.user.user_type === 'passenger');
            const lastDriver = data.slice().reverse().find((message) => message.user.user_type === 'driver');
            this.showDriverKick = lastDriver && lastDriver.message.chat_action !== 'left';
            this.showPassengerKick = lastPassenger && lastPassenger.message.chat_action !== 'left';
          }
          this.cdr.detectChanges();
          this.lastUpdate = Date.now();
        }),
        finalize(() => {
          this.showSearchPreloader = false;
          this.cdr.detectChanges();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public renewChatHistory(): void {
    // this.getChatHistoryForOrder(this._order.id);
  }

  public kickUserFromChat(userType: 'driver' | 'passenger') {
    const userId = this.chatMessages.slice().reverse().find((message) => message.user.user_type === userType)?.user.id;
    const chatId = this.chatMessages.find((message) => message.user.user_type === userType)?.chat.id;
    // const id = this.chatApiService.currentOctobusUser$.value?.user_id;
    const data: ChatUserActionInterface = {
      action: ChatUserActionEnum.kick,
      user_id: userId,
      chat_id: chatId
    };
    const message: ChatListenerObjectInterface = {
      message_type: 'user_action',
      payload: data
    };

    this.chatApiService.lastKick$.next(userType);
    this.sendChatMessage.emit(message);
  }

  private showButtonKick(user_type: string) {
    if (user_type === 'driver') {
      this.showDriverKick = true;
    } else if (user_type === 'passenger') {
      this.showPassengerKick = true;
    }
  }
}
