import { OrderApiService } from './../../services/order-api.service';
import { LogistCabsService } from './../../services/logist-cabs.service';
import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { LogistOrdersService } from 'src/app/logist/services/logist-orders.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'utax-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss', '../columns.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersListComponent implements OnInit, OnDestroy {
  @ViewChild('ordersContainer')
  private ordersContainer: ElementRef;
  componentDestroyed = new Subject();

  selectedItemHeight: number;

  ordersToShow = [];

  constructor(
    public logistOrdersService: LogistOrdersService,
    public logistCabsService: LogistCabsService,
    private orderApiService: OrderApiService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.logistOrdersService.isReadyToShowOrders$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((isReady: boolean) => {
        this.ordersToShow = [...this.logistOrdersService.ordersToShow];
        this.cd.detectChanges();
      });

    this.logistOrdersService.ordersToShow$.pipe(takeUntil(this.componentDestroyed)).subscribe(ordsToShow => {
      this.ordersToShow = [...ordsToShow];
      const selectedOrder = ordsToShow.find(
        ord => this.orderApiService.selectedOrder && this.orderApiService.selectedOrder.id === ord.id
      );
      if (!selectedOrder) {
        this.orderApiService.selectOrder(null); // unselect order if it is not in ordersToShow
      }
      this.cd.detectChanges();
    });

    this.logistOrdersService.ordersTableScroll$.pipe(takeUntil(this.componentDestroyed)).subscribe(data => {
      if (data === 'down' && this.ordersContainer) {
        this.ordersContainer.nativeElement.scrollBy(0, this.selectedItemHeight);
      }
      if (data === 'up' && this.ordersContainer) {
        this.ordersContainer.nativeElement.scrollBy(0, -this.selectedItemHeight);
      }
    });
  }

  trackById(i, order) {
    return order.id;
  }

  ngOnDestroy() {
    this.componentDestroyed.next(true);
    this.componentDestroyed.unsubscribe();
  }

  setSelectedItemHeight(event) {
    this.selectedItemHeight = event;
  }
}
