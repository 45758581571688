import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { QueueService } from '../../services';
import { QueueTableMode } from '../../models';
import { MatSelectChange } from '@angular/material/select';
import { map, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgOnDestroy } from '../../../../../shared/services';
import {GlobalDataService} from '@global-services/global-data.service';

@Component({
  selector: 'utax-queue-controls',
  templateUrl: './queue-controls.component.html',
  styleUrls: ['./queue-controls.component.scss'],
  providers: [NgOnDestroy],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueueControlsComponent implements OnInit {

  readonly queueTableMode = QueueTableMode;

  newQueues$: Observable<boolean>;
  editQueues$: Observable<boolean>;

  constructor(
    public queueService: QueueService,
    public globalDataService: GlobalDataService,
    private ngOnDestroy$: NgOnDestroy
  ) {}

  ngOnInit(): void {
    this.newQueues$ = this.queueService.queuesForPost$
      .pipe(
        takeUntil(this.ngOnDestroy$),
        map(newQueues => {
          const queues = this.queueService.allQueues$.value;
          if (newQueues.length) {
            const test = newQueues.some(newQueue => queues.some(queue => (
              queue.callLineId === newQueue.callLineId &&
              queue.taxiServiceId === newQueue.taxiServiceId &&
              queue.hasActiveRequest === newQueue.hasActiveRequest
            )));
            return test;
          }
          return false;
        })
      );

    this.editQueues$ = this.queueService.queuesForPut$
      .pipe(
        takeUntil(this.ngOnDestroy$),
        map(editQueues => {
          const queues = this.queueService.allQueues$.value;
          if (editQueues.length) {
            const test = editQueues.some(editQueue => queues.some(queue => (
              queue.callLineId === editQueue.callLineId &&
              queue.taxiServiceId === editQueue.taxiServiceId &&
              queue.hasActiveRequest === editQueue.hasActiveRequest &&
              queue.id !== editQueue.id
            )));
            return test;
          }
          return false;
        })
      );
  }

  changeQueueTableMode(mode: QueueTableMode): void {
    this.queueService.tableMode = mode;
  }

  save(): void {
    this.queueService.save();
  }

  cancel(): void {
    this.queueService.cancel();
  }

  changeQueueGroup(event: MatSelectChange): void {
    this.queueService.selectedGroupUuid$.next(event.value);
  }
}
