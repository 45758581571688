import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'utax-telephony-sync-button',
  templateUrl: './telephony-sync-button.component.html',
  styleUrls: ['./telephony-sync-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TelephonySyncButtonComponent {
  @Input('userConnected')
  set isUserConnected(userConnected) {
    this.userConnected = userConnected;
  }
  @Output() disconnect = new EventEmitter();
  @Output() connect = new EventEmitter();
  userConnected;
  constructor() {}

  disconnectClick(): void {
    this.disconnect.emit();
  }

  connectClick(): void {
    this.connect.emit();
  }
}
