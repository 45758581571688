const bonusesMode: Array<string> = ['SINGLE_DAYS', 'CONTINUOUS_DAYS', 'DATE_EXPRESSION'];
const intervalTypes: Array<string> = ['solid_interval', 'certain_time'];

export function getBonuseMode() {
  return bonusesMode;
}

export function getConvertedExpression(el) {
  const expression = el ? JSON.parse(el.expression) : null;
  if (!expression) {
    return {
      bonusesMode: 'CONTINUOUS_DAYS',
      start_day: 0,
      end_day: 6,
      start_time: '00:00',
      end_time: '23:59'
    };
  }
  const res = {
    ...expressionDateModeSource(expression),
    ...expressionContinuousDaysSource(expression),
    ...expressionSingleDaysSource(expression, el?.period)
  };
  return res;
}

function expressionDateModeSource(expression) {
  if (expression.length === 1 && expression[0].indexOf('=') + 1) {
    const dateExpressionArray = [...expression[0].match('=z{(.*)};z{(.*)}=')].slice(-2);
    return {
      bonuses_mode: bonusesMode[2],
      start_time: dateExpressionArray[0].slice(-9, -4),
      end_time: dateExpressionArray[1].slice(-9, -4),
      start_date: new Date(dateExpressionArray[0].substring(0, 10)),
      end_date: new Date(dateExpressionArray[1].substring(0, 10))
    };
  }
}

function expressionContinuousDaysSource(expression) {
  if (expression.length === 3) {
    const startDateArray = [...expression[0].match('~w{(.*)};t{(.*)}~')].slice(-2);
    const endDateArray = [...expression[2].match('~w{(.*)};t{(.*)}~')].slice(-2);
    return {
      bonuses_mode: bonusesMode[1],
      start_time: startDateArray[1].slice(0, 5),
      end_time: endDateArray[1].slice(6, 11),
      start_day: parseInt(startDateArray[0], 10),
      end_day: parseInt(endDateArray[0], 10)
    };
  }
}

function expressionSingleDaysSource(expression, periodType?) {
  let resultObj;
  if (expression.length === 1 && expression[0].indexOf('~w{') + 1) {
    if (periodType && periodType === 'weekly') {
      resultObj = getCertainDaysExpression(expression[0]);
    } else {
      resultObj = getFirstHalfOfDayExpression(expression[0]);
    }
  }
  if (expression.length === 2) {
    resultObj = getSecondHalfOfDayExpression(expression);
  }
  return resultObj;
}

function getCertainDaysExpression(expression) {
  const timeArray = expression.match('~w{(.*)};t{(.*)}~').slice(-2);
  return {
    bonuses_mode: bonusesMode[1],
    start_time: timeArray[1].slice(0, 5),
    end_time: timeArray[1].slice(-5),
    start_day: +timeArray[0].slice(0, 1),
    end_day: +timeArray[0].slice(-1),
    interval_type: intervalTypes[1]
  };
}

function getFirstHalfOfDayExpression(expression) {
  const timeArray = expression.match('~w{(.*)};t{(.*)}~').slice(-2);
  return {
    bonuses_mode: bonusesMode[0],
    start_time: timeArray[1].slice(0, 5),
    end_time: timeArray[1].slice(-5),
    days_of_week: getDaysOfWeek(timeArray[0])
  };
}

function getSecondHalfOfDayExpression(expression) {
  const startTimeValue = expression[0].match('~w{(.*)};t{(.*)}~').slice(-2);
  const endTimeValue = expression[1].match('~w{(.*)};t{(.*)}~').slice(-2);
  let resObj = {};
  if (endTimeValue[0] - startTimeValue[0] === 1) {
    resObj = {
      bonuses_mode: bonusesMode[1],
      start_day: parseInt(startTimeValue[0], 10),
      end_day: parseInt(endTimeValue[0], 10)
    };
  } else {
    resObj = {
      bonuses_mode: bonusesMode[0],
      days_of_week: getDaysOfWeek(startTimeValue[0])
    };
  }
  return {
    start_time: startTimeValue[1].slice(0, 5),
    end_time: endTimeValue[1].slice(6, 11),
    ...resObj
  };
}

function getDaysOfWeek(daysOfWeek: string) {
  const resultArr = daysOfWeek.split(',').map(el => {
    if (el.indexOf('-') !== -1) {
      return splitWeeksArraySource(el);
    } else {
      return [Number(el)];
    }
  });
  return [].concat.apply([], resultArr);
}

function splitWeeksArraySource(el) {
  const arr = el.split('-');
  const result = [];
  for (let i = Number(arr[0]); i <= Number(arr[1]); i++) {
    result.push(i);
  }
  return result;
}

export function getFormAnObjectToSend(obj) {
  let resultObj = Object.create({});
  switch (obj.bonuses_mode) {
    case bonusesMode[0]:
      resultObj = { ...daysModeObjectSorce(obj) };
      break;
    case bonusesMode[1]:
      resultObj = { ...continuousDaysConvertToObjectSource(obj) };
      break;
    case bonusesMode[2]:
      resultObj = { ...dateConvertToObjectSource(obj) };
      break;
    default:
      daysModeObjectSorce(obj);
      break;
  }
  return resultObj;
}

function daysModeObjectSorce(obj) {
  let resultExpressionArray = ' ';
  resultExpressionArray = `[\"~w{${obj.days_of_week.join(',')}};t{${obj.start_time}-${obj.end_time}}~\"]`;
  return {
    expression: resultExpressionArray
  };
}

function continuousDaysConvertToObjectSource(obj) {
  const startDay = obj.start_day,
    endDay = obj.end_day,
    daysInterval = [0, 1, 2, 3, 4, 5, 6];
  let res = [];
  if (startDay < endDay) {
    res = daysInterval.slice(startDay + 1, endDay);
  }
  if (startDay > endDay) {
    res = [...daysInterval.splice(startDay + 1, daysInterval.length - 1), ...daysInterval.splice(0, endDay)];
  }
  if (startDay === endDay) {
    res = [];
  }

  let result = '';
  if (obj.interval_type === intervalTypes[1]) {
    const daysRange = [startDay, ...res, endDay];
    result = `[\"~w{${daysRange.join(',')}};t{${obj.start_time}-${obj.end_time}}~\"]`;
  } else {
    result = `[\"~w{${startDay}};t{${obj.start_time}-23:59}~\", ${
      res.length ? `\"~w{${res.join(',')}};t{00:00-23:59}~\",` : ''
    }\"~w{${endDay}};t{00:00-${obj.end_time}}~\"]`;
  }

  return {
    expression: result
  }
}

function dateConvertToObjectSource(obj) {
  const startDate = obj.start_date
    .toLocaleString()
    .slice(0, 10)
    .split('.')
    .reverse()
    .join('-');
  const endDate = obj.end_date
    .toLocaleString()
    .slice(0, 10)
    .split('.')
    .reverse()
    .join('-');
  return {
    expression: JSON.stringify([`=z{${startDate}T${obj.start_time}:00Z};z{${endDate}T${obj.end_time}:00Z}=`])
  };
}
