import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'utax-currency-select',
  templateUrl: './currency-select.component.html',
  styleUrls: ['./currency-select.component.scss']
})
export class CurrencySelectComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() title: string;
  @Input() controlNames: {
    enable: string;
    value: string;
    type: string;
  };

  currencyValue = {
    natural: '',
    percent: ''
  };
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    // update local values from form
    if (this.form.get(this.controlNames.type).value === 'percent') {
      this.currencyValue.percent = this.form.controls[this.controlNames.value].value;
      this.currencyValue.natural = '';
    } else {
      this.currencyValue.natural = this.form.controls[this.controlNames.value].value;
      this.currencyValue.percent = '';
    }
  }

  switch(onType: string): void {
    this.form.controls[this.controlNames.type].patchValue(onType);
    if (onType === 'natural') {
      this.currencyValue.percent = '';
    } else {
      this.currencyValue.natural = '';
    }
  }

  updateFormValue(event) {
    this.form.get(this.controlNames.value).patchValue(event.target.value);
    this.form.markAsDirty();
  }
}
