import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, forkJoin, Observable, of, Subject, throwError} from 'rxjs';
import { Injectable } from '@angular/core';
import { StateService } from 'src/app/services/state.service';
import { catchError, filter, map, mergeMap, pluck, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { uuidv4 } from 'src/app/utils/uuid';
import { CityCanSearchAddress } from 'src/app/interfaces/state.interface';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { CustomTranslationService } from 'src/app/shared/services';
import * as Sentry from '@sentry/angular';
import { RemoteWorkService } from '../../cabinet/services';
import { RemoteWorkOperatorStatus } from '../../cabinet/models';
import {IAuthData, IAuthOctobusData} from '../../interfaces/auth.interface';
import {HandleEventService} from "@global-services/handle-event-service.service";

const loginUrl = 'operator/login',
  servicesUrl = 'operator/accessible/services',
  menuUrl = 'operator/menu',
  productsUrl = 'operator/products/list',
  timezoneUrl = 'operator/services/timezones',
  whoamiUrl = 'operator/whoami',
  citiesWithAddressesUrl = 'operator/cities', // list of cities with addresses for search
  accessiblePermissionsUrl = 'operator/accessible/permissions',
  closeWorkingShifturl = 'call-centre/api/operators/work-shifts/close',
  authCallCenterLogin = 'call-centre/api/auth/login',
  closeWorkingShifturlNew = 'call-centre/api/me/work-shift/close',
  systemSetting = 'operator/system/settings',
  octobusHttp = '/v1/dispatcher/ws',
  dispatcherToken = 'dispatcher/oauth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authState: BehaviorSubject<boolean>;
  token$: BehaviorSubject<string>;
  callCenterToken$: BehaviorSubject<string>;
  callCenterRefreshToken$: BehaviorSubject<string>;
  authErrors: BehaviorSubject<any>;
  octobusUpdateSubject$: Subject<any> = new Subject<any>();

  constructor(
    private http: HttpClient,
    private stateService: StateService,
    private router: Router,
    private cookieService: CookieService,
    private translate: TranslateService,
    private customTranslationService: CustomTranslationService,
    private handleEventService: HandleEventService,
    private remoteWorkService: RemoteWorkService
  ) {
    let token;
    let callCenterToken;
    let callCenterRefreshToken;
    if (location.hostname === 'localhost' || location.hostname.startsWith('192.168')) {
      token = localStorage.getItem('token');
      callCenterToken = localStorage.getItem('callCenterToken');
      callCenterRefreshToken = localStorage.getItem('callCenterRefreshToken');
    } else {
      token = this.cookieService.get('token');
      callCenterToken = this.cookieService.get('callCenterToken');
      callCenterRefreshToken = this.cookieService.get('callCenterRefreshToken');
    }

    this.authState = new BehaviorSubject(!!token);
    this.token$ = new BehaviorSubject(token);
    this.callCenterToken$ = new BehaviorSubject(callCenterToken);
    this.callCenterRefreshToken$ = new BehaviorSubject(callCenterRefreshToken);
    this.authState
      .pipe(
        filter(state => state),
        switchMap(() => this.remoteWorkService.init())
      )
      .subscribe(data => {
        if (data.status === RemoteWorkOperatorStatus.ready) {
          localStorage.setItem('telephonyConnect', `true`);
          forkJoin([
            this.http.get(servicesUrl),
            this.http.get(menuUrl),
            this.http.get(productsUrl),
            this.http.get(whoamiUrl),
            this.getCities(),
            this.http.get(accessiblePermissionsUrl).pipe(pluck('data')),
            this.http.get(systemSetting).pipe(pluck('data'))
          ])
            .subscribe(([services, menus, products, user, cities, accessiblePermissions, systemSetting]: Array<any>) => {
              Sentry.setUser({id: user.data.id, username: user.data.name});
              if (!localStorage.getItem('service')) {
                if (services.data[0]) {
                  localStorage.setItem('service', services.data[0].id);
                }
              }
              this.stateService.store.next({
                user,
                service: services.data,
                menu: menus.data,
                products: products.data,
                citiesWithAddresses: cities,
                permissions: accessiblePermissions,
                systemSetting
              });
              this.octobusUpdateSubject$.next(true);
              this.onAuth();
            });
          forkJoin([
            this.customTranslationService.getListOfLanguages(),
            this.customTranslationService.getListOfTranslations()
          ])
            .subscribe(([langs, translations]) => {
              this.customTranslationService.langs = langs;
              this.customTranslationService.translations = translations;
              this.customTranslationService.langs$.next(langs);
            });
        } else {
          this.http.get(whoamiUrl)
            .subscribe((user: any) => {
              Sentry.setUser({id: user.data.id, username: user.data.name});
              this.stateService.store.next({user});
              this.onAuth();
            });
        }
      });
  }

  onAuth = () => {
    if (!environment.liteVersion) {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/cabinet']);
    }
  };

  authenticate(credentials: { name: string; password: string; otp: string }) {
    let userInfo;
    if (credentials.otp === '') {
      userInfo = {name: credentials.name, password: credentials.password};
    } else {
      userInfo = credentials;
    }
    return this.http.post(loginUrl, userInfo).pipe(
      mergeMap((res: any) => forkJoin([
        of(res),
        this.callCenterLogin(res.access_token).pipe(catchError((err) => {
          if (environment.liteVersion) {
            return throwError(err);
          } else {
            localStorage.setItem('callCenterActive', 'inActive');
            return of(null);
          }
        }))])),
      map(([res, callCenterAuthResponse]) => {
        // login success
        console.log('TCL: AuthService -> authenticate -> res', res, callCenterAuthResponse);
        if (location.hostname.indexOf(environment.config.domain) + 1) {
          this.cookieService.set('token', res.access_token, 365, '/', environment.config.domain, false, 'Lax');
          this.cookieService.set('callCenterToken', callCenterAuthResponse?.accessToken, 365, '/', environment.config.domain, false, 'Lax');
          this.cookieService.set('callCenterRefreshToken', callCenterAuthResponse?.refreshToken, 365, '/', environment.config.domain, false, 'Lax');
        } else {
          localStorage.setItem('token', res.access_token);
          localStorage.setItem('callCenterToken', callCenterAuthResponse?.accessToken);
          localStorage.setItem('callCenterRefreshToken', callCenterAuthResponse?.refreshToken);
        }
        this.cookieService.set('userSessionId', uuidv4());
        this.authState.next(true);
        this.token$.next('');
        this.callCenterToken$.next('');
        this.callCenterRefreshToken$.next('');
        return res;
      }),
      catchError(err => {
        if (err?.error?.message){
          this.handleEventService.openSnackBar(err.error.message, 5);
        }
        return of(err);
      })
    );
  }

  callCenterLogin(accessToken: string): Observable<IAuthData> {
    return this.http.post<IAuthData>(authCallCenterLogin, { accessToken });
  }
  octobusLogin(): Observable<IAuthOctobusData> {
    return this.http.get<IAuthOctobusData>(`${dispatcherToken}/token`);
  }

  octobusRefresh(body: IAuthOctobusData): Observable<IAuthOctobusData> {
    return this.http.post<IAuthOctobusData>(`${dispatcherToken}/refresh`, body).pipe(catchError((err) => {
      if (err.status === 401 || err.status === 400) {
        return this.octobusLogin().pipe(
          tap(res => {
            localStorage.setItem('octobusToken', res.token);
            localStorage.setItem('octobusRefreshToken', res.refresh_token);
          })
        );
      }
      return throwError(err);
    }));
  }
  octobusCheck(): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.get<any>(environment.config.octobusHttp + octobusHttp, {params: {token: localStorage.getItem('octobusToken')}, headers});
  }

  logout() {
    this.closeWorkingShift().subscribe(() => {
      this.authState.next(false);
      this.cookieService.delete('token', '/', environment.config.domain);
      this.cookieService.delete('callCenterToken', '/', environment.config.domain);
      this.cookieService.delete('callCenterRefreshToken', '/', environment.config.domain);
      const cardItnArr = localStorage.getItem('custom.savedcarditn');
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem('custom.savedcarditn', cardItnArr);
      this.cookieService.deleteAll();
      this.router.navigate(['/login']).then(() => location.reload());
    });
  }

  private closeWorkingShift(): Observable<any> {
    if (this.stateService.telephonyEnterStatus$.value) {
      return this.http.post(closeWorkingShifturlNew, {}).pipe(
        map(() => true),
        catchError(error => {
          if (error.error.status === 401 || error.error.code === 404 ||
            (error.error.status === 400 && error.error.type === 'NO_ACTIVE_WORK_SHIFT')) {
            return of(true);
          } else {
            return throwError(error);
          }
        })
      );
    } else {
      return of(true);
    }
  }

  private getCities(): Observable<Array<any>> {
    return this.http.get(citiesWithAddressesUrl).pipe(
      pluck('data'),
      mergeMap((cities: CityCanSearchAddress[]) => {
        const citiesTranslateReqs = [];

        _.uniqBy(cities, 'key').forEach(city => {
          citiesTranslateReqs.push(
            this.translate.get(city.name).pipe(
              mergeMap(translatedCityName => {
                return of({...city, name: translatedCityName});
              })
            )
          );
        });
        return forkJoin(citiesTranslateReqs);
      })
    );
  }

  getUser(): Observable<any> {
    return this.stateService.getStoreParamSub('user').pipe(
      filter(value => value),
      pluck('data'),
    );
  }

  syncUser(): Observable<any> {
    return this.http.get(whoamiUrl)
      .pipe(
        tap((user: any) => this.stateService.store.next({
          ...this.stateService.store.value,
          user
        })),
        pluck('data'),
      );
  }
}
