import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {BehaviorSubject, Subject, throwError, timer} from 'rxjs';
import {delayWhen, filter, map, retryWhen, takeUntil, tap} from 'rxjs/operators';
import { YesNoModalComponent } from 'src/app/shared/components';
import { BrandingType, BrandingTypeScale } from '../../models/branding.model';
import { Tariff } from '../../models/tariff.model';
import { BrandingApiService } from '../../services/branding-api.service';
import { brandingScalesRepeatValidator } from '../../utils/validators';
import {StateService} from "@global-services/state.service";

@Component({
  selector: 'utax-branding-tab',
  templateUrl: './branding-tab.component.html',
  styleUrls: ['./branding-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrandingTabComponent implements OnInit, OnDestroy {
  public brandingViewMode: 'monthly' | 'weekly' = 'monthly';

  @Input('selectedTabIndex')
  set selectedTabIndex(index) {
    this.tabIndex = index;
    this.getBrandingTypes();
    this.getScales(this.brandingViewMode);
  }

  @Input('tariff')
  set setTariff(tariff: Tariff) {
    this.tariff = tariff;
  }

  @Input('service')
  set setService(service: number) {
    this.service = service;
    this.scales = undefined;
    this.getScales(this.brandingViewMode);
  }

  brandingForm: UntypedFormGroup;
  scalesForm: UntypedFormGroup;

  service: number;

  selectedBrandingIndex = 0;

  brandingTypeName$: BehaviorSubject<string> = new BehaviorSubject('');
  brandingTypeId$: BehaviorSubject<string> = new BehaviorSubject('');

  private tariff: Tariff;
  private tabIndex: number;
  brandingTypes: BrandingType[];
  private scales: BrandingTypeScale[];
  public allowEditScales = false;
  public allowRemoveScales = false;

  private dialogRef: MatDialogRef<YesNoModalComponent>;
  private componentDestroyed$ = new Subject();

  constructor(
    private brandingApiService: BrandingApiService,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private stateService: StateService
  ) {}

  get brandingTypesFormArray(): UntypedFormArray {
    if (this.brandingForm) {
      return this.brandingForm.controls.types as UntypedFormArray;
    }
  }

  get scalesFormArray(): UntypedFormArray {
    if (this.scalesForm) {
      return this.scalesForm.controls.scales as UntypedFormArray;
    }
  }

  ngOnInit(): void {
    this.stateService.store
      .pipe(
        map(store => {
          if (!store) {
            throw new Error('No permissions');
          }
          return store;
        }),
        retryWhen(errors =>
          errors.pipe(
            delayWhen(val => timer(1000))
          )
        ),
        tap((store) => {
          this.allowEditScales = store.permissions.some(p => p.name === 'operator_taxi_service_branding_scale_create') &&
            store.permissions.some(p => p.name === 'operator_taxi_service_branding_scale_update');
          this.allowRemoveScales = store.permissions.some(p => p.name === 'operator_taxi_service_branding_scale_delete');
        }),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe();
    this.brandingApiService.brandingTypes$
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(types => types.length > 0)
      )
      .subscribe(types => {
        this.selectedBrandingIndex = 0;
        this.brandingTypeName$.next(types[0].name);
        this.brandingTypeId$.next(types[0].id);
        this.brandingTypes = types;
        this.buildTypesForm(types);
      });
    this.brandingApiService.brandingTypeScales$.pipe(takeUntil(this.componentDestroyed$)).subscribe(scales => {
      this.scales = scales;
      this.buildScalesForm(scales);
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  addBrandingType(): void {
    this.brandingTypesFormArray.push(this.buildTypeFormGroup());
  }

  selectBrandingType(index: number): void {
    this.selectedBrandingIndex = index;
    this.brandingTypeName$.next(this.brandingTypesFormArray.value[index].name);
    this.brandingTypeId$.next(this.brandingTypesFormArray.value[index].id);
  }

  saveBrandingType(index: number): void {
    const brandingTypeValue = this.brandingTypesFormArray.controls[index].value;
    if (brandingTypeValue.id) {
      if (this.brandingTypes[index].name !== brandingTypeValue.name) {
        this.brandingApiService.putBrandingType(brandingTypeValue.name, brandingTypeValue.id);
      } else {
        (this.brandingTypesFormArray.controls[index] as UntypedFormGroup).controls.mode.patchValue('show');
      }
    } else {
      this.brandingApiService.postBrandingType(brandingTypeValue.name);
    }
  }

  deleteBrandingType(index: number): void {
    const brandingTypeValue = this.brandingTypesFormArray.controls[index].value;
    if (brandingTypeValue.id) {
      const config = new MatDialogConfig();
      config.data = {
        title: `UTAX_FRONT_DELETE_BRANDING_TYPE`,
        extra: brandingTypeValue.name
      };
      config.panelClass = 'yes-no-modal-container';
      this.dialogRef = this.dialog.open(YesNoModalComponent, config);
      this.dialogRef
        .afterClosed()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(res => {
          if (res === 'YES') {
            this.brandingApiService.deleteBrandingType(brandingTypeValue.id);
          }
        });
    } else {
      this.brandingTypesFormArray.removeAt(index);
    }
  }

  editBrandingType(index: number): void {
    (this.brandingTypesFormArray.controls[index] as UntypedFormGroup).controls.mode.patchValue('edit');
  }

  saveScale(index: number): void {
    const scaleValue = (this.scalesFormArray.controls[index] as UntypedFormGroup).getRawValue();
    const brandingTypeId = this.brandingTypesFormArray.controls[this.selectedBrandingIndex].value.id;
    if (scaleValue.id) {
      this.brandingApiService.putBrandingScale({
        id: scaleValue.id,
        branding_type_id: brandingTypeId,
        requests_quantity: scaleValue.requests_quantity,
        value: scaleValue.value,
        is_enabled: scaleValue.is_enabled ? scaleValue.is_enabled : false,
        period_type: this.brandingViewMode
      });
    } else {
      this.brandingApiService.postBrandingScale({
        requests_quantity: scaleValue.requests_quantity,
        value: scaleValue.value,
        branding_type_id: brandingTypeId,
        tariff_plan_id: this.tariff.id,
        is_enabled: scaleValue.is_enabled ? scaleValue.is_enabled : false,
        period_type: this.brandingViewMode,
        service_id: this.service
      });
    }
  }

  deleteScale(index: number): void {
    const scaleValue = this.scalesFormArray.controls[index].value;
    if (scaleValue.id) {
      const config = new MatDialogConfig();
      config.data = {
        title: `UTAX_FRONT_DELETE_BRANDING_TYPE_SCALE`
      };
      config.panelClass = 'yes-no-modal-container';
      this.dialogRef = this.dialog.open(YesNoModalComponent, config);
      this.dialogRef
        .afterClosed()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(res => {
          if (res === 'YES') {
            this.brandingApiService.deleteScale(scaleValue.id);
          }
        });
    } else {
      this.scalesFormArray.removeAt(index);
    }
  }

  addScale(): void {
    this.scalesFormArray.push(
      this.buildScaleFormGroup({
        branding_type: { id: this.brandingTypesFormArray.controls[this.selectedBrandingIndex].value.id },
        period_type: this.brandingViewMode
      })
    );
  }

  private getBrandingTypes(): void {
    if (this.tabIndex === 9 && !this.brandingTypes) {
      this.brandingApiService.getBrandingTypes();
    }
  }

  private getScales(viewMode =  'monthly'): void {
    if (this.tabIndex === 9 && this.service) {
      this.brandingApiService.getBrandingScalesByService(this.service, viewMode);
    }
  }

  // form building
  private buildTypeFormGroup(type?: BrandingType): UntypedFormGroup {
    return this.fb.group({
      name: [type && type.name ? type.name : '', [Validators.required]],
      id: [type && type.id ? type.id : ''],
      mode: [type ? 'show' : 'edit'] // edit | show
    });
  }

  private buildTypesForm(types: BrandingType[]): void {
    this.brandingForm = this.fb.group({
      types: this.fb.array([])
    });
    types.forEach(type => {
      this.brandingTypesFormArray.push(this.buildTypeFormGroup(type));
    });
    this.cd.detectChanges();
  }

  private buildScaleFormGroup(scale?: any): UntypedFormGroup {
    return this.fb.group({
      id: [scale && scale.id ? scale.id : ''],
      branding_type_id: [scale && scale.branding_type ? scale.branding_type.id : ''],
      is_enabled: [scale ? scale.is_enabled : false],
      requests_quantity: [
        scale && scale.requests_quantity ? scale.requests_quantity : 0,
        [Validators.required, Validators.min(0)]
      ],
      period_type: [scale && scale.period_type ? scale.period_type : 'monthly'],
      value: [scale && scale.value ? scale.value : 0, [Validators.required, Validators.min(0)]]
    });
  }

  private buildScalesForm(scales: BrandingTypeScale[]): void {
    this.scalesForm = this.fb.group({
      scales: this.fb.array([], [brandingScalesRepeatValidator()])
    });
    scales.forEach(scale => {
      this.scalesFormArray.push(this.buildScaleFormGroup(scale));
    });
    this.scalesForm.valueChanges.subscribe(() => {
      console.log('this.scalses', this.scalesForm);
    });
    this.cd.detectChanges();
  }

  public changeBrandingViewMode(event: 'monthly' | 'weekly'): void {
    this.brandingViewMode = event;
    this.getScales(this.brandingViewMode);
  }

  saveAllScales(): void {
    this.scalesFormArray.controls.forEach((group: UntypedFormGroup, index: number) => {
      if (group.touched) {
        this.saveScale(index);
      }
    });
  }
}
