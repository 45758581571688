import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Optional, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';

interface YesNoButtons {
  yes: { title: string; disabled: boolean };
  no: { title: string; disabled: boolean };
}

@Component({
  selector: 'utax-yes-no-modal',
  templateUrl: './yes-no-modal.component.html',
  styleUrls: ['./yes-no-modal.component.scss']
})
export class YesNoModalComponent implements AfterViewInit {
  @ViewChild('btnYes', { static: true }) btnYes: MatButton;
  @ViewChild('btnNo', { static: true }) btnNo: MatButton;

  shortcuts: ShortcutInput[] = [];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { title: string; extra?: string; buttons?: YesNoButtons },
    public dialogRef: MatDialogRef<YesNoModalComponent>
  ) {}

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: ['left'],
        command: e => {
          this.changeBtnToNo();
        },
        allowIn: [AllowIn.Input, AllowIn.Select],
        preventDefault: false
      },
      {
        key: ['right'],
        command: e => {
          this.changeBtnToYes();
        },
        allowIn: [AllowIn.Input, AllowIn.Select],
        preventDefault: false
      },
      {
        key: ['enter'],
        command: e => {
          const result = this.btnYes._elementRef.nativeElement.focused ? 'YES' : 'NO';
          this.dialogRef.close(result);
        },
        allowIn: [AllowIn.Input, AllowIn.Select],
        preventDefault: false
      },
      {
        key: ['esc'],
        command: e => {
          this.dialogRef.close('NO-ESC');
        },
        allowIn: [AllowIn.Input, AllowIn.Select],
        preventDefault: true
      }
    );
  }

  cancel(): void {
    this.dialogRef.close('NO');
  }

  confirm(): void {
    this.dialogRef.close('YES');
  }

  changeBtnToNo(): void {
    this.btnNo.focus();
  }

  changeBtnToYes(): void {
    this.btnYes.focus();
  }
}
