import { ArchiveDetailsInfoComponent } from './archive-details-info/archive-details-info.component';
import { ArchiveTableItemComponent } from './archive-table-item/archive-table-item.component';
import { ArchiveDetailsToolbarComponent } from './archive-details-toolbar/archive-details-toolbar.component';
import { ArchiveMapComponent } from './archive-map/archive-map.component';
import { ArchiveHistoryComponent } from './archive-history/archive-history.component';


export const components: any[] = [
  ArchiveMapComponent,
  ArchiveDetailsToolbarComponent,
  ArchiveTableItemComponent,
  ArchiveDetailsInfoComponent,
  ArchiveHistoryComponent
];

export * from './archive-details-info/archive-details-info.component';
export * from './archive-table-item/archive-table-item.component';
export * from './archive-details-toolbar/archive-details-toolbar.component';
export * from './archive-map/archive-map.component';
export * from './archive-history/archive-history.component';
