import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromContainers from './containers';
import * as fromComponents from './components';
import * as fromServices from './services';

import { MaterialModule } from 'src/app/utils/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [...fromContainers.containers, ...fromComponents.components],
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
        SharedModule,
        ReactiveFormsModule,
        KeyboardShortcutsModule,
        FormsModule,
        NgxMaskModule.forChild(),
        RouterModule,
        InfiniteScrollModule
    ],
  exports: [...fromContainers.containers, ...fromComponents.components],
  providers: [...fromServices.services]
})
export class CabinetModule {
}
