import { OrdersService } from './../../services/orders.service';
import { WebsocketService } from 'src/app/services';
import { HistoryService } from './../../services/history.service';
import { OrderFormComponent } from './../../order-form/containers/order-form/order-form.component';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { HandleEventService } from '../../services/handle-event-service.service';
import { LogistOrdersService } from './logist-orders.service';
import { UnassignCabModalComponent } from '../../shared/components/modals/unassign-cab/unassign-cab-modal.component';
import { Order } from 'src/app/interfaces/order.interface';
import { RemoveOrderComponent } from 'src/app/shared/components/modals/remove-order/remove-order.component';
import { OrderModalService } from 'src/app/order-form/services';
import { GeneralOrdersApiService } from 'src/app/services/general-orders-api.service';
import { LogistCabsService } from './logist-cabs.service';

const acceptOrder = 'operator/offer/accept',
  rejectOrder = 'operator/offer/reject',
  tripArrived = 'operator/trips/arrived',
  tripPickUp = 'operator/trips/pickup',
  tripDequeue = 'operator/trips/dequeue';

@Injectable({
  providedIn: 'root'
})
export class OrderApiService {
  selectedOrder;
  selectedOrder$ = new Subject();

  dialogRef: MatDialogRef<UnassignCabModalComponent | OrderFormComponent | RemoveOrderComponent>;

  private currentlySelectedOrderIndex;

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private cabAssignService: GeneralOrdersApiService,
    private handleEventService: HandleEventService,
    private logistOrdersService: LogistOrdersService,
    private historyService: HistoryService,
    private wsService: WebsocketService,
    private ordersService: OrdersService,
    private orderModalService: OrderModalService,
    private logistCabsService: LogistCabsService
  ) {}

  selectOrder(order) {
    if (order) {
      console.log('selectedOrder', order);
      this.selectedOrder$.next(order);
    } else {
      this.selectedOrder$.next(null);
      this.historyService.orderHistory$.next([]);
    }
    this.selectedOrder = order;
  }

  changeOrder(direction: string) {
    this.setCurrentlySelectedOrderIndex();

    if (this.currentlySelectedOrderIndex === undefined) {
      this.selectOrder(this.logistOrdersService.ordersToShow[0]);
      this.currentlySelectedOrderIndex = 0;
    } else if (
      direction === 'next' &&
      this.currentlySelectedOrderIndex + 1 !== this.logistOrdersService.ordersToShow.length
    ) {
      this.selectOrder(this.logistOrdersService.ordersToShow[this.currentlySelectedOrderIndex + 1]);
    } else if (direction === 'previos' && this.currentlySelectedOrderIndex !== 0) {
      this.selectOrder(this.logistOrdersService.ordersToShow[this.currentlySelectedOrderIndex - 1]);
    }
  }

  private setCurrentlySelectedOrderIndex() {
    this.logistOrdersService.ordersToShow.forEach((order: Order, index) => {
      if (this.selectedOrder !== undefined && this.selectedOrder !== null && this.selectedOrder.id === order.id) {
        this.currentlySelectedOrderIndex = index;
      }
    });
  }

  assignOrderToCab(order, cab) {
    this.assignCab({
      cab_id: cab.id,
      id: order.id,
      service: order.service_id
    }).subscribe({
      next: (response: any) => {
        console.log('🚀 ~ 1 ~ assignCab ~ response', response);
        // request completed successfully
        if (response.data) {
          this.logistOrdersService.ordersList = this.logistOrdersService.ordersList.map(ordItem => {
            if (ordItem.id === response.data.id) {
              return response.data;
            }
            return ordItem;
          });
          this.logistOrdersService.rebuildList();

          this.handleEventService.openSnackBar('Назначение водителю отправлено', 3);
        }
      },
      error: err => {
        if (err?.error?.code === 'working_shift_is_not_active') {
          this.handleEventService.openSnackBar('UTAX_FRONT_CAB_SHIFT_IS_NOT_ACTIVE');
        } else {
          this.handleEventService.openSnackBar(`Oшибка назначения ${JSON.stringify(err.error.message)}`, 3);
        }
      }
    });
  }

  unassignCab() {
    // if ()
    this.ordersService.unassignCab(this.selectedOrder.trip_id, this.selectedOrder.cab.callsign);
  }

  removeOrder() {
    if (this.selectedOrder) {
      this.ordersService.getRemoveOrderReason(this.selectedOrder.id).subscribe((res: any) => {
        const config = new MatDialogConfig();
        config.data = {
          reasons: res.data,
          id: this.selectedOrder.id
        };
        config.panelClass = 'remove-order-modal-container';
        config.width = '40vw';

        this.dialogRef = this.dialog.open(RemoveOrderComponent, config);
        this.ordersService.modalOpened = true;
        this.dialogRef.afterClosed().subscribe(data => {
          if (data.id) {
            this.ordersService.modalOpened = false;
            this.ordersService.removeOrderReq(data).subscribe(
              (response: any) => {
                this.logistCabsService.removeOfferFromCab(response.data.id); // remove offer from cab by order id
                this.logistOrdersService.removeOrderFromOrderLists(response.data);
                this.selectOrder(this.logistOrdersService.ordersToShow[0]);
                this.handleEventService.openSnackBar('Заказ отменен успешно', 3);
              },
              err => {
                this.handleEventService.openSnackBar(err.message, 3);
              }
            );
          }
          this.dialogRef = null;
          this.ordersService.modalOpened = false;
        });
      });
    }
  }

  editOrder(order) {
    this.dialogRef = this.orderModalService.editOrder(order);
    this.logistOrdersService.closeOrderForm$.subscribe(value => {
      if (value) {
        this.dialogRef.close();
      }
    });
  }

  assignCab(obj: { cab_id: string; id: string; service: number }) {
    return this.cabAssignService.assignCab(obj);
  }

  acceptOrder(id) {
    return this.http.post(acceptOrder, { id });
  }

  rejectOrder(id) {
    return this.http.post(rejectOrder, { id });
  }

  tripArrived(tripId) {
    return this.http.post(tripArrived, { id: tripId });
  }

  tripPickUp(tripId) {
    return this.http.post(tripPickUp, { id: tripId });
  }

  tripDequeue(tripId): Observable<any> {
    return this.http.post(tripDequeue, { id: tripId });
  }

  holdUnholdOrder(): Observable<any> {
    if (this.selectedOrder) {
      let isHoldString = '';
      this.selectedOrder.is_holding ? (isHoldString = 'unhold') : (isHoldString = 'hold');

      return this.ordersService.holdUnholdOrder(this.selectedOrder.id, isHoldString);
    } else {
      return of(false);
    }
  }
}
