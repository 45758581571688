import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { TariffFormsGenerationService, DriverTariffsService } from '../../services';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { YesNoModalComponent } from 'src/app/shared/components';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { PeriodicBonus } from '../../models/bonus.model';
import { HeaderToolsHandlerService } from 'src/app/navigation/services';
import {DateAdapter} from '@angular/material/core';

@Component({
  selector: 'utax-new-stocks-tab',
  templateUrl: './new-stocks-tab.component.html',
  styleUrls: ['./new-stocks-tab.component.scss']
})
export class NewStocksTabComponent implements OnInit, OnDestroy {
  @Input() tariffId: number;
  @Input() form: UntypedFormGroup;
  @Input() operation: 'sub' | 'add';
  @Input() bonuses: PeriodicBonus[];

  ordersType = ['all', 'automat', 'sending'];

  private dialogRef: MatDialogRef<YesNoModalComponent>;
  private componentDestroyed$ = new Subject();

  constructor(
    private tariffFormsService: TariffFormsGenerationService,
    private dialog: MatDialog,
    private driverTariffsService: DriverTariffsService,
    private headerToolsHandlerService: HeaderToolsHandlerService,
    private dateAdapter: DateAdapter<any>
  ) {
    this.headerToolsHandlerService.globalLanguage$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(lang => {
        if (lang === 'ua') {
          lang = 'uk';
        }
        this.dateAdapter.setLocale(lang);
      });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  get periodicBonusesArray(): UntypedFormArray {
    return this.form.controls.bonuses as UntypedFormArray;
  }

  getCompleted(index: number): boolean {
    const bonus = this.bonuses[index];
    if (!bonus) {
      return false;
    }
    return this.bonuses[index].is_completed;
  }

  addNewBonus(): void {
    this.tariffFormsService.addEmptyFormToPeriodicBonusesArray();
  }

  onRemoveBonus(index: number): void {
    const bonusId = this.periodicBonusesArray.controls[index].value.id;
    if (bonusId) {
      const config = new MatDialogConfig();
      config.data = {
        title: 'UTAX_FRONT_DO_YOU_REALY_WANT_TO_REMOVE_PERIODIC_BONUS?'
      };
      config.panelClass = 'yes-no-modal-container';
      this.dialogRef = this.dialog.open(YesNoModalComponent, config);
      this.dialogRef
        .afterClosed()
        .pipe(
          takeUntil(this.componentDestroyed$),
          mergeMap(data => {
            if (data === 'YES') {
              return this.driverTariffsService.deletePeriodicBonus(bonusId);
            } else {
              return of();
            }
          })
        )
        .subscribe((res: any) => {
          this.bonuses.splice(index, 1);
          this.periodicBonusesArray.removeAt(index);
        });
    } else {
      this.bonuses.splice(index, 1);
      this.periodicBonusesArray.removeAt(index);
    }
  }

  setFormValueField(event: { key: string; value: string }, name: string, index: number): void {
    (this.periodicBonusesArray.controls[index] as UntypedFormGroup).controls[name].patchValue(event.key);
    (this.periodicBonusesArray.controls[index] as UntypedFormGroup).markAllAsTouched();
  }
}
