export interface SortOption<T> {
  priority?: number;
  order: number;
  dividerAfter?: boolean;
  chracteristic: (item: T) => boolean;
  comparator: (a: T, b: T) => number;
}

export function ClusterSort<T>(
  items: Array<T>,
  options: Array<SortOption<T>>
): Array<T> {
  let filteredItems = items || [];
  return options
    .sort((a: SortOption<T>, b: SortOption<T>) => b.priority - a.priority)
    .map((option: SortOption<T>) => {
      const cluster = {
        items: filteredItems.filter(option.chracteristic),
        ...option
      };
      filteredItems = filteredItems.filter(item => !option.chracteristic(item));
      return cluster;
    })
    .sort((a: SortOption<T>, b: SortOption<T>) => a.order - b.order)
    .map(el => {
      el.items.sort(el.comparator);
      el.items.forEach(item => (item['dividerAfter'] = false));
      if (el.dividerAfter && el.items[el.items.length - 1]) {
        el.items[el.items.length - 1]['dividerAfter'] = true;
      }
      return el.items;
    })
    .flat();
}
