import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { WebsocketService } from './ws.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/services';

@Injectable({
  providedIn: 'root'
})
export class TaxiServicesService {
  TaxiServicesList = [];

  servicesSettings: any = {};
  servicesSettings$: BehaviorSubject<any> = new BehaviorSubject([]);

  productsList: any = {};

  constructor(private ws: WebsocketService, private http: HttpClient, private authService: AuthService) {}

  createSubscription() {
    this.authService.authState.subscribe(authenticated => {
      if (authenticated) {
        const mirandaConnection = this.ws.openConnection('miranda');

        this.http.get('operator/accessible/services').subscribe((res: any) => {
          this.TaxiServicesList = res.data;
          res.data.forEach(service => {
            if (!this.servicesSettings[service.id]) {
              this.servicesSettings[service.id] = {};
            }
            service.settings.forEach(setting => {
              this.servicesSettings[service.id][setting.key] = setting.value;
            });
          });
          this.servicesSettings$.next(this.servicesSettings);
        });

        mirandaConnection.on('sets_list').subscribe(msg => {
          if (msg.header.action === 'rcv') {
            for (const serviceInfo of msg.data) {
              for (const setting of serviceInfo.settings) {
                this.servicesSettings[serviceInfo.service_id] = {
                  ...this.servicesSettings[serviceInfo.service_id],
                  [setting.key]: setting.value
                };
              }
              this.productsList[serviceInfo.service_id] = serviceInfo.products.sort((a, b) => {
                return a.display_order - b.display_order;
              });
            }
            this.servicesSettings$.next(this.servicesSettings);
          }
        });

        mirandaConnection.on('single_sets').subscribe(msg => {
          if (msg.header.action === 'update' && msg.data.service_id) {
            this.servicesSettings[msg.data.service_id][msg.data.key] = msg.data.value;
            this.servicesSettings$.next(this.servicesSettings);
          }


          if (msg.header.action === 'update' && msg.header.data_id === 'product') {
            const updatedProduct = msg.data.product;
            const products = this.productsList[updatedProduct.service_id];

            if (products) {
              this.productsList[updatedProduct.service_id] = products.map(product => {
                if (product.id === updatedProduct.id) {
                  return {
                    ...updatedProduct,
                    show_in_list: product.show_in_list, // Do not update show_in_list value
                  };
                } else {
                  return product;
                }
              });

              this.productsList[updatedProduct.service_id].sort((a, b) => {
                return a.display_order - b.display_order;
              });
            }
          }
        });

        // create subscription for taxi-service settings
        mirandaConnection.createSub({
          data: {
            arguments: {
              single_sets: {
                reuse: false
              }
            },
            subscriptions: ['single_sets']
          },
          header: { action: 'set', type: 'sub' }
        });
      }
    });
  }

  getInfoAboutServices(servicesIds: string[]): Observable<any> {
    const idsStr = servicesIds.join(',');
    return this.http.get(`AUTH/${environment.config.estimate}/services_stats`, { params: { sids: idsStr } });
  }
}
