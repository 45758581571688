import * as L from 'leaflet';
import { Map } from 'leaflet';
import { Service } from 'src/app/interfaces/order.interface';

export function createMarker(location, map, markerIcon, iconType?: 'html' | any, zIndexOffset = 0) {
  let icon;
  if (iconType === 'html') {
    icon = L.divIcon({
      iconSize: null,
      className: '',
      html: markerIcon
    });
  } else {
    icon = L.icon({
      iconUrl: markerIcon,
      iconSize: [15, 21],
      iconAnchor: [15, 21]
    });
  }
  return L.marker(location, { icon, zIndexOffset }).addTo(map);
}

export function createPoly(polyline, map, options = {}) {
  const defOptions = {};
  return L.polyline(decode(polyline), { ...defOptions, ...options }).addTo(map);
}

export function decode(encoded) {
  if (!encoded) {
    return [];
  }
  const points = [];
  let index = 0;
  const len = encoded.length;
  let lat = 0,
    lng = 0;
  while (index < len) {
    let b,
      shift = 0,
      result = 0;
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);

    const dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
    lat += dlat;
    shift = 0;
    result = 0;
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    const dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
    lng += dlng;

    points.push([lat / 1e5, lng / 1e5]);
  }
  return points;
}

function toRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

function toDegrees(radians) {
  return (radians * 180) / Math.PI;
}

export function bearing(startLat, startLng, destLat, destLng) {
  startLat = toRadians(startLat);
  startLng = toRadians(startLng);
  destLat = toRadians(destLat);
  destLng = toRadians(destLng);

  const y = Math.sin(destLng - startLng) * Math.cos(destLat);
  const x =
    Math.cos(startLat) * Math.sin(destLat) - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
  let brng = Math.atan2(y, x);
  brng = toDegrees(brng);
  return (brng + 360) % 360;
}

export function serviceScale(map: Map, service: Service): void {
  if (service && service.city.boundary && map) {
    const bounds = L.latLngBounds(decode(service.city.boundary.polyline));
    if (bounds.isValid()) {
      map.fitBounds(bounds);
    }
  }
}
