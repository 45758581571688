import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/utils/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedModule } from 'src/app/shared/shared.module';

import * as fromContainer from './containers';
import * as fromComponents from './components';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";

@NgModule({
  declarations: [...fromContainer.containers, ...fromComponents.components],
    imports: [
        CommonModule,
        TranslateModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        InfiniteScrollModule,
        SharedModule,
        KeyboardShortcutsModule,
        NgxMatSelectSearchModule
    ]
})
export class RolesModule {}
