import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {WebsocketService} from "./ws.service";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {AuthService} from "../auth/services";
import {BalanceInterface} from "../interfaces/balance.interface";
import {ModifierObjInterface} from "../dispatcher/models/active-modifier.interface";


@Injectable({
  providedIn: 'root'
})
export class BalanceService {

  public balance$: BehaviorSubject<BalanceInterface> = new BehaviorSubject<BalanceInterface>(null);

  constructor(private ws: WebsocketService, private http: HttpClient, private authService: AuthService) {
  }

  createSubscription() {
    this.authService.authState.subscribe(authenticated => {
      if (authenticated) {
        const mirandaConnection = this.ws.openConnection('miranda');
        mirandaConnection.on('user_balance').subscribe(msg => {
          if (msg.header.action === 'rcv') {
            this.balance$.next(msg.data);
          } else if (msg.header.action === 'update') {
            this.balance$.next({...this.balance$.value, ...msg.data});
          }
        });

        // create subscription for balance
        mirandaConnection.createSub({
          data: {
            arguments: {},
            subscriptions: ['user_balance']
          },
          header: { action: 'set', type: 'sub' }
        });
      }
    });
  }

  getCurrentModifier(): Observable<HttpResponse<ModifierObjInterface>>  {
    return this.http.get<ModifierObjInterface>('call-centre/api/salary-multipliers/active', {observe: 'response'});
  }
}
