import { ServiceFilter } from './../../models/dispatcher';
import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'utax-dispatcher-service-filter',
  templateUrl: './dispatcher-service-filter.component.html',
  styleUrls: ['./dispatcher-service-filter.component.scss']
})
export class DispatcherServiceFilterComponent implements OnInit, OnChanges {
  @Output() toggled = new EventEmitter();
  @Output() doubleClickOnService = new EventEmitter();
  @Input() serviceFilter: ServiceFilter;
  @Input() ordersCount;
  @Input() remoted;
  @Input() cantBlockOperator: boolean; // if true operator cant stand from PC
  @Input('waitingCallsNumber') // info about calls in waiting
  set border(waitingCallsNumber) {
    if (waitingCallsNumber <= 2) {
      this.borderType = null;
    } else if (waitingCallsNumber > 2 && waitingCallsNumber <= 7) {
      this.borderType = 'thin';
    } else if (waitingCallsNumber > 7) {
      this.borderType = 'bold';
    } else {
      this.borderType = null;
    }
    // console.log('disp-serv-filters this.borderType', this.borderType, waitingCallsNumber);
  }

  borderType: string;
  constructor() {}

  ngOnInit() {}

  ngOnChanges() {}

  toggleCheckbox() {
    this.toggled.emit({
      id: this.serviceFilter.id,
      status: this.serviceFilter.status
    });
  }

  offAllServices() {
    this.doubleClickOnService.emit(this.serviceFilter.id);
  }
}
