import { Component, Output, Input, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'utax-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Output() toggle = new EventEmitter();
  @Input() theme: string;
  @Input() titleText: string;
  @Input() subtitle: string;
  @Input() isButton: any = true;
  @Input() value: any = false;
  @Input() disabled = false;
  @Input() minWidth = '170';
  @Input() loading = false;
  className;

  ngOnInit() {
    this.className = this.theme;
  }

  itemClick() {
    if (!this.disabled) {
      if (!this.isButton && this.theme !== 'warn') {
        this.className = this.value ? 'primary' : 'accent';
        this.value = !this.value;
        this.toggle.emit(this.value);
      } else {
        this.toggle.emit(true);
      }
    }
  }
}
