import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class PaymentService {
  constructor(private http: HttpClient) {}

  getPaymentMethod(userId, phone, type): Observable<any> {
    return this.http.post(`payment#operator/passenger/${userId}/payment_method`, { phone, payment_method_type: type });
  }
}
