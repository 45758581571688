import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedWorkerService {

  public sharedWorker: SharedWorker = new SharedWorker('shared-worker.worker.js', {type: 'classic', name: 'utax.shared-worker'});
  constructor() { }

  public startSharedWorker() {
    this.sharedWorker.port.start();
  }

  public sendMessageSharedWorker(message) {
    this.sharedWorker.port.postMessage(message);
  }


}
