import {ChangeDetectionStrategy, Component, Inject, OnInit, Optional} from '@angular/core';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormBuilder} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {HandleEventService} from "../../../../../../services";
import {finalize} from "rxjs/operators";
import {DispSettingsApiService} from "../../../services";

@UntilDestroy()
@Component({
  selector: 'utax-clear-scale-calls-modal',
  templateUrl: './clear-scale-calls-modal.component.html',
  styleUrls: ['./clear-scale-calls-modal.component.scss']
})
export class ClearScaleCallsModalComponent implements OnInit {

  public isLoading = false;


  constructor(public dialogRef: MatDialogRef<ClearScaleCallsModalComponent>,
              private fb: UntypedFormBuilder,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              private dispSettingsApiService: DispSettingsApiService,
              private translateService: TranslateService,
              private handleEventService: HandleEventService) { }

  ngOnInit(): void {
  }

  clear() {
    this.isLoading = true;
    this.dispSettingsApiService.clearDispatcherScaleCalls(this.data.id)
      .pipe(
        untilDestroyed(this),
        finalize(() => this.isLoading = false)
      )
      .subscribe(() => {
        this.handleServiceEvent(
          this.translateService.instant(
            'UTAX_SCALE_CALLS_CLEAR_SUCCESS')
        );
        this.dialogRef.close();
      });
  }

  private handleServiceEvent(msg) {
    this.handleEventService.openSnackBar(msg);
  }

}
