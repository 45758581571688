import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ICallsStatisticRealtime,
  IOperatorStatisticTaxiServiceCounters
} from '../../../models';

@Component({
  selector: 'utax-disp-settings-disp-queue-list',
  templateUrl: './disp-settings-disp-queue-list.component.html',
  styleUrls: ['./disp-settings-disp-queue-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispSettingsDispQueueListComponent implements OnInit {
  @Input('queueGroupsList')
  set setQueueGroupsList(queueGroupsList) {
    this.queueGroupsList = queueGroupsList;
    this.updateQueues();
  }

  @Input('queuesStatistic')
  set setQueuesStatistic(queuesStatistic: IOperatorStatisticTaxiServiceCounters[]) {
    this.queuesStatistic = queuesStatistic;
    this.updateQueues();
  }

  @Input('queueMissedStatistic')
  set setQueueMissedStatistic(queueMissedStatistic: ICallsStatisticRealtime[]) {
    this.queueMissedStatistic = queueMissedStatistic || [];
    if (this.queueMissedStatistic && this.queueMissedStatistic.length) {
      this.updateQueues();
    }
  }

  @Input() queues = [];
  @Input() selectedQueueId;
  @Output() selectQueue = new EventEmitter();

  queuesStat = [];
  private queueGroupsList: any[];
  private queuesStatistic: IOperatorStatisticTaxiServiceCounters[] = [];
  private queueMissedStatistic: ICallsStatisticRealtime[] = [];
  private queueAllObj = {
    id: 'all',
    displayName: 'UTAX_ALL_QUEUES',
    isInternal: true,
    isStatic: true
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  private updateQueues(): void {
    const newQueueStat = [];
    if (this.queueGroupsList?.length > 0) {
      this.queueGroupsList.forEach((queue) => {
        const stats = (this.queuesStatistic || []).find(queueStat => queueStat.taxiServiceId === queue.id);
        let statsRealtime = (this.queueMissedStatistic || []).find(v => v.taxiServiceId === queue.id);
        if (!statsRealtime) {
          statsRealtime = {
            answeredFromCompanyDrivers: 0,
            answeredFromDrivers: 0,
            answeredFromNewClients: 0,
            answeredFromPassengers: 0,
            missedFromCompanyDrivers: 0,
            missedFromDrivers: 0,
            missedFromNewClients: 0,
            missedFromPassengers: 0,
            recentFromCompanyDrivers: 0,
            recentFromDrivers: 0,
            recentFromNewClients: 0,
            recentFromPassengers: 0,
            recentTotalWaitDurationFromCompanyDrivers: 0,
            recentTotalWaitDurationFromDrivers: 0,
            recentTotalWaitDurationFromNewClients: 0,
            recentTotalWaitDurationFromPassengers: 0,
            taxiServiceId: 'string',
            waitingFromCompanyDrivers: 0,
            waitingFromDrivers: 0,
            waitingFromNewClients: 0,
            waitingFromPassengers: 0
          };
        }
        if (stats) {
          newQueueStat.push({
            ...queue,
            missed: {
              color: 'white',
              inProgressMissedCalls: (statsRealtime?.missedFromPassengers + statsRealtime?.missedFromNewClients) || 0,
              totalMissedCalls: statsRealtime?.missedFromDrivers || 0
            },
            passWait: {
            color: this.getWaitToTalkDurationColor(this.getMissedPassengerStats(statsRealtime)),
            waitToTalkDuration: this.getMissedPassengerStats(statsRealtime) ?? '-'
            },
            driverWait: {
            color: this.getWaitToTalkDurationColor(this.getMissedDriverStats(statsRealtime)),
            waitToTalkDuration: this.getMissedDriverStats(statsRealtime) ?? '-'
            },
            pass: {
              color: this.getColorOfStat((statsRealtime?.waitingFromPassengers + statsRealtime?.waitingFromNewClients),
                stats.receiveCallsFromPassengers
              ),
              inProgressPassCalls: (statsRealtime?.waitingFromPassengers + statsRealtime?.waitingFromNewClients),
              totalPassCalls: stats.receiveCallsFromPassengers
            },
            driver: {
              color: this.getColorOfStat((statsRealtime?.waitingFromDrivers + statsRealtime?.waitingFromCompanyDrivers), stats.receiveCallsFromDrivers),
              inProgressDriverCalls: (statsRealtime?.waitingFromDrivers + statsRealtime?.waitingFromCompanyDrivers),
              totalDriverCalls: stats.receiveCallsFromDrivers
            },
            remote: {
              color: 'white',
              remoters: stats.activeRemotely,
              totalDisps: stats.active
            }
          });
        } else {
          newQueueStat.push({
            ...queue,
            missed: {
              color: 'white',
              inProgressMissedCalls: '-',
              totalMissedCalls: '-'
            },
            passWait: {
              color: 'white',
              waitToTalkDuration: '-'
            },
            driverWait: {
              color: 'white',
              waitToTalkDuration: '-'
            },
            pass: {
              color: 'white',
              inProgressPassCalls: '-',
              totalPassCalls: '-'
            },
            driver: {
              color: 'white',
              inProgressDriverCalls: '-',
              totalDriverCalls: '-'
            },
            remote: {
              color: 'white',
              remoters: '-',
              totalDisps: '-'
            }
          });
        }
      });
    }
    this.queuesStat = [this.queueAllObj, ...newQueueStat];
  }

  private getColorOfStat(isWait: number, total: number): string {
    if ((isWait / total) >= 2) {
      return 'red';
    } else if ((isWait - total) >= 5) {
      return 'orange';
    } else {
      return 'white';
    }
  }


  private getWaitToTalkDurationColor(value: number | null): string {
    if (value == null) {
      return 'white';
    }

    if (value < 12) {
      return 'green';
    } else if (value >= 12 && value < 20) {
      return 'yellow';
    } else if (value >= 20 && value < 40) {
      return 'orange';
    } else {
      return 'red';
    }
  }

  private getMissedPassengerStats(call: ICallsStatisticRealtime)  {
    const total = Math.round((call?.recentTotalWaitDurationFromPassengers + call?.recentTotalWaitDurationFromNewClients) /
    (call?.recentFromPassengers + call?.recentFromNewClients));
    return !isNaN(total) ? total : null;
  }

  private getMissedDriverStats(call: ICallsStatisticRealtime)  {
    const total =  Math.round((call?.recentTotalWaitDurationFromDrivers + call?.recentTotalWaitDurationFromCompanyDrivers) /
      (call?.recentFromDrivers + call?.recentFromCompanyDrivers));
    return !isNaN(total) ? total : null;
  }
}
