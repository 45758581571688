import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTripOfCab'
})
export class GetTripOfCabPipe implements PipeTransform {

  transform(cab: any): any {
    let trip;
    if (cab.trips.length === 0) {
      trip = cab.trip;
    } else {
      trip = cab.trips.find(item => item.is_current);
    }
    return trip;
  }

}
