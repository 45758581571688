import { Pipe, PipeTransform } from '@angular/core';
import { CabTarget } from 'src/app/interfaces/target.interface';

@Pipe({
  name: 'getTargetIcon'
})
export class GetTargetIconPipe implements PipeTransform {

  transform(targets: CabTarget[], iconType: string): string {
    const target = (targets || []).find(item => item.type === iconType);
    if (target) {
      return target.is_reached ? `${iconType}_crosed` : iconType;
    }

    return '';
  }

}
