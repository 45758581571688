import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import {  MatTableDataSource } from '@angular/material/table';
import { QueueGroupModel } from 'src/app/call-centre/modules/queue/models';
import { RemoteWorkRequestStatus } from '../../../../../../cabinet/models';
import { DispatcherModel, DispatcherWorkStatus, DispCallStatus, DispRole } from '../../../models';
import { isFitInRoles, isQueueGroupFit, isStatusFit } from '../../../utils/table-filters';
import {GlobalDataService} from '@global-services/global-data.service';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'utax-disp-settings-remote-table',
  templateUrl: './disp-settings-remote-table.component.html',
  styleUrls: ['./disp-settings-remote-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispSettingsRemoteTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dispStatuses = DispatcherWorkStatus;
  callStatuses = DispCallStatus;
  statusesFilter = ['active', 'inactive', 'remote'];
  rolesFilter = [];
  roles = [];
  queueGroups = [];
  queueGroupsFilter = [];
  dispatchers: DispatcherModel[];
  dataSource: any;
  staticDispatchers: any;
  sortParams: any;
  selectedAll: boolean = false;

  readonly remoteWorkRequestStatus = RemoteWorkRequestStatus;

  @Input('dispatchers')
  set setDispatchers(dispatchers: DispatcherModel[]) {
    this.dispatchers = dispatchers;
    this.staticDispatchers = [...dispatchers];
    this.dataSource = new MatTableDataSource(dispatchers);
    this.filterTable();
  }
  @Input('roles')
  set setRoles(roles: DispRole[]) {
    this.roles = roles;
    this.rolesFilter = this.roles.map(role => role.uuid);
  }
  @Input('queueGroups')
  set setQueueGroups(queueGroups: QueueGroupModel[]) {
    this.queueGroups = queueGroups;
    this.queueGroupsFilter = this.queueGroups.map(group => group.uuid);
    this.selectedAll = (this.queueGroupsFilter.length === this.queueGroups.length);
  }

  @Output() approveRemoteWork = new EventEmitter();
  @Output() rejectRemoteWork = new EventEmitter();
  @Output() checkboxUpdate = new EventEmitter();
  @Output() remoteUpdate = new EventEmitter();
  @Output() queueGroupUpdate = new EventEmitter();
  @Output() onSorted = new EventEmitter();

  displayedColumns = [
    'state',
    'name',
    'global_user_name',
    'created_at',
    'roles',
    'brigade',
    'queue_service_groups',
    'allow_calls_from_drivers',
    'allow_calls_from_company_drivers',
    'allow_calls_from_passengers',
    'allow_calls_from_new_clients',
    'is_remoute'
  ];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private globalDataService: GlobalDataService,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getRoles(roleIds: string[]): any {
    return this.globalDataService.getRole(roleIds)?.join(', ');
  }

  getBrigade(brigadeId): any {
    return this.globalDataService.getBrigade(brigadeId).name;
  }

  serviceClick(service, serviceIndex: number, dispIndex: number): void {
    this.queueGroupUpdate.emit({
      uuid: this.dataSource.data[dispIndex].id,
      externalId: this.dataSource.data[dispIndex].externalId,
      active: !this.dataSource.data[dispIndex].taxiServices[serviceIndex].active,
      groupId: this.dataSource.data[dispIndex].taxiServices[serviceIndex].id,
      externalGroupId: this.dataSource.data[dispIndex].taxiServices[serviceIndex].externalId,
      dispIndex
    });
  }

  checkboxChange(callLine: any, name: string, event: any, index: number): void {
    this.dataSource.data[index].loading = true;
    this.checkboxUpdate.emit({
      id: this.dataSource.data[index].id,
      callLineId: callLine.id,
      name,
      allow: event.checked,
      index
    });
  }

  remoteChange(event: any, index: number): void {
    if (event.checked === false) {
      this.dataSource.data[index].loading = true;
      this.remoteUpdate.emit({
        uid: this.dataSource.data[index].externalId,
        index
      });
    }
  }

  getWorkTime(disp: any): string {
    if (disp.remoteWork && disp.remoteWork.created_at) {
      return disp.remoteWork.created_at;
    }

    if (
      disp.status &&
      disp.status.remoteWorkStatus === 'REMOTELY' &&
      disp.status.workShiftStatus === 'CLOSED'
    ) {
      return '';
    }
    return disp.status && disp.status.lastWorkShiftStartedAt;
  }

  selectAll(): void {
    this.queueGroupsFilter = this.queueGroups.map(item => item.uuid);
    this.filterTable();
  }

  deselectAll(): void {
    this.queueGroupsFilter = [];
    this.filterTable();
  }

  revokeRequest(uid: string): void {
    this.remoteUpdate.emit({ uid });
  }

  approveRemoteWorkRequest(id: string): void {
    this.approveRemoteWork.emit(id);
  }

  rejectRemoteWorkRequest(id: string): void {
    this.rejectRemoteWork.emit(id);
  }

  sortData(sort: any): any {
    if (sort) {
      this.onSorted.emit([`name,${sort?.direction || ''}`]);
    }
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  filterTable(): void {
    const newData = [];
    this.dispatchers.forEach((disp: DispatcherModel) => {
      let fit = true;
      if (this.rolesFilter.length !== this.roles.length) {
        fit = isFitInRoles(disp, this.rolesFilter);
      }
      if (fit && this.statusesFilter.length !== 3) {
        fit = isStatusFit(disp, this.statusesFilter);
      }
      if (fit && this.queueGroupsFilter.length !== this.queueGroups.length) {
        fit = isQueueGroupFit(disp, this.queueGroupsFilter);
      }
      if (fit) {
        newData.push(disp);
      }
    });
    this.dataSource = new MatTableDataSource(newData);
    this.changeDetectorRef.detectChanges();
    this.selectedAll = (this.queueGroupsFilter.length === this.queueGroups.length) ? true : false;
    this.sortData(this.sortParams);
  }
}
