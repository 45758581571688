import { DispOrdersApiService } from '../../../dispatcher/services/disp-orders-api.service';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

const MAX_EXPAND_TIME = 30;

@Component({
  selector: 'utax-order-increase-time',
  templateUrl: './order-increase-time.component.html',
  styleUrls: ['./order-increase-time.component.scss']
})
export class OrderIncreaseTimeComponent implements OnInit {
  @Input() orderId: any;
  @Output() closeWindow = new EventEmitter<boolean>();
  @ViewChild('myElement', { static: true }) myElement: any;

  maxTime: number;
  form: UntypedFormGroup;

  constructor(private orderApiService: DispOrdersApiService, private fb: UntypedFormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    this.maxTime = MAX_EXPAND_TIME;
    this.myElement.nativeElement.focus();
    this.form.controls.time.markAsTouched();
  }

  private createForm(): void {
    this.form = this.fb.group({
      time: [null, { validators: [Validators.required, this.validateOnMax] }]
    });
  }

  @HostListener('document:keyup', ['$event'])
  hotkeyUpHandler($event) {
    switch ($event.keyCode) {
      case 13: {
        $event.preventDefault();
        const time = this.form.value.time;
        if (time.length > 0 && +time <= MAX_EXPAND_TIME) {
          this.orderApiService
            .extendOrder({
              id: this.orderId,
              value: time
            })
            .subscribe((res: any) => (res.error ? this.closeWindow.emit(res.error) : this.closeWindow.emit(true)));
        }
        break;
      }

      case 27: {
        $event.preventDefault();
        this.closeWindow.emit(true);
        break;
      }
    }
  }

  @HostListener('document:mouseup', ['$event'])
  mouseHandler($event) {
    if ($event.target.closest('taxi-order-update-time') === null) {
      this.closeWindow.emit(true);
    }
  }

  private validateOnMax = (c: UntypedFormControl) => {
    let errorObj = null;

    if (Number(c.value) > MAX_EXPAND_TIME) {
      errorObj = {
        customMax: {
          valid: false
        }
      };
    }

    return errorObj;
  };
}
