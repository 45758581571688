import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChristmasService {

  public toggleSnow = true;
  public isValentine = false;
  public isEaster = false;

  // public snowflakes: Snowflakes = new Snowflakes({
  //   count: 15, // 100 snowflakes. Default: 50
  //   wind: false, // Without wind. Default: true
  //   minOpacity: 0.3, // From 0 to 1. Default: 0.6
  //   maxOpacity: 0.9, // From 0 to 1. Default: 1
  //   minSize: 20, // Default: 10
  //   maxSize: 30, // Default: 25
  //   rotation: false, // Default: true
  // });

  constructor() {

  }

  // public startSnow() {
  //   this.snowflakes.start();
  // }
  //
  // public stopSnow() {
  //   this.snowflakes.stop();
  // }
  //
  // public viewSnow() {
  //   this.snowflakes.show();
  // }
  //
  // public hideSnow() {
  //   this.snowflakes.hide();
  // }
}
