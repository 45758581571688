import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {OperatorCommentInterface} from '../interfaces/operator-comment.interface';

@Injectable({
  providedIn: 'root'
})
export class OperatorCommentService {

  public currentOrderComments$: BehaviorSubject<OperatorCommentInterface[]> = new BehaviorSubject<OperatorCommentInterface[]>([]);
  public currentOrderSelectId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() { }
}
