import { StateService } from '../../services/state.service';
import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getServiceName',
  pure: false
})
export class GetServiceNamePipe implements PipeTransform {
  translatedWord;

  constructor(private state: StateService, private translate: TranslateService, private cd: ChangeDetectorRef) { }

  transform(serviceId, type?: 'city'): any {
    let currentService;
    if (this.state.dumbStore.service) {
      currentService = this.state.dumbStore.service.find(item => item.id === serviceId);
    }
    if (currentService) {
      this.translate.get(currentService.city.name).subscribe(data => {
        this.translatedWord = data;
        this.cd.markForCheck();
      });
      return type && type === 'city' ? this.translatedWord : `${currentService.name} ${this.translatedWord}`;
    } else {
      return '';
    }
  }
}
