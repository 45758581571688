/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.pro = (function() {

    /**
     * Namespace pro.
     * @exports pro
     * @namespace
     */
    var pro = {};

    pro.megakit = (function() {

        /**
         * Namespace megakit.
         * @memberof pro
         * @namespace
         */
        var megakit = {};

        megakit.amelia = (function() {

            /**
             * Namespace amelia.
             * @memberof pro.megakit
             * @namespace
             */
            var amelia = {};

            amelia.bus = (function() {

                /**
                 * Namespace bus.
                 * @memberof pro.megakit.amelia
                 * @namespace
                 */
                var bus = {};

                bus.SubscriptionEvent = (function() {

                    /**
                     * Properties of a SubscriptionEvent.
                     * @memberof pro.megakit.amelia.bus
                     * @interface ISubscriptionEvent
                     * @property {number|null} [id] SubscriptionEvent id
                     * @property {Uint8Array|null} [payload] SubscriptionEvent payload
                     * @property {Array.<string>|null} [channels] SubscriptionEvent channels
                     */

                    /**
                     * Constructs a new SubscriptionEvent.
                     * @memberof pro.megakit.amelia.bus
                     * @classdesc Represents a SubscriptionEvent.
                     * @implements ISubscriptionEvent
                     * @constructor
                     * @param {pro.megakit.amelia.bus.ISubscriptionEvent=} [properties] Properties to set
                     */
                    function SubscriptionEvent(properties) {
                        this.channels = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SubscriptionEvent id.
                     * @member {number} id
                     * @memberof pro.megakit.amelia.bus.SubscriptionEvent
                     * @instance
                     */
                    SubscriptionEvent.prototype.id = 0;

                    /**
                     * SubscriptionEvent payload.
                     * @member {Uint8Array} payload
                     * @memberof pro.megakit.amelia.bus.SubscriptionEvent
                     * @instance
                     */
                    SubscriptionEvent.prototype.payload = $util.newBuffer([]);

                    /**
                     * SubscriptionEvent channels.
                     * @member {Array.<string>} channels
                     * @memberof pro.megakit.amelia.bus.SubscriptionEvent
                     * @instance
                     */
                    SubscriptionEvent.prototype.channels = $util.emptyArray;

                    /**
                     * Creates a new SubscriptionEvent instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.bus.SubscriptionEvent
                     * @static
                     * @param {pro.megakit.amelia.bus.ISubscriptionEvent=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.bus.SubscriptionEvent} SubscriptionEvent instance
                     */
                    SubscriptionEvent.create = function create(properties) {
                        return new SubscriptionEvent(properties);
                    };

                    /**
                     * Encodes the specified SubscriptionEvent message. Does not implicitly {@link pro.megakit.amelia.bus.SubscriptionEvent.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.bus.SubscriptionEvent
                     * @static
                     * @param {pro.megakit.amelia.bus.ISubscriptionEvent} message SubscriptionEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SubscriptionEvent.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                        if (message.payload != null && message.hasOwnProperty("payload"))
                            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.payload);
                        if (message.channels != null && message.channels.length)
                            for (var i = 0; i < message.channels.length; ++i)
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.channels[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified SubscriptionEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.bus.SubscriptionEvent.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.bus.SubscriptionEvent
                     * @static
                     * @param {pro.megakit.amelia.bus.ISubscriptionEvent} message SubscriptionEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SubscriptionEvent.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SubscriptionEvent message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.bus.SubscriptionEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.bus.SubscriptionEvent} SubscriptionEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SubscriptionEvent.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.bus.SubscriptionEvent();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.uint32();
                                break;
                            case 2:
                                message.payload = reader.bytes();
                                break;
                            case 3:
                                if (!(message.channels && message.channels.length))
                                    message.channels = [];
                                message.channels.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SubscriptionEvent message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.bus.SubscriptionEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.bus.SubscriptionEvent} SubscriptionEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SubscriptionEvent.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SubscriptionEvent message.
                     * @function verify
                     * @memberof pro.megakit.amelia.bus.SubscriptionEvent
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SubscriptionEvent.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id))
                                return "id: integer expected";
                        if (message.payload != null && message.hasOwnProperty("payload"))
                            if (!(message.payload && typeof message.payload.length === "number" || $util.isString(message.payload)))
                                return "payload: buffer expected";
                        if (message.channels != null && message.hasOwnProperty("channels")) {
                            if (!Array.isArray(message.channels))
                                return "channels: array expected";
                            for (var i = 0; i < message.channels.length; ++i)
                                if (!$util.isString(message.channels[i]))
                                    return "channels: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a SubscriptionEvent message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.bus.SubscriptionEvent
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.bus.SubscriptionEvent} SubscriptionEvent
                     */
                    SubscriptionEvent.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.bus.SubscriptionEvent)
                            return object;
                        var message = new $root.pro.megakit.amelia.bus.SubscriptionEvent();
                        if (object.id != null)
                            message.id = object.id >>> 0;
                        if (object.payload != null)
                            if (typeof object.payload === "string")
                                $util.base64.decode(object.payload, message.payload = $util.newBuffer($util.base64.length(object.payload)), 0);
                            else if (object.payload.length)
                                message.payload = object.payload;
                        if (object.channels) {
                            if (!Array.isArray(object.channels))
                                throw TypeError(".pro.megakit.amelia.bus.SubscriptionEvent.channels: array expected");
                            message.channels = [];
                            for (var i = 0; i < object.channels.length; ++i)
                                message.channels[i] = String(object.channels[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SubscriptionEvent message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.bus.SubscriptionEvent
                     * @static
                     * @param {pro.megakit.amelia.bus.SubscriptionEvent} message SubscriptionEvent
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SubscriptionEvent.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.channels = [];
                        if (options.defaults) {
                            object.id = 0;
                            if (options.bytes === String)
                                object.payload = "";
                            else {
                                object.payload = [];
                                if (options.bytes !== Array)
                                    object.payload = $util.newBuffer(object.payload);
                            }
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.payload != null && message.hasOwnProperty("payload"))
                            object.payload = options.bytes === String ? $util.base64.encode(message.payload, 0, message.payload.length) : options.bytes === Array ? Array.prototype.slice.call(message.payload) : message.payload;
                        if (message.channels && message.channels.length) {
                            object.channels = [];
                            for (var j = 0; j < message.channels.length; ++j)
                                object.channels[j] = message.channels[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this SubscriptionEvent to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.bus.SubscriptionEvent
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SubscriptionEvent.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SubscriptionEvent;
                })();

                return bus;
            })();

            amelia.PingPong = (function() {

                /**
                 * Properties of a PingPong.
                 * @memberof pro.megakit.amelia
                 * @interface IPingPong
                 */

                /**
                 * Constructs a new PingPong.
                 * @memberof pro.megakit.amelia
                 * @classdesc Represents a PingPong.
                 * @implements IPingPong
                 * @constructor
                 * @param {pro.megakit.amelia.IPingPong=} [properties] Properties to set
                 */
                function PingPong(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new PingPong instance using the specified properties.
                 * @function create
                 * @memberof pro.megakit.amelia.PingPong
                 * @static
                 * @param {pro.megakit.amelia.IPingPong=} [properties] Properties to set
                 * @returns {pro.megakit.amelia.PingPong} PingPong instance
                 */
                PingPong.create = function create(properties) {
                    return new PingPong(properties);
                };

                /**
                 * Encodes the specified PingPong message. Does not implicitly {@link pro.megakit.amelia.PingPong.verify|verify} messages.
                 * @function encode
                 * @memberof pro.megakit.amelia.PingPong
                 * @static
                 * @param {pro.megakit.amelia.IPingPong} message PingPong message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PingPong.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified PingPong message, length delimited. Does not implicitly {@link pro.megakit.amelia.PingPong.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof pro.megakit.amelia.PingPong
                 * @static
                 * @param {pro.megakit.amelia.IPingPong} message PingPong message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PingPong.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PingPong message from the specified reader or buffer.
                 * @function decode
                 * @memberof pro.megakit.amelia.PingPong
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {pro.megakit.amelia.PingPong} PingPong
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PingPong.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.PingPong();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PingPong message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof pro.megakit.amelia.PingPong
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {pro.megakit.amelia.PingPong} PingPong
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PingPong.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PingPong message.
                 * @function verify
                 * @memberof pro.megakit.amelia.PingPong
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PingPong.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a PingPong message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof pro.megakit.amelia.PingPong
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {pro.megakit.amelia.PingPong} PingPong
                 */
                PingPong.fromObject = function fromObject(object) {
                    if (object instanceof $root.pro.megakit.amelia.PingPong)
                        return object;
                    return new $root.pro.megakit.amelia.PingPong();
                };

                /**
                 * Creates a plain object from a PingPong message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof pro.megakit.amelia.PingPong
                 * @static
                 * @param {pro.megakit.amelia.PingPong} message PingPong
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PingPong.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this PingPong to JSON.
                 * @function toJSON
                 * @memberof pro.megakit.amelia.PingPong
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PingPong.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                PingPong.Ping = (function() {

                    /**
                     * Properties of a Ping.
                     * @memberof pro.megakit.amelia.PingPong
                     * @interface IPing
                     * @property {string|null} [id] Ping id
                     */

                    /**
                     * Constructs a new Ping.
                     * @memberof pro.megakit.amelia.PingPong
                     * @classdesc Represents a Ping.
                     * @implements IPing
                     * @constructor
                     * @param {pro.megakit.amelia.PingPong.IPing=} [properties] Properties to set
                     */
                    function Ping(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Ping id.
                     * @member {string} id
                     * @memberof pro.megakit.amelia.PingPong.Ping
                     * @instance
                     */
                    Ping.prototype.id = "";

                    /**
                     * Creates a new Ping instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.PingPong.Ping
                     * @static
                     * @param {pro.megakit.amelia.PingPong.IPing=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.PingPong.Ping} Ping instance
                     */
                    Ping.create = function create(properties) {
                        return new Ping(properties);
                    };

                    /**
                     * Encodes the specified Ping message. Does not implicitly {@link pro.megakit.amelia.PingPong.Ping.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.PingPong.Ping
                     * @static
                     * @param {pro.megakit.amelia.PingPong.IPing} message Ping message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Ping.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        return writer;
                    };

                    /**
                     * Encodes the specified Ping message, length delimited. Does not implicitly {@link pro.megakit.amelia.PingPong.Ping.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.PingPong.Ping
                     * @static
                     * @param {pro.megakit.amelia.PingPong.IPing} message Ping message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Ping.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Ping message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.PingPong.Ping
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.PingPong.Ping} Ping
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Ping.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.PingPong.Ping();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Ping message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.PingPong.Ping
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.PingPong.Ping} Ping
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Ping.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Ping message.
                     * @function verify
                     * @memberof pro.megakit.amelia.PingPong.Ping
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Ping.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        return null;
                    };

                    /**
                     * Creates a Ping message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.PingPong.Ping
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.PingPong.Ping} Ping
                     */
                    Ping.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.PingPong.Ping)
                            return object;
                        var message = new $root.pro.megakit.amelia.PingPong.Ping();
                        if (object.id != null)
                            message.id = String(object.id);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Ping message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.PingPong.Ping
                     * @static
                     * @param {pro.megakit.amelia.PingPong.Ping} message Ping
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Ping.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.id = "";
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        return object;
                    };

                    /**
                     * Converts this Ping to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.PingPong.Ping
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Ping.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Ping;
                })();

                PingPong.Pong = (function() {

                    /**
                     * Properties of a Pong.
                     * @memberof pro.megakit.amelia.PingPong
                     * @interface IPong
                     * @property {string|null} [pingId] Pong pingId
                     */

                    /**
                     * Constructs a new Pong.
                     * @memberof pro.megakit.amelia.PingPong
                     * @classdesc Represents a Pong.
                     * @implements IPong
                     * @constructor
                     * @param {pro.megakit.amelia.PingPong.IPong=} [properties] Properties to set
                     */
                    function Pong(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Pong pingId.
                     * @member {string} pingId
                     * @memberof pro.megakit.amelia.PingPong.Pong
                     * @instance
                     */
                    Pong.prototype.pingId = "";

                    /**
                     * Creates a new Pong instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.PingPong.Pong
                     * @static
                     * @param {pro.megakit.amelia.PingPong.IPong=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.PingPong.Pong} Pong instance
                     */
                    Pong.create = function create(properties) {
                        return new Pong(properties);
                    };

                    /**
                     * Encodes the specified Pong message. Does not implicitly {@link pro.megakit.amelia.PingPong.Pong.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.PingPong.Pong
                     * @static
                     * @param {pro.megakit.amelia.PingPong.IPong} message Pong message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Pong.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.pingId != null && message.hasOwnProperty("pingId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.pingId);
                        return writer;
                    };

                    /**
                     * Encodes the specified Pong message, length delimited. Does not implicitly {@link pro.megakit.amelia.PingPong.Pong.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.PingPong.Pong
                     * @static
                     * @param {pro.megakit.amelia.PingPong.IPong} message Pong message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Pong.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Pong message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.PingPong.Pong
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.PingPong.Pong} Pong
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Pong.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.PingPong.Pong();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.pingId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Pong message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.PingPong.Pong
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.PingPong.Pong} Pong
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Pong.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Pong message.
                     * @function verify
                     * @memberof pro.megakit.amelia.PingPong.Pong
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Pong.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.pingId != null && message.hasOwnProperty("pingId"))
                            if (!$util.isString(message.pingId))
                                return "pingId: string expected";
                        return null;
                    };

                    /**
                     * Creates a Pong message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.PingPong.Pong
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.PingPong.Pong} Pong
                     */
                    Pong.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.PingPong.Pong)
                            return object;
                        var message = new $root.pro.megakit.amelia.PingPong.Pong();
                        if (object.pingId != null)
                            message.pingId = String(object.pingId);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Pong message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.PingPong.Pong
                     * @static
                     * @param {pro.megakit.amelia.PingPong.Pong} message Pong
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Pong.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.pingId = "";
                        if (message.pingId != null && message.hasOwnProperty("pingId"))
                            object.pingId = message.pingId;
                        return object;
                    };

                    /**
                     * Converts this Pong to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.PingPong.Pong
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Pong.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Pong;
                })();

                return PingPong;
            })();

            amelia.MessageProto = (function() {

                /**
                 * Properties of a MessageProto.
                 * @memberof pro.megakit.amelia
                 * @interface IMessageProto
                 */

                /**
                 * Constructs a new MessageProto.
                 * @memberof pro.megakit.amelia
                 * @classdesc Represents a MessageProto.
                 * @implements IMessageProto
                 * @constructor
                 * @param {pro.megakit.amelia.IMessageProto=} [properties] Properties to set
                 */
                function MessageProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new MessageProto instance using the specified properties.
                 * @function create
                 * @memberof pro.megakit.amelia.MessageProto
                 * @static
                 * @param {pro.megakit.amelia.IMessageProto=} [properties] Properties to set
                 * @returns {pro.megakit.amelia.MessageProto} MessageProto instance
                 */
                MessageProto.create = function create(properties) {
                    return new MessageProto(properties);
                };

                /**
                 * Encodes the specified MessageProto message. Does not implicitly {@link pro.megakit.amelia.MessageProto.verify|verify} messages.
                 * @function encode
                 * @memberof pro.megakit.amelia.MessageProto
                 * @static
                 * @param {pro.megakit.amelia.IMessageProto} message MessageProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MessageProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified MessageProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.MessageProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof pro.megakit.amelia.MessageProto
                 * @static
                 * @param {pro.megakit.amelia.IMessageProto} message MessageProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MessageProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MessageProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof pro.megakit.amelia.MessageProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {pro.megakit.amelia.MessageProto} MessageProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MessageProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.MessageProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MessageProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof pro.megakit.amelia.MessageProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {pro.megakit.amelia.MessageProto} MessageProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MessageProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MessageProto message.
                 * @function verify
                 * @memberof pro.megakit.amelia.MessageProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MessageProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a MessageProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof pro.megakit.amelia.MessageProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {pro.megakit.amelia.MessageProto} MessageProto
                 */
                MessageProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.pro.megakit.amelia.MessageProto)
                        return object;
                    return new $root.pro.megakit.amelia.MessageProto();
                };

                /**
                 * Creates a plain object from a MessageProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof pro.megakit.amelia.MessageProto
                 * @static
                 * @param {pro.megakit.amelia.MessageProto} message MessageProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MessageProto.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this MessageProto to JSON.
                 * @function toJSON
                 * @memberof pro.megakit.amelia.MessageProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MessageProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                MessageProto.MessageProperty = (function() {

                    /**
                     * Properties of a MessageProperty.
                     * @memberof pro.megakit.amelia.MessageProto
                     * @interface IMessageProperty
                     * @property {string|null} [name] MessageProperty name
                     * @property {google.protobuf.IAny|null} [value] MessageProperty value
                     */

                    /**
                     * Constructs a new MessageProperty.
                     * @memberof pro.megakit.amelia.MessageProto
                     * @classdesc Represents a MessageProperty.
                     * @implements IMessageProperty
                     * @constructor
                     * @param {pro.megakit.amelia.MessageProto.IMessageProperty=} [properties] Properties to set
                     */
                    function MessageProperty(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * MessageProperty name.
                     * @member {string} name
                     * @memberof pro.megakit.amelia.MessageProto.MessageProperty
                     * @instance
                     */
                    MessageProperty.prototype.name = "";

                    /**
                     * MessageProperty value.
                     * @member {google.protobuf.IAny|null|undefined} value
                     * @memberof pro.megakit.amelia.MessageProto.MessageProperty
                     * @instance
                     */
                    MessageProperty.prototype.value = null;

                    /**
                     * Creates a new MessageProperty instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.MessageProto.MessageProperty
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.IMessageProperty=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.MessageProto.MessageProperty} MessageProperty instance
                     */
                    MessageProperty.create = function create(properties) {
                        return new MessageProperty(properties);
                    };

                    /**
                     * Encodes the specified MessageProperty message. Does not implicitly {@link pro.megakit.amelia.MessageProto.MessageProperty.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.MessageProto.MessageProperty
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.IMessageProperty} message MessageProperty message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MessageProperty.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.value != null && message.hasOwnProperty("value"))
                            $root.google.protobuf.Any.encode(message.value, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified MessageProperty message, length delimited. Does not implicitly {@link pro.megakit.amelia.MessageProto.MessageProperty.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.MessageProto.MessageProperty
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.IMessageProperty} message MessageProperty message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MessageProperty.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a MessageProperty message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.MessageProto.MessageProperty
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.MessageProto.MessageProperty} MessageProperty
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MessageProperty.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.MessageProto.MessageProperty();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.value = $root.google.protobuf.Any.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a MessageProperty message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.MessageProto.MessageProperty
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.MessageProto.MessageProperty} MessageProperty
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MessageProperty.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a MessageProperty message.
                     * @function verify
                     * @memberof pro.megakit.amelia.MessageProto.MessageProperty
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MessageProperty.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.value != null && message.hasOwnProperty("value")) {
                            var error = $root.google.protobuf.Any.verify(message.value);
                            if (error)
                                return "value." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a MessageProperty message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.MessageProto.MessageProperty
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.MessageProto.MessageProperty} MessageProperty
                     */
                    MessageProperty.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.MessageProto.MessageProperty)
                            return object;
                        var message = new $root.pro.megakit.amelia.MessageProto.MessageProperty();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.value != null) {
                            if (typeof object.value !== "object")
                                throw TypeError(".pro.megakit.amelia.MessageProto.MessageProperty.value: object expected");
                            message.value = $root.google.protobuf.Any.fromObject(object.value);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a MessageProperty message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.MessageProto.MessageProperty
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.MessageProperty} message MessageProperty
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MessageProperty.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.value = null;
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.value != null && message.hasOwnProperty("value"))
                            object.value = $root.google.protobuf.Any.toObject(message.value, options);
                        return object;
                    };

                    /**
                     * Converts this MessageProperty to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.MessageProto.MessageProperty
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MessageProperty.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return MessageProperty;
                })();

                MessageProto.ViolationProperty = (function() {

                    /**
                     * Properties of a ViolationProperty.
                     * @memberof pro.megakit.amelia.MessageProto
                     * @interface IViolationProperty
                     * @property {string|null} [field] ViolationProperty field
                     * @property {string|null} [message] ViolationProperty message
                     */

                    /**
                     * Constructs a new ViolationProperty.
                     * @memberof pro.megakit.amelia.MessageProto
                     * @classdesc Represents a ViolationProperty.
                     * @implements IViolationProperty
                     * @constructor
                     * @param {pro.megakit.amelia.MessageProto.IViolationProperty=} [properties] Properties to set
                     */
                    function ViolationProperty(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ViolationProperty field.
                     * @member {string} field
                     * @memberof pro.megakit.amelia.MessageProto.ViolationProperty
                     * @instance
                     */
                    ViolationProperty.prototype.field = "";

                    /**
                     * ViolationProperty message.
                     * @member {string} message
                     * @memberof pro.megakit.amelia.MessageProto.ViolationProperty
                     * @instance
                     */
                    ViolationProperty.prototype.message = "";

                    /**
                     * Creates a new ViolationProperty instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.MessageProto.ViolationProperty
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.IViolationProperty=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.MessageProto.ViolationProperty} ViolationProperty instance
                     */
                    ViolationProperty.create = function create(properties) {
                        return new ViolationProperty(properties);
                    };

                    /**
                     * Encodes the specified ViolationProperty message. Does not implicitly {@link pro.megakit.amelia.MessageProto.ViolationProperty.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.MessageProto.ViolationProperty
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.IViolationProperty} message ViolationProperty message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ViolationProperty.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.field != null && message.hasOwnProperty("field"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.field);
                        if (message.message != null && message.hasOwnProperty("message"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
                        return writer;
                    };

                    /**
                     * Encodes the specified ViolationProperty message, length delimited. Does not implicitly {@link pro.megakit.amelia.MessageProto.ViolationProperty.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.MessageProto.ViolationProperty
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.IViolationProperty} message ViolationProperty message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ViolationProperty.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ViolationProperty message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.MessageProto.ViolationProperty
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.MessageProto.ViolationProperty} ViolationProperty
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ViolationProperty.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.MessageProto.ViolationProperty();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.field = reader.string();
                                break;
                            case 2:
                                message.message = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ViolationProperty message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.MessageProto.ViolationProperty
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.MessageProto.ViolationProperty} ViolationProperty
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ViolationProperty.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ViolationProperty message.
                     * @function verify
                     * @memberof pro.megakit.amelia.MessageProto.ViolationProperty
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ViolationProperty.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.field != null && message.hasOwnProperty("field"))
                            if (!$util.isString(message.field))
                                return "field: string expected";
                        if (message.message != null && message.hasOwnProperty("message"))
                            if (!$util.isString(message.message))
                                return "message: string expected";
                        return null;
                    };

                    /**
                     * Creates a ViolationProperty message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.MessageProto.ViolationProperty
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.MessageProto.ViolationProperty} ViolationProperty
                     */
                    ViolationProperty.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.MessageProto.ViolationProperty)
                            return object;
                        var message = new $root.pro.megakit.amelia.MessageProto.ViolationProperty();
                        if (object.field != null)
                            message.field = String(object.field);
                        if (object.message != null)
                            message.message = String(object.message);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ViolationProperty message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.MessageProto.ViolationProperty
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.ViolationProperty} message ViolationProperty
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ViolationProperty.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.field = "";
                            object.message = "";
                        }
                        if (message.field != null && message.hasOwnProperty("field"))
                            object.field = message.field;
                        if (message.message != null && message.hasOwnProperty("message"))
                            object.message = message.message;
                        return object;
                    };

                    /**
                     * Converts this ViolationProperty to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.MessageProto.ViolationProperty
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ViolationProperty.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ViolationProperty;
                })();

                MessageProto.TextProperty = (function() {

                    /**
                     * Properties of a TextProperty.
                     * @memberof pro.megakit.amelia.MessageProto
                     * @interface ITextProperty
                     * @property {string|null} [message] TextProperty message
                     */

                    /**
                     * Constructs a new TextProperty.
                     * @memberof pro.megakit.amelia.MessageProto
                     * @classdesc Represents a TextProperty.
                     * @implements ITextProperty
                     * @constructor
                     * @param {pro.megakit.amelia.MessageProto.ITextProperty=} [properties] Properties to set
                     */
                    function TextProperty(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * TextProperty message.
                     * @member {string} message
                     * @memberof pro.megakit.amelia.MessageProto.TextProperty
                     * @instance
                     */
                    TextProperty.prototype.message = "";

                    /**
                     * Creates a new TextProperty instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.MessageProto.TextProperty
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.ITextProperty=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.MessageProto.TextProperty} TextProperty instance
                     */
                    TextProperty.create = function create(properties) {
                        return new TextProperty(properties);
                    };

                    /**
                     * Encodes the specified TextProperty message. Does not implicitly {@link pro.megakit.amelia.MessageProto.TextProperty.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.MessageProto.TextProperty
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.ITextProperty} message TextProperty message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TextProperty.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.message != null && message.hasOwnProperty("message"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.message);
                        return writer;
                    };

                    /**
                     * Encodes the specified TextProperty message, length delimited. Does not implicitly {@link pro.megakit.amelia.MessageProto.TextProperty.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.MessageProto.TextProperty
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.ITextProperty} message TextProperty message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TextProperty.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a TextProperty message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.MessageProto.TextProperty
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.MessageProto.TextProperty} TextProperty
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TextProperty.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.MessageProto.TextProperty();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.message = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a TextProperty message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.MessageProto.TextProperty
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.MessageProto.TextProperty} TextProperty
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TextProperty.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a TextProperty message.
                     * @function verify
                     * @memberof pro.megakit.amelia.MessageProto.TextProperty
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TextProperty.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.message != null && message.hasOwnProperty("message"))
                            if (!$util.isString(message.message))
                                return "message: string expected";
                        return null;
                    };

                    /**
                     * Creates a TextProperty message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.MessageProto.TextProperty
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.MessageProto.TextProperty} TextProperty
                     */
                    TextProperty.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.MessageProto.TextProperty)
                            return object;
                        var message = new $root.pro.megakit.amelia.MessageProto.TextProperty();
                        if (object.message != null)
                            message.message = String(object.message);
                        return message;
                    };

                    /**
                     * Creates a plain object from a TextProperty message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.MessageProto.TextProperty
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.TextProperty} message TextProperty
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TextProperty.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.message = "";
                        if (message.message != null && message.hasOwnProperty("message"))
                            object.message = message.message;
                        return object;
                    };

                    /**
                     * Converts this TextProperty to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.MessageProto.TextProperty
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TextProperty.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return TextProperty;
                })();

                MessageProto.RequestMessage = (function() {

                    /**
                     * Properties of a RequestMessage.
                     * @memberof pro.megakit.amelia.MessageProto
                     * @interface IRequestMessage
                     * @property {number|null} [path] RequestMessage path
                     * @property {string|null} [id] RequestMessage id
                     * @property {Array.<pro.megakit.amelia.MessageProto.IMessageProperty>|null} [properties] RequestMessage properties
                     * @property {Uint8Array|null} [body] RequestMessage body
                     */

                    /**
                     * Constructs a new RequestMessage.
                     * @memberof pro.megakit.amelia.MessageProto
                     * @classdesc Represents a RequestMessage.
                     * @implements IRequestMessage
                     * @constructor
                     * @param {pro.megakit.amelia.MessageProto.IRequestMessage=} [properties] Properties to set
                     */
                    function RequestMessage(properties) {
                        this.properties = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RequestMessage path.
                     * @member {number} path
                     * @memberof pro.megakit.amelia.MessageProto.RequestMessage
                     * @instance
                     */
                    RequestMessage.prototype.path = 0;

                    /**
                     * RequestMessage id.
                     * @member {string} id
                     * @memberof pro.megakit.amelia.MessageProto.RequestMessage
                     * @instance
                     */
                    RequestMessage.prototype.id = "";

                    /**
                     * RequestMessage properties.
                     * @member {Array.<pro.megakit.amelia.MessageProto.IMessageProperty>} properties
                     * @memberof pro.megakit.amelia.MessageProto.RequestMessage
                     * @instance
                     */
                    RequestMessage.prototype.properties = $util.emptyArray;

                    /**
                     * RequestMessage body.
                     * @member {Uint8Array} body
                     * @memberof pro.megakit.amelia.MessageProto.RequestMessage
                     * @instance
                     */
                    RequestMessage.prototype.body = $util.newBuffer([]);

                    /**
                     * Creates a new RequestMessage instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.MessageProto.RequestMessage
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.IRequestMessage=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.MessageProto.RequestMessage} RequestMessage instance
                     */
                    RequestMessage.create = function create(properties) {
                        return new RequestMessage(properties);
                    };

                    /**
                     * Encodes the specified RequestMessage message. Does not implicitly {@link pro.megakit.amelia.MessageProto.RequestMessage.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.MessageProto.RequestMessage
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.IRequestMessage} message RequestMessage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RequestMessage.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.path != null && message.hasOwnProperty("path"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.path);
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.id);
                        if (message.properties != null && message.properties.length)
                            for (var i = 0; i < message.properties.length; ++i)
                                $root.pro.megakit.amelia.MessageProto.MessageProperty.encode(message.properties[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.body != null && message.hasOwnProperty("body"))
                            writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.body);
                        return writer;
                    };

                    /**
                     * Encodes the specified RequestMessage message, length delimited. Does not implicitly {@link pro.megakit.amelia.MessageProto.RequestMessage.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.MessageProto.RequestMessage
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.IRequestMessage} message RequestMessage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RequestMessage.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RequestMessage message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.MessageProto.RequestMessage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.MessageProto.RequestMessage} RequestMessage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RequestMessage.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.MessageProto.RequestMessage();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.path = reader.uint32();
                                break;
                            case 2:
                                message.id = reader.string();
                                break;
                            case 3:
                                if (!(message.properties && message.properties.length))
                                    message.properties = [];
                                message.properties.push($root.pro.megakit.amelia.MessageProto.MessageProperty.decode(reader, reader.uint32()));
                                break;
                            case 4:
                                message.body = reader.bytes();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RequestMessage message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.MessageProto.RequestMessage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.MessageProto.RequestMessage} RequestMessage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RequestMessage.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RequestMessage message.
                     * @function verify
                     * @memberof pro.megakit.amelia.MessageProto.RequestMessage
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RequestMessage.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.path != null && message.hasOwnProperty("path"))
                            if (!$util.isInteger(message.path))
                                return "path: integer expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.properties != null && message.hasOwnProperty("properties")) {
                            if (!Array.isArray(message.properties))
                                return "properties: array expected";
                            for (var i = 0; i < message.properties.length; ++i) {
                                var error = $root.pro.megakit.amelia.MessageProto.MessageProperty.verify(message.properties[i]);
                                if (error)
                                    return "properties." + error;
                            }
                        }
                        if (message.body != null && message.hasOwnProperty("body"))
                            if (!(message.body && typeof message.body.length === "number" || $util.isString(message.body)))
                                return "body: buffer expected";
                        return null;
                    };

                    /**
                     * Creates a RequestMessage message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.MessageProto.RequestMessage
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.MessageProto.RequestMessage} RequestMessage
                     */
                    RequestMessage.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.MessageProto.RequestMessage)
                            return object;
                        var message = new $root.pro.megakit.amelia.MessageProto.RequestMessage();
                        if (object.path != null)
                            message.path = object.path >>> 0;
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.properties) {
                            if (!Array.isArray(object.properties))
                                throw TypeError(".pro.megakit.amelia.MessageProto.RequestMessage.properties: array expected");
                            message.properties = [];
                            for (var i = 0; i < object.properties.length; ++i) {
                                if (typeof object.properties[i] !== "object")
                                    throw TypeError(".pro.megakit.amelia.MessageProto.RequestMessage.properties: object expected");
                                message.properties[i] = $root.pro.megakit.amelia.MessageProto.MessageProperty.fromObject(object.properties[i]);
                            }
                        }
                        if (object.body != null)
                            if (typeof object.body === "string")
                                $util.base64.decode(object.body, message.body = $util.newBuffer($util.base64.length(object.body)), 0);
                            else if (object.body.length)
                                message.body = object.body;
                        return message;
                    };

                    /**
                     * Creates a plain object from a RequestMessage message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.MessageProto.RequestMessage
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.RequestMessage} message RequestMessage
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RequestMessage.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.properties = [];
                        if (options.defaults) {
                            object.path = 0;
                            object.id = "";
                            if (options.bytes === String)
                                object.body = "";
                            else {
                                object.body = [];
                                if (options.bytes !== Array)
                                    object.body = $util.newBuffer(object.body);
                            }
                        }
                        if (message.path != null && message.hasOwnProperty("path"))
                            object.path = message.path;
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.properties && message.properties.length) {
                            object.properties = [];
                            for (var j = 0; j < message.properties.length; ++j)
                                object.properties[j] = $root.pro.megakit.amelia.MessageProto.MessageProperty.toObject(message.properties[j], options);
                        }
                        if (message.body != null && message.hasOwnProperty("body"))
                            object.body = options.bytes === String ? $util.base64.encode(message.body, 0, message.body.length) : options.bytes === Array ? Array.prototype.slice.call(message.body) : message.body;
                        return object;
                    };

                    /**
                     * Converts this RequestMessage to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.MessageProto.RequestMessage
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RequestMessage.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RequestMessage;
                })();

                /**
                 * ResponseStatus enum.
                 * @name pro.megakit.amelia.MessageProto.ResponseStatus
                 * @enum {string}
                 * @property {number} SUCCESS=0 SUCCESS value
                 * @property {number} FAILURE=1 FAILURE value
                 */
                MessageProto.ResponseStatus = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "SUCCESS"] = 0;
                    values[valuesById[1] = "FAILURE"] = 1;
                    return values;
                })();

                MessageProto.ResponseMessage = (function() {

                    /**
                     * Properties of a ResponseMessage.
                     * @memberof pro.megakit.amelia.MessageProto
                     * @interface IResponseMessage
                     * @property {string|null} [requestId] ResponseMessage requestId
                     * @property {pro.megakit.amelia.MessageProto.ResponseStatus|null} [status] ResponseMessage status
                     * @property {string|null} [code] ResponseMessage code
                     * @property {Array.<pro.megakit.amelia.MessageProto.IMessageProperty>|null} [properties] ResponseMessage properties
                     * @property {Uint8Array|null} [body] ResponseMessage body
                     */

                    /**
                     * Constructs a new ResponseMessage.
                     * @memberof pro.megakit.amelia.MessageProto
                     * @classdesc Represents a ResponseMessage.
                     * @implements IResponseMessage
                     * @constructor
                     * @param {pro.megakit.amelia.MessageProto.IResponseMessage=} [properties] Properties to set
                     */
                    function ResponseMessage(properties) {
                        this.properties = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ResponseMessage requestId.
                     * @member {string} requestId
                     * @memberof pro.megakit.amelia.MessageProto.ResponseMessage
                     * @instance
                     */
                    ResponseMessage.prototype.requestId = "";

                    /**
                     * ResponseMessage status.
                     * @member {pro.megakit.amelia.MessageProto.ResponseStatus} status
                     * @memberof pro.megakit.amelia.MessageProto.ResponseMessage
                     * @instance
                     */
                    ResponseMessage.prototype.status = 0;

                    /**
                     * ResponseMessage code.
                     * @member {string} code
                     * @memberof pro.megakit.amelia.MessageProto.ResponseMessage
                     * @instance
                     */
                    ResponseMessage.prototype.code = "";

                    /**
                     * ResponseMessage properties.
                     * @member {Array.<pro.megakit.amelia.MessageProto.IMessageProperty>} properties
                     * @memberof pro.megakit.amelia.MessageProto.ResponseMessage
                     * @instance
                     */
                    ResponseMessage.prototype.properties = $util.emptyArray;

                    /**
                     * ResponseMessage body.
                     * @member {Uint8Array} body
                     * @memberof pro.megakit.amelia.MessageProto.ResponseMessage
                     * @instance
                     */
                    ResponseMessage.prototype.body = $util.newBuffer([]);

                    /**
                     * Creates a new ResponseMessage instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.MessageProto.ResponseMessage
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.IResponseMessage=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.MessageProto.ResponseMessage} ResponseMessage instance
                     */
                    ResponseMessage.create = function create(properties) {
                        return new ResponseMessage(properties);
                    };

                    /**
                     * Encodes the specified ResponseMessage message. Does not implicitly {@link pro.megakit.amelia.MessageProto.ResponseMessage.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.MessageProto.ResponseMessage
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.IResponseMessage} message ResponseMessage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ResponseMessage.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.requestId != null && message.hasOwnProperty("requestId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.requestId);
                        if (message.status != null && message.hasOwnProperty("status"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
                        if (message.code != null && message.hasOwnProperty("code"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.code);
                        if (message.properties != null && message.properties.length)
                            for (var i = 0; i < message.properties.length; ++i)
                                $root.pro.megakit.amelia.MessageProto.MessageProperty.encode(message.properties[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.body != null && message.hasOwnProperty("body"))
                            writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.body);
                        return writer;
                    };

                    /**
                     * Encodes the specified ResponseMessage message, length delimited. Does not implicitly {@link pro.megakit.amelia.MessageProto.ResponseMessage.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.MessageProto.ResponseMessage
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.IResponseMessage} message ResponseMessage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ResponseMessage.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ResponseMessage message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.MessageProto.ResponseMessage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.MessageProto.ResponseMessage} ResponseMessage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ResponseMessage.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.MessageProto.ResponseMessage();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.requestId = reader.string();
                                break;
                            case 2:
                                message.status = reader.int32();
                                break;
                            case 3:
                                message.code = reader.string();
                                break;
                            case 4:
                                if (!(message.properties && message.properties.length))
                                    message.properties = [];
                                message.properties.push($root.pro.megakit.amelia.MessageProto.MessageProperty.decode(reader, reader.uint32()));
                                break;
                            case 5:
                                message.body = reader.bytes();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ResponseMessage message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.MessageProto.ResponseMessage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.MessageProto.ResponseMessage} ResponseMessage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ResponseMessage.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ResponseMessage message.
                     * @function verify
                     * @memberof pro.megakit.amelia.MessageProto.ResponseMessage
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ResponseMessage.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.requestId != null && message.hasOwnProperty("requestId"))
                            if (!$util.isString(message.requestId))
                                return "requestId: string expected";
                        if (message.status != null && message.hasOwnProperty("status"))
                            switch (message.status) {
                            default:
                                return "status: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.code != null && message.hasOwnProperty("code"))
                            if (!$util.isString(message.code))
                                return "code: string expected";
                        if (message.properties != null && message.hasOwnProperty("properties")) {
                            if (!Array.isArray(message.properties))
                                return "properties: array expected";
                            for (var i = 0; i < message.properties.length; ++i) {
                                var error = $root.pro.megakit.amelia.MessageProto.MessageProperty.verify(message.properties[i]);
                                if (error)
                                    return "properties." + error;
                            }
                        }
                        if (message.body != null && message.hasOwnProperty("body"))
                            if (!(message.body && typeof message.body.length === "number" || $util.isString(message.body)))
                                return "body: buffer expected";
                        return null;
                    };

                    /**
                     * Creates a ResponseMessage message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.MessageProto.ResponseMessage
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.MessageProto.ResponseMessage} ResponseMessage
                     */
                    ResponseMessage.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.MessageProto.ResponseMessage)
                            return object;
                        var message = new $root.pro.megakit.amelia.MessageProto.ResponseMessage();
                        if (object.requestId != null)
                            message.requestId = String(object.requestId);
                        switch (object.status) {
                        case "SUCCESS":
                        case 0:
                            message.status = 0;
                            break;
                        case "FAILURE":
                        case 1:
                            message.status = 1;
                            break;
                        }
                        if (object.code != null)
                            message.code = String(object.code);
                        if (object.properties) {
                            if (!Array.isArray(object.properties))
                                throw TypeError(".pro.megakit.amelia.MessageProto.ResponseMessage.properties: array expected");
                            message.properties = [];
                            for (var i = 0; i < object.properties.length; ++i) {
                                if (typeof object.properties[i] !== "object")
                                    throw TypeError(".pro.megakit.amelia.MessageProto.ResponseMessage.properties: object expected");
                                message.properties[i] = $root.pro.megakit.amelia.MessageProto.MessageProperty.fromObject(object.properties[i]);
                            }
                        }
                        if (object.body != null)
                            if (typeof object.body === "string")
                                $util.base64.decode(object.body, message.body = $util.newBuffer($util.base64.length(object.body)), 0);
                            else if (object.body.length)
                                message.body = object.body;
                        return message;
                    };

                    /**
                     * Creates a plain object from a ResponseMessage message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.MessageProto.ResponseMessage
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.ResponseMessage} message ResponseMessage
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ResponseMessage.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.properties = [];
                        if (options.defaults) {
                            object.requestId = "";
                            object.status = options.enums === String ? "SUCCESS" : 0;
                            object.code = "";
                            if (options.bytes === String)
                                object.body = "";
                            else {
                                object.body = [];
                                if (options.bytes !== Array)
                                    object.body = $util.newBuffer(object.body);
                            }
                        }
                        if (message.requestId != null && message.hasOwnProperty("requestId"))
                            object.requestId = message.requestId;
                        if (message.status != null && message.hasOwnProperty("status"))
                            object.status = options.enums === String ? $root.pro.megakit.amelia.MessageProto.ResponseStatus[message.status] : message.status;
                        if (message.code != null && message.hasOwnProperty("code"))
                            object.code = message.code;
                        if (message.properties && message.properties.length) {
                            object.properties = [];
                            for (var j = 0; j < message.properties.length; ++j)
                                object.properties[j] = $root.pro.megakit.amelia.MessageProto.MessageProperty.toObject(message.properties[j], options);
                        }
                        if (message.body != null && message.hasOwnProperty("body"))
                            object.body = options.bytes === String ? $util.base64.encode(message.body, 0, message.body.length) : options.bytes === Array ? Array.prototype.slice.call(message.body) : message.body;
                        return object;
                    };

                    /**
                     * Converts this ResponseMessage to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.MessageProto.ResponseMessage
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ResponseMessage.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ResponseMessage;
                })();

                MessageProto.UnitMessage = (function() {

                    /**
                     * Properties of an UnitMessage.
                     * @memberof pro.megakit.amelia.MessageProto
                     * @interface IUnitMessage
                     */

                    /**
                     * Constructs a new UnitMessage.
                     * @memberof pro.megakit.amelia.MessageProto
                     * @classdesc Represents an UnitMessage.
                     * @implements IUnitMessage
                     * @constructor
                     * @param {pro.megakit.amelia.MessageProto.IUnitMessage=} [properties] Properties to set
                     */
                    function UnitMessage(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new UnitMessage instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.MessageProto.UnitMessage
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.IUnitMessage=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.MessageProto.UnitMessage} UnitMessage instance
                     */
                    UnitMessage.create = function create(properties) {
                        return new UnitMessage(properties);
                    };

                    /**
                     * Encodes the specified UnitMessage message. Does not implicitly {@link pro.megakit.amelia.MessageProto.UnitMessage.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.MessageProto.UnitMessage
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.IUnitMessage} message UnitMessage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UnitMessage.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified UnitMessage message, length delimited. Does not implicitly {@link pro.megakit.amelia.MessageProto.UnitMessage.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.MessageProto.UnitMessage
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.IUnitMessage} message UnitMessage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UnitMessage.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an UnitMessage message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.MessageProto.UnitMessage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.MessageProto.UnitMessage} UnitMessage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UnitMessage.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.MessageProto.UnitMessage();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an UnitMessage message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.MessageProto.UnitMessage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.MessageProto.UnitMessage} UnitMessage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UnitMessage.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an UnitMessage message.
                     * @function verify
                     * @memberof pro.megakit.amelia.MessageProto.UnitMessage
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UnitMessage.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates an UnitMessage message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.MessageProto.UnitMessage
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.MessageProto.UnitMessage} UnitMessage
                     */
                    UnitMessage.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.MessageProto.UnitMessage)
                            return object;
                        return new $root.pro.megakit.amelia.MessageProto.UnitMessage();
                    };

                    /**
                     * Creates a plain object from an UnitMessage message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.MessageProto.UnitMessage
                     * @static
                     * @param {pro.megakit.amelia.MessageProto.UnitMessage} message UnitMessage
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UnitMessage.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this UnitMessage to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.MessageProto.UnitMessage
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UnitMessage.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return UnitMessage;
                })();

                return MessageProto;
            })();

            amelia.SubscriptionProto = (function() {

                /**
                 * Properties of a SubscriptionProto.
                 * @memberof pro.megakit.amelia
                 * @interface ISubscriptionProto
                 */

                /**
                 * Constructs a new SubscriptionProto.
                 * @memberof pro.megakit.amelia
                 * @classdesc Represents a SubscriptionProto.
                 * @implements ISubscriptionProto
                 * @constructor
                 * @param {pro.megakit.amelia.ISubscriptionProto=} [properties] Properties to set
                 */
                function SubscriptionProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SubscriptionProto instance using the specified properties.
                 * @function create
                 * @memberof pro.megakit.amelia.SubscriptionProto
                 * @static
                 * @param {pro.megakit.amelia.ISubscriptionProto=} [properties] Properties to set
                 * @returns {pro.megakit.amelia.SubscriptionProto} SubscriptionProto instance
                 */
                SubscriptionProto.create = function create(properties) {
                    return new SubscriptionProto(properties);
                };

                /**
                 * Encodes the specified SubscriptionProto message. Does not implicitly {@link pro.megakit.amelia.SubscriptionProto.verify|verify} messages.
                 * @function encode
                 * @memberof pro.megakit.amelia.SubscriptionProto
                 * @static
                 * @param {pro.megakit.amelia.ISubscriptionProto} message SubscriptionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SubscriptionProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SubscriptionProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.SubscriptionProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof pro.megakit.amelia.SubscriptionProto
                 * @static
                 * @param {pro.megakit.amelia.ISubscriptionProto} message SubscriptionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SubscriptionProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SubscriptionProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof pro.megakit.amelia.SubscriptionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {pro.megakit.amelia.SubscriptionProto} SubscriptionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SubscriptionProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.SubscriptionProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SubscriptionProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof pro.megakit.amelia.SubscriptionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {pro.megakit.amelia.SubscriptionProto} SubscriptionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SubscriptionProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SubscriptionProto message.
                 * @function verify
                 * @memberof pro.megakit.amelia.SubscriptionProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SubscriptionProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SubscriptionProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof pro.megakit.amelia.SubscriptionProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {pro.megakit.amelia.SubscriptionProto} SubscriptionProto
                 */
                SubscriptionProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.pro.megakit.amelia.SubscriptionProto)
                        return object;
                    return new $root.pro.megakit.amelia.SubscriptionProto();
                };

                /**
                 * Creates a plain object from a SubscriptionProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof pro.megakit.amelia.SubscriptionProto
                 * @static
                 * @param {pro.megakit.amelia.SubscriptionProto} message SubscriptionProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SubscriptionProto.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SubscriptionProto to JSON.
                 * @function toJSON
                 * @memberof pro.megakit.amelia.SubscriptionProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SubscriptionProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                SubscriptionProto.Subscription = (function() {

                    /**
                     * Properties of a Subscription.
                     * @memberof pro.megakit.amelia.SubscriptionProto
                     * @interface ISubscription
                     * @property {string|null} [id] Subscription id
                     * @property {string|null} [name] Subscription name
                     * @property {Array.<pro.megakit.amelia.MessageProto.IMessageProperty>|null} [properties] Subscription properties
                     */

                    /**
                     * Constructs a new Subscription.
                     * @memberof pro.megakit.amelia.SubscriptionProto
                     * @classdesc Represents a Subscription.
                     * @implements ISubscription
                     * @constructor
                     * @param {pro.megakit.amelia.SubscriptionProto.ISubscription=} [properties] Properties to set
                     */
                    function Subscription(properties) {
                        this.properties = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Subscription id.
                     * @member {string} id
                     * @memberof pro.megakit.amelia.SubscriptionProto.Subscription
                     * @instance
                     */
                    Subscription.prototype.id = "";

                    /**
                     * Subscription name.
                     * @member {string} name
                     * @memberof pro.megakit.amelia.SubscriptionProto.Subscription
                     * @instance
                     */
                    Subscription.prototype.name = "";

                    /**
                     * Subscription properties.
                     * @member {Array.<pro.megakit.amelia.MessageProto.IMessageProperty>} properties
                     * @memberof pro.megakit.amelia.SubscriptionProto.Subscription
                     * @instance
                     */
                    Subscription.prototype.properties = $util.emptyArray;

                    /**
                     * Creates a new Subscription instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.SubscriptionProto.Subscription
                     * @static
                     * @param {pro.megakit.amelia.SubscriptionProto.ISubscription=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.SubscriptionProto.Subscription} Subscription instance
                     */
                    Subscription.create = function create(properties) {
                        return new Subscription(properties);
                    };

                    /**
                     * Encodes the specified Subscription message. Does not implicitly {@link pro.megakit.amelia.SubscriptionProto.Subscription.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.SubscriptionProto.Subscription
                     * @static
                     * @param {pro.megakit.amelia.SubscriptionProto.ISubscription} message Subscription message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Subscription.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.properties != null && message.properties.length)
                            for (var i = 0; i < message.properties.length; ++i)
                                $root.pro.megakit.amelia.MessageProto.MessageProperty.encode(message.properties[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Subscription message, length delimited. Does not implicitly {@link pro.megakit.amelia.SubscriptionProto.Subscription.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.SubscriptionProto.Subscription
                     * @static
                     * @param {pro.megakit.amelia.SubscriptionProto.ISubscription} message Subscription message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Subscription.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Subscription message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.SubscriptionProto.Subscription
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.SubscriptionProto.Subscription} Subscription
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Subscription.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.SubscriptionProto.Subscription();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                if (!(message.properties && message.properties.length))
                                    message.properties = [];
                                message.properties.push($root.pro.megakit.amelia.MessageProto.MessageProperty.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Subscription message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.SubscriptionProto.Subscription
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.SubscriptionProto.Subscription} Subscription
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Subscription.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Subscription message.
                     * @function verify
                     * @memberof pro.megakit.amelia.SubscriptionProto.Subscription
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Subscription.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.properties != null && message.hasOwnProperty("properties")) {
                            if (!Array.isArray(message.properties))
                                return "properties: array expected";
                            for (var i = 0; i < message.properties.length; ++i) {
                                var error = $root.pro.megakit.amelia.MessageProto.MessageProperty.verify(message.properties[i]);
                                if (error)
                                    return "properties." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a Subscription message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.SubscriptionProto.Subscription
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.SubscriptionProto.Subscription} Subscription
                     */
                    Subscription.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.SubscriptionProto.Subscription)
                            return object;
                        var message = new $root.pro.megakit.amelia.SubscriptionProto.Subscription();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.properties) {
                            if (!Array.isArray(object.properties))
                                throw TypeError(".pro.megakit.amelia.SubscriptionProto.Subscription.properties: array expected");
                            message.properties = [];
                            for (var i = 0; i < object.properties.length; ++i) {
                                if (typeof object.properties[i] !== "object")
                                    throw TypeError(".pro.megakit.amelia.SubscriptionProto.Subscription.properties: object expected");
                                message.properties[i] = $root.pro.megakit.amelia.MessageProto.MessageProperty.fromObject(object.properties[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Subscription message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.SubscriptionProto.Subscription
                     * @static
                     * @param {pro.megakit.amelia.SubscriptionProto.Subscription} message Subscription
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Subscription.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.properties = [];
                        if (options.defaults) {
                            object.id = "";
                            object.name = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.properties && message.properties.length) {
                            object.properties = [];
                            for (var j = 0; j < message.properties.length; ++j)
                                object.properties[j] = $root.pro.megakit.amelia.MessageProto.MessageProperty.toObject(message.properties[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this Subscription to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.SubscriptionProto.Subscription
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Subscription.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Subscription;
                })();

                SubscriptionProto.SubscriptionCollection = (function() {

                    /**
                     * Properties of a SubscriptionCollection.
                     * @memberof pro.megakit.amelia.SubscriptionProto
                     * @interface ISubscriptionCollection
                     * @property {Array.<pro.megakit.amelia.SubscriptionProto.ISubscription>|null} [subscriptions] SubscriptionCollection subscriptions
                     */

                    /**
                     * Constructs a new SubscriptionCollection.
                     * @memberof pro.megakit.amelia.SubscriptionProto
                     * @classdesc Represents a SubscriptionCollection.
                     * @implements ISubscriptionCollection
                     * @constructor
                     * @param {pro.megakit.amelia.SubscriptionProto.ISubscriptionCollection=} [properties] Properties to set
                     */
                    function SubscriptionCollection(properties) {
                        this.subscriptions = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SubscriptionCollection subscriptions.
                     * @member {Array.<pro.megakit.amelia.SubscriptionProto.ISubscription>} subscriptions
                     * @memberof pro.megakit.amelia.SubscriptionProto.SubscriptionCollection
                     * @instance
                     */
                    SubscriptionCollection.prototype.subscriptions = $util.emptyArray;

                    /**
                     * Creates a new SubscriptionCollection instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.SubscriptionProto.SubscriptionCollection
                     * @static
                     * @param {pro.megakit.amelia.SubscriptionProto.ISubscriptionCollection=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.SubscriptionProto.SubscriptionCollection} SubscriptionCollection instance
                     */
                    SubscriptionCollection.create = function create(properties) {
                        return new SubscriptionCollection(properties);
                    };

                    /**
                     * Encodes the specified SubscriptionCollection message. Does not implicitly {@link pro.megakit.amelia.SubscriptionProto.SubscriptionCollection.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.SubscriptionProto.SubscriptionCollection
                     * @static
                     * @param {pro.megakit.amelia.SubscriptionProto.ISubscriptionCollection} message SubscriptionCollection message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SubscriptionCollection.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.subscriptions != null && message.subscriptions.length)
                            for (var i = 0; i < message.subscriptions.length; ++i)
                                $root.pro.megakit.amelia.SubscriptionProto.Subscription.encode(message.subscriptions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SubscriptionCollection message, length delimited. Does not implicitly {@link pro.megakit.amelia.SubscriptionProto.SubscriptionCollection.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.SubscriptionProto.SubscriptionCollection
                     * @static
                     * @param {pro.megakit.amelia.SubscriptionProto.ISubscriptionCollection} message SubscriptionCollection message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SubscriptionCollection.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SubscriptionCollection message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.SubscriptionProto.SubscriptionCollection
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.SubscriptionProto.SubscriptionCollection} SubscriptionCollection
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SubscriptionCollection.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.SubscriptionProto.SubscriptionCollection();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.subscriptions && message.subscriptions.length))
                                    message.subscriptions = [];
                                message.subscriptions.push($root.pro.megakit.amelia.SubscriptionProto.Subscription.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SubscriptionCollection message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.SubscriptionProto.SubscriptionCollection
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.SubscriptionProto.SubscriptionCollection} SubscriptionCollection
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SubscriptionCollection.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SubscriptionCollection message.
                     * @function verify
                     * @memberof pro.megakit.amelia.SubscriptionProto.SubscriptionCollection
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SubscriptionCollection.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.subscriptions != null && message.hasOwnProperty("subscriptions")) {
                            if (!Array.isArray(message.subscriptions))
                                return "subscriptions: array expected";
                            for (var i = 0; i < message.subscriptions.length; ++i) {
                                var error = $root.pro.megakit.amelia.SubscriptionProto.Subscription.verify(message.subscriptions[i]);
                                if (error)
                                    return "subscriptions." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a SubscriptionCollection message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.SubscriptionProto.SubscriptionCollection
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.SubscriptionProto.SubscriptionCollection} SubscriptionCollection
                     */
                    SubscriptionCollection.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.SubscriptionProto.SubscriptionCollection)
                            return object;
                        var message = new $root.pro.megakit.amelia.SubscriptionProto.SubscriptionCollection();
                        if (object.subscriptions) {
                            if (!Array.isArray(object.subscriptions))
                                throw TypeError(".pro.megakit.amelia.SubscriptionProto.SubscriptionCollection.subscriptions: array expected");
                            message.subscriptions = [];
                            for (var i = 0; i < object.subscriptions.length; ++i) {
                                if (typeof object.subscriptions[i] !== "object")
                                    throw TypeError(".pro.megakit.amelia.SubscriptionProto.SubscriptionCollection.subscriptions: object expected");
                                message.subscriptions[i] = $root.pro.megakit.amelia.SubscriptionProto.Subscription.fromObject(object.subscriptions[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SubscriptionCollection message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.SubscriptionProto.SubscriptionCollection
                     * @static
                     * @param {pro.megakit.amelia.SubscriptionProto.SubscriptionCollection} message SubscriptionCollection
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SubscriptionCollection.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.subscriptions = [];
                        if (message.subscriptions && message.subscriptions.length) {
                            object.subscriptions = [];
                            for (var j = 0; j < message.subscriptions.length; ++j)
                                object.subscriptions[j] = $root.pro.megakit.amelia.SubscriptionProto.Subscription.toObject(message.subscriptions[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this SubscriptionCollection to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.SubscriptionProto.SubscriptionCollection
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SubscriptionCollection.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SubscriptionCollection;
                })();

                SubscriptionProto.UnsubscribeMessage = (function() {

                    /**
                     * Properties of an UnsubscribeMessage.
                     * @memberof pro.megakit.amelia.SubscriptionProto
                     * @interface IUnsubscribeMessage
                     * @property {string|null} [id] UnsubscribeMessage id
                     */

                    /**
                     * Constructs a new UnsubscribeMessage.
                     * @memberof pro.megakit.amelia.SubscriptionProto
                     * @classdesc Represents an UnsubscribeMessage.
                     * @implements IUnsubscribeMessage
                     * @constructor
                     * @param {pro.megakit.amelia.SubscriptionProto.IUnsubscribeMessage=} [properties] Properties to set
                     */
                    function UnsubscribeMessage(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * UnsubscribeMessage id.
                     * @member {string} id
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage
                     * @instance
                     */
                    UnsubscribeMessage.prototype.id = "";

                    /**
                     * Creates a new UnsubscribeMessage instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage
                     * @static
                     * @param {pro.megakit.amelia.SubscriptionProto.IUnsubscribeMessage=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage} UnsubscribeMessage instance
                     */
                    UnsubscribeMessage.create = function create(properties) {
                        return new UnsubscribeMessage(properties);
                    };

                    /**
                     * Encodes the specified UnsubscribeMessage message. Does not implicitly {@link pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage
                     * @static
                     * @param {pro.megakit.amelia.SubscriptionProto.IUnsubscribeMessage} message UnsubscribeMessage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UnsubscribeMessage.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        return writer;
                    };

                    /**
                     * Encodes the specified UnsubscribeMessage message, length delimited. Does not implicitly {@link pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage
                     * @static
                     * @param {pro.megakit.amelia.SubscriptionProto.IUnsubscribeMessage} message UnsubscribeMessage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UnsubscribeMessage.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an UnsubscribeMessage message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage} UnsubscribeMessage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UnsubscribeMessage.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an UnsubscribeMessage message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage} UnsubscribeMessage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UnsubscribeMessage.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an UnsubscribeMessage message.
                     * @function verify
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UnsubscribeMessage.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        return null;
                    };

                    /**
                     * Creates an UnsubscribeMessage message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage} UnsubscribeMessage
                     */
                    UnsubscribeMessage.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage)
                            return object;
                        var message = new $root.pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage();
                        if (object.id != null)
                            message.id = String(object.id);
                        return message;
                    };

                    /**
                     * Creates a plain object from an UnsubscribeMessage message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage
                     * @static
                     * @param {pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage} message UnsubscribeMessage
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UnsubscribeMessage.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.id = "";
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        return object;
                    };

                    /**
                     * Converts this UnsubscribeMessage to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeMessage
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UnsubscribeMessage.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return UnsubscribeMessage;
                })();

                SubscriptionProto.UnsubscribeAllMessage = (function() {

                    /**
                     * Properties of an UnsubscribeAllMessage.
                     * @memberof pro.megakit.amelia.SubscriptionProto
                     * @interface IUnsubscribeAllMessage
                     * @property {string|null} [name] UnsubscribeAllMessage name
                     */

                    /**
                     * Constructs a new UnsubscribeAllMessage.
                     * @memberof pro.megakit.amelia.SubscriptionProto
                     * @classdesc Represents an UnsubscribeAllMessage.
                     * @implements IUnsubscribeAllMessage
                     * @constructor
                     * @param {pro.megakit.amelia.SubscriptionProto.IUnsubscribeAllMessage=} [properties] Properties to set
                     */
                    function UnsubscribeAllMessage(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * UnsubscribeAllMessage name.
                     * @member {string} name
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage
                     * @instance
                     */
                    UnsubscribeAllMessage.prototype.name = "";

                    /**
                     * Creates a new UnsubscribeAllMessage instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage
                     * @static
                     * @param {pro.megakit.amelia.SubscriptionProto.IUnsubscribeAllMessage=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage} UnsubscribeAllMessage instance
                     */
                    UnsubscribeAllMessage.create = function create(properties) {
                        return new UnsubscribeAllMessage(properties);
                    };

                    /**
                     * Encodes the specified UnsubscribeAllMessage message. Does not implicitly {@link pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage
                     * @static
                     * @param {pro.megakit.amelia.SubscriptionProto.IUnsubscribeAllMessage} message UnsubscribeAllMessage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UnsubscribeAllMessage.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };

                    /**
                     * Encodes the specified UnsubscribeAllMessage message, length delimited. Does not implicitly {@link pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage
                     * @static
                     * @param {pro.megakit.amelia.SubscriptionProto.IUnsubscribeAllMessage} message UnsubscribeAllMessage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UnsubscribeAllMessage.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an UnsubscribeAllMessage message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage} UnsubscribeAllMessage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UnsubscribeAllMessage.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an UnsubscribeAllMessage message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage} UnsubscribeAllMessage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UnsubscribeAllMessage.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an UnsubscribeAllMessage message.
                     * @function verify
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UnsubscribeAllMessage.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };

                    /**
                     * Creates an UnsubscribeAllMessage message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage} UnsubscribeAllMessage
                     */
                    UnsubscribeAllMessage.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage)
                            return object;
                        var message = new $root.pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };

                    /**
                     * Creates a plain object from an UnsubscribeAllMessage message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage
                     * @static
                     * @param {pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage} message UnsubscribeAllMessage
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UnsubscribeAllMessage.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };

                    /**
                     * Converts this UnsubscribeAllMessage to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.SubscriptionProto.UnsubscribeAllMessage
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UnsubscribeAllMessage.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return UnsubscribeAllMessage;
                })();

                return SubscriptionProto;
            })();

            amelia.EventProto = (function() {

                /**
                 * Properties of an EventProto.
                 * @memberof pro.megakit.amelia
                 * @interface IEventProto
                 */

                /**
                 * Constructs a new EventProto.
                 * @memberof pro.megakit.amelia
                 * @classdesc Represents an EventProto.
                 * @implements IEventProto
                 * @constructor
                 * @param {pro.megakit.amelia.IEventProto=} [properties] Properties to set
                 */
                function EventProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new EventProto instance using the specified properties.
                 * @function create
                 * @memberof pro.megakit.amelia.EventProto
                 * @static
                 * @param {pro.megakit.amelia.IEventProto=} [properties] Properties to set
                 * @returns {pro.megakit.amelia.EventProto} EventProto instance
                 */
                EventProto.create = function create(properties) {
                    return new EventProto(properties);
                };

                /**
                 * Encodes the specified EventProto message. Does not implicitly {@link pro.megakit.amelia.EventProto.verify|verify} messages.
                 * @function encode
                 * @memberof pro.megakit.amelia.EventProto
                 * @static
                 * @param {pro.megakit.amelia.IEventProto} message EventProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EventProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified EventProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.EventProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof pro.megakit.amelia.EventProto
                 * @static
                 * @param {pro.megakit.amelia.IEventProto} message EventProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EventProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EventProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof pro.megakit.amelia.EventProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {pro.megakit.amelia.EventProto} EventProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EventProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.EventProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EventProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof pro.megakit.amelia.EventProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {pro.megakit.amelia.EventProto} EventProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EventProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EventProto message.
                 * @function verify
                 * @memberof pro.megakit.amelia.EventProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EventProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an EventProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof pro.megakit.amelia.EventProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {pro.megakit.amelia.EventProto} EventProto
                 */
                EventProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.pro.megakit.amelia.EventProto)
                        return object;
                    return new $root.pro.megakit.amelia.EventProto();
                };

                /**
                 * Creates a plain object from an EventProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof pro.megakit.amelia.EventProto
                 * @static
                 * @param {pro.megakit.amelia.EventProto} message EventProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EventProto.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this EventProto to JSON.
                 * @function toJSON
                 * @memberof pro.megakit.amelia.EventProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EventProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                EventProto.EventMessage = (function() {

                    /**
                     * Properties of an EventMessage.
                     * @memberof pro.megakit.amelia.EventProto
                     * @interface IEventMessage
                     * @property {number|null} [id] EventMessage id
                     * @property {Uint8Array|null} [payload] EventMessage payload
                     * @property {string|null} [subscriptionId] EventMessage subscriptionId
                     */

                    /**
                     * Constructs a new EventMessage.
                     * @memberof pro.megakit.amelia.EventProto
                     * @classdesc Represents an EventMessage.
                     * @implements IEventMessage
                     * @constructor
                     * @param {pro.megakit.amelia.EventProto.IEventMessage=} [properties] Properties to set
                     */
                    function EventMessage(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * EventMessage id.
                     * @member {number} id
                     * @memberof pro.megakit.amelia.EventProto.EventMessage
                     * @instance
                     */
                    EventMessage.prototype.id = 0;

                    /**
                     * EventMessage payload.
                     * @member {Uint8Array} payload
                     * @memberof pro.megakit.amelia.EventProto.EventMessage
                     * @instance
                     */
                    EventMessage.prototype.payload = $util.newBuffer([]);

                    /**
                     * EventMessage subscriptionId.
                     * @member {string} subscriptionId
                     * @memberof pro.megakit.amelia.EventProto.EventMessage
                     * @instance
                     */
                    EventMessage.prototype.subscriptionId = "";

                    /**
                     * Creates a new EventMessage instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.EventProto.EventMessage
                     * @static
                     * @param {pro.megakit.amelia.EventProto.IEventMessage=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.EventProto.EventMessage} EventMessage instance
                     */
                    EventMessage.create = function create(properties) {
                        return new EventMessage(properties);
                    };

                    /**
                     * Encodes the specified EventMessage message. Does not implicitly {@link pro.megakit.amelia.EventProto.EventMessage.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.EventProto.EventMessage
                     * @static
                     * @param {pro.megakit.amelia.EventProto.IEventMessage} message EventMessage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EventMessage.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                        if (message.payload != null && message.hasOwnProperty("payload"))
                            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.payload);
                        if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.subscriptionId);
                        return writer;
                    };

                    /**
                     * Encodes the specified EventMessage message, length delimited. Does not implicitly {@link pro.megakit.amelia.EventProto.EventMessage.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.EventProto.EventMessage
                     * @static
                     * @param {pro.megakit.amelia.EventProto.IEventMessage} message EventMessage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EventMessage.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an EventMessage message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.EventProto.EventMessage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.EventProto.EventMessage} EventMessage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EventMessage.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.EventProto.EventMessage();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.uint32();
                                break;
                            case 2:
                                message.payload = reader.bytes();
                                break;
                            case 3:
                                message.subscriptionId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an EventMessage message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.EventProto.EventMessage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.EventProto.EventMessage} EventMessage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EventMessage.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an EventMessage message.
                     * @function verify
                     * @memberof pro.megakit.amelia.EventProto.EventMessage
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    EventMessage.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id))
                                return "id: integer expected";
                        if (message.payload != null && message.hasOwnProperty("payload"))
                            if (!(message.payload && typeof message.payload.length === "number" || $util.isString(message.payload)))
                                return "payload: buffer expected";
                        if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                            if (!$util.isString(message.subscriptionId))
                                return "subscriptionId: string expected";
                        return null;
                    };

                    /**
                     * Creates an EventMessage message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.EventProto.EventMessage
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.EventProto.EventMessage} EventMessage
                     */
                    EventMessage.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.EventProto.EventMessage)
                            return object;
                        var message = new $root.pro.megakit.amelia.EventProto.EventMessage();
                        if (object.id != null)
                            message.id = object.id >>> 0;
                        if (object.payload != null)
                            if (typeof object.payload === "string")
                                $util.base64.decode(object.payload, message.payload = $util.newBuffer($util.base64.length(object.payload)), 0);
                            else if (object.payload.length)
                                message.payload = object.payload;
                        if (object.subscriptionId != null)
                            message.subscriptionId = String(object.subscriptionId);
                        return message;
                    };

                    /**
                     * Creates a plain object from an EventMessage message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.EventProto.EventMessage
                     * @static
                     * @param {pro.megakit.amelia.EventProto.EventMessage} message EventMessage
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    EventMessage.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.id = 0;
                            if (options.bytes === String)
                                object.payload = "";
                            else {
                                object.payload = [];
                                if (options.bytes !== Array)
                                    object.payload = $util.newBuffer(object.payload);
                            }
                            object.subscriptionId = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.payload != null && message.hasOwnProperty("payload"))
                            object.payload = options.bytes === String ? $util.base64.encode(message.payload, 0, message.payload.length) : options.bytes === Array ? Array.prototype.slice.call(message.payload) : message.payload;
                        if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                            object.subscriptionId = message.subscriptionId;
                        return object;
                    };

                    /**
                     * Converts this EventMessage to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.EventProto.EventMessage
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    EventMessage.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return EventMessage;
                })();

                EventProto.DriverContextEvent = (function() {

                    /**
                     * Properties of a DriverContextEvent.
                     * @memberof pro.megakit.amelia.EventProto
                     * @interface IDriverContextEvent
                     * @property {string|null} [cabId] DriverContextEvent cabId
                     */

                    /**
                     * Constructs a new DriverContextEvent.
                     * @memberof pro.megakit.amelia.EventProto
                     * @classdesc Represents a DriverContextEvent.
                     * @implements IDriverContextEvent
                     * @constructor
                     * @param {pro.megakit.amelia.EventProto.IDriverContextEvent=} [properties] Properties to set
                     */
                    function DriverContextEvent(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DriverContextEvent cabId.
                     * @member {string} cabId
                     * @memberof pro.megakit.amelia.EventProto.DriverContextEvent
                     * @instance
                     */
                    DriverContextEvent.prototype.cabId = "";

                    /**
                     * Creates a new DriverContextEvent instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.EventProto.DriverContextEvent
                     * @static
                     * @param {pro.megakit.amelia.EventProto.IDriverContextEvent=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.EventProto.DriverContextEvent} DriverContextEvent instance
                     */
                    DriverContextEvent.create = function create(properties) {
                        return new DriverContextEvent(properties);
                    };

                    /**
                     * Encodes the specified DriverContextEvent message. Does not implicitly {@link pro.megakit.amelia.EventProto.DriverContextEvent.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.EventProto.DriverContextEvent
                     * @static
                     * @param {pro.megakit.amelia.EventProto.IDriverContextEvent} message DriverContextEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverContextEvent.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cabId != null && message.hasOwnProperty("cabId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.cabId);
                        return writer;
                    };

                    /**
                     * Encodes the specified DriverContextEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.EventProto.DriverContextEvent.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.EventProto.DriverContextEvent
                     * @static
                     * @param {pro.megakit.amelia.EventProto.IDriverContextEvent} message DriverContextEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverContextEvent.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DriverContextEvent message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.EventProto.DriverContextEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.EventProto.DriverContextEvent} DriverContextEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverContextEvent.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.EventProto.DriverContextEvent();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cabId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DriverContextEvent message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.EventProto.DriverContextEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.EventProto.DriverContextEvent} DriverContextEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverContextEvent.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DriverContextEvent message.
                     * @function verify
                     * @memberof pro.megakit.amelia.EventProto.DriverContextEvent
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DriverContextEvent.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cabId != null && message.hasOwnProperty("cabId"))
                            if (!$util.isString(message.cabId))
                                return "cabId: string expected";
                        return null;
                    };

                    /**
                     * Creates a DriverContextEvent message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.EventProto.DriverContextEvent
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.EventProto.DriverContextEvent} DriverContextEvent
                     */
                    DriverContextEvent.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.EventProto.DriverContextEvent)
                            return object;
                        var message = new $root.pro.megakit.amelia.EventProto.DriverContextEvent();
                        if (object.cabId != null)
                            message.cabId = String(object.cabId);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DriverContextEvent message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.EventProto.DriverContextEvent
                     * @static
                     * @param {pro.megakit.amelia.EventProto.DriverContextEvent} message DriverContextEvent
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DriverContextEvent.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.cabId = "";
                        if (message.cabId != null && message.hasOwnProperty("cabId"))
                            object.cabId = message.cabId;
                        return object;
                    };

                    /**
                     * Converts this DriverContextEvent to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.EventProto.DriverContextEvent
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DriverContextEvent.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DriverContextEvent;
                })();

                EventProto.OperatorContextEvent = (function() {

                    /**
                     * Properties of an OperatorContextEvent.
                     * @memberof pro.megakit.amelia.EventProto
                     * @interface IOperatorContextEvent
                     * @property {string|null} [userId] OperatorContextEvent userId
                     * @property {string|null} [name] OperatorContextEvent name
                     */

                    /**
                     * Constructs a new OperatorContextEvent.
                     * @memberof pro.megakit.amelia.EventProto
                     * @classdesc Represents an OperatorContextEvent.
                     * @implements IOperatorContextEvent
                     * @constructor
                     * @param {pro.megakit.amelia.EventProto.IOperatorContextEvent=} [properties] Properties to set
                     */
                    function OperatorContextEvent(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OperatorContextEvent userId.
                     * @member {string} userId
                     * @memberof pro.megakit.amelia.EventProto.OperatorContextEvent
                     * @instance
                     */
                    OperatorContextEvent.prototype.userId = "";

                    /**
                     * OperatorContextEvent name.
                     * @member {string} name
                     * @memberof pro.megakit.amelia.EventProto.OperatorContextEvent
                     * @instance
                     */
                    OperatorContextEvent.prototype.name = "";

                    /**
                     * Creates a new OperatorContextEvent instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.EventProto.OperatorContextEvent
                     * @static
                     * @param {pro.megakit.amelia.EventProto.IOperatorContextEvent=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.EventProto.OperatorContextEvent} OperatorContextEvent instance
                     */
                    OperatorContextEvent.create = function create(properties) {
                        return new OperatorContextEvent(properties);
                    };

                    /**
                     * Encodes the specified OperatorContextEvent message. Does not implicitly {@link pro.megakit.amelia.EventProto.OperatorContextEvent.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.EventProto.OperatorContextEvent
                     * @static
                     * @param {pro.megakit.amelia.EventProto.IOperatorContextEvent} message OperatorContextEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OperatorContextEvent.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.userId != null && message.hasOwnProperty("userId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        return writer;
                    };

                    /**
                     * Encodes the specified OperatorContextEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.EventProto.OperatorContextEvent.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.EventProto.OperatorContextEvent
                     * @static
                     * @param {pro.megakit.amelia.EventProto.IOperatorContextEvent} message OperatorContextEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OperatorContextEvent.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OperatorContextEvent message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.EventProto.OperatorContextEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.EventProto.OperatorContextEvent} OperatorContextEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OperatorContextEvent.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.EventProto.OperatorContextEvent();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.userId = reader.string();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OperatorContextEvent message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.EventProto.OperatorContextEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.EventProto.OperatorContextEvent} OperatorContextEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OperatorContextEvent.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OperatorContextEvent message.
                     * @function verify
                     * @memberof pro.megakit.amelia.EventProto.OperatorContextEvent
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OperatorContextEvent.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userId != null && message.hasOwnProperty("userId"))
                            if (!$util.isString(message.userId))
                                return "userId: string expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };

                    /**
                     * Creates an OperatorContextEvent message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.EventProto.OperatorContextEvent
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.EventProto.OperatorContextEvent} OperatorContextEvent
                     */
                    OperatorContextEvent.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.EventProto.OperatorContextEvent)
                            return object;
                        var message = new $root.pro.megakit.amelia.EventProto.OperatorContextEvent();
                        if (object.userId != null)
                            message.userId = String(object.userId);
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };

                    /**
                     * Creates a plain object from an OperatorContextEvent message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.EventProto.OperatorContextEvent
                     * @static
                     * @param {pro.megakit.amelia.EventProto.OperatorContextEvent} message OperatorContextEvent
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OperatorContextEvent.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.userId = "";
                            object.name = "";
                        }
                        if (message.userId != null && message.hasOwnProperty("userId"))
                            object.userId = message.userId;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };

                    /**
                     * Converts this OperatorContextEvent to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.EventProto.OperatorContextEvent
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OperatorContextEvent.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OperatorContextEvent;
                })();

                EventProto.DriverRequestEvent = (function() {

                    /**
                     * Properties of a DriverRequestEvent.
                     * @memberof pro.megakit.amelia.EventProto
                     * @interface IDriverRequestEvent
                     * @property {string|null} [id] DriverRequestEvent id
                     */

                    /**
                     * Constructs a new DriverRequestEvent.
                     * @memberof pro.megakit.amelia.EventProto
                     * @classdesc Represents a DriverRequestEvent.
                     * @implements IDriverRequestEvent
                     * @constructor
                     * @param {pro.megakit.amelia.EventProto.IDriverRequestEvent=} [properties] Properties to set
                     */
                    function DriverRequestEvent(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DriverRequestEvent id.
                     * @member {string} id
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestEvent
                     * @instance
                     */
                    DriverRequestEvent.prototype.id = "";

                    /**
                     * Creates a new DriverRequestEvent instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestEvent
                     * @static
                     * @param {pro.megakit.amelia.EventProto.IDriverRequestEvent=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.EventProto.DriverRequestEvent} DriverRequestEvent instance
                     */
                    DriverRequestEvent.create = function create(properties) {
                        return new DriverRequestEvent(properties);
                    };

                    /**
                     * Encodes the specified DriverRequestEvent message. Does not implicitly {@link pro.megakit.amelia.EventProto.DriverRequestEvent.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestEvent
                     * @static
                     * @param {pro.megakit.amelia.EventProto.IDriverRequestEvent} message DriverRequestEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverRequestEvent.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        return writer;
                    };

                    /**
                     * Encodes the specified DriverRequestEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.EventProto.DriverRequestEvent.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestEvent
                     * @static
                     * @param {pro.megakit.amelia.EventProto.IDriverRequestEvent} message DriverRequestEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverRequestEvent.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DriverRequestEvent message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.EventProto.DriverRequestEvent} DriverRequestEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverRequestEvent.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.EventProto.DriverRequestEvent();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DriverRequestEvent message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.EventProto.DriverRequestEvent} DriverRequestEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverRequestEvent.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DriverRequestEvent message.
                     * @function verify
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestEvent
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DriverRequestEvent.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        return null;
                    };

                    /**
                     * Creates a DriverRequestEvent message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestEvent
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.EventProto.DriverRequestEvent} DriverRequestEvent
                     */
                    DriverRequestEvent.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.EventProto.DriverRequestEvent)
                            return object;
                        var message = new $root.pro.megakit.amelia.EventProto.DriverRequestEvent();
                        if (object.id != null)
                            message.id = String(object.id);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DriverRequestEvent message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestEvent
                     * @static
                     * @param {pro.megakit.amelia.EventProto.DriverRequestEvent} message DriverRequestEvent
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DriverRequestEvent.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.id = "";
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        return object;
                    };

                    /**
                     * Converts this DriverRequestEvent to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestEvent
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DriverRequestEvent.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DriverRequestEvent;
                })();

                EventProto.DriverRequestConfirmedEvent = (function() {

                    /**
                     * Properties of a DriverRequestConfirmedEvent.
                     * @memberof pro.megakit.amelia.EventProto
                     * @interface IDriverRequestConfirmedEvent
                     * @property {string|null} [id] DriverRequestConfirmedEvent id
                     * @property {google.protobuf.ITimestamp|null} [assignedAt] DriverRequestConfirmedEvent assignedAt
                     */

                    /**
                     * Constructs a new DriverRequestConfirmedEvent.
                     * @memberof pro.megakit.amelia.EventProto
                     * @classdesc Represents a DriverRequestConfirmedEvent.
                     * @implements IDriverRequestConfirmedEvent
                     * @constructor
                     * @param {pro.megakit.amelia.EventProto.IDriverRequestConfirmedEvent=} [properties] Properties to set
                     */
                    function DriverRequestConfirmedEvent(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DriverRequestConfirmedEvent id.
                     * @member {string} id
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent
                     * @instance
                     */
                    DriverRequestConfirmedEvent.prototype.id = "";

                    /**
                     * DriverRequestConfirmedEvent assignedAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} assignedAt
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent
                     * @instance
                     */
                    DriverRequestConfirmedEvent.prototype.assignedAt = null;

                    /**
                     * Creates a new DriverRequestConfirmedEvent instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent
                     * @static
                     * @param {pro.megakit.amelia.EventProto.IDriverRequestConfirmedEvent=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent} DriverRequestConfirmedEvent instance
                     */
                    DriverRequestConfirmedEvent.create = function create(properties) {
                        return new DriverRequestConfirmedEvent(properties);
                    };

                    /**
                     * Encodes the specified DriverRequestConfirmedEvent message. Does not implicitly {@link pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent
                     * @static
                     * @param {pro.megakit.amelia.EventProto.IDriverRequestConfirmedEvent} message DriverRequestConfirmedEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverRequestConfirmedEvent.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.assignedAt != null && message.hasOwnProperty("assignedAt"))
                            $root.google.protobuf.Timestamp.encode(message.assignedAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified DriverRequestConfirmedEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent
                     * @static
                     * @param {pro.megakit.amelia.EventProto.IDriverRequestConfirmedEvent} message DriverRequestConfirmedEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverRequestConfirmedEvent.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DriverRequestConfirmedEvent message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent} DriverRequestConfirmedEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverRequestConfirmedEvent.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                message.assignedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DriverRequestConfirmedEvent message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent} DriverRequestConfirmedEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverRequestConfirmedEvent.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DriverRequestConfirmedEvent message.
                     * @function verify
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DriverRequestConfirmedEvent.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.assignedAt != null && message.hasOwnProperty("assignedAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.assignedAt);
                            if (error)
                                return "assignedAt." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a DriverRequestConfirmedEvent message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent} DriverRequestConfirmedEvent
                     */
                    DriverRequestConfirmedEvent.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent)
                            return object;
                        var message = new $root.pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.assignedAt != null) {
                            if (typeof object.assignedAt !== "object")
                                throw TypeError(".pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent.assignedAt: object expected");
                            message.assignedAt = $root.google.protobuf.Timestamp.fromObject(object.assignedAt);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DriverRequestConfirmedEvent message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent
                     * @static
                     * @param {pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent} message DriverRequestConfirmedEvent
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DriverRequestConfirmedEvent.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.id = "";
                            object.assignedAt = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.assignedAt != null && message.hasOwnProperty("assignedAt"))
                            object.assignedAt = $root.google.protobuf.Timestamp.toObject(message.assignedAt, options);
                        return object;
                    };

                    /**
                     * Converts this DriverRequestConfirmedEvent to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.EventProto.DriverRequestConfirmedEvent
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DriverRequestConfirmedEvent.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DriverRequestConfirmedEvent;
                })();

                return EventProto;
            })();

            amelia.TickerProto = (function() {

                /**
                 * Properties of a TickerProto.
                 * @memberof pro.megakit.amelia
                 * @interface ITickerProto
                 */

                /**
                 * Constructs a new TickerProto.
                 * @memberof pro.megakit.amelia
                 * @classdesc Represents a TickerProto.
                 * @implements ITickerProto
                 * @constructor
                 * @param {pro.megakit.amelia.ITickerProto=} [properties] Properties to set
                 */
                function TickerProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new TickerProto instance using the specified properties.
                 * @function create
                 * @memberof pro.megakit.amelia.TickerProto
                 * @static
                 * @param {pro.megakit.amelia.ITickerProto=} [properties] Properties to set
                 * @returns {pro.megakit.amelia.TickerProto} TickerProto instance
                 */
                TickerProto.create = function create(properties) {
                    return new TickerProto(properties);
                };

                /**
                 * Encodes the specified TickerProto message. Does not implicitly {@link pro.megakit.amelia.TickerProto.verify|verify} messages.
                 * @function encode
                 * @memberof pro.megakit.amelia.TickerProto
                 * @static
                 * @param {pro.megakit.amelia.ITickerProto} message TickerProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TickerProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified TickerProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.TickerProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof pro.megakit.amelia.TickerProto
                 * @static
                 * @param {pro.megakit.amelia.ITickerProto} message TickerProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TickerProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TickerProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof pro.megakit.amelia.TickerProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {pro.megakit.amelia.TickerProto} TickerProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TickerProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.TickerProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TickerProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof pro.megakit.amelia.TickerProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {pro.megakit.amelia.TickerProto} TickerProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TickerProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TickerProto message.
                 * @function verify
                 * @memberof pro.megakit.amelia.TickerProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TickerProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a TickerProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof pro.megakit.amelia.TickerProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {pro.megakit.amelia.TickerProto} TickerProto
                 */
                TickerProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.pro.megakit.amelia.TickerProto)
                        return object;
                    return new $root.pro.megakit.amelia.TickerProto();
                };

                /**
                 * Creates a plain object from a TickerProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof pro.megakit.amelia.TickerProto
                 * @static
                 * @param {pro.megakit.amelia.TickerProto} message TickerProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TickerProto.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this TickerProto to JSON.
                 * @function toJSON
                 * @memberof pro.megakit.amelia.TickerProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TickerProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                TickerProto.Tick = (function() {

                    /**
                     * Properties of a Tick.
                     * @memberof pro.megakit.amelia.TickerProto
                     * @interface ITick
                     * @property {google.protobuf.ITimestamp|null} [timestamp] Tick timestamp
                     */

                    /**
                     * Constructs a new Tick.
                     * @memberof pro.megakit.amelia.TickerProto
                     * @classdesc Represents a Tick.
                     * @implements ITick
                     * @constructor
                     * @param {pro.megakit.amelia.TickerProto.ITick=} [properties] Properties to set
                     */
                    function Tick(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Tick timestamp.
                     * @member {google.protobuf.ITimestamp|null|undefined} timestamp
                     * @memberof pro.megakit.amelia.TickerProto.Tick
                     * @instance
                     */
                    Tick.prototype.timestamp = null;

                    /**
                     * Creates a new Tick instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.TickerProto.Tick
                     * @static
                     * @param {pro.megakit.amelia.TickerProto.ITick=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.TickerProto.Tick} Tick instance
                     */
                    Tick.create = function create(properties) {
                        return new Tick(properties);
                    };

                    /**
                     * Encodes the specified Tick message. Does not implicitly {@link pro.megakit.amelia.TickerProto.Tick.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.TickerProto.Tick
                     * @static
                     * @param {pro.megakit.amelia.TickerProto.ITick} message Tick message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Tick.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                            $root.google.protobuf.Timestamp.encode(message.timestamp, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Tick message, length delimited. Does not implicitly {@link pro.megakit.amelia.TickerProto.Tick.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.TickerProto.Tick
                     * @static
                     * @param {pro.megakit.amelia.TickerProto.ITick} message Tick message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Tick.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Tick message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.TickerProto.Tick
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.TickerProto.Tick} Tick
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Tick.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.TickerProto.Tick();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.timestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Tick message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.TickerProto.Tick
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.TickerProto.Tick} Tick
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Tick.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Tick message.
                     * @function verify
                     * @memberof pro.megakit.amelia.TickerProto.Tick
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Tick.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.timestamp);
                            if (error)
                                return "timestamp." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a Tick message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.TickerProto.Tick
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.TickerProto.Tick} Tick
                     */
                    Tick.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.TickerProto.Tick)
                            return object;
                        var message = new $root.pro.megakit.amelia.TickerProto.Tick();
                        if (object.timestamp != null) {
                            if (typeof object.timestamp !== "object")
                                throw TypeError(".pro.megakit.amelia.TickerProto.Tick.timestamp: object expected");
                            message.timestamp = $root.google.protobuf.Timestamp.fromObject(object.timestamp);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Tick message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.TickerProto.Tick
                     * @static
                     * @param {pro.megakit.amelia.TickerProto.Tick} message Tick
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Tick.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.timestamp = null;
                        if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                            object.timestamp = $root.google.protobuf.Timestamp.toObject(message.timestamp, options);
                        return object;
                    };

                    /**
                     * Converts this Tick to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.TickerProto.Tick
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Tick.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Tick;
                })();

                TickerProto.TickEvent = (function() {

                    /**
                     * Properties of a TickEvent.
                     * @memberof pro.megakit.amelia.TickerProto
                     * @interface ITickEvent
                     * @property {pro.megakit.amelia.TickerProto.ITick|null} [tick] TickEvent tick
                     */

                    /**
                     * Constructs a new TickEvent.
                     * @memberof pro.megakit.amelia.TickerProto
                     * @classdesc Represents a TickEvent.
                     * @implements ITickEvent
                     * @constructor
                     * @param {pro.megakit.amelia.TickerProto.ITickEvent=} [properties] Properties to set
                     */
                    function TickEvent(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * TickEvent tick.
                     * @member {pro.megakit.amelia.TickerProto.ITick|null|undefined} tick
                     * @memberof pro.megakit.amelia.TickerProto.TickEvent
                     * @instance
                     */
                    TickEvent.prototype.tick = null;

                    /**
                     * Creates a new TickEvent instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.TickerProto.TickEvent
                     * @static
                     * @param {pro.megakit.amelia.TickerProto.ITickEvent=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.TickerProto.TickEvent} TickEvent instance
                     */
                    TickEvent.create = function create(properties) {
                        return new TickEvent(properties);
                    };

                    /**
                     * Encodes the specified TickEvent message. Does not implicitly {@link pro.megakit.amelia.TickerProto.TickEvent.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.TickerProto.TickEvent
                     * @static
                     * @param {pro.megakit.amelia.TickerProto.ITickEvent} message TickEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TickEvent.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.tick != null && message.hasOwnProperty("tick"))
                            $root.pro.megakit.amelia.TickerProto.Tick.encode(message.tick, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified TickEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.TickerProto.TickEvent.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.TickerProto.TickEvent
                     * @static
                     * @param {pro.megakit.amelia.TickerProto.ITickEvent} message TickEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TickEvent.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a TickEvent message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.TickerProto.TickEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.TickerProto.TickEvent} TickEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TickEvent.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.TickerProto.TickEvent();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.tick = $root.pro.megakit.amelia.TickerProto.Tick.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a TickEvent message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.TickerProto.TickEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.TickerProto.TickEvent} TickEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TickEvent.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a TickEvent message.
                     * @function verify
                     * @memberof pro.megakit.amelia.TickerProto.TickEvent
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TickEvent.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.tick != null && message.hasOwnProperty("tick")) {
                            var error = $root.pro.megakit.amelia.TickerProto.Tick.verify(message.tick);
                            if (error)
                                return "tick." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a TickEvent message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.TickerProto.TickEvent
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.TickerProto.TickEvent} TickEvent
                     */
                    TickEvent.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.TickerProto.TickEvent)
                            return object;
                        var message = new $root.pro.megakit.amelia.TickerProto.TickEvent();
                        if (object.tick != null) {
                            if (typeof object.tick !== "object")
                                throw TypeError(".pro.megakit.amelia.TickerProto.TickEvent.tick: object expected");
                            message.tick = $root.pro.megakit.amelia.TickerProto.Tick.fromObject(object.tick);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a TickEvent message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.TickerProto.TickEvent
                     * @static
                     * @param {pro.megakit.amelia.TickerProto.TickEvent} message TickEvent
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TickEvent.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.tick = null;
                        if (message.tick != null && message.hasOwnProperty("tick"))
                            object.tick = $root.pro.megakit.amelia.TickerProto.Tick.toObject(message.tick, options);
                        return object;
                    };

                    /**
                     * Converts this TickEvent to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.TickerProto.TickEvent
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TickEvent.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return TickEvent;
                })();

                return TickerProto;
            })();

            amelia.SessionProto = (function() {

                /**
                 * Properties of a SessionProto.
                 * @memberof pro.megakit.amelia
                 * @interface ISessionProto
                 */

                /**
                 * Constructs a new SessionProto.
                 * @memberof pro.megakit.amelia
                 * @classdesc Represents a SessionProto.
                 * @implements ISessionProto
                 * @constructor
                 * @param {pro.megakit.amelia.ISessionProto=} [properties] Properties to set
                 */
                function SessionProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SessionProto instance using the specified properties.
                 * @function create
                 * @memberof pro.megakit.amelia.SessionProto
                 * @static
                 * @param {pro.megakit.amelia.ISessionProto=} [properties] Properties to set
                 * @returns {pro.megakit.amelia.SessionProto} SessionProto instance
                 */
                SessionProto.create = function create(properties) {
                    return new SessionProto(properties);
                };

                /**
                 * Encodes the specified SessionProto message. Does not implicitly {@link pro.megakit.amelia.SessionProto.verify|verify} messages.
                 * @function encode
                 * @memberof pro.megakit.amelia.SessionProto
                 * @static
                 * @param {pro.megakit.amelia.ISessionProto} message SessionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SessionProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SessionProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.SessionProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof pro.megakit.amelia.SessionProto
                 * @static
                 * @param {pro.megakit.amelia.ISessionProto} message SessionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SessionProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SessionProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof pro.megakit.amelia.SessionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {pro.megakit.amelia.SessionProto} SessionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SessionProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.SessionProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SessionProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof pro.megakit.amelia.SessionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {pro.megakit.amelia.SessionProto} SessionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SessionProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SessionProto message.
                 * @function verify
                 * @memberof pro.megakit.amelia.SessionProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SessionProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SessionProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof pro.megakit.amelia.SessionProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {pro.megakit.amelia.SessionProto} SessionProto
                 */
                SessionProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.pro.megakit.amelia.SessionProto)
                        return object;
                    return new $root.pro.megakit.amelia.SessionProto();
                };

                /**
                 * Creates a plain object from a SessionProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof pro.megakit.amelia.SessionProto
                 * @static
                 * @param {pro.megakit.amelia.SessionProto} message SessionProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SessionProto.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SessionProto to JSON.
                 * @function toJSON
                 * @memberof pro.megakit.amelia.SessionProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SessionProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * SessionType enum.
                 * @name pro.megakit.amelia.SessionProto.SessionType
                 * @enum {string}
                 * @property {number} START_WORKING_SHIFT=0 START_WORKING_SHIFT value
                 * @property {number} FINISH_WORKING_SHIFT=1 FINISH_WORKING_SHIFT value
                 * @property {number} VEHICLE_REFILL=2 VEHICLE_REFILL value
                 * @property {number} START_VEHICLE_WASHING=3 START_VEHICLE_WASHING value
                 * @property {number} FINISH_VEHICLE_WASHING=4 FINISH_VEHICLE_WASHING value
                 * @property {number} STORE_DRIVER_EXPENSE=5 STORE_DRIVER_EXPENSE value
                 * @property {number} START_DRIVER_REST=6 START_DRIVER_REST value
                 * @property {number} CANCEL_DRIVER_REQUEST=7 CANCEL_DRIVER_REQUEST value
                 */
                SessionProto.SessionType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "START_WORKING_SHIFT"] = 0;
                    values[valuesById[1] = "FINISH_WORKING_SHIFT"] = 1;
                    values[valuesById[2] = "VEHICLE_REFILL"] = 2;
                    values[valuesById[3] = "START_VEHICLE_WASHING"] = 3;
                    values[valuesById[4] = "FINISH_VEHICLE_WASHING"] = 4;
                    values[valuesById[5] = "STORE_DRIVER_EXPENSE"] = 5;
                    values[valuesById[6] = "START_DRIVER_REST"] = 6;
                    values[valuesById[7] = "CANCEL_DRIVER_REQUEST"] = 7;
                    return values;
                })();

                SessionProto.SessionImage = (function() {

                    /**
                     * Properties of a SessionImage.
                     * @memberof pro.megakit.amelia.SessionProto
                     * @interface ISessionImage
                     * @property {string|null} [id] SessionImage id
                     * @property {string|null} [type] SessionImage type
                     */

                    /**
                     * Constructs a new SessionImage.
                     * @memberof pro.megakit.amelia.SessionProto
                     * @classdesc Represents a SessionImage.
                     * @implements ISessionImage
                     * @constructor
                     * @param {pro.megakit.amelia.SessionProto.ISessionImage=} [properties] Properties to set
                     */
                    function SessionImage(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SessionImage id.
                     * @member {string} id
                     * @memberof pro.megakit.amelia.SessionProto.SessionImage
                     * @instance
                     */
                    SessionImage.prototype.id = "";

                    /**
                     * SessionImage type.
                     * @member {string} type
                     * @memberof pro.megakit.amelia.SessionProto.SessionImage
                     * @instance
                     */
                    SessionImage.prototype.type = "";

                    /**
                     * Creates a new SessionImage instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.SessionProto.SessionImage
                     * @static
                     * @param {pro.megakit.amelia.SessionProto.ISessionImage=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.SessionProto.SessionImage} SessionImage instance
                     */
                    SessionImage.create = function create(properties) {
                        return new SessionImage(properties);
                    };

                    /**
                     * Encodes the specified SessionImage message. Does not implicitly {@link pro.megakit.amelia.SessionProto.SessionImage.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.SessionProto.SessionImage
                     * @static
                     * @param {pro.megakit.amelia.SessionProto.ISessionImage} message SessionImage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SessionImage.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.type != null && message.hasOwnProperty("type"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
                        return writer;
                    };

                    /**
                     * Encodes the specified SessionImage message, length delimited. Does not implicitly {@link pro.megakit.amelia.SessionProto.SessionImage.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.SessionProto.SessionImage
                     * @static
                     * @param {pro.megakit.amelia.SessionProto.ISessionImage} message SessionImage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SessionImage.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SessionImage message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.SessionProto.SessionImage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.SessionProto.SessionImage} SessionImage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SessionImage.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.SessionProto.SessionImage();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                message.type = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SessionImage message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.SessionProto.SessionImage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.SessionProto.SessionImage} SessionImage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SessionImage.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SessionImage message.
                     * @function verify
                     * @memberof pro.megakit.amelia.SessionProto.SessionImage
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SessionImage.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            if (!$util.isString(message.type))
                                return "type: string expected";
                        return null;
                    };

                    /**
                     * Creates a SessionImage message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.SessionProto.SessionImage
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.SessionProto.SessionImage} SessionImage
                     */
                    SessionImage.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.SessionProto.SessionImage)
                            return object;
                        var message = new $root.pro.megakit.amelia.SessionProto.SessionImage();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.type != null)
                            message.type = String(object.type);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SessionImage message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.SessionProto.SessionImage
                     * @static
                     * @param {pro.megakit.amelia.SessionProto.SessionImage} message SessionImage
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SessionImage.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.id = "";
                            object.type = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = message.type;
                        return object;
                    };

                    /**
                     * Converts this SessionImage to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.SessionProto.SessionImage
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SessionImage.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SessionImage;
                })();

                return SessionProto;
            })();

            amelia.DriverRestProto = (function() {

                /**
                 * Properties of a DriverRestProto.
                 * @memberof pro.megakit.amelia
                 * @interface IDriverRestProto
                 */

                /**
                 * Constructs a new DriverRestProto.
                 * @memberof pro.megakit.amelia
                 * @classdesc Represents a DriverRestProto.
                 * @implements IDriverRestProto
                 * @constructor
                 * @param {pro.megakit.amelia.IDriverRestProto=} [properties] Properties to set
                 */
                function DriverRestProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new DriverRestProto instance using the specified properties.
                 * @function create
                 * @memberof pro.megakit.amelia.DriverRestProto
                 * @static
                 * @param {pro.megakit.amelia.IDriverRestProto=} [properties] Properties to set
                 * @returns {pro.megakit.amelia.DriverRestProto} DriverRestProto instance
                 */
                DriverRestProto.create = function create(properties) {
                    return new DriverRestProto(properties);
                };

                /**
                 * Encodes the specified DriverRestProto message. Does not implicitly {@link pro.megakit.amelia.DriverRestProto.verify|verify} messages.
                 * @function encode
                 * @memberof pro.megakit.amelia.DriverRestProto
                 * @static
                 * @param {pro.megakit.amelia.IDriverRestProto} message DriverRestProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DriverRestProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified DriverRestProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.DriverRestProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof pro.megakit.amelia.DriverRestProto
                 * @static
                 * @param {pro.megakit.amelia.IDriverRestProto} message DriverRestProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DriverRestProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a DriverRestProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof pro.megakit.amelia.DriverRestProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {pro.megakit.amelia.DriverRestProto} DriverRestProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DriverRestProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.DriverRestProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a DriverRestProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof pro.megakit.amelia.DriverRestProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {pro.megakit.amelia.DriverRestProto} DriverRestProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DriverRestProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a DriverRestProto message.
                 * @function verify
                 * @memberof pro.megakit.amelia.DriverRestProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DriverRestProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a DriverRestProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof pro.megakit.amelia.DriverRestProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {pro.megakit.amelia.DriverRestProto} DriverRestProto
                 */
                DriverRestProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.pro.megakit.amelia.DriverRestProto)
                        return object;
                    return new $root.pro.megakit.amelia.DriverRestProto();
                };

                /**
                 * Creates a plain object from a DriverRestProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof pro.megakit.amelia.DriverRestProto
                 * @static
                 * @param {pro.megakit.amelia.DriverRestProto} message DriverRestProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DriverRestProto.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this DriverRestProto to JSON.
                 * @function toJSON
                 * @memberof pro.megakit.amelia.DriverRestProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DriverRestProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                DriverRestProto.DriverRestType = (function() {

                    /**
                     * Properties of a DriverRestType.
                     * @memberof pro.megakit.amelia.DriverRestProto
                     * @interface IDriverRestType
                     * @property {string|null} [id] DriverRestType id
                     * @property {string|null} [displayName] DriverRestType displayName
                     * @property {number|null} [duration] DriverRestType duration
                     * @property {number|null} [limit] DriverRestType limit
                     * @property {boolean|null} [requireAddress] DriverRestType requireAddress
                     */

                    /**
                     * Constructs a new DriverRestType.
                     * @memberof pro.megakit.amelia.DriverRestProto
                     * @classdesc Represents a DriverRestType.
                     * @implements IDriverRestType
                     * @constructor
                     * @param {pro.megakit.amelia.DriverRestProto.IDriverRestType=} [properties] Properties to set
                     */
                    function DriverRestType(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DriverRestType id.
                     * @member {string} id
                     * @memberof pro.megakit.amelia.DriverRestProto.DriverRestType
                     * @instance
                     */
                    DriverRestType.prototype.id = "";

                    /**
                     * DriverRestType displayName.
                     * @member {string} displayName
                     * @memberof pro.megakit.amelia.DriverRestProto.DriverRestType
                     * @instance
                     */
                    DriverRestType.prototype.displayName = "";

                    /**
                     * DriverRestType duration.
                     * @member {number} duration
                     * @memberof pro.megakit.amelia.DriverRestProto.DriverRestType
                     * @instance
                     */
                    DriverRestType.prototype.duration = 0;

                    /**
                     * DriverRestType limit.
                     * @member {number} limit
                     * @memberof pro.megakit.amelia.DriverRestProto.DriverRestType
                     * @instance
                     */
                    DriverRestType.prototype.limit = 0;

                    /**
                     * DriverRestType requireAddress.
                     * @member {boolean} requireAddress
                     * @memberof pro.megakit.amelia.DriverRestProto.DriverRestType
                     * @instance
                     */
                    DriverRestType.prototype.requireAddress = false;

                    /**
                     * Creates a new DriverRestType instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.DriverRestProto.DriverRestType
                     * @static
                     * @param {pro.megakit.amelia.DriverRestProto.IDriverRestType=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.DriverRestProto.DriverRestType} DriverRestType instance
                     */
                    DriverRestType.create = function create(properties) {
                        return new DriverRestType(properties);
                    };

                    /**
                     * Encodes the specified DriverRestType message. Does not implicitly {@link pro.megakit.amelia.DriverRestProto.DriverRestType.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.DriverRestProto.DriverRestType
                     * @static
                     * @param {pro.megakit.amelia.DriverRestProto.IDriverRestType} message DriverRestType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverRestType.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.displayName);
                        if (message.duration != null && message.hasOwnProperty("duration"))
                            writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.duration);
                        if (message.limit != null && message.hasOwnProperty("limit"))
                            writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.limit);
                        if (message.requireAddress != null && message.hasOwnProperty("requireAddress"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.requireAddress);
                        return writer;
                    };

                    /**
                     * Encodes the specified DriverRestType message, length delimited. Does not implicitly {@link pro.megakit.amelia.DriverRestProto.DriverRestType.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.DriverRestProto.DriverRestType
                     * @static
                     * @param {pro.megakit.amelia.DriverRestProto.IDriverRestType} message DriverRestType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverRestType.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DriverRestType message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.DriverRestProto.DriverRestType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.DriverRestProto.DriverRestType} DriverRestType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverRestType.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.DriverRestProto.DriverRestType();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                message.displayName = reader.string();
                                break;
                            case 3:
                                message.duration = reader.uint32();
                                break;
                            case 4:
                                message.limit = reader.uint32();
                                break;
                            case 5:
                                message.requireAddress = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DriverRestType message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.DriverRestProto.DriverRestType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.DriverRestProto.DriverRestType} DriverRestType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverRestType.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DriverRestType message.
                     * @function verify
                     * @memberof pro.megakit.amelia.DriverRestProto.DriverRestType
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DriverRestType.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            if (!$util.isString(message.displayName))
                                return "displayName: string expected";
                        if (message.duration != null && message.hasOwnProperty("duration"))
                            if (!$util.isInteger(message.duration))
                                return "duration: integer expected";
                        if (message.limit != null && message.hasOwnProperty("limit"))
                            if (!$util.isInteger(message.limit))
                                return "limit: integer expected";
                        if (message.requireAddress != null && message.hasOwnProperty("requireAddress"))
                            if (typeof message.requireAddress !== "boolean")
                                return "requireAddress: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a DriverRestType message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.DriverRestProto.DriverRestType
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.DriverRestProto.DriverRestType} DriverRestType
                     */
                    DriverRestType.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.DriverRestProto.DriverRestType)
                            return object;
                        var message = new $root.pro.megakit.amelia.DriverRestProto.DriverRestType();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.displayName != null)
                            message.displayName = String(object.displayName);
                        if (object.duration != null)
                            message.duration = object.duration >>> 0;
                        if (object.limit != null)
                            message.limit = object.limit >>> 0;
                        if (object.requireAddress != null)
                            message.requireAddress = Boolean(object.requireAddress);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DriverRestType message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.DriverRestProto.DriverRestType
                     * @static
                     * @param {pro.megakit.amelia.DriverRestProto.DriverRestType} message DriverRestType
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DriverRestType.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.id = "";
                            object.displayName = "";
                            object.duration = 0;
                            object.limit = 0;
                            object.requireAddress = false;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            object.displayName = message.displayName;
                        if (message.duration != null && message.hasOwnProperty("duration"))
                            object.duration = message.duration;
                        if (message.limit != null && message.hasOwnProperty("limit"))
                            object.limit = message.limit;
                        if (message.requireAddress != null && message.hasOwnProperty("requireAddress"))
                            object.requireAddress = message.requireAddress;
                        return object;
                    };

                    /**
                     * Converts this DriverRestType to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.DriverRestProto.DriverRestType
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DriverRestType.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DriverRestType;
                })();

                return DriverRestProto;
            })();

            amelia.VehicleRefillProto = (function() {

                /**
                 * Properties of a VehicleRefillProto.
                 * @memberof pro.megakit.amelia
                 * @interface IVehicleRefillProto
                 */

                /**
                 * Constructs a new VehicleRefillProto.
                 * @memberof pro.megakit.amelia
                 * @classdesc Represents a VehicleRefillProto.
                 * @implements IVehicleRefillProto
                 * @constructor
                 * @param {pro.megakit.amelia.IVehicleRefillProto=} [properties] Properties to set
                 */
                function VehicleRefillProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new VehicleRefillProto instance using the specified properties.
                 * @function create
                 * @memberof pro.megakit.amelia.VehicleRefillProto
                 * @static
                 * @param {pro.megakit.amelia.IVehicleRefillProto=} [properties] Properties to set
                 * @returns {pro.megakit.amelia.VehicleRefillProto} VehicleRefillProto instance
                 */
                VehicleRefillProto.create = function create(properties) {
                    return new VehicleRefillProto(properties);
                };

                /**
                 * Encodes the specified VehicleRefillProto message. Does not implicitly {@link pro.megakit.amelia.VehicleRefillProto.verify|verify} messages.
                 * @function encode
                 * @memberof pro.megakit.amelia.VehicleRefillProto
                 * @static
                 * @param {pro.megakit.amelia.IVehicleRefillProto} message VehicleRefillProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VehicleRefillProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified VehicleRefillProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.VehicleRefillProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof pro.megakit.amelia.VehicleRefillProto
                 * @static
                 * @param {pro.megakit.amelia.IVehicleRefillProto} message VehicleRefillProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VehicleRefillProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a VehicleRefillProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof pro.megakit.amelia.VehicleRefillProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {pro.megakit.amelia.VehicleRefillProto} VehicleRefillProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VehicleRefillProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.VehicleRefillProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a VehicleRefillProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof pro.megakit.amelia.VehicleRefillProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {pro.megakit.amelia.VehicleRefillProto} VehicleRefillProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VehicleRefillProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a VehicleRefillProto message.
                 * @function verify
                 * @memberof pro.megakit.amelia.VehicleRefillProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                VehicleRefillProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a VehicleRefillProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof pro.megakit.amelia.VehicleRefillProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {pro.megakit.amelia.VehicleRefillProto} VehicleRefillProto
                 */
                VehicleRefillProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.pro.megakit.amelia.VehicleRefillProto)
                        return object;
                    return new $root.pro.megakit.amelia.VehicleRefillProto();
                };

                /**
                 * Creates a plain object from a VehicleRefillProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof pro.megakit.amelia.VehicleRefillProto
                 * @static
                 * @param {pro.megakit.amelia.VehicleRefillProto} message VehicleRefillProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VehicleRefillProto.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this VehicleRefillProto to JSON.
                 * @function toJSON
                 * @memberof pro.megakit.amelia.VehicleRefillProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VehicleRefillProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * VehicleFuelType enum.
                 * @name pro.megakit.amelia.VehicleRefillProto.VehicleFuelType
                 * @enum {string}
                 * @property {number} PETROL=0 PETROL value
                 * @property {number} GAS=1 GAS value
                 */
                VehicleRefillProto.VehicleFuelType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "PETROL"] = 0;
                    values[valuesById[1] = "GAS"] = 1;
                    return values;
                })();

                return VehicleRefillProto;
            })();

            amelia.DriverPauseProto = (function() {

                /**
                 * Properties of a DriverPauseProto.
                 * @memberof pro.megakit.amelia
                 * @interface IDriverPauseProto
                 */

                /**
                 * Constructs a new DriverPauseProto.
                 * @memberof pro.megakit.amelia
                 * @classdesc Represents a DriverPauseProto.
                 * @implements IDriverPauseProto
                 * @constructor
                 * @param {pro.megakit.amelia.IDriverPauseProto=} [properties] Properties to set
                 */
                function DriverPauseProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new DriverPauseProto instance using the specified properties.
                 * @function create
                 * @memberof pro.megakit.amelia.DriverPauseProto
                 * @static
                 * @param {pro.megakit.amelia.IDriverPauseProto=} [properties] Properties to set
                 * @returns {pro.megakit.amelia.DriverPauseProto} DriverPauseProto instance
                 */
                DriverPauseProto.create = function create(properties) {
                    return new DriverPauseProto(properties);
                };

                /**
                 * Encodes the specified DriverPauseProto message. Does not implicitly {@link pro.megakit.amelia.DriverPauseProto.verify|verify} messages.
                 * @function encode
                 * @memberof pro.megakit.amelia.DriverPauseProto
                 * @static
                 * @param {pro.megakit.amelia.IDriverPauseProto} message DriverPauseProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DriverPauseProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified DriverPauseProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.DriverPauseProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof pro.megakit.amelia.DriverPauseProto
                 * @static
                 * @param {pro.megakit.amelia.IDriverPauseProto} message DriverPauseProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DriverPauseProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a DriverPauseProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof pro.megakit.amelia.DriverPauseProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {pro.megakit.amelia.DriverPauseProto} DriverPauseProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DriverPauseProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.DriverPauseProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a DriverPauseProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof pro.megakit.amelia.DriverPauseProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {pro.megakit.amelia.DriverPauseProto} DriverPauseProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DriverPauseProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a DriverPauseProto message.
                 * @function verify
                 * @memberof pro.megakit.amelia.DriverPauseProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DriverPauseProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a DriverPauseProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof pro.megakit.amelia.DriverPauseProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {pro.megakit.amelia.DriverPauseProto} DriverPauseProto
                 */
                DriverPauseProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.pro.megakit.amelia.DriverPauseProto)
                        return object;
                    return new $root.pro.megakit.amelia.DriverPauseProto();
                };

                /**
                 * Creates a plain object from a DriverPauseProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof pro.megakit.amelia.DriverPauseProto
                 * @static
                 * @param {pro.megakit.amelia.DriverPauseProto} message DriverPauseProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DriverPauseProto.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this DriverPauseProto to JSON.
                 * @function toJSON
                 * @memberof pro.megakit.amelia.DriverPauseProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DriverPauseProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * DriverPauseType enum.
                 * @name pro.megakit.amelia.DriverPauseProto.DriverPauseType
                 * @enum {string}
                 * @property {number} BASE=0 BASE value
                 */
                DriverPauseProto.DriverPauseType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "BASE"] = 0;
                    return values;
                })();

                /**
                 * DriverPauseStatus enum.
                 * @name pro.megakit.amelia.DriverPauseProto.DriverPauseStatus
                 * @enum {string}
                 * @property {number} CREATED=0 CREATED value
                 * @property {number} STARTED=1 STARTED value
                 * @property {number} FINISHED=2 FINISHED value
                 * @property {number} CANCELED=3 CANCELED value
                 */
                DriverPauseProto.DriverPauseStatus = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "CREATED"] = 0;
                    values[valuesById[1] = "STARTED"] = 1;
                    values[valuesById[2] = "FINISHED"] = 2;
                    values[valuesById[3] = "CANCELED"] = 3;
                    return values;
                })();

                return DriverPauseProto;
            })();

            amelia.driver = (function() {

                /**
                 * Namespace driver.
                 * @memberof pro.megakit.amelia
                 * @namespace
                 */
                var driver = {};

                driver.EventProto = (function() {

                    /**
                     * Properties of an EventProto.
                     * @memberof pro.megakit.amelia.driver
                     * @interface IEventProto
                     */

                    /**
                     * Constructs a new EventProto.
                     * @memberof pro.megakit.amelia.driver
                     * @classdesc Represents an EventProto.
                     * @implements IEventProto
                     * @constructor
                     * @param {pro.megakit.amelia.driver.IEventProto=} [properties] Properties to set
                     */
                    function EventProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new EventProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.driver.EventProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IEventProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.driver.EventProto} EventProto instance
                     */
                    EventProto.create = function create(properties) {
                        return new EventProto(properties);
                    };

                    /**
                     * Encodes the specified EventProto message. Does not implicitly {@link pro.megakit.amelia.driver.EventProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.driver.EventProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IEventProto} message EventProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EventProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified EventProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.EventProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.driver.EventProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IEventProto} message EventProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EventProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an EventProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.driver.EventProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.driver.EventProto} EventProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EventProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.EventProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an EventProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.driver.EventProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.driver.EventProto} EventProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EventProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an EventProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.driver.EventProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    EventProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates an EventProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.driver.EventProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.driver.EventProto} EventProto
                     */
                    EventProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.driver.EventProto)
                            return object;
                        return new $root.pro.megakit.amelia.driver.EventProto();
                    };

                    /**
                     * Creates a plain object from an EventProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.driver.EventProto
                     * @static
                     * @param {pro.megakit.amelia.driver.EventProto} message EventProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    EventProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this EventProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.driver.EventProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    EventProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    EventProto.DriverReceiveActiveSessionsEvent = (function() {

                        /**
                         * Properties of a DriverReceiveActiveSessionsEvent.
                         * @memberof pro.megakit.amelia.driver.EventProto
                         * @interface IDriverReceiveActiveSessionsEvent
                         * @property {Array.<pro.megakit.amelia.driver.SessionProto.IActiveSession>|null} [sessions] DriverReceiveActiveSessionsEvent sessions
                         */

                        /**
                         * Constructs a new DriverReceiveActiveSessionsEvent.
                         * @memberof pro.megakit.amelia.driver.EventProto
                         * @classdesc Represents a DriverReceiveActiveSessionsEvent.
                         * @implements IDriverReceiveActiveSessionsEvent
                         * @constructor
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverReceiveActiveSessionsEvent=} [properties] Properties to set
                         */
                        function DriverReceiveActiveSessionsEvent(properties) {
                            this.sessions = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DriverReceiveActiveSessionsEvent sessions.
                         * @member {Array.<pro.megakit.amelia.driver.SessionProto.IActiveSession>} sessions
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent
                         * @instance
                         */
                        DriverReceiveActiveSessionsEvent.prototype.sessions = $util.emptyArray;

                        /**
                         * Creates a new DriverReceiveActiveSessionsEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverReceiveActiveSessionsEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent} DriverReceiveActiveSessionsEvent instance
                         */
                        DriverReceiveActiveSessionsEvent.create = function create(properties) {
                            return new DriverReceiveActiveSessionsEvent(properties);
                        };

                        /**
                         * Encodes the specified DriverReceiveActiveSessionsEvent message. Does not implicitly {@link pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverReceiveActiveSessionsEvent} message DriverReceiveActiveSessionsEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverReceiveActiveSessionsEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.sessions != null && message.sessions.length)
                                for (var i = 0; i < message.sessions.length; ++i)
                                    $root.pro.megakit.amelia.driver.SessionProto.ActiveSession.encode(message.sessions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified DriverReceiveActiveSessionsEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverReceiveActiveSessionsEvent} message DriverReceiveActiveSessionsEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverReceiveActiveSessionsEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DriverReceiveActiveSessionsEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent} DriverReceiveActiveSessionsEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverReceiveActiveSessionsEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.sessions && message.sessions.length))
                                        message.sessions = [];
                                    message.sessions.push($root.pro.megakit.amelia.driver.SessionProto.ActiveSession.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DriverReceiveActiveSessionsEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent} DriverReceiveActiveSessionsEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverReceiveActiveSessionsEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DriverReceiveActiveSessionsEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DriverReceiveActiveSessionsEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.sessions != null && message.hasOwnProperty("sessions")) {
                                if (!Array.isArray(message.sessions))
                                    return "sessions: array expected";
                                for (var i = 0; i < message.sessions.length; ++i) {
                                    var error = $root.pro.megakit.amelia.driver.SessionProto.ActiveSession.verify(message.sessions[i]);
                                    if (error)
                                        return "sessions." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a DriverReceiveActiveSessionsEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent} DriverReceiveActiveSessionsEvent
                         */
                        DriverReceiveActiveSessionsEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent();
                            if (object.sessions) {
                                if (!Array.isArray(object.sessions))
                                    throw TypeError(".pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent.sessions: array expected");
                                message.sessions = [];
                                for (var i = 0; i < object.sessions.length; ++i) {
                                    if (typeof object.sessions[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent.sessions: object expected");
                                    message.sessions[i] = $root.pro.megakit.amelia.driver.SessionProto.ActiveSession.fromObject(object.sessions[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a DriverReceiveActiveSessionsEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent} message DriverReceiveActiveSessionsEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DriverReceiveActiveSessionsEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.sessions = [];
                            if (message.sessions && message.sessions.length) {
                                object.sessions = [];
                                for (var j = 0; j < message.sessions.length; ++j)
                                    object.sessions[j] = $root.pro.megakit.amelia.driver.SessionProto.ActiveSession.toObject(message.sessions[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this DriverReceiveActiveSessionsEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveSessionsEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DriverReceiveActiveSessionsEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DriverReceiveActiveSessionsEvent;
                    })();

                    EventProto.DriverReceiveActiveWorkingShiftEvent = (function() {

                        /**
                         * Properties of a DriverReceiveActiveWorkingShiftEvent.
                         * @memberof pro.megakit.amelia.driver.EventProto
                         * @interface IDriverReceiveActiveWorkingShiftEvent
                         * @property {pro.megakit.amelia.driver.WorkingShiftProto.IDriverActiveWorkingShift|null} [workingShift] DriverReceiveActiveWorkingShiftEvent workingShift
                         * @property {Array.<pro.megakit.amelia.driver.DriverRequestProto.IActiveDriverRequest>|null} [requests] DriverReceiveActiveWorkingShiftEvent requests
                         */

                        /**
                         * Constructs a new DriverReceiveActiveWorkingShiftEvent.
                         * @memberof pro.megakit.amelia.driver.EventProto
                         * @classdesc Represents a DriverReceiveActiveWorkingShiftEvent.
                         * @implements IDriverReceiveActiveWorkingShiftEvent
                         * @constructor
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverReceiveActiveWorkingShiftEvent=} [properties] Properties to set
                         */
                        function DriverReceiveActiveWorkingShiftEvent(properties) {
                            this.requests = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DriverReceiveActiveWorkingShiftEvent workingShift.
                         * @member {pro.megakit.amelia.driver.WorkingShiftProto.IDriverActiveWorkingShift|null|undefined} workingShift
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent
                         * @instance
                         */
                        DriverReceiveActiveWorkingShiftEvent.prototype.workingShift = null;

                        /**
                         * DriverReceiveActiveWorkingShiftEvent requests.
                         * @member {Array.<pro.megakit.amelia.driver.DriverRequestProto.IActiveDriverRequest>} requests
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent
                         * @instance
                         */
                        DriverReceiveActiveWorkingShiftEvent.prototype.requests = $util.emptyArray;

                        /**
                         * Creates a new DriverReceiveActiveWorkingShiftEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverReceiveActiveWorkingShiftEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent} DriverReceiveActiveWorkingShiftEvent instance
                         */
                        DriverReceiveActiveWorkingShiftEvent.create = function create(properties) {
                            return new DriverReceiveActiveWorkingShiftEvent(properties);
                        };

                        /**
                         * Encodes the specified DriverReceiveActiveWorkingShiftEvent message. Does not implicitly {@link pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverReceiveActiveWorkingShiftEvent} message DriverReceiveActiveWorkingShiftEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverReceiveActiveWorkingShiftEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.workingShift != null && message.hasOwnProperty("workingShift"))
                                $root.pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift.encode(message.workingShift, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.requests != null && message.requests.length)
                                for (var i = 0; i < message.requests.length; ++i)
                                    $root.pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest.encode(message.requests[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified DriverReceiveActiveWorkingShiftEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverReceiveActiveWorkingShiftEvent} message DriverReceiveActiveWorkingShiftEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverReceiveActiveWorkingShiftEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DriverReceiveActiveWorkingShiftEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent} DriverReceiveActiveWorkingShiftEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverReceiveActiveWorkingShiftEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.workingShift = $root.pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.requests && message.requests.length))
                                        message.requests = [];
                                    message.requests.push($root.pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DriverReceiveActiveWorkingShiftEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent} DriverReceiveActiveWorkingShiftEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverReceiveActiveWorkingShiftEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DriverReceiveActiveWorkingShiftEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DriverReceiveActiveWorkingShiftEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.workingShift != null && message.hasOwnProperty("workingShift")) {
                                var error = $root.pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift.verify(message.workingShift);
                                if (error)
                                    return "workingShift." + error;
                            }
                            if (message.requests != null && message.hasOwnProperty("requests")) {
                                if (!Array.isArray(message.requests))
                                    return "requests: array expected";
                                for (var i = 0; i < message.requests.length; ++i) {
                                    var error = $root.pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest.verify(message.requests[i]);
                                    if (error)
                                        return "requests." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a DriverReceiveActiveWorkingShiftEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent} DriverReceiveActiveWorkingShiftEvent
                         */
                        DriverReceiveActiveWorkingShiftEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent();
                            if (object.workingShift != null) {
                                if (typeof object.workingShift !== "object")
                                    throw TypeError(".pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent.workingShift: object expected");
                                message.workingShift = $root.pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift.fromObject(object.workingShift);
                            }
                            if (object.requests) {
                                if (!Array.isArray(object.requests))
                                    throw TypeError(".pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent.requests: array expected");
                                message.requests = [];
                                for (var i = 0; i < object.requests.length; ++i) {
                                    if (typeof object.requests[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent.requests: object expected");
                                    message.requests[i] = $root.pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest.fromObject(object.requests[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a DriverReceiveActiveWorkingShiftEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent} message DriverReceiveActiveWorkingShiftEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DriverReceiveActiveWorkingShiftEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.requests = [];
                            if (options.defaults)
                                object.workingShift = null;
                            if (message.workingShift != null && message.hasOwnProperty("workingShift"))
                                object.workingShift = $root.pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift.toObject(message.workingShift, options);
                            if (message.requests && message.requests.length) {
                                object.requests = [];
                                for (var j = 0; j < message.requests.length; ++j)
                                    object.requests[j] = $root.pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest.toObject(message.requests[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this DriverReceiveActiveWorkingShiftEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverReceiveActiveWorkingShiftEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DriverReceiveActiveWorkingShiftEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DriverReceiveActiveWorkingShiftEvent;
                    })();

                    EventProto.DriverStateEvent = (function() {

                        /**
                         * Properties of a DriverStateEvent.
                         * @memberof pro.megakit.amelia.driver.EventProto
                         * @interface IDriverStateEvent
                         * @property {pro.megakit.amelia.driver.DriverStateProto.IDriverState|null} [state] DriverStateEvent state
                         * @property {pro.megakit.amelia.driver.WorkingShiftProto.IDriverActiveWorkingShift|null} [workingShift] DriverStateEvent workingShift
                         * @property {Array.<pro.megakit.amelia.driver.DriverRequestProto.IActiveDriverRequest>|null} [requests] DriverStateEvent requests
                         * @property {Array.<pro.megakit.amelia.driver.SessionProto.IActiveSession>|null} [sessions] DriverStateEvent sessions
                         * @property {pro.megakit.amelia.driver.VehicleWashingProto.IActiveVehicleWashing|null} [vehicleWashing] DriverStateEvent vehicleWashing
                         * @property {pro.megakit.amelia.driver.DriverRestProto.IActiveDriverRest|null} [driverRest] DriverStateEvent driverRest
                         */

                        /**
                         * Constructs a new DriverStateEvent.
                         * @memberof pro.megakit.amelia.driver.EventProto
                         * @classdesc Represents a DriverStateEvent.
                         * @implements IDriverStateEvent
                         * @constructor
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverStateEvent=} [properties] Properties to set
                         */
                        function DriverStateEvent(properties) {
                            this.requests = [];
                            this.sessions = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DriverStateEvent state.
                         * @member {pro.megakit.amelia.driver.DriverStateProto.IDriverState|null|undefined} state
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverStateEvent
                         * @instance
                         */
                        DriverStateEvent.prototype.state = null;

                        /**
                         * DriverStateEvent workingShift.
                         * @member {pro.megakit.amelia.driver.WorkingShiftProto.IDriverActiveWorkingShift|null|undefined} workingShift
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverStateEvent
                         * @instance
                         */
                        DriverStateEvent.prototype.workingShift = null;

                        /**
                         * DriverStateEvent requests.
                         * @member {Array.<pro.megakit.amelia.driver.DriverRequestProto.IActiveDriverRequest>} requests
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverStateEvent
                         * @instance
                         */
                        DriverStateEvent.prototype.requests = $util.emptyArray;

                        /**
                         * DriverStateEvent sessions.
                         * @member {Array.<pro.megakit.amelia.driver.SessionProto.IActiveSession>} sessions
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverStateEvent
                         * @instance
                         */
                        DriverStateEvent.prototype.sessions = $util.emptyArray;

                        /**
                         * DriverStateEvent vehicleWashing.
                         * @member {pro.megakit.amelia.driver.VehicleWashingProto.IActiveVehicleWashing|null|undefined} vehicleWashing
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverStateEvent
                         * @instance
                         */
                        DriverStateEvent.prototype.vehicleWashing = null;

                        /**
                         * DriverStateEvent driverRest.
                         * @member {pro.megakit.amelia.driver.DriverRestProto.IActiveDriverRest|null|undefined} driverRest
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverStateEvent
                         * @instance
                         */
                        DriverStateEvent.prototype.driverRest = null;

                        /**
                         * Creates a new DriverStateEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverStateEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverStateEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverStateEvent} DriverStateEvent instance
                         */
                        DriverStateEvent.create = function create(properties) {
                            return new DriverStateEvent(properties);
                        };

                        /**
                         * Encodes the specified DriverStateEvent message. Does not implicitly {@link pro.megakit.amelia.driver.EventProto.DriverStateEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverStateEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverStateEvent} message DriverStateEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverStateEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.state != null && message.hasOwnProperty("state"))
                                $root.pro.megakit.amelia.driver.DriverStateProto.DriverState.encode(message.state, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.workingShift != null && message.hasOwnProperty("workingShift"))
                                $root.pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift.encode(message.workingShift, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.requests != null && message.requests.length)
                                for (var i = 0; i < message.requests.length; ++i)
                                    $root.pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest.encode(message.requests[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.sessions != null && message.sessions.length)
                                for (var i = 0; i < message.sessions.length; ++i)
                                    $root.pro.megakit.amelia.driver.SessionProto.ActiveSession.encode(message.sessions[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            if (message.vehicleWashing != null && message.hasOwnProperty("vehicleWashing"))
                                $root.pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing.encode(message.vehicleWashing, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            if (message.driverRest != null && message.hasOwnProperty("driverRest"))
                                $root.pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest.encode(message.driverRest, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified DriverStateEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.EventProto.DriverStateEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverStateEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverStateEvent} message DriverStateEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverStateEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DriverStateEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverStateEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverStateEvent} DriverStateEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverStateEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.EventProto.DriverStateEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.state = $root.pro.megakit.amelia.driver.DriverStateProto.DriverState.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.workingShift = $root.pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    if (!(message.requests && message.requests.length))
                                        message.requests = [];
                                    message.requests.push($root.pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest.decode(reader, reader.uint32()));
                                    break;
                                case 4:
                                    if (!(message.sessions && message.sessions.length))
                                        message.sessions = [];
                                    message.sessions.push($root.pro.megakit.amelia.driver.SessionProto.ActiveSession.decode(reader, reader.uint32()));
                                    break;
                                case 5:
                                    message.vehicleWashing = $root.pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing.decode(reader, reader.uint32());
                                    break;
                                case 6:
                                    message.driverRest = $root.pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DriverStateEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverStateEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverStateEvent} DriverStateEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverStateEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DriverStateEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverStateEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DriverStateEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.state != null && message.hasOwnProperty("state")) {
                                var error = $root.pro.megakit.amelia.driver.DriverStateProto.DriverState.verify(message.state);
                                if (error)
                                    return "state." + error;
                            }
                            if (message.workingShift != null && message.hasOwnProperty("workingShift")) {
                                var error = $root.pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift.verify(message.workingShift);
                                if (error)
                                    return "workingShift." + error;
                            }
                            if (message.requests != null && message.hasOwnProperty("requests")) {
                                if (!Array.isArray(message.requests))
                                    return "requests: array expected";
                                for (var i = 0; i < message.requests.length; ++i) {
                                    var error = $root.pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest.verify(message.requests[i]);
                                    if (error)
                                        return "requests." + error;
                                }
                            }
                            if (message.sessions != null && message.hasOwnProperty("sessions")) {
                                if (!Array.isArray(message.sessions))
                                    return "sessions: array expected";
                                for (var i = 0; i < message.sessions.length; ++i) {
                                    var error = $root.pro.megakit.amelia.driver.SessionProto.ActiveSession.verify(message.sessions[i]);
                                    if (error)
                                        return "sessions." + error;
                                }
                            }
                            if (message.vehicleWashing != null && message.hasOwnProperty("vehicleWashing")) {
                                var error = $root.pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing.verify(message.vehicleWashing);
                                if (error)
                                    return "vehicleWashing." + error;
                            }
                            if (message.driverRest != null && message.hasOwnProperty("driverRest")) {
                                var error = $root.pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest.verify(message.driverRest);
                                if (error)
                                    return "driverRest." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a DriverStateEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverStateEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverStateEvent} DriverStateEvent
                         */
                        DriverStateEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.EventProto.DriverStateEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.EventProto.DriverStateEvent();
                            if (object.state != null) {
                                if (typeof object.state !== "object")
                                    throw TypeError(".pro.megakit.amelia.driver.EventProto.DriverStateEvent.state: object expected");
                                message.state = $root.pro.megakit.amelia.driver.DriverStateProto.DriverState.fromObject(object.state);
                            }
                            if (object.workingShift != null) {
                                if (typeof object.workingShift !== "object")
                                    throw TypeError(".pro.megakit.amelia.driver.EventProto.DriverStateEvent.workingShift: object expected");
                                message.workingShift = $root.pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift.fromObject(object.workingShift);
                            }
                            if (object.requests) {
                                if (!Array.isArray(object.requests))
                                    throw TypeError(".pro.megakit.amelia.driver.EventProto.DriverStateEvent.requests: array expected");
                                message.requests = [];
                                for (var i = 0; i < object.requests.length; ++i) {
                                    if (typeof object.requests[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.driver.EventProto.DriverStateEvent.requests: object expected");
                                    message.requests[i] = $root.pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest.fromObject(object.requests[i]);
                                }
                            }
                            if (object.sessions) {
                                if (!Array.isArray(object.sessions))
                                    throw TypeError(".pro.megakit.amelia.driver.EventProto.DriverStateEvent.sessions: array expected");
                                message.sessions = [];
                                for (var i = 0; i < object.sessions.length; ++i) {
                                    if (typeof object.sessions[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.driver.EventProto.DriverStateEvent.sessions: object expected");
                                    message.sessions[i] = $root.pro.megakit.amelia.driver.SessionProto.ActiveSession.fromObject(object.sessions[i]);
                                }
                            }
                            if (object.vehicleWashing != null) {
                                if (typeof object.vehicleWashing !== "object")
                                    throw TypeError(".pro.megakit.amelia.driver.EventProto.DriverStateEvent.vehicleWashing: object expected");
                                message.vehicleWashing = $root.pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing.fromObject(object.vehicleWashing);
                            }
                            if (object.driverRest != null) {
                                if (typeof object.driverRest !== "object")
                                    throw TypeError(".pro.megakit.amelia.driver.EventProto.DriverStateEvent.driverRest: object expected");
                                message.driverRest = $root.pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest.fromObject(object.driverRest);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a DriverStateEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverStateEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.DriverStateEvent} message DriverStateEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DriverStateEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.requests = [];
                                object.sessions = [];
                            }
                            if (options.defaults) {
                                object.state = null;
                                object.workingShift = null;
                                object.vehicleWashing = null;
                                object.driverRest = null;
                            }
                            if (message.state != null && message.hasOwnProperty("state"))
                                object.state = $root.pro.megakit.amelia.driver.DriverStateProto.DriverState.toObject(message.state, options);
                            if (message.workingShift != null && message.hasOwnProperty("workingShift"))
                                object.workingShift = $root.pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift.toObject(message.workingShift, options);
                            if (message.requests && message.requests.length) {
                                object.requests = [];
                                for (var j = 0; j < message.requests.length; ++j)
                                    object.requests[j] = $root.pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest.toObject(message.requests[j], options);
                            }
                            if (message.sessions && message.sessions.length) {
                                object.sessions = [];
                                for (var j = 0; j < message.sessions.length; ++j)
                                    object.sessions[j] = $root.pro.megakit.amelia.driver.SessionProto.ActiveSession.toObject(message.sessions[j], options);
                            }
                            if (message.vehicleWashing != null && message.hasOwnProperty("vehicleWashing"))
                                object.vehicleWashing = $root.pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing.toObject(message.vehicleWashing, options);
                            if (message.driverRest != null && message.hasOwnProperty("driverRest"))
                                object.driverRest = $root.pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest.toObject(message.driverRest, options);
                            return object;
                        };

                        /**
                         * Converts this DriverStateEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverStateEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DriverStateEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DriverStateEvent;
                    })();

                    EventProto.DriverWorkingShiftEvent = (function() {

                        /**
                         * Properties of a DriverWorkingShiftEvent.
                         * @memberof pro.megakit.amelia.driver.EventProto
                         * @interface IDriverWorkingShiftEvent
                         * @property {string|null} [id] DriverWorkingShiftEvent id
                         */

                        /**
                         * Constructs a new DriverWorkingShiftEvent.
                         * @memberof pro.megakit.amelia.driver.EventProto
                         * @classdesc Represents a DriverWorkingShiftEvent.
                         * @implements IDriverWorkingShiftEvent
                         * @constructor
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverWorkingShiftEvent=} [properties] Properties to set
                         */
                        function DriverWorkingShiftEvent(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DriverWorkingShiftEvent id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent
                         * @instance
                         */
                        DriverWorkingShiftEvent.prototype.id = "";

                        /**
                         * Creates a new DriverWorkingShiftEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverWorkingShiftEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent} DriverWorkingShiftEvent instance
                         */
                        DriverWorkingShiftEvent.create = function create(properties) {
                            return new DriverWorkingShiftEvent(properties);
                        };

                        /**
                         * Encodes the specified DriverWorkingShiftEvent message. Does not implicitly {@link pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverWorkingShiftEvent} message DriverWorkingShiftEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverWorkingShiftEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified DriverWorkingShiftEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverWorkingShiftEvent} message DriverWorkingShiftEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverWorkingShiftEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DriverWorkingShiftEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent} DriverWorkingShiftEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverWorkingShiftEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DriverWorkingShiftEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent} DriverWorkingShiftEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverWorkingShiftEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DriverWorkingShiftEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DriverWorkingShiftEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a DriverWorkingShiftEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent} DriverWorkingShiftEvent
                         */
                        DriverWorkingShiftEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a DriverWorkingShiftEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent} message DriverWorkingShiftEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DriverWorkingShiftEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this DriverWorkingShiftEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverWorkingShiftEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DriverWorkingShiftEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DriverWorkingShiftEvent;
                    })();

                    EventProto.WorkingShiftTripCounterEvent = (function() {

                        /**
                         * Properties of a WorkingShiftTripCounterEvent.
                         * @memberof pro.megakit.amelia.driver.EventProto
                         * @interface IWorkingShiftTripCounterEvent
                         * @property {string|null} [wshId] WorkingShiftTripCounterEvent wshId
                         * @property {number|null} [tripsCount] WorkingShiftTripCounterEvent tripsCount
                         * @property {number|null} [tripsAmount] WorkingShiftTripCounterEvent tripsAmount
                         */

                        /**
                         * Constructs a new WorkingShiftTripCounterEvent.
                         * @memberof pro.megakit.amelia.driver.EventProto
                         * @classdesc Represents a WorkingShiftTripCounterEvent.
                         * @implements IWorkingShiftTripCounterEvent
                         * @constructor
                         * @param {pro.megakit.amelia.driver.EventProto.IWorkingShiftTripCounterEvent=} [properties] Properties to set
                         */
                        function WorkingShiftTripCounterEvent(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * WorkingShiftTripCounterEvent wshId.
                         * @member {string} wshId
                         * @memberof pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent
                         * @instance
                         */
                        WorkingShiftTripCounterEvent.prototype.wshId = "";

                        /**
                         * WorkingShiftTripCounterEvent tripsCount.
                         * @member {number} tripsCount
                         * @memberof pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent
                         * @instance
                         */
                        WorkingShiftTripCounterEvent.prototype.tripsCount = 0;

                        /**
                         * WorkingShiftTripCounterEvent tripsAmount.
                         * @member {number} tripsAmount
                         * @memberof pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent
                         * @instance
                         */
                        WorkingShiftTripCounterEvent.prototype.tripsAmount = 0;

                        /**
                         * Creates a new WorkingShiftTripCounterEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IWorkingShiftTripCounterEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent} WorkingShiftTripCounterEvent instance
                         */
                        WorkingShiftTripCounterEvent.create = function create(properties) {
                            return new WorkingShiftTripCounterEvent(properties);
                        };

                        /**
                         * Encodes the specified WorkingShiftTripCounterEvent message. Does not implicitly {@link pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IWorkingShiftTripCounterEvent} message WorkingShiftTripCounterEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        WorkingShiftTripCounterEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.wshId);
                            if (message.tripsCount != null && message.hasOwnProperty("tripsCount"))
                                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.tripsCount);
                            if (message.tripsAmount != null && message.hasOwnProperty("tripsAmount"))
                                writer.uint32(/* id 3, wireType 1 =*/25).double(message.tripsAmount);
                            return writer;
                        };

                        /**
                         * Encodes the specified WorkingShiftTripCounterEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IWorkingShiftTripCounterEvent} message WorkingShiftTripCounterEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        WorkingShiftTripCounterEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a WorkingShiftTripCounterEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent} WorkingShiftTripCounterEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        WorkingShiftTripCounterEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.wshId = reader.string();
                                    break;
                                case 2:
                                    message.tripsCount = reader.uint32();
                                    break;
                                case 3:
                                    message.tripsAmount = reader.double();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a WorkingShiftTripCounterEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent} WorkingShiftTripCounterEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        WorkingShiftTripCounterEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a WorkingShiftTripCounterEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        WorkingShiftTripCounterEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                if (!$util.isString(message.wshId))
                                    return "wshId: string expected";
                            if (message.tripsCount != null && message.hasOwnProperty("tripsCount"))
                                if (!$util.isInteger(message.tripsCount))
                                    return "tripsCount: integer expected";
                            if (message.tripsAmount != null && message.hasOwnProperty("tripsAmount"))
                                if (typeof message.tripsAmount !== "number")
                                    return "tripsAmount: number expected";
                            return null;
                        };

                        /**
                         * Creates a WorkingShiftTripCounterEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent} WorkingShiftTripCounterEvent
                         */
                        WorkingShiftTripCounterEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent();
                            if (object.wshId != null)
                                message.wshId = String(object.wshId);
                            if (object.tripsCount != null)
                                message.tripsCount = object.tripsCount >>> 0;
                            if (object.tripsAmount != null)
                                message.tripsAmount = Number(object.tripsAmount);
                            return message;
                        };

                        /**
                         * Creates a plain object from a WorkingShiftTripCounterEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent} message WorkingShiftTripCounterEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        WorkingShiftTripCounterEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.wshId = "";
                                object.tripsCount = 0;
                                object.tripsAmount = 0;
                            }
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                object.wshId = message.wshId;
                            if (message.tripsCount != null && message.hasOwnProperty("tripsCount"))
                                object.tripsCount = message.tripsCount;
                            if (message.tripsAmount != null && message.hasOwnProperty("tripsAmount"))
                                object.tripsAmount = options.json && !isFinite(message.tripsAmount) ? String(message.tripsAmount) : message.tripsAmount;
                            return object;
                        };

                        /**
                         * Converts this WorkingShiftTripCounterEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.EventProto.WorkingShiftTripCounterEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        WorkingShiftTripCounterEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return WorkingShiftTripCounterEvent;
                    })();

                    EventProto.DriverRestEvent = (function() {

                        /**
                         * Properties of a DriverRestEvent.
                         * @memberof pro.megakit.amelia.driver.EventProto
                         * @interface IDriverRestEvent
                         * @property {string|null} [id] DriverRestEvent id
                         */

                        /**
                         * Constructs a new DriverRestEvent.
                         * @memberof pro.megakit.amelia.driver.EventProto
                         * @classdesc Represents a DriverRestEvent.
                         * @implements IDriverRestEvent
                         * @constructor
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverRestEvent=} [properties] Properties to set
                         */
                        function DriverRestEvent(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DriverRestEvent id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverRestEvent
                         * @instance
                         */
                        DriverRestEvent.prototype.id = "";

                        /**
                         * Creates a new DriverRestEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverRestEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverRestEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverRestEvent} DriverRestEvent instance
                         */
                        DriverRestEvent.create = function create(properties) {
                            return new DriverRestEvent(properties);
                        };

                        /**
                         * Encodes the specified DriverRestEvent message. Does not implicitly {@link pro.megakit.amelia.driver.EventProto.DriverRestEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverRestEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverRestEvent} message DriverRestEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverRestEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified DriverRestEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.EventProto.DriverRestEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverRestEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IDriverRestEvent} message DriverRestEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverRestEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DriverRestEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverRestEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverRestEvent} DriverRestEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverRestEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.EventProto.DriverRestEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DriverRestEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverRestEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverRestEvent} DriverRestEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverRestEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DriverRestEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverRestEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DriverRestEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a DriverRestEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverRestEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.EventProto.DriverRestEvent} DriverRestEvent
                         */
                        DriverRestEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.EventProto.DriverRestEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.EventProto.DriverRestEvent();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a DriverRestEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverRestEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.DriverRestEvent} message DriverRestEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DriverRestEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this DriverRestEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.EventProto.DriverRestEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DriverRestEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DriverRestEvent;
                    })();

                    EventProto.VehicleWashingEvent = (function() {

                        /**
                         * Properties of a VehicleWashingEvent.
                         * @memberof pro.megakit.amelia.driver.EventProto
                         * @interface IVehicleWashingEvent
                         * @property {string|null} [id] VehicleWashingEvent id
                         */

                        /**
                         * Constructs a new VehicleWashingEvent.
                         * @memberof pro.megakit.amelia.driver.EventProto
                         * @classdesc Represents a VehicleWashingEvent.
                         * @implements IVehicleWashingEvent
                         * @constructor
                         * @param {pro.megakit.amelia.driver.EventProto.IVehicleWashingEvent=} [properties] Properties to set
                         */
                        function VehicleWashingEvent(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * VehicleWashingEvent id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.EventProto.VehicleWashingEvent
                         * @instance
                         */
                        VehicleWashingEvent.prototype.id = "";

                        /**
                         * Creates a new VehicleWashingEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.EventProto.VehicleWashingEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IVehicleWashingEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.EventProto.VehicleWashingEvent} VehicleWashingEvent instance
                         */
                        VehicleWashingEvent.create = function create(properties) {
                            return new VehicleWashingEvent(properties);
                        };

                        /**
                         * Encodes the specified VehicleWashingEvent message. Does not implicitly {@link pro.megakit.amelia.driver.EventProto.VehicleWashingEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.EventProto.VehicleWashingEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IVehicleWashingEvent} message VehicleWashingEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VehicleWashingEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified VehicleWashingEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.EventProto.VehicleWashingEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.EventProto.VehicleWashingEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.IVehicleWashingEvent} message VehicleWashingEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VehicleWashingEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a VehicleWashingEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.EventProto.VehicleWashingEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.EventProto.VehicleWashingEvent} VehicleWashingEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VehicleWashingEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.EventProto.VehicleWashingEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a VehicleWashingEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.EventProto.VehicleWashingEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.EventProto.VehicleWashingEvent} VehicleWashingEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VehicleWashingEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a VehicleWashingEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.EventProto.VehicleWashingEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        VehicleWashingEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a VehicleWashingEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.EventProto.VehicleWashingEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.EventProto.VehicleWashingEvent} VehicleWashingEvent
                         */
                        VehicleWashingEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.EventProto.VehicleWashingEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.EventProto.VehicleWashingEvent();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a VehicleWashingEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.EventProto.VehicleWashingEvent
                         * @static
                         * @param {pro.megakit.amelia.driver.EventProto.VehicleWashingEvent} message VehicleWashingEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        VehicleWashingEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this VehicleWashingEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.EventProto.VehicleWashingEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        VehicleWashingEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return VehicleWashingEvent;
                    })();

                    return EventProto;
                })();

                driver.DriverStateProto = (function() {

                    /**
                     * Properties of a DriverStateProto.
                     * @memberof pro.megakit.amelia.driver
                     * @interface IDriverStateProto
                     */

                    /**
                     * Constructs a new DriverStateProto.
                     * @memberof pro.megakit.amelia.driver
                     * @classdesc Represents a DriverStateProto.
                     * @implements IDriverStateProto
                     * @constructor
                     * @param {pro.megakit.amelia.driver.IDriverStateProto=} [properties] Properties to set
                     */
                    function DriverStateProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new DriverStateProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.driver.DriverStateProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IDriverStateProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.driver.DriverStateProto} DriverStateProto instance
                     */
                    DriverStateProto.create = function create(properties) {
                        return new DriverStateProto(properties);
                    };

                    /**
                     * Encodes the specified DriverStateProto message. Does not implicitly {@link pro.megakit.amelia.driver.DriverStateProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.driver.DriverStateProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IDriverStateProto} message DriverStateProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverStateProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified DriverStateProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverStateProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.driver.DriverStateProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IDriverStateProto} message DriverStateProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverStateProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DriverStateProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.driver.DriverStateProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.driver.DriverStateProto} DriverStateProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverStateProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverStateProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DriverStateProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.driver.DriverStateProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.driver.DriverStateProto} DriverStateProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverStateProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DriverStateProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.driver.DriverStateProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DriverStateProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a DriverStateProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.driver.DriverStateProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.driver.DriverStateProto} DriverStateProto
                     */
                    DriverStateProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.driver.DriverStateProto)
                            return object;
                        return new $root.pro.megakit.amelia.driver.DriverStateProto();
                    };

                    /**
                     * Creates a plain object from a DriverStateProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.driver.DriverStateProto
                     * @static
                     * @param {pro.megakit.amelia.driver.DriverStateProto} message DriverStateProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DriverStateProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this DriverStateProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.driver.DriverStateProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DriverStateProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * ActiveScreen enum.
                     * @name pro.megakit.amelia.driver.DriverStateProto.ActiveScreen
                     * @enum {string}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} MAIN=1 MAIN value
                     * @property {number} START_WORKING_SHIFT=2 START_WORKING_SHIFT value
                     * @property {number} START_WORKING_SHIFT_WAITING=3 START_WORKING_SHIFT_WAITING value
                     * @property {number} FINISH_WORKING_SHIFT=4 FINISH_WORKING_SHIFT value
                     * @property {number} FINISH_WORKING_SHIFT_WAITING=5 FINISH_WORKING_SHIFT_WAITING value
                     * @property {number} REQUEST_REFILL=6 REQUEST_REFILL value
                     * @property {number} REQUEST_REFILL_WAITING=7 REQUEST_REFILL_WAITING value
                     */
                    DriverStateProto.ActiveScreen = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "MAIN"] = 1;
                        values[valuesById[2] = "START_WORKING_SHIFT"] = 2;
                        values[valuesById[3] = "START_WORKING_SHIFT_WAITING"] = 3;
                        values[valuesById[4] = "FINISH_WORKING_SHIFT"] = 4;
                        values[valuesById[5] = "FINISH_WORKING_SHIFT_WAITING"] = 5;
                        values[valuesById[6] = "REQUEST_REFILL"] = 6;
                        values[valuesById[7] = "REQUEST_REFILL_WAITING"] = 7;
                        return values;
                    })();

                    DriverStateProto.DriverState = (function() {

                        /**
                         * Properties of a DriverState.
                         * @memberof pro.megakit.amelia.driver.DriverStateProto
                         * @interface IDriverState
                         * @property {pro.megakit.amelia.driver.DriverStateProto.ActiveScreen|null} [activeScreen] DriverState activeScreen
                         */

                        /**
                         * Constructs a new DriverState.
                         * @memberof pro.megakit.amelia.driver.DriverStateProto
                         * @classdesc Represents a DriverState.
                         * @implements IDriverState
                         * @constructor
                         * @param {pro.megakit.amelia.driver.DriverStateProto.IDriverState=} [properties] Properties to set
                         */
                        function DriverState(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DriverState activeScreen.
                         * @member {pro.megakit.amelia.driver.DriverStateProto.ActiveScreen} activeScreen
                         * @memberof pro.megakit.amelia.driver.DriverStateProto.DriverState
                         * @instance
                         */
                        DriverState.prototype.activeScreen = 0;

                        /**
                         * Creates a new DriverState instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.DriverStateProto.DriverState
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverStateProto.IDriverState=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.DriverStateProto.DriverState} DriverState instance
                         */
                        DriverState.create = function create(properties) {
                            return new DriverState(properties);
                        };

                        /**
                         * Encodes the specified DriverState message. Does not implicitly {@link pro.megakit.amelia.driver.DriverStateProto.DriverState.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.DriverStateProto.DriverState
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverStateProto.IDriverState} message DriverState message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverState.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.activeScreen != null && message.hasOwnProperty("activeScreen"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.activeScreen);
                            return writer;
                        };

                        /**
                         * Encodes the specified DriverState message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverStateProto.DriverState.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverStateProto.DriverState
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverStateProto.IDriverState} message DriverState message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverState.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DriverState message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.DriverStateProto.DriverState
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.DriverStateProto.DriverState} DriverState
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverState.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverStateProto.DriverState();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.activeScreen = reader.int32();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DriverState message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverStateProto.DriverState
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.DriverStateProto.DriverState} DriverState
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverState.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DriverState message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.DriverStateProto.DriverState
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DriverState.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.activeScreen != null && message.hasOwnProperty("activeScreen"))
                                switch (message.activeScreen) {
                                default:
                                    return "activeScreen: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                    break;
                                }
                            return null;
                        };

                        /**
                         * Creates a DriverState message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.DriverStateProto.DriverState
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.DriverStateProto.DriverState} DriverState
                         */
                        DriverState.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.DriverStateProto.DriverState)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.DriverStateProto.DriverState();
                            switch (object.activeScreen) {
                            case "UNKNOWN":
                            case 0:
                                message.activeScreen = 0;
                                break;
                            case "MAIN":
                            case 1:
                                message.activeScreen = 1;
                                break;
                            case "START_WORKING_SHIFT":
                            case 2:
                                message.activeScreen = 2;
                                break;
                            case "START_WORKING_SHIFT_WAITING":
                            case 3:
                                message.activeScreen = 3;
                                break;
                            case "FINISH_WORKING_SHIFT":
                            case 4:
                                message.activeScreen = 4;
                                break;
                            case "FINISH_WORKING_SHIFT_WAITING":
                            case 5:
                                message.activeScreen = 5;
                                break;
                            case "REQUEST_REFILL":
                            case 6:
                                message.activeScreen = 6;
                                break;
                            case "REQUEST_REFILL_WAITING":
                            case 7:
                                message.activeScreen = 7;
                                break;
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a DriverState message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.DriverStateProto.DriverState
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverStateProto.DriverState} message DriverState
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DriverState.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.activeScreen = options.enums === String ? "UNKNOWN" : 0;
                            if (message.activeScreen != null && message.hasOwnProperty("activeScreen"))
                                object.activeScreen = options.enums === String ? $root.pro.megakit.amelia.driver.DriverStateProto.ActiveScreen[message.activeScreen] : message.activeScreen;
                            return object;
                        };

                        /**
                         * Converts this DriverState to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.DriverStateProto.DriverState
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DriverState.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DriverState;
                    })();

                    return DriverStateProto;
                })();

                driver.WhoamiProto = (function() {

                    /**
                     * Properties of a WhoamiProto.
                     * @memberof pro.megakit.amelia.driver
                     * @interface IWhoamiProto
                     */

                    /**
                     * Constructs a new WhoamiProto.
                     * @memberof pro.megakit.amelia.driver
                     * @classdesc Represents a WhoamiProto.
                     * @implements IWhoamiProto
                     * @constructor
                     * @param {pro.megakit.amelia.driver.IWhoamiProto=} [properties] Properties to set
                     */
                    function WhoamiProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new WhoamiProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.driver.WhoamiProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IWhoamiProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.driver.WhoamiProto} WhoamiProto instance
                     */
                    WhoamiProto.create = function create(properties) {
                        return new WhoamiProto(properties);
                    };

                    /**
                     * Encodes the specified WhoamiProto message. Does not implicitly {@link pro.megakit.amelia.driver.WhoamiProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.driver.WhoamiProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IWhoamiProto} message WhoamiProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WhoamiProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified WhoamiProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.WhoamiProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.driver.WhoamiProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IWhoamiProto} message WhoamiProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WhoamiProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a WhoamiProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.driver.WhoamiProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.driver.WhoamiProto} WhoamiProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WhoamiProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.WhoamiProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a WhoamiProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.driver.WhoamiProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.driver.WhoamiProto} WhoamiProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WhoamiProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a WhoamiProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.driver.WhoamiProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    WhoamiProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a WhoamiProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.driver.WhoamiProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.driver.WhoamiProto} WhoamiProto
                     */
                    WhoamiProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.driver.WhoamiProto)
                            return object;
                        return new $root.pro.megakit.amelia.driver.WhoamiProto();
                    };

                    /**
                     * Creates a plain object from a WhoamiProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.driver.WhoamiProto
                     * @static
                     * @param {pro.megakit.amelia.driver.WhoamiProto} message WhoamiProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    WhoamiProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this WhoamiProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.driver.WhoamiProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    WhoamiProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    WhoamiProto.WhoamiResponse = (function() {

                        /**
                         * Properties of a WhoamiResponse.
                         * @memberof pro.megakit.amelia.driver.WhoamiProto
                         * @interface IWhoamiResponse
                         * @property {string|null} [cabId] WhoamiResponse cabId
                         */

                        /**
                         * Constructs a new WhoamiResponse.
                         * @memberof pro.megakit.amelia.driver.WhoamiProto
                         * @classdesc Represents a WhoamiResponse.
                         * @implements IWhoamiResponse
                         * @constructor
                         * @param {pro.megakit.amelia.driver.WhoamiProto.IWhoamiResponse=} [properties] Properties to set
                         */
                        function WhoamiResponse(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * WhoamiResponse cabId.
                         * @member {string} cabId
                         * @memberof pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse
                         * @instance
                         */
                        WhoamiResponse.prototype.cabId = "";

                        /**
                         * Creates a new WhoamiResponse instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.WhoamiProto.IWhoamiResponse=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse} WhoamiResponse instance
                         */
                        WhoamiResponse.create = function create(properties) {
                            return new WhoamiResponse(properties);
                        };

                        /**
                         * Encodes the specified WhoamiResponse message. Does not implicitly {@link pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.WhoamiProto.IWhoamiResponse} message WhoamiResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        WhoamiResponse.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.cabId != null && message.hasOwnProperty("cabId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.cabId);
                            return writer;
                        };

                        /**
                         * Encodes the specified WhoamiResponse message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.WhoamiProto.IWhoamiResponse} message WhoamiResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        WhoamiResponse.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a WhoamiResponse message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse} WhoamiResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        WhoamiResponse.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.cabId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a WhoamiResponse message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse} WhoamiResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        WhoamiResponse.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a WhoamiResponse message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        WhoamiResponse.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.cabId != null && message.hasOwnProperty("cabId"))
                                if (!$util.isString(message.cabId))
                                    return "cabId: string expected";
                            return null;
                        };

                        /**
                         * Creates a WhoamiResponse message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse} WhoamiResponse
                         */
                        WhoamiResponse.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse();
                            if (object.cabId != null)
                                message.cabId = String(object.cabId);
                            return message;
                        };

                        /**
                         * Creates a plain object from a WhoamiResponse message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse} message WhoamiResponse
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        WhoamiResponse.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.cabId = "";
                            if (message.cabId != null && message.hasOwnProperty("cabId"))
                                object.cabId = message.cabId;
                            return object;
                        };

                        /**
                         * Converts this WhoamiResponse to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.WhoamiProto.WhoamiResponse
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        WhoamiResponse.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return WhoamiResponse;
                    })();

                    return WhoamiProto;
                })();

                driver.SessionProto = (function() {

                    /**
                     * Properties of a SessionProto.
                     * @memberof pro.megakit.amelia.driver
                     * @interface ISessionProto
                     */

                    /**
                     * Constructs a new SessionProto.
                     * @memberof pro.megakit.amelia.driver
                     * @classdesc Represents a SessionProto.
                     * @implements ISessionProto
                     * @constructor
                     * @param {pro.megakit.amelia.driver.ISessionProto=} [properties] Properties to set
                     */
                    function SessionProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new SessionProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.driver.SessionProto
                     * @static
                     * @param {pro.megakit.amelia.driver.ISessionProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.driver.SessionProto} SessionProto instance
                     */
                    SessionProto.create = function create(properties) {
                        return new SessionProto(properties);
                    };

                    /**
                     * Encodes the specified SessionProto message. Does not implicitly {@link pro.megakit.amelia.driver.SessionProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.driver.SessionProto
                     * @static
                     * @param {pro.megakit.amelia.driver.ISessionProto} message SessionProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SessionProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified SessionProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.SessionProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.driver.SessionProto
                     * @static
                     * @param {pro.megakit.amelia.driver.ISessionProto} message SessionProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SessionProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SessionProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.driver.SessionProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.driver.SessionProto} SessionProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SessionProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.SessionProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SessionProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.driver.SessionProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.driver.SessionProto} SessionProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SessionProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SessionProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.driver.SessionProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SessionProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a SessionProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.driver.SessionProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.driver.SessionProto} SessionProto
                     */
                    SessionProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.driver.SessionProto)
                            return object;
                        return new $root.pro.megakit.amelia.driver.SessionProto();
                    };

                    /**
                     * Creates a plain object from a SessionProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.driver.SessionProto
                     * @static
                     * @param {pro.megakit.amelia.driver.SessionProto} message SessionProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SessionProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this SessionProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.driver.SessionProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SessionProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    SessionProto.ActiveSession = (function() {

                        /**
                         * Properties of an ActiveSession.
                         * @memberof pro.megakit.amelia.driver.SessionProto
                         * @interface IActiveSession
                         * @property {string|null} [id] ActiveSession id
                         * @property {pro.megakit.amelia.SessionProto.SessionType|null} [type] ActiveSession type
                         * @property {Array.<pro.megakit.amelia.SessionProto.ISessionImage>|null} [images] ActiveSession images
                         */

                        /**
                         * Constructs a new ActiveSession.
                         * @memberof pro.megakit.amelia.driver.SessionProto
                         * @classdesc Represents an ActiveSession.
                         * @implements IActiveSession
                         * @constructor
                         * @param {pro.megakit.amelia.driver.SessionProto.IActiveSession=} [properties] Properties to set
                         */
                        function ActiveSession(properties) {
                            this.images = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ActiveSession id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.SessionProto.ActiveSession
                         * @instance
                         */
                        ActiveSession.prototype.id = "";

                        /**
                         * ActiveSession type.
                         * @member {pro.megakit.amelia.SessionProto.SessionType} type
                         * @memberof pro.megakit.amelia.driver.SessionProto.ActiveSession
                         * @instance
                         */
                        ActiveSession.prototype.type = 0;

                        /**
                         * ActiveSession images.
                         * @member {Array.<pro.megakit.amelia.SessionProto.ISessionImage>} images
                         * @memberof pro.megakit.amelia.driver.SessionProto.ActiveSession
                         * @instance
                         */
                        ActiveSession.prototype.images = $util.emptyArray;

                        /**
                         * Creates a new ActiveSession instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.SessionProto.ActiveSession
                         * @static
                         * @param {pro.megakit.amelia.driver.SessionProto.IActiveSession=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.SessionProto.ActiveSession} ActiveSession instance
                         */
                        ActiveSession.create = function create(properties) {
                            return new ActiveSession(properties);
                        };

                        /**
                         * Encodes the specified ActiveSession message. Does not implicitly {@link pro.megakit.amelia.driver.SessionProto.ActiveSession.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.SessionProto.ActiveSession
                         * @static
                         * @param {pro.megakit.amelia.driver.SessionProto.IActiveSession} message ActiveSession message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveSession.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.type != null && message.hasOwnProperty("type"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
                            if (message.images != null && message.images.length)
                                for (var i = 0; i < message.images.length; ++i)
                                    $root.pro.megakit.amelia.SessionProto.SessionImage.encode(message.images[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ActiveSession message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.SessionProto.ActiveSession.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.SessionProto.ActiveSession
                         * @static
                         * @param {pro.megakit.amelia.driver.SessionProto.IActiveSession} message ActiveSession message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveSession.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an ActiveSession message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.SessionProto.ActiveSession
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.SessionProto.ActiveSession} ActiveSession
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveSession.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.SessionProto.ActiveSession();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.type = reader.int32();
                                    break;
                                case 3:
                                    if (!(message.images && message.images.length))
                                        message.images = [];
                                    message.images.push($root.pro.megakit.amelia.SessionProto.SessionImage.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an ActiveSession message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.SessionProto.ActiveSession
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.SessionProto.ActiveSession} ActiveSession
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveSession.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an ActiveSession message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.SessionProto.ActiveSession
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ActiveSession.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                switch (message.type) {
                                default:
                                    return "type: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                    break;
                                }
                            if (message.images != null && message.hasOwnProperty("images")) {
                                if (!Array.isArray(message.images))
                                    return "images: array expected";
                                for (var i = 0; i < message.images.length; ++i) {
                                    var error = $root.pro.megakit.amelia.SessionProto.SessionImage.verify(message.images[i]);
                                    if (error)
                                        return "images." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates an ActiveSession message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.SessionProto.ActiveSession
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.SessionProto.ActiveSession} ActiveSession
                         */
                        ActiveSession.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.SessionProto.ActiveSession)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.SessionProto.ActiveSession();
                            if (object.id != null)
                                message.id = String(object.id);
                            switch (object.type) {
                            case "START_WORKING_SHIFT":
                            case 0:
                                message.type = 0;
                                break;
                            case "FINISH_WORKING_SHIFT":
                            case 1:
                                message.type = 1;
                                break;
                            case "VEHICLE_REFILL":
                            case 2:
                                message.type = 2;
                                break;
                            case "START_VEHICLE_WASHING":
                            case 3:
                                message.type = 3;
                                break;
                            case "FINISH_VEHICLE_WASHING":
                            case 4:
                                message.type = 4;
                                break;
                            case "STORE_DRIVER_EXPENSE":
                            case 5:
                                message.type = 5;
                                break;
                            case "START_DRIVER_REST":
                            case 6:
                                message.type = 6;
                                break;
                            case "CANCEL_DRIVER_REQUEST":
                            case 7:
                                message.type = 7;
                                break;
                            }
                            if (object.images) {
                                if (!Array.isArray(object.images))
                                    throw TypeError(".pro.megakit.amelia.driver.SessionProto.ActiveSession.images: array expected");
                                message.images = [];
                                for (var i = 0; i < object.images.length; ++i) {
                                    if (typeof object.images[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.driver.SessionProto.ActiveSession.images: object expected");
                                    message.images[i] = $root.pro.megakit.amelia.SessionProto.SessionImage.fromObject(object.images[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an ActiveSession message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.SessionProto.ActiveSession
                         * @static
                         * @param {pro.megakit.amelia.driver.SessionProto.ActiveSession} message ActiveSession
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ActiveSession.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.images = [];
                            if (options.defaults) {
                                object.id = "";
                                object.type = options.enums === String ? "START_WORKING_SHIFT" : 0;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = options.enums === String ? $root.pro.megakit.amelia.SessionProto.SessionType[message.type] : message.type;
                            if (message.images && message.images.length) {
                                object.images = [];
                                for (var j = 0; j < message.images.length; ++j)
                                    object.images[j] = $root.pro.megakit.amelia.SessionProto.SessionImage.toObject(message.images[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this ActiveSession to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.SessionProto.ActiveSession
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ActiveSession.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ActiveSession;
                    })();

                    SessionProto.StartSession = (function() {

                        /**
                         * Properties of a StartSession.
                         * @memberof pro.megakit.amelia.driver.SessionProto
                         * @interface IStartSession
                         * @property {pro.megakit.amelia.SessionProto.SessionType|null} [type] StartSession type
                         */

                        /**
                         * Constructs a new StartSession.
                         * @memberof pro.megakit.amelia.driver.SessionProto
                         * @classdesc Represents a StartSession.
                         * @implements IStartSession
                         * @constructor
                         * @param {pro.megakit.amelia.driver.SessionProto.IStartSession=} [properties] Properties to set
                         */
                        function StartSession(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StartSession type.
                         * @member {pro.megakit.amelia.SessionProto.SessionType} type
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSession
                         * @instance
                         */
                        StartSession.prototype.type = 0;

                        /**
                         * Creates a new StartSession instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSession
                         * @static
                         * @param {pro.megakit.amelia.driver.SessionProto.IStartSession=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.SessionProto.StartSession} StartSession instance
                         */
                        StartSession.create = function create(properties) {
                            return new StartSession(properties);
                        };

                        /**
                         * Encodes the specified StartSession message. Does not implicitly {@link pro.megakit.amelia.driver.SessionProto.StartSession.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSession
                         * @static
                         * @param {pro.megakit.amelia.driver.SessionProto.IStartSession} message StartSession message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartSession.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.type != null && message.hasOwnProperty("type"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                            return writer;
                        };

                        /**
                         * Encodes the specified StartSession message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.SessionProto.StartSession.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSession
                         * @static
                         * @param {pro.megakit.amelia.driver.SessionProto.IStartSession} message StartSession message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartSession.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StartSession message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSession
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.SessionProto.StartSession} StartSession
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartSession.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.SessionProto.StartSession();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.type = reader.int32();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StartSession message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSession
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.SessionProto.StartSession} StartSession
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartSession.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StartSession message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSession
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StartSession.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                switch (message.type) {
                                default:
                                    return "type: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                    break;
                                }
                            return null;
                        };

                        /**
                         * Creates a StartSession message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSession
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.SessionProto.StartSession} StartSession
                         */
                        StartSession.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.SessionProto.StartSession)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.SessionProto.StartSession();
                            switch (object.type) {
                            case "START_WORKING_SHIFT":
                            case 0:
                                message.type = 0;
                                break;
                            case "FINISH_WORKING_SHIFT":
                            case 1:
                                message.type = 1;
                                break;
                            case "VEHICLE_REFILL":
                            case 2:
                                message.type = 2;
                                break;
                            case "START_VEHICLE_WASHING":
                            case 3:
                                message.type = 3;
                                break;
                            case "FINISH_VEHICLE_WASHING":
                            case 4:
                                message.type = 4;
                                break;
                            case "STORE_DRIVER_EXPENSE":
                            case 5:
                                message.type = 5;
                                break;
                            case "START_DRIVER_REST":
                            case 6:
                                message.type = 6;
                                break;
                            case "CANCEL_DRIVER_REQUEST":
                            case 7:
                                message.type = 7;
                                break;
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a StartSession message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSession
                         * @static
                         * @param {pro.megakit.amelia.driver.SessionProto.StartSession} message StartSession
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StartSession.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.type = options.enums === String ? "START_WORKING_SHIFT" : 0;
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = options.enums === String ? $root.pro.megakit.amelia.SessionProto.SessionType[message.type] : message.type;
                            return object;
                        };

                        /**
                         * Converts this StartSession to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSession
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StartSession.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StartSession;
                    })();

                    SessionProto.StartSessionResponse = (function() {

                        /**
                         * Properties of a StartSessionResponse.
                         * @memberof pro.megakit.amelia.driver.SessionProto
                         * @interface IStartSessionResponse
                         * @property {string|null} [id] StartSessionResponse id
                         */

                        /**
                         * Constructs a new StartSessionResponse.
                         * @memberof pro.megakit.amelia.driver.SessionProto
                         * @classdesc Represents a StartSessionResponse.
                         * @implements IStartSessionResponse
                         * @constructor
                         * @param {pro.megakit.amelia.driver.SessionProto.IStartSessionResponse=} [properties] Properties to set
                         */
                        function StartSessionResponse(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StartSessionResponse id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSessionResponse
                         * @instance
                         */
                        StartSessionResponse.prototype.id = "";

                        /**
                         * Creates a new StartSessionResponse instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSessionResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.SessionProto.IStartSessionResponse=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.SessionProto.StartSessionResponse} StartSessionResponse instance
                         */
                        StartSessionResponse.create = function create(properties) {
                            return new StartSessionResponse(properties);
                        };

                        /**
                         * Encodes the specified StartSessionResponse message. Does not implicitly {@link pro.megakit.amelia.driver.SessionProto.StartSessionResponse.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSessionResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.SessionProto.IStartSessionResponse} message StartSessionResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartSessionResponse.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified StartSessionResponse message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.SessionProto.StartSessionResponse.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSessionResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.SessionProto.IStartSessionResponse} message StartSessionResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartSessionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StartSessionResponse message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSessionResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.SessionProto.StartSessionResponse} StartSessionResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartSessionResponse.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.SessionProto.StartSessionResponse();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StartSessionResponse message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSessionResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.SessionProto.StartSessionResponse} StartSessionResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartSessionResponse.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StartSessionResponse message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSessionResponse
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StartSessionResponse.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a StartSessionResponse message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSessionResponse
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.SessionProto.StartSessionResponse} StartSessionResponse
                         */
                        StartSessionResponse.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.SessionProto.StartSessionResponse)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.SessionProto.StartSessionResponse();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a StartSessionResponse message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSessionResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.SessionProto.StartSessionResponse} message StartSessionResponse
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StartSessionResponse.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this StartSessionResponse to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.SessionProto.StartSessionResponse
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StartSessionResponse.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StartSessionResponse;
                    })();

                    SessionProto.DeleteSession = (function() {

                        /**
                         * Properties of a DeleteSession.
                         * @memberof pro.megakit.amelia.driver.SessionProto
                         * @interface IDeleteSession
                         * @property {string|null} [id] DeleteSession id
                         */

                        /**
                         * Constructs a new DeleteSession.
                         * @memberof pro.megakit.amelia.driver.SessionProto
                         * @classdesc Represents a DeleteSession.
                         * @implements IDeleteSession
                         * @constructor
                         * @param {pro.megakit.amelia.driver.SessionProto.IDeleteSession=} [properties] Properties to set
                         */
                        function DeleteSession(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DeleteSession id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.SessionProto.DeleteSession
                         * @instance
                         */
                        DeleteSession.prototype.id = "";

                        /**
                         * Creates a new DeleteSession instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.SessionProto.DeleteSession
                         * @static
                         * @param {pro.megakit.amelia.driver.SessionProto.IDeleteSession=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.SessionProto.DeleteSession} DeleteSession instance
                         */
                        DeleteSession.create = function create(properties) {
                            return new DeleteSession(properties);
                        };

                        /**
                         * Encodes the specified DeleteSession message. Does not implicitly {@link pro.megakit.amelia.driver.SessionProto.DeleteSession.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.SessionProto.DeleteSession
                         * @static
                         * @param {pro.megakit.amelia.driver.SessionProto.IDeleteSession} message DeleteSession message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DeleteSession.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified DeleteSession message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.SessionProto.DeleteSession.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.SessionProto.DeleteSession
                         * @static
                         * @param {pro.megakit.amelia.driver.SessionProto.IDeleteSession} message DeleteSession message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DeleteSession.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DeleteSession message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.SessionProto.DeleteSession
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.SessionProto.DeleteSession} DeleteSession
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DeleteSession.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.SessionProto.DeleteSession();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DeleteSession message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.SessionProto.DeleteSession
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.SessionProto.DeleteSession} DeleteSession
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DeleteSession.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DeleteSession message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.SessionProto.DeleteSession
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DeleteSession.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a DeleteSession message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.SessionProto.DeleteSession
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.SessionProto.DeleteSession} DeleteSession
                         */
                        DeleteSession.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.SessionProto.DeleteSession)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.SessionProto.DeleteSession();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a DeleteSession message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.SessionProto.DeleteSession
                         * @static
                         * @param {pro.megakit.amelia.driver.SessionProto.DeleteSession} message DeleteSession
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DeleteSession.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this DeleteSession to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.SessionProto.DeleteSession
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DeleteSession.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DeleteSession;
                    })();

                    return SessionProto;
                })();

                driver.ImageProto = (function() {

                    /**
                     * Properties of an ImageProto.
                     * @memberof pro.megakit.amelia.driver
                     * @interface IImageProto
                     */

                    /**
                     * Constructs a new ImageProto.
                     * @memberof pro.megakit.amelia.driver
                     * @classdesc Represents an ImageProto.
                     * @implements IImageProto
                     * @constructor
                     * @param {pro.megakit.amelia.driver.IImageProto=} [properties] Properties to set
                     */
                    function ImageProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new ImageProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.driver.ImageProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IImageProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.driver.ImageProto} ImageProto instance
                     */
                    ImageProto.create = function create(properties) {
                        return new ImageProto(properties);
                    };

                    /**
                     * Encodes the specified ImageProto message. Does not implicitly {@link pro.megakit.amelia.driver.ImageProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.driver.ImageProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IImageProto} message ImageProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImageProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified ImageProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.ImageProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.driver.ImageProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IImageProto} message ImageProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImageProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ImageProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.driver.ImageProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.driver.ImageProto} ImageProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImageProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.ImageProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ImageProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.driver.ImageProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.driver.ImageProto} ImageProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImageProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ImageProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.driver.ImageProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ImageProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates an ImageProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.driver.ImageProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.driver.ImageProto} ImageProto
                     */
                    ImageProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.driver.ImageProto)
                            return object;
                        return new $root.pro.megakit.amelia.driver.ImageProto();
                    };

                    /**
                     * Creates a plain object from an ImageProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.driver.ImageProto
                     * @static
                     * @param {pro.megakit.amelia.driver.ImageProto} message ImageProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ImageProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this ImageProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.driver.ImageProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ImageProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    ImageProto.UploadImageRequest = (function() {

                        /**
                         * Properties of an UploadImageRequest.
                         * @memberof pro.megakit.amelia.driver.ImageProto
                         * @interface IUploadImageRequest
                         * @property {string|null} [sessionId] UploadImageRequest sessionId
                         * @property {string|null} [type] UploadImageRequest type
                         * @property {string|null} [mimeType] UploadImageRequest mimeType
                         * @property {Uint8Array|null} [data] UploadImageRequest data
                         */

                        /**
                         * Constructs a new UploadImageRequest.
                         * @memberof pro.megakit.amelia.driver.ImageProto
                         * @classdesc Represents an UploadImageRequest.
                         * @implements IUploadImageRequest
                         * @constructor
                         * @param {pro.megakit.amelia.driver.ImageProto.IUploadImageRequest=} [properties] Properties to set
                         */
                        function UploadImageRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * UploadImageRequest sessionId.
                         * @member {string} sessionId
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageRequest
                         * @instance
                         */
                        UploadImageRequest.prototype.sessionId = "";

                        /**
                         * UploadImageRequest type.
                         * @member {string} type
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageRequest
                         * @instance
                         */
                        UploadImageRequest.prototype.type = "";

                        /**
                         * UploadImageRequest mimeType.
                         * @member {string} mimeType
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageRequest
                         * @instance
                         */
                        UploadImageRequest.prototype.mimeType = "";

                        /**
                         * UploadImageRequest data.
                         * @member {Uint8Array} data
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageRequest
                         * @instance
                         */
                        UploadImageRequest.prototype.data = $util.newBuffer([]);

                        /**
                         * Creates a new UploadImageRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.ImageProto.IUploadImageRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.ImageProto.UploadImageRequest} UploadImageRequest instance
                         */
                        UploadImageRequest.create = function create(properties) {
                            return new UploadImageRequest(properties);
                        };

                        /**
                         * Encodes the specified UploadImageRequest message. Does not implicitly {@link pro.megakit.amelia.driver.ImageProto.UploadImageRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.ImageProto.IUploadImageRequest} message UploadImageRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        UploadImageRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
                            if (message.type != null && message.hasOwnProperty("type"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
                            if (message.mimeType != null && message.hasOwnProperty("mimeType"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.mimeType);
                            if (message.data != null && message.hasOwnProperty("data"))
                                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.data);
                            return writer;
                        };

                        /**
                         * Encodes the specified UploadImageRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.ImageProto.UploadImageRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.ImageProto.IUploadImageRequest} message UploadImageRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        UploadImageRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an UploadImageRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.ImageProto.UploadImageRequest} UploadImageRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        UploadImageRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.ImageProto.UploadImageRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.sessionId = reader.string();
                                    break;
                                case 2:
                                    message.type = reader.string();
                                    break;
                                case 3:
                                    message.mimeType = reader.string();
                                    break;
                                case 4:
                                    message.data = reader.bytes();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an UploadImageRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.ImageProto.UploadImageRequest} UploadImageRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        UploadImageRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an UploadImageRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        UploadImageRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                if (!$util.isString(message.sessionId))
                                    return "sessionId: string expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                if (!$util.isString(message.type))
                                    return "type: string expected";
                            if (message.mimeType != null && message.hasOwnProperty("mimeType"))
                                if (!$util.isString(message.mimeType))
                                    return "mimeType: string expected";
                            if (message.data != null && message.hasOwnProperty("data"))
                                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                                    return "data: buffer expected";
                            return null;
                        };

                        /**
                         * Creates an UploadImageRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.ImageProto.UploadImageRequest} UploadImageRequest
                         */
                        UploadImageRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.ImageProto.UploadImageRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.ImageProto.UploadImageRequest();
                            if (object.sessionId != null)
                                message.sessionId = String(object.sessionId);
                            if (object.type != null)
                                message.type = String(object.type);
                            if (object.mimeType != null)
                                message.mimeType = String(object.mimeType);
                            if (object.data != null)
                                if (typeof object.data === "string")
                                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                                else if (object.data.length)
                                    message.data = object.data;
                            return message;
                        };

                        /**
                         * Creates a plain object from an UploadImageRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.ImageProto.UploadImageRequest} message UploadImageRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        UploadImageRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.sessionId = "";
                                object.type = "";
                                object.mimeType = "";
                                if (options.bytes === String)
                                    object.data = "";
                                else {
                                    object.data = [];
                                    if (options.bytes !== Array)
                                        object.data = $util.newBuffer(object.data);
                                }
                            }
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                object.sessionId = message.sessionId;
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = message.type;
                            if (message.mimeType != null && message.hasOwnProperty("mimeType"))
                                object.mimeType = message.mimeType;
                            if (message.data != null && message.hasOwnProperty("data"))
                                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                            return object;
                        };

                        /**
                         * Converts this UploadImageRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        UploadImageRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return UploadImageRequest;
                    })();

                    ImageProto.UploadImageResponse = (function() {

                        /**
                         * Properties of an UploadImageResponse.
                         * @memberof pro.megakit.amelia.driver.ImageProto
                         * @interface IUploadImageResponse
                         * @property {string|null} [id] UploadImageResponse id
                         */

                        /**
                         * Constructs a new UploadImageResponse.
                         * @memberof pro.megakit.amelia.driver.ImageProto
                         * @classdesc Represents an UploadImageResponse.
                         * @implements IUploadImageResponse
                         * @constructor
                         * @param {pro.megakit.amelia.driver.ImageProto.IUploadImageResponse=} [properties] Properties to set
                         */
                        function UploadImageResponse(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * UploadImageResponse id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageResponse
                         * @instance
                         */
                        UploadImageResponse.prototype.id = "";

                        /**
                         * Creates a new UploadImageResponse instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.ImageProto.IUploadImageResponse=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.ImageProto.UploadImageResponse} UploadImageResponse instance
                         */
                        UploadImageResponse.create = function create(properties) {
                            return new UploadImageResponse(properties);
                        };

                        /**
                         * Encodes the specified UploadImageResponse message. Does not implicitly {@link pro.megakit.amelia.driver.ImageProto.UploadImageResponse.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.ImageProto.IUploadImageResponse} message UploadImageResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        UploadImageResponse.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified UploadImageResponse message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.ImageProto.UploadImageResponse.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.ImageProto.IUploadImageResponse} message UploadImageResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        UploadImageResponse.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an UploadImageResponse message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.ImageProto.UploadImageResponse} UploadImageResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        UploadImageResponse.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.ImageProto.UploadImageResponse();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an UploadImageResponse message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.ImageProto.UploadImageResponse} UploadImageResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        UploadImageResponse.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an UploadImageResponse message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageResponse
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        UploadImageResponse.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates an UploadImageResponse message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageResponse
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.ImageProto.UploadImageResponse} UploadImageResponse
                         */
                        UploadImageResponse.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.ImageProto.UploadImageResponse)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.ImageProto.UploadImageResponse();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from an UploadImageResponse message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.ImageProto.UploadImageResponse} message UploadImageResponse
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        UploadImageResponse.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this UploadImageResponse to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.ImageProto.UploadImageResponse
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        UploadImageResponse.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return UploadImageResponse;
                    })();

                    ImageProto.GetImageRequest = (function() {

                        /**
                         * Properties of a GetImageRequest.
                         * @memberof pro.megakit.amelia.driver.ImageProto
                         * @interface IGetImageRequest
                         * @property {string|null} [id] GetImageRequest id
                         */

                        /**
                         * Constructs a new GetImageRequest.
                         * @memberof pro.megakit.amelia.driver.ImageProto
                         * @classdesc Represents a GetImageRequest.
                         * @implements IGetImageRequest
                         * @constructor
                         * @param {pro.megakit.amelia.driver.ImageProto.IGetImageRequest=} [properties] Properties to set
                         */
                        function GetImageRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * GetImageRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.ImageProto.GetImageRequest
                         * @instance
                         */
                        GetImageRequest.prototype.id = "";

                        /**
                         * Creates a new GetImageRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.ImageProto.GetImageRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.ImageProto.IGetImageRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.ImageProto.GetImageRequest} GetImageRequest instance
                         */
                        GetImageRequest.create = function create(properties) {
                            return new GetImageRequest(properties);
                        };

                        /**
                         * Encodes the specified GetImageRequest message. Does not implicitly {@link pro.megakit.amelia.driver.ImageProto.GetImageRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.ImageProto.GetImageRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.ImageProto.IGetImageRequest} message GetImageRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GetImageRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified GetImageRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.ImageProto.GetImageRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.ImageProto.GetImageRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.ImageProto.IGetImageRequest} message GetImageRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GetImageRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a GetImageRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.ImageProto.GetImageRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.ImageProto.GetImageRequest} GetImageRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GetImageRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.ImageProto.GetImageRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a GetImageRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.ImageProto.GetImageRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.ImageProto.GetImageRequest} GetImageRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GetImageRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a GetImageRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.ImageProto.GetImageRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        GetImageRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a GetImageRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.ImageProto.GetImageRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.ImageProto.GetImageRequest} GetImageRequest
                         */
                        GetImageRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.ImageProto.GetImageRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.ImageProto.GetImageRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a GetImageRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.ImageProto.GetImageRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.ImageProto.GetImageRequest} message GetImageRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        GetImageRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this GetImageRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.ImageProto.GetImageRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        GetImageRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return GetImageRequest;
                    })();

                    ImageProto.DeleteImageRequest = (function() {

                        /**
                         * Properties of a DeleteImageRequest.
                         * @memberof pro.megakit.amelia.driver.ImageProto
                         * @interface IDeleteImageRequest
                         * @property {string|null} [id] DeleteImageRequest id
                         */

                        /**
                         * Constructs a new DeleteImageRequest.
                         * @memberof pro.megakit.amelia.driver.ImageProto
                         * @classdesc Represents a DeleteImageRequest.
                         * @implements IDeleteImageRequest
                         * @constructor
                         * @param {pro.megakit.amelia.driver.ImageProto.IDeleteImageRequest=} [properties] Properties to set
                         */
                        function DeleteImageRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DeleteImageRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.ImageProto.DeleteImageRequest
                         * @instance
                         */
                        DeleteImageRequest.prototype.id = "";

                        /**
                         * Creates a new DeleteImageRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.ImageProto.DeleteImageRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.ImageProto.IDeleteImageRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.ImageProto.DeleteImageRequest} DeleteImageRequest instance
                         */
                        DeleteImageRequest.create = function create(properties) {
                            return new DeleteImageRequest(properties);
                        };

                        /**
                         * Encodes the specified DeleteImageRequest message. Does not implicitly {@link pro.megakit.amelia.driver.ImageProto.DeleteImageRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.ImageProto.DeleteImageRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.ImageProto.IDeleteImageRequest} message DeleteImageRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DeleteImageRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified DeleteImageRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.ImageProto.DeleteImageRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.ImageProto.DeleteImageRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.ImageProto.IDeleteImageRequest} message DeleteImageRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DeleteImageRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DeleteImageRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.ImageProto.DeleteImageRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.ImageProto.DeleteImageRequest} DeleteImageRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DeleteImageRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.ImageProto.DeleteImageRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DeleteImageRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.ImageProto.DeleteImageRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.ImageProto.DeleteImageRequest} DeleteImageRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DeleteImageRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DeleteImageRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.ImageProto.DeleteImageRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DeleteImageRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a DeleteImageRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.ImageProto.DeleteImageRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.ImageProto.DeleteImageRequest} DeleteImageRequest
                         */
                        DeleteImageRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.ImageProto.DeleteImageRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.ImageProto.DeleteImageRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a DeleteImageRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.ImageProto.DeleteImageRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.ImageProto.DeleteImageRequest} message DeleteImageRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DeleteImageRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this DeleteImageRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.ImageProto.DeleteImageRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DeleteImageRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DeleteImageRequest;
                    })();

                    return ImageProto;
                })();

                driver.WorkingShiftProto = (function() {

                    /**
                     * Properties of a WorkingShiftProto.
                     * @memberof pro.megakit.amelia.driver
                     * @interface IWorkingShiftProto
                     */

                    /**
                     * Constructs a new WorkingShiftProto.
                     * @memberof pro.megakit.amelia.driver
                     * @classdesc Represents a WorkingShiftProto.
                     * @implements IWorkingShiftProto
                     * @constructor
                     * @param {pro.megakit.amelia.driver.IWorkingShiftProto=} [properties] Properties to set
                     */
                    function WorkingShiftProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new WorkingShiftProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IWorkingShiftProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.driver.WorkingShiftProto} WorkingShiftProto instance
                     */
                    WorkingShiftProto.create = function create(properties) {
                        return new WorkingShiftProto(properties);
                    };

                    /**
                     * Encodes the specified WorkingShiftProto message. Does not implicitly {@link pro.megakit.amelia.driver.WorkingShiftProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IWorkingShiftProto} message WorkingShiftProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WorkingShiftProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified WorkingShiftProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.WorkingShiftProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IWorkingShiftProto} message WorkingShiftProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WorkingShiftProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a WorkingShiftProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.driver.WorkingShiftProto} WorkingShiftProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WorkingShiftProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.WorkingShiftProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a WorkingShiftProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.driver.WorkingShiftProto} WorkingShiftProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WorkingShiftProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a WorkingShiftProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    WorkingShiftProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a WorkingShiftProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.driver.WorkingShiftProto} WorkingShiftProto
                     */
                    WorkingShiftProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.driver.WorkingShiftProto)
                            return object;
                        return new $root.pro.megakit.amelia.driver.WorkingShiftProto();
                    };

                    /**
                     * Creates a plain object from a WorkingShiftProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                     * @static
                     * @param {pro.megakit.amelia.driver.WorkingShiftProto} message WorkingShiftProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    WorkingShiftProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this WorkingShiftProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    WorkingShiftProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    WorkingShiftProto.DriverActiveWorkingShift = (function() {

                        /**
                         * Properties of a DriverActiveWorkingShift.
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                         * @interface IDriverActiveWorkingShift
                         * @property {string|null} [id] DriverActiveWorkingShift id
                         * @property {string|null} [status] DriverActiveWorkingShift status
                         * @property {number|null} [tripsCount] DriverActiveWorkingShift tripsCount
                         * @property {number|null} [tripsAmount] DriverActiveWorkingShift tripsAmount
                         * @property {number|Long|null} [startMileage] DriverActiveWorkingShift startMileage
                         * @property {google.protobuf.ITimestamp|null} [startedAt] DriverActiveWorkingShift startedAt
                         */

                        /**
                         * Constructs a new DriverActiveWorkingShift.
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                         * @classdesc Represents a DriverActiveWorkingShift.
                         * @implements IDriverActiveWorkingShift
                         * @constructor
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IDriverActiveWorkingShift=} [properties] Properties to set
                         */
                        function DriverActiveWorkingShift(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DriverActiveWorkingShift id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift
                         * @instance
                         */
                        DriverActiveWorkingShift.prototype.id = "";

                        /**
                         * DriverActiveWorkingShift status.
                         * @member {string} status
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift
                         * @instance
                         */
                        DriverActiveWorkingShift.prototype.status = "";

                        /**
                         * DriverActiveWorkingShift tripsCount.
                         * @member {number} tripsCount
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift
                         * @instance
                         */
                        DriverActiveWorkingShift.prototype.tripsCount = 0;

                        /**
                         * DriverActiveWorkingShift tripsAmount.
                         * @member {number} tripsAmount
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift
                         * @instance
                         */
                        DriverActiveWorkingShift.prototype.tripsAmount = 0;

                        /**
                         * DriverActiveWorkingShift startMileage.
                         * @member {number|Long} startMileage
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift
                         * @instance
                         */
                        DriverActiveWorkingShift.prototype.startMileage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * DriverActiveWorkingShift startedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} startedAt
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift
                         * @instance
                         */
                        DriverActiveWorkingShift.prototype.startedAt = null;

                        /**
                         * Creates a new DriverActiveWorkingShift instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IDriverActiveWorkingShift=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift} DriverActiveWorkingShift instance
                         */
                        DriverActiveWorkingShift.create = function create(properties) {
                            return new DriverActiveWorkingShift(properties);
                        };

                        /**
                         * Encodes the specified DriverActiveWorkingShift message. Does not implicitly {@link pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IDriverActiveWorkingShift} message DriverActiveWorkingShift message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverActiveWorkingShift.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.status != null && message.hasOwnProperty("status"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
                            if (message.tripsCount != null && message.hasOwnProperty("tripsCount"))
                                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.tripsCount);
                            if (message.tripsAmount != null && message.hasOwnProperty("tripsAmount"))
                                writer.uint32(/* id 4, wireType 1 =*/33).double(message.tripsAmount);
                            if (message.startMileage != null && message.hasOwnProperty("startMileage"))
                                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.startMileage);
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                $root.google.protobuf.Timestamp.encode(message.startedAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified DriverActiveWorkingShift message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IDriverActiveWorkingShift} message DriverActiveWorkingShift message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverActiveWorkingShift.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DriverActiveWorkingShift message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift} DriverActiveWorkingShift
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverActiveWorkingShift.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.status = reader.string();
                                    break;
                                case 3:
                                    message.tripsCount = reader.uint32();
                                    break;
                                case 4:
                                    message.tripsAmount = reader.double();
                                    break;
                                case 5:
                                    message.startMileage = reader.int64();
                                    break;
                                case 6:
                                    message.startedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DriverActiveWorkingShift message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift} DriverActiveWorkingShift
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverActiveWorkingShift.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DriverActiveWorkingShift message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DriverActiveWorkingShift.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.status != null && message.hasOwnProperty("status"))
                                if (!$util.isString(message.status))
                                    return "status: string expected";
                            if (message.tripsCount != null && message.hasOwnProperty("tripsCount"))
                                if (!$util.isInteger(message.tripsCount))
                                    return "tripsCount: integer expected";
                            if (message.tripsAmount != null && message.hasOwnProperty("tripsAmount"))
                                if (typeof message.tripsAmount !== "number")
                                    return "tripsAmount: number expected";
                            if (message.startMileage != null && message.hasOwnProperty("startMileage"))
                                if (!$util.isInteger(message.startMileage) && !(message.startMileage && $util.isInteger(message.startMileage.low) && $util.isInteger(message.startMileage.high)))
                                    return "startMileage: integer|Long expected";
                            if (message.startedAt != null && message.hasOwnProperty("startedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.startedAt);
                                if (error)
                                    return "startedAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a DriverActiveWorkingShift message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift} DriverActiveWorkingShift
                         */
                        DriverActiveWorkingShift.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.status != null)
                                message.status = String(object.status);
                            if (object.tripsCount != null)
                                message.tripsCount = object.tripsCount >>> 0;
                            if (object.tripsAmount != null)
                                message.tripsAmount = Number(object.tripsAmount);
                            if (object.startMileage != null)
                                if ($util.Long)
                                    (message.startMileage = $util.Long.fromValue(object.startMileage)).unsigned = false;
                                else if (typeof object.startMileage === "string")
                                    message.startMileage = parseInt(object.startMileage, 10);
                                else if (typeof object.startMileage === "number")
                                    message.startMileage = object.startMileage;
                                else if (typeof object.startMileage === "object")
                                    message.startMileage = new $util.LongBits(object.startMileage.low >>> 0, object.startMileage.high >>> 0).toNumber();
                            if (object.startedAt != null) {
                                if (typeof object.startedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift.startedAt: object expected");
                                message.startedAt = $root.google.protobuf.Timestamp.fromObject(object.startedAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a DriverActiveWorkingShift message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift} message DriverActiveWorkingShift
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DriverActiveWorkingShift.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.status = "";
                                object.tripsCount = 0;
                                object.tripsAmount = 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.startMileage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.startMileage = options.longs === String ? "0" : 0;
                                object.startedAt = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = message.status;
                            if (message.tripsCount != null && message.hasOwnProperty("tripsCount"))
                                object.tripsCount = message.tripsCount;
                            if (message.tripsAmount != null && message.hasOwnProperty("tripsAmount"))
                                object.tripsAmount = options.json && !isFinite(message.tripsAmount) ? String(message.tripsAmount) : message.tripsAmount;
                            if (message.startMileage != null && message.hasOwnProperty("startMileage"))
                                if (typeof message.startMileage === "number")
                                    object.startMileage = options.longs === String ? String(message.startMileage) : message.startMileage;
                                else
                                    object.startMileage = options.longs === String ? $util.Long.prototype.toString.call(message.startMileage) : options.longs === Number ? new $util.LongBits(message.startMileage.low >>> 0, message.startMileage.high >>> 0).toNumber() : message.startMileage;
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                object.startedAt = $root.google.protobuf.Timestamp.toObject(message.startedAt, options);
                            return object;
                        };

                        /**
                         * Converts this DriverActiveWorkingShift to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverActiveWorkingShift
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DriverActiveWorkingShift.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DriverActiveWorkingShift;
                    })();

                    WorkingShiftProto.StartWorkingShiftRequest = (function() {

                        /**
                         * Properties of a StartWorkingShiftRequest.
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                         * @interface IStartWorkingShiftRequest
                         * @property {string|null} [sessionId] StartWorkingShiftRequest sessionId
                         * @property {number|Long|null} [mileage] StartWorkingShiftRequest mileage
                         */

                        /**
                         * Constructs a new StartWorkingShiftRequest.
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                         * @classdesc Represents a StartWorkingShiftRequest.
                         * @implements IStartWorkingShiftRequest
                         * @constructor
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IStartWorkingShiftRequest=} [properties] Properties to set
                         */
                        function StartWorkingShiftRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StartWorkingShiftRequest sessionId.
                         * @member {string} sessionId
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest
                         * @instance
                         */
                        StartWorkingShiftRequest.prototype.sessionId = "";

                        /**
                         * StartWorkingShiftRequest mileage.
                         * @member {number|Long} mileage
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest
                         * @instance
                         */
                        StartWorkingShiftRequest.prototype.mileage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Creates a new StartWorkingShiftRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IStartWorkingShiftRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest} StartWorkingShiftRequest instance
                         */
                        StartWorkingShiftRequest.create = function create(properties) {
                            return new StartWorkingShiftRequest(properties);
                        };

                        /**
                         * Encodes the specified StartWorkingShiftRequest message. Does not implicitly {@link pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IStartWorkingShiftRequest} message StartWorkingShiftRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartWorkingShiftRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
                            if (message.mileage != null && message.hasOwnProperty("mileage"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.mileage);
                            return writer;
                        };

                        /**
                         * Encodes the specified StartWorkingShiftRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IStartWorkingShiftRequest} message StartWorkingShiftRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartWorkingShiftRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StartWorkingShiftRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest} StartWorkingShiftRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartWorkingShiftRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.sessionId = reader.string();
                                    break;
                                case 2:
                                    message.mileage = reader.int64();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StartWorkingShiftRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest} StartWorkingShiftRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartWorkingShiftRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StartWorkingShiftRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StartWorkingShiftRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                if (!$util.isString(message.sessionId))
                                    return "sessionId: string expected";
                            if (message.mileage != null && message.hasOwnProperty("mileage"))
                                if (!$util.isInteger(message.mileage) && !(message.mileage && $util.isInteger(message.mileage.low) && $util.isInteger(message.mileage.high)))
                                    return "mileage: integer|Long expected";
                            return null;
                        };

                        /**
                         * Creates a StartWorkingShiftRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest} StartWorkingShiftRequest
                         */
                        StartWorkingShiftRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest();
                            if (object.sessionId != null)
                                message.sessionId = String(object.sessionId);
                            if (object.mileage != null)
                                if ($util.Long)
                                    (message.mileage = $util.Long.fromValue(object.mileage)).unsigned = false;
                                else if (typeof object.mileage === "string")
                                    message.mileage = parseInt(object.mileage, 10);
                                else if (typeof object.mileage === "number")
                                    message.mileage = object.mileage;
                                else if (typeof object.mileage === "object")
                                    message.mileage = new $util.LongBits(object.mileage.low >>> 0, object.mileage.high >>> 0).toNumber();
                            return message;
                        };

                        /**
                         * Creates a plain object from a StartWorkingShiftRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest} message StartWorkingShiftRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StartWorkingShiftRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.sessionId = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.mileage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.mileage = options.longs === String ? "0" : 0;
                            }
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                object.sessionId = message.sessionId;
                            if (message.mileage != null && message.hasOwnProperty("mileage"))
                                if (typeof message.mileage === "number")
                                    object.mileage = options.longs === String ? String(message.mileage) : message.mileage;
                                else
                                    object.mileage = options.longs === String ? $util.Long.prototype.toString.call(message.mileage) : options.longs === Number ? new $util.LongBits(message.mileage.low >>> 0, message.mileage.high >>> 0).toNumber() : message.mileage;
                            return object;
                        };

                        /**
                         * Converts this StartWorkingShiftRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StartWorkingShiftRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StartWorkingShiftRequest;
                    })();

                    WorkingShiftProto.StartWorkingShiftResponse = (function() {

                        /**
                         * Properties of a StartWorkingShiftResponse.
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                         * @interface IStartWorkingShiftResponse
                         * @property {string|null} [id] StartWorkingShiftResponse id
                         * @property {string|null} [requestId] StartWorkingShiftResponse requestId
                         */

                        /**
                         * Constructs a new StartWorkingShiftResponse.
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                         * @classdesc Represents a StartWorkingShiftResponse.
                         * @implements IStartWorkingShiftResponse
                         * @constructor
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IStartWorkingShiftResponse=} [properties] Properties to set
                         */
                        function StartWorkingShiftResponse(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StartWorkingShiftResponse id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse
                         * @instance
                         */
                        StartWorkingShiftResponse.prototype.id = "";

                        /**
                         * StartWorkingShiftResponse requestId.
                         * @member {string} requestId
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse
                         * @instance
                         */
                        StartWorkingShiftResponse.prototype.requestId = "";

                        /**
                         * Creates a new StartWorkingShiftResponse instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IStartWorkingShiftResponse=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse} StartWorkingShiftResponse instance
                         */
                        StartWorkingShiftResponse.create = function create(properties) {
                            return new StartWorkingShiftResponse(properties);
                        };

                        /**
                         * Encodes the specified StartWorkingShiftResponse message. Does not implicitly {@link pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IStartWorkingShiftResponse} message StartWorkingShiftResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartWorkingShiftResponse.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.requestId);
                            return writer;
                        };

                        /**
                         * Encodes the specified StartWorkingShiftResponse message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IStartWorkingShiftResponse} message StartWorkingShiftResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartWorkingShiftResponse.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StartWorkingShiftResponse message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse} StartWorkingShiftResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartWorkingShiftResponse.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.requestId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StartWorkingShiftResponse message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse} StartWorkingShiftResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartWorkingShiftResponse.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StartWorkingShiftResponse message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StartWorkingShiftResponse.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                if (!$util.isString(message.requestId))
                                    return "requestId: string expected";
                            return null;
                        };

                        /**
                         * Creates a StartWorkingShiftResponse message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse} StartWorkingShiftResponse
                         */
                        StartWorkingShiftResponse.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.requestId != null)
                                message.requestId = String(object.requestId);
                            return message;
                        };

                        /**
                         * Creates a plain object from a StartWorkingShiftResponse message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse} message StartWorkingShiftResponse
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StartWorkingShiftResponse.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.requestId = "";
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                object.requestId = message.requestId;
                            return object;
                        };

                        /**
                         * Converts this StartWorkingShiftResponse to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.StartWorkingShiftResponse
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StartWorkingShiftResponse.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StartWorkingShiftResponse;
                    })();

                    WorkingShiftProto.FinishWorkingShiftRequest = (function() {

                        /**
                         * Properties of a FinishWorkingShiftRequest.
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                         * @interface IFinishWorkingShiftRequest
                         * @property {string|null} [id] FinishWorkingShiftRequest id
                         * @property {string|null} [sessionId] FinishWorkingShiftRequest sessionId
                         * @property {number|Long|null} [mileage] FinishWorkingShiftRequest mileage
                         */

                        /**
                         * Constructs a new FinishWorkingShiftRequest.
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                         * @classdesc Represents a FinishWorkingShiftRequest.
                         * @implements IFinishWorkingShiftRequest
                         * @constructor
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IFinishWorkingShiftRequest=} [properties] Properties to set
                         */
                        function FinishWorkingShiftRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FinishWorkingShiftRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest
                         * @instance
                         */
                        FinishWorkingShiftRequest.prototype.id = "";

                        /**
                         * FinishWorkingShiftRequest sessionId.
                         * @member {string} sessionId
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest
                         * @instance
                         */
                        FinishWorkingShiftRequest.prototype.sessionId = "";

                        /**
                         * FinishWorkingShiftRequest mileage.
                         * @member {number|Long} mileage
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest
                         * @instance
                         */
                        FinishWorkingShiftRequest.prototype.mileage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Creates a new FinishWorkingShiftRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IFinishWorkingShiftRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest} FinishWorkingShiftRequest instance
                         */
                        FinishWorkingShiftRequest.create = function create(properties) {
                            return new FinishWorkingShiftRequest(properties);
                        };

                        /**
                         * Encodes the specified FinishWorkingShiftRequest message. Does not implicitly {@link pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IFinishWorkingShiftRequest} message FinishWorkingShiftRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishWorkingShiftRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.sessionId);
                            if (message.mileage != null && message.hasOwnProperty("mileage"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.mileage);
                            return writer;
                        };

                        /**
                         * Encodes the specified FinishWorkingShiftRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IFinishWorkingShiftRequest} message FinishWorkingShiftRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishWorkingShiftRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FinishWorkingShiftRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest} FinishWorkingShiftRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishWorkingShiftRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.sessionId = reader.string();
                                    break;
                                case 3:
                                    message.mileage = reader.int64();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FinishWorkingShiftRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest} FinishWorkingShiftRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishWorkingShiftRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FinishWorkingShiftRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FinishWorkingShiftRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                if (!$util.isString(message.sessionId))
                                    return "sessionId: string expected";
                            if (message.mileage != null && message.hasOwnProperty("mileage"))
                                if (!$util.isInteger(message.mileage) && !(message.mileage && $util.isInteger(message.mileage.low) && $util.isInteger(message.mileage.high)))
                                    return "mileage: integer|Long expected";
                            return null;
                        };

                        /**
                         * Creates a FinishWorkingShiftRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest} FinishWorkingShiftRequest
                         */
                        FinishWorkingShiftRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.sessionId != null)
                                message.sessionId = String(object.sessionId);
                            if (object.mileage != null)
                                if ($util.Long)
                                    (message.mileage = $util.Long.fromValue(object.mileage)).unsigned = false;
                                else if (typeof object.mileage === "string")
                                    message.mileage = parseInt(object.mileage, 10);
                                else if (typeof object.mileage === "number")
                                    message.mileage = object.mileage;
                                else if (typeof object.mileage === "object")
                                    message.mileage = new $util.LongBits(object.mileage.low >>> 0, object.mileage.high >>> 0).toNumber();
                            return message;
                        };

                        /**
                         * Creates a plain object from a FinishWorkingShiftRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest} message FinishWorkingShiftRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FinishWorkingShiftRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.sessionId = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.mileage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.mileage = options.longs === String ? "0" : 0;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                object.sessionId = message.sessionId;
                            if (message.mileage != null && message.hasOwnProperty("mileage"))
                                if (typeof message.mileage === "number")
                                    object.mileage = options.longs === String ? String(message.mileage) : message.mileage;
                                else
                                    object.mileage = options.longs === String ? $util.Long.prototype.toString.call(message.mileage) : options.longs === Number ? new $util.LongBits(message.mileage.low >>> 0, message.mileage.high >>> 0).toNumber() : message.mileage;
                            return object;
                        };

                        /**
                         * Converts this FinishWorkingShiftRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FinishWorkingShiftRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FinishWorkingShiftRequest;
                    })();

                    WorkingShiftProto.FinishWorkingShiftPayload = (function() {

                        /**
                         * Properties of a FinishWorkingShiftPayload.
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                         * @interface IFinishWorkingShiftPayload
                         * @property {number|Long|null} [mileage] FinishWorkingShiftPayload mileage
                         */

                        /**
                         * Constructs a new FinishWorkingShiftPayload.
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                         * @classdesc Represents a FinishWorkingShiftPayload.
                         * @implements IFinishWorkingShiftPayload
                         * @constructor
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IFinishWorkingShiftPayload=} [properties] Properties to set
                         */
                        function FinishWorkingShiftPayload(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FinishWorkingShiftPayload mileage.
                         * @member {number|Long} mileage
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload
                         * @instance
                         */
                        FinishWorkingShiftPayload.prototype.mileage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Creates a new FinishWorkingShiftPayload instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IFinishWorkingShiftPayload=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload} FinishWorkingShiftPayload instance
                         */
                        FinishWorkingShiftPayload.create = function create(properties) {
                            return new FinishWorkingShiftPayload(properties);
                        };

                        /**
                         * Encodes the specified FinishWorkingShiftPayload message. Does not implicitly {@link pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IFinishWorkingShiftPayload} message FinishWorkingShiftPayload message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishWorkingShiftPayload.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.mileage != null && message.hasOwnProperty("mileage"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.mileage);
                            return writer;
                        };

                        /**
                         * Encodes the specified FinishWorkingShiftPayload message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IFinishWorkingShiftPayload} message FinishWorkingShiftPayload message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishWorkingShiftPayload.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FinishWorkingShiftPayload message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload} FinishWorkingShiftPayload
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishWorkingShiftPayload.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 3:
                                    message.mileage = reader.int64();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FinishWorkingShiftPayload message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload} FinishWorkingShiftPayload
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishWorkingShiftPayload.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FinishWorkingShiftPayload message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FinishWorkingShiftPayload.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.mileage != null && message.hasOwnProperty("mileage"))
                                if (!$util.isInteger(message.mileage) && !(message.mileage && $util.isInteger(message.mileage.low) && $util.isInteger(message.mileage.high)))
                                    return "mileage: integer|Long expected";
                            return null;
                        };

                        /**
                         * Creates a FinishWorkingShiftPayload message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload} FinishWorkingShiftPayload
                         */
                        FinishWorkingShiftPayload.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload();
                            if (object.mileage != null)
                                if ($util.Long)
                                    (message.mileage = $util.Long.fromValue(object.mileage)).unsigned = false;
                                else if (typeof object.mileage === "string")
                                    message.mileage = parseInt(object.mileage, 10);
                                else if (typeof object.mileage === "number")
                                    message.mileage = object.mileage;
                                else if (typeof object.mileage === "object")
                                    message.mileage = new $util.LongBits(object.mileage.low >>> 0, object.mileage.high >>> 0).toNumber();
                            return message;
                        };

                        /**
                         * Creates a plain object from a FinishWorkingShiftPayload message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload} message FinishWorkingShiftPayload
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FinishWorkingShiftPayload.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.mileage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.mileage = options.longs === String ? "0" : 0;
                            if (message.mileage != null && message.hasOwnProperty("mileage"))
                                if (typeof message.mileage === "number")
                                    object.mileage = options.longs === String ? String(message.mileage) : message.mileage;
                                else
                                    object.mileage = options.longs === String ? $util.Long.prototype.toString.call(message.mileage) : options.longs === Number ? new $util.LongBits(message.mileage.low >>> 0, message.mileage.high >>> 0).toNumber() : message.mileage;
                            return object;
                        };

                        /**
                         * Converts this FinishWorkingShiftPayload to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftPayload
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FinishWorkingShiftPayload.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FinishWorkingShiftPayload;
                    })();

                    WorkingShiftProto.FinishWorkingShiftResponse = (function() {

                        /**
                         * Properties of a FinishWorkingShiftResponse.
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                         * @interface IFinishWorkingShiftResponse
                         * @property {string|null} [requestId] FinishWorkingShiftResponse requestId
                         */

                        /**
                         * Constructs a new FinishWorkingShiftResponse.
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                         * @classdesc Represents a FinishWorkingShiftResponse.
                         * @implements IFinishWorkingShiftResponse
                         * @constructor
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IFinishWorkingShiftResponse=} [properties] Properties to set
                         */
                        function FinishWorkingShiftResponse(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FinishWorkingShiftResponse requestId.
                         * @member {string} requestId
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse
                         * @instance
                         */
                        FinishWorkingShiftResponse.prototype.requestId = "";

                        /**
                         * Creates a new FinishWorkingShiftResponse instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IFinishWorkingShiftResponse=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse} FinishWorkingShiftResponse instance
                         */
                        FinishWorkingShiftResponse.create = function create(properties) {
                            return new FinishWorkingShiftResponse(properties);
                        };

                        /**
                         * Encodes the specified FinishWorkingShiftResponse message. Does not implicitly {@link pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IFinishWorkingShiftResponse} message FinishWorkingShiftResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishWorkingShiftResponse.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.requestId);
                            return writer;
                        };

                        /**
                         * Encodes the specified FinishWorkingShiftResponse message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IFinishWorkingShiftResponse} message FinishWorkingShiftResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishWorkingShiftResponse.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FinishWorkingShiftResponse message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse} FinishWorkingShiftResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishWorkingShiftResponse.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.requestId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FinishWorkingShiftResponse message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse} FinishWorkingShiftResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishWorkingShiftResponse.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FinishWorkingShiftResponse message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FinishWorkingShiftResponse.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                if (!$util.isString(message.requestId))
                                    return "requestId: string expected";
                            return null;
                        };

                        /**
                         * Creates a FinishWorkingShiftResponse message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse} FinishWorkingShiftResponse
                         */
                        FinishWorkingShiftResponse.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse();
                            if (object.requestId != null)
                                message.requestId = String(object.requestId);
                            return message;
                        };

                        /**
                         * Creates a plain object from a FinishWorkingShiftResponse message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse} message FinishWorkingShiftResponse
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FinishWorkingShiftResponse.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.requestId = "";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                object.requestId = message.requestId;
                            return object;
                        };

                        /**
                         * Converts this FinishWorkingShiftResponse to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.FinishWorkingShiftResponse
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FinishWorkingShiftResponse.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FinishWorkingShiftResponse;
                    })();

                    WorkingShiftProto.DriverWorkingShiftStatistic = (function() {

                        /**
                         * Properties of a DriverWorkingShiftStatistic.
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                         * @interface IDriverWorkingShiftStatistic
                         * @property {string|null} [id] DriverWorkingShiftStatistic id
                         * @property {number|null} [tripsCount] DriverWorkingShiftStatistic tripsCount
                         * @property {number|null} [tripsAmount] DriverWorkingShiftStatistic tripsAmount
                         * @property {number|Long|null} [totalDuration] DriverWorkingShiftStatistic totalDuration
                         * @property {number|Long|null} [totalMileage] DriverWorkingShiftStatistic totalMileage
                         * @property {number|Long|null} [idleMileage] DriverWorkingShiftStatistic idleMileage
                         * @property {number|Long|null} [tripsMileage] DriverWorkingShiftStatistic tripsMileage
                         */

                        /**
                         * Constructs a new DriverWorkingShiftStatistic.
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto
                         * @classdesc Represents a DriverWorkingShiftStatistic.
                         * @implements IDriverWorkingShiftStatistic
                         * @constructor
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IDriverWorkingShiftStatistic=} [properties] Properties to set
                         */
                        function DriverWorkingShiftStatistic(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DriverWorkingShiftStatistic id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic
                         * @instance
                         */
                        DriverWorkingShiftStatistic.prototype.id = "";

                        /**
                         * DriverWorkingShiftStatistic tripsCount.
                         * @member {number} tripsCount
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic
                         * @instance
                         */
                        DriverWorkingShiftStatistic.prototype.tripsCount = 0;

                        /**
                         * DriverWorkingShiftStatistic tripsAmount.
                         * @member {number} tripsAmount
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic
                         * @instance
                         */
                        DriverWorkingShiftStatistic.prototype.tripsAmount = 0;

                        /**
                         * DriverWorkingShiftStatistic totalDuration.
                         * @member {number|Long} totalDuration
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic
                         * @instance
                         */
                        DriverWorkingShiftStatistic.prototype.totalDuration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * DriverWorkingShiftStatistic totalMileage.
                         * @member {number|Long} totalMileage
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic
                         * @instance
                         */
                        DriverWorkingShiftStatistic.prototype.totalMileage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * DriverWorkingShiftStatistic idleMileage.
                         * @member {number|Long} idleMileage
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic
                         * @instance
                         */
                        DriverWorkingShiftStatistic.prototype.idleMileage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * DriverWorkingShiftStatistic tripsMileage.
                         * @member {number|Long} tripsMileage
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic
                         * @instance
                         */
                        DriverWorkingShiftStatistic.prototype.tripsMileage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Creates a new DriverWorkingShiftStatistic instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IDriverWorkingShiftStatistic=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic} DriverWorkingShiftStatistic instance
                         */
                        DriverWorkingShiftStatistic.create = function create(properties) {
                            return new DriverWorkingShiftStatistic(properties);
                        };

                        /**
                         * Encodes the specified DriverWorkingShiftStatistic message. Does not implicitly {@link pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IDriverWorkingShiftStatistic} message DriverWorkingShiftStatistic message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverWorkingShiftStatistic.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.tripsCount != null && message.hasOwnProperty("tripsCount"))
                                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.tripsCount);
                            if (message.tripsAmount != null && message.hasOwnProperty("tripsAmount"))
                                writer.uint32(/* id 3, wireType 1 =*/25).double(message.tripsAmount);
                            if (message.totalDuration != null && message.hasOwnProperty("totalDuration"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.totalDuration);
                            if (message.totalMileage != null && message.hasOwnProperty("totalMileage"))
                                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.totalMileage);
                            if (message.idleMileage != null && message.hasOwnProperty("idleMileage"))
                                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.idleMileage);
                            if (message.tripsMileage != null && message.hasOwnProperty("tripsMileage"))
                                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.tripsMileage);
                            return writer;
                        };

                        /**
                         * Encodes the specified DriverWorkingShiftStatistic message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.IDriverWorkingShiftStatistic} message DriverWorkingShiftStatistic message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverWorkingShiftStatistic.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DriverWorkingShiftStatistic message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic} DriverWorkingShiftStatistic
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverWorkingShiftStatistic.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.tripsCount = reader.uint32();
                                    break;
                                case 3:
                                    message.tripsAmount = reader.double();
                                    break;
                                case 4:
                                    message.totalDuration = reader.int64();
                                    break;
                                case 5:
                                    message.totalMileage = reader.int64();
                                    break;
                                case 6:
                                    message.idleMileage = reader.int64();
                                    break;
                                case 7:
                                    message.tripsMileage = reader.int64();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DriverWorkingShiftStatistic message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic} DriverWorkingShiftStatistic
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverWorkingShiftStatistic.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DriverWorkingShiftStatistic message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DriverWorkingShiftStatistic.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.tripsCount != null && message.hasOwnProperty("tripsCount"))
                                if (!$util.isInteger(message.tripsCount))
                                    return "tripsCount: integer expected";
                            if (message.tripsAmount != null && message.hasOwnProperty("tripsAmount"))
                                if (typeof message.tripsAmount !== "number")
                                    return "tripsAmount: number expected";
                            if (message.totalDuration != null && message.hasOwnProperty("totalDuration"))
                                if (!$util.isInteger(message.totalDuration) && !(message.totalDuration && $util.isInteger(message.totalDuration.low) && $util.isInteger(message.totalDuration.high)))
                                    return "totalDuration: integer|Long expected";
                            if (message.totalMileage != null && message.hasOwnProperty("totalMileage"))
                                if (!$util.isInteger(message.totalMileage) && !(message.totalMileage && $util.isInteger(message.totalMileage.low) && $util.isInteger(message.totalMileage.high)))
                                    return "totalMileage: integer|Long expected";
                            if (message.idleMileage != null && message.hasOwnProperty("idleMileage"))
                                if (!$util.isInteger(message.idleMileage) && !(message.idleMileage && $util.isInteger(message.idleMileage.low) && $util.isInteger(message.idleMileage.high)))
                                    return "idleMileage: integer|Long expected";
                            if (message.tripsMileage != null && message.hasOwnProperty("tripsMileage"))
                                if (!$util.isInteger(message.tripsMileage) && !(message.tripsMileage && $util.isInteger(message.tripsMileage.low) && $util.isInteger(message.tripsMileage.high)))
                                    return "tripsMileage: integer|Long expected";
                            return null;
                        };

                        /**
                         * Creates a DriverWorkingShiftStatistic message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic} DriverWorkingShiftStatistic
                         */
                        DriverWorkingShiftStatistic.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.tripsCount != null)
                                message.tripsCount = object.tripsCount >>> 0;
                            if (object.tripsAmount != null)
                                message.tripsAmount = Number(object.tripsAmount);
                            if (object.totalDuration != null)
                                if ($util.Long)
                                    (message.totalDuration = $util.Long.fromValue(object.totalDuration)).unsigned = false;
                                else if (typeof object.totalDuration === "string")
                                    message.totalDuration = parseInt(object.totalDuration, 10);
                                else if (typeof object.totalDuration === "number")
                                    message.totalDuration = object.totalDuration;
                                else if (typeof object.totalDuration === "object")
                                    message.totalDuration = new $util.LongBits(object.totalDuration.low >>> 0, object.totalDuration.high >>> 0).toNumber();
                            if (object.totalMileage != null)
                                if ($util.Long)
                                    (message.totalMileage = $util.Long.fromValue(object.totalMileage)).unsigned = false;
                                else if (typeof object.totalMileage === "string")
                                    message.totalMileage = parseInt(object.totalMileage, 10);
                                else if (typeof object.totalMileage === "number")
                                    message.totalMileage = object.totalMileage;
                                else if (typeof object.totalMileage === "object")
                                    message.totalMileage = new $util.LongBits(object.totalMileage.low >>> 0, object.totalMileage.high >>> 0).toNumber();
                            if (object.idleMileage != null)
                                if ($util.Long)
                                    (message.idleMileage = $util.Long.fromValue(object.idleMileage)).unsigned = false;
                                else if (typeof object.idleMileage === "string")
                                    message.idleMileage = parseInt(object.idleMileage, 10);
                                else if (typeof object.idleMileage === "number")
                                    message.idleMileage = object.idleMileage;
                                else if (typeof object.idleMileage === "object")
                                    message.idleMileage = new $util.LongBits(object.idleMileage.low >>> 0, object.idleMileage.high >>> 0).toNumber();
                            if (object.tripsMileage != null)
                                if ($util.Long)
                                    (message.tripsMileage = $util.Long.fromValue(object.tripsMileage)).unsigned = false;
                                else if (typeof object.tripsMileage === "string")
                                    message.tripsMileage = parseInt(object.tripsMileage, 10);
                                else if (typeof object.tripsMileage === "number")
                                    message.tripsMileage = object.tripsMileage;
                                else if (typeof object.tripsMileage === "object")
                                    message.tripsMileage = new $util.LongBits(object.tripsMileage.low >>> 0, object.tripsMileage.high >>> 0).toNumber();
                            return message;
                        };

                        /**
                         * Creates a plain object from a DriverWorkingShiftStatistic message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic
                         * @static
                         * @param {pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic} message DriverWorkingShiftStatistic
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DriverWorkingShiftStatistic.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.tripsCount = 0;
                                object.tripsAmount = 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.totalDuration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.totalDuration = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.totalMileage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.totalMileage = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.idleMileage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.idleMileage = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.tripsMileage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.tripsMileage = options.longs === String ? "0" : 0;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.tripsCount != null && message.hasOwnProperty("tripsCount"))
                                object.tripsCount = message.tripsCount;
                            if (message.tripsAmount != null && message.hasOwnProperty("tripsAmount"))
                                object.tripsAmount = options.json && !isFinite(message.tripsAmount) ? String(message.tripsAmount) : message.tripsAmount;
                            if (message.totalDuration != null && message.hasOwnProperty("totalDuration"))
                                if (typeof message.totalDuration === "number")
                                    object.totalDuration = options.longs === String ? String(message.totalDuration) : message.totalDuration;
                                else
                                    object.totalDuration = options.longs === String ? $util.Long.prototype.toString.call(message.totalDuration) : options.longs === Number ? new $util.LongBits(message.totalDuration.low >>> 0, message.totalDuration.high >>> 0).toNumber() : message.totalDuration;
                            if (message.totalMileage != null && message.hasOwnProperty("totalMileage"))
                                if (typeof message.totalMileage === "number")
                                    object.totalMileage = options.longs === String ? String(message.totalMileage) : message.totalMileage;
                                else
                                    object.totalMileage = options.longs === String ? $util.Long.prototype.toString.call(message.totalMileage) : options.longs === Number ? new $util.LongBits(message.totalMileage.low >>> 0, message.totalMileage.high >>> 0).toNumber() : message.totalMileage;
                            if (message.idleMileage != null && message.hasOwnProperty("idleMileage"))
                                if (typeof message.idleMileage === "number")
                                    object.idleMileage = options.longs === String ? String(message.idleMileage) : message.idleMileage;
                                else
                                    object.idleMileage = options.longs === String ? $util.Long.prototype.toString.call(message.idleMileage) : options.longs === Number ? new $util.LongBits(message.idleMileage.low >>> 0, message.idleMileage.high >>> 0).toNumber() : message.idleMileage;
                            if (message.tripsMileage != null && message.hasOwnProperty("tripsMileage"))
                                if (typeof message.tripsMileage === "number")
                                    object.tripsMileage = options.longs === String ? String(message.tripsMileage) : message.tripsMileage;
                                else
                                    object.tripsMileage = options.longs === String ? $util.Long.prototype.toString.call(message.tripsMileage) : options.longs === Number ? new $util.LongBits(message.tripsMileage.low >>> 0, message.tripsMileage.high >>> 0).toNumber() : message.tripsMileage;
                            return object;
                        };

                        /**
                         * Converts this DriverWorkingShiftStatistic to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.WorkingShiftProto.DriverWorkingShiftStatistic
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DriverWorkingShiftStatistic.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DriverWorkingShiftStatistic;
                    })();

                    return WorkingShiftProto;
                })();

                driver.DriverRequestProto = (function() {

                    /**
                     * Properties of a DriverRequestProto.
                     * @memberof pro.megakit.amelia.driver
                     * @interface IDriverRequestProto
                     */

                    /**
                     * Constructs a new DriverRequestProto.
                     * @memberof pro.megakit.amelia.driver
                     * @classdesc Represents a DriverRequestProto.
                     * @implements IDriverRequestProto
                     * @constructor
                     * @param {pro.megakit.amelia.driver.IDriverRequestProto=} [properties] Properties to set
                     */
                    function DriverRequestProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new DriverRequestProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.driver.DriverRequestProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IDriverRequestProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.driver.DriverRequestProto} DriverRequestProto instance
                     */
                    DriverRequestProto.create = function create(properties) {
                        return new DriverRequestProto(properties);
                    };

                    /**
                     * Encodes the specified DriverRequestProto message. Does not implicitly {@link pro.megakit.amelia.driver.DriverRequestProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.driver.DriverRequestProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IDriverRequestProto} message DriverRequestProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverRequestProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified DriverRequestProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverRequestProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.driver.DriverRequestProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IDriverRequestProto} message DriverRequestProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverRequestProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DriverRequestProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.driver.DriverRequestProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.driver.DriverRequestProto} DriverRequestProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverRequestProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverRequestProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DriverRequestProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.driver.DriverRequestProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.driver.DriverRequestProto} DriverRequestProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverRequestProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DriverRequestProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.driver.DriverRequestProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DriverRequestProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a DriverRequestProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.driver.DriverRequestProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.driver.DriverRequestProto} DriverRequestProto
                     */
                    DriverRequestProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.driver.DriverRequestProto)
                            return object;
                        return new $root.pro.megakit.amelia.driver.DriverRequestProto();
                    };

                    /**
                     * Creates a plain object from a DriverRequestProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.driver.DriverRequestProto
                     * @static
                     * @param {pro.megakit.amelia.driver.DriverRequestProto} message DriverRequestProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DriverRequestProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this DriverRequestProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.driver.DriverRequestProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DriverRequestProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    DriverRequestProto.CreateDriverRequest = (function() {

                        /**
                         * Properties of a CreateDriverRequest.
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto
                         * @interface ICreateDriverRequest
                         * @property {string|null} [wshId] CreateDriverRequest wshId
                         * @property {pro.megakit.amelia.SessionProto.SessionType|null} [requestType] CreateDriverRequest requestType
                         * @property {string|null} [sessionId] CreateDriverRequest sessionId
                         * @property {Uint8Array|null} [payload] CreateDriverRequest payload
                         */

                        /**
                         * Constructs a new CreateDriverRequest.
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto
                         * @classdesc Represents a CreateDriverRequest.
                         * @implements ICreateDriverRequest
                         * @constructor
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ICreateDriverRequest=} [properties] Properties to set
                         */
                        function CreateDriverRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CreateDriverRequest wshId.
                         * @member {string} wshId
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest
                         * @instance
                         */
                        CreateDriverRequest.prototype.wshId = "";

                        /**
                         * CreateDriverRequest requestType.
                         * @member {pro.megakit.amelia.SessionProto.SessionType} requestType
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest
                         * @instance
                         */
                        CreateDriverRequest.prototype.requestType = 0;

                        /**
                         * CreateDriverRequest sessionId.
                         * @member {string} sessionId
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest
                         * @instance
                         */
                        CreateDriverRequest.prototype.sessionId = "";

                        /**
                         * CreateDriverRequest payload.
                         * @member {Uint8Array} payload
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest
                         * @instance
                         */
                        CreateDriverRequest.prototype.payload = $util.newBuffer([]);

                        /**
                         * Creates a new CreateDriverRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ICreateDriverRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest} CreateDriverRequest instance
                         */
                        CreateDriverRequest.create = function create(properties) {
                            return new CreateDriverRequest(properties);
                        };

                        /**
                         * Encodes the specified CreateDriverRequest message. Does not implicitly {@link pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ICreateDriverRequest} message CreateDriverRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CreateDriverRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.wshId);
                            if (message.requestType != null && message.hasOwnProperty("requestType"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.requestType);
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.sessionId);
                            if (message.payload != null && message.hasOwnProperty("payload"))
                                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.payload);
                            return writer;
                        };

                        /**
                         * Encodes the specified CreateDriverRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ICreateDriverRequest} message CreateDriverRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CreateDriverRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a CreateDriverRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest} CreateDriverRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CreateDriverRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.wshId = reader.string();
                                    break;
                                case 2:
                                    message.requestType = reader.int32();
                                    break;
                                case 3:
                                    message.sessionId = reader.string();
                                    break;
                                case 4:
                                    message.payload = reader.bytes();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a CreateDriverRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest} CreateDriverRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CreateDriverRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a CreateDriverRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CreateDriverRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                if (!$util.isString(message.wshId))
                                    return "wshId: string expected";
                            if (message.requestType != null && message.hasOwnProperty("requestType"))
                                switch (message.requestType) {
                                default:
                                    return "requestType: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                    break;
                                }
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                if (!$util.isString(message.sessionId))
                                    return "sessionId: string expected";
                            if (message.payload != null && message.hasOwnProperty("payload"))
                                if (!(message.payload && typeof message.payload.length === "number" || $util.isString(message.payload)))
                                    return "payload: buffer expected";
                            return null;
                        };

                        /**
                         * Creates a CreateDriverRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest} CreateDriverRequest
                         */
                        CreateDriverRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest();
                            if (object.wshId != null)
                                message.wshId = String(object.wshId);
                            switch (object.requestType) {
                            case "START_WORKING_SHIFT":
                            case 0:
                                message.requestType = 0;
                                break;
                            case "FINISH_WORKING_SHIFT":
                            case 1:
                                message.requestType = 1;
                                break;
                            case "VEHICLE_REFILL":
                            case 2:
                                message.requestType = 2;
                                break;
                            case "START_VEHICLE_WASHING":
                            case 3:
                                message.requestType = 3;
                                break;
                            case "FINISH_VEHICLE_WASHING":
                            case 4:
                                message.requestType = 4;
                                break;
                            case "STORE_DRIVER_EXPENSE":
                            case 5:
                                message.requestType = 5;
                                break;
                            case "START_DRIVER_REST":
                            case 6:
                                message.requestType = 6;
                                break;
                            case "CANCEL_DRIVER_REQUEST":
                            case 7:
                                message.requestType = 7;
                                break;
                            }
                            if (object.sessionId != null)
                                message.sessionId = String(object.sessionId);
                            if (object.payload != null)
                                if (typeof object.payload === "string")
                                    $util.base64.decode(object.payload, message.payload = $util.newBuffer($util.base64.length(object.payload)), 0);
                                else if (object.payload.length)
                                    message.payload = object.payload;
                            return message;
                        };

                        /**
                         * Creates a plain object from a CreateDriverRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest} message CreateDriverRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CreateDriverRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.wshId = "";
                                object.requestType = options.enums === String ? "START_WORKING_SHIFT" : 0;
                                object.sessionId = "";
                                if (options.bytes === String)
                                    object.payload = "";
                                else {
                                    object.payload = [];
                                    if (options.bytes !== Array)
                                        object.payload = $util.newBuffer(object.payload);
                                }
                            }
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                object.wshId = message.wshId;
                            if (message.requestType != null && message.hasOwnProperty("requestType"))
                                object.requestType = options.enums === String ? $root.pro.megakit.amelia.SessionProto.SessionType[message.requestType] : message.requestType;
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                object.sessionId = message.sessionId;
                            if (message.payload != null && message.hasOwnProperty("payload"))
                                object.payload = options.bytes === String ? $util.base64.encode(message.payload, 0, message.payload.length) : options.bytes === Array ? Array.prototype.slice.call(message.payload) : message.payload;
                            return object;
                        };

                        /**
                         * Converts this CreateDriverRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CreateDriverRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return CreateDriverRequest;
                    })();

                    DriverRequestProto.CreateDriverRequestResponse = (function() {

                        /**
                         * Properties of a CreateDriverRequestResponse.
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto
                         * @interface ICreateDriverRequestResponse
                         * @property {string|null} [id] CreateDriverRequestResponse id
                         */

                        /**
                         * Constructs a new CreateDriverRequestResponse.
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto
                         * @classdesc Represents a CreateDriverRequestResponse.
                         * @implements ICreateDriverRequestResponse
                         * @constructor
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ICreateDriverRequestResponse=} [properties] Properties to set
                         */
                        function CreateDriverRequestResponse(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CreateDriverRequestResponse id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse
                         * @instance
                         */
                        CreateDriverRequestResponse.prototype.id = "";

                        /**
                         * Creates a new CreateDriverRequestResponse instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ICreateDriverRequestResponse=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse} CreateDriverRequestResponse instance
                         */
                        CreateDriverRequestResponse.create = function create(properties) {
                            return new CreateDriverRequestResponse(properties);
                        };

                        /**
                         * Encodes the specified CreateDriverRequestResponse message. Does not implicitly {@link pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ICreateDriverRequestResponse} message CreateDriverRequestResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CreateDriverRequestResponse.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified CreateDriverRequestResponse message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ICreateDriverRequestResponse} message CreateDriverRequestResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CreateDriverRequestResponse.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a CreateDriverRequestResponse message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse} CreateDriverRequestResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CreateDriverRequestResponse.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a CreateDriverRequestResponse message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse} CreateDriverRequestResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CreateDriverRequestResponse.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a CreateDriverRequestResponse message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CreateDriverRequestResponse.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a CreateDriverRequestResponse message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse} CreateDriverRequestResponse
                         */
                        CreateDriverRequestResponse.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a CreateDriverRequestResponse message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse} message CreateDriverRequestResponse
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CreateDriverRequestResponse.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this CreateDriverRequestResponse to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CreateDriverRequestResponse
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CreateDriverRequestResponse.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return CreateDriverRequestResponse;
                    })();

                    DriverRequestProto.CancelDriverRequest = (function() {

                        /**
                         * Properties of a CancelDriverRequest.
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto
                         * @interface ICancelDriverRequest
                         * @property {string|null} [id] CancelDriverRequest id
                         */

                        /**
                         * Constructs a new CancelDriverRequest.
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto
                         * @classdesc Represents a CancelDriverRequest.
                         * @implements ICancelDriverRequest
                         * @constructor
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ICancelDriverRequest=} [properties] Properties to set
                         */
                        function CancelDriverRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CancelDriverRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest
                         * @instance
                         */
                        CancelDriverRequest.prototype.id = "";

                        /**
                         * Creates a new CancelDriverRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ICancelDriverRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest} CancelDriverRequest instance
                         */
                        CancelDriverRequest.create = function create(properties) {
                            return new CancelDriverRequest(properties);
                        };

                        /**
                         * Encodes the specified CancelDriverRequest message. Does not implicitly {@link pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ICancelDriverRequest} message CancelDriverRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CancelDriverRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified CancelDriverRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ICancelDriverRequest} message CancelDriverRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CancelDriverRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a CancelDriverRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest} CancelDriverRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CancelDriverRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a CancelDriverRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest} CancelDriverRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CancelDriverRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a CancelDriverRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CancelDriverRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a CancelDriverRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest} CancelDriverRequest
                         */
                        CancelDriverRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a CancelDriverRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest} message CancelDriverRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CancelDriverRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this CancelDriverRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CancelDriverRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return CancelDriverRequest;
                    })();

                    DriverRequestProto.CancelDriverRequestPayload = (function() {

                        /**
                         * Properties of a CancelDriverRequestPayload.
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto
                         * @interface ICancelDriverRequestPayload
                         * @property {string|null} [requestId] CancelDriverRequestPayload requestId
                         */

                        /**
                         * Constructs a new CancelDriverRequestPayload.
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto
                         * @classdesc Represents a CancelDriverRequestPayload.
                         * @implements ICancelDriverRequestPayload
                         * @constructor
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ICancelDriverRequestPayload=} [properties] Properties to set
                         */
                        function CancelDriverRequestPayload(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CancelDriverRequestPayload requestId.
                         * @member {string} requestId
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload
                         * @instance
                         */
                        CancelDriverRequestPayload.prototype.requestId = "";

                        /**
                         * Creates a new CancelDriverRequestPayload instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ICancelDriverRequestPayload=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload} CancelDriverRequestPayload instance
                         */
                        CancelDriverRequestPayload.create = function create(properties) {
                            return new CancelDriverRequestPayload(properties);
                        };

                        /**
                         * Encodes the specified CancelDriverRequestPayload message. Does not implicitly {@link pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ICancelDriverRequestPayload} message CancelDriverRequestPayload message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CancelDriverRequestPayload.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.requestId);
                            return writer;
                        };

                        /**
                         * Encodes the specified CancelDriverRequestPayload message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ICancelDriverRequestPayload} message CancelDriverRequestPayload message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CancelDriverRequestPayload.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a CancelDriverRequestPayload message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload} CancelDriverRequestPayload
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CancelDriverRequestPayload.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.requestId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a CancelDriverRequestPayload message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload} CancelDriverRequestPayload
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CancelDriverRequestPayload.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a CancelDriverRequestPayload message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CancelDriverRequestPayload.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                if (!$util.isString(message.requestId))
                                    return "requestId: string expected";
                            return null;
                        };

                        /**
                         * Creates a CancelDriverRequestPayload message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload} CancelDriverRequestPayload
                         */
                        CancelDriverRequestPayload.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload();
                            if (object.requestId != null)
                                message.requestId = String(object.requestId);
                            return message;
                        };

                        /**
                         * Creates a plain object from a CancelDriverRequestPayload message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload} message CancelDriverRequestPayload
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CancelDriverRequestPayload.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.requestId = "";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                object.requestId = message.requestId;
                            return object;
                        };

                        /**
                         * Converts this CancelDriverRequestPayload to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.CancelDriverRequestPayload
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CancelDriverRequestPayload.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return CancelDriverRequestPayload;
                    })();

                    DriverRequestProto.ActiveDriverRequest = (function() {

                        /**
                         * Properties of an ActiveDriverRequest.
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto
                         * @interface IActiveDriverRequest
                         * @property {string|null} [id] ActiveDriverRequest id
                         * @property {pro.megakit.amelia.SessionProto.SessionType|null} [type] ActiveDriverRequest type
                         * @property {pro.megakit.amelia.driver.SessionProto.IActiveSession|null} [session] ActiveDriverRequest session
                         * @property {string|null} [status] ActiveDriverRequest status
                         * @property {google.protobuf.ITimestamp|null} [createdAt] ActiveDriverRequest createdAt
                         * @property {google.protobuf.ITimestamp|null} [assignedAt] ActiveDriverRequest assignedAt
                         * @property {Uint8Array|null} [payload] ActiveDriverRequest payload
                         */

                        /**
                         * Constructs a new ActiveDriverRequest.
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto
                         * @classdesc Represents an ActiveDriverRequest.
                         * @implements IActiveDriverRequest
                         * @constructor
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.IActiveDriverRequest=} [properties] Properties to set
                         */
                        function ActiveDriverRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ActiveDriverRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest
                         * @instance
                         */
                        ActiveDriverRequest.prototype.id = "";

                        /**
                         * ActiveDriverRequest type.
                         * @member {pro.megakit.amelia.SessionProto.SessionType} type
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest
                         * @instance
                         */
                        ActiveDriverRequest.prototype.type = 0;

                        /**
                         * ActiveDriverRequest session.
                         * @member {pro.megakit.amelia.driver.SessionProto.IActiveSession|null|undefined} session
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest
                         * @instance
                         */
                        ActiveDriverRequest.prototype.session = null;

                        /**
                         * ActiveDriverRequest status.
                         * @member {string} status
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest
                         * @instance
                         */
                        ActiveDriverRequest.prototype.status = "";

                        /**
                         * ActiveDriverRequest createdAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest
                         * @instance
                         */
                        ActiveDriverRequest.prototype.createdAt = null;

                        /**
                         * ActiveDriverRequest assignedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} assignedAt
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest
                         * @instance
                         */
                        ActiveDriverRequest.prototype.assignedAt = null;

                        /**
                         * ActiveDriverRequest payload.
                         * @member {Uint8Array} payload
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest
                         * @instance
                         */
                        ActiveDriverRequest.prototype.payload = $util.newBuffer([]);

                        /**
                         * Creates a new ActiveDriverRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.IActiveDriverRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest} ActiveDriverRequest instance
                         */
                        ActiveDriverRequest.create = function create(properties) {
                            return new ActiveDriverRequest(properties);
                        };

                        /**
                         * Encodes the specified ActiveDriverRequest message. Does not implicitly {@link pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.IActiveDriverRequest} message ActiveDriverRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveDriverRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.type != null && message.hasOwnProperty("type"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
                            if (message.session != null && message.hasOwnProperty("session"))
                                $root.pro.megakit.amelia.driver.SessionProto.ActiveSession.encode(message.session, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.status != null && message.hasOwnProperty("status"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.status);
                            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            if (message.assignedAt != null && message.hasOwnProperty("assignedAt"))
                                $root.google.protobuf.Timestamp.encode(message.assignedAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            if (message.payload != null && message.hasOwnProperty("payload"))
                                writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.payload);
                            return writer;
                        };

                        /**
                         * Encodes the specified ActiveDriverRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.IActiveDriverRequest} message ActiveDriverRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveDriverRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an ActiveDriverRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest} ActiveDriverRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveDriverRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.type = reader.int32();
                                    break;
                                case 3:
                                    message.session = $root.pro.megakit.amelia.driver.SessionProto.ActiveSession.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.status = reader.string();
                                    break;
                                case 5:
                                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                case 6:
                                    message.assignedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                case 7:
                                    message.payload = reader.bytes();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an ActiveDriverRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest} ActiveDriverRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveDriverRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an ActiveDriverRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ActiveDriverRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                switch (message.type) {
                                default:
                                    return "type: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                    break;
                                }
                            if (message.session != null && message.hasOwnProperty("session")) {
                                var error = $root.pro.megakit.amelia.driver.SessionProto.ActiveSession.verify(message.session);
                                if (error)
                                    return "session." + error;
                            }
                            if (message.status != null && message.hasOwnProperty("status"))
                                if (!$util.isString(message.status))
                                    return "status: string expected";
                            if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.createdAt);
                                if (error)
                                    return "createdAt." + error;
                            }
                            if (message.assignedAt != null && message.hasOwnProperty("assignedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.assignedAt);
                                if (error)
                                    return "assignedAt." + error;
                            }
                            if (message.payload != null && message.hasOwnProperty("payload"))
                                if (!(message.payload && typeof message.payload.length === "number" || $util.isString(message.payload)))
                                    return "payload: buffer expected";
                            return null;
                        };

                        /**
                         * Creates an ActiveDriverRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest} ActiveDriverRequest
                         */
                        ActiveDriverRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            switch (object.type) {
                            case "START_WORKING_SHIFT":
                            case 0:
                                message.type = 0;
                                break;
                            case "FINISH_WORKING_SHIFT":
                            case 1:
                                message.type = 1;
                                break;
                            case "VEHICLE_REFILL":
                            case 2:
                                message.type = 2;
                                break;
                            case "START_VEHICLE_WASHING":
                            case 3:
                                message.type = 3;
                                break;
                            case "FINISH_VEHICLE_WASHING":
                            case 4:
                                message.type = 4;
                                break;
                            case "STORE_DRIVER_EXPENSE":
                            case 5:
                                message.type = 5;
                                break;
                            case "START_DRIVER_REST":
                            case 6:
                                message.type = 6;
                                break;
                            case "CANCEL_DRIVER_REQUEST":
                            case 7:
                                message.type = 7;
                                break;
                            }
                            if (object.session != null) {
                                if (typeof object.session !== "object")
                                    throw TypeError(".pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest.session: object expected");
                                message.session = $root.pro.megakit.amelia.driver.SessionProto.ActiveSession.fromObject(object.session);
                            }
                            if (object.status != null)
                                message.status = String(object.status);
                            if (object.createdAt != null) {
                                if (typeof object.createdAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest.createdAt: object expected");
                                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
                            }
                            if (object.assignedAt != null) {
                                if (typeof object.assignedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest.assignedAt: object expected");
                                message.assignedAt = $root.google.protobuf.Timestamp.fromObject(object.assignedAt);
                            }
                            if (object.payload != null)
                                if (typeof object.payload === "string")
                                    $util.base64.decode(object.payload, message.payload = $util.newBuffer($util.base64.length(object.payload)), 0);
                                else if (object.payload.length)
                                    message.payload = object.payload;
                            return message;
                        };

                        /**
                         * Creates a plain object from an ActiveDriverRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest} message ActiveDriverRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ActiveDriverRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.type = options.enums === String ? "START_WORKING_SHIFT" : 0;
                                object.session = null;
                                object.status = "";
                                object.createdAt = null;
                                object.assignedAt = null;
                                if (options.bytes === String)
                                    object.payload = "";
                                else {
                                    object.payload = [];
                                    if (options.bytes !== Array)
                                        object.payload = $util.newBuffer(object.payload);
                                }
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = options.enums === String ? $root.pro.megakit.amelia.SessionProto.SessionType[message.type] : message.type;
                            if (message.session != null && message.hasOwnProperty("session"))
                                object.session = $root.pro.megakit.amelia.driver.SessionProto.ActiveSession.toObject(message.session, options);
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = message.status;
                            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
                            if (message.assignedAt != null && message.hasOwnProperty("assignedAt"))
                                object.assignedAt = $root.google.protobuf.Timestamp.toObject(message.assignedAt, options);
                            if (message.payload != null && message.hasOwnProperty("payload"))
                                object.payload = options.bytes === String ? $util.base64.encode(message.payload, 0, message.payload.length) : options.bytes === Array ? Array.prototype.slice.call(message.payload) : message.payload;
                            return object;
                        };

                        /**
                         * Converts this ActiveDriverRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.DriverRequestProto.ActiveDriverRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ActiveDriverRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ActiveDriverRequest;
                    })();

                    return DriverRequestProto;
                })();

                driver.VehicleRefillProto = (function() {

                    /**
                     * Properties of a VehicleRefillProto.
                     * @memberof pro.megakit.amelia.driver
                     * @interface IVehicleRefillProto
                     */

                    /**
                     * Constructs a new VehicleRefillProto.
                     * @memberof pro.megakit.amelia.driver
                     * @classdesc Represents a VehicleRefillProto.
                     * @implements IVehicleRefillProto
                     * @constructor
                     * @param {pro.megakit.amelia.driver.IVehicleRefillProto=} [properties] Properties to set
                     */
                    function VehicleRefillProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new VehicleRefillProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.driver.VehicleRefillProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IVehicleRefillProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.driver.VehicleRefillProto} VehicleRefillProto instance
                     */
                    VehicleRefillProto.create = function create(properties) {
                        return new VehicleRefillProto(properties);
                    };

                    /**
                     * Encodes the specified VehicleRefillProto message. Does not implicitly {@link pro.megakit.amelia.driver.VehicleRefillProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.driver.VehicleRefillProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IVehicleRefillProto} message VehicleRefillProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VehicleRefillProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified VehicleRefillProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.VehicleRefillProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.driver.VehicleRefillProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IVehicleRefillProto} message VehicleRefillProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VehicleRefillProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a VehicleRefillProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.driver.VehicleRefillProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.driver.VehicleRefillProto} VehicleRefillProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VehicleRefillProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.VehicleRefillProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a VehicleRefillProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.driver.VehicleRefillProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.driver.VehicleRefillProto} VehicleRefillProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VehicleRefillProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a VehicleRefillProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.driver.VehicleRefillProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    VehicleRefillProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a VehicleRefillProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.driver.VehicleRefillProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.driver.VehicleRefillProto} VehicleRefillProto
                     */
                    VehicleRefillProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.driver.VehicleRefillProto)
                            return object;
                        return new $root.pro.megakit.amelia.driver.VehicleRefillProto();
                    };

                    /**
                     * Creates a plain object from a VehicleRefillProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.driver.VehicleRefillProto
                     * @static
                     * @param {pro.megakit.amelia.driver.VehicleRefillProto} message VehicleRefillProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    VehicleRefillProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this VehicleRefillProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.driver.VehicleRefillProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    VehicleRefillProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * VehicleFuelType enum.
                     * @name pro.megakit.amelia.driver.VehicleRefillProto.VehicleFuelType
                     * @enum {string}
                     * @property {number} PETROL=0 PETROL value
                     * @property {number} GAS=1 GAS value
                     */
                    VehicleRefillProto.VehicleFuelType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "PETROL"] = 0;
                        values[valuesById[1] = "GAS"] = 1;
                        return values;
                    })();

                    VehicleRefillProto.StoreVehicleRefillRequest = (function() {

                        /**
                         * Properties of a StoreVehicleRefillRequest.
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto
                         * @interface IStoreVehicleRefillRequest
                         * @property {string|null} [requestId] StoreVehicleRefillRequest requestId
                         * @property {string|null} [sessionId] StoreVehicleRefillRequest sessionId
                         * @property {pro.megakit.amelia.driver.VehicleRefillProto.VehicleFuelType|null} [fuelType] StoreVehicleRefillRequest fuelType
                         * @property {number|Long|null} [mileage] StoreVehicleRefillRequest mileage
                         * @property {number|null} [amount] StoreVehicleRefillRequest amount
                         * @property {number|null} [liters] StoreVehicleRefillRequest liters
                         */

                        /**
                         * Constructs a new StoreVehicleRefillRequest.
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto
                         * @classdesc Represents a StoreVehicleRefillRequest.
                         * @implements IStoreVehicleRefillRequest
                         * @constructor
                         * @param {pro.megakit.amelia.driver.VehicleRefillProto.IStoreVehicleRefillRequest=} [properties] Properties to set
                         */
                        function StoreVehicleRefillRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StoreVehicleRefillRequest requestId.
                         * @member {string} requestId
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest
                         * @instance
                         */
                        StoreVehicleRefillRequest.prototype.requestId = "";

                        /**
                         * StoreVehicleRefillRequest sessionId.
                         * @member {string} sessionId
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest
                         * @instance
                         */
                        StoreVehicleRefillRequest.prototype.sessionId = "";

                        /**
                         * StoreVehicleRefillRequest fuelType.
                         * @member {pro.megakit.amelia.driver.VehicleRefillProto.VehicleFuelType} fuelType
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest
                         * @instance
                         */
                        StoreVehicleRefillRequest.prototype.fuelType = 0;

                        /**
                         * StoreVehicleRefillRequest mileage.
                         * @member {number|Long} mileage
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest
                         * @instance
                         */
                        StoreVehicleRefillRequest.prototype.mileage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * StoreVehicleRefillRequest amount.
                         * @member {number} amount
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest
                         * @instance
                         */
                        StoreVehicleRefillRequest.prototype.amount = 0;

                        /**
                         * StoreVehicleRefillRequest liters.
                         * @member {number} liters
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest
                         * @instance
                         */
                        StoreVehicleRefillRequest.prototype.liters = 0;

                        /**
                         * Creates a new StoreVehicleRefillRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleRefillProto.IStoreVehicleRefillRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest} StoreVehicleRefillRequest instance
                         */
                        StoreVehicleRefillRequest.create = function create(properties) {
                            return new StoreVehicleRefillRequest(properties);
                        };

                        /**
                         * Encodes the specified StoreVehicleRefillRequest message. Does not implicitly {@link pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleRefillProto.IStoreVehicleRefillRequest} message StoreVehicleRefillRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StoreVehicleRefillRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.requestId);
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.sessionId);
                            if (message.fuelType != null && message.hasOwnProperty("fuelType"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.fuelType);
                            if (message.mileage != null && message.hasOwnProperty("mileage"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.mileage);
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                writer.uint32(/* id 5, wireType 1 =*/41).double(message.amount);
                            if (message.liters != null && message.hasOwnProperty("liters"))
                                writer.uint32(/* id 6, wireType 1 =*/49).double(message.liters);
                            return writer;
                        };

                        /**
                         * Encodes the specified StoreVehicleRefillRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleRefillProto.IStoreVehicleRefillRequest} message StoreVehicleRefillRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StoreVehicleRefillRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StoreVehicleRefillRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest} StoreVehicleRefillRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StoreVehicleRefillRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.requestId = reader.string();
                                    break;
                                case 2:
                                    message.sessionId = reader.string();
                                    break;
                                case 3:
                                    message.fuelType = reader.int32();
                                    break;
                                case 4:
                                    message.mileage = reader.int64();
                                    break;
                                case 5:
                                    message.amount = reader.double();
                                    break;
                                case 6:
                                    message.liters = reader.double();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StoreVehicleRefillRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest} StoreVehicleRefillRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StoreVehicleRefillRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StoreVehicleRefillRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StoreVehicleRefillRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                if (!$util.isString(message.requestId))
                                    return "requestId: string expected";
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                if (!$util.isString(message.sessionId))
                                    return "sessionId: string expected";
                            if (message.fuelType != null && message.hasOwnProperty("fuelType"))
                                switch (message.fuelType) {
                                default:
                                    return "fuelType: enum value expected";
                                case 0:
                                case 1:
                                    break;
                                }
                            if (message.mileage != null && message.hasOwnProperty("mileage"))
                                if (!$util.isInteger(message.mileage) && !(message.mileage && $util.isInteger(message.mileage.low) && $util.isInteger(message.mileage.high)))
                                    return "mileage: integer|Long expected";
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                if (typeof message.amount !== "number")
                                    return "amount: number expected";
                            if (message.liters != null && message.hasOwnProperty("liters"))
                                if (typeof message.liters !== "number")
                                    return "liters: number expected";
                            return null;
                        };

                        /**
                         * Creates a StoreVehicleRefillRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest} StoreVehicleRefillRequest
                         */
                        StoreVehicleRefillRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest();
                            if (object.requestId != null)
                                message.requestId = String(object.requestId);
                            if (object.sessionId != null)
                                message.sessionId = String(object.sessionId);
                            switch (object.fuelType) {
                            case "PETROL":
                            case 0:
                                message.fuelType = 0;
                                break;
                            case "GAS":
                            case 1:
                                message.fuelType = 1;
                                break;
                            }
                            if (object.mileage != null)
                                if ($util.Long)
                                    (message.mileage = $util.Long.fromValue(object.mileage)).unsigned = false;
                                else if (typeof object.mileage === "string")
                                    message.mileage = parseInt(object.mileage, 10);
                                else if (typeof object.mileage === "number")
                                    message.mileage = object.mileage;
                                else if (typeof object.mileage === "object")
                                    message.mileage = new $util.LongBits(object.mileage.low >>> 0, object.mileage.high >>> 0).toNumber();
                            if (object.amount != null)
                                message.amount = Number(object.amount);
                            if (object.liters != null)
                                message.liters = Number(object.liters);
                            return message;
                        };

                        /**
                         * Creates a plain object from a StoreVehicleRefillRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest} message StoreVehicleRefillRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StoreVehicleRefillRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.requestId = "";
                                object.sessionId = "";
                                object.fuelType = options.enums === String ? "PETROL" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.mileage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.mileage = options.longs === String ? "0" : 0;
                                object.amount = 0;
                                object.liters = 0;
                            }
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                object.requestId = message.requestId;
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                object.sessionId = message.sessionId;
                            if (message.fuelType != null && message.hasOwnProperty("fuelType"))
                                object.fuelType = options.enums === String ? $root.pro.megakit.amelia.driver.VehicleRefillProto.VehicleFuelType[message.fuelType] : message.fuelType;
                            if (message.mileage != null && message.hasOwnProperty("mileage"))
                                if (typeof message.mileage === "number")
                                    object.mileage = options.longs === String ? String(message.mileage) : message.mileage;
                                else
                                    object.mileage = options.longs === String ? $util.Long.prototype.toString.call(message.mileage) : options.longs === Number ? new $util.LongBits(message.mileage.low >>> 0, message.mileage.high >>> 0).toNumber() : message.mileage;
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
                            if (message.liters != null && message.hasOwnProperty("liters"))
                                object.liters = options.json && !isFinite(message.liters) ? String(message.liters) : message.liters;
                            return object;
                        };

                        /**
                         * Converts this StoreVehicleRefillRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StoreVehicleRefillRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StoreVehicleRefillRequest;
                    })();

                    VehicleRefillProto.StoreVehicleRefillResponse = (function() {

                        /**
                         * Properties of a StoreVehicleRefillResponse.
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto
                         * @interface IStoreVehicleRefillResponse
                         * @property {string|null} [id] StoreVehicleRefillResponse id
                         */

                        /**
                         * Constructs a new StoreVehicleRefillResponse.
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto
                         * @classdesc Represents a StoreVehicleRefillResponse.
                         * @implements IStoreVehicleRefillResponse
                         * @constructor
                         * @param {pro.megakit.amelia.driver.VehicleRefillProto.IStoreVehicleRefillResponse=} [properties] Properties to set
                         */
                        function StoreVehicleRefillResponse(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StoreVehicleRefillResponse id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse
                         * @instance
                         */
                        StoreVehicleRefillResponse.prototype.id = "";

                        /**
                         * Creates a new StoreVehicleRefillResponse instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleRefillProto.IStoreVehicleRefillResponse=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse} StoreVehicleRefillResponse instance
                         */
                        StoreVehicleRefillResponse.create = function create(properties) {
                            return new StoreVehicleRefillResponse(properties);
                        };

                        /**
                         * Encodes the specified StoreVehicleRefillResponse message. Does not implicitly {@link pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleRefillProto.IStoreVehicleRefillResponse} message StoreVehicleRefillResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StoreVehicleRefillResponse.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified StoreVehicleRefillResponse message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleRefillProto.IStoreVehicleRefillResponse} message StoreVehicleRefillResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StoreVehicleRefillResponse.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StoreVehicleRefillResponse message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse} StoreVehicleRefillResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StoreVehicleRefillResponse.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StoreVehicleRefillResponse message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse} StoreVehicleRefillResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StoreVehicleRefillResponse.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StoreVehicleRefillResponse message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StoreVehicleRefillResponse.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a StoreVehicleRefillResponse message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse} StoreVehicleRefillResponse
                         */
                        StoreVehicleRefillResponse.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a StoreVehicleRefillResponse message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse} message StoreVehicleRefillResponse
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StoreVehicleRefillResponse.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this StoreVehicleRefillResponse to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.VehicleRefillProto.StoreVehicleRefillResponse
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StoreVehicleRefillResponse.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StoreVehicleRefillResponse;
                    })();

                    return VehicleRefillProto;
                })();

                driver.VehicleWashingProto = (function() {

                    /**
                     * Properties of a VehicleWashingProto.
                     * @memberof pro.megakit.amelia.driver
                     * @interface IVehicleWashingProto
                     */

                    /**
                     * Constructs a new VehicleWashingProto.
                     * @memberof pro.megakit.amelia.driver
                     * @classdesc Represents a VehicleWashingProto.
                     * @implements IVehicleWashingProto
                     * @constructor
                     * @param {pro.megakit.amelia.driver.IVehicleWashingProto=} [properties] Properties to set
                     */
                    function VehicleWashingProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new VehicleWashingProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IVehicleWashingProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.driver.VehicleWashingProto} VehicleWashingProto instance
                     */
                    VehicleWashingProto.create = function create(properties) {
                        return new VehicleWashingProto(properties);
                    };

                    /**
                     * Encodes the specified VehicleWashingProto message. Does not implicitly {@link pro.megakit.amelia.driver.VehicleWashingProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IVehicleWashingProto} message VehicleWashingProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VehicleWashingProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified VehicleWashingProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.VehicleWashingProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IVehicleWashingProto} message VehicleWashingProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VehicleWashingProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a VehicleWashingProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.driver.VehicleWashingProto} VehicleWashingProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VehicleWashingProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.VehicleWashingProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a VehicleWashingProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.driver.VehicleWashingProto} VehicleWashingProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VehicleWashingProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a VehicleWashingProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    VehicleWashingProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a VehicleWashingProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.driver.VehicleWashingProto} VehicleWashingProto
                     */
                    VehicleWashingProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.driver.VehicleWashingProto)
                            return object;
                        return new $root.pro.megakit.amelia.driver.VehicleWashingProto();
                    };

                    /**
                     * Creates a plain object from a VehicleWashingProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                     * @static
                     * @param {pro.megakit.amelia.driver.VehicleWashingProto} message VehicleWashingProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    VehicleWashingProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this VehicleWashingProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    VehicleWashingProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    VehicleWashingProto.CreateVehicleWashingPayload = (function() {

                        /**
                         * Properties of a CreateVehicleWashingPayload.
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                         * @interface ICreateVehicleWashingPayload
                         * @property {string|null} [addressName] CreateVehicleWashingPayload addressName
                         * @property {boolean|null} [isApplyNow] CreateVehicleWashingPayload isApplyNow
                         * @property {google.protobuf.ITimestamp|null} [assignedAt] CreateVehicleWashingPayload assignedAt
                         */

                        /**
                         * Constructs a new CreateVehicleWashingPayload.
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                         * @classdesc Represents a CreateVehicleWashingPayload.
                         * @implements ICreateVehicleWashingPayload
                         * @constructor
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.ICreateVehicleWashingPayload=} [properties] Properties to set
                         */
                        function CreateVehicleWashingPayload(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CreateVehicleWashingPayload addressName.
                         * @member {string} addressName
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload
                         * @instance
                         */
                        CreateVehicleWashingPayload.prototype.addressName = "";

                        /**
                         * CreateVehicleWashingPayload isApplyNow.
                         * @member {boolean} isApplyNow
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload
                         * @instance
                         */
                        CreateVehicleWashingPayload.prototype.isApplyNow = false;

                        /**
                         * CreateVehicleWashingPayload assignedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} assignedAt
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload
                         * @instance
                         */
                        CreateVehicleWashingPayload.prototype.assignedAt = null;

                        /**
                         * Creates a new CreateVehicleWashingPayload instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.ICreateVehicleWashingPayload=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload} CreateVehicleWashingPayload instance
                         */
                        CreateVehicleWashingPayload.create = function create(properties) {
                            return new CreateVehicleWashingPayload(properties);
                        };

                        /**
                         * Encodes the specified CreateVehicleWashingPayload message. Does not implicitly {@link pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.ICreateVehicleWashingPayload} message CreateVehicleWashingPayload message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CreateVehicleWashingPayload.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.addressName);
                            if (message.isApplyNow != null && message.hasOwnProperty("isApplyNow"))
                                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isApplyNow);
                            if (message.assignedAt != null && message.hasOwnProperty("assignedAt"))
                                $root.google.protobuf.Timestamp.encode(message.assignedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified CreateVehicleWashingPayload message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.ICreateVehicleWashingPayload} message CreateVehicleWashingPayload message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CreateVehicleWashingPayload.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a CreateVehicleWashingPayload message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload} CreateVehicleWashingPayload
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CreateVehicleWashingPayload.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.addressName = reader.string();
                                    break;
                                case 2:
                                    message.isApplyNow = reader.bool();
                                    break;
                                case 3:
                                    message.assignedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a CreateVehicleWashingPayload message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload} CreateVehicleWashingPayload
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CreateVehicleWashingPayload.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a CreateVehicleWashingPayload message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CreateVehicleWashingPayload.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                if (!$util.isString(message.addressName))
                                    return "addressName: string expected";
                            if (message.isApplyNow != null && message.hasOwnProperty("isApplyNow"))
                                if (typeof message.isApplyNow !== "boolean")
                                    return "isApplyNow: boolean expected";
                            if (message.assignedAt != null && message.hasOwnProperty("assignedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.assignedAt);
                                if (error)
                                    return "assignedAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a CreateVehicleWashingPayload message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload} CreateVehicleWashingPayload
                         */
                        CreateVehicleWashingPayload.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload();
                            if (object.addressName != null)
                                message.addressName = String(object.addressName);
                            if (object.isApplyNow != null)
                                message.isApplyNow = Boolean(object.isApplyNow);
                            if (object.assignedAt != null) {
                                if (typeof object.assignedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload.assignedAt: object expected");
                                message.assignedAt = $root.google.protobuf.Timestamp.fromObject(object.assignedAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a CreateVehicleWashingPayload message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload} message CreateVehicleWashingPayload
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CreateVehicleWashingPayload.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.addressName = "";
                                object.isApplyNow = false;
                                object.assignedAt = null;
                            }
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                object.addressName = message.addressName;
                            if (message.isApplyNow != null && message.hasOwnProperty("isApplyNow"))
                                object.isApplyNow = message.isApplyNow;
                            if (message.assignedAt != null && message.hasOwnProperty("assignedAt"))
                                object.assignedAt = $root.google.protobuf.Timestamp.toObject(message.assignedAt, options);
                            return object;
                        };

                        /**
                         * Converts this CreateVehicleWashingPayload to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CreateVehicleWashingPayload
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CreateVehicleWashingPayload.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return CreateVehicleWashingPayload;
                    })();

                    VehicleWashingProto.StartVehicleWashingRequest = (function() {

                        /**
                         * Properties of a StartVehicleWashingRequest.
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                         * @interface IStartVehicleWashingRequest
                         * @property {string|null} [requestId] StartVehicleWashingRequest requestId
                         */

                        /**
                         * Constructs a new StartVehicleWashingRequest.
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                         * @classdesc Represents a StartVehicleWashingRequest.
                         * @implements IStartVehicleWashingRequest
                         * @constructor
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.IStartVehicleWashingRequest=} [properties] Properties to set
                         */
                        function StartVehicleWashingRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StartVehicleWashingRequest requestId.
                         * @member {string} requestId
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest
                         * @instance
                         */
                        StartVehicleWashingRequest.prototype.requestId = "";

                        /**
                         * Creates a new StartVehicleWashingRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.IStartVehicleWashingRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest} StartVehicleWashingRequest instance
                         */
                        StartVehicleWashingRequest.create = function create(properties) {
                            return new StartVehicleWashingRequest(properties);
                        };

                        /**
                         * Encodes the specified StartVehicleWashingRequest message. Does not implicitly {@link pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.IStartVehicleWashingRequest} message StartVehicleWashingRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartVehicleWashingRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.requestId);
                            return writer;
                        };

                        /**
                         * Encodes the specified StartVehicleWashingRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.IStartVehicleWashingRequest} message StartVehicleWashingRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartVehicleWashingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StartVehicleWashingRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest} StartVehicleWashingRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartVehicleWashingRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.requestId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StartVehicleWashingRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest} StartVehicleWashingRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartVehicleWashingRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StartVehicleWashingRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StartVehicleWashingRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                if (!$util.isString(message.requestId))
                                    return "requestId: string expected";
                            return null;
                        };

                        /**
                         * Creates a StartVehicleWashingRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest} StartVehicleWashingRequest
                         */
                        StartVehicleWashingRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest();
                            if (object.requestId != null)
                                message.requestId = String(object.requestId);
                            return message;
                        };

                        /**
                         * Creates a plain object from a StartVehicleWashingRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest} message StartVehicleWashingRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StartVehicleWashingRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.requestId = "";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                object.requestId = message.requestId;
                            return object;
                        };

                        /**
                         * Converts this StartVehicleWashingRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StartVehicleWashingRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StartVehicleWashingRequest;
                    })();

                    VehicleWashingProto.StartVehicleWashingResponse = (function() {

                        /**
                         * Properties of a StartVehicleWashingResponse.
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                         * @interface IStartVehicleWashingResponse
                         * @property {string|null} [id] StartVehicleWashingResponse id
                         */

                        /**
                         * Constructs a new StartVehicleWashingResponse.
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                         * @classdesc Represents a StartVehicleWashingResponse.
                         * @implements IStartVehicleWashingResponse
                         * @constructor
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.IStartVehicleWashingResponse=} [properties] Properties to set
                         */
                        function StartVehicleWashingResponse(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StartVehicleWashingResponse id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse
                         * @instance
                         */
                        StartVehicleWashingResponse.prototype.id = "";

                        /**
                         * Creates a new StartVehicleWashingResponse instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.IStartVehicleWashingResponse=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse} StartVehicleWashingResponse instance
                         */
                        StartVehicleWashingResponse.create = function create(properties) {
                            return new StartVehicleWashingResponse(properties);
                        };

                        /**
                         * Encodes the specified StartVehicleWashingResponse message. Does not implicitly {@link pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.IStartVehicleWashingResponse} message StartVehicleWashingResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartVehicleWashingResponse.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified StartVehicleWashingResponse message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.IStartVehicleWashingResponse} message StartVehicleWashingResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartVehicleWashingResponse.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StartVehicleWashingResponse message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse} StartVehicleWashingResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartVehicleWashingResponse.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StartVehicleWashingResponse message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse} StartVehicleWashingResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartVehicleWashingResponse.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StartVehicleWashingResponse message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StartVehicleWashingResponse.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a StartVehicleWashingResponse message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse} StartVehicleWashingResponse
                         */
                        StartVehicleWashingResponse.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a StartVehicleWashingResponse message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse} message StartVehicleWashingResponse
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StartVehicleWashingResponse.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this StartVehicleWashingResponse to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.StartVehicleWashingResponse
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StartVehicleWashingResponse.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StartVehicleWashingResponse;
                    })();

                    VehicleWashingProto.CancelVehicleWashingRequest = (function() {

                        /**
                         * Properties of a CancelVehicleWashingRequest.
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                         * @interface ICancelVehicleWashingRequest
                         * @property {string|null} [id] CancelVehicleWashingRequest id
                         */

                        /**
                         * Constructs a new CancelVehicleWashingRequest.
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                         * @classdesc Represents a CancelVehicleWashingRequest.
                         * @implements ICancelVehicleWashingRequest
                         * @constructor
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.ICancelVehicleWashingRequest=} [properties] Properties to set
                         */
                        function CancelVehicleWashingRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CancelVehicleWashingRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest
                         * @instance
                         */
                        CancelVehicleWashingRequest.prototype.id = "";

                        /**
                         * Creates a new CancelVehicleWashingRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.ICancelVehicleWashingRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest} CancelVehicleWashingRequest instance
                         */
                        CancelVehicleWashingRequest.create = function create(properties) {
                            return new CancelVehicleWashingRequest(properties);
                        };

                        /**
                         * Encodes the specified CancelVehicleWashingRequest message. Does not implicitly {@link pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.ICancelVehicleWashingRequest} message CancelVehicleWashingRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CancelVehicleWashingRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified CancelVehicleWashingRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.ICancelVehicleWashingRequest} message CancelVehicleWashingRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CancelVehicleWashingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a CancelVehicleWashingRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest} CancelVehicleWashingRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CancelVehicleWashingRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a CancelVehicleWashingRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest} CancelVehicleWashingRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CancelVehicleWashingRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a CancelVehicleWashingRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CancelVehicleWashingRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a CancelVehicleWashingRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest} CancelVehicleWashingRequest
                         */
                        CancelVehicleWashingRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a CancelVehicleWashingRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest} message CancelVehicleWashingRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CancelVehicleWashingRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this CancelVehicleWashingRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.CancelVehicleWashingRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CancelVehicleWashingRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return CancelVehicleWashingRequest;
                    })();

                    VehicleWashingProto.FinishVehicleWashingRequest = (function() {

                        /**
                         * Properties of a FinishVehicleWashingRequest.
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                         * @interface IFinishVehicleWashingRequest
                         * @property {string|null} [id] FinishVehicleWashingRequest id
                         * @property {string|null} [sessionId] FinishVehicleWashingRequest sessionId
                         * @property {number|null} [amount] FinishVehicleWashingRequest amount
                         */

                        /**
                         * Constructs a new FinishVehicleWashingRequest.
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                         * @classdesc Represents a FinishVehicleWashingRequest.
                         * @implements IFinishVehicleWashingRequest
                         * @constructor
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.IFinishVehicleWashingRequest=} [properties] Properties to set
                         */
                        function FinishVehicleWashingRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FinishVehicleWashingRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest
                         * @instance
                         */
                        FinishVehicleWashingRequest.prototype.id = "";

                        /**
                         * FinishVehicleWashingRequest sessionId.
                         * @member {string} sessionId
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest
                         * @instance
                         */
                        FinishVehicleWashingRequest.prototype.sessionId = "";

                        /**
                         * FinishVehicleWashingRequest amount.
                         * @member {number} amount
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest
                         * @instance
                         */
                        FinishVehicleWashingRequest.prototype.amount = 0;

                        /**
                         * Creates a new FinishVehicleWashingRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.IFinishVehicleWashingRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest} FinishVehicleWashingRequest instance
                         */
                        FinishVehicleWashingRequest.create = function create(properties) {
                            return new FinishVehicleWashingRequest(properties);
                        };

                        /**
                         * Encodes the specified FinishVehicleWashingRequest message. Does not implicitly {@link pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.IFinishVehicleWashingRequest} message FinishVehicleWashingRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishVehicleWashingRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.sessionId);
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                writer.uint32(/* id 3, wireType 1 =*/25).double(message.amount);
                            return writer;
                        };

                        /**
                         * Encodes the specified FinishVehicleWashingRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.IFinishVehicleWashingRequest} message FinishVehicleWashingRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishVehicleWashingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FinishVehicleWashingRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest} FinishVehicleWashingRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishVehicleWashingRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.sessionId = reader.string();
                                    break;
                                case 3:
                                    message.amount = reader.double();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FinishVehicleWashingRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest} FinishVehicleWashingRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishVehicleWashingRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FinishVehicleWashingRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FinishVehicleWashingRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                if (!$util.isString(message.sessionId))
                                    return "sessionId: string expected";
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                if (typeof message.amount !== "number")
                                    return "amount: number expected";
                            return null;
                        };

                        /**
                         * Creates a FinishVehicleWashingRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest} FinishVehicleWashingRequest
                         */
                        FinishVehicleWashingRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.sessionId != null)
                                message.sessionId = String(object.sessionId);
                            if (object.amount != null)
                                message.amount = Number(object.amount);
                            return message;
                        };

                        /**
                         * Creates a plain object from a FinishVehicleWashingRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest} message FinishVehicleWashingRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FinishVehicleWashingRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.sessionId = "";
                                object.amount = 0;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                object.sessionId = message.sessionId;
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
                            return object;
                        };

                        /**
                         * Converts this FinishVehicleWashingRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.FinishVehicleWashingRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FinishVehicleWashingRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FinishVehicleWashingRequest;
                    })();

                    VehicleWashingProto.ActiveVehicleWashing = (function() {

                        /**
                         * Properties of an ActiveVehicleWashing.
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                         * @interface IActiveVehicleWashing
                         * @property {string|null} [id] ActiveVehicleWashing id
                         * @property {string|null} [addressName] ActiveVehicleWashing addressName
                         * @property {google.protobuf.ITimestamp|null} [startedAt] ActiveVehicleWashing startedAt
                         */

                        /**
                         * Constructs a new ActiveVehicleWashing.
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto
                         * @classdesc Represents an ActiveVehicleWashing.
                         * @implements IActiveVehicleWashing
                         * @constructor
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.IActiveVehicleWashing=} [properties] Properties to set
                         */
                        function ActiveVehicleWashing(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ActiveVehicleWashing id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing
                         * @instance
                         */
                        ActiveVehicleWashing.prototype.id = "";

                        /**
                         * ActiveVehicleWashing addressName.
                         * @member {string} addressName
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing
                         * @instance
                         */
                        ActiveVehicleWashing.prototype.addressName = "";

                        /**
                         * ActiveVehicleWashing startedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} startedAt
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing
                         * @instance
                         */
                        ActiveVehicleWashing.prototype.startedAt = null;

                        /**
                         * Creates a new ActiveVehicleWashing instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.IActiveVehicleWashing=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing} ActiveVehicleWashing instance
                         */
                        ActiveVehicleWashing.create = function create(properties) {
                            return new ActiveVehicleWashing(properties);
                        };

                        /**
                         * Encodes the specified ActiveVehicleWashing message. Does not implicitly {@link pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.IActiveVehicleWashing} message ActiveVehicleWashing message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveVehicleWashing.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.addressName);
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                $root.google.protobuf.Timestamp.encode(message.startedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ActiveVehicleWashing message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.IActiveVehicleWashing} message ActiveVehicleWashing message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveVehicleWashing.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an ActiveVehicleWashing message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing} ActiveVehicleWashing
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveVehicleWashing.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.addressName = reader.string();
                                    break;
                                case 3:
                                    message.startedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an ActiveVehicleWashing message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing} ActiveVehicleWashing
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveVehicleWashing.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an ActiveVehicleWashing message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ActiveVehicleWashing.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                if (!$util.isString(message.addressName))
                                    return "addressName: string expected";
                            if (message.startedAt != null && message.hasOwnProperty("startedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.startedAt);
                                if (error)
                                    return "startedAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates an ActiveVehicleWashing message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing} ActiveVehicleWashing
                         */
                        ActiveVehicleWashing.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.addressName != null)
                                message.addressName = String(object.addressName);
                            if (object.startedAt != null) {
                                if (typeof object.startedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing.startedAt: object expected");
                                message.startedAt = $root.google.protobuf.Timestamp.fromObject(object.startedAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an ActiveVehicleWashing message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing
                         * @static
                         * @param {pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing} message ActiveVehicleWashing
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ActiveVehicleWashing.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.addressName = "";
                                object.startedAt = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                object.addressName = message.addressName;
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                object.startedAt = $root.google.protobuf.Timestamp.toObject(message.startedAt, options);
                            return object;
                        };

                        /**
                         * Converts this ActiveVehicleWashing to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.VehicleWashingProto.ActiveVehicleWashing
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ActiveVehicleWashing.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ActiveVehicleWashing;
                    })();

                    return VehicleWashingProto;
                })();

                driver.DriverExpenseProto = (function() {

                    /**
                     * Properties of a DriverExpenseProto.
                     * @memberof pro.megakit.amelia.driver
                     * @interface IDriverExpenseProto
                     */

                    /**
                     * Constructs a new DriverExpenseProto.
                     * @memberof pro.megakit.amelia.driver
                     * @classdesc Represents a DriverExpenseProto.
                     * @implements IDriverExpenseProto
                     * @constructor
                     * @param {pro.megakit.amelia.driver.IDriverExpenseProto=} [properties] Properties to set
                     */
                    function DriverExpenseProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new DriverExpenseProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.driver.DriverExpenseProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IDriverExpenseProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.driver.DriverExpenseProto} DriverExpenseProto instance
                     */
                    DriverExpenseProto.create = function create(properties) {
                        return new DriverExpenseProto(properties);
                    };

                    /**
                     * Encodes the specified DriverExpenseProto message. Does not implicitly {@link pro.megakit.amelia.driver.DriverExpenseProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.driver.DriverExpenseProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IDriverExpenseProto} message DriverExpenseProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverExpenseProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified DriverExpenseProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverExpenseProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.driver.DriverExpenseProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IDriverExpenseProto} message DriverExpenseProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverExpenseProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DriverExpenseProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.driver.DriverExpenseProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.driver.DriverExpenseProto} DriverExpenseProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverExpenseProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverExpenseProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DriverExpenseProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.driver.DriverExpenseProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.driver.DriverExpenseProto} DriverExpenseProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverExpenseProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DriverExpenseProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.driver.DriverExpenseProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DriverExpenseProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a DriverExpenseProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.driver.DriverExpenseProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.driver.DriverExpenseProto} DriverExpenseProto
                     */
                    DriverExpenseProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.driver.DriverExpenseProto)
                            return object;
                        return new $root.pro.megakit.amelia.driver.DriverExpenseProto();
                    };

                    /**
                     * Creates a plain object from a DriverExpenseProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.driver.DriverExpenseProto
                     * @static
                     * @param {pro.megakit.amelia.driver.DriverExpenseProto} message DriverExpenseProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DriverExpenseProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this DriverExpenseProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.driver.DriverExpenseProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DriverExpenseProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    DriverExpenseProto.DriverExpenseType = (function() {

                        /**
                         * Properties of a DriverExpenseType.
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto
                         * @interface IDriverExpenseType
                         * @property {string|null} [id] DriverExpenseType id
                         * @property {string|null} [displayName] DriverExpenseType displayName
                         */

                        /**
                         * Constructs a new DriverExpenseType.
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto
                         * @classdesc Represents a DriverExpenseType.
                         * @implements IDriverExpenseType
                         * @constructor
                         * @param {pro.megakit.amelia.driver.DriverExpenseProto.IDriverExpenseType=} [properties] Properties to set
                         */
                        function DriverExpenseType(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DriverExpenseType id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType
                         * @instance
                         */
                        DriverExpenseType.prototype.id = "";

                        /**
                         * DriverExpenseType displayName.
                         * @member {string} displayName
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType
                         * @instance
                         */
                        DriverExpenseType.prototype.displayName = "";

                        /**
                         * Creates a new DriverExpenseType instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverExpenseProto.IDriverExpenseType=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType} DriverExpenseType instance
                         */
                        DriverExpenseType.create = function create(properties) {
                            return new DriverExpenseType(properties);
                        };

                        /**
                         * Encodes the specified DriverExpenseType message. Does not implicitly {@link pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverExpenseProto.IDriverExpenseType} message DriverExpenseType message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverExpenseType.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.displayName != null && message.hasOwnProperty("displayName"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.displayName);
                            return writer;
                        };

                        /**
                         * Encodes the specified DriverExpenseType message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverExpenseProto.IDriverExpenseType} message DriverExpenseType message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverExpenseType.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DriverExpenseType message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType} DriverExpenseType
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverExpenseType.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.displayName = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DriverExpenseType message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType} DriverExpenseType
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverExpenseType.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DriverExpenseType message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DriverExpenseType.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.displayName != null && message.hasOwnProperty("displayName"))
                                if (!$util.isString(message.displayName))
                                    return "displayName: string expected";
                            return null;
                        };

                        /**
                         * Creates a DriverExpenseType message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType} DriverExpenseType
                         */
                        DriverExpenseType.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.displayName != null)
                                message.displayName = String(object.displayName);
                            return message;
                        };

                        /**
                         * Creates a plain object from a DriverExpenseType message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType} message DriverExpenseType
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DriverExpenseType.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.displayName = "";
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.displayName != null && message.hasOwnProperty("displayName"))
                                object.displayName = message.displayName;
                            return object;
                        };

                        /**
                         * Converts this DriverExpenseType to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DriverExpenseType.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DriverExpenseType;
                    })();

                    DriverExpenseProto.GetDriverExpenseTypesResponse = (function() {

                        /**
                         * Properties of a GetDriverExpenseTypesResponse.
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto
                         * @interface IGetDriverExpenseTypesResponse
                         * @property {Array.<pro.megakit.amelia.driver.DriverExpenseProto.IDriverExpenseType>|null} [types] GetDriverExpenseTypesResponse types
                         */

                        /**
                         * Constructs a new GetDriverExpenseTypesResponse.
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto
                         * @classdesc Represents a GetDriverExpenseTypesResponse.
                         * @implements IGetDriverExpenseTypesResponse
                         * @constructor
                         * @param {pro.megakit.amelia.driver.DriverExpenseProto.IGetDriverExpenseTypesResponse=} [properties] Properties to set
                         */
                        function GetDriverExpenseTypesResponse(properties) {
                            this.types = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * GetDriverExpenseTypesResponse types.
                         * @member {Array.<pro.megakit.amelia.driver.DriverExpenseProto.IDriverExpenseType>} types
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse
                         * @instance
                         */
                        GetDriverExpenseTypesResponse.prototype.types = $util.emptyArray;

                        /**
                         * Creates a new GetDriverExpenseTypesResponse instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverExpenseProto.IGetDriverExpenseTypesResponse=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse} GetDriverExpenseTypesResponse instance
                         */
                        GetDriverExpenseTypesResponse.create = function create(properties) {
                            return new GetDriverExpenseTypesResponse(properties);
                        };

                        /**
                         * Encodes the specified GetDriverExpenseTypesResponse message. Does not implicitly {@link pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverExpenseProto.IGetDriverExpenseTypesResponse} message GetDriverExpenseTypesResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GetDriverExpenseTypesResponse.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.types != null && message.types.length)
                                for (var i = 0; i < message.types.length; ++i)
                                    $root.pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType.encode(message.types[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified GetDriverExpenseTypesResponse message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverExpenseProto.IGetDriverExpenseTypesResponse} message GetDriverExpenseTypesResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GetDriverExpenseTypesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a GetDriverExpenseTypesResponse message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse} GetDriverExpenseTypesResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GetDriverExpenseTypesResponse.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.types && message.types.length))
                                        message.types = [];
                                    message.types.push($root.pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a GetDriverExpenseTypesResponse message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse} GetDriverExpenseTypesResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GetDriverExpenseTypesResponse.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a GetDriverExpenseTypesResponse message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        GetDriverExpenseTypesResponse.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.types != null && message.hasOwnProperty("types")) {
                                if (!Array.isArray(message.types))
                                    return "types: array expected";
                                for (var i = 0; i < message.types.length; ++i) {
                                    var error = $root.pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType.verify(message.types[i]);
                                    if (error)
                                        return "types." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a GetDriverExpenseTypesResponse message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse} GetDriverExpenseTypesResponse
                         */
                        GetDriverExpenseTypesResponse.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse();
                            if (object.types) {
                                if (!Array.isArray(object.types))
                                    throw TypeError(".pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse.types: array expected");
                                message.types = [];
                                for (var i = 0; i < object.types.length; ++i) {
                                    if (typeof object.types[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse.types: object expected");
                                    message.types[i] = $root.pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType.fromObject(object.types[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a GetDriverExpenseTypesResponse message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse} message GetDriverExpenseTypesResponse
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        GetDriverExpenseTypesResponse.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.types = [];
                            if (message.types && message.types.length) {
                                object.types = [];
                                for (var j = 0; j < message.types.length; ++j)
                                    object.types[j] = $root.pro.megakit.amelia.driver.DriverExpenseProto.DriverExpenseType.toObject(message.types[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this GetDriverExpenseTypesResponse to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.GetDriverExpenseTypesResponse
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        GetDriverExpenseTypesResponse.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return GetDriverExpenseTypesResponse;
                    })();

                    DriverExpenseProto.StoreDriverExpenseRequest = (function() {

                        /**
                         * Properties of a StoreDriverExpenseRequest.
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto
                         * @interface IStoreDriverExpenseRequest
                         * @property {string|null} [typeId] StoreDriverExpenseRequest typeId
                         * @property {string|null} [sessionId] StoreDriverExpenseRequest sessionId
                         * @property {number|null} [amount] StoreDriverExpenseRequest amount
                         * @property {string|null} [comment] StoreDriverExpenseRequest comment
                         */

                        /**
                         * Constructs a new StoreDriverExpenseRequest.
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto
                         * @classdesc Represents a StoreDriverExpenseRequest.
                         * @implements IStoreDriverExpenseRequest
                         * @constructor
                         * @param {pro.megakit.amelia.driver.DriverExpenseProto.IStoreDriverExpenseRequest=} [properties] Properties to set
                         */
                        function StoreDriverExpenseRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StoreDriverExpenseRequest typeId.
                         * @member {string} typeId
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest
                         * @instance
                         */
                        StoreDriverExpenseRequest.prototype.typeId = "";

                        /**
                         * StoreDriverExpenseRequest sessionId.
                         * @member {string} sessionId
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest
                         * @instance
                         */
                        StoreDriverExpenseRequest.prototype.sessionId = "";

                        /**
                         * StoreDriverExpenseRequest amount.
                         * @member {number} amount
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest
                         * @instance
                         */
                        StoreDriverExpenseRequest.prototype.amount = 0;

                        /**
                         * StoreDriverExpenseRequest comment.
                         * @member {string} comment
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest
                         * @instance
                         */
                        StoreDriverExpenseRequest.prototype.comment = "";

                        /**
                         * Creates a new StoreDriverExpenseRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverExpenseProto.IStoreDriverExpenseRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest} StoreDriverExpenseRequest instance
                         */
                        StoreDriverExpenseRequest.create = function create(properties) {
                            return new StoreDriverExpenseRequest(properties);
                        };

                        /**
                         * Encodes the specified StoreDriverExpenseRequest message. Does not implicitly {@link pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverExpenseProto.IStoreDriverExpenseRequest} message StoreDriverExpenseRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StoreDriverExpenseRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.typeId != null && message.hasOwnProperty("typeId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.typeId);
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.sessionId);
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                writer.uint32(/* id 3, wireType 1 =*/25).double(message.amount);
                            if (message.comment != null && message.hasOwnProperty("comment"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.comment);
                            return writer;
                        };

                        /**
                         * Encodes the specified StoreDriverExpenseRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverExpenseProto.IStoreDriverExpenseRequest} message StoreDriverExpenseRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StoreDriverExpenseRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StoreDriverExpenseRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest} StoreDriverExpenseRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StoreDriverExpenseRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.typeId = reader.string();
                                    break;
                                case 2:
                                    message.sessionId = reader.string();
                                    break;
                                case 3:
                                    message.amount = reader.double();
                                    break;
                                case 4:
                                    message.comment = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StoreDriverExpenseRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest} StoreDriverExpenseRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StoreDriverExpenseRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StoreDriverExpenseRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StoreDriverExpenseRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.typeId != null && message.hasOwnProperty("typeId"))
                                if (!$util.isString(message.typeId))
                                    return "typeId: string expected";
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                if (!$util.isString(message.sessionId))
                                    return "sessionId: string expected";
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                if (typeof message.amount !== "number")
                                    return "amount: number expected";
                            if (message.comment != null && message.hasOwnProperty("comment"))
                                if (!$util.isString(message.comment))
                                    return "comment: string expected";
                            return null;
                        };

                        /**
                         * Creates a StoreDriverExpenseRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest} StoreDriverExpenseRequest
                         */
                        StoreDriverExpenseRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest();
                            if (object.typeId != null)
                                message.typeId = String(object.typeId);
                            if (object.sessionId != null)
                                message.sessionId = String(object.sessionId);
                            if (object.amount != null)
                                message.amount = Number(object.amount);
                            if (object.comment != null)
                                message.comment = String(object.comment);
                            return message;
                        };

                        /**
                         * Creates a plain object from a StoreDriverExpenseRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest} message StoreDriverExpenseRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StoreDriverExpenseRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.typeId = "";
                                object.sessionId = "";
                                object.amount = 0;
                                object.comment = "";
                            }
                            if (message.typeId != null && message.hasOwnProperty("typeId"))
                                object.typeId = message.typeId;
                            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                                object.sessionId = message.sessionId;
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
                            if (message.comment != null && message.hasOwnProperty("comment"))
                                object.comment = message.comment;
                            return object;
                        };

                        /**
                         * Converts this StoreDriverExpenseRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.DriverExpenseProto.StoreDriverExpenseRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StoreDriverExpenseRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StoreDriverExpenseRequest;
                    })();

                    return DriverExpenseProto;
                })();

                driver.DriverRestProto = (function() {

                    /**
                     * Properties of a DriverRestProto.
                     * @memberof pro.megakit.amelia.driver
                     * @interface IDriverRestProto
                     */

                    /**
                     * Constructs a new DriverRestProto.
                     * @memberof pro.megakit.amelia.driver
                     * @classdesc Represents a DriverRestProto.
                     * @implements IDriverRestProto
                     * @constructor
                     * @param {pro.megakit.amelia.driver.IDriverRestProto=} [properties] Properties to set
                     */
                    function DriverRestProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new DriverRestProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.driver.DriverRestProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IDriverRestProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.driver.DriverRestProto} DriverRestProto instance
                     */
                    DriverRestProto.create = function create(properties) {
                        return new DriverRestProto(properties);
                    };

                    /**
                     * Encodes the specified DriverRestProto message. Does not implicitly {@link pro.megakit.amelia.driver.DriverRestProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.driver.DriverRestProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IDriverRestProto} message DriverRestProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverRestProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified DriverRestProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverRestProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.driver.DriverRestProto
                     * @static
                     * @param {pro.megakit.amelia.driver.IDriverRestProto} message DriverRestProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverRestProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DriverRestProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.driver.DriverRestProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.driver.DriverRestProto} DriverRestProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverRestProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverRestProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DriverRestProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.driver.DriverRestProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.driver.DriverRestProto} DriverRestProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverRestProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DriverRestProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.driver.DriverRestProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DriverRestProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a DriverRestProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.driver.DriverRestProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.driver.DriverRestProto} DriverRestProto
                     */
                    DriverRestProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.driver.DriverRestProto)
                            return object;
                        return new $root.pro.megakit.amelia.driver.DriverRestProto();
                    };

                    /**
                     * Creates a plain object from a DriverRestProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.driver.DriverRestProto
                     * @static
                     * @param {pro.megakit.amelia.driver.DriverRestProto} message DriverRestProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DriverRestProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this DriverRestProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.driver.DriverRestProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DriverRestProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    DriverRestProto.CreateDriverRestPayload = (function() {

                        /**
                         * Properties of a CreateDriverRestPayload.
                         * @memberof pro.megakit.amelia.driver.DriverRestProto
                         * @interface ICreateDriverRestPayload
                         * @property {string|null} [typeId] CreateDriverRestPayload typeId
                         * @property {string|null} [addressName] CreateDriverRestPayload addressName
                         */

                        /**
                         * Constructs a new CreateDriverRestPayload.
                         * @memberof pro.megakit.amelia.driver.DriverRestProto
                         * @classdesc Represents a CreateDriverRestPayload.
                         * @implements ICreateDriverRestPayload
                         * @constructor
                         * @param {pro.megakit.amelia.driver.DriverRestProto.ICreateDriverRestPayload=} [properties] Properties to set
                         */
                        function CreateDriverRestPayload(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CreateDriverRestPayload typeId.
                         * @member {string} typeId
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload
                         * @instance
                         */
                        CreateDriverRestPayload.prototype.typeId = "";

                        /**
                         * CreateDriverRestPayload addressName.
                         * @member {string} addressName
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload
                         * @instance
                         */
                        CreateDriverRestPayload.prototype.addressName = "";

                        /**
                         * Creates a new CreateDriverRestPayload instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.ICreateDriverRestPayload=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload} CreateDriverRestPayload instance
                         */
                        CreateDriverRestPayload.create = function create(properties) {
                            return new CreateDriverRestPayload(properties);
                        };

                        /**
                         * Encodes the specified CreateDriverRestPayload message. Does not implicitly {@link pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.ICreateDriverRestPayload} message CreateDriverRestPayload message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CreateDriverRestPayload.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.typeId != null && message.hasOwnProperty("typeId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.typeId);
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.addressName);
                            return writer;
                        };

                        /**
                         * Encodes the specified CreateDriverRestPayload message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.ICreateDriverRestPayload} message CreateDriverRestPayload message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CreateDriverRestPayload.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a CreateDriverRestPayload message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload} CreateDriverRestPayload
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CreateDriverRestPayload.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.typeId = reader.string();
                                    break;
                                case 2:
                                    message.addressName = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a CreateDriverRestPayload message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload} CreateDriverRestPayload
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CreateDriverRestPayload.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a CreateDriverRestPayload message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CreateDriverRestPayload.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.typeId != null && message.hasOwnProperty("typeId"))
                                if (!$util.isString(message.typeId))
                                    return "typeId: string expected";
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                if (!$util.isString(message.addressName))
                                    return "addressName: string expected";
                            return null;
                        };

                        /**
                         * Creates a CreateDriverRestPayload message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload} CreateDriverRestPayload
                         */
                        CreateDriverRestPayload.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload();
                            if (object.typeId != null)
                                message.typeId = String(object.typeId);
                            if (object.addressName != null)
                                message.addressName = String(object.addressName);
                            return message;
                        };

                        /**
                         * Creates a plain object from a CreateDriverRestPayload message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload} message CreateDriverRestPayload
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CreateDriverRestPayload.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.typeId = "";
                                object.addressName = "";
                            }
                            if (message.typeId != null && message.hasOwnProperty("typeId"))
                                object.typeId = message.typeId;
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                object.addressName = message.addressName;
                            return object;
                        };

                        /**
                         * Converts this CreateDriverRestPayload to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.CreateDriverRestPayload
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CreateDriverRestPayload.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return CreateDriverRestPayload;
                    })();

                    DriverRestProto.StartDriverRestRequest = (function() {

                        /**
                         * Properties of a StartDriverRestRequest.
                         * @memberof pro.megakit.amelia.driver.DriverRestProto
                         * @interface IStartDriverRestRequest
                         * @property {string|null} [requestId] StartDriverRestRequest requestId
                         */

                        /**
                         * Constructs a new StartDriverRestRequest.
                         * @memberof pro.megakit.amelia.driver.DriverRestProto
                         * @classdesc Represents a StartDriverRestRequest.
                         * @implements IStartDriverRestRequest
                         * @constructor
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IStartDriverRestRequest=} [properties] Properties to set
                         */
                        function StartDriverRestRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StartDriverRestRequest requestId.
                         * @member {string} requestId
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest
                         * @instance
                         */
                        StartDriverRestRequest.prototype.requestId = "";

                        /**
                         * Creates a new StartDriverRestRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IStartDriverRestRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest} StartDriverRestRequest instance
                         */
                        StartDriverRestRequest.create = function create(properties) {
                            return new StartDriverRestRequest(properties);
                        };

                        /**
                         * Encodes the specified StartDriverRestRequest message. Does not implicitly {@link pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IStartDriverRestRequest} message StartDriverRestRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartDriverRestRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.requestId);
                            return writer;
                        };

                        /**
                         * Encodes the specified StartDriverRestRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IStartDriverRestRequest} message StartDriverRestRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartDriverRestRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StartDriverRestRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest} StartDriverRestRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartDriverRestRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.requestId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StartDriverRestRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest} StartDriverRestRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartDriverRestRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StartDriverRestRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StartDriverRestRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                if (!$util.isString(message.requestId))
                                    return "requestId: string expected";
                            return null;
                        };

                        /**
                         * Creates a StartDriverRestRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest} StartDriverRestRequest
                         */
                        StartDriverRestRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest();
                            if (object.requestId != null)
                                message.requestId = String(object.requestId);
                            return message;
                        };

                        /**
                         * Creates a plain object from a StartDriverRestRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest} message StartDriverRestRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StartDriverRestRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.requestId = "";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                object.requestId = message.requestId;
                            return object;
                        };

                        /**
                         * Converts this StartDriverRestRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StartDriverRestRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StartDriverRestRequest;
                    })();

                    DriverRestProto.StartDriverRestResponse = (function() {

                        /**
                         * Properties of a StartDriverRestResponse.
                         * @memberof pro.megakit.amelia.driver.DriverRestProto
                         * @interface IStartDriverRestResponse
                         * @property {string|null} [id] StartDriverRestResponse id
                         */

                        /**
                         * Constructs a new StartDriverRestResponse.
                         * @memberof pro.megakit.amelia.driver.DriverRestProto
                         * @classdesc Represents a StartDriverRestResponse.
                         * @implements IStartDriverRestResponse
                         * @constructor
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IStartDriverRestResponse=} [properties] Properties to set
                         */
                        function StartDriverRestResponse(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StartDriverRestResponse id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse
                         * @instance
                         */
                        StartDriverRestResponse.prototype.id = "";

                        /**
                         * Creates a new StartDriverRestResponse instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IStartDriverRestResponse=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse} StartDriverRestResponse instance
                         */
                        StartDriverRestResponse.create = function create(properties) {
                            return new StartDriverRestResponse(properties);
                        };

                        /**
                         * Encodes the specified StartDriverRestResponse message. Does not implicitly {@link pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IStartDriverRestResponse} message StartDriverRestResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartDriverRestResponse.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified StartDriverRestResponse message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IStartDriverRestResponse} message StartDriverRestResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartDriverRestResponse.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StartDriverRestResponse message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse} StartDriverRestResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartDriverRestResponse.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StartDriverRestResponse message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse} StartDriverRestResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartDriverRestResponse.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StartDriverRestResponse message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StartDriverRestResponse.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a StartDriverRestResponse message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse} StartDriverRestResponse
                         */
                        StartDriverRestResponse.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a StartDriverRestResponse message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse} message StartDriverRestResponse
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StartDriverRestResponse.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this StartDriverRestResponse to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.StartDriverRestResponse
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StartDriverRestResponse.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StartDriverRestResponse;
                    })();

                    DriverRestProto.FinishDriverRestRequest = (function() {

                        /**
                         * Properties of a FinishDriverRestRequest.
                         * @memberof pro.megakit.amelia.driver.DriverRestProto
                         * @interface IFinishDriverRestRequest
                         * @property {string|null} [id] FinishDriverRestRequest id
                         */

                        /**
                         * Constructs a new FinishDriverRestRequest.
                         * @memberof pro.megakit.amelia.driver.DriverRestProto
                         * @classdesc Represents a FinishDriverRestRequest.
                         * @implements IFinishDriverRestRequest
                         * @constructor
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IFinishDriverRestRequest=} [properties] Properties to set
                         */
                        function FinishDriverRestRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FinishDriverRestRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest
                         * @instance
                         */
                        FinishDriverRestRequest.prototype.id = "";

                        /**
                         * Creates a new FinishDriverRestRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IFinishDriverRestRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest} FinishDriverRestRequest instance
                         */
                        FinishDriverRestRequest.create = function create(properties) {
                            return new FinishDriverRestRequest(properties);
                        };

                        /**
                         * Encodes the specified FinishDriverRestRequest message. Does not implicitly {@link pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IFinishDriverRestRequest} message FinishDriverRestRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishDriverRestRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified FinishDriverRestRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IFinishDriverRestRequest} message FinishDriverRestRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishDriverRestRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FinishDriverRestRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest} FinishDriverRestRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishDriverRestRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FinishDriverRestRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest} FinishDriverRestRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishDriverRestRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FinishDriverRestRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FinishDriverRestRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a FinishDriverRestRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest} FinishDriverRestRequest
                         */
                        FinishDriverRestRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a FinishDriverRestRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest} message FinishDriverRestRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FinishDriverRestRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this FinishDriverRestRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.FinishDriverRestRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FinishDriverRestRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FinishDriverRestRequest;
                    })();

                    DriverRestProto.GetDriverRestTypesResponse = (function() {

                        /**
                         * Properties of a GetDriverRestTypesResponse.
                         * @memberof pro.megakit.amelia.driver.DriverRestProto
                         * @interface IGetDriverRestTypesResponse
                         * @property {Array.<pro.megakit.amelia.DriverRestProto.IDriverRestType>|null} [types] GetDriverRestTypesResponse types
                         */

                        /**
                         * Constructs a new GetDriverRestTypesResponse.
                         * @memberof pro.megakit.amelia.driver.DriverRestProto
                         * @classdesc Represents a GetDriverRestTypesResponse.
                         * @implements IGetDriverRestTypesResponse
                         * @constructor
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IGetDriverRestTypesResponse=} [properties] Properties to set
                         */
                        function GetDriverRestTypesResponse(properties) {
                            this.types = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * GetDriverRestTypesResponse types.
                         * @member {Array.<pro.megakit.amelia.DriverRestProto.IDriverRestType>} types
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse
                         * @instance
                         */
                        GetDriverRestTypesResponse.prototype.types = $util.emptyArray;

                        /**
                         * Creates a new GetDriverRestTypesResponse instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IGetDriverRestTypesResponse=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse} GetDriverRestTypesResponse instance
                         */
                        GetDriverRestTypesResponse.create = function create(properties) {
                            return new GetDriverRestTypesResponse(properties);
                        };

                        /**
                         * Encodes the specified GetDriverRestTypesResponse message. Does not implicitly {@link pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IGetDriverRestTypesResponse} message GetDriverRestTypesResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GetDriverRestTypesResponse.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.types != null && message.types.length)
                                for (var i = 0; i < message.types.length; ++i)
                                    $root.pro.megakit.amelia.DriverRestProto.DriverRestType.encode(message.types[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified GetDriverRestTypesResponse message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IGetDriverRestTypesResponse} message GetDriverRestTypesResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GetDriverRestTypesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a GetDriverRestTypesResponse message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse} GetDriverRestTypesResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GetDriverRestTypesResponse.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.types && message.types.length))
                                        message.types = [];
                                    message.types.push($root.pro.megakit.amelia.DriverRestProto.DriverRestType.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a GetDriverRestTypesResponse message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse} GetDriverRestTypesResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GetDriverRestTypesResponse.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a GetDriverRestTypesResponse message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        GetDriverRestTypesResponse.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.types != null && message.hasOwnProperty("types")) {
                                if (!Array.isArray(message.types))
                                    return "types: array expected";
                                for (var i = 0; i < message.types.length; ++i) {
                                    var error = $root.pro.megakit.amelia.DriverRestProto.DriverRestType.verify(message.types[i]);
                                    if (error)
                                        return "types." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a GetDriverRestTypesResponse message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse} GetDriverRestTypesResponse
                         */
                        GetDriverRestTypesResponse.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse();
                            if (object.types) {
                                if (!Array.isArray(object.types))
                                    throw TypeError(".pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse.types: array expected");
                                message.types = [];
                                for (var i = 0; i < object.types.length; ++i) {
                                    if (typeof object.types[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse.types: object expected");
                                    message.types[i] = $root.pro.megakit.amelia.DriverRestProto.DriverRestType.fromObject(object.types[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a GetDriverRestTypesResponse message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse} message GetDriverRestTypesResponse
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        GetDriverRestTypesResponse.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.types = [];
                            if (message.types && message.types.length) {
                                object.types = [];
                                for (var j = 0; j < message.types.length; ++j)
                                    object.types[j] = $root.pro.megakit.amelia.DriverRestProto.DriverRestType.toObject(message.types[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this GetDriverRestTypesResponse to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.GetDriverRestTypesResponse
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        GetDriverRestTypesResponse.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return GetDriverRestTypesResponse;
                    })();

                    DriverRestProto.ActiveDriverRest = (function() {

                        /**
                         * Properties of an ActiveDriverRest.
                         * @memberof pro.megakit.amelia.driver.DriverRestProto
                         * @interface IActiveDriverRest
                         * @property {string|null} [id] ActiveDriverRest id
                         * @property {string|null} [typeId] ActiveDriverRest typeId
                         * @property {string|null} [addressName] ActiveDriverRest addressName
                         * @property {number|null} [duration] ActiveDriverRest duration
                         * @property {google.protobuf.ITimestamp|null} [startedAt] ActiveDriverRest startedAt
                         */

                        /**
                         * Constructs a new ActiveDriverRest.
                         * @memberof pro.megakit.amelia.driver.DriverRestProto
                         * @classdesc Represents an ActiveDriverRest.
                         * @implements IActiveDriverRest
                         * @constructor
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IActiveDriverRest=} [properties] Properties to set
                         */
                        function ActiveDriverRest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ActiveDriverRest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest
                         * @instance
                         */
                        ActiveDriverRest.prototype.id = "";

                        /**
                         * ActiveDriverRest typeId.
                         * @member {string} typeId
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest
                         * @instance
                         */
                        ActiveDriverRest.prototype.typeId = "";

                        /**
                         * ActiveDriverRest addressName.
                         * @member {string} addressName
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest
                         * @instance
                         */
                        ActiveDriverRest.prototype.addressName = "";

                        /**
                         * ActiveDriverRest duration.
                         * @member {number} duration
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest
                         * @instance
                         */
                        ActiveDriverRest.prototype.duration = 0;

                        /**
                         * ActiveDriverRest startedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} startedAt
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest
                         * @instance
                         */
                        ActiveDriverRest.prototype.startedAt = null;

                        /**
                         * Creates a new ActiveDriverRest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IActiveDriverRest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest} ActiveDriverRest instance
                         */
                        ActiveDriverRest.create = function create(properties) {
                            return new ActiveDriverRest(properties);
                        };

                        /**
                         * Encodes the specified ActiveDriverRest message. Does not implicitly {@link pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IActiveDriverRest} message ActiveDriverRest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveDriverRest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.typeId != null && message.hasOwnProperty("typeId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.typeId);
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.addressName);
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.duration);
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                $root.google.protobuf.Timestamp.encode(message.startedAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ActiveDriverRest message, length delimited. Does not implicitly {@link pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.IActiveDriverRest} message ActiveDriverRest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveDriverRest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an ActiveDriverRest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest} ActiveDriverRest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveDriverRest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.typeId = reader.string();
                                    break;
                                case 3:
                                    message.addressName = reader.string();
                                    break;
                                case 4:
                                    message.duration = reader.uint32();
                                    break;
                                case 5:
                                    message.startedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an ActiveDriverRest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest} ActiveDriverRest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveDriverRest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an ActiveDriverRest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ActiveDriverRest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.typeId != null && message.hasOwnProperty("typeId"))
                                if (!$util.isString(message.typeId))
                                    return "typeId: string expected";
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                if (!$util.isString(message.addressName))
                                    return "addressName: string expected";
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                if (!$util.isInteger(message.duration))
                                    return "duration: integer expected";
                            if (message.startedAt != null && message.hasOwnProperty("startedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.startedAt);
                                if (error)
                                    return "startedAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates an ActiveDriverRest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest} ActiveDriverRest
                         */
                        ActiveDriverRest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest)
                                return object;
                            var message = new $root.pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.typeId != null)
                                message.typeId = String(object.typeId);
                            if (object.addressName != null)
                                message.addressName = String(object.addressName);
                            if (object.duration != null)
                                message.duration = object.duration >>> 0;
                            if (object.startedAt != null) {
                                if (typeof object.startedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest.startedAt: object expected");
                                message.startedAt = $root.google.protobuf.Timestamp.fromObject(object.startedAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an ActiveDriverRest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest
                         * @static
                         * @param {pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest} message ActiveDriverRest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ActiveDriverRest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.typeId = "";
                                object.addressName = "";
                                object.duration = 0;
                                object.startedAt = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.typeId != null && message.hasOwnProperty("typeId"))
                                object.typeId = message.typeId;
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                object.addressName = message.addressName;
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                object.duration = message.duration;
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                object.startedAt = $root.google.protobuf.Timestamp.toObject(message.startedAt, options);
                            return object;
                        };

                        /**
                         * Converts this ActiveDriverRest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.driver.DriverRestProto.ActiveDriverRest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ActiveDriverRest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ActiveDriverRest;
                    })();

                    return DriverRestProto;
                })();

                return driver;
            })();

            amelia.operator = (function() {

                /**
                 * Namespace operator.
                 * @memberof pro.megakit.amelia
                 * @namespace
                 */
                var operator = {};

                operator.EventProto = (function() {

                    /**
                     * Properties of an EventProto.
                     * @memberof pro.megakit.amelia.operator
                     * @interface IEventProto
                     */

                    /**
                     * Constructs a new EventProto.
                     * @memberof pro.megakit.amelia.operator
                     * @classdesc Represents an EventProto.
                     * @implements IEventProto
                     * @constructor
                     * @param {pro.megakit.amelia.operator.IEventProto=} [properties] Properties to set
                     */
                    function EventProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new EventProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.operator.EventProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IEventProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.operator.EventProto} EventProto instance
                     */
                    EventProto.create = function create(properties) {
                        return new EventProto(properties);
                    };

                    /**
                     * Encodes the specified EventProto message. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.operator.EventProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IEventProto} message EventProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EventProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified EventProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.operator.EventProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IEventProto} message EventProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EventProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an EventProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.operator.EventProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.operator.EventProto} EventProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EventProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.EventProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an EventProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.operator.EventProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.operator.EventProto} EventProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EventProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an EventProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.operator.EventProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    EventProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates an EventProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.operator.EventProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.operator.EventProto} EventProto
                     */
                    EventProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.operator.EventProto)
                            return object;
                        return new $root.pro.megakit.amelia.operator.EventProto();
                    };

                    /**
                     * Creates a plain object from an EventProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.operator.EventProto
                     * @static
                     * @param {pro.megakit.amelia.operator.EventProto} message EventProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    EventProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this EventProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.operator.EventProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    EventProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    EventProto.ReceiveWorkingShiftsEvent = (function() {

                        /**
                         * Properties of a ReceiveWorkingShiftsEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @interface IReceiveWorkingShiftsEvent
                         * @property {Array.<pro.megakit.amelia.operator.WorkingShift.IActiveWorkingShift>|null} [workingShifts] ReceiveWorkingShiftsEvent workingShifts
                         * @property {Array.<pro.megakit.amelia.operator.DriverRequest.IActiveDriverRequest>|null} [activeDriverRequests] ReceiveWorkingShiftsEvent activeDriverRequests
                         * @property {Array.<pro.megakit.amelia.operator.DriverRequest.IFinishedDriverRequest>|null} [finishedDriverRequests] ReceiveWorkingShiftsEvent finishedDriverRequests
                         * @property {Array.<pro.megakit.amelia.operator.VehicleWashingProto.IActiveVehicleWashing>|null} [vehicleWashing] ReceiveWorkingShiftsEvent vehicleWashing
                         * @property {Array.<pro.megakit.amelia.operator.VehicleWashingProto.IFinishedVehicleWashing>|null} [finishedVehicleWashing] ReceiveWorkingShiftsEvent finishedVehicleWashing
                         * @property {Array.<pro.megakit.amelia.operator.DriverRestProto.IActiveDriverRest>|null} [driverRest] ReceiveWorkingShiftsEvent driverRest
                         * @property {Array.<pro.megakit.amelia.operator.DriverRestProto.IFinishedDriverRest>|null} [finishedDriverRest] ReceiveWorkingShiftsEvent finishedDriverRest
                         * @property {Array.<pro.megakit.amelia.operator.VehicleRefillProto.IFinishedVehicleRefill>|null} [finishedVehicleRefill] ReceiveWorkingShiftsEvent finishedVehicleRefill
                         * @property {Array.<pro.megakit.amelia.operator.DriverPauseProto.IActiveDriverPause>|null} [driverPauses] ReceiveWorkingShiftsEvent driverPauses
                         * @property {Array.<pro.megakit.amelia.operator.DriverPauseProto.IFinishedDriverPause>|null} [finishedDriverPauses] ReceiveWorkingShiftsEvent finishedDriverPauses
                         */

                        /**
                         * Constructs a new ReceiveWorkingShiftsEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @classdesc Represents a ReceiveWorkingShiftsEvent.
                         * @implements IReceiveWorkingShiftsEvent
                         * @constructor
                         * @param {pro.megakit.amelia.operator.EventProto.IReceiveWorkingShiftsEvent=} [properties] Properties to set
                         */
                        function ReceiveWorkingShiftsEvent(properties) {
                            this.workingShifts = [];
                            this.activeDriverRequests = [];
                            this.finishedDriverRequests = [];
                            this.vehicleWashing = [];
                            this.finishedVehicleWashing = [];
                            this.driverRest = [];
                            this.finishedDriverRest = [];
                            this.finishedVehicleRefill = [];
                            this.driverPauses = [];
                            this.finishedDriverPauses = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ReceiveWorkingShiftsEvent workingShifts.
                         * @member {Array.<pro.megakit.amelia.operator.WorkingShift.IActiveWorkingShift>} workingShifts
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @instance
                         */
                        ReceiveWorkingShiftsEvent.prototype.workingShifts = $util.emptyArray;

                        /**
                         * ReceiveWorkingShiftsEvent activeDriverRequests.
                         * @member {Array.<pro.megakit.amelia.operator.DriverRequest.IActiveDriverRequest>} activeDriverRequests
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @instance
                         */
                        ReceiveWorkingShiftsEvent.prototype.activeDriverRequests = $util.emptyArray;

                        /**
                         * ReceiveWorkingShiftsEvent finishedDriverRequests.
                         * @member {Array.<pro.megakit.amelia.operator.DriverRequest.IFinishedDriverRequest>} finishedDriverRequests
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @instance
                         */
                        ReceiveWorkingShiftsEvent.prototype.finishedDriverRequests = $util.emptyArray;

                        /**
                         * ReceiveWorkingShiftsEvent vehicleWashing.
                         * @member {Array.<pro.megakit.amelia.operator.VehicleWashingProto.IActiveVehicleWashing>} vehicleWashing
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @instance
                         */
                        ReceiveWorkingShiftsEvent.prototype.vehicleWashing = $util.emptyArray;

                        /**
                         * ReceiveWorkingShiftsEvent finishedVehicleWashing.
                         * @member {Array.<pro.megakit.amelia.operator.VehicleWashingProto.IFinishedVehicleWashing>} finishedVehicleWashing
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @instance
                         */
                        ReceiveWorkingShiftsEvent.prototype.finishedVehicleWashing = $util.emptyArray;

                        /**
                         * ReceiveWorkingShiftsEvent driverRest.
                         * @member {Array.<pro.megakit.amelia.operator.DriverRestProto.IActiveDriverRest>} driverRest
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @instance
                         */
                        ReceiveWorkingShiftsEvent.prototype.driverRest = $util.emptyArray;

                        /**
                         * ReceiveWorkingShiftsEvent finishedDriverRest.
                         * @member {Array.<pro.megakit.amelia.operator.DriverRestProto.IFinishedDriverRest>} finishedDriverRest
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @instance
                         */
                        ReceiveWorkingShiftsEvent.prototype.finishedDriverRest = $util.emptyArray;

                        /**
                         * ReceiveWorkingShiftsEvent finishedVehicleRefill.
                         * @member {Array.<pro.megakit.amelia.operator.VehicleRefillProto.IFinishedVehicleRefill>} finishedVehicleRefill
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @instance
                         */
                        ReceiveWorkingShiftsEvent.prototype.finishedVehicleRefill = $util.emptyArray;

                        /**
                         * ReceiveWorkingShiftsEvent driverPauses.
                         * @member {Array.<pro.megakit.amelia.operator.DriverPauseProto.IActiveDriverPause>} driverPauses
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @instance
                         */
                        ReceiveWorkingShiftsEvent.prototype.driverPauses = $util.emptyArray;

                        /**
                         * ReceiveWorkingShiftsEvent finishedDriverPauses.
                         * @member {Array.<pro.megakit.amelia.operator.DriverPauseProto.IFinishedDriverPause>} finishedDriverPauses
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @instance
                         */
                        ReceiveWorkingShiftsEvent.prototype.finishedDriverPauses = $util.emptyArray;

                        /**
                         * Creates a new ReceiveWorkingShiftsEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IReceiveWorkingShiftsEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent} ReceiveWorkingShiftsEvent instance
                         */
                        ReceiveWorkingShiftsEvent.create = function create(properties) {
                            return new ReceiveWorkingShiftsEvent(properties);
                        };

                        /**
                         * Encodes the specified ReceiveWorkingShiftsEvent message. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IReceiveWorkingShiftsEvent} message ReceiveWorkingShiftsEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ReceiveWorkingShiftsEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.workingShifts != null && message.workingShifts.length)
                                for (var i = 0; i < message.workingShifts.length; ++i)
                                    $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.encode(message.workingShifts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.activeDriverRequests != null && message.activeDriverRequests.length)
                                for (var i = 0; i < message.activeDriverRequests.length; ++i)
                                    $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.encode(message.activeDriverRequests[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.vehicleWashing != null && message.vehicleWashing.length)
                                for (var i = 0; i < message.vehicleWashing.length; ++i)
                                    $root.pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing.encode(message.vehicleWashing[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.driverRest != null && message.driverRest.length)
                                for (var i = 0; i < message.driverRest.length; ++i)
                                    $root.pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest.encode(message.driverRest[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            if (message.finishedDriverRest != null && message.finishedDriverRest.length)
                                for (var i = 0; i < message.finishedDriverRest.length; ++i)
                                    $root.pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest.encode(message.finishedDriverRest[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            if (message.finishedVehicleWashing != null && message.finishedVehicleWashing.length)
                                for (var i = 0; i < message.finishedVehicleWashing.length; ++i)
                                    $root.pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing.encode(message.finishedVehicleWashing[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            if (message.finishedDriverRequests != null && message.finishedDriverRequests.length)
                                for (var i = 0; i < message.finishedDriverRequests.length; ++i)
                                    $root.pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest.encode(message.finishedDriverRequests[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.finishedVehicleRefill != null && message.finishedVehicleRefill.length)
                                for (var i = 0; i < message.finishedVehicleRefill.length; ++i)
                                    $root.pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill.encode(message.finishedVehicleRefill[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.driverPauses != null && message.driverPauses.length)
                                for (var i = 0; i < message.driverPauses.length; ++i)
                                    $root.pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause.encode(message.driverPauses[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                            if (message.finishedDriverPauses != null && message.finishedDriverPauses.length)
                                for (var i = 0; i < message.finishedDriverPauses.length; ++i)
                                    $root.pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause.encode(message.finishedDriverPauses[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ReceiveWorkingShiftsEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IReceiveWorkingShiftsEvent} message ReceiveWorkingShiftsEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ReceiveWorkingShiftsEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ReceiveWorkingShiftsEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent} ReceiveWorkingShiftsEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ReceiveWorkingShiftsEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.workingShifts && message.workingShifts.length))
                                        message.workingShifts = [];
                                    message.workingShifts.push($root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.decode(reader, reader.uint32()));
                                    break;
                                case 2:
                                    if (!(message.activeDriverRequests && message.activeDriverRequests.length))
                                        message.activeDriverRequests = [];
                                    message.activeDriverRequests.push($root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.decode(reader, reader.uint32()));
                                    break;
                                case 7:
                                    if (!(message.finishedDriverRequests && message.finishedDriverRequests.length))
                                        message.finishedDriverRequests = [];
                                    message.finishedDriverRequests.push($root.pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest.decode(reader, reader.uint32()));
                                    break;
                                case 3:
                                    if (!(message.vehicleWashing && message.vehicleWashing.length))
                                        message.vehicleWashing = [];
                                    message.vehicleWashing.push($root.pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing.decode(reader, reader.uint32()));
                                    break;
                                case 6:
                                    if (!(message.finishedVehicleWashing && message.finishedVehicleWashing.length))
                                        message.finishedVehicleWashing = [];
                                    message.finishedVehicleWashing.push($root.pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing.decode(reader, reader.uint32()));
                                    break;
                                case 4:
                                    if (!(message.driverRest && message.driverRest.length))
                                        message.driverRest = [];
                                    message.driverRest.push($root.pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest.decode(reader, reader.uint32()));
                                    break;
                                case 5:
                                    if (!(message.finishedDriverRest && message.finishedDriverRest.length))
                                        message.finishedDriverRest = [];
                                    message.finishedDriverRest.push($root.pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest.decode(reader, reader.uint32()));
                                    break;
                                case 8:
                                    if (!(message.finishedVehicleRefill && message.finishedVehicleRefill.length))
                                        message.finishedVehicleRefill = [];
                                    message.finishedVehicleRefill.push($root.pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill.decode(reader, reader.uint32()));
                                    break;
                                case 9:
                                    if (!(message.driverPauses && message.driverPauses.length))
                                        message.driverPauses = [];
                                    message.driverPauses.push($root.pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause.decode(reader, reader.uint32()));
                                    break;
                                case 10:
                                    if (!(message.finishedDriverPauses && message.finishedDriverPauses.length))
                                        message.finishedDriverPauses = [];
                                    message.finishedDriverPauses.push($root.pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ReceiveWorkingShiftsEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent} ReceiveWorkingShiftsEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ReceiveWorkingShiftsEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ReceiveWorkingShiftsEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ReceiveWorkingShiftsEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.workingShifts != null && message.hasOwnProperty("workingShifts")) {
                                if (!Array.isArray(message.workingShifts))
                                    return "workingShifts: array expected";
                                for (var i = 0; i < message.workingShifts.length; ++i) {
                                    var error = $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.verify(message.workingShifts[i]);
                                    if (error)
                                        return "workingShifts." + error;
                                }
                            }
                            if (message.activeDriverRequests != null && message.hasOwnProperty("activeDriverRequests")) {
                                if (!Array.isArray(message.activeDriverRequests))
                                    return "activeDriverRequests: array expected";
                                for (var i = 0; i < message.activeDriverRequests.length; ++i) {
                                    var error = $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.verify(message.activeDriverRequests[i]);
                                    if (error)
                                        return "activeDriverRequests." + error;
                                }
                            }
                            if (message.finishedDriverRequests != null && message.hasOwnProperty("finishedDriverRequests")) {
                                if (!Array.isArray(message.finishedDriverRequests))
                                    return "finishedDriverRequests: array expected";
                                for (var i = 0; i < message.finishedDriverRequests.length; ++i) {
                                    var error = $root.pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest.verify(message.finishedDriverRequests[i]);
                                    if (error)
                                        return "finishedDriverRequests." + error;
                                }
                            }
                            if (message.vehicleWashing != null && message.hasOwnProperty("vehicleWashing")) {
                                if (!Array.isArray(message.vehicleWashing))
                                    return "vehicleWashing: array expected";
                                for (var i = 0; i < message.vehicleWashing.length; ++i) {
                                    var error = $root.pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing.verify(message.vehicleWashing[i]);
                                    if (error)
                                        return "vehicleWashing." + error;
                                }
                            }
                            if (message.finishedVehicleWashing != null && message.hasOwnProperty("finishedVehicleWashing")) {
                                if (!Array.isArray(message.finishedVehicleWashing))
                                    return "finishedVehicleWashing: array expected";
                                for (var i = 0; i < message.finishedVehicleWashing.length; ++i) {
                                    var error = $root.pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing.verify(message.finishedVehicleWashing[i]);
                                    if (error)
                                        return "finishedVehicleWashing." + error;
                                }
                            }
                            if (message.driverRest != null && message.hasOwnProperty("driverRest")) {
                                if (!Array.isArray(message.driverRest))
                                    return "driverRest: array expected";
                                for (var i = 0; i < message.driverRest.length; ++i) {
                                    var error = $root.pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest.verify(message.driverRest[i]);
                                    if (error)
                                        return "driverRest." + error;
                                }
                            }
                            if (message.finishedDriverRest != null && message.hasOwnProperty("finishedDriverRest")) {
                                if (!Array.isArray(message.finishedDriverRest))
                                    return "finishedDriverRest: array expected";
                                for (var i = 0; i < message.finishedDriverRest.length; ++i) {
                                    var error = $root.pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest.verify(message.finishedDriverRest[i]);
                                    if (error)
                                        return "finishedDriverRest." + error;
                                }
                            }
                            if (message.finishedVehicleRefill != null && message.hasOwnProperty("finishedVehicleRefill")) {
                                if (!Array.isArray(message.finishedVehicleRefill))
                                    return "finishedVehicleRefill: array expected";
                                for (var i = 0; i < message.finishedVehicleRefill.length; ++i) {
                                    var error = $root.pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill.verify(message.finishedVehicleRefill[i]);
                                    if (error)
                                        return "finishedVehicleRefill." + error;
                                }
                            }
                            if (message.driverPauses != null && message.hasOwnProperty("driverPauses")) {
                                if (!Array.isArray(message.driverPauses))
                                    return "driverPauses: array expected";
                                for (var i = 0; i < message.driverPauses.length; ++i) {
                                    var error = $root.pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause.verify(message.driverPauses[i]);
                                    if (error)
                                        return "driverPauses." + error;
                                }
                            }
                            if (message.finishedDriverPauses != null && message.hasOwnProperty("finishedDriverPauses")) {
                                if (!Array.isArray(message.finishedDriverPauses))
                                    return "finishedDriverPauses: array expected";
                                for (var i = 0; i < message.finishedDriverPauses.length; ++i) {
                                    var error = $root.pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause.verify(message.finishedDriverPauses[i]);
                                    if (error)
                                        return "finishedDriverPauses." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a ReceiveWorkingShiftsEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent} ReceiveWorkingShiftsEvent
                         */
                        ReceiveWorkingShiftsEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent();
                            if (object.workingShifts) {
                                if (!Array.isArray(object.workingShifts))
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.workingShifts: array expected");
                                message.workingShifts = [];
                                for (var i = 0; i < object.workingShifts.length; ++i) {
                                    if (typeof object.workingShifts[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.workingShifts: object expected");
                                    message.workingShifts[i] = $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.fromObject(object.workingShifts[i]);
                                }
                            }
                            if (object.activeDriverRequests) {
                                if (!Array.isArray(object.activeDriverRequests))
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.activeDriverRequests: array expected");
                                message.activeDriverRequests = [];
                                for (var i = 0; i < object.activeDriverRequests.length; ++i) {
                                    if (typeof object.activeDriverRequests[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.activeDriverRequests: object expected");
                                    message.activeDriverRequests[i] = $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.fromObject(object.activeDriverRequests[i]);
                                }
                            }
                            if (object.finishedDriverRequests) {
                                if (!Array.isArray(object.finishedDriverRequests))
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.finishedDriverRequests: array expected");
                                message.finishedDriverRequests = [];
                                for (var i = 0; i < object.finishedDriverRequests.length; ++i) {
                                    if (typeof object.finishedDriverRequests[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.finishedDriverRequests: object expected");
                                    message.finishedDriverRequests[i] = $root.pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest.fromObject(object.finishedDriverRequests[i]);
                                }
                            }
                            if (object.vehicleWashing) {
                                if (!Array.isArray(object.vehicleWashing))
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.vehicleWashing: array expected");
                                message.vehicleWashing = [];
                                for (var i = 0; i < object.vehicleWashing.length; ++i) {
                                    if (typeof object.vehicleWashing[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.vehicleWashing: object expected");
                                    message.vehicleWashing[i] = $root.pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing.fromObject(object.vehicleWashing[i]);
                                }
                            }
                            if (object.finishedVehicleWashing) {
                                if (!Array.isArray(object.finishedVehicleWashing))
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.finishedVehicleWashing: array expected");
                                message.finishedVehicleWashing = [];
                                for (var i = 0; i < object.finishedVehicleWashing.length; ++i) {
                                    if (typeof object.finishedVehicleWashing[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.finishedVehicleWashing: object expected");
                                    message.finishedVehicleWashing[i] = $root.pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing.fromObject(object.finishedVehicleWashing[i]);
                                }
                            }
                            if (object.driverRest) {
                                if (!Array.isArray(object.driverRest))
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.driverRest: array expected");
                                message.driverRest = [];
                                for (var i = 0; i < object.driverRest.length; ++i) {
                                    if (typeof object.driverRest[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.driverRest: object expected");
                                    message.driverRest[i] = $root.pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest.fromObject(object.driverRest[i]);
                                }
                            }
                            if (object.finishedDriverRest) {
                                if (!Array.isArray(object.finishedDriverRest))
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.finishedDriverRest: array expected");
                                message.finishedDriverRest = [];
                                for (var i = 0; i < object.finishedDriverRest.length; ++i) {
                                    if (typeof object.finishedDriverRest[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.finishedDriverRest: object expected");
                                    message.finishedDriverRest[i] = $root.pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest.fromObject(object.finishedDriverRest[i]);
                                }
                            }
                            if (object.finishedVehicleRefill) {
                                if (!Array.isArray(object.finishedVehicleRefill))
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.finishedVehicleRefill: array expected");
                                message.finishedVehicleRefill = [];
                                for (var i = 0; i < object.finishedVehicleRefill.length; ++i) {
                                    if (typeof object.finishedVehicleRefill[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.finishedVehicleRefill: object expected");
                                    message.finishedVehicleRefill[i] = $root.pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill.fromObject(object.finishedVehicleRefill[i]);
                                }
                            }
                            if (object.driverPauses) {
                                if (!Array.isArray(object.driverPauses))
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.driverPauses: array expected");
                                message.driverPauses = [];
                                for (var i = 0; i < object.driverPauses.length; ++i) {
                                    if (typeof object.driverPauses[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.driverPauses: object expected");
                                    message.driverPauses[i] = $root.pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause.fromObject(object.driverPauses[i]);
                                }
                            }
                            if (object.finishedDriverPauses) {
                                if (!Array.isArray(object.finishedDriverPauses))
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.finishedDriverPauses: array expected");
                                message.finishedDriverPauses = [];
                                for (var i = 0; i < object.finishedDriverPauses.length; ++i) {
                                    if (typeof object.finishedDriverPauses[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent.finishedDriverPauses: object expected");
                                    message.finishedDriverPauses[i] = $root.pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause.fromObject(object.finishedDriverPauses[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ReceiveWorkingShiftsEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent} message ReceiveWorkingShiftsEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ReceiveWorkingShiftsEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.workingShifts = [];
                                object.activeDriverRequests = [];
                                object.vehicleWashing = [];
                                object.driverRest = [];
                                object.finishedDriverRest = [];
                                object.finishedVehicleWashing = [];
                                object.finishedDriverRequests = [];
                                object.finishedVehicleRefill = [];
                                object.driverPauses = [];
                                object.finishedDriverPauses = [];
                            }
                            if (message.workingShifts && message.workingShifts.length) {
                                object.workingShifts = [];
                                for (var j = 0; j < message.workingShifts.length; ++j)
                                    object.workingShifts[j] = $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.toObject(message.workingShifts[j], options);
                            }
                            if (message.activeDriverRequests && message.activeDriverRequests.length) {
                                object.activeDriverRequests = [];
                                for (var j = 0; j < message.activeDriverRequests.length; ++j)
                                    object.activeDriverRequests[j] = $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.toObject(message.activeDriverRequests[j], options);
                            }
                            if (message.vehicleWashing && message.vehicleWashing.length) {
                                object.vehicleWashing = [];
                                for (var j = 0; j < message.vehicleWashing.length; ++j)
                                    object.vehicleWashing[j] = $root.pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing.toObject(message.vehicleWashing[j], options);
                            }
                            if (message.driverRest && message.driverRest.length) {
                                object.driverRest = [];
                                for (var j = 0; j < message.driverRest.length; ++j)
                                    object.driverRest[j] = $root.pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest.toObject(message.driverRest[j], options);
                            }
                            if (message.finishedDriverRest && message.finishedDriverRest.length) {
                                object.finishedDriverRest = [];
                                for (var j = 0; j < message.finishedDriverRest.length; ++j)
                                    object.finishedDriverRest[j] = $root.pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest.toObject(message.finishedDriverRest[j], options);
                            }
                            if (message.finishedVehicleWashing && message.finishedVehicleWashing.length) {
                                object.finishedVehicleWashing = [];
                                for (var j = 0; j < message.finishedVehicleWashing.length; ++j)
                                    object.finishedVehicleWashing[j] = $root.pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing.toObject(message.finishedVehicleWashing[j], options);
                            }
                            if (message.finishedDriverRequests && message.finishedDriverRequests.length) {
                                object.finishedDriverRequests = [];
                                for (var j = 0; j < message.finishedDriverRequests.length; ++j)
                                    object.finishedDriverRequests[j] = $root.pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest.toObject(message.finishedDriverRequests[j], options);
                            }
                            if (message.finishedVehicleRefill && message.finishedVehicleRefill.length) {
                                object.finishedVehicleRefill = [];
                                for (var j = 0; j < message.finishedVehicleRefill.length; ++j)
                                    object.finishedVehicleRefill[j] = $root.pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill.toObject(message.finishedVehicleRefill[j], options);
                            }
                            if (message.driverPauses && message.driverPauses.length) {
                                object.driverPauses = [];
                                for (var j = 0; j < message.driverPauses.length; ++j)
                                    object.driverPauses[j] = $root.pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause.toObject(message.driverPauses[j], options);
                            }
                            if (message.finishedDriverPauses && message.finishedDriverPauses.length) {
                                object.finishedDriverPauses = [];
                                for (var j = 0; j < message.finishedDriverPauses.length; ++j)
                                    object.finishedDriverPauses[j] = $root.pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause.toObject(message.finishedDriverPauses[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this ReceiveWorkingShiftsEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.EventProto.ReceiveWorkingShiftsEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ReceiveWorkingShiftsEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ReceiveWorkingShiftsEvent;
                    })();

                    EventProto.WorkingShiftCreatedEvent = (function() {

                        /**
                         * Properties of a WorkingShiftCreatedEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @interface IWorkingShiftCreatedEvent
                         * @property {pro.megakit.amelia.operator.WorkingShift.IActiveWorkingShift|null} [workingShift] WorkingShiftCreatedEvent workingShift
                         * @property {pro.megakit.amelia.operator.DriverRequest.IActiveDriverRequest|null} [driverRequest] WorkingShiftCreatedEvent driverRequest
                         */

                        /**
                         * Constructs a new WorkingShiftCreatedEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @classdesc Represents a WorkingShiftCreatedEvent.
                         * @implements IWorkingShiftCreatedEvent
                         * @constructor
                         * @param {pro.megakit.amelia.operator.EventProto.IWorkingShiftCreatedEvent=} [properties] Properties to set
                         */
                        function WorkingShiftCreatedEvent(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * WorkingShiftCreatedEvent workingShift.
                         * @member {pro.megakit.amelia.operator.WorkingShift.IActiveWorkingShift|null|undefined} workingShift
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent
                         * @instance
                         */
                        WorkingShiftCreatedEvent.prototype.workingShift = null;

                        /**
                         * WorkingShiftCreatedEvent driverRequest.
                         * @member {pro.megakit.amelia.operator.DriverRequest.IActiveDriverRequest|null|undefined} driverRequest
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent
                         * @instance
                         */
                        WorkingShiftCreatedEvent.prototype.driverRequest = null;

                        /**
                         * Creates a new WorkingShiftCreatedEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IWorkingShiftCreatedEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent} WorkingShiftCreatedEvent instance
                         */
                        WorkingShiftCreatedEvent.create = function create(properties) {
                            return new WorkingShiftCreatedEvent(properties);
                        };

                        /**
                         * Encodes the specified WorkingShiftCreatedEvent message. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IWorkingShiftCreatedEvent} message WorkingShiftCreatedEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        WorkingShiftCreatedEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.workingShift != null && message.hasOwnProperty("workingShift"))
                                $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.encode(message.workingShift, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.driverRequest != null && message.hasOwnProperty("driverRequest"))
                                $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.encode(message.driverRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified WorkingShiftCreatedEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IWorkingShiftCreatedEvent} message WorkingShiftCreatedEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        WorkingShiftCreatedEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a WorkingShiftCreatedEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent} WorkingShiftCreatedEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        WorkingShiftCreatedEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.workingShift = $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.driverRequest = $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a WorkingShiftCreatedEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent} WorkingShiftCreatedEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        WorkingShiftCreatedEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a WorkingShiftCreatedEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        WorkingShiftCreatedEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.workingShift != null && message.hasOwnProperty("workingShift")) {
                                var error = $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.verify(message.workingShift);
                                if (error)
                                    return "workingShift." + error;
                            }
                            if (message.driverRequest != null && message.hasOwnProperty("driverRequest")) {
                                var error = $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.verify(message.driverRequest);
                                if (error)
                                    return "driverRequest." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a WorkingShiftCreatedEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent} WorkingShiftCreatedEvent
                         */
                        WorkingShiftCreatedEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent();
                            if (object.workingShift != null) {
                                if (typeof object.workingShift !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent.workingShift: object expected");
                                message.workingShift = $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.fromObject(object.workingShift);
                            }
                            if (object.driverRequest != null) {
                                if (typeof object.driverRequest !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent.driverRequest: object expected");
                                message.driverRequest = $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.fromObject(object.driverRequest);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a WorkingShiftCreatedEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent} message WorkingShiftCreatedEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        WorkingShiftCreatedEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.workingShift = null;
                                object.driverRequest = null;
                            }
                            if (message.workingShift != null && message.hasOwnProperty("workingShift"))
                                object.workingShift = $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.toObject(message.workingShift, options);
                            if (message.driverRequest != null && message.hasOwnProperty("driverRequest"))
                                object.driverRequest = $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.toObject(message.driverRequest, options);
                            return object;
                        };

                        /**
                         * Converts this WorkingShiftCreatedEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftCreatedEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        WorkingShiftCreatedEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return WorkingShiftCreatedEvent;
                    })();

                    EventProto.WorkingShiftRequestCreatedEvent = (function() {

                        /**
                         * Properties of a WorkingShiftRequestCreatedEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @interface IWorkingShiftRequestCreatedEvent
                         * @property {string|null} [workingShiftId] WorkingShiftRequestCreatedEvent workingShiftId
                         * @property {pro.megakit.amelia.operator.DriverRequest.IActiveDriverRequest|null} [driverRequest] WorkingShiftRequestCreatedEvent driverRequest
                         */

                        /**
                         * Constructs a new WorkingShiftRequestCreatedEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @classdesc Represents a WorkingShiftRequestCreatedEvent.
                         * @implements IWorkingShiftRequestCreatedEvent
                         * @constructor
                         * @param {pro.megakit.amelia.operator.EventProto.IWorkingShiftRequestCreatedEvent=} [properties] Properties to set
                         */
                        function WorkingShiftRequestCreatedEvent(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * WorkingShiftRequestCreatedEvent workingShiftId.
                         * @member {string} workingShiftId
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent
                         * @instance
                         */
                        WorkingShiftRequestCreatedEvent.prototype.workingShiftId = "";

                        /**
                         * WorkingShiftRequestCreatedEvent driverRequest.
                         * @member {pro.megakit.amelia.operator.DriverRequest.IActiveDriverRequest|null|undefined} driverRequest
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent
                         * @instance
                         */
                        WorkingShiftRequestCreatedEvent.prototype.driverRequest = null;

                        /**
                         * Creates a new WorkingShiftRequestCreatedEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IWorkingShiftRequestCreatedEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent} WorkingShiftRequestCreatedEvent instance
                         */
                        WorkingShiftRequestCreatedEvent.create = function create(properties) {
                            return new WorkingShiftRequestCreatedEvent(properties);
                        };

                        /**
                         * Encodes the specified WorkingShiftRequestCreatedEvent message. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IWorkingShiftRequestCreatedEvent} message WorkingShiftRequestCreatedEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        WorkingShiftRequestCreatedEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.workingShiftId != null && message.hasOwnProperty("workingShiftId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.workingShiftId);
                            if (message.driverRequest != null && message.hasOwnProperty("driverRequest"))
                                $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.encode(message.driverRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified WorkingShiftRequestCreatedEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IWorkingShiftRequestCreatedEvent} message WorkingShiftRequestCreatedEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        WorkingShiftRequestCreatedEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a WorkingShiftRequestCreatedEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent} WorkingShiftRequestCreatedEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        WorkingShiftRequestCreatedEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.workingShiftId = reader.string();
                                    break;
                                case 2:
                                    message.driverRequest = $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a WorkingShiftRequestCreatedEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent} WorkingShiftRequestCreatedEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        WorkingShiftRequestCreatedEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a WorkingShiftRequestCreatedEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        WorkingShiftRequestCreatedEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.workingShiftId != null && message.hasOwnProperty("workingShiftId"))
                                if (!$util.isString(message.workingShiftId))
                                    return "workingShiftId: string expected";
                            if (message.driverRequest != null && message.hasOwnProperty("driverRequest")) {
                                var error = $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.verify(message.driverRequest);
                                if (error)
                                    return "driverRequest." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a WorkingShiftRequestCreatedEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent} WorkingShiftRequestCreatedEvent
                         */
                        WorkingShiftRequestCreatedEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent();
                            if (object.workingShiftId != null)
                                message.workingShiftId = String(object.workingShiftId);
                            if (object.driverRequest != null) {
                                if (typeof object.driverRequest !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent.driverRequest: object expected");
                                message.driverRequest = $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.fromObject(object.driverRequest);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a WorkingShiftRequestCreatedEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent} message WorkingShiftRequestCreatedEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        WorkingShiftRequestCreatedEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.workingShiftId = "";
                                object.driverRequest = null;
                            }
                            if (message.workingShiftId != null && message.hasOwnProperty("workingShiftId"))
                                object.workingShiftId = message.workingShiftId;
                            if (message.driverRequest != null && message.hasOwnProperty("driverRequest"))
                                object.driverRequest = $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.toObject(message.driverRequest, options);
                            return object;
                        };

                        /**
                         * Converts this WorkingShiftRequestCreatedEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftRequestCreatedEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        WorkingShiftRequestCreatedEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return WorkingShiftRequestCreatedEvent;
                    })();

                    EventProto.WorkingShiftEvent = (function() {

                        /**
                         * Properties of a WorkingShiftEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @interface IWorkingShiftEvent
                         * @property {string|null} [id] WorkingShiftEvent id
                         */

                        /**
                         * Constructs a new WorkingShiftEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @classdesc Represents a WorkingShiftEvent.
                         * @implements IWorkingShiftEvent
                         * @constructor
                         * @param {pro.megakit.amelia.operator.EventProto.IWorkingShiftEvent=} [properties] Properties to set
                         */
                        function WorkingShiftEvent(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * WorkingShiftEvent id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftEvent
                         * @instance
                         */
                        WorkingShiftEvent.prototype.id = "";

                        /**
                         * Creates a new WorkingShiftEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IWorkingShiftEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.EventProto.WorkingShiftEvent} WorkingShiftEvent instance
                         */
                        WorkingShiftEvent.create = function create(properties) {
                            return new WorkingShiftEvent(properties);
                        };

                        /**
                         * Encodes the specified WorkingShiftEvent message. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.WorkingShiftEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IWorkingShiftEvent} message WorkingShiftEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        WorkingShiftEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified WorkingShiftEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.WorkingShiftEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IWorkingShiftEvent} message WorkingShiftEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        WorkingShiftEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a WorkingShiftEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.EventProto.WorkingShiftEvent} WorkingShiftEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        WorkingShiftEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.EventProto.WorkingShiftEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a WorkingShiftEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.EventProto.WorkingShiftEvent} WorkingShiftEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        WorkingShiftEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a WorkingShiftEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        WorkingShiftEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a WorkingShiftEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.EventProto.WorkingShiftEvent} WorkingShiftEvent
                         */
                        WorkingShiftEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.EventProto.WorkingShiftEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.EventProto.WorkingShiftEvent();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a WorkingShiftEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.WorkingShiftEvent} message WorkingShiftEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        WorkingShiftEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this WorkingShiftEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        WorkingShiftEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return WorkingShiftEvent;
                    })();

                    EventProto.VehicleWashingEvent = (function() {

                        /**
                         * Properties of a VehicleWashingEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @interface IVehicleWashingEvent
                         * @property {string|null} [id] VehicleWashingEvent id
                         */

                        /**
                         * Constructs a new VehicleWashingEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @classdesc Represents a VehicleWashingEvent.
                         * @implements IVehicleWashingEvent
                         * @constructor
                         * @param {pro.megakit.amelia.operator.EventProto.IVehicleWashingEvent=} [properties] Properties to set
                         */
                        function VehicleWashingEvent(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * VehicleWashingEvent id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingEvent
                         * @instance
                         */
                        VehicleWashingEvent.prototype.id = "";

                        /**
                         * Creates a new VehicleWashingEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IVehicleWashingEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.EventProto.VehicleWashingEvent} VehicleWashingEvent instance
                         */
                        VehicleWashingEvent.create = function create(properties) {
                            return new VehicleWashingEvent(properties);
                        };

                        /**
                         * Encodes the specified VehicleWashingEvent message. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.VehicleWashingEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IVehicleWashingEvent} message VehicleWashingEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VehicleWashingEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified VehicleWashingEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.VehicleWashingEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IVehicleWashingEvent} message VehicleWashingEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VehicleWashingEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a VehicleWashingEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.EventProto.VehicleWashingEvent} VehicleWashingEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VehicleWashingEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.EventProto.VehicleWashingEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a VehicleWashingEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.EventProto.VehicleWashingEvent} VehicleWashingEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VehicleWashingEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a VehicleWashingEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        VehicleWashingEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a VehicleWashingEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.EventProto.VehicleWashingEvent} VehicleWashingEvent
                         */
                        VehicleWashingEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.EventProto.VehicleWashingEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.EventProto.VehicleWashingEvent();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a VehicleWashingEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.VehicleWashingEvent} message VehicleWashingEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        VehicleWashingEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this VehicleWashingEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        VehicleWashingEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return VehicleWashingEvent;
                    })();

                    EventProto.VehicleWashingFinishedEvent = (function() {

                        /**
                         * Properties of a VehicleWashingFinishedEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @interface IVehicleWashingFinishedEvent
                         * @property {string|null} [id] VehicleWashingFinishedEvent id
                         * @property {number|null} [amount] VehicleWashingFinishedEvent amount
                         * @property {number|null} [duration] VehicleWashingFinishedEvent duration
                         * @property {google.protobuf.ITimestamp|null} [finishedAt] VehicleWashingFinishedEvent finishedAt
                         */

                        /**
                         * Constructs a new VehicleWashingFinishedEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @classdesc Represents a VehicleWashingFinishedEvent.
                         * @implements IVehicleWashingFinishedEvent
                         * @constructor
                         * @param {pro.megakit.amelia.operator.EventProto.IVehicleWashingFinishedEvent=} [properties] Properties to set
                         */
                        function VehicleWashingFinishedEvent(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * VehicleWashingFinishedEvent id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent
                         * @instance
                         */
                        VehicleWashingFinishedEvent.prototype.id = "";

                        /**
                         * VehicleWashingFinishedEvent amount.
                         * @member {number} amount
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent
                         * @instance
                         */
                        VehicleWashingFinishedEvent.prototype.amount = 0;

                        /**
                         * VehicleWashingFinishedEvent duration.
                         * @member {number} duration
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent
                         * @instance
                         */
                        VehicleWashingFinishedEvent.prototype.duration = 0;

                        /**
                         * VehicleWashingFinishedEvent finishedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} finishedAt
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent
                         * @instance
                         */
                        VehicleWashingFinishedEvent.prototype.finishedAt = null;

                        /**
                         * Creates a new VehicleWashingFinishedEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IVehicleWashingFinishedEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent} VehicleWashingFinishedEvent instance
                         */
                        VehicleWashingFinishedEvent.create = function create(properties) {
                            return new VehicleWashingFinishedEvent(properties);
                        };

                        /**
                         * Encodes the specified VehicleWashingFinishedEvent message. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IVehicleWashingFinishedEvent} message VehicleWashingFinishedEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VehicleWashingFinishedEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                writer.uint32(/* id 2, wireType 1 =*/17).double(message.amount);
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.duration);
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt"))
                                $root.google.protobuf.Timestamp.encode(message.finishedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified VehicleWashingFinishedEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IVehicleWashingFinishedEvent} message VehicleWashingFinishedEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VehicleWashingFinishedEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a VehicleWashingFinishedEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent} VehicleWashingFinishedEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VehicleWashingFinishedEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.amount = reader.double();
                                    break;
                                case 3:
                                    message.duration = reader.uint32();
                                    break;
                                case 4:
                                    message.finishedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a VehicleWashingFinishedEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent} VehicleWashingFinishedEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VehicleWashingFinishedEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a VehicleWashingFinishedEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        VehicleWashingFinishedEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                if (typeof message.amount !== "number")
                                    return "amount: number expected";
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                if (!$util.isInteger(message.duration))
                                    return "duration: integer expected";
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.finishedAt);
                                if (error)
                                    return "finishedAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a VehicleWashingFinishedEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent} VehicleWashingFinishedEvent
                         */
                        VehicleWashingFinishedEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.amount != null)
                                message.amount = Number(object.amount);
                            if (object.duration != null)
                                message.duration = object.duration >>> 0;
                            if (object.finishedAt != null) {
                                if (typeof object.finishedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent.finishedAt: object expected");
                                message.finishedAt = $root.google.protobuf.Timestamp.fromObject(object.finishedAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a VehicleWashingFinishedEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent} message VehicleWashingFinishedEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        VehicleWashingFinishedEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.amount = 0;
                                object.duration = 0;
                                object.finishedAt = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                object.duration = message.duration;
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt"))
                                object.finishedAt = $root.google.protobuf.Timestamp.toObject(message.finishedAt, options);
                            return object;
                        };

                        /**
                         * Converts this VehicleWashingFinishedEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleWashingFinishedEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        VehicleWashingFinishedEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return VehicleWashingFinishedEvent;
                    })();

                    EventProto.DriverRestEvent = (function() {

                        /**
                         * Properties of a DriverRestEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @interface IDriverRestEvent
                         * @property {string|null} [id] DriverRestEvent id
                         */

                        /**
                         * Constructs a new DriverRestEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @classdesc Represents a DriverRestEvent.
                         * @implements IDriverRestEvent
                         * @constructor
                         * @param {pro.megakit.amelia.operator.EventProto.IDriverRestEvent=} [properties] Properties to set
                         */
                        function DriverRestEvent(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DriverRestEvent id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestEvent
                         * @instance
                         */
                        DriverRestEvent.prototype.id = "";

                        /**
                         * Creates a new DriverRestEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IDriverRestEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.EventProto.DriverRestEvent} DriverRestEvent instance
                         */
                        DriverRestEvent.create = function create(properties) {
                            return new DriverRestEvent(properties);
                        };

                        /**
                         * Encodes the specified DriverRestEvent message. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.DriverRestEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IDriverRestEvent} message DriverRestEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverRestEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified DriverRestEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.DriverRestEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IDriverRestEvent} message DriverRestEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverRestEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DriverRestEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.EventProto.DriverRestEvent} DriverRestEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverRestEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.EventProto.DriverRestEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DriverRestEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.EventProto.DriverRestEvent} DriverRestEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverRestEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DriverRestEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DriverRestEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a DriverRestEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.EventProto.DriverRestEvent} DriverRestEvent
                         */
                        DriverRestEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.EventProto.DriverRestEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.EventProto.DriverRestEvent();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a DriverRestEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.DriverRestEvent} message DriverRestEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DriverRestEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this DriverRestEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DriverRestEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DriverRestEvent;
                    })();

                    EventProto.DriverRestFinishedEvent = (function() {

                        /**
                         * Properties of a DriverRestFinishedEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @interface IDriverRestFinishedEvent
                         * @property {string|null} [id] DriverRestFinishedEvent id
                         * @property {number|null} [totalDuration] DriverRestFinishedEvent totalDuration
                         * @property {google.protobuf.ITimestamp|null} [finishedAt] DriverRestFinishedEvent finishedAt
                         */

                        /**
                         * Constructs a new DriverRestFinishedEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @classdesc Represents a DriverRestFinishedEvent.
                         * @implements IDriverRestFinishedEvent
                         * @constructor
                         * @param {pro.megakit.amelia.operator.EventProto.IDriverRestFinishedEvent=} [properties] Properties to set
                         */
                        function DriverRestFinishedEvent(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DriverRestFinishedEvent id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent
                         * @instance
                         */
                        DriverRestFinishedEvent.prototype.id = "";

                        /**
                         * DriverRestFinishedEvent totalDuration.
                         * @member {number} totalDuration
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent
                         * @instance
                         */
                        DriverRestFinishedEvent.prototype.totalDuration = 0;

                        /**
                         * DriverRestFinishedEvent finishedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} finishedAt
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent
                         * @instance
                         */
                        DriverRestFinishedEvent.prototype.finishedAt = null;

                        /**
                         * Creates a new DriverRestFinishedEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IDriverRestFinishedEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent} DriverRestFinishedEvent instance
                         */
                        DriverRestFinishedEvent.create = function create(properties) {
                            return new DriverRestFinishedEvent(properties);
                        };

                        /**
                         * Encodes the specified DriverRestFinishedEvent message. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IDriverRestFinishedEvent} message DriverRestFinishedEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverRestFinishedEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.totalDuration != null && message.hasOwnProperty("totalDuration"))
                                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.totalDuration);
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt"))
                                $root.google.protobuf.Timestamp.encode(message.finishedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified DriverRestFinishedEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IDriverRestFinishedEvent} message DriverRestFinishedEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverRestFinishedEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DriverRestFinishedEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent} DriverRestFinishedEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverRestFinishedEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.totalDuration = reader.uint32();
                                    break;
                                case 3:
                                    message.finishedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DriverRestFinishedEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent} DriverRestFinishedEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverRestFinishedEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DriverRestFinishedEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DriverRestFinishedEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.totalDuration != null && message.hasOwnProperty("totalDuration"))
                                if (!$util.isInteger(message.totalDuration))
                                    return "totalDuration: integer expected";
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.finishedAt);
                                if (error)
                                    return "finishedAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a DriverRestFinishedEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent} DriverRestFinishedEvent
                         */
                        DriverRestFinishedEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.totalDuration != null)
                                message.totalDuration = object.totalDuration >>> 0;
                            if (object.finishedAt != null) {
                                if (typeof object.finishedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent.finishedAt: object expected");
                                message.finishedAt = $root.google.protobuf.Timestamp.fromObject(object.finishedAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a DriverRestFinishedEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent} message DriverRestFinishedEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DriverRestFinishedEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.totalDuration = 0;
                                object.finishedAt = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.totalDuration != null && message.hasOwnProperty("totalDuration"))
                                object.totalDuration = message.totalDuration;
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt"))
                                object.finishedAt = $root.google.protobuf.Timestamp.toObject(message.finishedAt, options);
                            return object;
                        };

                        /**
                         * Converts this DriverRestFinishedEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverRestFinishedEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DriverRestFinishedEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DriverRestFinishedEvent;
                    })();

                    EventProto.VehicleRefillFinishedEvent = (function() {

                        /**
                         * Properties of a VehicleRefillFinishedEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @interface IVehicleRefillFinishedEvent
                         * @property {string|null} [requestId] VehicleRefillFinishedEvent requestId
                         */

                        /**
                         * Constructs a new VehicleRefillFinishedEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @classdesc Represents a VehicleRefillFinishedEvent.
                         * @implements IVehicleRefillFinishedEvent
                         * @constructor
                         * @param {pro.megakit.amelia.operator.EventProto.IVehicleRefillFinishedEvent=} [properties] Properties to set
                         */
                        function VehicleRefillFinishedEvent(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * VehicleRefillFinishedEvent requestId.
                         * @member {string} requestId
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent
                         * @instance
                         */
                        VehicleRefillFinishedEvent.prototype.requestId = "";

                        /**
                         * Creates a new VehicleRefillFinishedEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IVehicleRefillFinishedEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent} VehicleRefillFinishedEvent instance
                         */
                        VehicleRefillFinishedEvent.create = function create(properties) {
                            return new VehicleRefillFinishedEvent(properties);
                        };

                        /**
                         * Encodes the specified VehicleRefillFinishedEvent message. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IVehicleRefillFinishedEvent} message VehicleRefillFinishedEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VehicleRefillFinishedEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.requestId);
                            return writer;
                        };

                        /**
                         * Encodes the specified VehicleRefillFinishedEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IVehicleRefillFinishedEvent} message VehicleRefillFinishedEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VehicleRefillFinishedEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a VehicleRefillFinishedEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent} VehicleRefillFinishedEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VehicleRefillFinishedEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.requestId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a VehicleRefillFinishedEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent} VehicleRefillFinishedEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VehicleRefillFinishedEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a VehicleRefillFinishedEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        VehicleRefillFinishedEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                if (!$util.isString(message.requestId))
                                    return "requestId: string expected";
                            return null;
                        };

                        /**
                         * Creates a VehicleRefillFinishedEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent} VehicleRefillFinishedEvent
                         */
                        VehicleRefillFinishedEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent();
                            if (object.requestId != null)
                                message.requestId = String(object.requestId);
                            return message;
                        };

                        /**
                         * Creates a plain object from a VehicleRefillFinishedEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent} message VehicleRefillFinishedEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        VehicleRefillFinishedEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.requestId = "";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                object.requestId = message.requestId;
                            return object;
                        };

                        /**
                         * Converts this VehicleRefillFinishedEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.EventProto.VehicleRefillFinishedEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        VehicleRefillFinishedEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return VehicleRefillFinishedEvent;
                    })();

                    EventProto.WorkingShiftReceivedEvent = (function() {

                        /**
                         * Properties of a WorkingShiftReceivedEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @interface IWorkingShiftReceivedEvent
                         * @property {pro.megakit.amelia.operator.WorkingShift.IActiveWorkingShift|null} [workingShift] WorkingShiftReceivedEvent workingShift
                         */

                        /**
                         * Constructs a new WorkingShiftReceivedEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @classdesc Represents a WorkingShiftReceivedEvent.
                         * @implements IWorkingShiftReceivedEvent
                         * @constructor
                         * @param {pro.megakit.amelia.operator.EventProto.IWorkingShiftReceivedEvent=} [properties] Properties to set
                         */
                        function WorkingShiftReceivedEvent(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * WorkingShiftReceivedEvent workingShift.
                         * @member {pro.megakit.amelia.operator.WorkingShift.IActiveWorkingShift|null|undefined} workingShift
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent
                         * @instance
                         */
                        WorkingShiftReceivedEvent.prototype.workingShift = null;

                        /**
                         * Creates a new WorkingShiftReceivedEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IWorkingShiftReceivedEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent} WorkingShiftReceivedEvent instance
                         */
                        WorkingShiftReceivedEvent.create = function create(properties) {
                            return new WorkingShiftReceivedEvent(properties);
                        };

                        /**
                         * Encodes the specified WorkingShiftReceivedEvent message. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IWorkingShiftReceivedEvent} message WorkingShiftReceivedEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        WorkingShiftReceivedEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.workingShift != null && message.hasOwnProperty("workingShift"))
                                $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.encode(message.workingShift, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified WorkingShiftReceivedEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IWorkingShiftReceivedEvent} message WorkingShiftReceivedEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        WorkingShiftReceivedEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a WorkingShiftReceivedEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent} WorkingShiftReceivedEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        WorkingShiftReceivedEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.workingShift = $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a WorkingShiftReceivedEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent} WorkingShiftReceivedEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        WorkingShiftReceivedEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a WorkingShiftReceivedEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        WorkingShiftReceivedEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.workingShift != null && message.hasOwnProperty("workingShift")) {
                                var error = $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.verify(message.workingShift);
                                if (error)
                                    return "workingShift." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a WorkingShiftReceivedEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent} WorkingShiftReceivedEvent
                         */
                        WorkingShiftReceivedEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent();
                            if (object.workingShift != null) {
                                if (typeof object.workingShift !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent.workingShift: object expected");
                                message.workingShift = $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.fromObject(object.workingShift);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a WorkingShiftReceivedEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent} message WorkingShiftReceivedEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        WorkingShiftReceivedEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.workingShift = null;
                            if (message.workingShift != null && message.hasOwnProperty("workingShift"))
                                object.workingShift = $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.toObject(message.workingShift, options);
                            return object;
                        };

                        /**
                         * Converts this WorkingShiftReceivedEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.EventProto.WorkingShiftReceivedEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        WorkingShiftReceivedEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return WorkingShiftReceivedEvent;
                    })();

                    EventProto.DriverPauseEvent = (function() {

                        /**
                         * Properties of a DriverPauseEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @interface IDriverPauseEvent
                         * @property {string|null} [id] DriverPauseEvent id
                         */

                        /**
                         * Constructs a new DriverPauseEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @classdesc Represents a DriverPauseEvent.
                         * @implements IDriverPauseEvent
                         * @constructor
                         * @param {pro.megakit.amelia.operator.EventProto.IDriverPauseEvent=} [properties] Properties to set
                         */
                        function DriverPauseEvent(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DriverPauseEvent id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseEvent
                         * @instance
                         */
                        DriverPauseEvent.prototype.id = "";

                        /**
                         * Creates a new DriverPauseEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IDriverPauseEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.EventProto.DriverPauseEvent} DriverPauseEvent instance
                         */
                        DriverPauseEvent.create = function create(properties) {
                            return new DriverPauseEvent(properties);
                        };

                        /**
                         * Encodes the specified DriverPauseEvent message. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.DriverPauseEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IDriverPauseEvent} message DriverPauseEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverPauseEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified DriverPauseEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.DriverPauseEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IDriverPauseEvent} message DriverPauseEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverPauseEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DriverPauseEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.EventProto.DriverPauseEvent} DriverPauseEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverPauseEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.EventProto.DriverPauseEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DriverPauseEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.EventProto.DriverPauseEvent} DriverPauseEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverPauseEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DriverPauseEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DriverPauseEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a DriverPauseEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.EventProto.DriverPauseEvent} DriverPauseEvent
                         */
                        DriverPauseEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.EventProto.DriverPauseEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.EventProto.DriverPauseEvent();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a DriverPauseEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.DriverPauseEvent} message DriverPauseEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DriverPauseEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this DriverPauseEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DriverPauseEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DriverPauseEvent;
                    })();

                    EventProto.DriverPauseFinishedEvent = (function() {

                        /**
                         * Properties of a DriverPauseFinishedEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @interface IDriverPauseFinishedEvent
                         * @property {string|null} [id] DriverPauseFinishedEvent id
                         * @property {number|null} [duration] DriverPauseFinishedEvent duration
                         * @property {google.protobuf.ITimestamp|null} [finishedAt] DriverPauseFinishedEvent finishedAt
                         */

                        /**
                         * Constructs a new DriverPauseFinishedEvent.
                         * @memberof pro.megakit.amelia.operator.EventProto
                         * @classdesc Represents a DriverPauseFinishedEvent.
                         * @implements IDriverPauseFinishedEvent
                         * @constructor
                         * @param {pro.megakit.amelia.operator.EventProto.IDriverPauseFinishedEvent=} [properties] Properties to set
                         */
                        function DriverPauseFinishedEvent(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DriverPauseFinishedEvent id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent
                         * @instance
                         */
                        DriverPauseFinishedEvent.prototype.id = "";

                        /**
                         * DriverPauseFinishedEvent duration.
                         * @member {number} duration
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent
                         * @instance
                         */
                        DriverPauseFinishedEvent.prototype.duration = 0;

                        /**
                         * DriverPauseFinishedEvent finishedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} finishedAt
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent
                         * @instance
                         */
                        DriverPauseFinishedEvent.prototype.finishedAt = null;

                        /**
                         * Creates a new DriverPauseFinishedEvent instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IDriverPauseFinishedEvent=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent} DriverPauseFinishedEvent instance
                         */
                        DriverPauseFinishedEvent.create = function create(properties) {
                            return new DriverPauseFinishedEvent(properties);
                        };

                        /**
                         * Encodes the specified DriverPauseFinishedEvent message. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IDriverPauseFinishedEvent} message DriverPauseFinishedEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverPauseFinishedEvent.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.duration);
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt"))
                                $root.google.protobuf.Timestamp.encode(message.finishedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified DriverPauseFinishedEvent message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.IDriverPauseFinishedEvent} message DriverPauseFinishedEvent message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DriverPauseFinishedEvent.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DriverPauseFinishedEvent message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent} DriverPauseFinishedEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverPauseFinishedEvent.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.duration = reader.uint32();
                                    break;
                                case 3:
                                    message.finishedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DriverPauseFinishedEvent message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent} DriverPauseFinishedEvent
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DriverPauseFinishedEvent.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DriverPauseFinishedEvent message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DriverPauseFinishedEvent.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                if (!$util.isInteger(message.duration))
                                    return "duration: integer expected";
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.finishedAt);
                                if (error)
                                    return "finishedAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a DriverPauseFinishedEvent message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent} DriverPauseFinishedEvent
                         */
                        DriverPauseFinishedEvent.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.duration != null)
                                message.duration = object.duration >>> 0;
                            if (object.finishedAt != null) {
                                if (typeof object.finishedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent.finishedAt: object expected");
                                message.finishedAt = $root.google.protobuf.Timestamp.fromObject(object.finishedAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a DriverPauseFinishedEvent message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent
                         * @static
                         * @param {pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent} message DriverPauseFinishedEvent
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DriverPauseFinishedEvent.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.duration = 0;
                                object.finishedAt = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                object.duration = message.duration;
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt"))
                                object.finishedAt = $root.google.protobuf.Timestamp.toObject(message.finishedAt, options);
                            return object;
                        };

                        /**
                         * Converts this DriverPauseFinishedEvent to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.EventProto.DriverPauseFinishedEvent
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DriverPauseFinishedEvent.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DriverPauseFinishedEvent;
                    })();

                    return EventProto;
                })();

                operator.WorkingShift = (function() {

                    /**
                     * Properties of a WorkingShift.
                     * @memberof pro.megakit.amelia.operator
                     * @interface IWorkingShift
                     */

                    /**
                     * Constructs a new WorkingShift.
                     * @memberof pro.megakit.amelia.operator
                     * @classdesc Represents a WorkingShift.
                     * @implements IWorkingShift
                     * @constructor
                     * @param {pro.megakit.amelia.operator.IWorkingShift=} [properties] Properties to set
                     */
                    function WorkingShift(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new WorkingShift instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.operator.WorkingShift
                     * @static
                     * @param {pro.megakit.amelia.operator.IWorkingShift=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.operator.WorkingShift} WorkingShift instance
                     */
                    WorkingShift.create = function create(properties) {
                        return new WorkingShift(properties);
                    };

                    /**
                     * Encodes the specified WorkingShift message. Does not implicitly {@link pro.megakit.amelia.operator.WorkingShift.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.operator.WorkingShift
                     * @static
                     * @param {pro.megakit.amelia.operator.IWorkingShift} message WorkingShift message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WorkingShift.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified WorkingShift message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.WorkingShift.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.operator.WorkingShift
                     * @static
                     * @param {pro.megakit.amelia.operator.IWorkingShift} message WorkingShift message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WorkingShift.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a WorkingShift message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.operator.WorkingShift
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.operator.WorkingShift} WorkingShift
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WorkingShift.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.WorkingShift();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a WorkingShift message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.operator.WorkingShift
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.operator.WorkingShift} WorkingShift
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WorkingShift.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a WorkingShift message.
                     * @function verify
                     * @memberof pro.megakit.amelia.operator.WorkingShift
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    WorkingShift.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a WorkingShift message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.operator.WorkingShift
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.operator.WorkingShift} WorkingShift
                     */
                    WorkingShift.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.operator.WorkingShift)
                            return object;
                        return new $root.pro.megakit.amelia.operator.WorkingShift();
                    };

                    /**
                     * Creates a plain object from a WorkingShift message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.operator.WorkingShift
                     * @static
                     * @param {pro.megakit.amelia.operator.WorkingShift} message WorkingShift
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    WorkingShift.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this WorkingShift to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.operator.WorkingShift
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    WorkingShift.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    WorkingShift.ActiveWorkingShift = (function() {

                        /**
                         * Properties of an ActiveWorkingShift.
                         * @memberof pro.megakit.amelia.operator.WorkingShift
                         * @interface IActiveWorkingShift
                         * @property {string|null} [id] ActiveWorkingShift id
                         * @property {string|null} [cabId] ActiveWorkingShift cabId
                         * @property {string|null} [status] ActiveWorkingShift status
                         * @property {number|Long|null} [startMileage] ActiveWorkingShift startMileage
                         * @property {google.protobuf.ITimestamp|null} [startedAt] ActiveWorkingShift startedAt
                         * @property {number|null} [tripsCount] ActiveWorkingShift tripsCount
                         * @property {number|null} [tripsAmount] ActiveWorkingShift tripsAmount
                         * @property {boolean|null} [isParkingForceAllowed] ActiveWorkingShift isParkingForceAllowed
                         */

                        /**
                         * Constructs a new ActiveWorkingShift.
                         * @memberof pro.megakit.amelia.operator.WorkingShift
                         * @classdesc Represents an ActiveWorkingShift.
                         * @implements IActiveWorkingShift
                         * @constructor
                         * @param {pro.megakit.amelia.operator.WorkingShift.IActiveWorkingShift=} [properties] Properties to set
                         */
                        function ActiveWorkingShift(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ActiveWorkingShift id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @instance
                         */
                        ActiveWorkingShift.prototype.id = "";

                        /**
                         * ActiveWorkingShift cabId.
                         * @member {string} cabId
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @instance
                         */
                        ActiveWorkingShift.prototype.cabId = "";

                        /**
                         * ActiveWorkingShift status.
                         * @member {string} status
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @instance
                         */
                        ActiveWorkingShift.prototype.status = "";

                        /**
                         * ActiveWorkingShift startMileage.
                         * @member {number|Long} startMileage
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @instance
                         */
                        ActiveWorkingShift.prototype.startMileage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * ActiveWorkingShift startedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} startedAt
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @instance
                         */
                        ActiveWorkingShift.prototype.startedAt = null;

                        /**
                         * ActiveWorkingShift tripsCount.
                         * @member {number} tripsCount
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @instance
                         */
                        ActiveWorkingShift.prototype.tripsCount = 0;

                        /**
                         * ActiveWorkingShift tripsAmount.
                         * @member {number} tripsAmount
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @instance
                         */
                        ActiveWorkingShift.prototype.tripsAmount = 0;

                        /**
                         * ActiveWorkingShift isParkingForceAllowed.
                         * @member {boolean} isParkingForceAllowed
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @instance
                         */
                        ActiveWorkingShift.prototype.isParkingForceAllowed = false;

                        /**
                         * Creates a new ActiveWorkingShift instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @static
                         * @param {pro.megakit.amelia.operator.WorkingShift.IActiveWorkingShift=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift} ActiveWorkingShift instance
                         */
                        ActiveWorkingShift.create = function create(properties) {
                            return new ActiveWorkingShift(properties);
                        };

                        /**
                         * Encodes the specified ActiveWorkingShift message. Does not implicitly {@link pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @static
                         * @param {pro.megakit.amelia.operator.WorkingShift.IActiveWorkingShift} message ActiveWorkingShift message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveWorkingShift.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.cabId != null && message.hasOwnProperty("cabId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.cabId);
                            if (message.status != null && message.hasOwnProperty("status"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.status);
                            if (message.startMileage != null && message.hasOwnProperty("startMileage"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.startMileage);
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                $root.google.protobuf.Timestamp.encode(message.startedAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            if (message.tripsCount != null && message.hasOwnProperty("tripsCount"))
                                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.tripsCount);
                            if (message.tripsAmount != null && message.hasOwnProperty("tripsAmount"))
                                writer.uint32(/* id 7, wireType 1 =*/57).double(message.tripsAmount);
                            if (message.isParkingForceAllowed != null && message.hasOwnProperty("isParkingForceAllowed"))
                                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isParkingForceAllowed);
                            return writer;
                        };

                        /**
                         * Encodes the specified ActiveWorkingShift message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @static
                         * @param {pro.megakit.amelia.operator.WorkingShift.IActiveWorkingShift} message ActiveWorkingShift message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveWorkingShift.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an ActiveWorkingShift message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift} ActiveWorkingShift
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveWorkingShift.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.cabId = reader.string();
                                    break;
                                case 3:
                                    message.status = reader.string();
                                    break;
                                case 4:
                                    message.startMileage = reader.int64();
                                    break;
                                case 5:
                                    message.startedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                case 6:
                                    message.tripsCount = reader.uint32();
                                    break;
                                case 7:
                                    message.tripsAmount = reader.double();
                                    break;
                                case 8:
                                    message.isParkingForceAllowed = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an ActiveWorkingShift message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift} ActiveWorkingShift
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveWorkingShift.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an ActiveWorkingShift message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ActiveWorkingShift.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.cabId != null && message.hasOwnProperty("cabId"))
                                if (!$util.isString(message.cabId))
                                    return "cabId: string expected";
                            if (message.status != null && message.hasOwnProperty("status"))
                                if (!$util.isString(message.status))
                                    return "status: string expected";
                            if (message.startMileage != null && message.hasOwnProperty("startMileage"))
                                if (!$util.isInteger(message.startMileage) && !(message.startMileage && $util.isInteger(message.startMileage.low) && $util.isInteger(message.startMileage.high)))
                                    return "startMileage: integer|Long expected";
                            if (message.startedAt != null && message.hasOwnProperty("startedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.startedAt);
                                if (error)
                                    return "startedAt." + error;
                            }
                            if (message.tripsCount != null && message.hasOwnProperty("tripsCount"))
                                if (!$util.isInteger(message.tripsCount))
                                    return "tripsCount: integer expected";
                            if (message.tripsAmount != null && message.hasOwnProperty("tripsAmount"))
                                if (typeof message.tripsAmount !== "number")
                                    return "tripsAmount: number expected";
                            if (message.isParkingForceAllowed != null && message.hasOwnProperty("isParkingForceAllowed"))
                                if (typeof message.isParkingForceAllowed !== "boolean")
                                    return "isParkingForceAllowed: boolean expected";
                            return null;
                        };

                        /**
                         * Creates an ActiveWorkingShift message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift} ActiveWorkingShift
                         */
                        ActiveWorkingShift.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.cabId != null)
                                message.cabId = String(object.cabId);
                            if (object.status != null)
                                message.status = String(object.status);
                            if (object.startMileage != null)
                                if ($util.Long)
                                    (message.startMileage = $util.Long.fromValue(object.startMileage)).unsigned = false;
                                else if (typeof object.startMileage === "string")
                                    message.startMileage = parseInt(object.startMileage, 10);
                                else if (typeof object.startMileage === "number")
                                    message.startMileage = object.startMileage;
                                else if (typeof object.startMileage === "object")
                                    message.startMileage = new $util.LongBits(object.startMileage.low >>> 0, object.startMileage.high >>> 0).toNumber();
                            if (object.startedAt != null) {
                                if (typeof object.startedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift.startedAt: object expected");
                                message.startedAt = $root.google.protobuf.Timestamp.fromObject(object.startedAt);
                            }
                            if (object.tripsCount != null)
                                message.tripsCount = object.tripsCount >>> 0;
                            if (object.tripsAmount != null)
                                message.tripsAmount = Number(object.tripsAmount);
                            if (object.isParkingForceAllowed != null)
                                message.isParkingForceAllowed = Boolean(object.isParkingForceAllowed);
                            return message;
                        };

                        /**
                         * Creates a plain object from an ActiveWorkingShift message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @static
                         * @param {pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift} message ActiveWorkingShift
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ActiveWorkingShift.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.cabId = "";
                                object.status = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.startMileage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.startMileage = options.longs === String ? "0" : 0;
                                object.startedAt = null;
                                object.tripsCount = 0;
                                object.tripsAmount = 0;
                                object.isParkingForceAllowed = false;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.cabId != null && message.hasOwnProperty("cabId"))
                                object.cabId = message.cabId;
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = message.status;
                            if (message.startMileage != null && message.hasOwnProperty("startMileage"))
                                if (typeof message.startMileage === "number")
                                    object.startMileage = options.longs === String ? String(message.startMileage) : message.startMileage;
                                else
                                    object.startMileage = options.longs === String ? $util.Long.prototype.toString.call(message.startMileage) : options.longs === Number ? new $util.LongBits(message.startMileage.low >>> 0, message.startMileage.high >>> 0).toNumber() : message.startMileage;
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                object.startedAt = $root.google.protobuf.Timestamp.toObject(message.startedAt, options);
                            if (message.tripsCount != null && message.hasOwnProperty("tripsCount"))
                                object.tripsCount = message.tripsCount;
                            if (message.tripsAmount != null && message.hasOwnProperty("tripsAmount"))
                                object.tripsAmount = options.json && !isFinite(message.tripsAmount) ? String(message.tripsAmount) : message.tripsAmount;
                            if (message.isParkingForceAllowed != null && message.hasOwnProperty("isParkingForceAllowed"))
                                object.isParkingForceAllowed = message.isParkingForceAllowed;
                            return object;
                        };

                        /**
                         * Converts this ActiveWorkingShift to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.WorkingShift.ActiveWorkingShift
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ActiveWorkingShift.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ActiveWorkingShift;
                    })();

                    WorkingShift.StartWorkingShiftRequest = (function() {

                        /**
                         * Properties of a StartWorkingShiftRequest.
                         * @memberof pro.megakit.amelia.operator.WorkingShift
                         * @interface IStartWorkingShiftRequest
                         * @property {string|null} [cabId] StartWorkingShiftRequest cabId
                         * @property {number|Long|null} [startMileage] StartWorkingShiftRequest startMileage
                         */

                        /**
                         * Constructs a new StartWorkingShiftRequest.
                         * @memberof pro.megakit.amelia.operator.WorkingShift
                         * @classdesc Represents a StartWorkingShiftRequest.
                         * @implements IStartWorkingShiftRequest
                         * @constructor
                         * @param {pro.megakit.amelia.operator.WorkingShift.IStartWorkingShiftRequest=} [properties] Properties to set
                         */
                        function StartWorkingShiftRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StartWorkingShiftRequest cabId.
                         * @member {string} cabId
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest
                         * @instance
                         */
                        StartWorkingShiftRequest.prototype.cabId = "";

                        /**
                         * StartWorkingShiftRequest startMileage.
                         * @member {number|Long} startMileage
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest
                         * @instance
                         */
                        StartWorkingShiftRequest.prototype.startMileage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Creates a new StartWorkingShiftRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.WorkingShift.IStartWorkingShiftRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest} StartWorkingShiftRequest instance
                         */
                        StartWorkingShiftRequest.create = function create(properties) {
                            return new StartWorkingShiftRequest(properties);
                        };

                        /**
                         * Encodes the specified StartWorkingShiftRequest message. Does not implicitly {@link pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.WorkingShift.IStartWorkingShiftRequest} message StartWorkingShiftRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartWorkingShiftRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.cabId != null && message.hasOwnProperty("cabId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.cabId);
                            if (message.startMileage != null && message.hasOwnProperty("startMileage"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startMileage);
                            return writer;
                        };

                        /**
                         * Encodes the specified StartWorkingShiftRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.WorkingShift.IStartWorkingShiftRequest} message StartWorkingShiftRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartWorkingShiftRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StartWorkingShiftRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest} StartWorkingShiftRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartWorkingShiftRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.cabId = reader.string();
                                    break;
                                case 2:
                                    message.startMileage = reader.int64();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StartWorkingShiftRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest} StartWorkingShiftRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartWorkingShiftRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StartWorkingShiftRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StartWorkingShiftRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.cabId != null && message.hasOwnProperty("cabId"))
                                if (!$util.isString(message.cabId))
                                    return "cabId: string expected";
                            if (message.startMileage != null && message.hasOwnProperty("startMileage"))
                                if (!$util.isInteger(message.startMileage) && !(message.startMileage && $util.isInteger(message.startMileage.low) && $util.isInteger(message.startMileage.high)))
                                    return "startMileage: integer|Long expected";
                            return null;
                        };

                        /**
                         * Creates a StartWorkingShiftRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest} StartWorkingShiftRequest
                         */
                        StartWorkingShiftRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest();
                            if (object.cabId != null)
                                message.cabId = String(object.cabId);
                            if (object.startMileage != null)
                                if ($util.Long)
                                    (message.startMileage = $util.Long.fromValue(object.startMileage)).unsigned = false;
                                else if (typeof object.startMileage === "string")
                                    message.startMileage = parseInt(object.startMileage, 10);
                                else if (typeof object.startMileage === "number")
                                    message.startMileage = object.startMileage;
                                else if (typeof object.startMileage === "object")
                                    message.startMileage = new $util.LongBits(object.startMileage.low >>> 0, object.startMileage.high >>> 0).toNumber();
                            return message;
                        };

                        /**
                         * Creates a plain object from a StartWorkingShiftRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest} message StartWorkingShiftRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StartWorkingShiftRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.cabId = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.startMileage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.startMileage = options.longs === String ? "0" : 0;
                            }
                            if (message.cabId != null && message.hasOwnProperty("cabId"))
                                object.cabId = message.cabId;
                            if (message.startMileage != null && message.hasOwnProperty("startMileage"))
                                if (typeof message.startMileage === "number")
                                    object.startMileage = options.longs === String ? String(message.startMileage) : message.startMileage;
                                else
                                    object.startMileage = options.longs === String ? $util.Long.prototype.toString.call(message.startMileage) : options.longs === Number ? new $util.LongBits(message.startMileage.low >>> 0, message.startMileage.high >>> 0).toNumber() : message.startMileage;
                            return object;
                        };

                        /**
                         * Converts this StartWorkingShiftRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StartWorkingShiftRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StartWorkingShiftRequest;
                    })();

                    WorkingShift.StartWorkingShiftResponse = (function() {

                        /**
                         * Properties of a StartWorkingShiftResponse.
                         * @memberof pro.megakit.amelia.operator.WorkingShift
                         * @interface IStartWorkingShiftResponse
                         * @property {string|null} [id] StartWorkingShiftResponse id
                         */

                        /**
                         * Constructs a new StartWorkingShiftResponse.
                         * @memberof pro.megakit.amelia.operator.WorkingShift
                         * @classdesc Represents a StartWorkingShiftResponse.
                         * @implements IStartWorkingShiftResponse
                         * @constructor
                         * @param {pro.megakit.amelia.operator.WorkingShift.IStartWorkingShiftResponse=} [properties] Properties to set
                         */
                        function StartWorkingShiftResponse(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StartWorkingShiftResponse id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse
                         * @instance
                         */
                        StartWorkingShiftResponse.prototype.id = "";

                        /**
                         * Creates a new StartWorkingShiftResponse instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse
                         * @static
                         * @param {pro.megakit.amelia.operator.WorkingShift.IStartWorkingShiftResponse=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse} StartWorkingShiftResponse instance
                         */
                        StartWorkingShiftResponse.create = function create(properties) {
                            return new StartWorkingShiftResponse(properties);
                        };

                        /**
                         * Encodes the specified StartWorkingShiftResponse message. Does not implicitly {@link pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse
                         * @static
                         * @param {pro.megakit.amelia.operator.WorkingShift.IStartWorkingShiftResponse} message StartWorkingShiftResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartWorkingShiftResponse.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified StartWorkingShiftResponse message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse
                         * @static
                         * @param {pro.megakit.amelia.operator.WorkingShift.IStartWorkingShiftResponse} message StartWorkingShiftResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartWorkingShiftResponse.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StartWorkingShiftResponse message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse} StartWorkingShiftResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartWorkingShiftResponse.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StartWorkingShiftResponse message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse} StartWorkingShiftResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartWorkingShiftResponse.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StartWorkingShiftResponse message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StartWorkingShiftResponse.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a StartWorkingShiftResponse message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse} StartWorkingShiftResponse
                         */
                        StartWorkingShiftResponse.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a StartWorkingShiftResponse message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse
                         * @static
                         * @param {pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse} message StartWorkingShiftResponse
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StartWorkingShiftResponse.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this StartWorkingShiftResponse to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.WorkingShift.StartWorkingShiftResponse
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StartWorkingShiftResponse.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StartWorkingShiftResponse;
                    })();

                    WorkingShift.FinishWorkingShiftRequest = (function() {

                        /**
                         * Properties of a FinishWorkingShiftRequest.
                         * @memberof pro.megakit.amelia.operator.WorkingShift
                         * @interface IFinishWorkingShiftRequest
                         * @property {string|null} [id] FinishWorkingShiftRequest id
                         * @property {number|Long|null} [finishMileage] FinishWorkingShiftRequest finishMileage
                         */

                        /**
                         * Constructs a new FinishWorkingShiftRequest.
                         * @memberof pro.megakit.amelia.operator.WorkingShift
                         * @classdesc Represents a FinishWorkingShiftRequest.
                         * @implements IFinishWorkingShiftRequest
                         * @constructor
                         * @param {pro.megakit.amelia.operator.WorkingShift.IFinishWorkingShiftRequest=} [properties] Properties to set
                         */
                        function FinishWorkingShiftRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FinishWorkingShiftRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest
                         * @instance
                         */
                        FinishWorkingShiftRequest.prototype.id = "";

                        /**
                         * FinishWorkingShiftRequest finishMileage.
                         * @member {number|Long} finishMileage
                         * @memberof pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest
                         * @instance
                         */
                        FinishWorkingShiftRequest.prototype.finishMileage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Creates a new FinishWorkingShiftRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.WorkingShift.IFinishWorkingShiftRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest} FinishWorkingShiftRequest instance
                         */
                        FinishWorkingShiftRequest.create = function create(properties) {
                            return new FinishWorkingShiftRequest(properties);
                        };

                        /**
                         * Encodes the specified FinishWorkingShiftRequest message. Does not implicitly {@link pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.WorkingShift.IFinishWorkingShiftRequest} message FinishWorkingShiftRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishWorkingShiftRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.finishMileage != null && message.hasOwnProperty("finishMileage"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.finishMileage);
                            return writer;
                        };

                        /**
                         * Encodes the specified FinishWorkingShiftRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.WorkingShift.IFinishWorkingShiftRequest} message FinishWorkingShiftRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishWorkingShiftRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FinishWorkingShiftRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest} FinishWorkingShiftRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishWorkingShiftRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.finishMileage = reader.int64();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FinishWorkingShiftRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest} FinishWorkingShiftRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishWorkingShiftRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FinishWorkingShiftRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FinishWorkingShiftRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.finishMileage != null && message.hasOwnProperty("finishMileage"))
                                if (!$util.isInteger(message.finishMileage) && !(message.finishMileage && $util.isInteger(message.finishMileage.low) && $util.isInteger(message.finishMileage.high)))
                                    return "finishMileage: integer|Long expected";
                            return null;
                        };

                        /**
                         * Creates a FinishWorkingShiftRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest} FinishWorkingShiftRequest
                         */
                        FinishWorkingShiftRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.finishMileage != null)
                                if ($util.Long)
                                    (message.finishMileage = $util.Long.fromValue(object.finishMileage)).unsigned = false;
                                else if (typeof object.finishMileage === "string")
                                    message.finishMileage = parseInt(object.finishMileage, 10);
                                else if (typeof object.finishMileage === "number")
                                    message.finishMileage = object.finishMileage;
                                else if (typeof object.finishMileage === "object")
                                    message.finishMileage = new $util.LongBits(object.finishMileage.low >>> 0, object.finishMileage.high >>> 0).toNumber();
                            return message;
                        };

                        /**
                         * Creates a plain object from a FinishWorkingShiftRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest} message FinishWorkingShiftRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FinishWorkingShiftRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.finishMileage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.finishMileage = options.longs === String ? "0" : 0;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.finishMileage != null && message.hasOwnProperty("finishMileage"))
                                if (typeof message.finishMileage === "number")
                                    object.finishMileage = options.longs === String ? String(message.finishMileage) : message.finishMileage;
                                else
                                    object.finishMileage = options.longs === String ? $util.Long.prototype.toString.call(message.finishMileage) : options.longs === Number ? new $util.LongBits(message.finishMileage.low >>> 0, message.finishMileage.high >>> 0).toNumber() : message.finishMileage;
                            return object;
                        };

                        /**
                         * Converts this FinishWorkingShiftRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.WorkingShift.FinishWorkingShiftRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FinishWorkingShiftRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FinishWorkingShiftRequest;
                    })();

                    return WorkingShift;
                })();

                operator.DriverRequest = (function() {

                    /**
                     * Properties of a DriverRequest.
                     * @memberof pro.megakit.amelia.operator
                     * @interface IDriverRequest
                     */

                    /**
                     * Constructs a new DriverRequest.
                     * @memberof pro.megakit.amelia.operator
                     * @classdesc Represents a DriverRequest.
                     * @implements IDriverRequest
                     * @constructor
                     * @param {pro.megakit.amelia.operator.IDriverRequest=} [properties] Properties to set
                     */
                    function DriverRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new DriverRequest instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.operator.DriverRequest
                     * @static
                     * @param {pro.megakit.amelia.operator.IDriverRequest=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.operator.DriverRequest} DriverRequest instance
                     */
                    DriverRequest.create = function create(properties) {
                        return new DriverRequest(properties);
                    };

                    /**
                     * Encodes the specified DriverRequest message. Does not implicitly {@link pro.megakit.amelia.operator.DriverRequest.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.operator.DriverRequest
                     * @static
                     * @param {pro.megakit.amelia.operator.IDriverRequest} message DriverRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified DriverRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.operator.DriverRequest
                     * @static
                     * @param {pro.megakit.amelia.operator.IDriverRequest} message DriverRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DriverRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.operator.DriverRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.operator.DriverRequest} DriverRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DriverRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.operator.DriverRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.operator.DriverRequest} DriverRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DriverRequest message.
                     * @function verify
                     * @memberof pro.megakit.amelia.operator.DriverRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DriverRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a DriverRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.operator.DriverRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.operator.DriverRequest} DriverRequest
                     */
                    DriverRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.operator.DriverRequest)
                            return object;
                        return new $root.pro.megakit.amelia.operator.DriverRequest();
                    };

                    /**
                     * Creates a plain object from a DriverRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.operator.DriverRequest
                     * @static
                     * @param {pro.megakit.amelia.operator.DriverRequest} message DriverRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DriverRequest.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this DriverRequest to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.operator.DriverRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DriverRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    DriverRequest.ActiveDriverRequest = (function() {

                        /**
                         * Properties of an ActiveDriverRequest.
                         * @memberof pro.megakit.amelia.operator.DriverRequest
                         * @interface IActiveDriverRequest
                         * @property {string|null} [id] ActiveDriverRequest id
                         * @property {string|null} [wshId] ActiveDriverRequest wshId
                         * @property {string|null} [cabId] ActiveDriverRequest cabId
                         * @property {string|null} [status] ActiveDriverRequest status
                         * @property {pro.megakit.amelia.SessionProto.SessionType|null} [type] ActiveDriverRequest type
                         * @property {pro.megakit.amelia.operator.DriverSessionProto.IActiveDriverSession|null} [session] ActiveDriverRequest session
                         * @property {Uint8Array|null} [payload] ActiveDriverRequest payload
                         * @property {google.protobuf.ITimestamp|null} [createdAt] ActiveDriverRequest createdAt
                         * @property {google.protobuf.ITimestamp|null} [assignedAt] ActiveDriverRequest assignedAt
                         */

                        /**
                         * Constructs a new ActiveDriverRequest.
                         * @memberof pro.megakit.amelia.operator.DriverRequest
                         * @classdesc Represents an ActiveDriverRequest.
                         * @implements IActiveDriverRequest
                         * @constructor
                         * @param {pro.megakit.amelia.operator.DriverRequest.IActiveDriverRequest=} [properties] Properties to set
                         */
                        function ActiveDriverRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ActiveDriverRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @instance
                         */
                        ActiveDriverRequest.prototype.id = "";

                        /**
                         * ActiveDriverRequest wshId.
                         * @member {string} wshId
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @instance
                         */
                        ActiveDriverRequest.prototype.wshId = "";

                        /**
                         * ActiveDriverRequest cabId.
                         * @member {string} cabId
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @instance
                         */
                        ActiveDriverRequest.prototype.cabId = "";

                        /**
                         * ActiveDriverRequest status.
                         * @member {string} status
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @instance
                         */
                        ActiveDriverRequest.prototype.status = "";

                        /**
                         * ActiveDriverRequest type.
                         * @member {pro.megakit.amelia.SessionProto.SessionType} type
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @instance
                         */
                        ActiveDriverRequest.prototype.type = 0;

                        /**
                         * ActiveDriverRequest session.
                         * @member {pro.megakit.amelia.operator.DriverSessionProto.IActiveDriverSession|null|undefined} session
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @instance
                         */
                        ActiveDriverRequest.prototype.session = null;

                        /**
                         * ActiveDriverRequest payload.
                         * @member {Uint8Array} payload
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @instance
                         */
                        ActiveDriverRequest.prototype.payload = $util.newBuffer([]);

                        /**
                         * ActiveDriverRequest createdAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @instance
                         */
                        ActiveDriverRequest.prototype.createdAt = null;

                        /**
                         * ActiveDriverRequest assignedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} assignedAt
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @instance
                         */
                        ActiveDriverRequest.prototype.assignedAt = null;

                        /**
                         * Creates a new ActiveDriverRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRequest.IActiveDriverRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest} ActiveDriverRequest instance
                         */
                        ActiveDriverRequest.create = function create(properties) {
                            return new ActiveDriverRequest(properties);
                        };

                        /**
                         * Encodes the specified ActiveDriverRequest message. Does not implicitly {@link pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRequest.IActiveDriverRequest} message ActiveDriverRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveDriverRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.wshId);
                            if (message.cabId != null && message.hasOwnProperty("cabId"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.cabId);
                            if (message.status != null && message.hasOwnProperty("status"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.status);
                            if (message.type != null && message.hasOwnProperty("type"))
                                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                            if (message.session != null && message.hasOwnProperty("session"))
                                $root.pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession.encode(message.session, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            if (message.payload != null && message.hasOwnProperty("payload"))
                                writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.payload);
                            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.assignedAt != null && message.hasOwnProperty("assignedAt"))
                                $root.google.protobuf.Timestamp.encode(message.assignedAt, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ActiveDriverRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRequest.IActiveDriverRequest} message ActiveDriverRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveDriverRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an ActiveDriverRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest} ActiveDriverRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveDriverRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.wshId = reader.string();
                                    break;
                                case 3:
                                    message.cabId = reader.string();
                                    break;
                                case 4:
                                    message.status = reader.string();
                                    break;
                                case 5:
                                    message.type = reader.int32();
                                    break;
                                case 6:
                                    message.session = $root.pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession.decode(reader, reader.uint32());
                                    break;
                                case 7:
                                    message.payload = reader.bytes();
                                    break;
                                case 8:
                                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                case 9:
                                    message.assignedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an ActiveDriverRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest} ActiveDriverRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveDriverRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an ActiveDriverRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ActiveDriverRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                if (!$util.isString(message.wshId))
                                    return "wshId: string expected";
                            if (message.cabId != null && message.hasOwnProperty("cabId"))
                                if (!$util.isString(message.cabId))
                                    return "cabId: string expected";
                            if (message.status != null && message.hasOwnProperty("status"))
                                if (!$util.isString(message.status))
                                    return "status: string expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                switch (message.type) {
                                default:
                                    return "type: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                    break;
                                }
                            if (message.session != null && message.hasOwnProperty("session")) {
                                var error = $root.pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession.verify(message.session);
                                if (error)
                                    return "session." + error;
                            }
                            if (message.payload != null && message.hasOwnProperty("payload"))
                                if (!(message.payload && typeof message.payload.length === "number" || $util.isString(message.payload)))
                                    return "payload: buffer expected";
                            if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.createdAt);
                                if (error)
                                    return "createdAt." + error;
                            }
                            if (message.assignedAt != null && message.hasOwnProperty("assignedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.assignedAt);
                                if (error)
                                    return "assignedAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates an ActiveDriverRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest} ActiveDriverRequest
                         */
                        ActiveDriverRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.wshId != null)
                                message.wshId = String(object.wshId);
                            if (object.cabId != null)
                                message.cabId = String(object.cabId);
                            if (object.status != null)
                                message.status = String(object.status);
                            switch (object.type) {
                            case "START_WORKING_SHIFT":
                            case 0:
                                message.type = 0;
                                break;
                            case "FINISH_WORKING_SHIFT":
                            case 1:
                                message.type = 1;
                                break;
                            case "VEHICLE_REFILL":
                            case 2:
                                message.type = 2;
                                break;
                            case "START_VEHICLE_WASHING":
                            case 3:
                                message.type = 3;
                                break;
                            case "FINISH_VEHICLE_WASHING":
                            case 4:
                                message.type = 4;
                                break;
                            case "STORE_DRIVER_EXPENSE":
                            case 5:
                                message.type = 5;
                                break;
                            case "START_DRIVER_REST":
                            case 6:
                                message.type = 6;
                                break;
                            case "CANCEL_DRIVER_REQUEST":
                            case 7:
                                message.type = 7;
                                break;
                            }
                            if (object.session != null) {
                                if (typeof object.session !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.session: object expected");
                                message.session = $root.pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession.fromObject(object.session);
                            }
                            if (object.payload != null)
                                if (typeof object.payload === "string")
                                    $util.base64.decode(object.payload, message.payload = $util.newBuffer($util.base64.length(object.payload)), 0);
                                else if (object.payload.length)
                                    message.payload = object.payload;
                            if (object.createdAt != null) {
                                if (typeof object.createdAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.createdAt: object expected");
                                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
                            }
                            if (object.assignedAt != null) {
                                if (typeof object.assignedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest.assignedAt: object expected");
                                message.assignedAt = $root.google.protobuf.Timestamp.fromObject(object.assignedAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an ActiveDriverRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest} message ActiveDriverRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ActiveDriverRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.wshId = "";
                                object.cabId = "";
                                object.status = "";
                                object.type = options.enums === String ? "START_WORKING_SHIFT" : 0;
                                object.session = null;
                                if (options.bytes === String)
                                    object.payload = "";
                                else {
                                    object.payload = [];
                                    if (options.bytes !== Array)
                                        object.payload = $util.newBuffer(object.payload);
                                }
                                object.createdAt = null;
                                object.assignedAt = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                object.wshId = message.wshId;
                            if (message.cabId != null && message.hasOwnProperty("cabId"))
                                object.cabId = message.cabId;
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = message.status;
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = options.enums === String ? $root.pro.megakit.amelia.SessionProto.SessionType[message.type] : message.type;
                            if (message.session != null && message.hasOwnProperty("session"))
                                object.session = $root.pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession.toObject(message.session, options);
                            if (message.payload != null && message.hasOwnProperty("payload"))
                                object.payload = options.bytes === String ? $util.base64.encode(message.payload, 0, message.payload.length) : options.bytes === Array ? Array.prototype.slice.call(message.payload) : message.payload;
                            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
                            if (message.assignedAt != null && message.hasOwnProperty("assignedAt"))
                                object.assignedAt = $root.google.protobuf.Timestamp.toObject(message.assignedAt, options);
                            return object;
                        };

                        /**
                         * Converts this ActiveDriverRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ActiveDriverRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ActiveDriverRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ActiveDriverRequest;
                    })();

                    DriverRequest.FinishedDriverRequest = (function() {

                        /**
                         * Properties of a FinishedDriverRequest.
                         * @memberof pro.megakit.amelia.operator.DriverRequest
                         * @interface IFinishedDriverRequest
                         * @property {string|null} [id] FinishedDriverRequest id
                         * @property {string|null} [wshId] FinishedDriverRequest wshId
                         * @property {string|null} [status] FinishedDriverRequest status
                         * @property {pro.megakit.amelia.SessionProto.SessionType|null} [type] FinishedDriverRequest type
                         * @property {pro.megakit.amelia.operator.DriverSessionProto.IActiveDriverSession|null} [session] FinishedDriverRequest session
                         * @property {google.protobuf.ITimestamp|null} [createdAt] FinishedDriverRequest createdAt
                         * @property {google.protobuf.ITimestamp|null} [assignedAt] FinishedDriverRequest assignedAt
                         */

                        /**
                         * Constructs a new FinishedDriverRequest.
                         * @memberof pro.megakit.amelia.operator.DriverRequest
                         * @classdesc Represents a FinishedDriverRequest.
                         * @implements IFinishedDriverRequest
                         * @constructor
                         * @param {pro.megakit.amelia.operator.DriverRequest.IFinishedDriverRequest=} [properties] Properties to set
                         */
                        function FinishedDriverRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FinishedDriverRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest
                         * @instance
                         */
                        FinishedDriverRequest.prototype.id = "";

                        /**
                         * FinishedDriverRequest wshId.
                         * @member {string} wshId
                         * @memberof pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest
                         * @instance
                         */
                        FinishedDriverRequest.prototype.wshId = "";

                        /**
                         * FinishedDriverRequest status.
                         * @member {string} status
                         * @memberof pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest
                         * @instance
                         */
                        FinishedDriverRequest.prototype.status = "";

                        /**
                         * FinishedDriverRequest type.
                         * @member {pro.megakit.amelia.SessionProto.SessionType} type
                         * @memberof pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest
                         * @instance
                         */
                        FinishedDriverRequest.prototype.type = 0;

                        /**
                         * FinishedDriverRequest session.
                         * @member {pro.megakit.amelia.operator.DriverSessionProto.IActiveDriverSession|null|undefined} session
                         * @memberof pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest
                         * @instance
                         */
                        FinishedDriverRequest.prototype.session = null;

                        /**
                         * FinishedDriverRequest createdAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
                         * @memberof pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest
                         * @instance
                         */
                        FinishedDriverRequest.prototype.createdAt = null;

                        /**
                         * FinishedDriverRequest assignedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} assignedAt
                         * @memberof pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest
                         * @instance
                         */
                        FinishedDriverRequest.prototype.assignedAt = null;

                        /**
                         * Creates a new FinishedDriverRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRequest.IFinishedDriverRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest} FinishedDriverRequest instance
                         */
                        FinishedDriverRequest.create = function create(properties) {
                            return new FinishedDriverRequest(properties);
                        };

                        /**
                         * Encodes the specified FinishedDriverRequest message. Does not implicitly {@link pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRequest.IFinishedDriverRequest} message FinishedDriverRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishedDriverRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.wshId);
                            if (message.status != null && message.hasOwnProperty("status"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.status);
                            if (message.type != null && message.hasOwnProperty("type"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
                            if (message.session != null && message.hasOwnProperty("session"))
                                $root.pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession.encode(message.session, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            if (message.assignedAt != null && message.hasOwnProperty("assignedAt"))
                                $root.google.protobuf.Timestamp.encode(message.assignedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified FinishedDriverRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRequest.IFinishedDriverRequest} message FinishedDriverRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishedDriverRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FinishedDriverRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest} FinishedDriverRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishedDriverRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.wshId = reader.string();
                                    break;
                                case 3:
                                    message.status = reader.string();
                                    break;
                                case 4:
                                    message.type = reader.int32();
                                    break;
                                case 5:
                                    message.session = $root.pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession.decode(reader, reader.uint32());
                                    break;
                                case 6:
                                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                case 7:
                                    message.assignedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FinishedDriverRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest} FinishedDriverRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishedDriverRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FinishedDriverRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FinishedDriverRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                if (!$util.isString(message.wshId))
                                    return "wshId: string expected";
                            if (message.status != null && message.hasOwnProperty("status"))
                                if (!$util.isString(message.status))
                                    return "status: string expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                switch (message.type) {
                                default:
                                    return "type: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                    break;
                                }
                            if (message.session != null && message.hasOwnProperty("session")) {
                                var error = $root.pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession.verify(message.session);
                                if (error)
                                    return "session." + error;
                            }
                            if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.createdAt);
                                if (error)
                                    return "createdAt." + error;
                            }
                            if (message.assignedAt != null && message.hasOwnProperty("assignedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.assignedAt);
                                if (error)
                                    return "assignedAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a FinishedDriverRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest} FinishedDriverRequest
                         */
                        FinishedDriverRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.wshId != null)
                                message.wshId = String(object.wshId);
                            if (object.status != null)
                                message.status = String(object.status);
                            switch (object.type) {
                            case "START_WORKING_SHIFT":
                            case 0:
                                message.type = 0;
                                break;
                            case "FINISH_WORKING_SHIFT":
                            case 1:
                                message.type = 1;
                                break;
                            case "VEHICLE_REFILL":
                            case 2:
                                message.type = 2;
                                break;
                            case "START_VEHICLE_WASHING":
                            case 3:
                                message.type = 3;
                                break;
                            case "FINISH_VEHICLE_WASHING":
                            case 4:
                                message.type = 4;
                                break;
                            case "STORE_DRIVER_EXPENSE":
                            case 5:
                                message.type = 5;
                                break;
                            case "START_DRIVER_REST":
                            case 6:
                                message.type = 6;
                                break;
                            case "CANCEL_DRIVER_REQUEST":
                            case 7:
                                message.type = 7;
                                break;
                            }
                            if (object.session != null) {
                                if (typeof object.session !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest.session: object expected");
                                message.session = $root.pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession.fromObject(object.session);
                            }
                            if (object.createdAt != null) {
                                if (typeof object.createdAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest.createdAt: object expected");
                                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
                            }
                            if (object.assignedAt != null) {
                                if (typeof object.assignedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest.assignedAt: object expected");
                                message.assignedAt = $root.google.protobuf.Timestamp.fromObject(object.assignedAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a FinishedDriverRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest} message FinishedDriverRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FinishedDriverRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.wshId = "";
                                object.status = "";
                                object.type = options.enums === String ? "START_WORKING_SHIFT" : 0;
                                object.session = null;
                                object.createdAt = null;
                                object.assignedAt = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                object.wshId = message.wshId;
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = message.status;
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = options.enums === String ? $root.pro.megakit.amelia.SessionProto.SessionType[message.type] : message.type;
                            if (message.session != null && message.hasOwnProperty("session"))
                                object.session = $root.pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession.toObject(message.session, options);
                            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
                            if (message.assignedAt != null && message.hasOwnProperty("assignedAt"))
                                object.assignedAt = $root.google.protobuf.Timestamp.toObject(message.assignedAt, options);
                            return object;
                        };

                        /**
                         * Converts this FinishedDriverRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.DriverRequest.FinishedDriverRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FinishedDriverRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FinishedDriverRequest;
                    })();

                    DriverRequest.ConfirmDriverRequest = (function() {

                        /**
                         * Properties of a ConfirmDriverRequest.
                         * @memberof pro.megakit.amelia.operator.DriverRequest
                         * @interface IConfirmDriverRequest
                         * @property {string|null} [id] ConfirmDriverRequest id
                         * @property {number|null} [delay] ConfirmDriverRequest delay
                         */

                        /**
                         * Constructs a new ConfirmDriverRequest.
                         * @memberof pro.megakit.amelia.operator.DriverRequest
                         * @classdesc Represents a ConfirmDriverRequest.
                         * @implements IConfirmDriverRequest
                         * @constructor
                         * @param {pro.megakit.amelia.operator.DriverRequest.IConfirmDriverRequest=} [properties] Properties to set
                         */
                        function ConfirmDriverRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ConfirmDriverRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest
                         * @instance
                         */
                        ConfirmDriverRequest.prototype.id = "";

                        /**
                         * ConfirmDriverRequest delay.
                         * @member {number} delay
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest
                         * @instance
                         */
                        ConfirmDriverRequest.prototype.delay = 0;

                        /**
                         * Creates a new ConfirmDriverRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRequest.IConfirmDriverRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest} ConfirmDriverRequest instance
                         */
                        ConfirmDriverRequest.create = function create(properties) {
                            return new ConfirmDriverRequest(properties);
                        };

                        /**
                         * Encodes the specified ConfirmDriverRequest message. Does not implicitly {@link pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRequest.IConfirmDriverRequest} message ConfirmDriverRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ConfirmDriverRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.delay != null && message.hasOwnProperty("delay"))
                                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.delay);
                            return writer;
                        };

                        /**
                         * Encodes the specified ConfirmDriverRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRequest.IConfirmDriverRequest} message ConfirmDriverRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ConfirmDriverRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ConfirmDriverRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest} ConfirmDriverRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ConfirmDriverRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.delay = reader.uint32();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ConfirmDriverRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest} ConfirmDriverRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ConfirmDriverRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ConfirmDriverRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ConfirmDriverRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.delay != null && message.hasOwnProperty("delay"))
                                if (!$util.isInteger(message.delay))
                                    return "delay: integer expected";
                            return null;
                        };

                        /**
                         * Creates a ConfirmDriverRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest} ConfirmDriverRequest
                         */
                        ConfirmDriverRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.delay != null)
                                message.delay = object.delay >>> 0;
                            return message;
                        };

                        /**
                         * Creates a plain object from a ConfirmDriverRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest} message ConfirmDriverRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ConfirmDriverRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.delay = 0;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.delay != null && message.hasOwnProperty("delay"))
                                object.delay = message.delay;
                            return object;
                        };

                        /**
                         * Converts this ConfirmDriverRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.DriverRequest.ConfirmDriverRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ConfirmDriverRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ConfirmDriverRequest;
                    })();

                    DriverRequest.RejectDriverRequest = (function() {

                        /**
                         * Properties of a RejectDriverRequest.
                         * @memberof pro.megakit.amelia.operator.DriverRequest
                         * @interface IRejectDriverRequest
                         * @property {string|null} [id] RejectDriverRequest id
                         */

                        /**
                         * Constructs a new RejectDriverRequest.
                         * @memberof pro.megakit.amelia.operator.DriverRequest
                         * @classdesc Represents a RejectDriverRequest.
                         * @implements IRejectDriverRequest
                         * @constructor
                         * @param {pro.megakit.amelia.operator.DriverRequest.IRejectDriverRequest=} [properties] Properties to set
                         */
                        function RejectDriverRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * RejectDriverRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest
                         * @instance
                         */
                        RejectDriverRequest.prototype.id = "";

                        /**
                         * Creates a new RejectDriverRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRequest.IRejectDriverRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest} RejectDriverRequest instance
                         */
                        RejectDriverRequest.create = function create(properties) {
                            return new RejectDriverRequest(properties);
                        };

                        /**
                         * Encodes the specified RejectDriverRequest message. Does not implicitly {@link pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRequest.IRejectDriverRequest} message RejectDriverRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        RejectDriverRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified RejectDriverRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRequest.IRejectDriverRequest} message RejectDriverRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        RejectDriverRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a RejectDriverRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest} RejectDriverRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        RejectDriverRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a RejectDriverRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest} RejectDriverRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        RejectDriverRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a RejectDriverRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        RejectDriverRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a RejectDriverRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest} RejectDriverRequest
                         */
                        RejectDriverRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a RejectDriverRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest} message RejectDriverRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        RejectDriverRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this RejectDriverRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.DriverRequest.RejectDriverRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        RejectDriverRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return RejectDriverRequest;
                    })();

                    return DriverRequest;
                })();

                operator.DriverSessionProto = (function() {

                    /**
                     * Properties of a DriverSessionProto.
                     * @memberof pro.megakit.amelia.operator
                     * @interface IDriverSessionProto
                     */

                    /**
                     * Constructs a new DriverSessionProto.
                     * @memberof pro.megakit.amelia.operator
                     * @classdesc Represents a DriverSessionProto.
                     * @implements IDriverSessionProto
                     * @constructor
                     * @param {pro.megakit.amelia.operator.IDriverSessionProto=} [properties] Properties to set
                     */
                    function DriverSessionProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new DriverSessionProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.operator.DriverSessionProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IDriverSessionProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.operator.DriverSessionProto} DriverSessionProto instance
                     */
                    DriverSessionProto.create = function create(properties) {
                        return new DriverSessionProto(properties);
                    };

                    /**
                     * Encodes the specified DriverSessionProto message. Does not implicitly {@link pro.megakit.amelia.operator.DriverSessionProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.operator.DriverSessionProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IDriverSessionProto} message DriverSessionProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverSessionProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified DriverSessionProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverSessionProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.operator.DriverSessionProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IDriverSessionProto} message DriverSessionProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverSessionProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DriverSessionProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.operator.DriverSessionProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.operator.DriverSessionProto} DriverSessionProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverSessionProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverSessionProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DriverSessionProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.operator.DriverSessionProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.operator.DriverSessionProto} DriverSessionProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverSessionProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DriverSessionProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.operator.DriverSessionProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DriverSessionProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a DriverSessionProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.operator.DriverSessionProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.operator.DriverSessionProto} DriverSessionProto
                     */
                    DriverSessionProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.operator.DriverSessionProto)
                            return object;
                        return new $root.pro.megakit.amelia.operator.DriverSessionProto();
                    };

                    /**
                     * Creates a plain object from a DriverSessionProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.operator.DriverSessionProto
                     * @static
                     * @param {pro.megakit.amelia.operator.DriverSessionProto} message DriverSessionProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DriverSessionProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this DriverSessionProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.operator.DriverSessionProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DriverSessionProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    DriverSessionProto.ActiveDriverSession = (function() {

                        /**
                         * Properties of an ActiveDriverSession.
                         * @memberof pro.megakit.amelia.operator.DriverSessionProto
                         * @interface IActiveDriverSession
                         * @property {string|null} [id] ActiveDriverSession id
                         * @property {Array.<pro.megakit.amelia.SessionProto.ISessionImage>|null} [images] ActiveDriverSession images
                         */

                        /**
                         * Constructs a new ActiveDriverSession.
                         * @memberof pro.megakit.amelia.operator.DriverSessionProto
                         * @classdesc Represents an ActiveDriverSession.
                         * @implements IActiveDriverSession
                         * @constructor
                         * @param {pro.megakit.amelia.operator.DriverSessionProto.IActiveDriverSession=} [properties] Properties to set
                         */
                        function ActiveDriverSession(properties) {
                            this.images = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ActiveDriverSession id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession
                         * @instance
                         */
                        ActiveDriverSession.prototype.id = "";

                        /**
                         * ActiveDriverSession images.
                         * @member {Array.<pro.megakit.amelia.SessionProto.ISessionImage>} images
                         * @memberof pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession
                         * @instance
                         */
                        ActiveDriverSession.prototype.images = $util.emptyArray;

                        /**
                         * Creates a new ActiveDriverSession instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverSessionProto.IActiveDriverSession=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession} ActiveDriverSession instance
                         */
                        ActiveDriverSession.create = function create(properties) {
                            return new ActiveDriverSession(properties);
                        };

                        /**
                         * Encodes the specified ActiveDriverSession message. Does not implicitly {@link pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverSessionProto.IActiveDriverSession} message ActiveDriverSession message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveDriverSession.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.images != null && message.images.length)
                                for (var i = 0; i < message.images.length; ++i)
                                    $root.pro.megakit.amelia.SessionProto.SessionImage.encode(message.images[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ActiveDriverSession message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverSessionProto.IActiveDriverSession} message ActiveDriverSession message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveDriverSession.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an ActiveDriverSession message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession} ActiveDriverSession
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveDriverSession.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    if (!(message.images && message.images.length))
                                        message.images = [];
                                    message.images.push($root.pro.megakit.amelia.SessionProto.SessionImage.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an ActiveDriverSession message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession} ActiveDriverSession
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveDriverSession.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an ActiveDriverSession message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ActiveDriverSession.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.images != null && message.hasOwnProperty("images")) {
                                if (!Array.isArray(message.images))
                                    return "images: array expected";
                                for (var i = 0; i < message.images.length; ++i) {
                                    var error = $root.pro.megakit.amelia.SessionProto.SessionImage.verify(message.images[i]);
                                    if (error)
                                        return "images." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates an ActiveDriverSession message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession} ActiveDriverSession
                         */
                        ActiveDriverSession.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.images) {
                                if (!Array.isArray(object.images))
                                    throw TypeError(".pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession.images: array expected");
                                message.images = [];
                                for (var i = 0; i < object.images.length; ++i) {
                                    if (typeof object.images[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession.images: object expected");
                                    message.images[i] = $root.pro.megakit.amelia.SessionProto.SessionImage.fromObject(object.images[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an ActiveDriverSession message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession} message ActiveDriverSession
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ActiveDriverSession.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.images = [];
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.images && message.images.length) {
                                object.images = [];
                                for (var j = 0; j < message.images.length; ++j)
                                    object.images[j] = $root.pro.megakit.amelia.SessionProto.SessionImage.toObject(message.images[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this ActiveDriverSession to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.DriverSessionProto.ActiveDriverSession
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ActiveDriverSession.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ActiveDriverSession;
                    })();

                    return DriverSessionProto;
                })();

                operator.ImageProto = (function() {

                    /**
                     * Properties of an ImageProto.
                     * @memberof pro.megakit.amelia.operator
                     * @interface IImageProto
                     */

                    /**
                     * Constructs a new ImageProto.
                     * @memberof pro.megakit.amelia.operator
                     * @classdesc Represents an ImageProto.
                     * @implements IImageProto
                     * @constructor
                     * @param {pro.megakit.amelia.operator.IImageProto=} [properties] Properties to set
                     */
                    function ImageProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new ImageProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.operator.ImageProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IImageProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.operator.ImageProto} ImageProto instance
                     */
                    ImageProto.create = function create(properties) {
                        return new ImageProto(properties);
                    };

                    /**
                     * Encodes the specified ImageProto message. Does not implicitly {@link pro.megakit.amelia.operator.ImageProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.operator.ImageProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IImageProto} message ImageProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImageProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified ImageProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.ImageProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.operator.ImageProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IImageProto} message ImageProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImageProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ImageProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.operator.ImageProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.operator.ImageProto} ImageProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImageProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.ImageProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ImageProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.operator.ImageProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.operator.ImageProto} ImageProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImageProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ImageProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.operator.ImageProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ImageProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates an ImageProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.operator.ImageProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.operator.ImageProto} ImageProto
                     */
                    ImageProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.operator.ImageProto)
                            return object;
                        return new $root.pro.megakit.amelia.operator.ImageProto();
                    };

                    /**
                     * Creates a plain object from an ImageProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.operator.ImageProto
                     * @static
                     * @param {pro.megakit.amelia.operator.ImageProto} message ImageProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ImageProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this ImageProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.operator.ImageProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ImageProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    ImageProto.GetImageRequest = (function() {

                        /**
                         * Properties of a GetImageRequest.
                         * @memberof pro.megakit.amelia.operator.ImageProto
                         * @interface IGetImageRequest
                         * @property {string|null} [id] GetImageRequest id
                         */

                        /**
                         * Constructs a new GetImageRequest.
                         * @memberof pro.megakit.amelia.operator.ImageProto
                         * @classdesc Represents a GetImageRequest.
                         * @implements IGetImageRequest
                         * @constructor
                         * @param {pro.megakit.amelia.operator.ImageProto.IGetImageRequest=} [properties] Properties to set
                         */
                        function GetImageRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * GetImageRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.ImageProto.GetImageRequest
                         * @instance
                         */
                        GetImageRequest.prototype.id = "";

                        /**
                         * Creates a new GetImageRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.ImageProto.GetImageRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.ImageProto.IGetImageRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.ImageProto.GetImageRequest} GetImageRequest instance
                         */
                        GetImageRequest.create = function create(properties) {
                            return new GetImageRequest(properties);
                        };

                        /**
                         * Encodes the specified GetImageRequest message. Does not implicitly {@link pro.megakit.amelia.operator.ImageProto.GetImageRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.ImageProto.GetImageRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.ImageProto.IGetImageRequest} message GetImageRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GetImageRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified GetImageRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.ImageProto.GetImageRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.ImageProto.GetImageRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.ImageProto.IGetImageRequest} message GetImageRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GetImageRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a GetImageRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.ImageProto.GetImageRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.ImageProto.GetImageRequest} GetImageRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GetImageRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.ImageProto.GetImageRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a GetImageRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.ImageProto.GetImageRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.ImageProto.GetImageRequest} GetImageRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GetImageRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a GetImageRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.ImageProto.GetImageRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        GetImageRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a GetImageRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.ImageProto.GetImageRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.ImageProto.GetImageRequest} GetImageRequest
                         */
                        GetImageRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.ImageProto.GetImageRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.ImageProto.GetImageRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a GetImageRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.ImageProto.GetImageRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.ImageProto.GetImageRequest} message GetImageRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        GetImageRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this GetImageRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.ImageProto.GetImageRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        GetImageRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return GetImageRequest;
                    })();

                    return ImageProto;
                })();

                operator.VehicleWashingProto = (function() {

                    /**
                     * Properties of a VehicleWashingProto.
                     * @memberof pro.megakit.amelia.operator
                     * @interface IVehicleWashingProto
                     */

                    /**
                     * Constructs a new VehicleWashingProto.
                     * @memberof pro.megakit.amelia.operator
                     * @classdesc Represents a VehicleWashingProto.
                     * @implements IVehicleWashingProto
                     * @constructor
                     * @param {pro.megakit.amelia.operator.IVehicleWashingProto=} [properties] Properties to set
                     */
                    function VehicleWashingProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new VehicleWashingProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IVehicleWashingProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.operator.VehicleWashingProto} VehicleWashingProto instance
                     */
                    VehicleWashingProto.create = function create(properties) {
                        return new VehicleWashingProto(properties);
                    };

                    /**
                     * Encodes the specified VehicleWashingProto message. Does not implicitly {@link pro.megakit.amelia.operator.VehicleWashingProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IVehicleWashingProto} message VehicleWashingProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VehicleWashingProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified VehicleWashingProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.VehicleWashingProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IVehicleWashingProto} message VehicleWashingProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VehicleWashingProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a VehicleWashingProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.operator.VehicleWashingProto} VehicleWashingProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VehicleWashingProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.VehicleWashingProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a VehicleWashingProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.operator.VehicleWashingProto} VehicleWashingProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VehicleWashingProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a VehicleWashingProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    VehicleWashingProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a VehicleWashingProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.operator.VehicleWashingProto} VehicleWashingProto
                     */
                    VehicleWashingProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.operator.VehicleWashingProto)
                            return object;
                        return new $root.pro.megakit.amelia.operator.VehicleWashingProto();
                    };

                    /**
                     * Creates a plain object from a VehicleWashingProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                     * @static
                     * @param {pro.megakit.amelia.operator.VehicleWashingProto} message VehicleWashingProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    VehicleWashingProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this VehicleWashingProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    VehicleWashingProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    VehicleWashingProto.ActiveVehicleWashing = (function() {

                        /**
                         * Properties of an ActiveVehicleWashing.
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                         * @interface IActiveVehicleWashing
                         * @property {string|null} [id] ActiveVehicleWashing id
                         * @property {string|null} [requestId] ActiveVehicleWashing requestId
                         * @property {string|null} [addressName] ActiveVehicleWashing addressName
                         * @property {google.protobuf.ITimestamp|null} [startedAt] ActiveVehicleWashing startedAt
                         */

                        /**
                         * Constructs a new ActiveVehicleWashing.
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                         * @classdesc Represents an ActiveVehicleWashing.
                         * @implements IActiveVehicleWashing
                         * @constructor
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IActiveVehicleWashing=} [properties] Properties to set
                         */
                        function ActiveVehicleWashing(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ActiveVehicleWashing id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing
                         * @instance
                         */
                        ActiveVehicleWashing.prototype.id = "";

                        /**
                         * ActiveVehicleWashing requestId.
                         * @member {string} requestId
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing
                         * @instance
                         */
                        ActiveVehicleWashing.prototype.requestId = "";

                        /**
                         * ActiveVehicleWashing addressName.
                         * @member {string} addressName
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing
                         * @instance
                         */
                        ActiveVehicleWashing.prototype.addressName = "";

                        /**
                         * ActiveVehicleWashing startedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} startedAt
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing
                         * @instance
                         */
                        ActiveVehicleWashing.prototype.startedAt = null;

                        /**
                         * Creates a new ActiveVehicleWashing instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IActiveVehicleWashing=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing} ActiveVehicleWashing instance
                         */
                        ActiveVehicleWashing.create = function create(properties) {
                            return new ActiveVehicleWashing(properties);
                        };

                        /**
                         * Encodes the specified ActiveVehicleWashing message. Does not implicitly {@link pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IActiveVehicleWashing} message ActiveVehicleWashing message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveVehicleWashing.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.requestId);
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.addressName);
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                $root.google.protobuf.Timestamp.encode(message.startedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ActiveVehicleWashing message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IActiveVehicleWashing} message ActiveVehicleWashing message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveVehicleWashing.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an ActiveVehicleWashing message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing} ActiveVehicleWashing
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveVehicleWashing.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.requestId = reader.string();
                                    break;
                                case 3:
                                    message.addressName = reader.string();
                                    break;
                                case 4:
                                    message.startedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an ActiveVehicleWashing message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing} ActiveVehicleWashing
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveVehicleWashing.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an ActiveVehicleWashing message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ActiveVehicleWashing.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                if (!$util.isString(message.requestId))
                                    return "requestId: string expected";
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                if (!$util.isString(message.addressName))
                                    return "addressName: string expected";
                            if (message.startedAt != null && message.hasOwnProperty("startedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.startedAt);
                                if (error)
                                    return "startedAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates an ActiveVehicleWashing message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing} ActiveVehicleWashing
                         */
                        ActiveVehicleWashing.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.requestId != null)
                                message.requestId = String(object.requestId);
                            if (object.addressName != null)
                                message.addressName = String(object.addressName);
                            if (object.startedAt != null) {
                                if (typeof object.startedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing.startedAt: object expected");
                                message.startedAt = $root.google.protobuf.Timestamp.fromObject(object.startedAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an ActiveVehicleWashing message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing} message ActiveVehicleWashing
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ActiveVehicleWashing.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.requestId = "";
                                object.addressName = "";
                                object.startedAt = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                object.requestId = message.requestId;
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                object.addressName = message.addressName;
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                object.startedAt = $root.google.protobuf.Timestamp.toObject(message.startedAt, options);
                            return object;
                        };

                        /**
                         * Converts this ActiveVehicleWashing to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.ActiveVehicleWashing
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ActiveVehicleWashing.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ActiveVehicleWashing;
                    })();

                    VehicleWashingProto.FinishedVehicleWashing = (function() {

                        /**
                         * Properties of a FinishedVehicleWashing.
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                         * @interface IFinishedVehicleWashing
                         * @property {string|null} [id] FinishedVehicleWashing id
                         * @property {string|null} [requestId] FinishedVehicleWashing requestId
                         * @property {string|null} [wshId] FinishedVehicleWashing wshId
                         * @property {string|null} [addressName] FinishedVehicleWashing addressName
                         * @property {number|null} [amount] FinishedVehicleWashing amount
                         * @property {number|null} [duration] FinishedVehicleWashing duration
                         * @property {google.protobuf.ITimestamp|null} [assignedAt] FinishedVehicleWashing assignedAt
                         * @property {google.protobuf.ITimestamp|null} [startedAt] FinishedVehicleWashing startedAt
                         * @property {google.protobuf.ITimestamp|null} [finishedAt] FinishedVehicleWashing finishedAt
                         */

                        /**
                         * Constructs a new FinishedVehicleWashing.
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                         * @classdesc Represents a FinishedVehicleWashing.
                         * @implements IFinishedVehicleWashing
                         * @constructor
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IFinishedVehicleWashing=} [properties] Properties to set
                         */
                        function FinishedVehicleWashing(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FinishedVehicleWashing id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @instance
                         */
                        FinishedVehicleWashing.prototype.id = "";

                        /**
                         * FinishedVehicleWashing requestId.
                         * @member {string} requestId
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @instance
                         */
                        FinishedVehicleWashing.prototype.requestId = "";

                        /**
                         * FinishedVehicleWashing wshId.
                         * @member {string} wshId
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @instance
                         */
                        FinishedVehicleWashing.prototype.wshId = "";

                        /**
                         * FinishedVehicleWashing addressName.
                         * @member {string} addressName
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @instance
                         */
                        FinishedVehicleWashing.prototype.addressName = "";

                        /**
                         * FinishedVehicleWashing amount.
                         * @member {number} amount
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @instance
                         */
                        FinishedVehicleWashing.prototype.amount = 0;

                        /**
                         * FinishedVehicleWashing duration.
                         * @member {number} duration
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @instance
                         */
                        FinishedVehicleWashing.prototype.duration = 0;

                        /**
                         * FinishedVehicleWashing assignedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} assignedAt
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @instance
                         */
                        FinishedVehicleWashing.prototype.assignedAt = null;

                        /**
                         * FinishedVehicleWashing startedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} startedAt
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @instance
                         */
                        FinishedVehicleWashing.prototype.startedAt = null;

                        /**
                         * FinishedVehicleWashing finishedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} finishedAt
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @instance
                         */
                        FinishedVehicleWashing.prototype.finishedAt = null;

                        /**
                         * Creates a new FinishedVehicleWashing instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IFinishedVehicleWashing=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing} FinishedVehicleWashing instance
                         */
                        FinishedVehicleWashing.create = function create(properties) {
                            return new FinishedVehicleWashing(properties);
                        };

                        /**
                         * Encodes the specified FinishedVehicleWashing message. Does not implicitly {@link pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IFinishedVehicleWashing} message FinishedVehicleWashing message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishedVehicleWashing.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.requestId);
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.wshId);
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.addressName);
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                writer.uint32(/* id 5, wireType 1 =*/41).double(message.amount);
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.duration);
                            if (message.assignedAt != null && message.hasOwnProperty("assignedAt"))
                                $root.google.protobuf.Timestamp.encode(message.assignedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                $root.google.protobuf.Timestamp.encode(message.startedAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt"))
                                $root.google.protobuf.Timestamp.encode(message.finishedAt, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified FinishedVehicleWashing message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IFinishedVehicleWashing} message FinishedVehicleWashing message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishedVehicleWashing.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FinishedVehicleWashing message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing} FinishedVehicleWashing
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishedVehicleWashing.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.requestId = reader.string();
                                    break;
                                case 3:
                                    message.wshId = reader.string();
                                    break;
                                case 4:
                                    message.addressName = reader.string();
                                    break;
                                case 5:
                                    message.amount = reader.double();
                                    break;
                                case 6:
                                    message.duration = reader.uint32();
                                    break;
                                case 7:
                                    message.assignedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                case 8:
                                    message.startedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                case 9:
                                    message.finishedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FinishedVehicleWashing message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing} FinishedVehicleWashing
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishedVehicleWashing.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FinishedVehicleWashing message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FinishedVehicleWashing.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                if (!$util.isString(message.requestId))
                                    return "requestId: string expected";
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                if (!$util.isString(message.wshId))
                                    return "wshId: string expected";
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                if (!$util.isString(message.addressName))
                                    return "addressName: string expected";
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                if (typeof message.amount !== "number")
                                    return "amount: number expected";
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                if (!$util.isInteger(message.duration))
                                    return "duration: integer expected";
                            if (message.assignedAt != null && message.hasOwnProperty("assignedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.assignedAt);
                                if (error)
                                    return "assignedAt." + error;
                            }
                            if (message.startedAt != null && message.hasOwnProperty("startedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.startedAt);
                                if (error)
                                    return "startedAt." + error;
                            }
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.finishedAt);
                                if (error)
                                    return "finishedAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a FinishedVehicleWashing message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing} FinishedVehicleWashing
                         */
                        FinishedVehicleWashing.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.requestId != null)
                                message.requestId = String(object.requestId);
                            if (object.wshId != null)
                                message.wshId = String(object.wshId);
                            if (object.addressName != null)
                                message.addressName = String(object.addressName);
                            if (object.amount != null)
                                message.amount = Number(object.amount);
                            if (object.duration != null)
                                message.duration = object.duration >>> 0;
                            if (object.assignedAt != null) {
                                if (typeof object.assignedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing.assignedAt: object expected");
                                message.assignedAt = $root.google.protobuf.Timestamp.fromObject(object.assignedAt);
                            }
                            if (object.startedAt != null) {
                                if (typeof object.startedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing.startedAt: object expected");
                                message.startedAt = $root.google.protobuf.Timestamp.fromObject(object.startedAt);
                            }
                            if (object.finishedAt != null) {
                                if (typeof object.finishedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing.finishedAt: object expected");
                                message.finishedAt = $root.google.protobuf.Timestamp.fromObject(object.finishedAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a FinishedVehicleWashing message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing} message FinishedVehicleWashing
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FinishedVehicleWashing.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.requestId = "";
                                object.wshId = "";
                                object.addressName = "";
                                object.amount = 0;
                                object.duration = 0;
                                object.assignedAt = null;
                                object.startedAt = null;
                                object.finishedAt = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                object.requestId = message.requestId;
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                object.wshId = message.wshId;
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                object.addressName = message.addressName;
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                object.duration = message.duration;
                            if (message.assignedAt != null && message.hasOwnProperty("assignedAt"))
                                object.assignedAt = $root.google.protobuf.Timestamp.toObject(message.assignedAt, options);
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                object.startedAt = $root.google.protobuf.Timestamp.toObject(message.startedAt, options);
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt"))
                                object.finishedAt = $root.google.protobuf.Timestamp.toObject(message.finishedAt, options);
                            return object;
                        };

                        /**
                         * Converts this FinishedVehicleWashing to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishedVehicleWashing
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FinishedVehicleWashing.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FinishedVehicleWashing;
                    })();

                    VehicleWashingProto.StartVehicleWashingRequest = (function() {

                        /**
                         * Properties of a StartVehicleWashingRequest.
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                         * @interface IStartVehicleWashingRequest
                         * @property {string|null} [requestId] StartVehicleWashingRequest requestId
                         */

                        /**
                         * Constructs a new StartVehicleWashingRequest.
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                         * @classdesc Represents a StartVehicleWashingRequest.
                         * @implements IStartVehicleWashingRequest
                         * @constructor
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IStartVehicleWashingRequest=} [properties] Properties to set
                         */
                        function StartVehicleWashingRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StartVehicleWashingRequest requestId.
                         * @member {string} requestId
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest
                         * @instance
                         */
                        StartVehicleWashingRequest.prototype.requestId = "";

                        /**
                         * Creates a new StartVehicleWashingRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IStartVehicleWashingRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest} StartVehicleWashingRequest instance
                         */
                        StartVehicleWashingRequest.create = function create(properties) {
                            return new StartVehicleWashingRequest(properties);
                        };

                        /**
                         * Encodes the specified StartVehicleWashingRequest message. Does not implicitly {@link pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IStartVehicleWashingRequest} message StartVehicleWashingRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartVehicleWashingRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.requestId);
                            return writer;
                        };

                        /**
                         * Encodes the specified StartVehicleWashingRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IStartVehicleWashingRequest} message StartVehicleWashingRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartVehicleWashingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StartVehicleWashingRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest} StartVehicleWashingRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartVehicleWashingRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.requestId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StartVehicleWashingRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest} StartVehicleWashingRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartVehicleWashingRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StartVehicleWashingRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StartVehicleWashingRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                if (!$util.isString(message.requestId))
                                    return "requestId: string expected";
                            return null;
                        };

                        /**
                         * Creates a StartVehicleWashingRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest} StartVehicleWashingRequest
                         */
                        StartVehicleWashingRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest();
                            if (object.requestId != null)
                                message.requestId = String(object.requestId);
                            return message;
                        };

                        /**
                         * Creates a plain object from a StartVehicleWashingRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest} message StartVehicleWashingRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StartVehicleWashingRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.requestId = "";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                object.requestId = message.requestId;
                            return object;
                        };

                        /**
                         * Converts this StartVehicleWashingRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StartVehicleWashingRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StartVehicleWashingRequest;
                    })();

                    VehicleWashingProto.StartVehicleWashingResponse = (function() {

                        /**
                         * Properties of a StartVehicleWashingResponse.
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                         * @interface IStartVehicleWashingResponse
                         * @property {string|null} [id] StartVehicleWashingResponse id
                         */

                        /**
                         * Constructs a new StartVehicleWashingResponse.
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                         * @classdesc Represents a StartVehicleWashingResponse.
                         * @implements IStartVehicleWashingResponse
                         * @constructor
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IStartVehicleWashingResponse=} [properties] Properties to set
                         */
                        function StartVehicleWashingResponse(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StartVehicleWashingResponse id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse
                         * @instance
                         */
                        StartVehicleWashingResponse.prototype.id = "";

                        /**
                         * Creates a new StartVehicleWashingResponse instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IStartVehicleWashingResponse=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse} StartVehicleWashingResponse instance
                         */
                        StartVehicleWashingResponse.create = function create(properties) {
                            return new StartVehicleWashingResponse(properties);
                        };

                        /**
                         * Encodes the specified StartVehicleWashingResponse message. Does not implicitly {@link pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IStartVehicleWashingResponse} message StartVehicleWashingResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartVehicleWashingResponse.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified StartVehicleWashingResponse message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IStartVehicleWashingResponse} message StartVehicleWashingResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartVehicleWashingResponse.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StartVehicleWashingResponse message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse} StartVehicleWashingResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartVehicleWashingResponse.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StartVehicleWashingResponse message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse} StartVehicleWashingResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartVehicleWashingResponse.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StartVehicleWashingResponse message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StartVehicleWashingResponse.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a StartVehicleWashingResponse message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse} StartVehicleWashingResponse
                         */
                        StartVehicleWashingResponse.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a StartVehicleWashingResponse message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse} message StartVehicleWashingResponse
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StartVehicleWashingResponse.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this StartVehicleWashingResponse to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.StartVehicleWashingResponse
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StartVehicleWashingResponse.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StartVehicleWashingResponse;
                    })();

                    VehicleWashingProto.FinishVehicleWashingRequest = (function() {

                        /**
                         * Properties of a FinishVehicleWashingRequest.
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                         * @interface IFinishVehicleWashingRequest
                         * @property {string|null} [id] FinishVehicleWashingRequest id
                         * @property {number|null} [amount] FinishVehicleWashingRequest amount
                         */

                        /**
                         * Constructs a new FinishVehicleWashingRequest.
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                         * @classdesc Represents a FinishVehicleWashingRequest.
                         * @implements IFinishVehicleWashingRequest
                         * @constructor
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IFinishVehicleWashingRequest=} [properties] Properties to set
                         */
                        function FinishVehicleWashingRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FinishVehicleWashingRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest
                         * @instance
                         */
                        FinishVehicleWashingRequest.prototype.id = "";

                        /**
                         * FinishVehicleWashingRequest amount.
                         * @member {number} amount
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest
                         * @instance
                         */
                        FinishVehicleWashingRequest.prototype.amount = 0;

                        /**
                         * Creates a new FinishVehicleWashingRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IFinishVehicleWashingRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest} FinishVehicleWashingRequest instance
                         */
                        FinishVehicleWashingRequest.create = function create(properties) {
                            return new FinishVehicleWashingRequest(properties);
                        };

                        /**
                         * Encodes the specified FinishVehicleWashingRequest message. Does not implicitly {@link pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IFinishVehicleWashingRequest} message FinishVehicleWashingRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishVehicleWashingRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                writer.uint32(/* id 2, wireType 1 =*/17).double(message.amount);
                            return writer;
                        };

                        /**
                         * Encodes the specified FinishVehicleWashingRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.IFinishVehicleWashingRequest} message FinishVehicleWashingRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishVehicleWashingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FinishVehicleWashingRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest} FinishVehicleWashingRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishVehicleWashingRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.amount = reader.double();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FinishVehicleWashingRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest} FinishVehicleWashingRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishVehicleWashingRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FinishVehicleWashingRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FinishVehicleWashingRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                if (typeof message.amount !== "number")
                                    return "amount: number expected";
                            return null;
                        };

                        /**
                         * Creates a FinishVehicleWashingRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest} FinishVehicleWashingRequest
                         */
                        FinishVehicleWashingRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.amount != null)
                                message.amount = Number(object.amount);
                            return message;
                        };

                        /**
                         * Creates a plain object from a FinishVehicleWashingRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest} message FinishVehicleWashingRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FinishVehicleWashingRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.amount = 0;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
                            return object;
                        };

                        /**
                         * Converts this FinishVehicleWashingRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.FinishVehicleWashingRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FinishVehicleWashingRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FinishVehicleWashingRequest;
                    })();

                    VehicleWashingProto.CancelVehicleWashingRequest = (function() {

                        /**
                         * Properties of a CancelVehicleWashingRequest.
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                         * @interface ICancelVehicleWashingRequest
                         * @property {string|null} [id] CancelVehicleWashingRequest id
                         */

                        /**
                         * Constructs a new CancelVehicleWashingRequest.
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto
                         * @classdesc Represents a CancelVehicleWashingRequest.
                         * @implements ICancelVehicleWashingRequest
                         * @constructor
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.ICancelVehicleWashingRequest=} [properties] Properties to set
                         */
                        function CancelVehicleWashingRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CancelVehicleWashingRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest
                         * @instance
                         */
                        CancelVehicleWashingRequest.prototype.id = "";

                        /**
                         * Creates a new CancelVehicleWashingRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.ICancelVehicleWashingRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest} CancelVehicleWashingRequest instance
                         */
                        CancelVehicleWashingRequest.create = function create(properties) {
                            return new CancelVehicleWashingRequest(properties);
                        };

                        /**
                         * Encodes the specified CancelVehicleWashingRequest message. Does not implicitly {@link pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.ICancelVehicleWashingRequest} message CancelVehicleWashingRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CancelVehicleWashingRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified CancelVehicleWashingRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.ICancelVehicleWashingRequest} message CancelVehicleWashingRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CancelVehicleWashingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a CancelVehicleWashingRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest} CancelVehicleWashingRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CancelVehicleWashingRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a CancelVehicleWashingRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest} CancelVehicleWashingRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CancelVehicleWashingRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a CancelVehicleWashingRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CancelVehicleWashingRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a CancelVehicleWashingRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest} CancelVehicleWashingRequest
                         */
                        CancelVehicleWashingRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a CancelVehicleWashingRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest} message CancelVehicleWashingRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CancelVehicleWashingRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this CancelVehicleWashingRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.VehicleWashingProto.CancelVehicleWashingRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CancelVehicleWashingRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return CancelVehicleWashingRequest;
                    })();

                    return VehicleWashingProto;
                })();

                operator.DriverRestProto = (function() {

                    /**
                     * Properties of a DriverRestProto.
                     * @memberof pro.megakit.amelia.operator
                     * @interface IDriverRestProto
                     */

                    /**
                     * Constructs a new DriverRestProto.
                     * @memberof pro.megakit.amelia.operator
                     * @classdesc Represents a DriverRestProto.
                     * @implements IDriverRestProto
                     * @constructor
                     * @param {pro.megakit.amelia.operator.IDriverRestProto=} [properties] Properties to set
                     */
                    function DriverRestProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new DriverRestProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.operator.DriverRestProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IDriverRestProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.operator.DriverRestProto} DriverRestProto instance
                     */
                    DriverRestProto.create = function create(properties) {
                        return new DriverRestProto(properties);
                    };

                    /**
                     * Encodes the specified DriverRestProto message. Does not implicitly {@link pro.megakit.amelia.operator.DriverRestProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.operator.DriverRestProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IDriverRestProto} message DriverRestProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverRestProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified DriverRestProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverRestProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.operator.DriverRestProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IDriverRestProto} message DriverRestProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverRestProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DriverRestProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.operator.DriverRestProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.operator.DriverRestProto} DriverRestProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverRestProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverRestProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DriverRestProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.operator.DriverRestProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.operator.DriverRestProto} DriverRestProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverRestProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DriverRestProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.operator.DriverRestProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DriverRestProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a DriverRestProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.operator.DriverRestProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.operator.DriverRestProto} DriverRestProto
                     */
                    DriverRestProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.operator.DriverRestProto)
                            return object;
                        return new $root.pro.megakit.amelia.operator.DriverRestProto();
                    };

                    /**
                     * Creates a plain object from a DriverRestProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.operator.DriverRestProto
                     * @static
                     * @param {pro.megakit.amelia.operator.DriverRestProto} message DriverRestProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DriverRestProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this DriverRestProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.operator.DriverRestProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DriverRestProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    DriverRestProto.ActiveDriverRest = (function() {

                        /**
                         * Properties of an ActiveDriverRest.
                         * @memberof pro.megakit.amelia.operator.DriverRestProto
                         * @interface IActiveDriverRest
                         * @property {string|null} [id] ActiveDriverRest id
                         * @property {string|null} [requestId] ActiveDriverRest requestId
                         * @property {string|null} [typeId] ActiveDriverRest typeId
                         * @property {string|null} [addressName] ActiveDriverRest addressName
                         * @property {number|null} [duration] ActiveDriverRest duration
                         * @property {google.protobuf.ITimestamp|null} [startedAt] ActiveDriverRest startedAt
                         */

                        /**
                         * Constructs a new ActiveDriverRest.
                         * @memberof pro.megakit.amelia.operator.DriverRestProto
                         * @classdesc Represents an ActiveDriverRest.
                         * @implements IActiveDriverRest
                         * @constructor
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IActiveDriverRest=} [properties] Properties to set
                         */
                        function ActiveDriverRest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ActiveDriverRest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest
                         * @instance
                         */
                        ActiveDriverRest.prototype.id = "";

                        /**
                         * ActiveDriverRest requestId.
                         * @member {string} requestId
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest
                         * @instance
                         */
                        ActiveDriverRest.prototype.requestId = "";

                        /**
                         * ActiveDriverRest typeId.
                         * @member {string} typeId
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest
                         * @instance
                         */
                        ActiveDriverRest.prototype.typeId = "";

                        /**
                         * ActiveDriverRest addressName.
                         * @member {string} addressName
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest
                         * @instance
                         */
                        ActiveDriverRest.prototype.addressName = "";

                        /**
                         * ActiveDriverRest duration.
                         * @member {number} duration
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest
                         * @instance
                         */
                        ActiveDriverRest.prototype.duration = 0;

                        /**
                         * ActiveDriverRest startedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} startedAt
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest
                         * @instance
                         */
                        ActiveDriverRest.prototype.startedAt = null;

                        /**
                         * Creates a new ActiveDriverRest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IActiveDriverRest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest} ActiveDriverRest instance
                         */
                        ActiveDriverRest.create = function create(properties) {
                            return new ActiveDriverRest(properties);
                        };

                        /**
                         * Encodes the specified ActiveDriverRest message. Does not implicitly {@link pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IActiveDriverRest} message ActiveDriverRest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveDriverRest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.requestId);
                            if (message.typeId != null && message.hasOwnProperty("typeId"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.typeId);
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.addressName);
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.duration);
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                $root.google.protobuf.Timestamp.encode(message.startedAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ActiveDriverRest message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IActiveDriverRest} message ActiveDriverRest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveDriverRest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an ActiveDriverRest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest} ActiveDriverRest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveDriverRest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.requestId = reader.string();
                                    break;
                                case 3:
                                    message.typeId = reader.string();
                                    break;
                                case 4:
                                    message.addressName = reader.string();
                                    break;
                                case 5:
                                    message.duration = reader.uint32();
                                    break;
                                case 6:
                                    message.startedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an ActiveDriverRest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest} ActiveDriverRest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveDriverRest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an ActiveDriverRest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ActiveDriverRest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                if (!$util.isString(message.requestId))
                                    return "requestId: string expected";
                            if (message.typeId != null && message.hasOwnProperty("typeId"))
                                if (!$util.isString(message.typeId))
                                    return "typeId: string expected";
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                if (!$util.isString(message.addressName))
                                    return "addressName: string expected";
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                if (!$util.isInteger(message.duration))
                                    return "duration: integer expected";
                            if (message.startedAt != null && message.hasOwnProperty("startedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.startedAt);
                                if (error)
                                    return "startedAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates an ActiveDriverRest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest} ActiveDriverRest
                         */
                        ActiveDriverRest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.requestId != null)
                                message.requestId = String(object.requestId);
                            if (object.typeId != null)
                                message.typeId = String(object.typeId);
                            if (object.addressName != null)
                                message.addressName = String(object.addressName);
                            if (object.duration != null)
                                message.duration = object.duration >>> 0;
                            if (object.startedAt != null) {
                                if (typeof object.startedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest.startedAt: object expected");
                                message.startedAt = $root.google.protobuf.Timestamp.fromObject(object.startedAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an ActiveDriverRest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest} message ActiveDriverRest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ActiveDriverRest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.requestId = "";
                                object.typeId = "";
                                object.addressName = "";
                                object.duration = 0;
                                object.startedAt = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                object.requestId = message.requestId;
                            if (message.typeId != null && message.hasOwnProperty("typeId"))
                                object.typeId = message.typeId;
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                object.addressName = message.addressName;
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                object.duration = message.duration;
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                object.startedAt = $root.google.protobuf.Timestamp.toObject(message.startedAt, options);
                            return object;
                        };

                        /**
                         * Converts this ActiveDriverRest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.ActiveDriverRest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ActiveDriverRest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ActiveDriverRest;
                    })();

                    DriverRestProto.FinishedDriverRest = (function() {

                        /**
                         * Properties of a FinishedDriverRest.
                         * @memberof pro.megakit.amelia.operator.DriverRestProto
                         * @interface IFinishedDriverRest
                         * @property {string|null} [id] FinishedDriverRest id
                         * @property {string|null} [requestId] FinishedDriverRest requestId
                         * @property {string|null} [wshId] FinishedDriverRest wshId
                         * @property {string|null} [typeId] FinishedDriverRest typeId
                         * @property {string|null} [addressName] FinishedDriverRest addressName
                         * @property {number|null} [duration] FinishedDriverRest duration
                         * @property {number|null} [totalDuration] FinishedDriverRest totalDuration
                         * @property {google.protobuf.ITimestamp|null} [startedAt] FinishedDriverRest startedAt
                         * @property {google.protobuf.ITimestamp|null} [finishedAt] FinishedDriverRest finishedAt
                         */

                        /**
                         * Constructs a new FinishedDriverRest.
                         * @memberof pro.megakit.amelia.operator.DriverRestProto
                         * @classdesc Represents a FinishedDriverRest.
                         * @implements IFinishedDriverRest
                         * @constructor
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IFinishedDriverRest=} [properties] Properties to set
                         */
                        function FinishedDriverRest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FinishedDriverRest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @instance
                         */
                        FinishedDriverRest.prototype.id = "";

                        /**
                         * FinishedDriverRest requestId.
                         * @member {string} requestId
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @instance
                         */
                        FinishedDriverRest.prototype.requestId = "";

                        /**
                         * FinishedDriverRest wshId.
                         * @member {string} wshId
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @instance
                         */
                        FinishedDriverRest.prototype.wshId = "";

                        /**
                         * FinishedDriverRest typeId.
                         * @member {string} typeId
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @instance
                         */
                        FinishedDriverRest.prototype.typeId = "";

                        /**
                         * FinishedDriverRest addressName.
                         * @member {string} addressName
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @instance
                         */
                        FinishedDriverRest.prototype.addressName = "";

                        /**
                         * FinishedDriverRest duration.
                         * @member {number} duration
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @instance
                         */
                        FinishedDriverRest.prototype.duration = 0;

                        /**
                         * FinishedDriverRest totalDuration.
                         * @member {number} totalDuration
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @instance
                         */
                        FinishedDriverRest.prototype.totalDuration = 0;

                        /**
                         * FinishedDriverRest startedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} startedAt
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @instance
                         */
                        FinishedDriverRest.prototype.startedAt = null;

                        /**
                         * FinishedDriverRest finishedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} finishedAt
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @instance
                         */
                        FinishedDriverRest.prototype.finishedAt = null;

                        /**
                         * Creates a new FinishedDriverRest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IFinishedDriverRest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest} FinishedDriverRest instance
                         */
                        FinishedDriverRest.create = function create(properties) {
                            return new FinishedDriverRest(properties);
                        };

                        /**
                         * Encodes the specified FinishedDriverRest message. Does not implicitly {@link pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IFinishedDriverRest} message FinishedDriverRest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishedDriverRest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.requestId);
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.wshId);
                            if (message.typeId != null && message.hasOwnProperty("typeId"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.typeId);
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.addressName);
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.duration);
                            if (message.totalDuration != null && message.hasOwnProperty("totalDuration"))
                                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.totalDuration);
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                $root.google.protobuf.Timestamp.encode(message.startedAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt"))
                                $root.google.protobuf.Timestamp.encode(message.finishedAt, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified FinishedDriverRest message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IFinishedDriverRest} message FinishedDriverRest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishedDriverRest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FinishedDriverRest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest} FinishedDriverRest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishedDriverRest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.requestId = reader.string();
                                    break;
                                case 3:
                                    message.wshId = reader.string();
                                    break;
                                case 4:
                                    message.typeId = reader.string();
                                    break;
                                case 5:
                                    message.addressName = reader.string();
                                    break;
                                case 6:
                                    message.duration = reader.uint32();
                                    break;
                                case 7:
                                    message.totalDuration = reader.uint32();
                                    break;
                                case 8:
                                    message.startedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                case 9:
                                    message.finishedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FinishedDriverRest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest} FinishedDriverRest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishedDriverRest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FinishedDriverRest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FinishedDriverRest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                if (!$util.isString(message.requestId))
                                    return "requestId: string expected";
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                if (!$util.isString(message.wshId))
                                    return "wshId: string expected";
                            if (message.typeId != null && message.hasOwnProperty("typeId"))
                                if (!$util.isString(message.typeId))
                                    return "typeId: string expected";
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                if (!$util.isString(message.addressName))
                                    return "addressName: string expected";
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                if (!$util.isInteger(message.duration))
                                    return "duration: integer expected";
                            if (message.totalDuration != null && message.hasOwnProperty("totalDuration"))
                                if (!$util.isInteger(message.totalDuration))
                                    return "totalDuration: integer expected";
                            if (message.startedAt != null && message.hasOwnProperty("startedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.startedAt);
                                if (error)
                                    return "startedAt." + error;
                            }
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.finishedAt);
                                if (error)
                                    return "finishedAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a FinishedDriverRest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest} FinishedDriverRest
                         */
                        FinishedDriverRest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.requestId != null)
                                message.requestId = String(object.requestId);
                            if (object.wshId != null)
                                message.wshId = String(object.wshId);
                            if (object.typeId != null)
                                message.typeId = String(object.typeId);
                            if (object.addressName != null)
                                message.addressName = String(object.addressName);
                            if (object.duration != null)
                                message.duration = object.duration >>> 0;
                            if (object.totalDuration != null)
                                message.totalDuration = object.totalDuration >>> 0;
                            if (object.startedAt != null) {
                                if (typeof object.startedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest.startedAt: object expected");
                                message.startedAt = $root.google.protobuf.Timestamp.fromObject(object.startedAt);
                            }
                            if (object.finishedAt != null) {
                                if (typeof object.finishedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest.finishedAt: object expected");
                                message.finishedAt = $root.google.protobuf.Timestamp.fromObject(object.finishedAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a FinishedDriverRest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest} message FinishedDriverRest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FinishedDriverRest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.requestId = "";
                                object.wshId = "";
                                object.typeId = "";
                                object.addressName = "";
                                object.duration = 0;
                                object.totalDuration = 0;
                                object.startedAt = null;
                                object.finishedAt = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                object.requestId = message.requestId;
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                object.wshId = message.wshId;
                            if (message.typeId != null && message.hasOwnProperty("typeId"))
                                object.typeId = message.typeId;
                            if (message.addressName != null && message.hasOwnProperty("addressName"))
                                object.addressName = message.addressName;
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                object.duration = message.duration;
                            if (message.totalDuration != null && message.hasOwnProperty("totalDuration"))
                                object.totalDuration = message.totalDuration;
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                object.startedAt = $root.google.protobuf.Timestamp.toObject(message.startedAt, options);
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt"))
                                object.finishedAt = $root.google.protobuf.Timestamp.toObject(message.finishedAt, options);
                            return object;
                        };

                        /**
                         * Converts this FinishedDriverRest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishedDriverRest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FinishedDriverRest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FinishedDriverRest;
                    })();

                    DriverRestProto.GetDriverRestTypesResponse = (function() {

                        /**
                         * Properties of a GetDriverRestTypesResponse.
                         * @memberof pro.megakit.amelia.operator.DriverRestProto
                         * @interface IGetDriverRestTypesResponse
                         * @property {Array.<pro.megakit.amelia.DriverRestProto.IDriverRestType>|null} [types] GetDriverRestTypesResponse types
                         */

                        /**
                         * Constructs a new GetDriverRestTypesResponse.
                         * @memberof pro.megakit.amelia.operator.DriverRestProto
                         * @classdesc Represents a GetDriverRestTypesResponse.
                         * @implements IGetDriverRestTypesResponse
                         * @constructor
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IGetDriverRestTypesResponse=} [properties] Properties to set
                         */
                        function GetDriverRestTypesResponse(properties) {
                            this.types = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * GetDriverRestTypesResponse types.
                         * @member {Array.<pro.megakit.amelia.DriverRestProto.IDriverRestType>} types
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse
                         * @instance
                         */
                        GetDriverRestTypesResponse.prototype.types = $util.emptyArray;

                        /**
                         * Creates a new GetDriverRestTypesResponse instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IGetDriverRestTypesResponse=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse} GetDriverRestTypesResponse instance
                         */
                        GetDriverRestTypesResponse.create = function create(properties) {
                            return new GetDriverRestTypesResponse(properties);
                        };

                        /**
                         * Encodes the specified GetDriverRestTypesResponse message. Does not implicitly {@link pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IGetDriverRestTypesResponse} message GetDriverRestTypesResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GetDriverRestTypesResponse.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.types != null && message.types.length)
                                for (var i = 0; i < message.types.length; ++i)
                                    $root.pro.megakit.amelia.DriverRestProto.DriverRestType.encode(message.types[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified GetDriverRestTypesResponse message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IGetDriverRestTypesResponse} message GetDriverRestTypesResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GetDriverRestTypesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a GetDriverRestTypesResponse message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse} GetDriverRestTypesResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GetDriverRestTypesResponse.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.types && message.types.length))
                                        message.types = [];
                                    message.types.push($root.pro.megakit.amelia.DriverRestProto.DriverRestType.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a GetDriverRestTypesResponse message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse} GetDriverRestTypesResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GetDriverRestTypesResponse.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a GetDriverRestTypesResponse message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        GetDriverRestTypesResponse.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.types != null && message.hasOwnProperty("types")) {
                                if (!Array.isArray(message.types))
                                    return "types: array expected";
                                for (var i = 0; i < message.types.length; ++i) {
                                    var error = $root.pro.megakit.amelia.DriverRestProto.DriverRestType.verify(message.types[i]);
                                    if (error)
                                        return "types." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a GetDriverRestTypesResponse message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse} GetDriverRestTypesResponse
                         */
                        GetDriverRestTypesResponse.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse();
                            if (object.types) {
                                if (!Array.isArray(object.types))
                                    throw TypeError(".pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse.types: array expected");
                                message.types = [];
                                for (var i = 0; i < object.types.length; ++i) {
                                    if (typeof object.types[i] !== "object")
                                        throw TypeError(".pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse.types: object expected");
                                    message.types[i] = $root.pro.megakit.amelia.DriverRestProto.DriverRestType.fromObject(object.types[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a GetDriverRestTypesResponse message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse} message GetDriverRestTypesResponse
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        GetDriverRestTypesResponse.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.types = [];
                            if (message.types && message.types.length) {
                                object.types = [];
                                for (var j = 0; j < message.types.length; ++j)
                                    object.types[j] = $root.pro.megakit.amelia.DriverRestProto.DriverRestType.toObject(message.types[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this GetDriverRestTypesResponse to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.GetDriverRestTypesResponse
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        GetDriverRestTypesResponse.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return GetDriverRestTypesResponse;
                    })();

                    DriverRestProto.StartDriverRestRequest = (function() {

                        /**
                         * Properties of a StartDriverRestRequest.
                         * @memberof pro.megakit.amelia.operator.DriverRestProto
                         * @interface IStartDriverRestRequest
                         * @property {string|null} [requestId] StartDriverRestRequest requestId
                         */

                        /**
                         * Constructs a new StartDriverRestRequest.
                         * @memberof pro.megakit.amelia.operator.DriverRestProto
                         * @classdesc Represents a StartDriverRestRequest.
                         * @implements IStartDriverRestRequest
                         * @constructor
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IStartDriverRestRequest=} [properties] Properties to set
                         */
                        function StartDriverRestRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StartDriverRestRequest requestId.
                         * @member {string} requestId
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest
                         * @instance
                         */
                        StartDriverRestRequest.prototype.requestId = "";

                        /**
                         * Creates a new StartDriverRestRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IStartDriverRestRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest} StartDriverRestRequest instance
                         */
                        StartDriverRestRequest.create = function create(properties) {
                            return new StartDriverRestRequest(properties);
                        };

                        /**
                         * Encodes the specified StartDriverRestRequest message. Does not implicitly {@link pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IStartDriverRestRequest} message StartDriverRestRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartDriverRestRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.requestId);
                            return writer;
                        };

                        /**
                         * Encodes the specified StartDriverRestRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IStartDriverRestRequest} message StartDriverRestRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartDriverRestRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StartDriverRestRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest} StartDriverRestRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartDriverRestRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.requestId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StartDriverRestRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest} StartDriverRestRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartDriverRestRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StartDriverRestRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StartDriverRestRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                if (!$util.isString(message.requestId))
                                    return "requestId: string expected";
                            return null;
                        };

                        /**
                         * Creates a StartDriverRestRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest} StartDriverRestRequest
                         */
                        StartDriverRestRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest();
                            if (object.requestId != null)
                                message.requestId = String(object.requestId);
                            return message;
                        };

                        /**
                         * Creates a plain object from a StartDriverRestRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest} message StartDriverRestRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StartDriverRestRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.requestId = "";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                object.requestId = message.requestId;
                            return object;
                        };

                        /**
                         * Converts this StartDriverRestRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StartDriverRestRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StartDriverRestRequest;
                    })();

                    DriverRestProto.StartDriverRestResponse = (function() {

                        /**
                         * Properties of a StartDriverRestResponse.
                         * @memberof pro.megakit.amelia.operator.DriverRestProto
                         * @interface IStartDriverRestResponse
                         * @property {string|null} [id] StartDriverRestResponse id
                         */

                        /**
                         * Constructs a new StartDriverRestResponse.
                         * @memberof pro.megakit.amelia.operator.DriverRestProto
                         * @classdesc Represents a StartDriverRestResponse.
                         * @implements IStartDriverRestResponse
                         * @constructor
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IStartDriverRestResponse=} [properties] Properties to set
                         */
                        function StartDriverRestResponse(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StartDriverRestResponse id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse
                         * @instance
                         */
                        StartDriverRestResponse.prototype.id = "";

                        /**
                         * Creates a new StartDriverRestResponse instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IStartDriverRestResponse=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse} StartDriverRestResponse instance
                         */
                        StartDriverRestResponse.create = function create(properties) {
                            return new StartDriverRestResponse(properties);
                        };

                        /**
                         * Encodes the specified StartDriverRestResponse message. Does not implicitly {@link pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IStartDriverRestResponse} message StartDriverRestResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartDriverRestResponse.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified StartDriverRestResponse message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IStartDriverRestResponse} message StartDriverRestResponse message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StartDriverRestResponse.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StartDriverRestResponse message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse} StartDriverRestResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartDriverRestResponse.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StartDriverRestResponse message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse} StartDriverRestResponse
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StartDriverRestResponse.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StartDriverRestResponse message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StartDriverRestResponse.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a StartDriverRestResponse message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse} StartDriverRestResponse
                         */
                        StartDriverRestResponse.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a StartDriverRestResponse message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse} message StartDriverRestResponse
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StartDriverRestResponse.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this StartDriverRestResponse to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.StartDriverRestResponse
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StartDriverRestResponse.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StartDriverRestResponse;
                    })();

                    DriverRestProto.FinishDriverRestRequest = (function() {

                        /**
                         * Properties of a FinishDriverRestRequest.
                         * @memberof pro.megakit.amelia.operator.DriverRestProto
                         * @interface IFinishDriverRestRequest
                         * @property {string|null} [id] FinishDriverRestRequest id
                         */

                        /**
                         * Constructs a new FinishDriverRestRequest.
                         * @memberof pro.megakit.amelia.operator.DriverRestProto
                         * @classdesc Represents a FinishDriverRestRequest.
                         * @implements IFinishDriverRestRequest
                         * @constructor
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IFinishDriverRestRequest=} [properties] Properties to set
                         */
                        function FinishDriverRestRequest(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FinishDriverRestRequest id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest
                         * @instance
                         */
                        FinishDriverRestRequest.prototype.id = "";

                        /**
                         * Creates a new FinishDriverRestRequest instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IFinishDriverRestRequest=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest} FinishDriverRestRequest instance
                         */
                        FinishDriverRestRequest.create = function create(properties) {
                            return new FinishDriverRestRequest(properties);
                        };

                        /**
                         * Encodes the specified FinishDriverRestRequest message. Does not implicitly {@link pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IFinishDriverRestRequest} message FinishDriverRestRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishDriverRestRequest.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified FinishDriverRestRequest message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.IFinishDriverRestRequest} message FinishDriverRestRequest message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishDriverRestRequest.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FinishDriverRestRequest message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest} FinishDriverRestRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishDriverRestRequest.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FinishDriverRestRequest message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest} FinishDriverRestRequest
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishDriverRestRequest.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FinishDriverRestRequest message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FinishDriverRestRequest.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates a FinishDriverRestRequest message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest} FinishDriverRestRequest
                         */
                        FinishDriverRestRequest.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest();
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from a FinishDriverRestRequest message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest} message FinishDriverRestRequest
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FinishDriverRestRequest.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.id = "";
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this FinishDriverRestRequest to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.DriverRestProto.FinishDriverRestRequest
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FinishDriverRestRequest.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FinishDriverRestRequest;
                    })();

                    return DriverRestProto;
                })();

                operator.VehicleRefillProto = (function() {

                    /**
                     * Properties of a VehicleRefillProto.
                     * @memberof pro.megakit.amelia.operator
                     * @interface IVehicleRefillProto
                     */

                    /**
                     * Constructs a new VehicleRefillProto.
                     * @memberof pro.megakit.amelia.operator
                     * @classdesc Represents a VehicleRefillProto.
                     * @implements IVehicleRefillProto
                     * @constructor
                     * @param {pro.megakit.amelia.operator.IVehicleRefillProto=} [properties] Properties to set
                     */
                    function VehicleRefillProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new VehicleRefillProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.operator.VehicleRefillProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IVehicleRefillProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.operator.VehicleRefillProto} VehicleRefillProto instance
                     */
                    VehicleRefillProto.create = function create(properties) {
                        return new VehicleRefillProto(properties);
                    };

                    /**
                     * Encodes the specified VehicleRefillProto message. Does not implicitly {@link pro.megakit.amelia.operator.VehicleRefillProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.operator.VehicleRefillProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IVehicleRefillProto} message VehicleRefillProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VehicleRefillProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified VehicleRefillProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.VehicleRefillProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.operator.VehicleRefillProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IVehicleRefillProto} message VehicleRefillProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VehicleRefillProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a VehicleRefillProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.operator.VehicleRefillProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.operator.VehicleRefillProto} VehicleRefillProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VehicleRefillProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.VehicleRefillProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a VehicleRefillProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.operator.VehicleRefillProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.operator.VehicleRefillProto} VehicleRefillProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VehicleRefillProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a VehicleRefillProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.operator.VehicleRefillProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    VehicleRefillProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a VehicleRefillProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.operator.VehicleRefillProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.operator.VehicleRefillProto} VehicleRefillProto
                     */
                    VehicleRefillProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.operator.VehicleRefillProto)
                            return object;
                        return new $root.pro.megakit.amelia.operator.VehicleRefillProto();
                    };

                    /**
                     * Creates a plain object from a VehicleRefillProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.operator.VehicleRefillProto
                     * @static
                     * @param {pro.megakit.amelia.operator.VehicleRefillProto} message VehicleRefillProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    VehicleRefillProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this VehicleRefillProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.operator.VehicleRefillProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    VehicleRefillProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    VehicleRefillProto.FinishedVehicleRefill = (function() {

                        /**
                         * Properties of a FinishedVehicleRefill.
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto
                         * @interface IFinishedVehicleRefill
                         * @property {string|null} [id] FinishedVehicleRefill id
                         * @property {string|null} [requestId] FinishedVehicleRefill requestId
                         * @property {string|null} [wshId] FinishedVehicleRefill wshId
                         * @property {pro.megakit.amelia.VehicleRefillProto.VehicleFuelType|null} [fuelType] FinishedVehicleRefill fuelType
                         * @property {number|null} [amount] FinishedVehicleRefill amount
                         * @property {number|null} [liters] FinishedVehicleRefill liters
                         * @property {number|Long|null} [mileage] FinishedVehicleRefill mileage
                         * @property {google.protobuf.ITimestamp|null} [filledAt] FinishedVehicleRefill filledAt
                         */

                        /**
                         * Constructs a new FinishedVehicleRefill.
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto
                         * @classdesc Represents a FinishedVehicleRefill.
                         * @implements IFinishedVehicleRefill
                         * @constructor
                         * @param {pro.megakit.amelia.operator.VehicleRefillProto.IFinishedVehicleRefill=} [properties] Properties to set
                         */
                        function FinishedVehicleRefill(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FinishedVehicleRefill id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @instance
                         */
                        FinishedVehicleRefill.prototype.id = "";

                        /**
                         * FinishedVehicleRefill requestId.
                         * @member {string} requestId
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @instance
                         */
                        FinishedVehicleRefill.prototype.requestId = "";

                        /**
                         * FinishedVehicleRefill wshId.
                         * @member {string} wshId
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @instance
                         */
                        FinishedVehicleRefill.prototype.wshId = "";

                        /**
                         * FinishedVehicleRefill fuelType.
                         * @member {pro.megakit.amelia.VehicleRefillProto.VehicleFuelType} fuelType
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @instance
                         */
                        FinishedVehicleRefill.prototype.fuelType = 0;

                        /**
                         * FinishedVehicleRefill amount.
                         * @member {number} amount
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @instance
                         */
                        FinishedVehicleRefill.prototype.amount = 0;

                        /**
                         * FinishedVehicleRefill liters.
                         * @member {number} liters
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @instance
                         */
                        FinishedVehicleRefill.prototype.liters = 0;

                        /**
                         * FinishedVehicleRefill mileage.
                         * @member {number|Long} mileage
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @instance
                         */
                        FinishedVehicleRefill.prototype.mileage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * FinishedVehicleRefill filledAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} filledAt
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @instance
                         */
                        FinishedVehicleRefill.prototype.filledAt = null;

                        /**
                         * Creates a new FinishedVehicleRefill instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleRefillProto.IFinishedVehicleRefill=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill} FinishedVehicleRefill instance
                         */
                        FinishedVehicleRefill.create = function create(properties) {
                            return new FinishedVehicleRefill(properties);
                        };

                        /**
                         * Encodes the specified FinishedVehicleRefill message. Does not implicitly {@link pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleRefillProto.IFinishedVehicleRefill} message FinishedVehicleRefill message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishedVehicleRefill.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.requestId);
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.wshId);
                            if (message.fuelType != null && message.hasOwnProperty("fuelType"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.fuelType);
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                writer.uint32(/* id 5, wireType 1 =*/41).double(message.amount);
                            if (message.liters != null && message.hasOwnProperty("liters"))
                                writer.uint32(/* id 6, wireType 1 =*/49).double(message.liters);
                            if (message.mileage != null && message.hasOwnProperty("mileage"))
                                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.mileage);
                            if (message.filledAt != null && message.hasOwnProperty("filledAt"))
                                $root.google.protobuf.Timestamp.encode(message.filledAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified FinishedVehicleRefill message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleRefillProto.IFinishedVehicleRefill} message FinishedVehicleRefill message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishedVehicleRefill.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FinishedVehicleRefill message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill} FinishedVehicleRefill
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishedVehicleRefill.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.requestId = reader.string();
                                    break;
                                case 3:
                                    message.wshId = reader.string();
                                    break;
                                case 4:
                                    message.fuelType = reader.int32();
                                    break;
                                case 5:
                                    message.amount = reader.double();
                                    break;
                                case 6:
                                    message.liters = reader.double();
                                    break;
                                case 7:
                                    message.mileage = reader.int64();
                                    break;
                                case 8:
                                    message.filledAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FinishedVehicleRefill message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill} FinishedVehicleRefill
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishedVehicleRefill.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FinishedVehicleRefill message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FinishedVehicleRefill.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                if (!$util.isString(message.requestId))
                                    return "requestId: string expected";
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                if (!$util.isString(message.wshId))
                                    return "wshId: string expected";
                            if (message.fuelType != null && message.hasOwnProperty("fuelType"))
                                switch (message.fuelType) {
                                default:
                                    return "fuelType: enum value expected";
                                case 0:
                                case 1:
                                    break;
                                }
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                if (typeof message.amount !== "number")
                                    return "amount: number expected";
                            if (message.liters != null && message.hasOwnProperty("liters"))
                                if (typeof message.liters !== "number")
                                    return "liters: number expected";
                            if (message.mileage != null && message.hasOwnProperty("mileage"))
                                if (!$util.isInteger(message.mileage) && !(message.mileage && $util.isInteger(message.mileage.low) && $util.isInteger(message.mileage.high)))
                                    return "mileage: integer|Long expected";
                            if (message.filledAt != null && message.hasOwnProperty("filledAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.filledAt);
                                if (error)
                                    return "filledAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a FinishedVehicleRefill message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill} FinishedVehicleRefill
                         */
                        FinishedVehicleRefill.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.requestId != null)
                                message.requestId = String(object.requestId);
                            if (object.wshId != null)
                                message.wshId = String(object.wshId);
                            switch (object.fuelType) {
                            case "PETROL":
                            case 0:
                                message.fuelType = 0;
                                break;
                            case "GAS":
                            case 1:
                                message.fuelType = 1;
                                break;
                            }
                            if (object.amount != null)
                                message.amount = Number(object.amount);
                            if (object.liters != null)
                                message.liters = Number(object.liters);
                            if (object.mileage != null)
                                if ($util.Long)
                                    (message.mileage = $util.Long.fromValue(object.mileage)).unsigned = false;
                                else if (typeof object.mileage === "string")
                                    message.mileage = parseInt(object.mileage, 10);
                                else if (typeof object.mileage === "number")
                                    message.mileage = object.mileage;
                                else if (typeof object.mileage === "object")
                                    message.mileage = new $util.LongBits(object.mileage.low >>> 0, object.mileage.high >>> 0).toNumber();
                            if (object.filledAt != null) {
                                if (typeof object.filledAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill.filledAt: object expected");
                                message.filledAt = $root.google.protobuf.Timestamp.fromObject(object.filledAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a FinishedVehicleRefill message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @static
                         * @param {pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill} message FinishedVehicleRefill
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FinishedVehicleRefill.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.requestId = "";
                                object.wshId = "";
                                object.fuelType = options.enums === String ? "PETROL" : 0;
                                object.amount = 0;
                                object.liters = 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.mileage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.mileage = options.longs === String ? "0" : 0;
                                object.filledAt = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.requestId != null && message.hasOwnProperty("requestId"))
                                object.requestId = message.requestId;
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                object.wshId = message.wshId;
                            if (message.fuelType != null && message.hasOwnProperty("fuelType"))
                                object.fuelType = options.enums === String ? $root.pro.megakit.amelia.VehicleRefillProto.VehicleFuelType[message.fuelType] : message.fuelType;
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
                            if (message.liters != null && message.hasOwnProperty("liters"))
                                object.liters = options.json && !isFinite(message.liters) ? String(message.liters) : message.liters;
                            if (message.mileage != null && message.hasOwnProperty("mileage"))
                                if (typeof message.mileage === "number")
                                    object.mileage = options.longs === String ? String(message.mileage) : message.mileage;
                                else
                                    object.mileage = options.longs === String ? $util.Long.prototype.toString.call(message.mileage) : options.longs === Number ? new $util.LongBits(message.mileage.low >>> 0, message.mileage.high >>> 0).toNumber() : message.mileage;
                            if (message.filledAt != null && message.hasOwnProperty("filledAt"))
                                object.filledAt = $root.google.protobuf.Timestamp.toObject(message.filledAt, options);
                            return object;
                        };

                        /**
                         * Converts this FinishedVehicleRefill to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.VehicleRefillProto.FinishedVehicleRefill
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FinishedVehicleRefill.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FinishedVehicleRefill;
                    })();

                    return VehicleRefillProto;
                })();

                operator.DriverPauseProto = (function() {

                    /**
                     * Properties of a DriverPauseProto.
                     * @memberof pro.megakit.amelia.operator
                     * @interface IDriverPauseProto
                     */

                    /**
                     * Constructs a new DriverPauseProto.
                     * @memberof pro.megakit.amelia.operator
                     * @classdesc Represents a DriverPauseProto.
                     * @implements IDriverPauseProto
                     * @constructor
                     * @param {pro.megakit.amelia.operator.IDriverPauseProto=} [properties] Properties to set
                     */
                    function DriverPauseProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new DriverPauseProto instance using the specified properties.
                     * @function create
                     * @memberof pro.megakit.amelia.operator.DriverPauseProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IDriverPauseProto=} [properties] Properties to set
                     * @returns {pro.megakit.amelia.operator.DriverPauseProto} DriverPauseProto instance
                     */
                    DriverPauseProto.create = function create(properties) {
                        return new DriverPauseProto(properties);
                    };

                    /**
                     * Encodes the specified DriverPauseProto message. Does not implicitly {@link pro.megakit.amelia.operator.DriverPauseProto.verify|verify} messages.
                     * @function encode
                     * @memberof pro.megakit.amelia.operator.DriverPauseProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IDriverPauseProto} message DriverPauseProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverPauseProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified DriverPauseProto message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverPauseProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof pro.megakit.amelia.operator.DriverPauseProto
                     * @static
                     * @param {pro.megakit.amelia.operator.IDriverPauseProto} message DriverPauseProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DriverPauseProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DriverPauseProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof pro.megakit.amelia.operator.DriverPauseProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {pro.megakit.amelia.operator.DriverPauseProto} DriverPauseProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverPauseProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverPauseProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DriverPauseProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof pro.megakit.amelia.operator.DriverPauseProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {pro.megakit.amelia.operator.DriverPauseProto} DriverPauseProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DriverPauseProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DriverPauseProto message.
                     * @function verify
                     * @memberof pro.megakit.amelia.operator.DriverPauseProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DriverPauseProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a DriverPauseProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof pro.megakit.amelia.operator.DriverPauseProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {pro.megakit.amelia.operator.DriverPauseProto} DriverPauseProto
                     */
                    DriverPauseProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.pro.megakit.amelia.operator.DriverPauseProto)
                            return object;
                        return new $root.pro.megakit.amelia.operator.DriverPauseProto();
                    };

                    /**
                     * Creates a plain object from a DriverPauseProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof pro.megakit.amelia.operator.DriverPauseProto
                     * @static
                     * @param {pro.megakit.amelia.operator.DriverPauseProto} message DriverPauseProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DriverPauseProto.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this DriverPauseProto to JSON.
                     * @function toJSON
                     * @memberof pro.megakit.amelia.operator.DriverPauseProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DriverPauseProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    DriverPauseProto.ActiveDriverPause = (function() {

                        /**
                         * Properties of an ActiveDriverPause.
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto
                         * @interface IActiveDriverPause
                         * @property {string|null} [id] ActiveDriverPause id
                         * @property {string|null} [wshId] ActiveDriverPause wshId
                         * @property {pro.megakit.amelia.DriverPauseProto.DriverPauseType|null} [type] ActiveDriverPause type
                         * @property {pro.megakit.amelia.DriverPauseProto.DriverPauseStatus|null} [status] ActiveDriverPause status
                         * @property {google.protobuf.ITimestamp|null} [createdAt] ActiveDriverPause createdAt
                         * @property {google.protobuf.ITimestamp|null} [startedAt] ActiveDriverPause startedAt
                         */

                        /**
                         * Constructs a new ActiveDriverPause.
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto
                         * @classdesc Represents an ActiveDriverPause.
                         * @implements IActiveDriverPause
                         * @constructor
                         * @param {pro.megakit.amelia.operator.DriverPauseProto.IActiveDriverPause=} [properties] Properties to set
                         */
                        function ActiveDriverPause(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ActiveDriverPause id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause
                         * @instance
                         */
                        ActiveDriverPause.prototype.id = "";

                        /**
                         * ActiveDriverPause wshId.
                         * @member {string} wshId
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause
                         * @instance
                         */
                        ActiveDriverPause.prototype.wshId = "";

                        /**
                         * ActiveDriverPause type.
                         * @member {pro.megakit.amelia.DriverPauseProto.DriverPauseType} type
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause
                         * @instance
                         */
                        ActiveDriverPause.prototype.type = 0;

                        /**
                         * ActiveDriverPause status.
                         * @member {pro.megakit.amelia.DriverPauseProto.DriverPauseStatus} status
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause
                         * @instance
                         */
                        ActiveDriverPause.prototype.status = 0;

                        /**
                         * ActiveDriverPause createdAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause
                         * @instance
                         */
                        ActiveDriverPause.prototype.createdAt = null;

                        /**
                         * ActiveDriverPause startedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} startedAt
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause
                         * @instance
                         */
                        ActiveDriverPause.prototype.startedAt = null;

                        /**
                         * Creates a new ActiveDriverPause instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverPauseProto.IActiveDriverPause=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause} ActiveDriverPause instance
                         */
                        ActiveDriverPause.create = function create(properties) {
                            return new ActiveDriverPause(properties);
                        };

                        /**
                         * Encodes the specified ActiveDriverPause message. Does not implicitly {@link pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverPauseProto.IActiveDriverPause} message ActiveDriverPause message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveDriverPause.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.wshId);
                            if (message.type != null && message.hasOwnProperty("type"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
                            if (message.status != null && message.hasOwnProperty("status"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.status);
                            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                $root.google.protobuf.Timestamp.encode(message.startedAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ActiveDriverPause message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverPauseProto.IActiveDriverPause} message ActiveDriverPause message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ActiveDriverPause.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an ActiveDriverPause message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause} ActiveDriverPause
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveDriverPause.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.wshId = reader.string();
                                    break;
                                case 3:
                                    message.type = reader.int32();
                                    break;
                                case 4:
                                    message.status = reader.int32();
                                    break;
                                case 5:
                                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                case 6:
                                    message.startedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an ActiveDriverPause message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause} ActiveDriverPause
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ActiveDriverPause.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an ActiveDriverPause message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ActiveDriverPause.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                if (!$util.isString(message.wshId))
                                    return "wshId: string expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                switch (message.type) {
                                default:
                                    return "type: enum value expected";
                                case 0:
                                    break;
                                }
                            if (message.status != null && message.hasOwnProperty("status"))
                                switch (message.status) {
                                default:
                                    return "status: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                    break;
                                }
                            if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.createdAt);
                                if (error)
                                    return "createdAt." + error;
                            }
                            if (message.startedAt != null && message.hasOwnProperty("startedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.startedAt);
                                if (error)
                                    return "startedAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates an ActiveDriverPause message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause} ActiveDriverPause
                         */
                        ActiveDriverPause.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.wshId != null)
                                message.wshId = String(object.wshId);
                            switch (object.type) {
                            case "BASE":
                            case 0:
                                message.type = 0;
                                break;
                            }
                            switch (object.status) {
                            case "CREATED":
                            case 0:
                                message.status = 0;
                                break;
                            case "STARTED":
                            case 1:
                                message.status = 1;
                                break;
                            case "FINISHED":
                            case 2:
                                message.status = 2;
                                break;
                            case "CANCELED":
                            case 3:
                                message.status = 3;
                                break;
                            }
                            if (object.createdAt != null) {
                                if (typeof object.createdAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause.createdAt: object expected");
                                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
                            }
                            if (object.startedAt != null) {
                                if (typeof object.startedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause.startedAt: object expected");
                                message.startedAt = $root.google.protobuf.Timestamp.fromObject(object.startedAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an ActiveDriverPause message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause} message ActiveDriverPause
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ActiveDriverPause.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.wshId = "";
                                object.type = options.enums === String ? "BASE" : 0;
                                object.status = options.enums === String ? "CREATED" : 0;
                                object.createdAt = null;
                                object.startedAt = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                object.wshId = message.wshId;
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = options.enums === String ? $root.pro.megakit.amelia.DriverPauseProto.DriverPauseType[message.type] : message.type;
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = options.enums === String ? $root.pro.megakit.amelia.DriverPauseProto.DriverPauseStatus[message.status] : message.status;
                            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                object.startedAt = $root.google.protobuf.Timestamp.toObject(message.startedAt, options);
                            return object;
                        };

                        /**
                         * Converts this ActiveDriverPause to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.ActiveDriverPause
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ActiveDriverPause.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ActiveDriverPause;
                    })();

                    DriverPauseProto.FinishedDriverPause = (function() {

                        /**
                         * Properties of a FinishedDriverPause.
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto
                         * @interface IFinishedDriverPause
                         * @property {string|null} [id] FinishedDriverPause id
                         * @property {string|null} [wshId] FinishedDriverPause wshId
                         * @property {pro.megakit.amelia.DriverPauseProto.DriverPauseType|null} [type] FinishedDriverPause type
                         * @property {pro.megakit.amelia.DriverPauseProto.DriverPauseStatus|null} [status] FinishedDriverPause status
                         * @property {number|null} [duration] FinishedDriverPause duration
                         * @property {google.protobuf.ITimestamp|null} [createdAt] FinishedDriverPause createdAt
                         * @property {google.protobuf.ITimestamp|null} [startedAt] FinishedDriverPause startedAt
                         * @property {google.protobuf.ITimestamp|null} [finishedAt] FinishedDriverPause finishedAt
                         */

                        /**
                         * Constructs a new FinishedDriverPause.
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto
                         * @classdesc Represents a FinishedDriverPause.
                         * @implements IFinishedDriverPause
                         * @constructor
                         * @param {pro.megakit.amelia.operator.DriverPauseProto.IFinishedDriverPause=} [properties] Properties to set
                         */
                        function FinishedDriverPause(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FinishedDriverPause id.
                         * @member {string} id
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @instance
                         */
                        FinishedDriverPause.prototype.id = "";

                        /**
                         * FinishedDriverPause wshId.
                         * @member {string} wshId
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @instance
                         */
                        FinishedDriverPause.prototype.wshId = "";

                        /**
                         * FinishedDriverPause type.
                         * @member {pro.megakit.amelia.DriverPauseProto.DriverPauseType} type
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @instance
                         */
                        FinishedDriverPause.prototype.type = 0;

                        /**
                         * FinishedDriverPause status.
                         * @member {pro.megakit.amelia.DriverPauseProto.DriverPauseStatus} status
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @instance
                         */
                        FinishedDriverPause.prototype.status = 0;

                        /**
                         * FinishedDriverPause duration.
                         * @member {number} duration
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @instance
                         */
                        FinishedDriverPause.prototype.duration = 0;

                        /**
                         * FinishedDriverPause createdAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @instance
                         */
                        FinishedDriverPause.prototype.createdAt = null;

                        /**
                         * FinishedDriverPause startedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} startedAt
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @instance
                         */
                        FinishedDriverPause.prototype.startedAt = null;

                        /**
                         * FinishedDriverPause finishedAt.
                         * @member {google.protobuf.ITimestamp|null|undefined} finishedAt
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @instance
                         */
                        FinishedDriverPause.prototype.finishedAt = null;

                        /**
                         * Creates a new FinishedDriverPause instance using the specified properties.
                         * @function create
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverPauseProto.IFinishedDriverPause=} [properties] Properties to set
                         * @returns {pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause} FinishedDriverPause instance
                         */
                        FinishedDriverPause.create = function create(properties) {
                            return new FinishedDriverPause(properties);
                        };

                        /**
                         * Encodes the specified FinishedDriverPause message. Does not implicitly {@link pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause.verify|verify} messages.
                         * @function encode
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverPauseProto.IFinishedDriverPause} message FinishedDriverPause message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishedDriverPause.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.wshId);
                            if (message.type != null && message.hasOwnProperty("type"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
                            if (message.status != null && message.hasOwnProperty("status"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.status);
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.duration);
                            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                $root.google.protobuf.Timestamp.encode(message.startedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt"))
                                $root.google.protobuf.Timestamp.encode(message.finishedAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified FinishedDriverPause message, length delimited. Does not implicitly {@link pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverPauseProto.IFinishedDriverPause} message FinishedDriverPause message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FinishedDriverPause.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FinishedDriverPause message from the specified reader or buffer.
                         * @function decode
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause} FinishedDriverPause
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishedDriverPause.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.wshId = reader.string();
                                    break;
                                case 3:
                                    message.type = reader.int32();
                                    break;
                                case 4:
                                    message.status = reader.int32();
                                    break;
                                case 5:
                                    message.duration = reader.uint32();
                                    break;
                                case 6:
                                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                case 7:
                                    message.startedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                case 8:
                                    message.finishedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FinishedDriverPause message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause} FinishedDriverPause
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FinishedDriverPause.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FinishedDriverPause message.
                         * @function verify
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FinishedDriverPause.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                if (!$util.isString(message.wshId))
                                    return "wshId: string expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                switch (message.type) {
                                default:
                                    return "type: enum value expected";
                                case 0:
                                    break;
                                }
                            if (message.status != null && message.hasOwnProperty("status"))
                                switch (message.status) {
                                default:
                                    return "status: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                    break;
                                }
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                if (!$util.isInteger(message.duration))
                                    return "duration: integer expected";
                            if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.createdAt);
                                if (error)
                                    return "createdAt." + error;
                            }
                            if (message.startedAt != null && message.hasOwnProperty("startedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.startedAt);
                                if (error)
                                    return "startedAt." + error;
                            }
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.finishedAt);
                                if (error)
                                    return "finishedAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a FinishedDriverPause message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause} FinishedDriverPause
                         */
                        FinishedDriverPause.fromObject = function fromObject(object) {
                            if (object instanceof $root.pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause)
                                return object;
                            var message = new $root.pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.wshId != null)
                                message.wshId = String(object.wshId);
                            switch (object.type) {
                            case "BASE":
                            case 0:
                                message.type = 0;
                                break;
                            }
                            switch (object.status) {
                            case "CREATED":
                            case 0:
                                message.status = 0;
                                break;
                            case "STARTED":
                            case 1:
                                message.status = 1;
                                break;
                            case "FINISHED":
                            case 2:
                                message.status = 2;
                                break;
                            case "CANCELED":
                            case 3:
                                message.status = 3;
                                break;
                            }
                            if (object.duration != null)
                                message.duration = object.duration >>> 0;
                            if (object.createdAt != null) {
                                if (typeof object.createdAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause.createdAt: object expected");
                                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
                            }
                            if (object.startedAt != null) {
                                if (typeof object.startedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause.startedAt: object expected");
                                message.startedAt = $root.google.protobuf.Timestamp.fromObject(object.startedAt);
                            }
                            if (object.finishedAt != null) {
                                if (typeof object.finishedAt !== "object")
                                    throw TypeError(".pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause.finishedAt: object expected");
                                message.finishedAt = $root.google.protobuf.Timestamp.fromObject(object.finishedAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a FinishedDriverPause message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @static
                         * @param {pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause} message FinishedDriverPause
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FinishedDriverPause.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.wshId = "";
                                object.type = options.enums === String ? "BASE" : 0;
                                object.status = options.enums === String ? "CREATED" : 0;
                                object.duration = 0;
                                object.createdAt = null;
                                object.startedAt = null;
                                object.finishedAt = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.wshId != null && message.hasOwnProperty("wshId"))
                                object.wshId = message.wshId;
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = options.enums === String ? $root.pro.megakit.amelia.DriverPauseProto.DriverPauseType[message.type] : message.type;
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = options.enums === String ? $root.pro.megakit.amelia.DriverPauseProto.DriverPauseStatus[message.status] : message.status;
                            if (message.duration != null && message.hasOwnProperty("duration"))
                                object.duration = message.duration;
                            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                object.startedAt = $root.google.protobuf.Timestamp.toObject(message.startedAt, options);
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt"))
                                object.finishedAt = $root.google.protobuf.Timestamp.toObject(message.finishedAt, options);
                            return object;
                        };

                        /**
                         * Converts this FinishedDriverPause to JSON.
                         * @function toJSON
                         * @memberof pro.megakit.amelia.operator.DriverPauseProto.FinishedDriverPause
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FinishedDriverPause.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FinishedDriverPause;
                    })();

                    return DriverPauseProto;
                })();

                return operator;
            })();

            return amelia;
        })();

        return megakit;
    })();

    return pro;
})();

$root.google = (function() {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    var google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        var protobuf = {};

        protobuf.Any = (function() {

            /**
             * Properties of an Any.
             * @memberof google.protobuf
             * @interface IAny
             * @property {string|null} [type_url] Any type_url
             * @property {Uint8Array|null} [value] Any value
             */

            /**
             * Constructs a new Any.
             * @memberof google.protobuf
             * @classdesc Represents an Any.
             * @implements IAny
             * @constructor
             * @param {google.protobuf.IAny=} [properties] Properties to set
             */
            function Any(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Any type_url.
             * @member {string} type_url
             * @memberof google.protobuf.Any
             * @instance
             */
            Any.prototype.type_url = "";

            /**
             * Any value.
             * @member {Uint8Array} value
             * @memberof google.protobuf.Any
             * @instance
             */
            Any.prototype.value = $util.newBuffer([]);

            /**
             * Creates a new Any instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny=} [properties] Properties to set
             * @returns {google.protobuf.Any} Any instance
             */
            Any.create = function create(properties) {
                return new Any(properties);
            };

            /**
             * Encodes the specified Any message. Does not implicitly {@link google.protobuf.Any.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny} message Any message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Any.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type_url != null && message.hasOwnProperty("type_url"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.type_url);
                if (message.value != null && message.hasOwnProperty("value"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.value);
                return writer;
            };

            /**
             * Encodes the specified Any message, length delimited. Does not implicitly {@link google.protobuf.Any.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny} message Any message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Any.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Any message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Any
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Any} Any
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Any.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Any();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.type_url = reader.string();
                        break;
                    case 2:
                        message.value = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Any message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Any
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Any} Any
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Any.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Any message.
             * @function verify
             * @memberof google.protobuf.Any
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Any.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type_url != null && message.hasOwnProperty("type_url"))
                    if (!$util.isString(message.type_url))
                        return "type_url: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                        return "value: buffer expected";
                return null;
            };

            /**
             * Creates an Any message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Any
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Any} Any
             */
            Any.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Any)
                    return object;
                var message = new $root.google.protobuf.Any();
                if (object.type_url != null)
                    message.type_url = String(object.type_url);
                if (object.value != null)
                    if (typeof object.value === "string")
                        $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                    else if (object.value.length)
                        message.value = object.value;
                return message;
            };

            /**
             * Creates a plain object from an Any message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.Any} message Any
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Any.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.type_url = "";
                    if (options.bytes === String)
                        object.value = "";
                    else {
                        object.value = [];
                        if (options.bytes !== Array)
                            object.value = $util.newBuffer(object.value);
                    }
                }
                if (message.type_url != null && message.hasOwnProperty("type_url"))
                    object.type_url = message.type_url;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                return object;
            };

            /**
             * Converts this Any to JSON.
             * @function toJSON
             * @memberof google.protobuf.Any
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Any.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Any;
        })();

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.seconds = reader.int64();
                        break;
                    case 2:
                        message.nanos = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                var message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Timestamp;
        })();

        return protobuf;
    })();

    return google;
})();

module.exports = $root;
