import { OnInit, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { createMarker } from '../utils/map';

@Component({
  selector: 'utax-map-home',
  template: ''
})
export class MapHomeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() parkingLocation: { lat: any; lng: any };
  @Input() mapComponent;

  map;
  marker;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.map = this.mapComponent.map;
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }
    this.marker = createMarker(this.parkingLocation, this.map, this.homeMarkerTemplate(), 'html', 1000);
  }

  ngOnDestroy() {
    if (this.map) {
      this.map.removeLayer(this.marker);
    }
  }

  private homeMarkerTemplate(): string {
    return `<div class="home-marker-container">
      <svg width="20" height="20" viewBox="0 0 81 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33.5" cy="33.0078" r="3.5" fill="#000204"/>
      <circle cx="46.5" cy="33.0078" r="3.5" fill="#000204"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M79.5367 34.5904L48.3402 3.39237C43.8155 -1.13079 36.4562 -1.13079 31.9314 3.39237L0.734916 34.5904C-0.244972 35.5703 -0.244972 37.1585 0.734916 38.1384C1.71324 39.1183 3.30301 39.1183 4.28133 38.1384L6.27089 36.1489V68.0383C6.27089 72.0214 9.49982 75.2503 13.4829 75.2503H66.7888C70.7718 75.2503 74.0008 72.0214 74.0008 68.0383V36.1489L75.9903 38.1384C76.4795 38.6276 77.1223 38.8737 77.7635 38.8737C78.4048 38.8737 79.0476 38.6276 79.5367 38.1384C80.5166 37.1585 80.5166 35.5703 79.5367 34.5904ZM22.5184 44.8332H57.2608C58.2196 44.8332 58.9978 45.608 58.9978 46.5702V61.3621C58.9978 65.5587 55 69.8734 51.0493 69.8734L41.6266 70.0471H38.1524L28.7298 69.8734C25 69.8734 20.7812 65.5587 20.7812 61.3621V46.5702C20.7812 45.6114 21.5595 44.8332 22.5184 44.8332ZM51.9102 15.9658L48.9814 22.8962C54.9432 26.18 58.9976 32.589 58.9978 39.9386C58.9978 40.911 58.2195 41.7003 57.2606 41.7003H22.5184C21.5595 41.7003 20.7812 40.911 20.7812 39.9386C20.7812 32.589 24.8357 26.18 30.8009 22.8962L27.8721 15.9658C27.4933 15.0743 27.8998 14.0384 28.7823 13.6544C29.6544 13.2633 30.6862 13.6826 31.0614 14.5811L33.9969 21.515C35.8557 20.902 37.836 20.5603 39.8927 20.5603C41.9495 20.5603 43.9298 20.902 45.7885 21.515L48.7173 14.5811C49.0925 13.6826 50.1243 13.2668 50.9964 13.6544C51.8789 14.0386 52.2888 15.0708 51.9102 15.9658ZM31.2038 32.8919C31.2038 33.8643 31.982 34.6536 32.9409 34.6536C33.8998 34.6536 34.6781 33.8643 34.6781 32.8919C34.6781 31.9194 33.8998 31.1301 32.9409 31.1301C31.982 31.1301 31.2038 31.9194 31.2038 32.8919ZM45.1008 32.8919C45.1008 33.8643 45.879 34.6536 46.8379 34.6536C47.7968 34.6536 48.5751 33.8643 48.5751 32.8919C48.5751 31.9194 47.7968 31.1301 46.8379 31.1301C45.879 31.1301 45.1008 31.9194 45.1008 32.8919Z" fill="black"/>
      </svg>
    </div>`;
  }
}
