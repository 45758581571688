import { Address } from 'src/app/interfaces/address.interface';
import * as _ from 'lodash';
import * as moment from 'moment';

function getTransformedAddress(address: Address) {
  if (address.id) {
    return { waypoint_id: address.id };
  }
  return {
    latitude: address.lat,
    longitude: address.lng,
    name: `${address.name}${address.cityWithAddresses && address.cityWithAddresses.name ? ', ' + address.cityWithAddresses.name : ''}`,
    relation_id: address.relation_id
  };
}

export function getWaypoints(waypoints) {
  const copy = _.cloneDeep(waypoints);
  return {
    start_point: getTransformedAddress(copy.shift()),
    end_point: getTransformedAddress(copy.pop()),
    waypoints: copy.map(w => getTransformedAddress(w))
  };
}

export function getPreorderInfoObj(formValue) {
  if (formValue.type.isPreorder) {
    const hours = formValue.type.preorderedTime.replace(':', '.').split('.')[0];
    const minutes = formValue.type.preorderedTime.replace(':', '.').split('.')[1];
    return {
      assigned_at_tz: moment(formValue.type.preorderedDate)
        .hours(hours)
        .minutes(minutes)
        .format('YYYY-MM-DD HH:mm:ss'),
      is_preorder: formValue.type.isPreorder
    };
  } else {
    return {
      is_preorder: formValue.type.isPreorder
    };
  }
}
