import { MessageToDriverComponent } from './../../../shared/components/modals/message-to-driver/message-to-driver.component';
import { OrderFormService } from './../../../order-form/services/order-form.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Filters } from './../../models/filters';
import { LogistOrdersService } from './../../services/logist-orders.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HeaderToolsHandlerService } from './../../../navigation/services/header-tools-handler.service';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef
} from '@angular/core';
import { LogistCabsService } from '../../services/logist-cabs.service';
import { MatDialog } from '@angular/material/dialog';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { OrderApiService, AmeliaService, SendingService } from '../../services';
import { SortOption } from '../../../utils/cluster-sort';
import * as _ from 'lodash';
import { OrdersService } from 'src/app/services/orders.service';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';
import { StateService } from 'src/app/services';
import { Service } from 'src/app/interfaces/order.interface';

@Component({
  selector: 'utax-logist',
  templateUrl: './logist.component.html',
  styleUrls: ['./logist.component.scss']
})
export class LogistComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public logistCabsService: LogistCabsService,
    private headerToolsHandlerService: HeaderToolsHandlerService,
    private dialog: MatDialog,
    private ameliaService: AmeliaService,
    private fb: UntypedFormBuilder,
    public logistOrdersService: LogistOrdersService,
    private cdRef: ChangeDetectorRef,
    private ordersApiService: OrderApiService,
    public orderFormService: OrderFormService,
    public ordersService: OrdersService,
    public sendingService: SendingService,
    private stateService: StateService
  ) {
    let logistFilterCost: number = +localStorage.getItem('logistFilterCost');
    let logistFilterPerKm: number;
    let logistFilterDistance: number;
    let logistFilterPreorderTime: number;

    if (!logistFilterCost) {
      localStorage.setItem('logistFilterCost', '25');
      localStorage.setItem('logistFilterPerKm', '5');
      localStorage.setItem('logistFilterDistance', '5');
      localStorage.setItem('logistFilterPreorderTime', '50');
      logistFilterCost = 25;
      logistFilterPerKm = 5;
      logistFilterDistance = 5;
      logistFilterPreorderTime = 50;
    } else {
      logistFilterCost = +localStorage.getItem('logistFilterCost');
      logistFilterPerKm = +localStorage.getItem('logistFilterPerKm');
      logistFilterDistance = +localStorage.getItem('logistFilterDistance');
      logistFilterPreorderTime = +localStorage.getItem('logistFilterPreorderTime');
    }
    this.filters = this.fb.group({
      cost: [logistFilterCost, [Validators.required, Validators.max(100)]],
      costPerKm: [logistFilterPerKm, Validators.required],
      distance: [logistFilterDistance, [Validators.max(10), Validators.required]],
      preorderTime: [logistFilterPreorderTime, [Validators.required]]
    });
    this.logistOrdersService.filters = {
      cost: logistFilterCost,
      costPerKm: logistFilterPerKm,
      distance: logistFilterDistance,
      preorderTime: logistFilterPreorderTime
    };
  }
  toggleDriversList = false;
  showMoreCabs = false;
  showExpandButton = true;
  showDelaySwitch = true;
  cabsQantity = 0;
  @ViewChild('controlMenu') controlMenu: TemplateRef<any>;
  @ViewChild('cabsContainer') cabsContainer: ElementRef<any>;

  services: Service[] = [];
  logistServiceFilters$: Observable<Service[]>;

  filters: UntypedFormGroup;
  shortcuts: ShortcutInput[] = [];
  private componentDestroyed$ = new Subject();

  sortOptions: SortOption<any>[] = [
    {
      priority: 1,
      order: 1,
      chracteristic: cab => cab.color === 'red',
      comparator: (a, b) => +a.callsign - +b.callsign
    },
    {
      priority: 2,
      order: 2,
      chracteristic: cab => cab.color === 'yellow',
      comparator: (a, b) => +a.callsign - +b.callsign
    },
    {
      priority: 3,
      order: 3,
      chracteristic: cab => cab.color === 'green',
      comparator: (a, b) => +a.callsign - +b.callsign
    }
  ];

  ngOnInit() {
    this.stateService.store.pipe(takeUntil(this.componentDestroyed$)).subscribe(store => {
      if (store.service && store.service.length > 0) {
        this.services = store.service.map(serv => ({ city: serv.city, name: serv.name, id: serv.id }));
        console.log('LogistComponent -> ngOnInit -> services', this.services);
        this.logistOrdersService.services = this.services;
        this.logistCabsService.enableService();
        this.logistOrdersService.enableService();
        this.ameliaService.enableService();
        this.logistCabsService.selectCab({ id: this.logistCabsService.selectedServiceId });
      }
    });
    this.filters.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe((filtersValue: Filters) => {
      localStorage.setItem('logistFilterCost', `${filtersValue.cost}`);
      localStorage.setItem('logistFilterPerKm', `${filtersValue.costPerKm}`);
      localStorage.setItem('logistFilterDistance', `${filtersValue.distance}`);
      localStorage.setItem('logistFilterPreorderTime', `${filtersValue.preorderTime}`);

      this.logistOrdersService.ordersFiltersChanges({
        cost: +filtersValue.cost,
        costPerKm: +filtersValue.costPerKm,
        distance: +filtersValue.distance,
        preorderTime: +filtersValue.preorderTime
      });
    });

    this.logistCabsService.visibleCabsSub$.pipe(takeUntil(this.componentDestroyed$)).subscribe(visibleCabs => {
      const service = _.head(
        _([...visibleCabs, ...this.logistCabsService.disabledCabs].map(cab => cab.service_id))
          .countBy()
          .entries()
          .maxBy(_.last)
      );
      this.sendingService.setTaxiService(service);
      if (this.cabsContainer) {
        this.checkOverflow(this.cabsContainer.nativeElement);
      }
    });
    this.logistServiceFiltersSub();
  }

  ngAfterViewInit(): void {
    this.headerToolsHandlerService.template.next({
      controlMenu: this.controlMenu,
      source: 'logist'
    });

    this.shortcuts.push(
      {
        key: ['left'],
        command: e => {
          this.logistCabsService.selectPreviosCab();
        },
        preventDefault: true
      },
      {
        key: ['right'],
        command: e => {
          this.logistCabsService.selectNextCab();
        },
        preventDefault: true
      },
      {
        key: ['down'],
        command: e => {
          this.logistOrdersService.ordersTableScroll$.next('down');
          this.ordersApiService.changeOrder('next');
        },
        preventDefault: true
      },
      {
        key: ['up'],
        command: e => {
          this.logistOrdersService.ordersTableScroll$.next('up');
          this.ordersApiService.changeOrder('previos');
        },
        preventDefault: true
      }
    );
  }

  log(a) {
    console.log(a);
  }

  trackByFn(i, obj) {
    return obj.id + obj.status;
  }

  ngOnDestroy(): void {
    this.logistCabsService.disableService();
    this.logistOrdersService.disableService();
    this.ameliaService.disableService();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  checkOverflow(element): void {
    this.showExpandButton =
      (this.showMoreCabs && element.offsetWidth === element.scrollWidth) || element.offsetWidth < element.scrollWidth;
  }

  selectCab(cab): void {
    console.log('PC: LogistComponent -> cab', cab);

    if (this.logistCabsService.selectedCabId !== cab.id) {
      this.ordersApiService.selectOrder(null);
    }
    this.logistCabsService.selectCab(cab);
    this.logistCabsService.clickedCab$.next(cab);
    this.cdRef.detectChanges();
  }

  selectService(event: number): void {
    this.logistCabsService.selectedServiceId = event;
    this.selectCab({ id: this.logistCabsService.selectedServiceId });
  }

  openMessageModal(): void {
    this.dialog.open(MessageToDriverComponent, {
      disableClose: true,
      panelClass: 'message-to-driver-modal-container'
    });
  }

  private logistServiceFiltersSub(): void {
    this.logistServiceFilters$ = combineLatest([
      this.stateService.getStoreParamSub('service'),
      this.logistCabsService.visibleCabsSub$
    ]).pipe(
      takeUntil(this.componentDestroyed$),
      debounceTime(200),
      filter(([services, cabs]) => {
        return services && cabs && services.length > 0 && cabs.length > 0;
      }),
      map(([services, cabs]) => {
        const servIdCabs = _.uniqBy(cabs, 'service_id');
        return services.filter(serv => servIdCabs.some(cab => cab.service_id === serv.id));
      }),
      tap(services => {
        if (!this.logistCabsService.selectedServiceId) {
          this.logistCabsService.selectedServiceId = services[0].id;
        }
      })
    );
  }
}
