import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {pluck} from 'rxjs/operators';
import {AutocolumnInterface} from '../interfaces/autocolumn.interface';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AutocolumnService {
  private autocolumnsUrl = 'operator/vehicle/groups';

  constructor(private http: HttpClient) { }

  public getAutocolumns(serviceId: number): Observable<AutocolumnInterface[]> {
    return this.http.get<AutocolumnInterface[]>(this.autocolumnsUrl, { params: { service_id: `${serviceId}` } }).pipe(pluck('data'));
  }

  public saveAutocolumn(autocolumn: AutocolumnInterface): Observable<AutocolumnInterface> {
    return this.http.put<AutocolumnInterface>(this.autocolumnsUrl, autocolumn);
  }

}
