import { HistoryService } from './../../../services/history.service';
import { LogistCabsService } from './../../services/logist-cabs.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { OrderApiService } from './../../services/order-api.service';
import { Component, OnInit } from '@angular/core';
import {HandleEventService, StateService} from 'src/app/services';
import { AmeliaService } from '../../services';
import { AmeliaApiService } from '../../services/amelia-api.service';
import { debounceTime, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { RequestHistoryModel } from '../../models/requests-history.model';
import * as _ from 'lodash';

@Component({
  selector: 'utax-logist-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  selectedOrder$: Subject<any>;
  selectedCab$: Subject<any>;
  autoscale = true;
  showAllDrivers = true;
  showDriverTrip = false;
  selectedTabIndex$ = new BehaviorSubject([]);
  requestshistory$: Observable<RequestHistoryModel[]>;

  constructor(
    private ordersApi: OrderApiService,
    private logistCabsService: LogistCabsService,
    public historyService: HistoryService,
    public state: StateService,
    public ameliaService: AmeliaService,
    private ameliaApiService: AmeliaApiService,
    public handleEventService: HandleEventService
  ) {
    this.selectedCab$ = this.logistCabsService.currentCabSub;
    this.selectedOrder$ = this.ordersApi.selectedOrder$;
  }

  ngOnInit() {
    this.requestHistorySub();
  }

  mapToggleChange(event: { type: string; state: boolean }): void {
    this[event.type] = event.state;
  }

  selectedIndexChange(event): void {
    this.selectedTabIndex$.next(event);
  }

  toggleHomeMode($event: { wsh_id: string; is_force_allowed: boolean }): void {
    this.ameliaApiService.toggleHomeMode($event).subscribe();
  }

  private requestHistorySub(): void {
    this.requestshistory$ = combineLatest([
      this.selectedCab$.pipe(
        distinctUntilChanged(this.distinctUntilChangedSelectCabFunc),
        filter((cab: any) => cab.id)
      ),
      this.ameliaService.workingShifts$.pipe(distinctUntilChanged()),
      this.selectedTabIndex$,
      this.ameliaService.requestConfirmEvent$.pipe(filter((res: boolean) => res))
    ]).pipe(
      debounceTime(100),
      filter(() => {
        return (this.selectedTabIndex$.value as any) === 3;
      }),
      map(([cab, workingShifts]) => workingShifts.find(wsh => wsh.cabId === cab.id)?.id),
      // distinctUntilChanged(),
      mergeMap((wshId: string) => {
        if (!wshId) {
          return of([]);
        } else {
          return this.ameliaApiService.getWorkingShiftRequestsHistory(wshId);
        }
      }),
      map((reqs: any[]) => reqs.reverse())
    );
  }

  private distinctUntilChangedSelectCabFunc = (prev: any, curr: any) => {
    if (prev.id === curr.id && prev.requests?.length === curr.requests?.length) {
      return true; // skip this
    }
    if (prev.id !== curr.id) {
      return false;
    }
    if (prev.id === curr.id && prev.requests?.length !== curr.requests?.length) {
      return false;
    }
    return prev.id === curr.id || prev.requests === curr.requests;
  };

  showSnack($event: boolean) {
    if ($event) {
      this.handleEventService.openSnackBar('UTAX_COPY_LINK_IN_BUFFER_SUCCESS');
    }
  }
}
