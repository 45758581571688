import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DispSalarySettingMode, DispSalaryTableMode } from '../../models';
import { DispSalaryService } from '../../services';
import {Observable} from 'rxjs';
import {ITaxiService} from '../../../../../models/global-data.interfaces';
import {map} from 'rxjs/operators';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'utax-disp-salary-controls',
  templateUrl: './disp-salary-controls.component.html',
  styleUrls: ['./disp-salary-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispSalaryControlsComponent implements OnInit {

  readonly dispSalarySettingMode = DispSalarySettingMode;
  readonly dispSalaryTableMode = DispSalaryTableMode;
  groups$: Observable<ITaxiService[]>;

  constructor(
    public dispSalaryService: DispSalaryService,
  ) { }

  ngOnInit(): void {
    this.groups$ = this.dispSalaryService.groups$.pipe(
      map((groups) => {
        const findFirstContact = groups.findIndex((group) => group.displayName === 'Перший контакт');
        const findSenior = groups.findIndex((group) => group.displayName === 'Старша');
        const findComplaint = groups.findIndex((group) => group.displayName === 'Скарги');
        if (findFirstContact !== -1) {
          groups.splice(0, 0, groups.splice(findFirstContact, 1)[0]);
        }
        if (findSenior !== -1) {
          groups.splice(findFirstContact !== -1 ? 1 : 0, 0, groups.splice(findSenior, 1)[0]);
        }
        if (findComplaint !== -1) {
          groups.splice(
            findSenior !== - 1 && findFirstContact !== -1 ? 2 : findSenior !== - 1 || findFirstContact !== -1 ? 1 : 0,
            0,
            groups.splice(findComplaint, 1)[0]);
        }
        return groups;
      })
    );
  }

  changeSettingMode(event: MatButtonToggleChange): void {
    this.dispSalaryService.settingMode = event.value;
  }

  changeTableMode(mode: DispSalaryTableMode): void {
    this.dispSalaryService.tableMode = mode;
  }

  changeGroup(event: MatSelectChange): void {
    this.dispSalaryService.selectedGroupUuid$.next(event.value);
  }

  save(): void {
    this.dispSalaryService.save();
  }

  cancel(): void {
    this.dispSalaryService.cancel();
  }
}
