import { ApiOrderFormService } from './api-order-form.service';
import { OrderFormService } from './order-form.service';
import { OrderModalService } from './order-modal.service';

export const services: any[] = [
  OrderFormService,
  ApiOrderFormService,
  OrderModalService
];

export * from './api-order-form.service';
export * from './order-form.service';
export * from './order-modal.service';
