import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { HandleEventService } from 'src/app/services';
import { BrandingType, BrandingTypeScale } from '../models/branding.model';

const brandingTypesUrl = 'operator/vehicle/branding/types',
  brandingScalesUrl = 'operator/tariff-plans/branding/scales',
  brandingScalseByService = 'operator/branding/scales';

@Injectable()
export class BrandingApiService {
  brandingTypes$: BehaviorSubject<BrandingType[]> = new BehaviorSubject([]);
  private brandingTypes: BrandingType[] = [];
  brandingTypeScales$: BehaviorSubject<BrandingTypeScale[]> = new BehaviorSubject([]);
  private brandingTypeScales: BrandingTypeScale[] = [];

  constructor(private http: HttpClient, private handleEventService: HandleEventService) {}

  getBrandingTypes(): void {
    this.http
      .get(brandingTypesUrl)
      .pipe(pluck('data'))
      .subscribe((res: BrandingType[]) => {
        this.brandingTypes$.next(res);
        this.brandingTypes = res;
      });
  }

  postBrandingType(name: string): void {
    this.http
      .post(brandingTypesUrl, { name })
      .pipe(pluck('data'))
      .subscribe((res: BrandingType) => {
        this.brandingTypes.push(res);
        this.brandingTypes$.next(this.brandingTypes);

        this.handleEventService.openSnackBar('UTAX_FRONT_BRANDING_TYPE_CREATED');
      });
  }

  putBrandingType(name: string, id: string): void {
    this.http
      .put(brandingTypesUrl, { name, id })
      .pipe(pluck('data'))
      .subscribe((res: BrandingType) => {
        this.brandingTypes = this.brandingTypes.map(type => (type.id === res.id ? res : type));
        this.brandingTypes$.next(this.brandingTypes);

        this.handleEventService.openSnackBar('UTAX_FRONT_BRANDING_TYPE_UPDATED');
      });
  }

  deleteBrandingType(id: string): void {
    this.http.delete(brandingTypesUrl, { params: { id } }).subscribe(() => {
      this.brandingTypes = this.brandingTypes.filter(type => type.id !== id);
      this.brandingTypes$.next(this.brandingTypes);

      this.handleEventService.openSnackBar('UTAX_FRONT_BRANDING_TYPE_DELETED');
    });
  }

  getBrandingScalesByTariffId(tariffPlanId: string): void {
    this.http
      .get(brandingScalesUrl, { params: { tariff_plan_id: tariffPlanId } })
      .pipe(pluck('data'))
      .subscribe((res: BrandingTypeScale[]) => {
        this.brandingTypeScales = res;
        this.brandingTypeScales$.next(res);
      });
  }

  getBrandingScalesByService(serviceId, viewMode: string): void {
    this.http
      .get(brandingScalseByService, { params: { service_id: serviceId, period_type: viewMode } })
      .pipe(pluck('data'))
      .subscribe((res: BrandingTypeScale[]) => {
        this.brandingTypeScales = res;
        this.brandingTypeScales$.next(res);
      });
  }

  postBrandingScale(body: {
    requests_quantity: number;
    value: number;
    branding_type_id: string;
    tariff_plan_id: string;
    is_enabled: boolean;
    period_type: string;
    service_id: number;
  }): void {
    this.http
      .post(brandingScalseByService, body)
      .pipe(pluck('data'))
      .subscribe((res: BrandingTypeScale) => {
        this.brandingTypeScales.push(res);
        this.brandingTypeScales$.next(this.brandingTypeScales);

        this.handleEventService.openSnackBar('UTAX_FRONT_BRANDING_TYPE_SCALE_CREATED');
      });
  }

  putBrandingScale(body: {
    id: string;
    requests_quantity: number;
    value: number;
    branding_type_id: string;
    is_enabled: boolean;
    period_type: string;
  }): void {
    this.http
      .put(brandingScalseByService, body)
      .pipe(pluck('data'))
      .subscribe((res: BrandingTypeScale) => {
        this.brandingTypeScales = this.brandingTypeScales.map(scale => (scale.id === res.id ? res : scale));
        this.brandingTypeScales$.next(this.brandingTypeScales);
        this.handleEventService.openSnackBar('UTAX_FRONT_BRANDING_TYPE_SCALE_UPDATED');
      });
  }

  deleteScale(id: string): void {
    this.http.delete(brandingScalseByService, { params: { id } }).subscribe(() => {
      this.brandingTypeScales = this.brandingTypeScales.filter(scale => scale.id !== id);
      this.brandingTypeScales$.next(this.brandingTypeScales);
      this.handleEventService.openSnackBar('UTAX_FRONT_BRANDING_TYPE_SCALE_DELETED');
    });
  }
}
