import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Client } from './client';
import {Observable, of} from 'rxjs';
import { pluck, tap } from 'rxjs/operators';
import {CarDataInterface} from "../../autocolumn/vehicles/models/vehicles.interface";

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  vehicleModelsObjs = [];

  constructor(private http: HttpClient, private client: Client) {}

  getVehicles(obj): Observable<CarDataInterface> {
    return this.http.get<CarDataInterface>(this.client.getVehiclesUrl(), { params: new HttpParams({ fromObject: obj }) });
  }

  getColor() {
    return this.http.get(this.client.getColorUrl());
  }

  getCountry() {
    return this.http.get(this.client.getCountryUrl());
  }

  getVehicleBody() {
    return this.http.get(this.client.getVehicleBodyTypesUrl());
  }

  getVehicleBrands() {
    return this.http.get(this.client.getVehicleBrandsUrl());
  }

  getVehicleBrandAndModel(modelId: number): Observable<any> {
    const vehicleModelObj: any = (this.vehicleModelsObjs || []).find(item => item.id === modelId);
    if (vehicleModelObj) {
      return of(vehicleModelObj);
    } else {
      return this.http
        .get<any>(this.client.getVehicleBrandUrl(), { params: { id: `${modelId}` } })
        .pipe(
          pluck('data'),
          tap((data: any) => {
            if (!this.vehicleModelsObjs.some(item => item.id === modelId)) {
              this.vehicleModelsObjs.push(data);
            }
          })
        );
    }
  }

  getVehicleModel(obj) {
    return this.http.get(this.client.getVehicleModelUrl(obj));
  }

  getVehicleClasses() {
    return this.http.get(this.client.getVehicleClassesUrl());
  }

  getVehicleDrivers(obj) {
    return this.http.get(this.client.getVehicleDriversUrl(obj));
  }

  getVehicleGroups(obj) {
    return this.http.get(this.client.getVehicleGroupsUrl(obj));
  }

  editVehicle(obj) {
    return this.http.put(this.client.VehicleUpdateUrl(), obj);
  }

  addNewBrand(name) {
    return this.http.post('operator/dictionary/admin/vehicle-brands', { name });
  }

  addNewModel(name, brand_id) {
    return this.http.post('operator/dictionary/admin/vehicle-models', { name, brand_id });
  }

  deleteVehicle(obj) {
    return this.http.delete(this.client.getVehicleDeleteUrl(obj));
  }

  getFeatures(obj): Observable<any> {
    return this.http.get( this.client.getFeaturesUrl(obj) );
  }

  getTemporaryId(service_id): Observable<any> {
    return this.http.post('operator/vehicles/temporary', {service_id});
  }

  createVehicleGroup(obj, service_id) {
    return this.http.post(this.client.createVehicleGroupUrl(service_id), obj);
  }

  updateVehicleGroup(obj) {
    return this.http.put(this.client.updateVehicleGroupUrl(), obj);
  }

  getVehiclesQuantity(obj) {
    return this.http.get(this.client.getVehicleGroupCountUrl(obj));
  }

  moveVehicleGroup(obj) {
    return this.http.post(this.client.moveVehicleGroupUrl(obj.service_id), obj);
  }

  deleteGroup(obj) {
    return this.http.delete(this.client.updateVehicleGroupUrl(), { params: obj });
  }

  }
