import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tariff'
})
export class TariffPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return  value.replace(/[^A-Za-zА-я]/g, '').charAt(0);
  }

}
