import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable, of, Subject, throwError, timer} from 'rxjs';
import {
  Invitation,
  Inviter,
  Registerer,
  Session,
  SessionState,
  SIPExtension,
  UserAgent,
  UserAgentOptions,
  Web
} from 'sip.js';
import {CallWayCall} from 'src/app/dispatcher/models/dispatcher';
import {HandleEventService} from '@global-services/handle-event-service.service';
import {getInfoAbountIncomingCall} from '../utils/telephony';
import {TelephonyApiService} from './telephony-api.service';
import {CallWayService} from '../../dispatcher/services/call-way.service';
import {normalizePhoneNumber} from 'src/app/shared/utils/normalize-number';
import {catchError, map, skip, tap} from 'rxjs/operators';
import {TelephonyWsService} from './telephony-ws.service';
import {TelephonyServerService} from './telephony-server.service';
import {environment} from '@global-environments/environment';
import {RemoteWorkService} from '../../cabinet/services';
import {Router} from '@angular/router';
import {TelephonyIncomingService} from './telephony-incoming.service';
import {TelephonyRatingInterface} from '../models/telephony.interface';
import {GlobalDataService} from '../../services/global-data.service';
import {StateService} from '@global-services/state.service';
import {DispOrdersService} from "../../dispatcher/services";
import {OrdersService} from "@global-services/orders.service";

@Injectable()
export class TelephonyService {
  settingsForm: UntypedFormGroup;
  connected$: BehaviorSubject<any> = new BehaviorSubject(false);
  closeModal$ = new Subject();
  sessions: any = {};
  sessions$: BehaviorSubject<any> = new BehaviorSubject([]);
  pauseStatus$ = new BehaviorSubject(false);
  enterStatus$ = new BehaviorSubject(false);
  public currentModifier$: BehaviorSubject<number> = new BehaviorSubject(null);
  isTransferCall$ = new BehaviorSubject(false);
  telephonyRating$: BehaviorSubject<TelephonyRatingInterface> = new BehaviorSubject<TelephonyRatingInterface>(null);
  private ratingCallTimeoutId;
  public lastCallStream$: BehaviorSubject<TelephonyRatingInterface> = new BehaviorSubject<TelephonyRatingInterface>(null);
  public lastCallDirection: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private connectTimeoutId;
  public timerRating$: Observable<number> = timer(5000);

  private connected = false;
  private user: UserAgent;
  private operatorStatus: any;
  private repetCountToConnect: 0;

  constructor(
    private fb: UntypedFormBuilder,
    private state: StateService,
    private handleEventService: HandleEventService,
    private telephonyApiService: TelephonyApiService,
    private telephonyWsService: TelephonyWsService,
    private telephonyServerService: TelephonyServerService,
    private callWayService: CallWayService,
    private remoteWorkService: RemoteWorkService,
    private telephonyIncomingService: TelephonyIncomingService,
    private router: Router,
    private globalDataService: GlobalDataService,
    private dispOrdersService: DispOrdersService,
    private ordersService: OrdersService,
  ) {
    this.telephonyServerService.voipServer$
      .pipe(skip(1))
      .subscribe(server => {
        if (this.settingsForm && server) {
          console.log('🚀 ~ disconnect ~ this.user', this.user);
          if (this.operatorStatus) {
            this.operatorStatus.close();
          }
          this.disconnectTelephony();
          this.settingsForm.patchValue({
            server: server.connectionUrl,
            realm: this.getRealm(server.connectionUrl)
          });
          if (typeof this.connectTimeoutId === 'number') {
            clearTimeout(this.connectTimeoutId);
            this.connectTimeoutId = null;
          }
          this.connectTimeoutId = setTimeout(() => this.connect(), 2000);
        } else if (server === null) {
          this.connected$.next(false);
          this.operatorStatus?.onBinary().subscribe(status => {
            if (status.type && status.type === 'ASTERISK_SERVER_LIST_UPDATED') {
              this.telephonyServerService.checkAsteriskServer(status.data);
            }
          });
        }
    });
  }

  connect(): void {
    console.log('0 connect');
    this.generateForm().subscribe(res => {
      if (!this.connected && this.settingsForm && this.settingsForm.valid) {
        this.createUser();
        const registerer = new Registerer(this.user);
        this.user
          .start()
          .then(start => {
            console.log('🚀 ~ start', start);
            return registerer.register().catch(error => {
              console.log('~Registerer error', error);
              if (this.repetCountToConnect < 10) {
                setTimeout(() => {
                  this.repetCountToConnect++;
                  this.connect();
                }, 30000);
              }
            });
          })
          .then((reg: any) => {
            console.log('🚀 ~ reg', reg);
          });

        registerer.stateChange.addListener(regState => {
          console.log('🚀 ~~ regState', regState);
          this.connected = regState === 'Registered';
          this.connected$.next(this.connected);
          if (!this.connected) {
            this.sessions = {};
            this.sessions$.next(this.sessions);
            console.log('🚀 8 sessions$.next');
          } else {
            // this.telephonyWsService.connectOperatorStatus();
            this.operatorStatus = this.telephonyWsService.connectOperatorStatus();
            this.operatorStatus.onBinary().subscribe(status => {
              if (status.type && status.type === 'ASTERISK_SERVER_LIST_UPDATED') {
                this.telephonyServerService.checkAsteriskServer(status.data);
              }
              if (status && status.type === 'ACTIVE_SALARY_MULTIPLIER_CHANGED') {
                this.currentModifier$.next(status.data.activeSalaryMultiplier ? status.data.activeSalaryMultiplier.multiplier : null);
              } else if (status && status?.type.includes('CALL_CONVERSATION')) {
                  this.ordersService.changeOrderCallOperatorStatus(status.data);
              } else if (status.type && status.type === 'OPERATOR_STATUS_CHANGED') {
                const statusValue = status?.data?.workStatus === 'PAUSED' || status?.data?.workStatus === 'WALKED_AWAY';
                this.pauseStatus$.next(statusValue);
                if (status.data.workShiftStatus !== 'OPEN' &&
                  Object.keys(this.sessions$.value).length == 0
                ) {
                  this.startWorkingShift().subscribe(() => {
                    this.remoteWorkService.workState$.next(true);
                    console.log('telephony connect true');
                    localStorage.setItem('telephonyConnect', `true`);
                  });
                } else {
                  localStorage.setItem('telephonyConnect', `true`);
                }
              }
            });
          }
        });
      } else {
        if (this.operatorStatus) {
          this.operatorStatus = this.telephonyWsService.connectOperatorStatus();
          this.operatorStatus.onBinary().subscribe(status => {


            if (status && status.type === 'ACTIVE_SALARY_MULTIPLIER_CHANGED') {
              this.currentModifier$.next(status.data.activeSalaryMultiplier ? status.data.activeSalaryMultiplier.multiplier : null);
            } else if (status && status?.type.includes('CALL_CONVERSATION')) {
                this.ordersService.changeOrderCallOperatorStatus(status.data);
            } else if (status.type && status.type === 'OPERATOR_STATUS_CHANGED') {
              const statusValue = status?.data?.workStatus === 'PAUSED' || status?.data?.workStatus === 'WALKED_AWAY';
              this.pauseStatus$.next(statusValue);
            }


          });
        }
      }
      console.log('🚀 connect ~ this.connected', this.connected, this.settingsForm, this.settingsForm.valid);
    });
  }

  disconnect(): Observable<any> {
    console.log('🚀 ~ disconnect ~ this.user', this.user);
    if (this.operatorStatus) {
      this.operatorStatus.close();
    }
    return this.closeWorkingShift().pipe(
      tap(() => {
        this.disconnectTelephony();
      })
    );
  }

  disconnectTelephony() {
    if (this.user) {
      this.user.stop();
      this.connected$.next(false);
      this.sessions = {};
      this.sessions$.next(this.sessions);
      console.log('🚀 9 sessions$.next');
      localStorage.setItem('telephonyConnect', `false`);
    }
  }

  generateForm(): Observable<any> {
    if (!this.settingsForm && this.state.hasCallCentrePerms()) {
      return this.telephonyApiService.getOperatorCredentials()
        .pipe(
          tap(config => {
            const localstorValue = JSON.parse(localStorage.getItem('telephonySettings'));
            console.log('🚀 ~ erateForm ~ this.state.dumbStore', this.state.dumbStore);
            const server = this.telephonyServerService.voipServer$?.value?.connectionUrl;
            this.settingsForm = this.fb.group({
              login: [config.username, [Validators.required]],
              password: [config.password, [Validators.required]],
              id: [config.id],
              server: [server, [Validators.required]],
              realm: [this.getRealm(server), [Validators.required]],
            });
            console.log('🚀 ~ erateForm ~ this.settingsForm', this.settingsForm);
          }),
          map(() => true)
        );
    } else {
      return of(false);
    }
  }

  private getRealm(url: string): string {
    return url?.match(/wss:\/\/(.*?)\/ws/)[0]
      .split(':')[0];
  }

  makeCall(phoneNumber: any, userToCall, reCall?: boolean): void {
    if (this.connected && !this.isSomeSessionEstablishing()) {
      sessionStorage.setItem(
        'lastCall',
        JSON.stringify({ phone: phoneNumber, user: userToCall })
      );
      // connected and not calling now
      const formValue = this.settingsForm.value;
      // const target = UserAgent.makeURI(`sip:*101@${formValue.realm}`); // 1009
      // const target = UserAgent.makeURI(`sip:${normalizePhoneNumber(phoneNumber)}@${formValue.realm}`); // 1009
      let target; // 1009
      if (reCall) {
        target = UserAgent.makeURI(`sip:${normalizePhoneNumber(phoneNumber)}@${formValue.realm}`);
      } else if (userToCall.orderId) {
        target = UserAgent.makeURI(`sip:*200*${normalizePhoneNumber(phoneNumber)}*${userToCall.orderId}@${formValue.realm}`);
      } else {
        target = UserAgent.makeURI(`sip:*200*${normalizePhoneNumber(phoneNumber)}@${formValue.realm}`);
      }
      const inviter: any = new Inviter(this.user, target, {
        earlyMedia: true,
      });
      // this.handleEventService.makeNotifiSound('telephony-outcome', inviter.id);
      inviter.stateChange.addListener((state: SessionState) => {
        console.log('🚀 Session state changed to', state);
        switch (state) {
          case SessionState.Initial:
            break;
          case SessionState.Establishing:
            // trying / calling
            const remoteStream = new MediaStream();
            inviter.sessionDescriptionHandler.peerConnection.getReceivers().forEach(receiver => {
              if (receiver.track) {
                remoteStream.addTrack(receiver.track);
              }
            });
            this.sessions[inviter.id].remoteStream = remoteStream;
            this.sessions[inviter.id].callInRinging = true;
            this.sessions$.next(this.sessions);
            break;
          case SessionState.Established:
            if (this.sessions[inviter.id]) {
              this.sessions[inviter.id].outcomeCall = {phoneNumber, start: true};
            }
            this.setupRemoteMedia(inviter, true);
            this.lockRatingButton();
            if (environment.liteVersion && userToCall.orderId) {
              const prevReqViewOrdLite = this.dispOrdersService.requiredViewOrdersLite$.value;
              if (!prevReqViewOrdLite.length ||
                prevReqViewOrdLite.length && !prevReqViewOrdLite.some(req => req.requestId === userToCall.orderId)) {
                prevReqViewOrdLite.push({requestId: userToCall.orderId});
                this.dispOrdersService.requiredViewOrdersLite$.next(prevReqViewOrdLite);
              }
            }
            // this.lastCallDirection.next(`outcome`);
            // this.handleEventService.pauseSound(inviter.id);
            break;
          case SessionState.Terminating:
            if (this.sessions[inviter.id]) {
              this.sessions[inviter.id].outcomeCall = {phoneNumber: 0, start: false};
            }
            this.closeModal$.next({close: true, inviter});
            // this.handleEventService.pauseSound(inviter.id);
            this.cleanupMedia(inviter.id);
            break;
          case SessionState.Terminated:
            // this.handleEventService.pauseSound(inviter.id);
            this.cleanupMedia(inviter.id);
            break;
          default:
            throw new Error('🚀 Unknown session state.');
        }
      });
      inviter.invite().catch(error => {
        if (`${error}`.indexOf('Requested device not found') !== -1) {
          this.handleEventService.openSnackBar('Микрофон не обнаружен', 15);
        } else if (`${error}`.indexOf('Permission denied') !== -1) {
          this.handleEventService.openSnackBar('Доступ к микрофону заблокирован', 15);
        }
      });
      const currentSessions = Object.keys(this.sessions);
      if (currentSessions?.length) {
        currentSessions.forEach(id => {
          if (!this.sessions[id].hold) {
            this.holdUnholdCall(id);
          }
        });
      }
      this.sessions[inviter.id] = inviter;
      this.sessions[inviter.id].outcomeCall = {phoneNumber, start: false};
      this.sessions[inviter.id].outcomePassOrDriver = userToCall;
      this.sessions$.next(this.sessions);

      console.log('🚀 10 sessions$.next');
      console.log('🚀 makeCall ~ this.session', this.sessions);
    }
  }

  endCall(id: string): void {
    console.log('🚀 ~ ~ endCall ~ this.sessions', this.sessions);
    if (this.sessions[id]) {
      switch (this.sessions[id].state) {
        case SessionState.Initial:
        case SessionState.Establishing: {
          if (this.sessions[id] instanceof Inviter) {
            // An unestablished outgoing session
            if (this.sessions[id]?.incomingPassOrDriver?.prevQueue && this.isTransferCall$.value ) {
              this.isTransferCall$.next(false);
            }
            (this.sessions[id] as any).cancel();
            // this.isTransferCall$.next(false);

          } else {
            // An unestablished incoming session
            if (this.sessions[id]?.incomingPassOrDriver?.prevQueue && this.isTransferCall$.value ) {
              this.isTransferCall$.next(false);
            }
            (this.sessions[id] as any).reject();
            // this.isTransferCall$.next(false);
          }
          break;
        }
        case SessionState.Established:
          // An established session
          this.sessions[id].bye();
          this.lockRatingButton(true);
          break;
        case SessionState.Terminating:
        case SessionState.Terminated:
          // Cannot terminate a session that is already terminated
          break;
      }
    }
  }

  holdUnholdCall(id): void {
    const options: any = {};
    if (this.sessions[id].hold) {
      options.sessionDescriptionHandlerModifiers = [];
    } else {
      options.sessionDescriptionHandlerModifiers = [Web.holdModifier];
    }
    this.sessions[id].invite(options).then(res => {
      // this.hold[id] = !this.hold[id];
      console.log('🚀 holdCall', this.sessions[id].hold);
    });
    this.sessions[id].hold = !this.sessions[id].hold;
    this.sessions$.next(this.sessions);
    this.handleEventService.openSnackBar(this.sessions[id].hold ? 'UTAX_CURRENT_CALL_ON_PAUSE' : 'UTAX_CURRENT_CALL_ON_RESUMED');
    console.log('🚀 11 sessions$.next');
  }

  transferCall(sessionId: string, data: any): void {
    console.log(data);
    console.log('!!!!!!!!!');
    // Send an outgoing REFER request
    if (sessionId === 'first') {
      const session: any = Object.values(this.sessions)[0];
      if (session) {
        const transferTarget = this.getUserAgent(data, session.id);
        this.sessions[session.id].refer(transferTarget).then(res => {
          console.log('🚀 ~  ~ res transferTarget', res, this.sessions);
        });
        this.endCall(session.id);
        this.handleEventService.openSnackBar('TRANSFER_SENT');
      }
    } else if (this.sessions[sessionId]) {
      const transferTarget = this.getUserAgent(data, sessionId);
      this.sessions[sessionId].refer(transferTarget).then(res => {
        console.log('🚀 ~  ~ res transferTarget', res, this.sessions);
      });
      this.endCall(sessionId);
    }
  }

  setConference(): void {
    const sessions = Object.values(this.sessions);
    if (sessions.length === 2) {
      if (sessions[0] && sessions[1]) {
        // start setting conference
        const formValue = this.settingsForm.value;
        const UUID = this.generator();
        const transferTargetFirst = UserAgent.makeURI(`sip:*102*${UUID}@${formValue.realm}`);
        const transferTargetSecond = UserAgent.makeURI(`sip:*103*${UUID}@${formValue.realm}`);
        this.sessions[(sessions[0] as Session).id].refer(transferTargetFirst, {
          onNotify: () => this.endCall((sessions[0] as Session).id)
        })
          .then(res => {
            console.log('🚀 ~  ~ set first sessions');
          });
        this.sessions[(sessions[1] as Session).id].refer(transferTargetSecond, {
          onNotify: () => this.endCall((sessions[1] as Session).id)
        })
          .then(res => {
            console.log('🚀 ~  ~ set second sessions');
          });
        // end setting conference

        // this.sessions[(sessions[0] as Session).id].refer(sessions[1]).then(res => {
        //   console.log('🚀 ~  ~ setConference');
        // });
        // this.endCall((sessions[0] as Session).id);
        // this.endCall((sessions[1] as Session).id);
      }
    }
  }

  private generator(): string {
    return `${this.S4()}${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}${this.S4()}${this.S4()}`;
  }

  private S4(): string {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  acceptIncomingCall(id: string): CallWayCall {
    console.log('🚀 ~ acceptIncomingCall ~ this.session', this.sessions, id);
    if (this.sessions[id]) {
      const session = this.sessions[id];
      if (session) {
        sessionStorage.setItem(
          'lastCall',
          JSON.stringify({ phone: session.incomingPhoneNumber, user: session.incomingPassOrDriver})
        );
      }
      (this.sessions[id] as Invitation).accept();
      this.handleEventService.pauseSound(id);
      return getInfoAbountIncomingCall(this.sessions[id]);
    }
  }

  rejectIncomingCall(id: string): void {
    console.log('🚀 ~ rejectIncomingCall ~ this.session', this.sessions, id);
    if (this.sessions[id]) {
      (this.sessions[id] as Invitation).reject();
      this.handleEventService.pauseSound(id);
    }
  }

  private getUserAgent(data, serviceId: any): any {
    let firstContact = false;
    if (serviceId && this.callWayService.transferQueues && this.callWayService.transferQueues.length > 0) {
      firstContact = this.callWayService.transferQueues.find(item => `${item.queue_id}` === `${this.sessions[serviceId]?.incomingPassOrDriver?.serviceId}`)?.is_first_contact;
    }

    const formValue = this.settingsForm.value;
    const transferTarget = (data.transferId == '101') ?
      UserAgent.makeURI(`sip:*${data.transferId}@${formValue.realm}`) :
      UserAgent.makeURI(`sip:*100*${data.transferId}@${formValue.realm}`);

    if (!transferTarget) {
      throw new Error('Failed to create transfer target URI.');
    }

    return transferTarget;
  }

  private onInvite = (invitation: Invitation): void => {
    const incomingSession: Session = invitation;
    this.handleEventService.makeNotifiSound('telephony-income', incomingSession.id);
    incomingSession.stateChange.addListener((state: SessionState) => {
      console.log('🚀 ~ invitation ~ stateChange', state, incomingSession);
      switch (state) {
        case SessionState.Initial:
          break;
        case SessionState.Establishing:
          break;
        case SessionState.Established:
          this.setupRemoteMedia(invitation);
          break;
        case SessionState.Terminating:
          this.closeModal$.next({close: true, invitation});
          // this.pauseWorkingShift().subscribe();
          this.handleEventService.pauseSound(incomingSession.id);
          this.cleanupMedia(incomingSession.id);
          break;
        // fall through
        case SessionState.Terminated:
          this.handleEventService.pauseSound(incomingSession.id);
          this.cleanupMedia(incomingSession.id);
          this.telephonyIncomingService.isIncomeCalls$.next(false);
          break;
        default:
          throw new Error('🚀 Unknown session state.');
      }
      if (
        (invitation as any).dialog?.initialTransaction?.lastFinalResponse?.indexOf('487 Request Terminated') > -1 ||
        (invitation as any).dialog?.initialTransaction?.lastFinalResponse?.indexOf('480 Temporarily Unavailable') > -1
      ) {
        // this.pauseWorkingShift().subscribe();
        this.closeModal$.next({close: true, invitation});
      }
    });
    this.sessions[incomingSession.id] = incomingSession;
    this.sessions[
      incomingSession.id
      ].incomingPhoneNumber = (invitation as any).incomingInviteRequest.message.from.uri.normal.user;
    this.sessions$.next(this.sessions);

    const headerData = this.getInviteHeadersParams((invitation as any)?.incomingInviteRequest?.message?.headers['X-Callcenter-Payload'][0]?.raw);
    const queueId = headerData?.qid;
    if (headerData?.pqid && this.globalDataService.globalTransferList$.value.some((q) => queueId === q.mappingId && q.isSpecial)) {
      this.isTransferCall$.next(true);
    }
    console.log('🚀 0 sessions$.next');
    this.getPassenger(incomingSession);
  };

  private onDisconnect = (error: Error): void => {
    console.error(error, 'disconnect user delegate');
    this.disconnectTelephony();
  }

  private createUser(): void {
    const formValue = this.settingsForm.value;
    const userAgentOptions: UserAgentOptions = {
      authorizationPassword: formValue.password, // 112233445566
      authorizationUsername: formValue.login, // 2005
      transportOptions: {
        server: formValue.server // 'wss://voiptest.megakit.pro:8089/ws'
      },
      delegate: {
        onInvite: this.onInvite, // invite in call, somebody calling me
        onDisconnect: this.onDisconnect
      },
      uri: UserAgent.makeURI(`sip:${formValue.id}@${formValue.realm}`), // 2005 // voiptest.megakit.pro
      sessionDescriptionHandlerFactoryOptions: {
        constraints: {audio: true, video: false},
        peerConnectionOptions: {
          rtcConfiguration: {
            iceServers: [{urls: 'stun:calls.megakit.pro:3478'}]
          }
        }
      },
      sipExtension100rel: SIPExtension.Supported,
    };
    console.log('🚀 ~ createUser ~ userAgentOptions', userAgentOptions);
    this.user = new UserAgent(userAgentOptions);
  }

  private setupRemoteMedia(session: any, earlyMedia = false): void {
    // conversation going
    if (!earlyMedia) {
      const remoteStream = new MediaStream();
      session.sessionDescriptionHandler.peerConnection.getReceivers().forEach(receiver => {
        if (receiver.track) {
          remoteStream.addTrack(receiver.track);
        }
      });
      this.sessions[session.id].remoteStream = remoteStream;
    }
    this.sessions[session.id].callInProgress = true;
    this.sessions[session.id].callInRinging = false;
    this.sessions[session.id].timer$ = new BehaviorSubject(0);
    this.sessions$.next(this.sessions);

    // const call = getInfoAbountIncomingCall(session);
    // this.incomeCalls$.next(call);
    console.log('🚀 1 sessions$.next');
  }

  private cleanupMedia(id): void {
    if (this.sessions[id]?.incomingPassOrDriver?.prevQueue && this.isTransferCall$.value ) {
      this.isTransferCall$.next(false);
    }
    delete this.sessions[id];
    this.sessions$.next(this.sessions);
    // if (Object.values(this.sessions).length === 0) {
    //   this.isTransferCall$.next(false);
    // }
    console.log('🚀 3 sessions$.next');
  }

  private isSomeSessionEstablishing(): boolean {
    for (const sessionId in this.sessions) {
      if (this.sessions[sessionId].state === SessionState.Establishing) {
        return true;
      }
    }
    return false;
  }

  private getInviteHeadersParams(str: string): any {
    const response = {};
    if (str) {
      const headersArr = str.slice(1).split('&');
      headersArr.forEach(headerItem => {
        const param = headerItem.split('=');
        if (param.length && param[0] && param[1]) {
          response[param[0]] = param[1];
        }
      });
    }
    return response;
  }

  private getPassenger(invitation): void {
    if (invitation) {
      const phoneNumber = invitation?.incomingInviteRequest?.message?.from?.uri?.normal?.user;
      const headerData = this.getInviteHeadersParams(invitation?.incomingInviteRequest?.message?.headers['X-Callcenter-Payload'][0]?.raw);
      const queueId = headerData?.qid;

      const prevQueue = headerData?.pqid;      let serviceId = null;
      if (queueId && this.callWayService.queues && this.callWayService.queues.length > 0) {
        serviceId = this.callWayService.queues.find(item => `${item.queue_id}` === `${queueId}`)?.service_id;
      }
      if (phoneNumber && serviceId) {
        this.telephonyApiService
          .getPassengerByPhoneNumber(`+38${normalizePhoneNumber(phoneNumber)}`, serviceId)
          .subscribe({
            next: res => {
              this.sessions[invitation.id].incomingPassOrDriver = {...res, serviceId};
              this.sessions$.next(this.sessions);
              console.log('🚀 4 sessions$.next');
            },
            error: err => {
              console.error(err);
              this.sessions[invitation.id].incomingPassOrDriver = {
                phone_number: phoneNumber ? phoneNumber : '',
                serviceId: serviceId ? serviceId : '',
                prevQueue: prevQueue ? prevQueue : '',
              };
              this.sessions$.next(this.sessions);
              console.log('🚀 5 sessions$.next');
            }
          });
      } else {
        const serviceName = this.callWayService.transferQueues.find(item => item.mappingId === queueId)?.name;
        this.sessions[invitation.id].incomingPassOrDriver = {
          phone_number: phoneNumber ? phoneNumber : '',
          serviceId: queueId ? queueId : '',
          serviceName: queueId ? serviceName : '',
          prevQueue: prevQueue ? prevQueue : ''
        };
        this.sessions$.next(this.sessions);

        console.log('🚀 6 sessions$.next');
      }
    }
  }

  startWorkingShift(): Observable<any> {
    return this.telephonyApiService.startWorkingShift().pipe(
      tap(() => {
        this.enterStatus$.next(true);
        this.state.telephonyEnterStatus$.next(true);
      }),
      catchError(error => {
        if (error.error.type === 'ACTIVE_WORK_SHIFT_ALREADY_EXISTS') {
          this.handleEventService.openSnackBar('ALREADY_HAVE_ACTIVE_WORK_SHIFT', 5);
          return of(true);
        } else if (error.error.status === 401) {
          return of(true);
        }

        if (environment.liteVersion) {
          this.remoteWorkService.workState$.next(false);
          this.router.navigate(['/cabinet']);
        }

        return throwError(error);
      })
    );
  }

  closeWorkingShift(): Observable<any> {
    return this.telephonyApiService.closeWorkingShift().pipe(
      tap(() => {
        this.enterStatus$.next(false);
        this.state.telephonyEnterStatus$.next(false);
      }),
      catchError(error => {
        if (error.error.status === 401 ||
          error.error.code === 404 ||
          error.error.status === 400 && error.error.type === 'NO_ACTIVE_WORK_SHIFT') {
          return of(true);
        }
        return throwError(error);
      })
    );
  }

  changeWorkingShiftStatus(status: boolean): Observable<any> {
    return this.telephonyApiService.walkedAway(status).pipe(
      catchError(error => {
        if (error.error.status === 401) {
          return of(true);
        }
        return throwError(error);
      })
    );
  }

  sendRating(lastCall: TelephonyRatingInterface) {
    if (typeof this.ratingCallTimeoutId === 'number') {
      clearTimeout(this.ratingCallTimeoutId);
      this.ratingCallTimeoutId = null;
      this.telephonyRating$.next({ ...lastCall, ratingSending: false });
      localStorage.setItem('ratingCall', JSON.stringify(this.telephonyRating$.value));
    } else {
      this.telephonyRating$.next({ ...lastCall, ratingSending: true });
      localStorage.setItem('ratingCall', JSON.stringify(this.telephonyRating$.value));
      this.ratingCallTimeoutId = setTimeout(() => {
        this.telephonyApiService.sendQualityRating({rating: 1, callStageId: lastCall.callStageId})
          .pipe(
            tap(() => {
              const newStateRating = {callStageId: lastCall.callStageId, isRated: true, ratingSending: true, ratingUnlock: true};
              localStorage.setItem('ratingCall', JSON.stringify(newStateRating));
              this.lastCallStream$.next(newStateRating);
              this.telephonyRating$.next(newStateRating);
              clearTimeout(this.ratingCallTimeoutId);
              this.ratingCallTimeoutId = null;
            })
          )
          .subscribe();
      }, 10000);
    }
  }

  sendRatingNow() {
    if (typeof this.ratingCallTimeoutId === 'number') {
      clearTimeout(this.ratingCallTimeoutId);
      this.ratingCallTimeoutId = null;
      this.telephonyApiService.sendQualityRating({rating: 1, callStageId: this.telephonyRating$.value?.callStageId})
        .subscribe();
    }
  }

  lockRatingButton(endCall: boolean = false) {
    if (!this.telephonyIncomingService.isIncomeCalls$.value) {
      if (!endCall || Object.values(this.sessions).length && endCall) {
        localStorage.setItem('lastCallDirection', `outcome`);
        this.lastCallDirection.next(`outcome`);
      }
    }
  }
}
