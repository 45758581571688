import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private cache = {};

  constructor(private http: HttpClient) { }

  getValue(url, obj?) {
    const subscription = new BehaviorSubject([]);
    const value = this.cache[url(obj) + ''];
    if (value) {
      subscription.next(value);
    } else {
      const sub = obj ? this.http.get<any>(url(obj)) : this.http.get<any>(url());
      sub.subscribe(res => {
        this.cache[url(obj) + ''] = res?.data;
        subscription.next(res.data);
      });
    }
    return subscription;

  }
}
