import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'etherTime'
})
export class EtherTimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return moment().diff(moment.utc(value), 'minutes');
  }

}
