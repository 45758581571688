import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timer'
})
export class TimerPipe implements PipeTransform {

  transform(value: string): string {
    const currentDate = moment();
    const date = moment(value);
    return currentDate.diff(date) < 600000 ? moment.utc(currentDate.diff(date)).format('m:ss') : Math.floor(moment.duration(currentDate.diff(date)).asMinutes()) + ' хв';
  }

}
