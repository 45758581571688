import { Component, Inject, Optional } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'utax-telephony-settings-modal',
  templateUrl: './telephony-settings-modal.component.html',
  styleUrls: ['./telephony-settings-modal.component.scss']
})
export class TelephonySettingsModalComponent {
  form: UntypedFormGroup;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogSelf: MatDialogRef<TelephonySettingsModalComponent>
  ) {
    this.form = data.form;
  }

  save(): void {
    this.dialogSelf.close(true);
    localStorage.setItem('telephonySettings', JSON.stringify(this.form.value));
  }

  cancel(): void {
    this.dialogSelf.close(false);
  }
}
