import { Pipe, PipeTransform } from '@angular/core';
import {TimezoneService} from '../../services';

@Pipe({
  name: 'locTime'
})
export class LocTimePipe implements PipeTransform {
  constructor(private timezoneService: TimezoneService) {}

  transform(value: any): any {
    return this.timezoneService.utcToTz(value);
  }
}
