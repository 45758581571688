import { QueueModule } from './queue/queue.module';
import { DispSettingsModule } from './disp-settings/disp-settings.module';
import { DispSalaryModule } from './disp-salary/disp-salary.module';
import {PassengerBlacklistModule} from './passenger-blacklist/passenger-blacklist.module';

export const modules = [QueueModule, DispSettingsModule, DispSalaryModule, PassengerBlacklistModule];

export * from './disp-settings/disp-settings.module';
export * from './disp-salary/disp-salary.module';
export * from './queue/queue.module';
export * from './salary-modifier/salary-modifier.module';
export * from './passenger-blacklist/passenger-blacklist.module';

