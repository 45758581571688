import { NgxMaskModule } from 'ngx-mask';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

// containers
import * as fromContainers from './containers';

// components
import * as fromComponents from './components';

// services
import * as fromServices from './services';

// pipes
import * as fromPipes from './pipes';

// directives
import * as fromDirectives from './directives';

import { MaterialModule } from '../utils/material.module';
import { SharedModule } from './../shared/shared.module';
import { MapModule } from './../map/map.module';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { TelephonyModule } from '../telephony/telephony.module';
import {OverlayModule} from "@angular/cdk/overlay";
import { AddDistanceModalComponent } from './components/add-distance-modal/add-distance-modal.component';

@NgModule({
  declarations: [
    ...fromContainers.containers,
    ...fromComponents.components,
    ...fromPipes.pipes,
    ...fromDirectives.directives,
    AddDistanceModalComponent
  ],
    imports: [
        CommonModule,
        MapModule,
        MaterialModule,
        ReactiveFormsModule,
        TranslateModule,
        SharedModule,
        FormsModule,
        KeyboardShortcutsModule,
        NgxMaskModule.forChild(),
        TelephonyModule,
        OverlayModule
    ],
  providers: [...fromServices.services]
})
export class OrderFormModule {}
