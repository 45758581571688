export function appendItemsWithoutDuplicates<T, ID>(
  array: T[],
  items: T[],
  idMapper: (item: T) => ID,
  comparator?: (a: T, b: T) => number,
): void {

  if (items.length === 0) {
    return;
  }

  if (array.length === 0) {
    array.push(...items);
    return;
  }

  const firstAppendItem = items[0];
  const firstAppendItemId = idMapper(firstAppendItem);

  let skip = 0;

  for (let i = (array.length - 1); i >= 0; i--) {
    const item = array[i];

    if (firstAppendItemId === idMapper(item)) {
      skip = array.length - i;
      break;
    }

    if (comparator && comparator(item, firstAppendItem) < 0) {
      break;
    }
  }

  if (skip >= items.length) {
    return;
  }

  array.push(...items.slice(skip));
}
