import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {
  transform(data: object) {
    return Object.keys(data)
      .filter(key => !Number.isInteger(Number(key)));
  }
}
