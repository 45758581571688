import { FilterProductsPipe } from './filter-products.pipe';
import { GetAddressPlaceholderPipe } from './get-address-placeholder.pipe';
import { GetIntercityInfoPipe } from './get-intercity-info.pipe';
import { SummDistancesPipe } from './summ-distances.pipe';
import { FilterAddressesPipe } from './filter-addresses.pipe';

export const pipes: any[] = [
  GetAddressPlaceholderPipe,
  FilterProductsPipe,
  GetIntercityInfoPipe,
  SummDistancesPipe,
  FilterAddressesPipe
];

export * from './filter-addresses.pipe';
export * from './summ-distances.pipe';
export * from './get-intercity-info.pipe';
export * from './filter-products.pipe';
export * from './get-address-placeholder.pipe';
