export const paymentsPeriods = [
  {
    value: 'DAILY',
    id: 'daily'
  },
  {
    value: 'WEEKLY',
    id: 'weekly'
  }
];
export const intervalTypes = [
  {
    value: 'SOLID_INTERVAL',
    id: 'solid_interval'
  },
  {
    value: 'CERTAIN_TIME',
    id: 'certain_time'
  }
];
export const daysOfWeek = [
  {
    name: 'MONDAY',
    id: 1
  },
  {
    name: 'TUESDAY',
    id: 2
  },
  {
    name: 'WEDNESDAY',
    id: 3
  },
  {
    name: 'THURSDAY',
    id: 4
  },
  {
    name: 'FRIDAY',
    id: 5
  },
  {
    name: 'SATURDAY',
    id: 6
  },
  {
    name: 'SUNDAY',
    id: 0
  }
];

export const daysOfWeekScheduledBonuses = [
  {
    value: 'SUNDAY',
    id: 0
  },
  {
    value: 'MONDAY',
    id: 1
  },
  {
    value: 'TUESDAY',
    id: 2
  },
  {
    value: 'WEDNESDAY',
    id: 3
  },
  {
    value: 'THURSDAY',
    id: 4
  },
  {
    value: 'FRIDAY',
    id: 5
  },
  {
    value: 'SATURDAY',
    id: 6
  }
];

export const daysOfWeekISO8601 = [
  {
    name: 'MONDAY',
    id: 1
  },
  {
    name: 'TUESDAY',
    id: 2
  },
  {
    name: 'WEDNESDAY',
    id: 3
  },
  {
    name: 'THURSDAY',
    id: 4
  },
  {
    name: 'FRIDAY',
    id: 5
  },
  {
    name: 'SATURDAY',
    id: 6
  },
  {
    name: 'SUNDAY',
    id: 7
  }
];

export const OPTIONS_COUNT_OR_APPLY = [
   {
     isSelected: false,
     name: 'sending'
   },
   {
     isSelected: false,
     name: 'app'
   },
   {
     isSelected: false,
     name: 'ether'
   },
   {
     isSelected: false,
     name: 'call'
   },
   {
     isSelected: false,
     name: 'intercity'
   },
   {
     isSelected: false,
     name: 'cashe'
   },
   {
     isSelected: false,
     name: 'casheless'
   },
   {
     isSelected: false,
     name: 'visible'
   },
   {
     isSelected: false,
     name: 'hidden'
   }
 ];
