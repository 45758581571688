import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject, HostListener } from '@angular/core';

@Component({
  selector: 'utax-remove-order',
  templateUrl: './remove-order.component.html',
  styleUrls: ['./remove-order.component.scss']
})
export class RemoveOrderComponent implements OnInit {
  reason = null;
  comment = null;
  showHelpMessage = false;
  reasons = [];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RemoveOrderComponent>
  ) {}

  ngOnInit() {
    this.reasons = this.data.reasons.sort(a => {
      if (a.display_name === 'Машина не найдена') {
        return -1;
      } else {
        return 1;
      }
    });
    this.reason = this.reasons[0].id;
  }

  @HostListener('document:keyup', ['$event'])
  hotkeyUpHandler($event) {
    switch ($event.keyCode) {
      case 120: { // F9
        $event.preventDefault();
        this.send();
        break;
      }
    }
  }

  send() {
    if (this.reason == null) {
      this.showHelpMessage = true;
      return;
    }

    const data = {
      id: this.data.id,
      reason: this.reason,
      comment: this.comment
    };

    this.dialogRef.close(data);
  }
}
