import { OkModalComponent } from '../../shared/components';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { OrderFormService } from '../../order-form/services';
import { Filters } from '../models/filters';
import { Injectable } from '@angular/core';
import { OrdersService, RmService, WebsocketService } from '../../services';
import { LogistCabsService } from './logist-cabs.service';
import { Order, Service } from 'src/app/interfaces/order.interface';
import { BehaviorSubject, Subject } from 'rxjs';
import { ClusterSort, SortOption } from '../../utils/cluster-sort';
import { TimezoneService } from 'src/app/services';

import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import * as _ from 'lodash';
import { getColorOfCab } from '../utils/status-color';
import { bufferTime, map } from 'rxjs/operators';
import { calcDist } from '../utils/dist-utils';
import {OrderClass} from '../../classes/order.class';

const bufferInterval = 3000;

@Injectable({
  providedIn: 'root'
})
export class LogistOrdersService {
  ordersTableScroll$ = new Subject();

  dialogRef: MatDialogRef<OkModalComponent>;
  dialogConfig = new MatDialogConfig();
  closeOrderForm$: Subject<any> = new Subject();

  services: Service[] = [];
  queuedRequests = {};

  constructor(
    private ws: WebsocketService,
    private ordersService: OrdersService,
    private rm: RmService,
    private logistCabsService: LogistCabsService,
    private tzService: TimezoneService,
    private orderFormService: OrderFormService,
    private dialog: MatDialog
  ) {
  }

  filters: Filters;

  ordersList: Order[] = [];
  public dispayedOrders = [];
  ordersByCabs: any = {};
  private subscriptionsList = [];
  ordersToShow = [];
  ordersToShow$: Subject<any> = new Subject();

  sortOptions: Array<SortOption<OrderClass>> = [
    {
      priority: 5,
      order: 1,
      chracteristic: (order: Order) => !!order.cab,
      dividerAfter: true,
      comparator: this.sortBy
    },
    {
      priority: 4,
      order: 2,
      chracteristic: (order: Order) => order.is_reserved,
      dividerAfter: true,
      comparator: this.sortBy
    },
    {
      priority: 3,
      order: 3,
      chracteristic: (order: Order) => order.is_holding,
      comparator: this.sortBy
    },
    {
      priority: 1,
      order: 4,
      chracteristic: (order: Order) => true,
      comparator: this.sortBy
    }
  ];

  isReadyToShowOrders$: Subject<any> = new Subject();

  currentOrderInProgress$: BehaviorSubject<any> = new BehaviorSubject([]);

  sortBy(a: Order, b: Order) {
    return moment(b.assigned_at).diff(a.assigned_at);
  }

  rebuildList() {
    this.filterOrders(this.ordersList, this.logistCabsService.activeCabs);
  }

  enableService() {
    const mirandaConnection = this.ws.openConnection('miranda');

    mirandaConnection.on('req_queue').subscribe(msg => {
      if (msg.header.action === 'create') {
        msg.data.status = 'processing';
        if (this.queuedRequests[msg.data.cab_id]) {
          this.queuedRequests[msg.data.cab_id].push(msg.data);
        } else {
          this.queuedRequests[msg.data.cab_id] = [msg.data];
        }
      }

      if (msg.header.action === 'rcv' || msg.header.action === 'delete') {
        for (const key in this.queuedRequests) {
          if (key) {
            this.queuedRequests[key] = (this.queuedRequests[key] || []).filter(
              req => req.request_id !== msg.data.request_id
            );
          }
        }
      }

      this.ordersService.updateOrders([], true);
    });

    this.ordersFiltersChanges(this.filters);
    this.ordersService.ordersInstantUpdates$.subscribe((orders: any[]) => {
      this.ordersList = Array.from(this.ordersService.currentOrders.values());
      this.filterOrders(orders, this.logistCabsService.activeCabs);
    });
    this.ordersService.ordersDeletions$.subscribe(id => {
      this.ordersList = Array.from(this.ordersService.currentOrders.values());
      this.filterOrders([{ id, delete: true }], this.logistCabsService.activeCabs);
    });
    const ordersSub = this.ordersService.ordersUpdates$
      .pipe(
        bufferTime(bufferInterval),
        map(responses => _.uniqBy(responses.reverse().flat(), (order: any) => order.id))
      )
      .subscribe((orders: Order[]) => {
        this.ordersList = Array.from(this.ordersService.currentOrders.values());
        this.filterOrders(orders, this.logistCabsService.activeCabs);
      });
    const activeCabsSub = this.logistCabsService.visibleCabsSub$
      .pipe(
        bufferTime(bufferInterval),
        map(responses => _.uniqBy(responses.reverse().flat(), (cab: any) => cab.id))
      )
      .subscribe(cabs => {
        this.filterOrders(this.ordersList, cabs);
      });
    const disabledCabsSub = this.logistCabsService.disabledCabsSub.subscribe(cabs =>
      cabs.forEach(cab => this.deleteCab(cab.id))
    );
    this.ordersService.createSubscription();
    const currentCabSub = this.logistCabsService.currentCabSub.subscribe((cab: any) => {
      this.ordersToShow = ClusterSort(this.ordersByCabs[cab.id], this.sortOptions);
      this.currentOrderInProgress$.next(
        this.ordersToShow.find(ord => ord.cab && ord.cab.id === cab.id && ord.trip.is_current)
      );
      this.isReadyToShowOrders$.next(true);
    });
    this.subscriptionsList.push(ordersSub, activeCabsSub, disabledCabsSub, currentCabSub);
  }

  public disableService() {
    this.subscriptionsList.forEach(sub => sub.unsubscribe());
    this.subscriptionsList = [];
  }

  private filterOrders(orders, cabs: Array<any>) {
    // closing order form if order deleted
    if (
      this.orderFormService.order &&
      orders.length === 1 &&
      orders[0].delete === true &&
      orders[0].id === this.orderFormService.order.id
    ) {
      // open info modal
      this.dialogConfig.data = {
        title: 'ORDER_CANCELED_BY_PASSENGER'
      };
      this.dialogConfig.panelClass = 'ok-modal-container';
      this.dialogRef = this.dialog.open(OkModalComponent, this.dialogConfig);
      this.dialogRef.afterClosed().subscribe(data => {
        if (data === 'OK') {
          // close order from
          this.closeOrderForm$.next(true);
        }
        this.dialogConfig = {};
      });
    }

    this.logistCabsService.activeCabs = this.logistCabsService.activeCabs.map(cab => {
      const currentTrip = cab.trips.find(trip => trip.is_current || trip.is_queued);
      if (currentTrip) {
        cab.order = this.getOrderByRequestId(currentTrip.request_id);
      } else {
        cab.order = null;
      }
      cab.color = getColorOfCab(cab);
      return cab;
    });

    orders = orders
      .map(order => {
        if (order.delete || (order.status && order.status !== 'processing' && !order.is_active_order)) {
          this.deleteOrder(order.id);
        }
        order.is_reserved = order.status === 'processing'; // ?
        return order;
      })
      .filter(order => !order.delete)
      .filter(
        order =>
          (order && order.fare && order.fare.amount > this.filters.cost) ||
          (order.product && order.product.show_tech && order.status === 'procesing')
      );

    const ordersByCabs = {};
    const oldOrdersByCabs = {};

    cabs.forEach(cab => {
      oldOrdersByCabs[cab.id] = (this.ordersByCabs[cab.id] || []).filter(
        order => !orders.find(el => el.id === order.id)
      );
      let activeOrders = [
        ...(cab.trips || []), // current order
        ...(this.queuedRequests[cab.id] || []),
        ...(cab.offer && cab.offer.status === 'processing' && !cab.offer.is_sending ? [cab.offer] : []) // assigned order
      ]
        .map(trip => {
          const order = this.getOrderByRequestId(trip.request_id);
          if (order) {
            order.is_reserved = trip.status === 'processing';
            if (
              this.queuedRequests[cab.id] &&
              this.queuedRequests[cab.id].length > 0 &&
              this.queuedRequests[cab.id][0].type
            ) {
              order.assignedBy = this.queuedRequests[cab.id][0].type;
            } else if (!order.is_reserved) {
              order.assignedBy = null;
            }
          }
          return order;
        })
        .filter(Boolean)
        .map(activeOrder => {
          activeOrder.is_active_order = true;
          return activeOrder;
        });

      activeOrders = [
        ...activeOrders,
        ...Array.from(this.ordersService.currentOrders.values()).filter(order =>
          (order.queued_requests || []).find(request => request.cab_id === cab.id)
        )
      ];

      let filteredOrders = orders
        .filter(
          order =>
            (order.status === 'processing' ||
              order.is_holding ||
              (order.product.show_tech && order.status === 'procesing')) &&
            cab.city_id === order.city_id
        )
        .map(order => {
          order.approximatelyFeed = calcDist(this.getFirstPoint(cab), order.waypoints[0]);
          return order;
        })
        .filter(order => {
          return (
            (order.approximatelyFeed <= this.filters.distance ||
              order.is_holding ||
              (order.product.show_tech && order.status === 'procesing')) &&
            this.canBeShownByPreorderTime(order)
          );
        })
        .filter(order => order.service_id === cab.service_id);
      filteredOrders = _.cloneDeep(filteredOrders).map(order => {
        order.is_active_order = false;
        order.is_reserved = false;
        return order;
      });

      filteredOrders = _.unionBy(activeOrders, filteredOrders, order => order.id);
      /* filteredOrders =  [
        ...filteredOrders/*.filter(order => !order.is_active_order),
        ...activeOrders
      ];*/

      const invalidOrders = orders.filter(
        order => !filteredOrders.find(filteredOrder => filteredOrder.id === order.id)
      );
      oldOrdersByCabs[cab.id] = oldOrdersByCabs[cab.id].filter(
        order => !invalidOrders.find(invalidOrder => invalidOrder.id === order.id)
      );

      ordersByCabs[cab.id] = filteredOrders;

      if (cab.id === this.logistCabsService.selectedCabId) {
        this.ordersToShow = this.ordersToShow.map(ord => {
          const filtered = filteredOrders.find(item => item.id === ord.id);
          return filtered ? filtered : ord;
        });
        this.ordersToShow$.next(this.ordersToShow);
      }
    });

    this.calcDistByRM(ordersByCabs, cabs, oldOrdersByCabs);
    if (this.logistCabsService.selectedCabId !== 'all') {
      this.logistCabsService.selectCab(this.logistCabsService.selectedCab);
    }
  }

  calcDistByRM(ordersByCabs, cabs, oldOrders) {
    const ordersList = [];
    const cabLocations = cabs
      .filter(cab => ordersByCabs[cab.id])
      .map(cab => {
        ordersList.push(...ordersByCabs[cab.id]);
        return this.getFirstPoint(cab);
      });
    const shortOrdersList = _.uniqBy(ordersList, order => order.id);
    if (cabLocations.length && shortOrdersList.length) {
      this.rm
        .routeTable(
          cabLocations,
          shortOrdersList.map(order => order.waypoints[0])
        )
        .subscribe((res: any) => {
          cabs
            .filter(cab => ordersByCabs[cab.id])
            .forEach((cab, cabIndex) => {
              if (ordersByCabs[cab.id]) {
                ordersByCabs[cab.id].forEach(order => {
                  if (
                    !order.is_active_order ||
                    order.status === 'accepted' ||
                    order.status === 'arriving' ||
                    order.status === 'waiting' ||
                    order.status === 'processing'
                  ) {
                    order.feed = res.distances[cabIndex][shortOrdersList.map(el => el.id).indexOf(order.id)] / 1000;
                  } else {
                    order.feed = null;
                  }

                  order.costPerKm = order.fare.amount / (order.fare.distance / 1000 + order.feed || 0);
                });
                this.ordersByCabs[cab.id] = _.uniqBy(
                  [
                    ...oldOrders[cab.id],
                    ..._.cloneDeep(
                      ordersByCabs[cab.id].filter(
                        order =>
                          (order.feed <= this.filters.distance && order.costPerKm >= this.filters.costPerKm) ||
                          order.is_active_order ||
                          order.is_holding ||
                          /*  order.is_reserved ||*/
                          (order.product.show_tech && order.status === 'procesing')
                      )
                    )
                  ],
                  order => order.id
                );

                // this.selectAll(); // all cabs all services
                this.selectAll(this.services.map(serv => serv.id));

                this.ordersToShow = ClusterSort(
                  this.ordersByCabs[this.logistCabsService.selectedCabId] || [],
                  this.sortOptions
                );
                const ordInProgress = this.ordersToShow.find(ord => ord.cab && ord.cab.id === cab.id);
                if (ordInProgress) {
                  this.currentOrderInProgress$.next(ordInProgress);
                }

                this.isReadyToShowOrders$.next(true);
              }
            });
        });
    }
  }

  selectAll(serviceIds?: number[]) {
    const orders = [];
    for (const id in this.ordersByCabs) {
      if (id) {
        const cab = this.logistCabsService.activeCabs.find(el => el.id === id);
        if (id !== 'all' && cab && (cab.color === 'red' || cab.color === 'yellow')) {
          orders.push(...this.getOrdersForAllTab(cab));
        }
      }
    }

    if (serviceIds) {
      serviceIds.forEach(servId => {
        this.ordersByCabs[servId] = this.sortOrderCabList(orders.filter(order => order.service_id === servId));
      });
    } else {
      this.ordersByCabs.all = this.sortOrderCabList(orders);
    }
  }

  private sortOrderCabList(orders): any[] {
    return orders.map(order => {
      if (order.cabsList) {
        order.cabsList = order.cabsList.sort((a, b) => a.costPerKm - b.costPerKm);
      }
      return order;
    });
  }

  private getOrdersForAllTab(cab: any): any[] {
    const orders = [];

    this.ordersByCabs[cab.id].forEach(order => {
      if (
        (order.is_visible_vehicle_user ||
          moment.utc(order.queued_at).diff(moment(), 'seconds') <= 0 ||
          order.is_queued) &&
        order.status === 'processing' &&
        !order.is_reserved &&
        moment.utc(order.sending_at).diff(moment(), 'seconds') >= 0
      ) {
        const existingOrder = orders.find(el => el.id === order.id);
        const newCab = {
          ...cab,
          feed: order.feed,
          costPerKm: order.costPerKm
        };
        if (
          order.feed <= this.filters.distance &&
          order.fare.amount >= this.filters.cost &&
          order.costPerKm >= this.filters.costPerKm
        ) {
          if (existingOrder) {
            existingOrder.cabsList.push(newCab);
          } else {
            order.cabsList = [newCab];
            orders.push(order);
          }
        }
      }
    });

    return orders;
  }

  ordersFiltersChanges(filters: Filters) {
    if (filters) {
      for (const key in filters) {
        if (key) {
          this.filters[key] = +filters[key];
        }
      }
      this.filterOrders(this.ordersList, this.logistCabsService.activeCabs);
    }
  }

  getFirstPoint(cab) {
    return cab.order &&
    cab.order.trip &&
    cab.order.trip.is_current &&
    cab.order.status !== 'accepted' &&
    cab.order.status !== 'arriving' &&
    cab.order.status !== 'waiting'
      ? _.last(cab.order.waypoints)
      : cab.location;
  }

  getOrderByRequestId(requestId: string): Order {
    return this.ordersList.find(order => {
      return order.id === requestId;
    });
  }

  getOrderByCabId(cabId: string): Order {
    return this.ordersList.find((order: Order) => {
      return order.cab && order.cab.id === cabId;
    });
  }

  changeOrderStatus(orderId, status) {
    this.ordersList = this.ordersList.map(ord => {
      if (ord.id === orderId) {
        return { ...ord, status };
      } else {
        return ord;
      }
    });
    this.ordersToShow = this.ordersToShow.map(ord => {
      if (ord.id === orderId) {
        return { ...ord, status };
      } else {
        return ord;
      }
    });
  }

  changeReservedStatus(orderId) {
    this.ordersList = this.ordersList.map(ord => {
      if (ord.id === orderId) {
        return { ...ord, is_reserved: !ord.is_reserved };
      } else {
        return ord;
      }
    });
    this.ordersToShow = this.ordersToShow.map(ord => {
      if (ord.id === orderId) {
        return { ...ord, is_reserved: !ord.is_reserved };
      } else {
        return ord;
      }
    });
  }

  removeOrderFromOrderLists(order) {
    this.ordersToShow = this.ordersToShow.filter(item => {
      if (order.id !== item.id) {
        return item;
      }
    });
    this.ordersList = this.ordersList.filter(item => {
      if (order.id !== item.id) {
        return item;
      }
    });
  }

  private deleteOrder(orderId) {
    for (const key in this.ordersByCabs) {
      if (key) {
        this.ordersByCabs[key] = this.ordersByCabs[key].filter(order => order.id !== orderId);
      }
    }
  }

  private deleteCab(cabId) {
    delete this.ordersByCabs[cabId];
  }

  private canBeShownByPreorderTime(order: Order): boolean {
    if (!order.is_preorder) {
      return true;
    }
    if (order.is_preorder) {
      const assignedAt = momentTz.utc(order.assigned_at).tz(this.tzService.timezones[+localStorage.getItem('service')]);
      const diffInMinutes = moment().diff(assignedAt, 'minutes');
      if (this.filters.preorderTime + diffInMinutes > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
}
