import { OrderFormService } from './order-form.service';
import {Observable, of, Subject, throwError} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {mergeMap, switchMap, catchError, tap, map, finalize} from 'rxjs/operators';
import { getWaypoints } from '../utils/form-utils';
import { environment } from '@global-environments/environment';
import {AddedKmToOrderInterface, Fare} from 'src/app/interfaces/order.interface';
import { TaxiServicesService, PaymentService, HandleEventService } from '../../services';
import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SaveOrderConfirmModalComponent } from '../components/save-order-confirm-modal/save-order-confirm-modal.component';
import { CardPaymentModalComponent } from '../components/card-payment-modal/card-payment-modal.component';
import {PassengerPopularityAddressInterface} from "../interfaces/passenger-popularity-address.interface";

const passangerUrl = 'operator/passenger',
  passengerServiceUrl = 'passenger-service/operator/passenger',
  updateOrderUrl = 'operator/requests/update',
  createOrderUrl = 'operator/requests/store',
  estimateUrl = 'operator/requests/estimate',
  fareUrl = 'operator/requests/fare';

@Injectable()
export class ApiOrderFormService {
  services = [];
  passengerId;
  phonePaymentId;
  dialogRef: MatDialogRef<SaveOrderConfirmModalComponent>;
  yesNoModalOpened = false;
  public fullPassenger: any;

  estimationObject: any;
  closeOrderForm$: Subject<boolean> = new Subject();

  constructor(
    private http: HttpClient,
    private orderFormService: OrderFormService,
    private taxiServices: TaxiServicesService,
    private paymentService: PaymentService,
    private dialog: MatDialog,
    private handleEventService: HandleEventService
  ) {}

  saveOrder(duplication = false): Observable<any> {
    // update fare if amount changed
    return this.orderFareUpdating(duplication).pipe(
      mergeMap(newFare => {
        const paymentMethodInfo = this.phonePaymentId ? { payment_method_id: this.phonePaymentId } : {};

        if (duplication) {
          return this.doEstimate(true).pipe(
            switchMap((fare: Fare) => {
              if (this.orderFormService.fare.amount !== fare.amount && !this.yesNoModalOpened) {
                this.yesNoModalOpened = true;
                const overdraft = this.orderFormService?.order?.passenger_overdraft?.amount || 0;
                this.dialogRef = this.openYesNoModal(this.orderFormService.fare.amount, fare.amount, overdraft);
                let waitForHold = false;
                return this.dialogRef.afterClosed().pipe(
                  mergeMap(modalInfo => {
                    this.yesNoModalOpened = false;

                    if (modalInfo === 'SAVE') {
                      const isCard =
                        this.orderFormService.order &&
                        this.orderFormService.order.trip &&
                        this.orderFormService.order.is_card_payment;
                      if (isCard && this.orderFormService.fare.amount > this.orderFormService.order.fare.amount) {
                        this.openCardPayFrom(this.orderFormService.order, this.orderFormService.fare.amount);
                        waitForHold = true;
                      }
                      return this.createOrder(
                        this.orderFormService.prepareOrderToSave(paymentMethodInfo, fare),
                        duplication
                      );
                    } else {
                      return of('closed');
                    }
                  })
                );
              } else {
                return this.createOrder(this.orderFormService.prepareOrderToSave(paymentMethodInfo, fare), duplication);
              }
            })
          );
        } else {
          return this.orderFormService.order
            ? this.updateOrder(this.orderFormService.prepareOrderToSave(paymentMethodInfo))
            : this.createOrder(this.orderFormService.prepareOrderToSave(paymentMethodInfo), duplication);
        }
      })
    );
  }

  openYesNoModal(oldPrice, newPrice, overdraft?) {
    return this.dialog.open(SaveOrderConfirmModalComponent, {
      data: {
        title: 'DIFFERENT_BETWEEN_FARE',
        oldPrice,
        newPrice,
        overdraft
      },
      panelClass: 'request-activate-dialog-container',
      width: '455px'
    });
  }

  openCardPayFrom(order, amount): void {
    this.dialog
      .open(CardPaymentModalComponent, {
        minWidth: 400,
        minHeight: 220,
        panelClass: 'yes-no-modal-container',
        data: {
          order,
          amount
        },
        disableClose: true
      })
      .afterClosed()
      .subscribe(() => {
        this.closeOrderForm$.next(true);
      });
  }

  doEstimate(force?: boolean): Observable<any> {
    this.orderFormService.disableSaveButtons$.next(true);
    this.orderFormService.isEstimationProgress$.next(true);
    const formValue = this.orderFormService.generalForm.getRawValue();
    const productIds = this.taxiServices.productsList[formValue.basic.taxiService]
      .filter(product => product.show_in_list || this.orderFormService.generalForm.value.basic.tariff === product.id ||
        (!product.show_in_list && this.orderFormService.initialProductId === product.id))
      .map(product => product.id);
    const estObj: any = {
      ...this.getPreorderObj(formValue.type),
      added_value: +this.orderFormService.addedValue || 0,
      is_by_the_city: formValue.type.incity,
      is_intercity: formValue.type.intercity,
      passenger_id: formValue.basic.passengerId,
      product_ids: productIds,
      service: formValue.basic.taxiService,
      services: formValue.addresses.features ? formValue.addresses.features : [],
      ...getWaypoints(formValue.addresses.addresses)
    };
    if (force) {
      estObj.force = true;
    }

    if (this.orderFormService.order) {
      estObj.request_id = this.orderFormService.order.id;
    }

    return this.http.post(this.getEstimateUrl(formValue.basic.taxiService), estObj).pipe(
      map(estimationObject => {
        this.estimationObject = estimationObject;
        const fareObj = this.estimationObject.fares.find(
          fare => fare.product === this.orderFormService.generalForm.value.basic.tariff
        );
        this.orderFormService.disableSaveButtons$.next(fareObj?.amount < 0);
        return {
          ...estimationObject,
          ...fareObj
        };
      }),
      catchError(error => {
        if (error && error.error && error.error.error === '1000: [GOSRM][ERROR]: No route found') {
          this.handleEventService.openSnackBar('Ошибка. Нет маршрута между точками, обратитесь к картографам.', 7);
        }
        if (this.orderFormService.fare) {
          this.orderFormService.fare.invalid = true;
        }
        this.orderFormService.isEstimationProgress$.next(false);
        return of(this.orderFormService.fare);
      }),
      tap((fareObj) => {
        if (fareObj && fareObj?.amount < 0) {
          this.orderFormService.disableSaveButtons$.next(true);
        } else {
          this.orderFormService.disableSaveButtons$.next(false);
        }
        this.orderFormService.isEstimationProgress$.next(false);
      })
    );
  }

  updatePassanger() {
    return this.http.post(passangerUrl, this.getPassengerObj());
  }

  getPassengerObj() {
    const basicFormValue = (this.orderFormService.generalForm.controls.basic as UntypedFormGroup).getRawValue();
    return {
      passenger: {
        id: basicFormValue.passengerId,
        comment: basicFormValue.commentAboutPassenger,
        name: basicFormValue.passengerName,
        phone_number: basicFormValue.clientPhone,
        country: 'ua'
      },
      service: basicFormValue.taxiService
    };
  }

  getEstimateUrl(serviceId) {
    if (this.taxiServices.servicesSettings[serviceId].system_build_in_estimate === '1') {
      return estimateUrl;
    } else {
      return `${environment.config.estimate}/estimate`;
    }
  }

  getPhonePaymentSetting() {
    return (
      this.taxiServices.servicesSettings[this.orderFormService.generalForm.getRawValue().basic.taxiService]
        .accounting_enabled_phone_payment === '1'
    );
  }

  setPassenger(mode: string) {
    const basicFormValue = this.orderFormService.generalForm.controls.basic.value;
    if (basicFormValue.clientPhone && basicFormValue.clientPhone.length === 13 && basicFormValue.taxiService) {
      this.getPassengerByPhoneNumber(basicFormValue.clientPhone, basicFormValue.taxiService)
        .pipe(
          mergeMap((passenger: any) => {
            return this.getPassengerById(passenger.data.id, basicFormValue.taxiService);
          })
        )
        .subscribe((fullPassenger: any) => {
          this.fullPassenger = fullPassenger;
          if (this.passengerId === undefined || this.passengerId !== fullPassenger.data.id) {
            this.passengerId = fullPassenger.data.id;
            this.orderFormService.setPassengerInForm(fullPassenger);

            if (
              basicFormValue.clientPhone.match(/^\+?3?8?(0(67|68|96|97|98)\d{7})$/) &&
              this.getPhonePaymentSetting()
            ) {
              this.paymentService
                .getPaymentMethod(this.passengerId, basicFormValue.clientPhone, 'phone')
                .subscribe((res: any) => {
                  this.phonePaymentId = res.id;
                });
            } else {
              this.phonePaymentId = null;
            }

            (this.orderFormService.generalForm.get('basic') as UntypedFormGroup).controls.passengerName.enable();
            (this.orderFormService.generalForm.get('basic') as UntypedFormGroup).controls.contactPhone.enable();
            (this.orderFormService.generalForm.get('basic') as UntypedFormGroup).controls.commentAboutPassenger.enable();
            (this.orderFormService.generalForm.get('type') as UntypedFormGroup).enable();
            (this.orderFormService.generalForm.get('addresses') as UntypedFormGroup).enable();
          }
        });
    }
  }

  getPassengerByPhoneNumber(phone, service): Observable<any> {
    return this.http.post(passangerUrl, {
      passenger: {
        phone_number: phone,
        country: 'ua'
      },
      service
    });
  }

  getPassengerById(id, service): Observable<any> {
    return this.http.get(passangerUrl, {
      params: { id, service }
    });
  }

  getPassengerPopularityAddress(passengerId: string, service_code: string, location: string): Observable<PassengerPopularityAddressInterface> {
    return this.http.get<PassengerPopularityAddressInterface>(`${passengerServiceUrl}/${passengerId}/account`, {
      params: { service_code, location }
    });
  }

  getFare(id: string): Observable<any> {
    // let params = new HttpParams();
    // params = params.append('id', id).append('with_paid_times', '1');

    // return this.http.get<any>(`${fareUrl}`, { params });
    return this.http.get(`${fareUrl}?id=${id}`);
  }

  private orderFareUpdating(duplication: boolean): Observable<any> {
    if (
      this.orderFormService.order &&
      this.orderFormService.fare &&
      (this.orderFormService.order.fare.amount !== this.orderFormService.fare.amount ||
        this.orderFormService.fare.added_value !== this.orderFormService.addedValue)
    ) {
      // order creating fare update
      return this.updateFare({
        added_value: +this.orderFormService.addedValue || 0,
        id: this.orderFormService.fare.id,
        notify: false,
        added_distance: this.orderFormService.addDistance$.value
      }).pipe(
        mergeMap((updatedFare: Fare) => {
          this.orderFormService.fare = updatedFare;
          if (this.orderFormService.order.fare.id === updatedFare.id) {
            this.orderFormService.order.fare =  updatedFare;
          }
          return of(updatedFare);
        })
      );
    } else if (this.orderFormService.fare.added_value !== this.orderFormService.addedValue && !duplication) {
      // order creating fare update
      return this.updateFare({
        added_value: +this.orderFormService.addedValue || 0,
        id: this.orderFormService.fare.id,
        notify: false,
        added_distance: this.orderFormService.addDistance$.value
      }).pipe(
        mergeMap((updatedFare: Fare) => {
          this.orderFormService.fare = updatedFare;
          return of(updatedFare);
        })
      );
    } else {
      return of(true);
    }
  }

  private updateFare(obj: { added_value: number; id: string; notify: boolean; added_distance?: AddedKmToOrderInterface }) {
    return this.http.post(fareUrl, {...obj, with_request_update: true});
  }

  private updateOrder(upOrd) {
    return this.http.post(updateOrderUrl, upOrd).pipe(
      tap(() => (this.phonePaymentId = null)),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => {
        this.orderFormService.disableSaveButtons$.next(false);
      })
    );
  }

  private createOrder(newOrd, dublication: boolean): Observable<any> {
    console.log('createOrder', newOrd.fare_id);
    if (dublication) {
      newOrd.is_duplicate = true;
    }
    return this.http.post(createOrderUrl, newOrd).pipe(
      tap(() => {
        if (!dublication) {
          this.phonePaymentId = null;
        }
      })
    );
  }

  private getPreorderObj(formValueType) {
    if (formValueType.isPreorder && formValueType.preorderedTime && formValueType.preorderedDate) {
      return {
        is_preorder: true,
        assigned_at_tz: moment(formValueType.preorderedDate)
          .hours(formValueType.preorderedTime.substring(0, 2))
          .minutes(formValueType.preorderedTime.substring(3, 5))
          .format('YYYY-MM-DD HH:mm:ss'),
      };
    } else {
      return {
        is_preorder: false
      };
    }
  }
}
