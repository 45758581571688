import { BehaviorSubject } from 'rxjs';
import { ArchiveApiService } from './../../services/archive-api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'utax-archive-table',
  templateUrl: './archive-table.component.html',
  styleUrls: ['./archive-table.component.scss', '../columns.scss']
})
export class ArchiveTableComponent implements OnInit {
  list = new BehaviorSubject([]);
  testList;

  constructor(public archiveApiService: ArchiveApiService) { }

  ngOnInit() {
  }

  scroll(newPage) {
    const page = this.archiveApiService.options.page;
    const buffer = page * this.archiveApiService.options.per_page - newPage;
    if (buffer < 10) {
      this.archiveApiService.getArchiveList({ page: page + 1 });
    }
  }

  switchSearch(event) {
    console.log('TCL: ArchiveTableComponent -> switchSearch -> event', event);
    const searchParam = {};
    this.archiveApiService;
    for (const key in event) {
      searchParam[`search[${key}]`] = event[key] ? this.archiveApiService.query : '';
    }
    this.archiveApiService.getArchiveList(searchParam);

  }
}
