import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { ScheduledBonusesService } from '../../services/scheduled-bonuses.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  getBonuseMode,
  getFormAnObjectToSend,
  getConvertedExpression
} from 'src/app/shared/utils/convert-time-expression';
import { ScheduledBonuse } from '../../models/bonus.model';
import { takeUntil, map, debounceTime } from 'rxjs/operators';
import { daysOfWeekScheduledBonuses } from '../../utils/consts';
import { YesNoModalComponent } from 'src/app/shared/components';

interface CustomDay {
  id: number;
  value: string;
}

@Component({
  selector: 'utax-driver-bonuses-tab',
  templateUrl: './driver-bonuses-tab.component.html',
  styleUrls: ['./driver-bonuses-tab.component.scss']
})
export class DriverBonusesTabComponent implements OnInit, OnChanges, OnDestroy {
  @Input() service: number;
  @Input() tariff: any; // selected driver tariff obj
  @Input() listOfTariffs: any[];

  bonusesListForm: UntypedFormGroup;
  bonusesListFormArray: UntypedFormArray;
  selectedIndex = 0;
  daysOfWeek: CustomDay[];
  bonusesMode: Array<string>;

  sortedListOfTariffs: any[];

  private bonusesSubs: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    public scheduledBonusesService: ScheduledBonusesService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog
  ) {
    this.daysOfWeek = daysOfWeekScheduledBonuses;
  }

  ngOnInit() {
    this.bonusesMode = getBonuseMode();
    this.bonusesSubs = this.scheduledBonusesService.bonuses$.subscribe((bonuses: ScheduledBonuse[]) => {
      const bonusesFiltered = bonuses.filter(bonus => {
        return (
          bonus.tariff_plans.length === 0 || bonus.tariff_plans.some(planId => this.tariff && planId === this.tariff.id)
        );
      });
      this.clearFormArray();
      this.buildBonusesFormArray(this.dividerExpressionValues(bonusesFiltered));
    });
    this.bonusesListFormArray = this.fb.array([this.initControls()]);
    this.initForm();
    this.bonusesListForm.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$),
        map((val: any) => val.bonuses),
        debounceTime(100)
      )
      .subscribe(bonusesArray => {
        this.getSwitchDaysOfWeekFields(bonusesArray);
      });
  }

  ngOnChanges(): void {
    this.sortedListOfTariffs = [...this.listOfTariffs];
    this.sortedListOfTariffs.sort((a, b) => {
      return this.tariff && a.id !== this.tariff.id ? 1 : -1;
    });
    this.scheduledBonusesService.selectedServiceId = this.service;
    if (this.service) {
      this.scheduledBonusesService.fetchBonusesByService(this.service);
    }
  }

  ngOnDestroy() {
    this.bonusesSubs.unsubscribe();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  select(index: number) {
    this.selectedIndex = index;
  }

  onSaveBonuse(index: number) {
    const saveFormsValues = this.bonusesListFormArray.controls[index].value;
    const requertObject = {
      id: saveFormsValues.id ? saveFormsValues.id : null,
      value: saveFormsValues.bonuse_sum,
      use_product_filter: saveFormsValues.use_product_filter,
      products: saveFormsValues.products,
      use_payment_type_filter: saveFormsValues.use_payment_type_filter,
      payment_types: saveFormsValues.payment_types,
      request_type: saveFormsValues.request_type,
      tariff_plans: saveFormsValues.tariff_plans,
      use_tariff_plan_filter: true,
      ...getFormAnObjectToSend(this.timeValueSource(saveFormsValues))
    };
    this.scheduledBonusesService.fetchCreateUpdateBonuice(requertObject);
  }

  onRemoveBonuse(index) {
    const removedBonuseOId = this.bonusesListFormArray.getRawValue()[index].id;
    if (removedBonuseOId) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: 'BONUSE_REMOVE_MSG'
      };
      dialogConfig.panelClass = 'yes-no-modal-container';
      this.dialog
        .open(YesNoModalComponent, dialogConfig)
        .afterClosed()
        .subscribe(res => {
          if (res === 'YES') {
            this.scheduledBonusesService.fethRmoveBonuse(removedBonuseOId);
            this.select(index - 1);
          }
        });
    } else {
      this.bonusesListFormArray.removeAt(index);
    }
  }

  addNewBonuse() {
    this.bonusesListFormArray.push(this.initControls());
    this.select(this.bonusesListFormArray.length - 1);
  }

  private getSwitchDaysOfWeekFields(data) {
    const disableEnableControls: Array<string> = ['days_of_week', 'start_day', 'end_day', 'start_date', 'end_date'];
    data.forEach((item, index) => {
      switch (item.bonuses_mode) {
        case this.bonusesMode[0]:
          this.weeksModeSwitchSource(index, disableEnableControls);
          break;
        case this.bonusesMode[1]:
          this.daysModeSwitchSource(index, disableEnableControls);
          break;
        case this.bonusesMode[2]:
          this.monthModeSwitchSource(index, disableEnableControls);
          break;
        default:
          this.weeksModeSwitchSource(index, disableEnableControls);
          break;
      }
    });
  }

  private daysModeSwitchSource(i: number, controlsArr: Array<string>) {
    controlsArr.forEach((el, index) => {
      index === 1 || index === 2
        ? (this.bonusesListFormArray.controls[i] as UntypedFormGroup).controls[el].enable()
        : (this.bonusesListFormArray.controls[i] as UntypedFormGroup).controls[el].disable();
    });
  }

  private weeksModeSwitchSource(i: number, controlsArr: Array<string>) {
    controlsArr.forEach((el, index) => {
      index === 0
        ? (this.bonusesListFormArray.controls[i] as UntypedFormGroup).controls[el].enable()
        : (this.bonusesListFormArray.controls[i] as UntypedFormGroup).controls[el].disable();
    });
  }

  private monthModeSwitchSource(i: number, controlsArr: Array<string>) {
    controlsArr.forEach((el, index) => {
      index === 3 || index === 4
        ? (this.bonusesListFormArray.controls[i] as UntypedFormGroup).controls[el].enable()
        : (this.bonusesListFormArray.controls[i] as UntypedFormGroup).controls[el].disable();
    });
  }

  private dividerExpressionValues(data: ScheduledBonuse[]) {
    return data.map((el: ScheduledBonuse) => {
      return { ...el, ...getConvertedExpression(el) };
    });
  }

  private buildBonusesFormArray(data) {
    if (data && this.bonusesListFormArray) {
      data.forEach((el, i) => {
        this.bonusesListFormArray.setControl(i, this.initControls(el));
      });
    }
  }

  private clearFormArray() {
    if (this.bonusesListFormArray) {
      this.bonusesListFormArray.controls = [];
      this.bonusesListFormArray.setControl(0, this.initControls());
    }
  }

  private initForm() {
    this.bonusesListForm = this.fb.group({
      bonuses: this.bonusesListFormArray
    });
  }

  private initControls(el?) {
    return this.fb.group({
      start_time: [
        el && el.start_time ? el.start_time : '00:00',
        Validators.compose([
          Validators.maxLength(5),
          Validators.required,
          Validators.pattern('^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9])|(23:?00))')
        ])
      ],
      end_time: [
        el && el.end_time ? el.end_time : '00:00',
        Validators.compose([
          Validators.maxLength(5),
          Validators.required,
          Validators.pattern('^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9])|(23:?00))')
        ])
      ],
      days_of_week: [
        el && el.days_of_week ? el.days_of_week : this.daysOfWeek.map(elem => elem.id),
        Validators.required
      ],
      bonuse_sum: [el && el.value ? el.value : 0, Validators.compose([Validators.required, Validators.min(0)])],
      id: el && el.id ? el.id : null,
      bonuses_mode: el && el.bonuses_mode ? el.bonuses_mode : this.bonusesMode[0],
      start_day: [el && el.start_day ? el.start_day : 0, Validators.required],
      end_day: [el && el.end_day ? el.end_day : 0, Validators.required],
      start_date: [el && el.start_date ? el.start_date : '', Validators.required],
      end_date: [el && el.end_date ? el.end_date : '', Validators.required],
      use_product_filter: [el ? el.use_product_filter : false],
      products: [el && el.products ? el.products : []],
      use_payment_type_filter: [el ? el.use_payment_type_filter : false],
      payment_types: [el && el.payment_types ? el.payment_types : []],
      request_type: [el ? el.request_type : 'all', Validators.required],
      tariff_plans: [el ? el.tariff_plans : '', Validators.required]
    });
  }

  private timeValueSource(obj) {
    if (!(obj.start_time.indexOf(':') + 1)) {
      obj.start_time = obj.start_time.slice(0, 2) + ':' + obj.start_time.slice(2);
    }
    if (!(obj.end_time.indexOf(':') + 1)) {
      obj.end_time = obj.end_time.slice(0, 2) + ':' + obj.end_time.slice(2);
    }
    return obj;
  }
}
