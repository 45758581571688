import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';
import { PassengerData } from 'src/app/interfaces/order.interface';
import {AsteriskServerInterface} from '../models/asteriskServer.interface';
import {QualityRatingInterface} from '../models/telephony.interface';

const passangerUrl = 'operator/passenger',
  openWorkingShifturl = 'call-centre/api/me/work-shift/open',
  closeWorkingShifturl = 'call-centre/api/me/work-shift/close',
  pauseStatusUrl = 'call-centre/api/me/status/pause',
  unpauseStatusUrl = 'call-centre/api/me/status/unpause',
  operatorCredentialsUrl = 'call-centre/api/me/asterisk/credentials',
  qualityRatingUrl = 'call-centre/api/call-quality-ratings';

@Injectable()
export class TelephonyApiService {
  constructor(private http: HttpClient) {}

  getPassengerByPhoneNumber(phone: string, service: number): Observable<PassengerData> {
    return this.http
      .post<PassengerData>(passangerUrl, {
        passenger: {
          phone_number: phone,
          country: 'ua'
        },
        service
      })
      .pipe(pluck('data'));
  }

  startWorkingShift(): Observable<any> {
    return this.http.post(openWorkingShifturl, {}).pipe(map(() => true));
  }

  closeWorkingShift(): Observable<any> {
    return this.http.post(closeWorkingShifturl, {}).pipe(map(() => true));
  }

  walkedAway(status: boolean): Observable<any> {
    if (status) {
      return this.http.post(unpauseStatusUrl, {}).pipe(map(() => true));
    }
    return this.http.post(pauseStatusUrl, {}).pipe(map(() => true));
  }

  getOperatorCredentials(): Observable<any> {
    return this.http.get(operatorCredentialsUrl);
  }

  getAsteriskServices(): Observable<AsteriskServerInterface[]> {
    return this.http.get<any[]>('call-centre/api/asterisk/servers');
  }

  sendQualityRating(rating: QualityRatingInterface): Observable<void> {
    return this.http.post<void>(qualityRatingUrl, rating);
  }
}
