import { Queue, CallWayCall } from './../models/dispatcher';
import { HttpClient } from '@angular/common/http';
import { WebSocketSubject } from 'rxjs/webSocket';
import { Injectable, NgZone } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { pluck } from 'rxjs/operators';

const queuesUrl = 'operator/telephony/queues';
const queuesUrl2 = 'operator/call-center/queues';
const transferQueuesUrl = 'operator/telephony/queues/transfer';
const transferQueuesListUrl = 'call-centre/api/queues/common';

@Injectable({
  providedIn: 'root'
})
export class CallWayService {
  useCallWay = false;
  callWaySocket$: WebSocketSubject<any>;
  callWaySocketConnected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  callWaySocketConnected = false;
  call$: Subject<CallWayCall[]> = new Subject();
  queues: Queue[];
  queues2: Queue[];
  transferQueueIds: any[];
  transferQueues: any[];
  transferQueues$: BehaviorSubject<any> = new BehaviorSubject(false);
  private attemptsNumber = 0;
  constructor(private http: HttpClient, private zone: NgZone) {}

  enableService() {
    this.useCallWay = false;
    this.http
      .get(queuesUrl)
      .pipe(pluck('data'))
      .subscribe((queuesArr: any[]) => {
        this.queues = queuesArr;
        // this.connect();
      });
    this.http
      .get(queuesUrl2)
      .pipe(pluck('data'))
      .subscribe((queuesArr: any[]) => {
        this.queues2 = queuesArr;
        // this.connect();
      });
    this.http
      .get(transferQueuesUrl)
      .pipe(pluck('data'))
      .subscribe((transQueues: any[]) => {
        this.transferQueueIds = transQueues.map(item => `${item.queue_id}`);
      });
    this.http
      .get(transferQueuesListUrl)
      .subscribe((transQueues: any[]) => {
        this.transferQueues = transQueues;
        this.transferQueues$.next(this.transferQueues);
      });
  }

  completeCallWaySocket(): void {
    this.callWaySocket$.complete();
  }

  private connect(): void {
    this.callWaySocket$ = new WebSocketSubject({
      url: 'ws://127.0.0.1:7000',
      openObserver: {
        next: () => {
          this.callWaySocketConnected = true;
          this.callWaySocketConnected$.next(true);
          console.log('callway open!', this.callWaySocketConnected);
        }
      },
      closeObserver: {
        next: () => {
          this.callWaySocketConnected = false;
          this.callWaySocketConnected$.next(false);
          console.log('callway close!', this.callWaySocketConnected);
        }
      }
    });
    this.callWaySocket$.subscribe(
      message => {
        this.attemptsNumber = 0;
        this.call$.next(message);
      },
      error => {
        this.attemptsNumber = this.attemptsNumber + 1;
        this.reconnectCallWaySocket();
        console.log('CALLWAY ERROR => connect', error);
      }
    );

    this.zone.runOutsideAngular(() => {
      setInterval(() => {
        this.callWaySocket$.next({
          ping: 'true'
        });
      }, 15000);
    });
  }

  private reconnectCallWaySocket(): void {
    setTimeout(() => {
      this.callWaySocket$.complete();
      this.connect();
    }, this.getTimeToReconnect());
  }

  private getTimeToReconnect(): number {
    if (this.attemptsNumber <= 20) {
      return 5000;
    } else if (this.attemptsNumber > 20 && this.attemptsNumber <= 40) {
      return 10000;
    } else if (this.attemptsNumber > 40 && this.attemptsNumber < 70) {
      return 60000;
    } else {
      return 9999999;
    }
  }
}
