import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {ImageInterface} from '../interfaces/image.interface';

const imageUrl = 'media/api/images';
const imagesUrl = 'media/api/images/batch';

@Injectable()
export class MediaService {
  constructor(private http: HttpClient) {}

  getImage(storedId: string): any {
    return this.http.get(imageUrl, {
      responseType: 'blob',
      params: { uId: storedId },
      headers: new HttpHeaders('application/octet-stream')
    });
  }

  getImagesArray(storedIds: string[]): Observable<string> {
    let params = new HttpParams();
    params = params.append('uIds', storedIds.join(','));
    return this.http.get<string>(imagesUrl, { params });
  }

  postImage(file: any, token: string): Observable<{ id: string }> {
    const fd = new FormData();
    return this.http.post<{ id: string }>(`${imageUrl}/${token}`, fd);
  }

  public postDriverImage(image: Blob, driver_id: string, type: ImageInterface['type']): Observable<any> {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.post<any>(`operator/media/driver/images`, formData, {params: {driver_id, type}});
  }

  public postVehicleImage(image: Blob, vehicle_id: string, type: ImageInterface['type']): Observable<any> {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.post<any>(`operator/media/vehicle/images`, formData, {params: {vehicle_id, type}});
  }

  public postTemporaryDriverImage(image: Blob, id: string, type: ImageInterface['type']): Observable<any> {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.post<any>(`operator/media/temporary/driver/images`, formData, {params: {id, type}});
  }

  public postTemporaryVehicleImage(image: Blob, id: string, type: ImageInterface['type']): Observable<any> {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.post<any>(`operator/media/temporary/vehicle/images`, formData, {params: {id, type}});
  }
}
