import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getReqIconHistory'
})
export class GetReqIconHistoryPipe implements PipeTransform {
  transform(reqType: 'start_driver_rest' | 'vehicle_refill' | 'start_vehicle_washing'): string {
    if (reqType === 'start_driver_rest') {
      return 'coffee-without-fill';
    } else if (reqType === 'vehicle_refill') {
      return 'refill_req';
    } else if (reqType === 'start_vehicle_washing') {
      return 'washing_req';
    }
  }
}
