import { Component, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'utax-partners-modal',
  templateUrl: './partners-modal.component.html',
  styleUrls: ['./partners-modal.component.scss']
})
export class PartnersModalComponent {
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PartnersModalComponent>,
    public sanitizer: DomSanitizer
  ) {}
}
