import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output, ViewChild,
  ViewChildren
} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {AllowIn, ShortcutInput} from 'ng-keyboard-shortcuts';
import {Observable, of, Subject} from 'rxjs';
import {debounceTime, filter, pluck, switchMap, takeUntil, tap} from 'rxjs/operators';
import {HandleEventService, StateService} from 'src/app/services';
import {TelephonySettingsModalComponent} from '../../components';
import {TelephonyApiService, TelephonyService} from '../../services';
import {TransferQueueModalComponent} from 'src/app/dispatcher/components';
import {secondsToMinutesAndSeconds} from 'src/app/utils/time-transforms';
import {OrderFormService} from '../../../order-form/services';
import {TelephonySharedService} from '../../services/telephony-shared.service';
import {TelephonyIncomingService} from '../../services/telephony-incoming.service';
import {CallWayService} from '../../../dispatcher/services';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {GlobalDataService} from '@global-services/global-data.service';
import {environment} from '@global-environments/environment';
import {CallWayCall} from '../../../dispatcher/models/dispatcher';

@UntilDestroy()
@Component({
  selector: 'utax-telephony',
  templateUrl: './telephony.component.html',
  styleUrls: ['./telephony.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TelephonyComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  @ViewChildren('mediaElement') mediaElement: any;
  @ViewChild('telephonyContainer') telephonyContainer: ElementRef;
  @Input() transferQueues;
  @Output() closeOrderForm = new EventEmitter();
  shortcuts$: Observable<ShortcutInput[]>;

  userConnected = false;
  isConference = false;

  sessions: any[] = [];
  subscribeTimer: { [key: string]: { subs: any, time: number } } = {};

  private dialogRef: MatDialogRef<TelephonySettingsModalComponent | TransferQueueModalComponent>;
  private componentDestroyed$ = new Subject();
  constructor(
    public telephonyService: TelephonyService,
    private cd: ChangeDetectorRef,
    private orderFormService: OrderFormService,
    private callWayService: CallWayService,
    private dialog: MatDialog,
    private state: StateService,
    private telephonySharedService: TelephonySharedService,
    private telephonyApiService: TelephonyApiService,
    private telephonyIncomingService: TelephonyIncomingService,
    private handleEventService: HandleEventService,
    public globalDataService: GlobalDataService
  ) {}

  ngOnInit(): void {
    this.connectionSub();
    this.closeModalSub();
    this.userSub();
    this.shortcutHelper();
  }

  ngAfterViewInit(): void {
    this.sessionsSub();
    setTimeout(() => this.initLastRatingCall(), 100);
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  conference(): void {
    this.telephonyService.setConference();
  }

  sessionsSub(): void {
    this.telephonyService.sessions$.pipe(takeUntil(this.componentDestroyed$)
    ).subscribe(sessions => {
      this.sessions = Object.values(sessions);
      this.isConference = (this.sessions.length === 2) && this.sessions.every(session => session.callInProgress);
      this.sessions = this.sessions.map(session => this.prepearSession(session));
      this.cd.markForCheck();
      if ( environment.liteVersion) {
        setTimeout(() => {
          this.telephonySharedService.liteDispatcherMarginCompensation$.next(this.telephonyContainer.nativeElement.offsetHeight);
        }, 100);
      }
    });
  }

  endCall(id): void {
    this.telephonyService.endCall(id);
  }

  acceptIncomingCall(session): void {
    if (session.incomingPhoneNumber && !session.callInProgress) {
      const call = this.telephonyService.acceptIncomingCall(session.id);
      console.log('🚀 ~acceptIncomingCall ~ call', call);
      if (this.telephonyService.telephonyRating$.value?.ratingSending) {
        this.telephonyService.sendRatingNow();
      }
      if (call) {
        this.telephonySharedService.incomeCalls$.next(call);
        this.telephonyIncomingService.isIncomeCalls$.next(true);
        this.initEvaluationProcess(call);
      }
    }
  }

  rejectIncomingCall(id: string): void {
    this.telephonyService.rejectIncomingCall(id);
  }

  startTimer(session) {
    this.subscribeTimer[session.id].subs = setInterval(() => {
      this.subscribeTimer[session.id].time++;
      session.timer$.next(secondsToMinutesAndSeconds(this.subscribeTimer[session.id].time));
    }, 1000);
  }

  cancel(session): void {
    if (this.subscribeTimer[session.id]) {
      this.subscribeTimer[session.id].time = 0;
      clearInterval(this.subscribeTimer[session.id].subs);
    }

    if (session.callInProgress) {
      this.endCall(session.id);
    } else if (session.incomingPhoneNumber) {
      this.rejectIncomingCall(session.id);
    } else if (session?.outcomeCall?.phoneNumber) {
      this.endCall(session.id);
    }
  }

  hold(id): void {
    this.telephonyService.holdUnholdCall(id);
  }

  transfer(sessionId): void {
    console.log(sessionId);
    console.log(this.transferQueues);
    console.log('--------------------------');
    if (!this.telephonyIncomingService.isIncomeCalls$.value) {
      this.handleEventService.openSnackBar('UTAX_OUTCOME_CALL_TRANSFER_NOT_ALLOWED');
      return;
    }
    if (this.transferQueues && this.transferQueues.length > 0) {
      const config = new MatDialogConfig();
      config.panelClass = 'transfer-call-modal-container';
      config.data = {
        queues: this.transferQueues,
        permanentalOpen: true,
        transferQueueIds: this.callWayService.transferQueueIds,
        showHelpInfo: false
      };
      config.disableClose = true;
      config.position = {
        top: '6vh'
      };
      config.height = '94vh';
      this.dialogRef = this.dialog.open(TransferQueueModalComponent, config);
      this.dialogRef
        .afterClosed()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(data => {
          if (data && data.queue_id) {
            this.telephonyService.transferCall(sessionId, data);
          }
          this.dialogRef = null;
        });
    }
  }

  openSettings(): void {
    const config = new MatDialogConfig();
    config.data = {
      form: this.telephonyService.settingsForm
    };
    config.panelClass = 'yes-no-modal-container';
    this.dialogRef = this.dialog.open(TelephonySettingsModalComponent, config);
    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(res => {
        if (res) {
          this.connect();
        }
      });
  }

  getPrevQueueName(mappingId: string): any {
    return this.transferQueues.find(queue => queue.mappingId === mappingId)?.name;
  }


  connect(): void {
    this.telephonyService.connect();
  }

  changeOperatorStatus(status: boolean): void {
    this.telephonyService.changeWorkingShiftStatus(status).subscribe();
  }


  closeShift(): void {
    if (this.state.hasCallCentrePerms()) {
      this.telephonyApiService.closeWorkingShift().subscribe();
    }
  }

  startShift(): void {
    if (this.state.hasCallCentrePerms()) {
      this.telephonyApiService.startWorkingShift().subscribe();
    }
  }

  disconnect(): void {
    this.telephonyService.disconnect().subscribe();
  }

  lastCallReplay(): void {
    const sessionStorageCall = sessionStorage.getItem('lastCall');
    if (sessionStorageCall) {
      const { phone, user } = JSON.parse(sessionStorageCall);
      if (phone && user) {
        this.telephonyService.makeCall(phone, user, true);
      }
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  public beforeunloadHandler($event) {
    if (this.sessions.some(session => session.callInProgress)) {
      $event.returnValue = 'Are you sure?';
    }
  }

  private closeModal(cseq): void {
    this.closeOrderForm.emit({ cseq });
  }

  private prepearSession(session): any {
    const prepeared = session;
    // somebody colling
    if (session.incomingPassOrDriver && !session.incomingPassOrDriver.serviceCityName) {
      const incomingUserService = this.state?.dumbStore?.service?.find(
        serv => serv.id === session.incomingPassOrDriver.serviceId
      );
    }

    // outcome call
    if (session.outcomePassOrDriver && !session.outcomePassOrDriver.serviceCityName) {
      const userService = this.state?.dumbStore?.service?.find(
        serv => serv.id === session.outcomePassOrDriver.serviceId
      );
    }

    // call in progress
    if ((session.callInProgress || session.callInRinging) && this.mediaElement?._results?.length) {
      let mediaIndex = 0;
      if ((this.mediaElement as any)._results.length > 1) {
        (this.mediaElement as any)._results.forEach((element, index) => {
          if (element.nativeElement.id === session.id) {
            mediaIndex = index;
          }
        });
      }
      this.mediaElement._results[mediaIndex].nativeElement.srcObject = session.remoteStream;
      this.mediaElement._results[mediaIndex].nativeElement.play();
      if ((!this.subscribeTimer[session.id] || this.subscribeTimer[session.id]?.time === 0) && session.callInProgress) {
        this.subscribeTimer[session.id] = { subs: null, time: 0 };
        this.startTimer(session);
        session.timer$.next(secondsToMinutesAndSeconds(this.subscribeTimer[session.id].time));
      }
    }
    return prepeared;
  }

  private closeModalSub(): void {
    this.telephonyService.closeModal$.pipe(takeUntil(this.componentDestroyed$)).subscribe(({ close, invitation }) => {
      if (close) {
        let cseq;
        if (invitation?.incomingInviteRequest?.message?.cseq) {
          cseq = invitation.incomingInviteRequest.message.cseq;
        }
        this.closeModal(cseq);
      }
    });
  }

  private connectionSub(): void {
    this.telephonyService.connected$.pipe(takeUntil(this.componentDestroyed$)).subscribe((connected: boolean) => {
      this.userConnected = connected;
      this.cd.markForCheck();
    });
  }

  private userSub(): void {
    this.state
      .getStoreParamSub('user')
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(res => res),
        pluck('data')
      )
      .subscribe(user => {
        const needToConnect = localStorage.getItem('telephonyConnect');
        if (needToConnect === 'true') {
          this.connect();
        }
        this.telephonyService.generateForm().subscribe();
      });
  }
  private initEvaluationProcess(call: CallWayCall): void {
    localStorage.setItem('lastCallDirection', `income`);
    this.telephonyService.lastCallDirection.next('income');
    if (this.telephonyService.lastCallStream$.value &&
      !this.telephonyService.lastCallStream$.value.isRated &&
      this.telephonyService.lastCallStream$.value.ratingSending) {
      this.telephonyApiService.sendQualityRating({callStageId: this.telephonyService.lastCallStream$.value.callStageId, rating: 1})
        .pipe(
          debounceTime(5000),
          tap(() => {
            const defaultRating = {callStageId: call.call_id, isRated: false, ratingSending: false, ratingUnlock: true};
            localStorage.setItem('ratingCall', JSON.stringify(defaultRating));
            this.initLastRatingCall();
          }),
          untilDestroyed(this)
        )
        .subscribe();
    } else {
      if (this.telephonyService.lastCallStream$.value) {
        const lockRating = {callStageId: this.telephonyService.lastCallStream$.value.callStageId, isRated: false, ratingSending: false, ratingUnlock: false};
        localStorage.setItem('ratingCall', JSON.stringify(lockRating));
        this.initLastRatingCall();
      }
      this.telephonyService.timerRating$.pipe(
        tap(() => {
          if (this.telephonyIncomingService.isIncomeCalls$.value) {
            const defaultRating = {callStageId: call.call_id, isRated: false, ratingSending: false, ratingUnlock: true};
            localStorage.setItem('ratingCall', JSON.stringify(defaultRating));
            this.initLastRatingCall();
          }
        }),
        untilDestroyed(this)
      ).subscribe();
    }
  }
  private initLastRatingCall(): void {
    const lastCall = JSON.parse(localStorage.getItem('ratingCall'));
    this.telephonyService.lastCallDirection.next(localStorage.getItem('lastCallDirection'));
    this.telephonyService.lastCallStream$.next(JSON.parse(localStorage.getItem('ratingCall')));
    if (this.telephonyService.lastCallStream$.value) {
      this.telephonyService.telephonyRating$.next(lastCall);
    }
  }

  public sendRating() {
    if (this.telephonyService.lastCallStream$.value.ratingUnlock) {
      if (this.telephonyService.lastCallStream$.value && !this.telephonyService.lastCallStream$.value.isRated) {
        this.telephonyService.sendRating(this.telephonyService.lastCallStream$.value);
      }
    } else {
      this.handleEventService.openSnackBar('UTAX_SPEAK_TO_LITTLE_TO_BE_RATED');
    }
  }

  public shortcutHelper(): void {
    this.shortcuts$ = this.globalDataService.isNewKeyboardLayout$
      .pipe(
        switchMap(isNewKeyboardLayout => {
          if (isNewKeyboardLayout) {
            return of([
              {
                key: ['f1'],
                command: e => {
                  if (this.sessions && this.sessions.length > 0) {
                    this.acceptIncomingCall(this.sessions[0]);
                  }
                },
                preventDefault: true,
              },
              {
                key: ['f3'],
                command: e => {
                  if (this.sessions && this.sessions.length > 0) {
                    for (const session in this.sessions) {
                      if (
                        this.sessions[session] &&
                        (this.sessions[session].callInProgress &&
                          !this.sessions[session]?.hold) ||
                        this.sessions[session]?.outcomeCall
                      ) {
                        this.cancel(this.sessions[session]);
                      }
                    }
                  }
                },
                preventDefault: true,
                allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select]
              },
              {
                key: ['f4'],
                command: e => {
                  if (this.sessions && this.sessions.length > 0) {
                    this.hold(this.sessions[0].id);
                  }
                },
                preventDefault: true,
                allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select]
              },
              {
                key: ['PageDown'],
                preventDefault: true,
                command: () => {
                  this.sendRating();
                }
              },
            ]);
          } else {
            return of([
              {
                key: ['1'],
                command: e => {
                  if (e.event.code === 'Digit1' && this.sessions && this.sessions.length > 0) {
                    this.acceptIncomingCall(this.sessions[0]);
                  }
                },
                allowIn: [AllowIn.Textarea, AllowIn.Input]
              },
              {
                key: ['3'],
                command: e => {
                  if (e.event.code === 'Digit3' && this.sessions && this.sessions.length > 0) {
                    for (const session in this.sessions) {
                      if (
                        this.sessions[session] &&
                        (this.sessions[session].callInProgress &&
                          !this.sessions[session]?.hold) ||
                        this.sessions[session]?.outcomeCall
                      ) {
                        this.cancel(this.sessions[session]);
                      }
                    }
                  }
                },
                allowIn: [AllowIn.Textarea, AllowIn.Input]
              },
              {
                key: ['4'],
                command: e => {
                  if (e.event.code === 'Digit4' && this.sessions && this.sessions.length > 0) {
                    this.hold(this.sessions[0].id);
                  }
                },
                allowIn: [AllowIn.Textarea, AllowIn.Input]
              },
              {
                key: ['PageDown'],
                preventDefault: true,
                command: () => {
                  this.sendRating();
                }
              },
            ]);
          }
        })
      );
  }

}
