import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ChatHistoryDataInterface} from '../../interfaces/chat.interface';

@Component({
  selector: 'utax-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent implements OnInit {

  @Input() message: ChatHistoryDataInterface;

  constructor() { }

  ngOnInit(): void {
  }

  prepareName(name: string) {
    const firstIndex = name.indexOf('(');
    return name.slice(firstIndex + 1, name.length - 1);
  }
}
