import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {OrderClass} from '@global-classes/order.class';
import {TranslateService} from '@ngx-translate/core';
import {tap} from "rxjs/operators";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {StateService} from "@global-services/state.service";
import {OrdersService} from "@global-services/orders.service";

@UntilDestroy()
@Component({
  selector: 'utax-dispatcher-order-item-comment',
  templateUrl: './dispatcher-order-item-comment.component.html',
  styleUrls: ['./dispatcher-order-item-comment.component.scss']
})
export class DispatcherOrderItemCommentComponent implements OnInit, OnChanges {
  @Input() order: OrderClass;
  public comment: string;
  public permissionOperatorComment: boolean;
  constructor(
    private translateService: TranslateService,
    private ordersService: OrdersService,
    private stateService: StateService
  ) {
    this.stateService.getStoreParamSub('permissions')
      .pipe(
        tap((permissions) => this.permissionOperatorComment = permissions.some(p => p.name === 'operator_request_discuss')),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public createTooltipText(order: OrderClass): string {
    let comment = '';
    if (order?.disp_comment) {
      comment += `${this.translateService.instant('UTAX_FRONT_AFTER')}: ${order.disp_comment}`;
    }
    if (order?.pass_comment) {
      comment += `${order?.disp_comment ? '\n\n' : ''}${this.translateService.instant('UTAX_FRONT_BEFORE')}: ${order.pass_comment}`;
    }
    if (order?.last_discussion_message && this.permissionOperatorComment) {
      comment += `${order?.pass_comment || order?.disp_comment ? '\n\n' : ''}${this.translateService.instant('UTAX_OPERATOR_COMMENT')}: ${order.last_discussion_message}`;
    }
    return comment;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.comment = this.createTooltipText(this.order);
  }

  ngOnInit(): void {
    this.ordersService.ordersUpdates$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
      this.comment = this.createTooltipText(this.order);
    });
  }

}
