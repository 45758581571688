import { OrderFormModule } from './order-form/order-form.module';
import { AppTranslateModule } from './utils/app-translate.module';
import { MapModule } from './map/map.module';
import { AuthModule } from './auth/auth.module';
import { CabinetModule } from './cabinet/cabinet.module';
import { ArchiveModule } from './archive/archive.module';
import { LogistModule } from './logist/logist.module';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './utils/material.module';
import { DispatcherModule } from './dispatcher/dispatcher.module';
import { UsersPageModule } from './users/users-page.module';
import { DriverTariffsModule } from './driver-tariffs/driver-tariffs.module';
import { TelephonyModule } from './telephony/telephony.module';
import { CallCentreModule } from './call-centre/call-centre.module';
import {environment} from '../environments/environment';

export let modules: any[] = [];

if (!environment.liteVersion) {
    modules = [
      OrderFormModule,
      AppTranslateModule,
      MapModule,
      AuthModule,
      CabinetModule,
      ArchiveModule,
      LogistModule,
      SharedModule,
      MaterialModule,
      DispatcherModule,
      UsersPageModule,
      DriverTariffsModule,
      TelephonyModule,
      CallCentreModule
    ];
  } else {
    modules = [
      OrderFormModule,
      AppTranslateModule,
      MapModule,
      AuthModule,
      CabinetModule,
      // ArchiveModule,
      // LogistModule,
      SharedModule,
      MaterialModule,
      DispatcherModule,
      // UsersPageModule,
      // DriverTariffsModule,
      TelephonyModule,
      // CallCentreModule
    ];
  }

export * from './call-centre/call-centre.module';
export * from './telephony/telephony.module';
export * from './driver-tariffs/driver-tariffs.module';
export * from './dispatcher/dispatcher.module';
export * from './order-form/order-form.module';
export * from './utils/app-routing.module';
export * from './utils/app-translate.module';
export * from './utils/material.module';
export * from './archive/archive.module';
export * from './archive/archive.module';
export * from './map/map.module';
export * from './logist/logist.module';
export * from './navigation/navigation.module';
export * from './shared/shared.module';
export * from './users/users-page.module';
