import { DispatcherOrderItemCommentComponent } from './dispatcher-order-item-comment/dispatcher-order-item-comment.component';
import { DispatcherDetailsMapComponent } from './dispatcher-details-map/dispatcher-details-map.component';
import { DispatcherDetailsToolbarComponent } from './dispatcher-details-toolbar/dispatcher-details-toolbar.component';
import { DispatcherOrderItemTimerComponent } from './dispatcher-order-item-timer/dispatcher-order-item-timer.component';
import { DispatcherServiceFilterComponent } from './dispatcher-service-filter/dispatcher-service-filter.component';
import { DispatcherOrderItemComponent } from './dispatcher-order-item/dispatcher-order-item.component';
import { TransferQueueModalComponent } from './transfer-queue-modal/transfer-queue-modal.component';

export const components: any[] = [
  DispatcherOrderItemComponent,
  DispatcherServiceFilterComponent,
  DispatcherOrderItemTimerComponent,
  DispatcherDetailsToolbarComponent,
  DispatcherDetailsMapComponent,
  DispatcherOrderItemCommentComponent,
  TransferQueueModalComponent
];

export * from './transfer-queue-modal/transfer-queue-modal.component';
export * from './dispatcher-order-item-comment/dispatcher-order-item-comment.component';
export * from './dispatcher-details-map/dispatcher-details-map.component';
export * from './dispatcher-details-toolbar/dispatcher-details-toolbar.component';
export * from './dispatcher-order-item-timer/dispatcher-order-item-timer.component';
export * from './dispatcher-service-filter/dispatcher-service-filter.component';
export * from './dispatcher-order-item/dispatcher-order-item.component';
