import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'utax-taxi-service-select',
  templateUrl: './taxi-service-select.component.html',
  styleUrls: ['./taxi-service-select.component.scss']
})
export class TaxiServiceSelectComponent implements OnInit {
  @Output() selectService = new EventEmitter();
  @Input() services;

  selectedService =  +localStorage.getItem('service');

  constructor() { }

  ngOnInit() {
    this.selectService.emit(+localStorage.getItem('service'));
  }

  setServiceToLocal(event) {
    this.selectService.emit(event.value);
  }
}
