import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TaxiServicesService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class SendingService {
  sendingDelayEnabled: boolean;
  serviceId;
  private settings: any = {};

  constructor(private http: HttpClient, private taxiServices: TaxiServicesService) {
    this.taxiServices.servicesSettings$.subscribe(sets => {
      this.settings = sets;
      this.sendingDelayEnabled = sets[this.serviceId] && sets[this.serviceId].requests_sending_delay_enabled == '1';
    });
  }

  setTaxiService(service) {
    this.serviceId = service;
    this.sendingDelayEnabled =
      this.settings[this.serviceId] && this.settings[this.serviceId].requests_sending_delay_enabled == '1';
  }

  switchLogistDelay() {
    this.http
      .put('operator/settings', {
        key: 'requests_sending_delay_enabled',
        service: this.serviceId,
        value: this.sendingDelayEnabled ? '1' : '0'
      })
      .subscribe(res => {});
  }
}
