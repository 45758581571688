import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasServiceReqs'
})
export class HasServiceReqsPipe implements PipeTransform {
  transform(cabs: any[], serviceId: number): boolean {
    return (cabs || []).some(cab => cab.service_id === serviceId && this.hasCabReqsInStatus(cab, 'waiting'));
  }

  private hasCabReqsInStatus(cab: any, status: string): boolean {
    return (cab.requests || []).some(req => req.status === status);
  }
}
