import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, of, Subject } from 'rxjs';
import {debounceTime, filter, map, mergeMap, switchMap, takeUntil} from 'rxjs/operators';
import { DispSettingsCreateDispModalComponent } from '../../components';
import { DispSettingsApiService, DispSettingsService } from '../../services';
import { HistoryChange } from '../../models';
import * as moment from 'moment';
import * as _ from 'lodash';
import { UntypedFormGroup } from '@angular/forms';
import {HandleEventService} from "@global-services/handle-event-service.service";

@Component({
  selector: 'utax-disp-settings-edit',
  templateUrl: './disp-settings-edit.component.html',
  styleUrls: ['./disp-settings-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispSettingsEditComponent implements OnInit, OnDestroy {
  selectedId: string;
  historyList$ = new BehaviorSubject([]);
  historyPage = 1;
  sortParam = {type: 'sort', value: 'name'};  private dialogRef: MatDialogRef<DispSettingsCreateDispModalComponent>;
  private componentDestroyed$ = new Subject();
  constructor(
    public dispSettingsService: DispSettingsService,
    private dispSettingsApiService: DispSettingsApiService,
    private handleEventService: HandleEventService
  ) {}

  ngOnInit(): void {
    this.dispSettingsService.editFilterForm$
      .pipe(
        filter(form => Boolean(form)),
        mergeMap((form: UntypedFormGroup) => {
          return form.valueChanges
            .pipe(
              map(() => form)
            );
        }),
        debounceTime(300),
        takeUntil(this.componentDestroyed$),
        map(form => {
          return this.dispSettingsService.prepearFilters(form);
        })
      )
      .subscribe(filters => {
        this.dispSettingsService.pageDispatchers = 1;
        this.dispSettingsService.loadAllDispatchers([...filters, this.sortParam], true);
      });
    if (this.dispSettingsService.editFilterForm$.value) {
      this.dispSettingsService.editFilterForm$.value.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  toggleSelected(form: UntypedFormGroup): void {
    this.dispSettingsService.prepearFilters(form);
    if (form.value?.queueGroups?.length === 0) {
      this.select('');
    }
  }

  edit(event): void {
    if (event.id) {
      this.dispSettingsApiService
        .getDispById(event.id)
        .pipe(
          mergeMap((disp: any) => {
            this.dialogRef = this.dispSettingsService.openDispModal({
              ...disp,
              // groups: disp.groups.map(group => group.uuid)
            });
            return this.dialogRef.afterClosed().pipe(takeUntil(this.componentDestroyed$));
          }),
          mergeMap(saveObj => {
            if ( saveObj.password) {
              return this.dispSettingsApiService.updateDispPassword(saveObj?.id, saveObj.password).pipe(
                switchMap(() => of(saveObj))
              );
            }
            return of(saveObj);
          }),
          mergeMap(saveObj => {
            if (saveObj) {
              delete saveObj.password;
              return this.dispSettingsApiService.updateDisp(saveObj);
            }
            return of(false);
          })
        )
        .subscribe(save => {
          if (save) {
            this.handleEventService.openSnackBar('UTAX_FRONT_USER_UPDATED_SUCCESS', 1);
            this.dispSettingsService.allDispatchers$.next(
              this.dispSettingsService.allDispatchers$.value.map((disp: any) => {
                return save.id === disp.id ? save : disp;
              })
            );
            this.historyPage = 1;
            this.loadHistory();
          }
        });
    }
  }

  select(event: string): void {
    this.selectedId = event;
    this.historyPage = 1;
    this.historyList$.next([]);
    this.loadHistory();
  }

  loadHistory(): void {
    if (this.selectedId) {
      this.dispSettingsApiService.getDispHistory(this.selectedId, this.historyPage, 30).subscribe(data => {
        const history = data.content;
        if (this.historyPage === 1) {
          this.historyList$.next(history);
          // this.historyList$.next(this.prepearHistory(history));
        } else {
          this.historyList$.next([...this.historyList$.value, ...history]);
          // this.historyList$.next([...this.historyList$.value, ...this.prepearHistory(history)]);
        }
      });
    }
  }

  scroll(): void {
    this.dispSettingsService.pageDispatchers = this.dispSettingsService.pageDispatchers + 1;
    this.dispSettingsService.loadAllDispatchers(
      [...this.dispSettingsService.prepearFilters(this.dispSettingsService.editFilterForm$.value), this.sortParam], true
    );
  }

  sorted(value): void {
    this.sortParam = {type: 'sort', value};
    this.dispSettingsService.pageDispatchers = 1;
    this.dispSettingsService.loadAllDispatchers([
      ...this.dispSettingsService.prepearFilters(this.dispSettingsService.editFilterForm$.value),
      this.sortParam
    ], true);
  }

  historyScroll(): void {
    this.historyPage = this.historyPage + 1;
    this.loadHistory();
  }

  private prepearHistory(history): any[] {
    return history
      .filter(
        rec =>
          rec.changeType === HistoryChange.OPERATOR_UPDATE_TAXI_SERVICES ||
          rec.changeType === HistoryChange.OPERATOR_CREATE
      )
      .map(record => {
        if (record.changeType === HistoryChange.OPERATOR_UPDATE_TAXI_SERVICES) {
          return this.prepearServicesHistoryItem(record);
        } else if (record.changeType === HistoryChange.OPERATOR_CREATE) {
          return record;
        }
      })
      .reverse();
  }
  private prepearServicesHistoryItem(record): any {
    return {
      ...record
    };
  }

  toggleSelectedBrigades(form: UntypedFormGroup): void {
    this.dispSettingsService.prepearFilters(form);
    if (form.value?.brigades?.length === 0) {
      this.select('');
    }
  }
}
