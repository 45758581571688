import { Component, OnInit, OnChanges, Input, OnDestroy } from '@angular/core';
import { Order } from '../../interfaces/order.interface';
import { createPoly, createMarker } from '../utils/map';
import {LocationService} from "../../shared/services/location.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {tap} from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: 'utax-map-order',
  template: ''
})
export class MapOrderComponent implements OnInit, OnChanges, OnDestroy {
  private _order: any;
  @Input() set order(value: any) {
   if (value?.id === this.orderId && !value?.cab) {
     if (this.driverMarker) {
       this.map.removeLayer(this.driverMarker);
       this.driverMarker = null;
     }
   }
   this._order = value;
   if (value && value.status === 'in_progress') {
      this.locationService.passengerLocation$.next(null);
    } else if ( value && value.id !== this.orderId) {
      this.orderId = value.id;
    }
   if (!value?.id) {
     this.locationService.passengerLocation$.next(null);
     this.locationService.driverLocation$.next(null);
     this.orderId = null;
     this.isNewOrder = true;
   }
  }
  get order(): any {
    return this._order;
  }
  @Input() mapComponent;
  map;
  polyline;
  polylines = [];
  markers = [];
  orderId: string;
  passengerMarker;
  passengerTimer;
  driverMarker;
  driverTimer;
  private isNewOrder = false;


  constructor(private locationService: LocationService) {}

  ngOnInit() {
    if (!this.isNewOrder) {
      this.getRealTimeLocation();
    }
  }

  ngOnChanges(): void {
    this.map = this.mapComponent.map;
    this.setRoute();
  }

  ngOnDestroy() {
    this.clear();
    if (this.passengerMarker) {
      this.map.removeLayer(this.passengerMarker);
      this.passengerMarker = null;
    }
    if (this.driverMarker) {
      this.map.removeLayer(this.driverMarker);
      this.driverMarker = null;
    }
  }

  setRoute() {
    this.clear();
    if (this.order && this.order.fare) {
      if (this.order.fare.intercity) {
        this.polylines = [
          ...(this.order.fare.incity || []).map(route =>
            createPoly(route.polyline, this.map)
          ),
          ...(this.order.fare.intercity || []).map(route =>
            createPoly(route.polyline, this.map, { color: 'green' })
          )
        ];
      } else {
        this.polyline = createPoly(this.order.fare.route.polyline, this.map);
        this.mapComponent.order = this.polyline;
      }
      this.order.waypoints.forEach(waypoint => {
        this.markers.push(
          createMarker(
            waypoint,
            this.map,
            this.createPointTemplate(waypoint),
            'html'
          )
        );
      });
    } else if (
      this.order &&
      this.order.waypoints &&
      this.order.waypoints.length === 1
    ) {
      this.markers.push(
        createMarker(
          this.order.waypoints[0],
          this.map,
          this.createPointTemplate(this.order.waypoints[0]),
          'html'
        )
      );
    }
  }

  createPointTemplate(waypoint) {
    return `<div class="map-marker">
    <div class="caption">${waypoint.ord + 1}</div>
    </div>`;
  }
  createPointTemplateLocation() {
    return `<div class="map-marker">
    <div class="caption">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 25" fill="none">
        <rect x="0.125" y="0.75" width="23.75" height="23.75" rx="11.875" fill="white"/>
        <circle cx="12.0058" cy="8.77928" r="3.78709" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9922 17.832C5.99118 17.5658 6.05072 17.3028 6.16632 17.063C6.52907 16.3375 7.55202 15.953 8.40085 15.7789C9.01302 15.6482 9.63365 15.561 10.2581 15.5177C11.4143 15.4161 12.5771 15.4161 13.7332 15.5177C14.3577 15.5615 14.9782 15.6487 15.5905 15.7789C16.4393 15.953 17.4623 16.3012 17.825 17.063C18.0575 17.5519 18.0575 18.1194 17.825 18.6083C17.4623 19.3701 16.4393 19.7183 15.5905 19.8852C14.9791 20.0212 14.3582 20.111 13.7332 20.1536C12.7922 20.2334 11.8468 20.2479 10.9038 20.1971C10.6862 20.1971 10.4758 20.1971 10.2581 20.1536C9.6355 20.1115 9.01702 20.0217 8.4081 19.8852C7.55202 19.7183 6.53632 19.3701 6.16632 18.6083C6.05131 18.3657 5.99183 18.1005 5.9922 17.832Z" fill="black"/>
        </svg>
</div>
    </div>`;
  }

  cabMarkerTemplate() {
    const triangleStr = `<div class="triangle-container">
      <div class="triangle" style="transform: rotate(${Math.round(
      this.order?.cab?.bearing ? this.order.cab.bearing : 0
    )}deg); border-color: transparent transparent #33C39B transparent;"></div>
    </div>`;

    return `<div class="cab-marker cab-color-black">
        ${triangleStr}
      <div class="cab-title" style="color: #083750">${this.order?.cab?.callsign} </div>
    </div>`;
  }

  clear() {
    if (this.polylines.length > 0) {
      this.polylines.forEach(poly => this.map.removeLayer(poly));
      this.polylines = [];
    }
    if (this.polyline) {
      this.map.removeLayer(this.polyline);
      this.polyline = null;
    }
    if (this.markers.length > 0) {
      this.markers.forEach(marker => this.map.removeLayer(marker));
      this.markers = [];
    }
  }

  private passengerLocationTimer() {
    if (this.passengerTimer) {
      clearTimeout(this.passengerTimer);
    }
    this.passengerTimer = setTimeout(() => {
      this.locationService.passengerLocation$.next(null);
    }, 20000);
  }

  private driverLocationTimer() {
    if (this.driverTimer) {
      clearTimeout(this.driverTimer);
    }
    this.driverTimer = setTimeout(() => {
      this.locationService.driverLocation$.next(null);
    }, 20000);
  }

  private getRealTimeLocation() {
    this.locationService.passengerLocation$
      .pipe(
        tap((location) => {
          if (location) {
            const locationArr = location.split(',');
            if (!this.passengerMarker) {
              this.passengerMarker = createMarker(
                {lat: locationArr[0], lng: locationArr[1]},
                this.map,
                this.createPointTemplateLocation(),
                'html',
                375
              );
            } else {
              this.passengerMarker.setLatLng(locationArr).update();
            }
            this.passengerLocationTimer();
          } else {
            if (this.passengerMarker) {
              this.map.removeLayer(this.passengerMarker);
              this.passengerMarker = null;
            }
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.locationService.driverLocation$
      .pipe(
        tap((location) => {
          if (location) {
            const locationArr = location.split(',');
            if (!this.driverMarker) {
              this.driverMarker = createMarker(
                {lat: locationArr[0], lng: locationArr[1]},
                this.map,
                this.cabMarkerTemplate(),
                'html'
              );
            } else {
              this.driverMarker.setLatLng(locationArr).update();
            }
            this.driverLocationTimer();
          } else {
            if (this.driverMarker) {
              this.map.removeLayer(this.driverMarker);
              this.driverMarker = null;
            }
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
