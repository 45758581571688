import {Directive, HostListener, ElementRef, Input} from '@angular/core';

@Directive({
  selector: 'input[utaxNumbersOnly]'
})
export class NumbersOnlyDirective {
  @Input() utaxNumbersOnly = false;


  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this.el.nativeElement.value;
    if (this.utaxNumbersOnly) {
      this.el.nativeElement.value = initalValue.replace(/[^0-9+]*/g, '');
    } else {
      this.el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    }
    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
