import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {HeaderToolsHandlerService} from "@global-navigation/services";
import {PassengerBlacklistService} from "../../services/passenger-blacklist.service";

@Component({
  selector: 'utax-passenger-blacklist',
  templateUrl: './passenger-blacklist.component.html',
  styleUrls: ['./passenger-blacklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PassengerBlacklistComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('controlMenu') controlMenu: TemplateRef<any>;


  constructor(
    private headerToolsHandlerService: HeaderToolsHandlerService,
    public blackListService: PassengerBlacklistService,
  ) { }

  ngOnInit(): void {
    this.getPassengerBlackList();
  }

  ngAfterViewInit() {
    this.headerToolsHandlerService.template.next({
      controlMenu: this.controlMenu,
      source: 'rebinding'
    });
  }

  private getPassengerBlackList(): void {
    this.blackListService.getClients(
      this.blackListService.params$.value,
      this.blackListService.body$.value
    );
  }

  changedPhone(phone: string): void {
    this.blackListService.body$.next({...this.blackListService.body$.value, phoneNumber: phone});
    this.blackListService.params$.next(this.blackListService.DEFAULT_PARAMS);
    this.getPassengerBlackList();
  }

  ngOnDestroy(): void {
    this.blackListService.params$.next(this.blackListService.DEFAULT_PARAMS);
    this.blackListService.body$.next({});
  }

}
