import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'utax-compensation-tab',
  templateUrl: './compensation-tab.component.html',
  styleUrls: ['./compensation-tab.component.scss']
})
export class CompensationTabComponent {
  @Input() form: UntypedFormGroup;
}
