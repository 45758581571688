import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  QueueCallType,
  QueueModel,
  QueueTableMode
} from '../../models';
import { QueueService } from '../../services';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { NgOnDestroy } from 'src/app/shared/services';
import {GlobalDataService} from '@global-services/global-data.service';

@Component({
  selector: 'utax-queue-item',
  templateUrl: './queue-item.component.html',
  styleUrls: ['./queue-item.component.scss', '../columns.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroy]
})
export class QueueItemComponent implements OnInit {
  @Input() queue: QueueModel;
  @Input() newQueue = false;

  isDisabledCheckbox = false;

  readonly queueTableMode = QueueTableMode;
  readonly queueCallType = QueueCallType;
  queueFormGroup: UntypedFormGroup;
  _queueFormGroup: UntypedFormGroup;

  constructor(
    public queueService: QueueService,
    public globalDataService: GlobalDataService,
    private formBuilder: UntypedFormBuilder,
    private ngOnDestroy$: NgOnDestroy
  ) {}

  ngOnInit(): void {
    this.queueFormGroup = this.formBuilder.group({
      productId: [this.queue?.productId ? this.queue.productId : ''],
      taxiServiceId: [this.queue?.taxiServiceId ? this.queue.taxiServiceId : this.queueService.selectedGroupUuid$.value, [Validators.required]],
      callLineId: [this.queue?.callLineId ? this.queue.callLineId : '', [Validators.required]],
      strategyId: [this.queue?.strategyId ? this.queue.strategyId : ''],
      name: [this.queue?.name ? this.queue.name : '', [Validators.required]],
      hasActiveRequest: [this.queue?.hasActiveRequest ? this.queue?.hasActiveRequest : false],
      maxWaitingTime: [this.queue?.maxWaitingTime ? this.queue.maxWaitingTime : null,
        [validateNumber, Validators.minLength(0), Validators.min(0), Validators.required]
      ],
      priority: [(this.queue && (this.queue.priority || this.queue.priority === 0)) ? this.queue.priority : null, [Validators.required]],
      transferBack: [this.queue?.transferBack ? this.queue?.transferBack : false],
      id: [this.queue?.id ? this.queue?.id : ''],
    });

    this._queueFormGroup = {...this.queueFormGroup.value};

    this.queueFormGroup.valueChanges
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(() => {
        const newClientsLine = this.globalDataService.globalCallLines$.value.find(line => line.type === QueueCallType.INCOMING_FROM_NEW_CLIENTS);
        if (
          this.queueFormGroup.value.callLineId &&
          this.queueFormGroup.value.callLineId === newClientsLine.id
        ) {
          if (!this.isDisabledCheckbox) {
            this.isDisabledCheckbox = true;
            this.queueFormGroup.patchValue({
              hasActiveRequest: false
            });
          }
        } else {
          this.isDisabledCheckbox = false;
        }
        this.save();
      });

    this.queueService.tableMode$
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(mode => {
        if (mode === QueueTableMode.VIEW) {
          this.queueFormGroup.reset(this._queueFormGroup);
        }
      });
  }

  getService(id): any {
    return this.globalDataService.getService(id);
  }

  getCallLine(callLineId): any {
    return this.globalDataService.getCallLine(callLineId);
  }

  getQueueStrategy(id): any {
    return this.globalDataService.getQueueStrategy(id);
  }

  private save(): void {
    if (this.queueFormGroup.dirty && this.queueFormGroup.valid) {
      this.newQueue ?
        this.queueService.addQueuesForPost(this.getData()) :
        this.queueService.addQueuesForPut(this.getData());
    } else {
      this.newQueue ?
        this.queueService.removeQueuesForPost(this.getData()) :
        this.queueService.removeQueuesForPut(this.getData());
    }
  }

  private getData(): any {
    return {
      ...this.queueFormGroup.value,
      transferBack: this.queueFormGroup.value.transferBack
    };
  }

}

export function validateNumber(c: UntypedFormControl) {
  const arr = (c && c.value) ? String(c.value).split('') : [];
  const res = arr.every((str) => {
    if (str !== '+' && +str) {
      return true;
    }
    return false;
  });
  // tslint:disable-next-line:no-unused-expression
  !res ? { valid: false } : null;
}
