import { Injectable } from '@angular/core';
import { pluck } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ScheduledBonuse } from '../models/bonus.model';
import { HttpClient } from '@angular/common/http';
import { HandleEventService } from 'src/app/services';

const scheduledBonusesUrl = 'operator/scheduled-bonuses';
const productListUrl = 'operator/products/list';

@Injectable({
  providedIn: 'root'
})
export class ScheduledBonusesService {
  selectedServiceId: number;
  products = [];
  bonuses$ = new Subject<ScheduledBonuse[]>();
  private scheduledBonuses: ScheduledBonuse[];

  constructor(private http: HttpClient, private handleEventService: HandleEventService) {}

  fetchBonusesByService(service): void {
    this.getAllScheduledBonecesByService(service).subscribe((bonuses: ScheduledBonuse[]) => {
      this.scheduledBonuses = bonuses;
      this.bonuses$.next([...this.scheduledBonuses]);
    });
    this.http.get(productListUrl).subscribe((res: any) => {
      this.products = res.data.filter(product => product.service_id === service);
    });
  }

  fethRmoveBonuse(id: string) {
    this.removeScheduledBonuse(id).subscribe((isRmove: boolean) => {
      this.scheduledBonuses = this.scheduledBonuses.filter(el => el.id !== id);
      this.bonuses$.next([...this.scheduledBonuses]);
    });
  }

  fetchCreateUpdateBonuice(obj: ScheduledBonuse) {
    if (obj.id) {
      this.updateScheduledBonuse(obj).subscribe((bonuse: ScheduledBonuse) => {
        this.handleEventService.openSnackBar('BONUS_SAVED');
        this.scheduledBonuses = this.scheduledBonuses.map(el => {
          if (el.id === bonuse.id) {
            return bonuse;
          } else {
            return el;
          }
        });
        this.bonuses$.next([...this.scheduledBonuses]);
      });
    } else {
      const newBonuse: ScheduledBonuse = obj;
      delete newBonuse.id;
      this.createScheduledBonuse({ ...newBonuse, service_id: this.selectedServiceId.toString() }).subscribe(
        (bonuse: ScheduledBonuse) => {
          this.handleEventService.openSnackBar('BONUS_CREATED');
          this.scheduledBonuses.push(bonuse);
          this.bonuses$.next([...this.scheduledBonuses]);
        }
      );
    }
  }

  private getAllScheduledBonecesByService(serviceId) {
    return this.http.get(scheduledBonusesUrl, { params: { service: serviceId } }).pipe(pluck('data'));
  }

  private createScheduledBonuse(body: ScheduledBonuse): Observable<any> {
    return this.http.post(scheduledBonusesUrl, body).pipe(pluck('data'));
  }

  private updateScheduledBonuse(body: ScheduledBonuse): Observable<any> {
    return this.http.put(scheduledBonusesUrl, body).pipe(pluck('data'));
  }

  private removeScheduledBonuse(bonuseId: string) {
    return this.http.delete(scheduledBonusesUrl, { params: { id: bonuseId } });
  }
}
