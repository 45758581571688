import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

const searchCabUrl = 'operator/cab/query';
const getDriversUrl =  'operator/driver';

@Injectable({
  providedIn: 'root'
})
export class CabsApiService {
  constructor(private http: HttpClient) {}

  getCabs(service, search) {
    return this.http.get(searchCabUrl, {
      params: new HttpParams({ fromObject: { service, query: search } })
    });
  }

  getDrivers(obj) { // Get
    return this.http.get(getDriversUrl, { params: new HttpParams({ fromObject: obj }) });
  }
}
