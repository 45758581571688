import { AuthService } from './../../../auth/services/auth.service';
import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import { StateService } from 'src/app/services/state.service';
import { Router } from '@angular/router';
import { SpaceForCallwayService } from 'src/app/shared/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {HeaderToolsHandlerService} from '@global-navigation/services';
import {RemoteWorkService} from '@global-cabinet/services';
import {TelephonyService} from '@global-telephony/services';
import {APP_CONFIG} from '@global-utils/injection-tokens';

const { version } = require('../../../../../package.json');


@Component({
  selector: 'utax-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  loggedIn: boolean;
  onDashboardPage: boolean;

  environment;
  versionCode: string;
  version = version;
  public source: string;

  private componentDestroyed$ = new Subject();
  constructor(
    public stateService: StateService,
    public spaceForCallwayService: SpaceForCallwayService,
    private authService: AuthService,
    private router: Router,
    public headerToolsHandlerService: HeaderToolsHandlerService,
    private remoteWorkService: RemoteWorkService,
    private telephonyService: TelephonyService,
    @Inject(APP_CONFIG) private environments: any
  ) {
    this.authService.authState.pipe(takeUntil(this.componentDestroyed$)).subscribe(state => {
      this.loggedIn = state;
    });
    this.environment = environments;
    this.versionCode = this.environment.liteVersion ? 'L' : 'F';
  }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.router.url === '/dashboard' ? (this.onDashboardPage = true) : (this.onDashboardPage = false);
    });
    this.headerToolsHandlerService.template
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((templateOfHeaderTools: any) => {
        if (templateOfHeaderTools) {
          this.source = templateOfHeaderTools.source;
        }
      });
  }

  public goToLink() {
    window.open('/#/cabinet', '_blank');
  }

  logOut() {
    const isActiveCallCenter = localStorage.getItem('callCenterActive');
    if (isActiveCallCenter || (!this.remoteWorkService.workState$.value && this.environment.liteVersion)) {
      this.authService.logout();
    } else {
      this.telephonyService.disconnect().subscribe(arg => {
        console.log('🚀 ~ disconnect ~ arg', arg);
        if (arg) {
          this.authService.logout();
        }
      });
    }
  }



  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }
}
