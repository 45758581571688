import { OrderFormService } from '../../../order-form/services';
import {AllowIn, ShortcutInput} from 'ng-keyboard-shortcuts';
import { TaxiServicesService } from '../../../services';
import { DispOrdersService } from '../../services';
import { Service } from '../../../interfaces/order.interface';
import { DispatcherFilters, ServiceFilter } from '../../models/dispatcher';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import { SpaceForCallwayService } from 'src/app/shared/services';
import { RemoteWorkService } from '../../../cabinet/services';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {GlobalDataService} from '@global-services/global-data.service';
import {APP_CONFIG} from '@global-utils/injection-tokens';

@UntilDestroy()
@Component({
  selector: 'utax-dispatcher-filters',
  templateUrl: './dispatcher-filters.component.html',
  styleUrls: ['./dispatcher-filters.component.scss']
})
export class DispatcherFiltersComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() filtersChange = new EventEmitter();
  public  isLite = true;
  @ViewChild('searchFilter') searchFilter: ElementRef;

  filters: DispatcherFilters = {
    checkBoxes: {
      type: {
        utax_front_preorder: {
          icon: 'time-back',
          status: false
        },
        current: {
          icon: 'lightning',
          status: false
        }
      },
      input: {
        telephony: { icon: 'call-end', status: false },
        app: { icon: 'smartphone', status: false }
      },
      payment_type: {
        cash: { icon: 'cash', status: false },
        utax_front_card: { icon: 'cashless', status: false },
        bonus: { icon: 'coins', status: false },
        phone: { icon: 'phone_payments', status: false }
      },
      trip_source: {
        search: { icon: 'zoom', status: false },
        utax_front_accepted: { icon: 'assigned', status: false },
        utax_front_on_address: {
          icon: 'waiting',
          status: false
        },
        utax_front_in_progress: {
          icon: 'in-progress',
          status: false
        }
      },
      self_order: {
        utax_front_my_orders: {
          icon: 'keyboard',
          status: false
        }
      }
    },
    services: [],
    additionalFilters: {
      myOrders: {
        userId: null,
        status: false
      },
      conveyor: {
        status: false
      }
    },
    search: '',
    remoted: false
  };

  shortcuts$: Observable<ShortcutInput[]>;
  @Input() remoted: boolean;

  @Input('services')
  set servicesFilters(services: Service[]) {
    if (services.length > 0 && this.filters.services.length === 0) {
      this.filters.services = services.map(
        (service: any): ServiceFilter => {
          return {
            id: service.id,
            displayName: service.city.name,
            name: service.name,
            status: true,
            cantBlockOperator: service && service?.blockedOperators >= 2,
            waitingCallsNumber: service.waitingCallsNumber
          };
        }
      );
    }

    if (services.length > 0) {
      this.filters.services = services.map(service => {
        const serv = this.filters.services.find(item => item.id === service.id);
        if (serv) {
          return {
            ...serv,
            onlineCabs: service ? service.onlineCabs : 0,
            cantBlockOperator: service && service?.blockedOperators >= 2,
            waitingCallsNumber: service.waitingCallsNumber
          };
        } else {
          return {
            id: service.id,
            displayName: service.city.name,
            name: service.name,
            status: true,
            onlineCabs: service ? service.onlineCabs : 0,
            cantBlockOperator: service && service?.blockedOperators >= 2,
            waitingCallsNumber: service.waitingCallsNumber
          };
        }
      });
    }
  }

  componentDestroyed = new Subject();

  constructor(
    public dispOrdersService: DispOrdersService,
    private taxiServicesService: TaxiServicesService,
    private remoteWorkService: RemoteWorkService,
    public orderFormService: OrderFormService,
    public spaceForCallwayService: SpaceForCallwayService,
    public globalDataService: GlobalDataService,
    @Inject(APP_CONFIG) private environment: any
  ) {
    this.isLite = this.environment.liteVersion;
  }

  ngOnInit() {
    if (this.remoted) {
      this.filters.remoted = true;
      this.filters.checkBoxes.self_order[
        Object.keys(this.filters.checkBoxes.self_order)[0]
        ].status = true;
    }
    this.filtersChange.emit({
      filters: this.filters,
      serviceFilter: false
    });

    // setting number of orders to each filter
    this.dispOrdersService.filtersOrdersLength$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((value: DispatcherFilters) => {
        for (const groupkey in value.checkBoxes) {
          if (groupkey) {
            for (const itemKey in value.checkBoxes[groupkey]) {
              if (itemKey) {
                this.filters.checkBoxes[groupkey][itemKey].ordersLength =
                  value.checkBoxes[groupkey][itemKey].ordersLength;
              }
            }
          }
        }
      });
    this.shortcutHelper();
    this.dispOrdersService.filterLiteSearchStr$
      .pipe(
        untilDestroyed(this)
      ).subscribe((value: string) => {
        this.filters.search = value;
        this.searchInput();
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.componentDestroyed.next(true);
    this.componentDestroyed.unsubscribe();
  }

  checkboxToggled(event, key) {
    this.filters.checkBoxes[key][Object.keys(event)[0]].status =
      !this.filters.checkBoxes[key][Object.keys(event)[0]].status;

    this.filtersChange.emit({
      filters: this.filters,
      serviceFilter: false
    });
  }

  lengthCompare(a, b) {
    if (a.value[Object.keys(a.value)[0]].hotkey > b.value[Object.keys(b.value)[0]].hotkey) {
      return 1;
    } else {
      return -1;
    }
  }

  clearFilters(event: boolean): void {
    Object.keys(this.filters.checkBoxes).forEach((filterGroupKey: string) => {
      if (this.remoted && filterGroupKey === 'self_order') {
        this.filters.checkBoxes[filterGroupKey][
          Object.keys(this.filters.checkBoxes.self_order)[0]
          ].status = true;
        return;
      }
      const groupKeys = Object.keys(this.filters.checkBoxes[filterGroupKey]);
      groupKeys.forEach((filterKey: string) => {
        this.filters.checkBoxes[filterGroupKey][filterKey].status = false;
      });
    });

    Object.keys(this.filters.additionalFilters).forEach((filterKey: string) => {
      this.filters.additionalFilters[filterKey].status = false;
    });

    this.filters.services = this.filters.services.map((service: ServiceFilter) => {
      return { ...service, status: true };
    });

    if (this.remoted) {
      this.filters.remoted = true;
    }

    this.filtersChange.emit({
      filters: this.filters,
      serviceFilter: false
    });

    this.filters.search = '';
  }

  searchInput() {
    if (this.filters.search.length) {
      if (this.remoted) {
        this.filters.checkBoxes.self_order[
          Object.keys(this.filters.checkBoxes.self_order)[0]
          ].status = false;
      }
    } else {
      if (this.remoted) {
        this.filters.checkBoxes.self_order[
          Object.keys(this.filters.checkBoxes.self_order)[0]
          ].status = true;
      }
    }
    this.filtersChange.emit({
      filters: this.filters,
      serviceFilter: false
    });
  }

  public shortcutHelper(): void {
    this.shortcuts$ = this.globalDataService.isNewKeyboardLayout$
      .pipe(
        switchMap(isNewKeyboardLayout => {
          if (!this.isLite) {
            if (isNewKeyboardLayout) {
              return of([{
                key: ['esc'],
                command: () => {
                  this.clearFilters(true);
                },
                preventDefault: true,
                allowIn: [AllowIn.Input, AllowIn.Select]
              },
                {
                  key: ['f8'],
                  command: () => {
                    this.searchFilter.nativeElement.focus();
                  },
                  preventDefault: true
                }
              ]);
            } else {
              return of([{
                key: ['f8'],
                command: () => {
                  this.clearFilters(true);
                },
                preventDefault: true,
                allowIn: [AllowIn.Input, AllowIn.Select]
              }]);
            }
          } else {
            return of([]);
          }
        })
      );
  }
}
