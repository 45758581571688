import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { Service } from 'src/app/interfaces/order.interface';

@Component({
  selector: 'utax-service-select',
  templateUrl: './service-select.component.html',
  styleUrls: ['./service-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceSelectComponent {
  @Input('services')
  set serviceInfo(services) {
    if (services && services.length > 0) {
      this.servicesList = services;
      this.selectedServiceId = services[0].id;
    }
  }
  @Output() serviceSelect = new EventEmitter();

  selectedServiceId: number;
  servicesList: Service[];
  constructor() {}

  selectionChange(): void {
    this.serviceSelect.emit(this.selectedServiceId);
  }
}
