import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  WithdrawalReceipt,
  WithdrawalRequest,
  WithdrawalState,
  WithdrawalTransaction
} from '../models/withdrawal.models';
import { DataModel, PaginationModel } from '../../shared/models/data.model';
import { map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WithdrawalApiService {

  constructor(private httpClient: HttpClient) {
  }

  getState(): Observable<WithdrawalState> {
    return this.httpClient.get<DataModel<WithdrawalState>>('operator/withdrawal/state')
      .pipe(pluck('data'));
  }

  getTransactions(page: number, pageSize: number): Observable<PaginationModel<WithdrawalTransaction[]>> {
    return this.httpClient.get<PaginationModel<WithdrawalTransaction[]>>('operator/withdrawal/transactions', {
      params: {
        page: page.toString(),
        per_page: pageSize.toString(),
      }
    });
  }

  requestWithdrawal(cardId: string, amount: number): Observable<WithdrawalRequest> {
    return this.httpClient.post<DataModel<WithdrawalRequest>>('operator/withdrawal/request', {
      card_id: cardId,
      amount,
    }, {
      observe: 'response',
    })
      .pipe(
        map(response => {
          const request = response.body.data;
          if (response.headers.has('X-Verification-Code')) {
            request.verification_code = response.headers.get('X-Verification-Code') || '';
          }
          return request;
        })
      );
  }

  withdraw(request: WithdrawalRequest, verificationCode: string, tin: string): Observable<WithdrawalReceipt> {
    const body = {
      request_id: request.id,
      verification_code: verificationCode,
      tin,
    };
    if (!body.tin) {
      delete body.tin;
    }
    return this.httpClient.post<DataModel<WithdrawalReceipt>>('operator/withdrawal/withdraw', body)
      .pipe(pluck('data'));
  }

  cancelRequest(request: WithdrawalRequest): Observable<void> {
    return this.httpClient.post<void>('operator/withdrawal/cancel', {
      request_id: request.id,
    });
  }
}
