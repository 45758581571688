import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'utax-commission-tab',
  templateUrl: './commission-tab.component.html',
  styleUrls: ['./commission-tab.component.scss']
})
export class CommissionTabComponent implements OnInit {
  @Input() form: UntypedFormGroup;

  constructor() {}

  controlNames = {
    commission: {
      enable: 'is_commission_enabled',
      value: 'commission_value',
      type: 'commission_type'
    },
    distribution: {
      enable: 'is_commission_offer_enabled',
      value: 'commission_offer_value',
      type: 'commission_offer_type'
    },
    intercity: {
      enable: 'is_commission_intercity_enabled',
      value: 'commission_intercity_value',
      type: 'commission_intercity_type'
    },
    commission_app_request: {
      enable: 'is_commission_app_request_enabled',
      value: 'commission_app_request_value',
      type: 'commission_app_request_type'
    },
    hiddenMode: {
      enable: 'is_commission_hidden_mode_enabled',
      value: 'commission_hidden_mode_value',
      type: 'commission_hidden_mode_type'
    },
    hiddenSendingMode: {
      enable: 'is_commission_hidden_mode_sending_enabled',
      value: 'commission_hidden_mode_sending_value',
      type: 'commission_hidden_mode_sending_type'
    },
    commissionCashlessMode: {
      enable: 'is_commission_cashless_enabled',
      value: 'commission_cashless_value',
      type: 'commission_cashless_type'
    }
  };

  ngOnInit(): void {}

  disableBonusValue(event): void {
    if (event.checked) {
      this.form.controls.bonus_value.enable();
      this.form.controls.bonus_offer_value.enable();
    } else {
      this.form.controls.bonus_value.disable();
      this.form.controls.bonus_offer_value.disable();
    }
  }

  disableFields(event, controlName): void {
    if (event.checked) {
      this.form.controls[controlName].enable();
    } else {
      this.form.controls[controlName].disable();
    }
  }
}
