import { LogistStatusesComponent } from './logist-statuses/logist-statuses.component';
import { DetailsComponent } from './details/details.component';
import { LogistComponent } from './logist/logist.component';

export const containers: any[] = [
  LogistComponent,
  DetailsComponent,
  LogistStatusesComponent
];

export * from './logist-statuses/logist-statuses.component';
export * from './logist/logist.component';
export * from './details/details.component';
