import { DispSettingsDispComponent } from './disp-settings-disp/disp-settings-disp.component';
import { DispSettingsEditComponent } from './disp-settings-edit/disp-settings-edit.component';
import { DispSettingsRemoteComponent } from './disp-settings-remote/disp-settings-remote.component';
import { DispSettingsComponent } from './disp-settings/disp-settings.component';

export const containers = [
  DispSettingsComponent,
  DispSettingsDispComponent,
  DispSettingsRemoteComponent,
  DispSettingsEditComponent
];

export * from './disp-settings-edit/disp-settings-edit.component';
export * from './disp-settings-disp/disp-settings-disp.component';
export * from './disp-settings-remote/disp-settings-remote.component';
export * from './disp-settings/disp-settings.component';
