import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RemoteWorkOperatorStatus } from '../cabinet/models';
import { RemoteWorkService } from '../cabinet/services';
import { filter, map } from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RemoteWorkGuard implements CanActivate {

  constructor(
    private router: Router,
    private remoteWorkService: RemoteWorkService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.remoteWorkService.remoteWorkState$
      .pipe(
        filter(data => Boolean(data)),
        map(data => {
          if (data?.status !== RemoteWorkOperatorStatus.ready ||
            (data?.status === RemoteWorkOperatorStatus.ready && !this.remoteWorkService.workState$.value && environment.liteVersion)) {
            this.router.navigate(['/cabinet']);
            return false;
          }
          return true;
        })
      );
  }

}
