import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortServices'
})
export class SortServicesPipe implements PipeTransform {
  transform(disps: any[]): any[] {
    return disps
      .sort((a, b) => {
        if (a.active && !b.active) {
          return -1;
        } else if (!a.active && b.active) {
          return 1;
        }

        return new Intl.Collator().compare(a.name, b.name);
      })
  }
}
