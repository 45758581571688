import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringCut'
})
export class StringCutPipe implements PipeTransform {
  transform(str: string, len: number): any {
    if (str.length > len) {
        return str.slice(0, len);
    }
    return str;
  }
}
