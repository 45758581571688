import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnInit, Output,
  ViewChild
} from '@angular/core';
import {OrderClass} from '@global-classes/order.class';
import {OperatorCommentService} from '../../../shared/services/operator-comment.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {OperatorCommentApiService} from '../../../shared/services/operator-comment-api.service';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {finalize, tap} from "rxjs/operators";
import {MatExpansionPanel} from "@angular/material/expansion";
import {OrderFormService} from "../../services";

@UntilDestroy()
@Component({
  selector: 'utax-order-operator-comment',
  templateUrl: './order-operator-comment.component.html',
  styleUrls: ['./order-operator-comment.component.scss']
})
export class OrderOperatorCommentComponent implements OnInit {
  @ViewChild('panel') panel: MatExpansionPanel;
  @Input() public expandedCommentPanel!: boolean;
  @Output() public expandedCommentPanelChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public formComment: FormGroup;
  public saveProcessing = false;

  public isNewMessage: boolean;
  public elem: any;

  constructor(
    public operatorCommentService: OperatorCommentService,
    public operatorCommentApiService: OperatorCommentApiService,
    public orderFormService: OrderFormService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.formComment = this.fb.group({
      message: [''],
      request_id: [this.orderFormService.order.id],
    });
    this.init();
  }

  public sendComment(): void {
    this.saveProcessing = true;
    this.operatorCommentApiService.addOperatorComment(this.formComment.value, this.orderFormService.order.service_id)
      .pipe(
        tap((res) => {
          this.formComment.get('message').reset('');
          this.expandedCommentPanel = true;
          this.expandedCommentPanelChange.emit(this.expandedCommentPanel);
          setTimeout(() => {
            this.open();
          }, 50);
        }),
        finalize(() => this.saveProcessing = false),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public saveComment($event: MouseEvent): void {
    $event.stopPropagation();
    if (!this.saveProcessing) {
      this.sendComment();
    }
  }

  open() {
    document
      .getElementsByClassName('expansion-panel-operator-comment')[0]
      .getElementsByClassName('mat-expansion-panel-body')[0]
      .lastElementChild
      .scrollIntoView({ behavior: 'smooth', block: 'end' });
  }

  private init() {
    // this.isInit = false;
    this.isNewMessage = false;
    this.cdr.detectChanges();
    this.operatorCommentService.currentOrderComments$.pipe(
      tap((comments) => {
        setTimeout(() => {
          if (comments.length && this.expandedCommentPanel) {
            this.elem = document
              .getElementsByClassName('expansion-panel-operator-comment')[0]
              .getElementsByClassName('mat-expansion-panel-body')[0];
            if (this.elem && this.elem.scrollTop + this.elem.offsetHeight !== this.elem.scrollHeight) {
              this.isNewMessage = true;
              this.cdr.detectChanges();
              this.scrollingContent();
            }
          }
        }, 0);
      }),
      untilDestroyed(this)
    ).subscribe();
  }


  public scrollingContent(): void {
    // setTimeout(() => {
      const onScrollToBottom =     document
        ?.getElementsByClassName('expansion-panel-operator-comment')[0]
        ?.getElementsByClassName('mat-expansion-panel-body')[0]
        ?.getElementsByClassName('message-item');
      if (!onScrollToBottom.length) { return; }

      const io = new IntersectionObserver((entries) => {
        const { isIntersecting } = entries[0];
        if (isIntersecting) {
          this.isNewMessage = false;
          this.cdr.detectChanges();
        }

      }, {threshold: 1, root: this.elem,  rootMargin: '0px 0px 0px 0px'});
      io.observe(onScrollToBottom[onScrollToBottom.length - 1]);
    // }, 0)
  }

  scrollToBottom() {
    this.elem.scrollTo({
      top: this.elem.scrollHeight,
      behavior: 'smooth',
    });
    this.isNewMessage = false;

    this.cdr.detectChanges();
  }
}
