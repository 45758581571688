import { OperatorApiService } from './operator-api.service';
import { UserApiService } from './user-api.service';
import { RemoteWorkService } from './remote-work.service';
import { RemoteWorkApiService } from './remote-work-api.service';
import { UserSecurityApiService } from './user-security-api.service';

export const services = [
  OperatorApiService,
  UserApiService,
  RemoteWorkService,
  RemoteWorkApiService,
  UserSecurityApiService,
];

export * from './operator-api.service';
export * from './user-api.service';
export * from './remote-work.service';
export * from './remote-work-api.service';
export * from './user-security-api.service';
