import { Injectable } from '@angular/core';
import { RemoteWorkApiService } from './remote-work-api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { RemoteWorkOperatorStatus, RemoteWorkRequestStatus, RemoteWorkStateModel } from '../models';
import {map, switchMap, tap} from 'rxjs/operators';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RemoteWorkService {

  public remoteWorkState$: BehaviorSubject<RemoteWorkStateModel> = new BehaviorSubject<RemoteWorkStateModel>(null);
  public workState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);


  constructor(private remoteWorkApiService: RemoteWorkApiService) {}

  init(): Observable<RemoteWorkStateModel> {
    if(!environment.liteVersion) {
      return this.remoteWorkApiService.getRemoteWorkState()
        .pipe(
          map(date => {
            if (date.last_remote_work_request) {
              date.last_remote_work_request.updated_at = date.last_remote_work_request.updated_at + 'Z';
              date.last_remote_work_request.created_at = date.last_remote_work_request.created_at + 'Z';
              if (
                date.status === RemoteWorkOperatorStatus.ready
                && (date.last_remote_work_request.status === RemoteWorkRequestStatus.revoked
                  || date.last_remote_work_request.status === RemoteWorkRequestStatus.processing)
              ) {
                date.status = RemoteWorkOperatorStatus.remote_work_request_required;
              }
            }
            return date;
          }),
          tap(data => {
            this.remoteWorkState$.next(data);
          })
        );
    } else {
      return this.remoteWorkApiService.statusWorkingShift().pipe(
        tap(status => {
          this.workState$.next(status)
        }),
        switchMap(() => this.remoteWorkApiService.getRemoteWorkState()),
        map(date => {
          if (date.last_remote_work_request) {
            date.last_remote_work_request.updated_at = date.last_remote_work_request.updated_at + 'Z';
            date.last_remote_work_request.created_at = date.last_remote_work_request.created_at + 'Z';
            if (
              date.status === RemoteWorkOperatorStatus.ready
              && (date.last_remote_work_request.status === RemoteWorkRequestStatus.revoked
                || date.last_remote_work_request.status === RemoteWorkRequestStatus.processing)
            ) {
              date.status = RemoteWorkOperatorStatus.remote_work_request_required;
            }
          }
          return date;
        }),
        tap(data => {
          this.remoteWorkState$.next(data);
        })
      );
    }
  }
}
