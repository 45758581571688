import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectorRef, OnChanges } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getProgressWidth } from '../../utils/status-color';
import { ChangeDetectionStrategy } from '@angular/core';
import { CabTarget } from 'src/app/interfaces/target.interface';

@Component({
  selector: 'utax-cab',
  templateUrl: './cab.component.html',
  styleUrls: ['./cab.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CabComponent implements OnInit, OnChanges, OnDestroy {
  @Input() cab;
  @Input() isSelected = false;
  @Input() ordersNumber;
  @Input() isAllSelected: boolean;

  @Output() select = new EventEmitter();
  status = '';

  showReqIcon = true;

  componentDestroyed$ = new Subject();

  targets: CabTarget[] = [];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.cab.progressWidth = 0;

    if (this.cab.trip) {
      this.cab.progressWidth = getProgressWidth(this.cab.color, this.cab, this.cab.order);

      interval(1000)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(() => {
          this.cab.progressWidth = getProgressWidth(this.cab.color, this.cab, this.cab.order);
          this.cd.detectChanges();
        });
    } else if (this.status === 'rest' || this.status === 'washing') {
      // @todo count progress width for rest or washing
    }
  }

  ngOnChanges() {
    if (this.cab && this.cab.targets) {
      this.targets = [...this.cab.targets as Array<any>];
    } else {
      this.targets = null;
    }
    if (this.cab.trip) {
      this.cab.progressWidth = getProgressWidth(this.cab.color, this.cab, this.cab.order);
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }
}
