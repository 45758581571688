import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import * as fromContainers from './containers';
import * as fromComponents from './components';
import * as fromServices from './services';
import * as fromPipes from './pipes';

import { MaterialModule } from '../utils/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { Routes, RouterModule } from '@angular/router';
import { DriverTariffsComponent } from './containers';
import { NgxMaskModule } from 'ngx-mask';
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {MtxPopoverModule} from "@ng-matero/extensions/popover";
import {MtxCheckboxGroupModule} from "@ng-matero/extensions/checkbox-group";

const ROUTES: Routes = [
  {
    path: '',
    component: DriverTariffsComponent
  }
];

@NgModule({
  declarations: [...fromContainers.containers, ...fromComponents.components, ...fromPipes.pipes],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    SharedModule,
    ReactiveFormsModule,
    KeyboardShortcutsModule,
    FormsModule,
    RouterModule.forChild(ROUTES),
    NgxMaskModule.forChild(),
    InfiniteScrollModule,
    MtxPopoverModule,
    MtxCheckboxGroupModule
  ],
  providers: [...fromServices.services, DatePipe]
})
export class DriverTariffsModule {}
