import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from "rxjs";
import {
  OperatorCommentResponseInterface,
  OperatorCommentsResponseInterface,
  OperatorNewCommentInterface
} from "../interfaces/operator-comment.interface";

@Injectable({
  providedIn: 'root'
})
export class OperatorCommentApiService {

  constructor(private http: HttpClient) { }

  public getOperatorComments(id: string, service_id: number): Observable<OperatorCommentsResponseInterface> {
    const params = new HttpParams({
      fromObject: {
        id,
        service_id: service_id.toString()
      }
    });
    return this.http.get<OperatorCommentsResponseInterface>('operator/requests/discussion', {params});
  }

  public addOperatorComment(body: OperatorNewCommentInterface, service_id: number): Observable<OperatorCommentResponseInterface> {
    const params = new HttpParams({
      fromObject: {
        service_id: service_id.toString()
      }
    });
    return this.http.post<OperatorCommentResponseInterface>('operator/requests/discussion', body, {params});
  }


}
