import { Component, OnInit } from '@angular/core';
import {map, tap} from 'rxjs/operators';
import {StateService} from '@global-services/state.service';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'utax-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss']
})
export class UsersPageComponent implements OnInit {
  private allowedPermissionsRole = ['operator_roles_view_any', 'operator_roles_update'];
  private allowedPermissionsUser = ['operator_users_view_any', 'operator_users_update'];
  public isPermission = false;
  public isUsersAllow = false;
  public isRolesAllow = false;


  constructor(private stateService: StateService) {}

  ngOnInit(): void {
    this.stateService.getStoreParamSub('permissions').pipe(
      tap(permissions => this.isPermission = !!permissions.length),
      tap(permissions => {
        this.isUsersAllow = permissions.some(permission => this.allowedPermissionsUser.includes(permission.name));
        this.isRolesAllow = permissions.some(permission => this.allowedPermissionsRole.includes(permission.name));
      }),
      untilDestroyed(this)
    ).subscribe();
  }
}
