import { OrderFormMapInfoPanelComponent } from './order-form-map-info-panel/order-form-map-info-panel.component';
import { SaveOrderConfirmModalComponent } from './save-order-confirm-modal/save-order-confirm-modal.component';
import { OrderFormEstimateComponent } from './order-form-estimate/order-form-estimate.component';
import { OrderFromAddressesComponent } from './order-from-addresses/order-from-addresses.component';
import { OrderTypeFormComponent } from './order-type-form/order-type-form.component';
import { OrderFormMainButtonsComponent } from './order-form-main-buttons/order-form-main-buttons.component';
import { BasicOrderInfoFormComponent } from './basic-order-info-form/basic-order-info-form.component';
import { OrderFormMapComponent } from './order-form-map/order-form-map.component';
import { CardPaymentModalComponent } from './card-payment-modal/card-payment-modal.component';
import {OrderDebtInfoComponent} from './order-debt-info/order-debt-info.component';
import {OrderOperatorCommentComponent} from './order-operator-comment/order-operator-comment.component';

export const components: any[] = [
  OrderFormMapComponent,
  BasicOrderInfoFormComponent,
  OrderFormMainButtonsComponent,
  OrderTypeFormComponent,
  OrderFromAddressesComponent,
  OrderFormEstimateComponent,
  SaveOrderConfirmModalComponent,
  OrderFormMapInfoPanelComponent,
  CardPaymentModalComponent,
  OrderDebtInfoComponent,
  OrderOperatorCommentComponent
];

export * from './order-form-map-info-panel/order-form-map-info-panel.component';
export * from './order-form-estimate/order-form-estimate.component';
export * from './order-from-addresses/order-from-addresses.component';
export * from './order-type-form/order-type-form.component';
export * from './order-form-main-buttons/order-form-main-buttons.component';
export * from './basic-order-info-form/basic-order-info-form.component';
export * from './order-form-map/order-form-map.component';
export * from './card-payment-modal/card-payment-modal.component';
export * from './order-debt-info/order-debt-info.component';
export * from './order-operator-comment/order-operator-comment.component';
