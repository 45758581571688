import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class UserSecurityApiService {

  constructor(private httpClient: HttpClient) {
  }

  requestPhoneNumberVerification(): Observable<HttpResponse<void>> {
    return this.httpClient.post<void>('operator/security/phone-number-verification/request', null, {
      observe: 'response',
    });
  }

  passPhoneNumberVerification(verificationCode: string): Observable<void> {
    return this.httpClient.post<void>('operator/security/phone-number-verification/pass', {
      verification_code: verificationCode,
    });
  }
}
