import { UntypedFormGroup } from '@angular/forms';

export class ReactiveFormUtil {
  public static getDirtyValues(form: UntypedFormGroup): any {
    return Object.keys(form.controls)
      .reduce((acc, key) => {
        const control = form.controls[key];
        if (control.dirty) {
          if (control.hasOwnProperty('controls')) {
            acc[key] = this.getDirtyValues(control as UntypedFormGroup);
          } else {
            acc[key] = control.value;
          }
        }
        return acc;
      }, {});
  }
}
