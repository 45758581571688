import {Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef} from '@angular/core';
import {UserHistoryInterface} from "../../../models/user.interface";
import {Role} from "../../../models/role.interface";
import { Service } from 'src/app/interfaces/order.interface';
import {Subject} from "rxjs";
import {UsersService} from "../../services";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'utax-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  @Output() editUserClick: EventEmitter<any> = new EventEmitter();
  @Output() deleteUserClick: EventEmitter<any> = new EventEmitter();
  @Output() getNextPage: EventEmitter<number> = new EventEmitter<number>();
  @Input() usersHistory: UserHistoryInterface[];
  @Input() public isUsersAllowEdit: boolean;
  @Input() rolesInp: Role[];
  @Input() servicesInp: Service[];
  @Input() scrollToTop: Subject<null>;
  @ViewChild('historyBlock') historyBlock: ElementRef;
  constructor(public userService: UsersService) {}

  ngOnInit(): void {
    this.userService.scrollToTop$
      .pipe(
      untilDestroyed(this)).subscribe(() => {
      setTimeout(() => {
        this.historyBlock.nativeElement.scrollTop = 0;
      }, 0);
    });
  }

  editUser(): void {
    this.editUserClick.emit();
  }

  deleteUser(): void {
    this.deleteUserClick.emit();
  }

  public scrollPage() {
    this.getNextPage.emit(1);
  }
}
