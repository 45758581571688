import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GetBlockReasonService {
  constructor(private http: HttpClient) {}

  getRemoveDriverReason() {
    return this.http.get('operator/blocking/reasons?type=driver_block');
  }

  public getPassengersBlockReasons() {
    return this.http.get('operator/blocking/reasons?type=passenger_block');
  }

  getDriverBlockUrl() {
    return 'operator/driver/block';
  }

  driverBlock(obj) {
    return this.http.post('operator/driver/block', obj);
  }
}
