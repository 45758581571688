import { Injectable } from '@angular/core';
import {ImageInterface, VehicleImageInterface, VehicleOneImageInterface} from '../interfaces/image.interface';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageHubService {

  public driverImagesHub: BehaviorSubject<ImageInterface[]> = new BehaviorSubject<ImageInterface[]>([]);
  public vehiclesImagesHub: BehaviorSubject<VehicleImageInterface[]> = new BehaviorSubject<VehicleImageInterface[]>([]);
  public driverImageHub: BehaviorSubject<ImageInterface> = new BehaviorSubject<ImageInterface>(null);
  public vehiclesImageHub: BehaviorSubject<VehicleOneImageInterface> = new BehaviorSubject<VehicleOneImageInterface>(null);
  public renewPhoto$ = new Subject();
  public imageVehicleGallery$: BehaviorSubject<ImageInterface[]> = new BehaviorSubject([]);
  public imageDriverGallery$: BehaviorSubject<ImageInterface[]> = new BehaviorSubject([]);



  constructor() { }
  public clear(): void {
    this.driverImagesHub.next([]);
    this.vehiclesImagesHub.next([]);
    this.imageDriverGallery$.next([]);
    this.imageVehicleGallery$.next([]);
    this.driverImageHub.next(null);
    this.vehiclesImageHub.next(null);
  }
}
