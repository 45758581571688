import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'isStyleCheckbox'
})
export class IsStyleCheckboxPipe implements PipeTransform {
  transform(
    dispatchersFormArray: UntypedFormArray,
    index: number,
    checkboxName: string,
    initialDispatchers: any[],
    additionalValue
  ): boolean {
    // checkbox toused and doesnt equal old value
    return (
      (dispatchersFormArray.controls[index] as UntypedFormGroup).controls[checkboxName].touched &&
      initialDispatchers[index][checkboxName] !==
        (dispatchersFormArray.controls[index] as UntypedFormGroup).controls[checkboxName].value
    );
  }
}
