export const FIRST_CONTAC_UUID = 'b834a88a-45d9-45e6-8ecc-f6d13d9f04f3';

export const daysOfWeekModifier = [
  {
    value: 'MONDAY',
    shortValue: 'MON',
    id: 1
  },
  {
    value: 'TUESDAY',
    shortValue: 'TU',
    id: 2
  },
  {
    value: 'WEDNESDAY',
    shortValue: 'WED',
    id: 3
  },
  {
    value: 'THURSDAY',
    shortValue: 'TH',
    id: 4
  },
  {
    value: 'FRIDAY',
    shortValue: 'FRI',
    id: 5
  },
  {
    value: 'SATURDAY',
    shortValue: 'SAT',
    id: 6
  },
  {
    value: 'SUNDAY',
    shortValue: 'SUN',
    id: 0
  },
];

export const periodTypeModifier = [
  {
    viewValue: 'BY_DAYS_MD',
    value: 'day'
  },
  {
    viewValue: 'SOLID_PERIOD_MD',
    value: 'solid'
  },
  {
    viewValue: 'BY_DATE_MD',
    value: 'date'
  }
];
