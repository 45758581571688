import { BrowserModule } from '@angular/platform-browser';
import {NgModule, ErrorHandler, APP_INITIALIZER, InjectionToken} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { NgxSpinnerModule } from 'ngx-spinner';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

import { AppComponent } from './app.component';
import { AuthGuard } from './utils/auth.guard';
import { RemoteWorkGuard } from './utils/remote-work.guard';
import { RequestBuildInterceptor } from './utils/request-build.interceptor';
import { ErrorInterceptor } from './utils/error.interceptor';

import { CookieService } from 'ngx-cookie-service';
import { NgxMaskModule } from 'ngx-mask';
import {AppRoutingModule, NavigationModule} from './index';
import {environment} from '../environments/environment';


// services
import * as fromServices from './services';

import * as fromModules from './index';
import { OrderModalService } from './order-form/services';
import {Router, RouterModule} from '@angular/router';
import * as Sentry from '@sentry/angular';
import { IsLoginGuard } from './utils/is-login.guard';
import { GlobalErrorHandler } from './utils/global-error-handler';
import {APP_CONFIG} from "./utils/injection-tokens";
import {DateAdapter, MAT_DATE_LOCALE} from "@angular/material/core";
import {CustomDateAdapter} from "./shared/utils/custom-date-adapter";
import {NgxWebstorageModule} from "ngx-webstorage";
import player from 'lottie-web';
import {LottieCacheModule, LottieModule} from "ngx-lottie";


export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    // NoopAnimationsModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ScrollingModule,
    NgxSpinnerModule,
    AppRoutingModule,
    NavigationModule,
    ...fromModules.modules,
    KeyboardShortcutsModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxWebstorageModule.forRoot({ prefix: 'custom', separator: '.', caseSensitive: false }),
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
    NgxSpinnerModule,
  ],
  providers: [
    ...fromServices.services,
    OrderModalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestBuildInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    /*{provide: PLATFORM_PIPES, useValue: TranslatePipe, multi: true},*/
    AuthGuard,
    RemoteWorkGuard,
    IsLoginGuard,
    CookieService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
    provide: Sentry.TraceService,
    deps: [Router],
    useValue: undefined
},
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: APP_CONFIG,
      useValue: environment
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'uk-UA'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
