import { Injectable } from '@angular/core';
import { Role, Permission } from '../../models/user.interface';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { pluck } from 'rxjs/operators';
import {DefaultDataHistoryInterface, DefaultHistoryInterface} from "../../../shared/interfaces/history.interface";

const rolesUrl = 'operator/roles';
const permissionsUrl = 'operator/roles/permissions';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  selectedRole: Role;
  selectedRole$: Subject<Role> = new Subject<Role>();
  listOfPermissions: Permission[];
  public loadingHistoryRoles$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  getListOfRoles(): Observable<any> {
    return this.http.get(rolesUrl).pipe(pluck('data'));
  }

  postRole(displayName: string, description: string): Observable<any> {
    return this.http.post(rolesUrl, { display_name: displayName, description }).pipe(pluck('data'));
  }

  putRole(id: string, displayName: string, description: string): Observable<any> {
    return this.http.put(rolesUrl, { display_name: displayName, id, description }).pipe(pluck('data'));
  }

  deleteRole(): Observable<any> {
    return this.http.delete(rolesUrl, { params: { id: this.selectedRole.id } });
  }

  getListOfPermissions(): void {
    this.http
      .get(permissionsUrl)
      .pipe(pluck('data'))
      .subscribe((perms: Permission[]) => {
        this.listOfPermissions = perms;
      });
  }
  getHistoryRoles(id: string, page = 1, per_page = 30): Observable<DefaultDataHistoryInterface> {
    return this.http.get<DefaultDataHistoryInterface>(`operator/history/role`, {params: {id, page: page.toString(), per_page}});
  }

  addPermitionsToRole(roleId: string, permissionsIds: string[]): Observable<any> {
    return this.http.post(`${permissionsUrl}/update`, { id: roleId, permissions: permissionsIds }).pipe(pluck('data'));
  }

  removePermitionsFromRole(roleId: string, permissionsIds: string[]): Observable<any> {
    return this.http.post(`${permissionsUrl}/detach`, { id: roleId, permissions: permissionsIds });
  }
}
