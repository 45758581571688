import { OrdersService } from 'src/app/services';
import { HistoryService } from './../../../services/history.service';
import { Subject } from 'rxjs';
import { LogistCabsService } from './../../services/logist-cabs.service';
import { DomSanitizer } from '@angular/platform-browser';
import { OrderApiService } from './../../services/order-api.service';
import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  HostListener,
  ViewRef
} from '@angular/core';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { SortOption } from '../../../utils/cluster-sort';
import { OrderFormService } from 'src/app/order-form/services';
import { DispOrdersService } from 'src/app/dispatcher/services/disp-orders.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'utax-logist-order',
  templateUrl: './logist-order.component.html',
  styleUrls: ['./logist-order.component.scss', '../columns.scss']
})
export class LogistOrderComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('logistOrder')
  private logistOrder: ElementRef<any>;
  @Output() itemHeight = new EventEmitter();

  @Input() order;
  @Input() showCabs;

  shortcuts: ShortcutInput[] = [];
  hideOldOrdersInAllTab = false;
  componentDestroyed = new Subject();
  options: SortOption<any>[] = [
    {
      order: 1,
      priority: 1,
      comparator: (a, b) => +a.feed - b.feed,
      chracteristic: a => true
    }
  ];

  private canHold = true;

  constructor(
    public orderApiService: OrderApiService,
    public sanitizer: DomSanitizer,
    public logistCabsService: LogistCabsService,
    private cd: ChangeDetectorRef,
    private historyService: HistoryService,
    private dispOrdersService: DispOrdersService,
    public ordersService: OrdersService,
    public orderFormService: OrderFormService
  ) {}

  ngOnInit() {
    this.orderApiService.selectedOrder$.pipe(takeUntil(this.componentDestroyed)).subscribe((selectedOrder: any) => {
      if (selectedOrder && this.order && selectedOrder.id === this.order.id) {
        // TODO use other service for selecting
        this.dispOrdersService.selectedOrder = this.order;
        this.itemHeight.emit(this.logistOrder.nativeElement.clientHeight);
      }
      if (!(this.cd as ViewRef).destroyed) {
        this.cd.detectChanges();
      }
    });
  }

  ngOnChanges() {}

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: ['space'],
        command: e => this.holdUnholdOrder(),
        preventDefault: true
      },
      {
        key: ['del'],
        command: e => this.removeOrder(),
        preventDefault: true
      },
      {
        key: ['end'],
        command: e => this.editOrder(this.orderApiService.selectedOrder),
        preventDefault: true
      }
    );
  }

  ngOnDestroy() {
    this.componentDestroyed.next(true);
    this.componentDestroyed.unsubscribe();
  }

  assignCab(cab?): void {
    const cabToAssign = cab ? cab : this.logistCabsService.selectedCab;
    this.orderApiService.assignOrderToCab(this.dispOrdersService.selectedOrder, cabToAssign);
  }

  unassignCab(): void {
    this.orderApiService.unassignCab();
  }

  holdUnholdOrder(): void {
    if (this.canHold) {
      this.canHold = false;
      this.orderApiService.holdUnholdOrder().subscribe({
        next: res => {
          this.order = {
            ...this.order,
            is_holding: !this.orderApiService.selectedOrder.is_holding
          };
          this.ordersService.updateOrders(
            [
              {
                ...this.orderApiService.selectedOrder,
                is_holding: !this.orderApiService.selectedOrder.is_holding
              }
            ],
            true
          );
          this.orderApiService.selectOrder({
            ...this.orderApiService.selectedOrder,
            is_holding: !this.orderApiService.selectedOrder.is_holding
          });
          this.cd.detectChanges();
          this.canHold = true;
        },
        error: err => {
          console.log('holdUnholdOrder logist error', err);
          this.canHold = true;
        }
      });
    }
  }

  removeOrder(): void {
    this.orderApiService.removeOrder();
  }

  editOrder(order): void {
    this.orderApiService.editOrder(order);
  }

  timeEnd(event): void {
    if (event && this.logistCabsService.selectedCabId === 'all') {
      this.hideOldOrdersInAllTab = true;
    }
  }

  selectOrder(): void {
    this.orderApiService.selectOrder(this.order);
    this.historyService.getOrderHistory(this.order.id, this.order.trip_id);
  }

  private assignUnassignCab(): void {
    if (this.order.cab === null && !this.order.is_reserved) {
      this.assignCab();
    } else {
      this.unassignCab();
    }
  }

  @HostListener('window:keydown', ['$event'])
  private hotkeyDown($event: KeyboardEvent): void {
    if (
      $event.key === 'Enter' &&
      this.orderApiService.selectedOrder &&
      this.orderApiService.selectedOrder.id === this.order.id &&
      !this.ordersService.modalOpened
    ) {
      $event.preventDefault();
      this.orderApiService.selectOrder(this.order);
      this.assignUnassignCab();
    }
  }
}
