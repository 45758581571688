import { Injectable, isDevMode } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Client {

  getUploadImgUrl() {
    return 'upload';
  }

  getImagesUrl(id) {
    return `images/id/${id}`;
  }

  getCabsQuery(obj) {
    return 'operator/cab/query' + this.transformObjToGetParameter(obj);
  }

  getTariffPlanListUrl(obj) {
    return 'operator/tariff-plans' + this.transformObjToGetParameter(obj);
  }

  getColorUrl() {
    return 'operator/dictionary/colors';
  }

  getCountryUrl() {
    return 'operator/dictionary/countries';
  }

  getVehicleBodyTypesUrl() {
    return 'operator/dictionary/vehicle-body-types';
  }

  getVehicleBrandsUrl() {
    return 'operator/dictionary/vehicle-brands';
  }

  getVehicleModelUrl(obj) {
    return 'operator/dictionary/vehicle-models' + this.transformObjToGetParameter(obj);
  }

  getVehicleBrandUrl() {
    return 'operator/dictionary/vehicle-model';
  }

  getVehicleCategoriesUrl() {
    return 'operator/dictionary/vehicle-categories';
  }

  getDriverFiredReasonsUrl(obj) {
    return 'operator/dictionary/driver-fired-reasons' + this.transformObjToGetParameter(obj);
  }

  getDriverStatusesUrl(obj) {
    return 'operator/dictionary/driver-statuses' + this.transformObjToGetParameter(obj);
  }

  //  driver service

  getDriverReplenishUrl() {
    return 'operator/accounting/add';
  }

  getDriverMinusUrl() {
    return 'operator/accounting/sub';
  }

  getDriverBlockUrl() {
    return 'operator/driver/block';
  }

  getDriverUnblockUrl() {
    return 'operator/driver/unblock';
  }

  getDriverSentCodeUrl(obj) {
    return 'operator/drivers/account/send' + this.transformObjToGetParameter(obj);
  }

  getDriverPinUrl() {
    return 'operator/drivers/account/pin';
  }

  getDriversUrl() {
    return 'operator/drivers';
  }

  getDriverUrl(obj) {
    return 'operator/driver/show' + this.transformObjToGetParameter(obj);
  }

  getDriverNewUrl(obj) {
    return 'operator/drivers/show' + this.transformObjToGetParameter(obj);
  }

  getDriverCarsUrl(obj) {
    return 'operator/drivers/vehicles' + this.transformObjToGetParameter(obj);
  }

  getDriverCarHistoryUrl() {
    return 'operator/history/vehicle';
  }

  // getDriverBlockReasonUrl(obj) { return 'operator/driver/blocking/reason' + this.transformObjToGetParameter(obj); }

  getDriverCreateUrl() {
    return 'operator/driver/create';
  }

  getDriverUpdateUrl() {
    return 'operator/driver/update';
  }

  getDriverFireUrl() {
    return 'operator/driver/fire';
  }

  //  vehicle service

  getVehicleDeleteUrl(obj) {
    return 'operator/vehicle' + this.transformObjToGetParameter(obj);
  } // service,
  // id

  getVehicleDriversUrl(obj) {
    return 'operator/vehicle/drivers' + this.transformObjToGetParameter(obj);
  }

  getVehicleGroupsUrl(obj) {
    return 'operator/vehicle/groups' + this.transformObjToGetParameter(obj);
  }

  getVehicleGroupCountUrl(obj) {
    return 'operator/vehicle/groups/count' + this.transformObjToGetParameter(obj);
  }

  moveVehicleGroupUrl(service_id) {
    return 'operator/vehicle/groups/move?service_id=' + service_id;
  }

  updateVehicleGroupUrl() {
    return 'operator/vehicle/groups';
  }

  createVehicleGroupUrl(service_id) {
    return 'operator/vehicle/groups?service_id=' + service_id;
  }

  getVehicleClassesUrl() {
    return 'operator/vehicle/classes';
  }

  getVehiclesUrl() {
    return 'operator/vehicles';
  }

  getVehicleUrl(obj) {
    return 'operator/vehicle/show' + this.transformObjToGetParameter(obj);
  }

  getVehicleCreateUrl() {
    return 'operator/vehicle/create';
  }

  VehicleUpdateUrl() {
    return 'operator/vehicles';
  }

  getBlockReasonsUrl = obj => {
    return 'operator/blocking/reasons' + this.transformObjToGetParameter(obj);
  };

  getDriverStatusUrl(obj) {
    return 'operator/driver/status' + this.transformObjToGetParameter(obj);
  }

  getBlockHistoryUrl = obj => {
    return 'operator/blocking/history/driver' + this.transformObjToGetParameter(obj);
  };

  getHistoryOrderUrl(obj) {
    return 'operator/history/request' + this.transformObjToGetParameter(obj);
  } // id
  getHistoryDriverUrl(obj) {
    return 'operator/history/driver' + this.transformObjToGetParameter(obj);
  } // id
  getHistoryCauserUrl(obj) {
    return 'operator/history/causer' + this.transformObjToGetParameter(obj);
  } // id = log_id

  getFeaturesUrl(obj) {
    return 'operator/features' + this.transformObjToGetParameter(obj);
  } // ?service=838


  private transformObjToGetParameter(obj) {
    if (!obj) {
      return '';
    }
    let keys = Object.keys(obj);
    let resp = '';
    if (keys.length > 0) {
      for (let i = 0; i < keys.length; i++) {
        if (!i) {
          resp += '?' + keys[i] + '=' + obj[keys[i]];
        } else {
          resp += '&' + keys[i] + '=' + obj[keys[i]];
        }
      }
    }
    return resp;
  }
}

