import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {PassengerBlacklistApiService} from './passenger-blacklist-api.service';
import {
  PassengerBlacklistDataBodyInterface,
  PassengerBlacklistDataQueryInterface,
  PassengerBlacklistFullInterface,
  PassengerBlacklistInterface, PassengerBlacklistSortDataContentInterface
} from '../models/passenger-blacklist.interface';
import {finalize} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PassengerBlacklistService {
  clients$: BehaviorSubject<PassengerBlacklistSortDataContentInterface[]> = new BehaviorSubject<PassengerBlacklistSortDataContentInterface[]>([]);
  preloader$ = new Subject<boolean>();
  showSearchPreloader = false;
  public DEFAULT_PARAMS: PassengerBlacklistDataQueryInterface = {
    page: 1,
    size: 30,
  };
  public params$: BehaviorSubject<PassengerBlacklistDataQueryInterface> = new BehaviorSubject<PassengerBlacklistDataQueryInterface>(this.DEFAULT_PARAMS);
  public body$: BehaviorSubject<PassengerBlacklistDataBodyInterface> = new BehaviorSubject<PassengerBlacklistDataBodyInterface>({});
  public totalPage$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private passengerBlacklistApiService: PassengerBlacklistApiService,
  ) { }

  getClients(paramsObj: PassengerBlacklistDataQueryInterface, body: PassengerBlacklistDataBodyInterface, scrollDown?: boolean): void {
    this.preloader$.next(true);
    this.showSearchPreloader = true;
    this.passengerBlacklistApiService.getPassengerInBlackList(paramsObj, body)
        .pipe(
          finalize(() => {
            if (!scrollDown) {
              document.getElementById('table').scrollTop = 0;
            }
            setTimeout(() => {
              this.showSearchPreloader = false;
              this.preloader$.next(false);
            }, 200);
          })
        )
        .subscribe(clients => {
          this.totalPage$.next(clients.totalPages);
          if (scrollDown) {
            this.clients$.next(this.clients$.value.concat(clients.content));
          } else {
            this.clients$.next(clients.content);
          }
        });
  }
}
