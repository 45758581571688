import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'utax-telephony-pause-button',
  templateUrl: './telephony-pause-button.component.html',
  styleUrls: ['./telephony-pause-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TelephonyPauseButtonComponent {
  @Input() userConnected: boolean;
  @Input('paused')
  set isPause(paused) {
    this.paused = paused;
  }
  @Output() changeOperatorStatus = new EventEmitter();
  paused;

  constructor() {}

  changeStatus(status): void {
    this.changeOperatorStatus.emit(status);
  }
}
