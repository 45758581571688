import { DispOrdersService } from '../../services';
import { OrdersService } from '../../../services';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShortcutInput } from 'ng-keyboard-shortcuts';

@Component({
  selector: 'utax-dispatcher-orders-list',
  templateUrl: './dispatcher-orders-list.component.html',
  styleUrls: ['./dispatcher-orders-list.component.scss', '../../columns.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispatcherOrdersListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isFormOpened: boolean;
  private componentDestroyed$ = new Subject();
  shortcuts: ShortcutInput[] = [];

  constructor(
    public ordersService: OrdersService,
    public dispOrdersService: DispOrdersService,
    private cd: ChangeDetectorRef
  ) {
  }

  @ViewChild('viewPort', { read: CdkVirtualScrollViewport, static: false }) viewPort: CdkVirtualScrollViewport;

  ngOnInit() {
    this.dispOrdersService.filteredOrders$.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.cd.markForCheck();
    });
    this.dispOrdersService.selectedOrder$.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.cd.markForCheck();
    });
  }

  ngAfterViewInit() {
    this.dispOrdersService.scrollIndexObservable$.pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      if (res > -1) {
        this.viewPort?.scrollToIndex(res);
      }
    });
    this.shortcuts.push(
      {
        key: ['up'],
        command: () => {
          this.dispOrdersService.selectCallWayOrd('up');
        },
        preventDefault: true
      },
      {
        key: ['down'],
        command: () => {
          this.dispOrdersService.selectCallWayOrd('down');
        },
        preventDefault: true
      }
    );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  trackById(i, order) {
    return order.id;
  }

  getNumberOfSelectedCallWay(): number {
    const selectedIndex = this.dispOrdersService.callWayOrderIds.indexOf(this.dispOrdersService.selectedOrder?.id);

    return selectedIndex !== -1 ? selectedIndex + 1 : 0;
  }
}
