import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// containers
import * as fromContainers from './containers';

// components
import * as fromComponents from './components';

// services
import * as fromServices from './services';

import { MaterialModule } from '../utils/material.module';
import { SharedModule } from '../shared/shared.module';
import { MapModule } from '../map/map.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [...fromContainers.containers, ...fromComponents.components],
  imports: [
    CommonModule,
    MapModule,
    MaterialModule,
    TranslateModule,
    ReactiveFormsModule,
    SharedModule,
    ScrollingModule,
    FormsModule,
    NgxSpinnerModule
  ],
  providers: [...fromServices.services]
})
export class ArchiveModule {}
