import { Component, ChangeDetectionStrategy, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'utax-checkbox-value',
  template: `
    <mat-checkbox [disabled]="disabled"
                  [(ngModel)]="simplyValue">
    </mat-checkbox>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxValueComponent),
      multi: true
    }
  ]
})
export class CheckboxValueComponent<T> implements ControlValueAccessor {

  @Input() on: T;
  @Input() off: T;
  @Input() disabled = false;

  @Input() set value(value: T) {
    this.booleanValue = this.transformValueToBoolean(value);
  }

  set simplyValue(value: boolean) {
    const difficultValue = this.transformBooleanToValue(value);
    this.booleanValue = value;
    this.onChange(difficultValue);
    this.onTouch(difficultValue);
  }

  get simplyValue(): boolean {
    return this.booleanValue;
  }

  booleanValue: boolean;
  onChange: any = () => {};
  onTouch: any = () => {};

  constructor() { }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  transformValueToBoolean(value: T): boolean {
    if (value) {
      if (this.on) {
        return value === this.on;
      }
      if (this.off) {
        return value === this.off;
      }
    } else {
      return false;
    }
  }

  transformBooleanToValue(value: boolean): T | boolean {
    if (value) {
      return this.on ? this.on : true;
    } else {
      return this.off ? this.off : false;
    }
  }

}
