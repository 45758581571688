import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AnimationOptions} from "ngx-lottie";
import {ChristmasService} from "@global-services/christmas.service";

@Component({
  selector: 'utax-lottie-custom',
  templateUrl: './lottie-custom.component.html',
  styleUrls: ['./lottie-custom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LottieCustomComponent implements OnInit {

  options: AnimationOptions;




  constructor(private christmasService: ChristmasService ) { }

  ngOnInit(): void {
    this.initSnow();
  }


  private initSnow() {
    if(this.christmasService.isEaster) {
      this.options = {
        path: '/assets/lottie/egg.json'
      };
    }
  }

}
