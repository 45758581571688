import { ArchiveComponent } from './archive/archive.component';
import { ArchiveDetailsComponent } from './archive-details/archive-details.component';
import { ArchiveTableComponent } from './archive-table/archive-table.component';
import { ArchiveToolsComponent } from './archive-tools/archive-tools.component';
import { ArchiveSearchComponent } from './archive-search/archive-search.component';

export const containers = [
    ArchiveComponent,
    ArchiveDetailsComponent,
    ArchiveTableComponent,
    ArchiveToolsComponent,
    ArchiveSearchComponent
];

export * from './archive/archive.component';
export * from './archive-details/archive-details.component';
export * from './archive-table/archive-table.component';
export * from './archive-tools/archive-tools.component';
export * from './archive-search/archive-search.component';
