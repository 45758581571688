import {Injectable, Injector} from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, of } from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import { TelephonyApiService } from './telephony-api.service';
import { AuthService } from 'src/app/auth/services';
import {AsteriskServerInterface} from '../models/asteriskServer.interface';
import {HandleEventService, WebsocketService} from '../../services';
import {TelephonyIncomingService} from './telephony-incoming.service';

@Injectable({
  providedIn: 'root'
})
export class TelephonyServerService {
  voipBaseUrls: AsteriskServerInterface[] = [];
  callCenterBaseUrls: string[] = environment.config.callCenterBaseUrls;
  port: string = environment.config.voipPort;
  voipServer$: BehaviorSubject<AsteriskServerInterface> = new BehaviorSubject(null);
  connectedWsStatus: boolean;

  constructor(
    private telephonyApiService: TelephonyApiService,
    private authService: AuthService,
    private handlerEventService: HandleEventService,
    private injector: Injector,
    private telephonyIncomingService: TelephonyIncomingService,
    private wsService: WebsocketService
  ) {
    this.authService.authState
      .pipe(
        switchMap(state => (state) ? this.telephonyApiService.getAsteriskServices() : of([]))
      )
      .subscribe(services => {
        this.connect(services);
      });
    this.wsService.connections.callCenter.isConnected.pipe(
      tap((status) => this.connectedWsStatus = !!status),
      switchMap((status) => this.connectedWsStatus !== !!status ? this.telephonyApiService.getAsteriskServices() : of(null))
    )
      .subscribe(services => {
        services ? this.checkAsteriskServer(services) : services;
      });
  }

  private connect(services): void {
    this.voipBaseUrls = services;
    if (services.length > 0) {
      const voipServer = this.shuffle(this.voipBaseUrls).find(s => s.status === 'UP');
      if (voipServer) {
        this.voipServer$.next(voipServer);
      } else {
        const notUpServer = this.voipBaseUrls[0];
        this.voipServer$.next(null);
        this.handlerEventService.openSnackBar('NO_SERVERS_AVAILABLE');
      }
    }
  }

  changeServer(index: number) {
    const voipBaseUrl = this.voipBaseUrls[index];
    const callCenterBaseUrl = this.callCenterBaseUrls[index];
    localStorage.setItem('voipServer', voipBaseUrl.connectionUrl);
    localStorage.setItem('callCenterServer', callCenterBaseUrl);
    this.voipServer$.next(voipBaseUrl);
  }


  checkAsteriskServer(asteriskServers: AsteriskServerInterface[]) {
    const updatedServers = asteriskServers;
    if (this.voipServer$.value && asteriskServers.some( server => server.id === this.voipServer$.value.id && server.status === 'UP')) {
      this.voipBaseUrls = updatedServers;
    } else if (!(asteriskServers.some( server => server.id === this.voipServer$.value?.id && server.status === 'UP')) && this.telephonyIncomingService.isIncomeCalls$.value) {
      this.telephonyIncomingService.isIncomeCalls$.subscribe((call) => {
        if (!call && !(asteriskServers.some( server => server.id === this.voipServer$.value?.id && server.status === 'UP'))) {
          console.log('call-INCOME', updatedServers);
          this.connect(updatedServers);
        }
      });
    } else {
      this.connect(updatedServers);
    }
  }

  private shuffle(array: Array<any>): Array<any> {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
}
