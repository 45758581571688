import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  OnDestroy,
  OnChanges,
  ViewChild
} from '@angular/core';
import { Service } from 'src/app/interfaces/order.interface';
import { DispOrdersService } from '../../services/disp-orders.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'utax-services-slider',
  templateUrl: './services-slider.component.html',
  styleUrls: ['./services-slider.component.scss']
})
export class ServicesSliderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() filters;
  @Input() remoted;
  @Input() callWay: boolean;

  @ViewChild('filterContainer') private filterContainer;
  ordersCount = {};

  @Output() private filtersChange = new EventEmitter();

  services;
  @Input('services') set newServiceCollections(services) {
    this.services = services;
    this.filtersChange.emit({
      filters: this.filters,
      serviceFilter: true
    })
  }
  private componentDestroyed$ = new Subject();
  constructor(private el: ElementRef, private dispOrdersService: DispOrdersService) {}

  ngOnInit() {
    this.dispOrdersService.ordersByService$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(ordersByServiceCounter => {
        this.ordersCount = ordersByServiceCounter;
      });
  }

  ngOnChanges() {}

  move(direction: 'left' | 'right') {
    if (direction === 'left') {
      this.filterContainer.nativeElement.scrollLeft -= 450;
    } else {
      this.filterContainer.nativeElement.scrollLeft += 450;
    }
  }

  toggleServiceFilter(event: { id: number; status: boolean }): void {
    this.filters.services.find(service => service.id === event.id).status = !event.status;

    this.filtersChange.emit({
      filters: this.filters,
      serviceFilter: true
    });
  }

  doubleClickOnService(serviceId: number) {
    this.filters.services = this.filters.services.map((service: Service) => {
      if (service.id === serviceId) {
        return { ...service, status: true };
      } else {
        return { ...service, status: false };
      }
    });

    this.filtersChange.emit({
      filters: this.filters,
      serviceFilter: true
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }
}
