import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { BrandingType } from '../../models/branding.model';

@Component({
  selector: 'utax-branding-tab-scales-list',
  templateUrl: './branding-tab-scales-list.component.html',
  styleUrls: ['./branding-tab-scales-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrandingTabScalesListComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() brandingTypeName: string;
  @Input() brandingTypeId: number;
  @Input() brandingTypes: BrandingType;
  @Input() allowEdit: boolean;
  @Input() allowRemove: boolean;
  @Output() addScale = new EventEmitter();
  @Output() save: EventEmitter<number> = new EventEmitter();
  @Output() saveAllScales: EventEmitter<void> = new EventEmitter();
  @Output() delete: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  get scalesFormArray(): UntypedFormArray {
    if (this.form) {
      return this.form.controls.scales as UntypedFormArray;
    }
  }
}
