import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';
import {
  DispSalaryQueueType,
  DispSalarySettingCategory,
  DispSalarySettingMode,
  DispSalarySettingModel,
  DispSalaryTableMode, IDispCategory
} from '../../models';
import { DispSalaryService } from '../../services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {filter, map, takeUntil} from 'rxjs/operators';
import { NgOnDestroy } from '../../../../../shared/services';
import {GlobalDataService} from '@global-services/global-data.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'utax-disp-salary-item',
  templateUrl: './disp-salary-item.component.html',
  styleUrls: ['./disp-salary-item.component.scss', '../columns.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroy]
})
export class DispSalaryItemComponent implements OnInit {

  readonly dispSalarySettingMode = DispSalarySettingMode;
  readonly dispSalaryTableMode = DispSalaryTableMode;
  readonly dispSalarySettingCategory = DispSalarySettingCategory;
  readonly dispSalaryQueueType = DispSalaryQueueType;

  settingFormGroup: UntypedFormGroup;
  _settingFormGroup: UntypedFormGroup;

  @Input() setting: DispSalarySettingModel;
  @Input() newSetting = false;

  @HostListener('mousedown') onMouseDown() {
    if (this.dispSalaryService.tableMode === DispSalaryTableMode.EDIT) {
      this.dispSalaryService.selectedSettingUuid$.next(this.setting.id);
    }
  }

  public basicClientCategories: Observable<IDispCategory[]>

  constructor(
    public dispSalaryService: DispSalaryService,
    public globalDataService: GlobalDataService,
    private formBuilder: UntypedFormBuilder,
    private ngOnDestroy$: NgOnDestroy
  ) { }

  ngOnInit(): void {
    this.settingFormGroup = this.formBuilder.group({
      amount: [this.setting?.amount ? this.setting.amount : '',
        [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$'), Validators.minLength(0)]
      ],
      minTalkDuration: [this.setting?.minTalkDuration ? this.setting.minTalkDuration : '',
        [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$'), Validators.minLength(0)]
      ],
      operatorCategoryId: [this.setting?.operatorCategoryId ? this.setting.operatorCategoryId : '', [Validators.required]],
      clientCategoryId: [this.setting?.clientCategoryId ? this.setting.clientCategoryId : null],
      isRemoteWork: [typeof this.setting?.isRemoteWork === 'boolean' ? this.setting.isRemoteWork : '', [Validators.required]],
      queueId: [this.setting?.queueId ? this.setting.queueId : null]
    });

    const mode = this.dispSalaryService.settingMode$.value;
    if (mode === DispSalarySettingMode.BASIC) {
      this.settingFormGroup.controls['clientCategoryId'].setValidators(Validators.required);
      this.settingFormGroup.controls['queueId'].clearValidators();
    } else if (mode === DispSalarySettingMode.INDIVIDUAL) {
      this.settingFormGroup.controls['queueId'].setValidators(Validators.required);
      this.settingFormGroup.controls['clientCategoryId'].clearValidators();
    }

    this._settingFormGroup = {...this.settingFormGroup.value};
    let add = false;

    this.settingFormGroup.valueChanges
      .pipe(
        takeUntil(this.ngOnDestroy$),
        filter(() => add)
      )
      .subscribe(() => this.addFormToList());

    this.dispSalaryService.tableMode$
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(mode => {
        if (mode !== DispSalaryTableMode.ADD) {
          add = true;
        }
        this.settingFormGroup.reset(this._settingFormGroup);
      });
    this.basicClientCategories = this.dispSalaryService.clientCategories$
      .pipe(
        map(categories => categories.filter(category => category.type !== 'QUEUE_TYPE_GLOBAL_NEW_CLIENTS'))
      );
  }

  private addFormToList(): void {
    if (this.settingFormGroup.dirty && this.settingFormGroup.valid) {
      const submitForm = this.settingFormGroup.value
      submitForm.id = this.dispSalaryService.selectedSettingUuid$.value
      this.dispSalaryService.addNewSetting(this.settingFormGroup.value);
    }
  }

  getSelectedWorkShift(remote: boolean): string {
    return remote ? 'REMOTE' : 'LOCAL';
  }

  getQueue(): string {
    const queueId = this.settingFormGroup.get('queueId').value;
    return this.dispSalaryService.queues$.value.find(queue => queue.id === queueId)?.name;
  }

  getClientCategory(): string {
    const category = this.settingFormGroup.get('clientCategoryId').value;
    return this.dispSalaryService.clientCategories$.value.find(c => c.id === category)?.type;
  }

  getOperatorCategory(): string {
    const category = this.settingFormGroup.get('operatorCategoryId').value;
    return this.globalDataService.globalCategories$.value.find(c => c.id === category)?.name;
  }

  save(): void {
    if (this.settingFormGroup.dirty && this.settingFormGroup.valid) {
      this.dispSalaryService.postSetting(this.settingFormGroup.value)
        .subscribe(() => this.dispSalaryService.reload());
    }
  }

  cancel(): void {
    this.dispSalaryService.tableMode = DispSalaryTableMode.VIEW;
  }

}
