import { Component, OnInit, Input, OnChanges, OnDestroy, EventEmitter, Output } from '@angular/core';
import { PeriodicBonus } from '../../models/bonus.model';
import { Tariff } from '../../models/tariff.model';
import { TariffFormsGenerationService } from '../../services';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'utax-driver-tariff-form',
  templateUrl: './driver-tariff-form.component.html',
  styleUrls: ['./driver-tariff-form.component.scss']
})
export class DriverTariffFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input('tariff')
  set tariffSet(tariff: Tariff) {
    this.tariff = tariff;
    this.tariffFormsService.tariff.next(tariff);
    if (!tariff) {
      this.selectedIndexChange(0);
    }
  }
  @Input() serviceId: number;
  @Input() tariffs: Tariff[];
  @Input() bonuses: PeriodicBonus[];
  @Output() save = new EventEmitter();
  @Output() clear = new EventEmitter();

  tariff: Tariff;
  showControlButtons = true;
  selectedTabIndex: number;

  constructor(
    public tariffFormsService: TariffFormsGenerationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.formGeneration();
  }

  ngOnDestroy(): void {
    this.tariffFormsService.selectedTabIndex$.next(null);
  }

  selectedIndexChange(event): void {
    this.selectedTabIndex = event;
    this.tariffFormsService.selectedTabIndex$.next(event);
    this.showControlButtons = event !== 1 && event !== 7 && event !== 8 && event !== 9;
  }

  saveTariff(): void {
    this.save.emit();
  }

  clearForm(): void {
    this.clear.emit();
  }

  private formGeneration(): void {
    this.tariffFormsService.generalForm = this.tariffFormsService.createGeneralDriverTariffForm(this.tariff, this.bonuses);
  }

}
