import {
  Component,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { createMarker } from '../utils/map';

@Component({
  selector: 'utax-map-cab',
  template: ''
})
export class MapCabComponent implements OnInit, OnChanges, OnDestroy {
  @Input() cab;
  @Input() isCabSelected;
  @Input() mapComponent;
  map;
  marker;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.map = this.mapComponent.map;
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }
    this.marker = createMarker(
      this.cab.location,
      this.map,
      this.cabMarkerTemplate(),
      'html',
      this.isCabSelected ? 1000 : 300
    );
  }

  ngOnDestroy() {
    if (this.map) {
      this.map.removeLayer(this.marker);
    }
  }

  cabMarkerTemplate() {
    const cabSelected = this.isCabSelected ? 'cab-selected' : '';

    const triangleStr = `<div class="triangle-container">
      <div class="triangle triangle-${cabSelected}" style="transform: rotate(${Math.round(
      this.cab.bearing
    )}deg)"></div>
    </div>`;

    return `<div class="cab-marker cab-color-${this.cab.color || 'red'} ${cabSelected}">
      ${this.cab.bearing <= 0 ? triangleStr : ''}
      <div class="cab-title title-${cabSelected}" >${this.cab.callsign +
      ' '} </div>
      ${this.cab.bearing > 0 ? triangleStr : ''}
    </div>`;
  }
}
