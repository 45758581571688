import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseRemoteWorkModel, RemoteWorkRequestModel, RemoteWorkStateModel } from '../models';
import {map, pluck} from 'rxjs/operators';
import { DataModel } from '../../shared/models/data.model';

const statusWorkingShifturl = 'call-centre/api/me/status';

interface StatusWorkingShiftInterface {
  displayStatus: string;
  workStatus: string;
  callStatus: string;
  incomingCallStatus: string;
  outgoingCallStatus: string;
  primaryOutgoingCallStatus: string;
  secondaryOutgoingCallStatus: string;
  workShiftStatus: string;
  remoteWorkStatus: string;
  lastWorkShiftStartedAt: string;
  lastWorkShiftEndedAt: string;
  updatedAt: string;
}

@Injectable({
  providedIn: 'root'
})
export class RemoteWorkApiService {

  constructor(private httpClient: HttpClient) { }

  // Remote Work API
  // GET

  getRemoteWorkList(): Observable<RemoteWorkRequestModel[]> {
    return this.httpClient.get<DataModel<RemoteWorkRequestModel[]>>('operator/remote-work/requests')
      .pipe(pluck('data'));
  }

  getRemoteWorkState(): Observable<RemoteWorkStateModel> {
    return this.httpClient.get<DataModel<RemoteWorkStateModel>>('operator/state')
      .pipe(pluck('data'));
  }

  getRemoteWorkRequestStatus(id: string): Observable<BaseRemoteWorkModel> {
    const params = new HttpParams().set('id', id);
    return this.httpClient.get<DataModel<BaseRemoteWorkModel>>('operator/remote-work/requests/status', {params})
      .pipe(pluck('data'));
  }

  // POST

  postRemoteWorkApprove(id: string): Observable<null> {
    const params = new HttpParams().set('id', id);
    return this.httpClient.post<null>('operator/remote-work/requests/approve', null, {params});
  }

  postRemoteWorkReject(id: string): Observable<null> {
    const params = new HttpParams().set('id', id);
    return this.httpClient.post<null>('operator/remote-work/requests/reject', null, {params});
  }

  postRemoteWorkRevoke(id: {id: string} | {user_id: string}): Observable<any> {
    const params = new HttpParams({ fromObject: id });
    return this.httpClient.post<any>('operator/remote-work/requests/revoke', null, {params});
  }

  postRemoteWorkCreateRequest(): Observable<BaseRemoteWorkModel> {
    return this.httpClient.post<DataModel<BaseRemoteWorkModel>>('operator/remote-work/requests', null)
      .pipe(pluck('data'));
  }

  public statusWorkingShift(): Observable<boolean> {
    return this.httpClient.get<StatusWorkingShiftInterface>(statusWorkingShifturl).pipe(map((status) => status?.workShiftStatus === 'OPEN'));
  }
}
