import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject } from '@angular/core';

@Component({
  selector: 'utax-block-driver-reason',
  templateUrl: './block-driver-reason.component.html',
  styleUrls: ['./block-driver-reason.component.scss']
})
export class BlockDriverReasonModalComponent implements OnInit {
  reason = null;
  comment = null;
  showHelpMessage = false;
  reasons = [];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BlockDriverReasonModalComponent>
  ) {}

  ngOnInit() {
    this.reasons = this.data.reasons;
    this.reason = this.reasons[0].id;
  }

  send() {
    if (this.reason == null) {
      this.showHelpMessage = true;
      return;
    }

    const data = {
      id: this.data.id,
      service: this.data.service,
      reason_id: this.reason,
      comment: this.comment
    };

    this.dialogRef.close(data);
  }
}
