import { SortOption } from 'src/app/utils/cluster-sort';
import { Injectable } from '@angular/core';
import { WebsocketService } from '../../services/ws.service';
import { BehaviorSubject, Subject, Observable, Subscription } from 'rxjs';
import { DriverPartner } from '../models/partner';
import { OrderCab } from 'src/app/interfaces/order.interface';
import { HttpClient } from '@angular/common/http';
import { CabTarget } from 'src/app/interfaces/target.interface';

interface CabTargetMsg {
  data: CabTarget;
  header: {
    action: 'create' | 'update' | 'delete';
    sub_id: string;
    type: 'cab_target';
  };
}

@Injectable()
export class LogistCabsService {
  // visibleCabs = [];
  visibleCabsSub$ = new BehaviorSubject([]);
  public activeCabs = [];
  public disabledCabs = [];
  public activeCabsSub = new BehaviorSubject([]);
  allActiveCabs$ = new BehaviorSubject([]);
  public disabledCabsSub = new BehaviorSubject([]);
  public currentCabSub = new BehaviorSubject({ id: 'all' });
  public clickedCab$ = new Subject();
  selectedCabId = 'all';
  selectedCab;
  deactivatedCabIds: string[] = [];
  selectedServiceId: number;

  currentlySelectedCabIndex: number;

  requests;
  shifts;
  driverPauses: any[];

  mirandaConnection;
  msgSub$;
  cabTarget$: Subscription;

  allRcvdCabs = [];

  sortOptions: SortOption<any>[] = [
    {
      priority: 1,
      order: 1,
      chracteristic: cab => cab.color === 'red',
      comparator: (a, b) => +a.callsign - +b.callsign
    },
    {
      priority: 2,
      order: 2,
      chracteristic: cab => cab.color === 'yellow',
      comparator: (a, b) => +a.callsign - +b.callsign
    },
    {
      priority: 3,
      order: 3,
      chracteristic: cab => cab.color === 'green',
      comparator: (a, b) => +a.callsign - +b.callsign
    }
  ];

  constructor(private wsService: WebsocketService, private http: HttpClient) {}

  enableService() {
    this.mirandaConnection = this.wsService.openConnection('miranda');
    this.msgSub$ = this.mirandaConnection.on('vehusr').subscribe(msg => {
      switch (msg.header.action) {
        case 'rcv':
          this.processingCabs(msg.data);
          this.getRcvCabs(msg.data);
          break;
        case 'update':
          this.updateRcvCabs(msg.data);
          this.processingCabs([msg.data]);
          break;
      }
    });

    this.cabTarget$ = this.mirandaConnection.on('cab_target').subscribe((msg: CabTargetMsg) => {
      let cab;
      switch (msg.header.action) {
        case 'create':
          cab = {
            ...this.getCabWithUpdatedTargets(msg.data, msg.header.action)
          };
          break;
        case 'update':
          cab = {
            ...this.getCabWithUpdatedTargets(msg.data, msg.header.action)
          };
          break;
        case 'delete':
          cab = {
            ...this.getCabWithUpdatedTargets(msg.data, msg.header.action)
          };
          break;
      }

      if (cab) {
        this.processingCabs([
          {
            id: '',
            cabs: [cab]
          }
        ]);
      }
    });

    this.mirandaConnection.createSub({
      data: { arguments: {}, subscriptions: ['vehusr'] },
      header: { action: 'set', type: 'sub' }
    });
  }

  selectCab(cab) {
    this.selectedCabId = `${cab.id}`;
    this.selectedCab = cab;
    this.currentCabSub.next(cab);
  }

  enableCabAutomation(cabId): Observable<any> {
    return this.http.post('operator/automation/cabs/enable', {
      id: cabId
    });
  }

  disableCabAutomation(cabId): Observable<any> {
    return this.http.post('operator/automation/cabs/disable', {
      id: cabId
    });
  }

  pauseAutomationCabPause(id: string): Observable<any> {
    return this.http.post('operator/automation/cabs/pause', { id });
  }

  resumeAutomationCabPause(id: string): Observable<any> {
    return this.http.post('operator/automation/cabs/resume', { id });
  }

  selectNextCab() {
    this.setCurrentlySelectedCabIndex();

    if (this.currentlySelectedCabIndex === undefined) {
      this.selectCab(this.activeCabs[0]);
      this.currentlySelectedCabIndex = 0;
    } else if (this.currentlySelectedCabIndex + 1 !== this.activeCabs.length) {
      this.selectCab(this.activeCabs[this.currentlySelectedCabIndex + 1]);
    }
  }

  selectPreviosCab() {
    this.setCurrentlySelectedCabIndex();

    if (this.currentlySelectedCabIndex === undefined) {
      this.selectCab(this.activeCabs[0]);
      this.currentlySelectedCabIndex = 0;
    } else if (this.currentlySelectedCabIndex !== 0) {
      this.selectCab(this.activeCabs[this.currentlySelectedCabIndex - 1]);
    }
  }

  removeOfferFromCab(orderId): void {
    // remove offer from cab by order id
    this.activeCabs = this.activeCabs.map(cab => {
      if (cab.offers.length > 0 && cab.offers.some(offer => offer.request_id === orderId)) {
        return { ...cab, offers: [], offer: null };
      } else {
        return cab;
      }
    });
    this.allActiveCabs$.next(this.activeCabs);
  }

  private setCurrentlySelectedCabIndex() {
    this.activeCabs.forEach((cab: any, index) => {
      if (this.selectedCabId !== undefined && this.selectedCabId === cab.id) {
        this.currentlySelectedCabIndex = index;
      }
    });
  }

  processingCabs(vehUsers, doNotCheckOnReqs?: boolean) {
    const activeCabs = [];
    const activeCabsObj = this.toObj(this.activeCabs);
    const disabledCabs = [];
    const disabledCabsObj = this.toObj(this.disabledCabs);
    vehUsers.forEach(vehUser => {
      const partners = this.getDriverPartners(vehUser);

      vehUser.cabs
        .filter(cab => cab.callsign !== null)
        .forEach(cab => {
          // targets managment
          if ((this.allRcvdCabs || []).some(item => item.id === cab.id)) {
            cab.targets = this.allRcvdCabs.find(item => item.id === cab.id).targets;
            this.allRcvdCabs = this.allRcvdCabs.map(item => (item.id === cab.id ? cab : item));
          }

          if (this.deactivatedCabIds.some(id => cab.id === id)) {
            this.deactivatedCabIds = this.deactivatedCabIds.filter(id => cab.id !== id);
          } else {
            cab.partners = partners;
            const key = cab.id;
            if (!doNotCheckOnReqs) {
              cab = this.checkOnRequests(cab);
            }
            cab = this.checkOnPauses(cab);
            if (cab.is_active) {
              if (activeCabsObj[key]) {
                cab.order = activeCabsObj[key].order;
                cab.color = activeCabsObj[key].color;
              }
              activeCabs.push(cab);
              activeCabsObj[key] = cab;
              delete disabledCabsObj[key];
            } else {
              disabledCabs.push(cab);
              disabledCabsObj[key] = cab;
              delete activeCabsObj[key];
            }
          }
        });
    });

    this.activeCabs = this.toArr(activeCabsObj).sort((a, b) => +a.callsign - +b.callsign);
    this.allActiveCabs$.next(this.activeCabs);
    this.disabledCabs = this.toArr(disabledCabsObj);

    const selectedCab = activeCabs.find(actCab => actCab.id === this.selectedCabId);
    if (selectedCab) {
      this.selectCab(selectedCab);
    }
    this.activeCabsSub.next(activeCabs);
    this.disabledCabsSub.next(disabledCabs);
  }

  private toObj(arr) {
    const obj = {};
    arr.forEach(el => (obj[el.id] = el));
    return obj;
  }

  private toArr(obj) {
    const arr = [];
    for (const key in obj) {
      arr.push(obj[key]);
    }
    return arr;
  }

  checkOnRequests(activeCab) {
    let cab = activeCab;
    if (this.shifts) {
      this.shifts.forEach(shift => {
        if (shift.cabId === activeCab.id) {
          const requestsOfCab = this.requests.filter(req => req.wshId === shift.id);
          cab = {
            ...activeCab,
            requests: requestsOfCab
          };
        }
      });
    }
    return cab;
  }

  private checkOnPauses(cab): any {
    let pauses = [];
    const shift = (this.shifts || []).find(item => item.cabId === cab.id);
    if (shift && shift.id) {
      pauses = (this.driverPauses || []).filter(pause => pause.wshId === shift.id);
    }
    return pauses.length > 0 ? { ...cab, pauses } : cab;
  }

  private getDriverPartners(vehUser): DriverPartner[] {
    let partners: DriverPartner[] = [];
    if (vehUser.cabs.length > 1) {
      const filteresCabs = vehUser.cabs.filter(cab => Boolean(cab.callsign));
      partners = filteresCabs.map((cab: OrderCab) => {
        return {
          callsign: cab.callsign,
          name: `${cab.driver.first_name} ${cab.driver.middle_name ? cab.driver.middle_name + ' ' : ''}${
            cab.driver.last_name
          }`,
          phoneNumber: cab.driver.phone_number
        };
      });
    }
    return partners.length > 1 ? partners : [];
  }

  setRequestsAndShifts(requests, shifts) {
    this.requests = requests;
    this.shifts = shifts;
  }

  getCabWithUpdatedTargets(target: CabTarget, action: 'create' | 'update' | 'delete'): any {
    const cab = (this.allRcvdCabs || []).find(item => item.id === target.cab_id);
    switch (action) {
      case 'create':
        cab.targets.push(target);
        break;
      case 'update':
        cab.targets = cab.targets.map(item => (item.id === target.id ? target : item));
        break;
      case 'delete':
        cab.targets = cab.targets.filter(item => item.id !== target.id);
        break;
      default:
        break;
    }

    return cab;
  }

  disableService() {
    this.mirandaConnection.close();
    this.msgSub$.unsubscribe();
    this.cabTarget$.unsubscribe();
  }

  private getRcvCabs(vehUsers): void {
    vehUsers.forEach(vehUser => {
      vehUser.cabs.forEach(cab => {
        this.allRcvdCabs.push(cab);
      });
    });
  }

  private updateRcvCabs(vehUser): void {
    vehUser.cabs.forEach(item => {
      if (!this.allRcvdCabs.some(cab => cab.id === item.id)) {
        this.allRcvdCabs.push(item);
      }
    });
  }
}
