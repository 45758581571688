import { GetOrderAddressLinePipe } from './get-order-address-line.pipe';
import { GetTaxiServiceNamePipe } from './get-taxi-service-name.pipe';
import { GetTaxiServiceStringPipe } from './get-taxi-service-string.pipe';
import { StringCutPipe } from './string-cut.pipe';
import { CustomTimeFormatPipe } from './custom-time-format.pipe';

export const pipes: any[] = [
  GetTaxiServiceStringPipe,
  GetTaxiServiceNamePipe,
  GetOrderAddressLinePipe,
  StringCutPipe,
  CustomTimeFormatPipe
];

export * from './get-order-address-line.pipe';
export * from './get-taxi-service-name.pipe';
export * from './get-taxi-service-string.pipe';
export * from './string-cut.pipe';
export * from './custom-time-format.pipe';
