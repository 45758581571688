import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'utax-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss']
})
export class CheckboxGroupComponent implements OnInit {
  @Input() checkBoxGroup;

  @Output() checkboxToggled = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  toggle(item) {
    const event = {};
    event[item.key] = !item.value.status;
    this.checkboxToggled.emit(event);
  }

  log(a) {
    console.log(a);
  }

  shortcutCompare(a, b) {
    if (+a.value.hotkey.substr(1) > +b.value.hotkey.substr(1)) {
      return 1;
    } else {
      return -1;
    }
  }
}
