import { Pipe, PipeTransform } from '@angular/core';
import { DispatcherModel } from '../models';

@Pipe({
  name: 'sortAndFilterDispatchers'
})
export class SortAndFilterDispatchersPipe implements PipeTransform {
  transform(disps: DispatcherModel[], queueGroupId: string): DispatcherModel[] {
    return disps
      .filter(disp => disp.queue_service_groups.some(group => group.id === queueGroupId))
      .sort((a, b) => {
        return a.queue_service_groups.find(group => group.id === queueGroupId).active &&
        !b.queue_service_groups.find(group => group.id === queueGroupId).active
          ? -1
          : 1;
      });
  }
}
