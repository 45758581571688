import { Waypoint } from './../models/dispatcher';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getOrderAddressLine'
})
export class GetOrderAddressLinePipe implements PipeTransform {
  transform(waypoints: Waypoint[], status: string): string {
    if (status === 'in_progress' || status === 'completed') {
      return waypoints.find((point: Waypoint) => point.type === 'end').name;
    } else {
      return waypoints.find((point: Waypoint) => point.type === 'start').name;
    }
  }
}
