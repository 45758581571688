import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  OperatorBlacklistDataInterface,
  PassengerBlacklistAddInterface,
  PassengerBlacklistAddResponseInterface, PassengerBlacklistDataBodyInterface,
  PassengerBlacklistDataInterface,
  PassengerBlacklistDataQueryInterface,
  PassengerBlacklistFullInterface,
  PassengerBlacklistInterface
} from '../models/passenger-blacklist.interface';

@Injectable({
  providedIn: 'root'
})
export class PassengerBlacklistApiService {

  constructor(private http: HttpClient) { }


  public getOperatorsForBlacklist(): Observable<OperatorBlacklistDataInterface> {
    let params = new HttpParams();
    params = params.append('onlyUsedBlocking', '1');
    return this.http.get<OperatorBlacklistDataInterface>('call-centre/api/operators/minimal', {params});
  }

  public getPassengerInBlackList(paramsObj: PassengerBlacklistDataQueryInterface, body: PassengerBlacklistDataBodyInterface): Observable<PassengerBlacklistDataInterface> {
    let params: HttpParams = new HttpParams();
    for (const param in paramsObj) {
      if (Array.isArray(paramsObj[param])) {
        const paramsSet = new Set(paramsObj[param].flat());
        paramsSet.forEach((p) => params = params.append(param, String(p)));
      } else {
        if (paramsObj[param]) {
          params = params.set(param, paramsObj[param]);
        }
      }
    }
    if (body?.operatorIds && !body?.operatorIds.length) {
      delete body.operatorIds;
    }
    return this.http.post<PassengerBlacklistDataInterface>('call-centre/api/block-list/search', body, {params});
  }


  public addPassengerInBlackList(body: PassengerBlacklistAddInterface): Observable<PassengerBlacklistAddResponseInterface> {
    return this.http.post<PassengerBlacklistAddResponseInterface>('call-centre/api/block-list', body);
  }
  public unlockPassenger(id: string): Observable<void> {
    return this.http.put<void>(`call-centre/api/block-list/${id}/unblock`, {});
  }

  public unlockPassengerId(id: string): Observable<void> {
    return this.http.put<void>(`call-centre/api/clients/${id}/unblock`, {});
  }


}
