import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getIntercityInfo'
})
export class GetIntercityInfoPipe implements PipeTransform {
  transform(breakdown: any[], type?: 'intercity' | 'incity'): any {
    if (type) {
      let featureItem = breakdown.find(item => item.display_name === type); // near_intercity
      if (type === 'intercity' && !featureItem) {
        featureItem = breakdown.find(item => item.display_name === 'near_intercity');
      }
      return featureItem ? featureItem.unit_qty : '';
    }
    return breakdown ? breakdown.some(item => item.type === 'intercity') : false;
  }
}
