import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'utax-map-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class MapControlsComponent implements OnInit {

  @ViewChild('contentWrapper') content: ElementRef;

  constructor() { }

  ngOnInit() {
  }

}
