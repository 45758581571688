import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';

import * as moment from 'moment';
import { interval, Subject } from 'rxjs';
import { takeUntil, startWith } from 'rxjs/operators';
import {OrderClass} from '@global-classes/order.class';

@Component({
  selector: 'utax-dispatcher-order-item-timer',
  templateUrl: './dispatcher-order-item-timer.component.html',
  styleUrls: ['./dispatcher-order-item-timer.component.scss']
})
export class DispatcherOrderItemTimerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() order: OrderClass;

  totalTime: number;
  timeLeft: number;
  width = 0;
  classNameForTimer = 'white';

  public isPreorderStart: boolean;

  componentDestroyed$ = new Subject();

  constructor() {}

  ngOnInit() {
    if (this.order) {
      interval(9000)
        .pipe(startWith(0), takeUntil(this.componentDestroyed$))
        .subscribe(() => {
          this.countTime();
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.countTime();
  }

  countTime() {
    this.timeLeft = moment.utc(this.order.searched_at).diff(moment(), 'minutes');
    if (this.order.is_preorder) {
      this.totalTime = moment.utc(this.order.searched_at).diff(moment.utc(this.order.sending_at), 'minutes');
      this.isPreorderStart = moment.utc(this.order.sending_at).isSameOrBefore(moment());
    } else {
      this.totalTime = moment.utc(this.order.searched_at).diff(moment.utc(this.order.created_at), 'minutes');
    }
    if (this.timeLeft > 0) {
      this.width = Math.floor((this.timeLeft / this.totalTime) * 100);
      if (this.timeLeft <= this.totalTime - (this.totalTime - 2)) {
        this.classNameForTimer = 'orange';
      } else {
        this.classNameForTimer = 'white';
      }
    } else {
      this.width = 100;
      this.classNameForTimer = 'red';
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }
}
