import { HandleEventService } from './handle-event-service.service';
import { UnassignCabModalComponent } from '../shared/components';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {filter, mergeMap, pluck, takeUntil} from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebsocketService } from './ws.service';
import { BehaviorSubject, Subject } from 'rxjs';
import {Order, OrderCallDispatcherStatusInterface, OrdersMap} from '../interfaces/order.interface';
import { AuthService } from '../auth/services';
import { StateService } from './state.service';
import * as moment from 'moment';
import {OrderClass} from '../classes/order.class';
import {ChatApiService} from "../shared/services";
import {GlobalDataService} from "@global-services/global-data.service";
import {OperatorCommentService} from "../shared/services/operator-comment.service";

const removeOrderReasonUrl = 'operator/requests/reasons',
  removeOrderUrl = 'operator/requests/cancel',
  tripComplete = 'operator/trips/complete',
  unassignCabReasonUrl = 'operator/trips/reasons',
  unassignCabUrl = 'operator/trips/cancel';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  currentOrders: OrdersMap = new Map<string, OrderClass>();
  currentOrdersUpdates$ = new BehaviorSubject<OrdersMap>(this.currentOrders);
  ordersUpdates$ = new Subject<OrderClass>();
  ordersDeletions$ = new Subject<string>();
  ordersInstantUpdates$ = new Subject<OrderClass[]>();
  public delDriverFromOrderStatus$: BehaviorSubject<'allowed' | 'processing' | 'completed'> = new BehaviorSubject<'allowed' | 'processing' | 'completed'>('allowed');

  isLoading = false;
  modalOpened = false;
  reqDiscussSubscribed = false;

  private deletedOrderIdsMap = new Map<string, boolean>();

  dialogRef: MatDialogRef<UnassignCabModalComponent>;
  public accountingPassengerOverdraft$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    private ws: WebsocketService,
    private http: HttpClient,
    private dialog: MatDialog,
    private handleEventService: HandleEventService,
    private authService: AuthService,
    private stateService: StateService,
    private globalDataService: GlobalDataService,
    private operatorCommentService: OperatorCommentService,
  ) {
  }

  createSubscription() {
    this.authService.authState.pipe(filter(state => state)).subscribe(() => {
      const mirandaConnection = this.ws.openConnection('miranda');
      this.isLoading = true;

      mirandaConnection.on('bulk').subscribe(msg => {
        this.getAccountingPassengerOverdraft();
        this.isLoading = false;
        this.currentOrders = this.getOrdersMap(msg.data.requests);
        this.currentOrdersUpdates$.next(this.currentOrders);
        this.globalDataService.callConversationList$.subscribe((list) => {
          list.forEach(call => this.changeOrderCallOperatorStatus(call));
        });
      });

      mirandaConnection
        .on('invoice')
        .subscribe(res => {
          // const res = JSON.parse(msg);
          if (res.header.action === 'update' &&
            (['hold_processing', 'hold_approved', 'hold_declined', 'hold_expired'].includes(res.data.status))) {
            const order = this.currentOrders.get(res.data.request_id);
            if (!order) {
              return;
            }
            if (order?.trip?.invoices) {
              const index = order.trip.invoices.findIndex(invoice => invoice.id === res.data.id);
              if (index !== -1) {
                order.trip.invoices[index] = res.data;
              } else {
                order.trip.invoices.push(res.data);
              }
            } else {
              if ( order?.trip) {
                order.trip.invoices = [res.data];
              } else {
                order.trip = { invoices: [res.data] };
              }
            }
            this.currentOrders.set(res.data.request_id, order);
            this.ordersUpdates$.next(order);
          }
        });

      mirandaConnection.on('req').subscribe(msg => {
        if (msg.header.action === 'rcv' && !this.deletedOrderIdsMap.has(msg.data.id)) {
          this.currentOrders.set(msg.data.id, this.getOrder(msg.data));
          this.ordersUpdates$.next(this.getOrder(msg.data));
        }
        if (msg.header.action === 'update' && !this.deletedOrderIdsMap.has(msg.data.id)) {
          if (msg.data.status === 'completed' || msg.data.status === 'canceled') {
            this.currentOrders.delete(msg.data.id);
            this.ordersDeletions$.next(msg.data.id);
          } else {
            const currentOrder = this.currentOrders.get(msg.data.id);
            const newOrder = this.getOrder(msg.data);
            newOrder.calls = currentOrder?.calls ? currentOrder.calls : [];
            this.currentOrders.set(msg.data.id, newOrder);
            this.ordersUpdates$.next(newOrder);
          }
        }
        if (msg.header.action === 'lock') {
          const order = this.currentOrders.get(msg.data.id);
          if (order) {
            order.is_locked = true;
            order.lock_mode = msg.data.lock_mode;
            order.locked_user = msg.data.locked_user;
            this.ordersUpdates$.next(order);
          }
        }
        if (msg.header.action === 'unlock') {
          const order = this.currentOrders.get(msg.data.id);
          if (order) {
            order.is_locked = false;
            order.locked_user = null;
            order.lock_mode = null;
            this.ordersUpdates$.next(order);
          }
        }
        if (msg.header.action === 'delete') {
          this.currentOrders.delete(msg.data.id);
          this.ordersDeletions$.next(msg.data.id);
        }
      });

      mirandaConnection.on('accessible_services').subscribe(msg => {
        const storeValue = this.stateService.store.value;
        this.stateService.store.next({
          ...storeValue,
          service: msg.data,
        });
      });

      if (!this.reqDiscussSubscribed) {
        mirandaConnection.on('req_discuss').subscribe(res => {
          if (res.header.action === 'update') {
            const order = this.currentOrders.get(res.data.id);
            if (!order) {
              return;
            } else {
              order.last_discussion_message = res.data.message;
              if (res.data.id === this.operatorCommentService.currentOrderSelectId$.value) {
                this.operatorCommentService.currentOrderComments$.next([...this.operatorCommentService.currentOrderComments$.value, res.data]);
              }
            }
            this.currentOrders.set(res.data.id, order);
            this.ordersUpdates$.next(order);
          }
          this.reqDiscussSubscribed = true;
        });
      }
      mirandaConnection.createSub({
        data: { arguments: {}, subscriptions: ['req'] },
        header: { action: 'set', type: 'sub' }
      });
    });
  }

  private getOrdersMap(orders: Order[]): OrdersMap {
    const entries: [string, OrderClass][] = orders.map(order => [order.id, this.getOrder(order)]);

    return new Map<string, OrderClass>(entries);
  }

  private getOrder(order: Order): OrderClass {
    if (order.waypoints) {
      order.waypoints = order.waypoints.sort((a, b) => a.ord - b.ord);
    }
    if (order.assigned_at) {
      order.assigned_at_moment = moment.utc(order.assigned_at);
    }
    order.is_card_payment = order.payment_type === 'card'
      || order.payment_type === 'apple_pay'
      || order.payment_type === 'google_pay';
    order.accountingPassengerOverdraft = this.accountingPassengerOverdraft$.value;
    return new OrderClass(order);
  }

  updateOrders(orders: OrderClass[], instantUpdate: boolean = false) {
    orders.forEach(order => {
      this.currentOrders.set(order.id, order);
      this.ordersUpdates$.next(order);
    });

    if (instantUpdate) {
      this.ordersInstantUpdates$.next(orders);
    }
  }

  markDeleted(id: string): void {
    this.deletedOrderIdsMap.set(id, true);
    this.currentOrders.delete(id);
    this.ordersDeletions$.next(id);
  }

  removeOrderReq(obj) {
    return this.http.delete(removeOrderUrl, { params: obj });
  }

  getRemoveOrderReason(orderId) {
    return this.http.get(removeOrderReasonUrl, {
      params: new HttpParams({ fromObject: { id: orderId } })
    });
  }

  holdUnholdOrder(orderId: string, isHoldString: string) {
    return this.http.put(`operator/requests/${isHoldString}`, { id: orderId });
  }

  tripComplete(tripId) {
    return this.http.post(tripComplete, { id: tripId });
  }

  getUnassignOrderCabReason(tripId: string) {
    return this.http.get(unassignCabReasonUrl, {
      params: new HttpParams({ fromObject: { id: tripId } })
    });
  }

  unassignCab(tripId: string, callsign: string) {
    this.getUnassignOrderCabReason(tripId)
      .pipe(
        pluck('data'),
        mergeMap((reasons: any[]) => {
          const config: MatDialogConfig = {
            backdropClass: 'request-dialog-backdrop',
            panelClass: 'request-reject-dialog-container',
            data: {
              reasons,
              id: tripId,
              callsign
            }
          };

          this.dialogRef = this.dialog.open(UnassignCabModalComponent, config);
          this.modalOpened = true;
          return this.dialogRef.afterClosed();
        })
      )
      .subscribe(data => {
        if (data && data.id) {
          this.unassignCabReq(data).subscribe(
            (res: any) => {
              this.delDriverFromOrderStatus$.next('completed');
              this.handleEventService.openSnackBar('UTAX_DEL_DRIVER_FROM_ORDER', 3);
              this.modalOpened = false;
            },
            err => {
              this.delDriverFromOrderStatus$.next('allowed');
              this.handleEventService.openSnackBar(err.message, 3);
              this.modalOpened = false;
            }
          );
        }
        this.dialogRef = null;
        this.modalOpened = false;
      });
  }

  unassignCabReq(obj: { id: string; reason: any; comment: string }) {
    return this.http.post(unassignCabUrl, obj);
  }

  public getAccountingPassengerOverdraft(): void {
    if (this.stateService.dumbStore?.systemSetting?.accounting_passenger_overdraft_enabled === '1') {
      this.accountingPassengerOverdraft$.next(+this.stateService.dumbStore?.systemSetting?.accounting_passenger_overdraft_max_value);
    } else {
      this.accountingPassengerOverdraft$.next(-1);
    }
  }

  public changeOrderCallOperatorStatus(orderCallInfo: string | OrderCallDispatcherStatusInterface): void {
    let order;
    if (typeof orderCallInfo === 'string') {
      for (const ord of this.currentOrders.values()) {
        if (ord.calls.length) {
          const findCall = ord.calls.find((call) => call.callId === orderCallInfo);
          if (findCall) {
            order = ord;
            order.calls = ord.calls.filter((call) => call.callId !== orderCallInfo);
            this.ordersUpdates$.next(order);
          }
        }
      }
    }
      // else if (!orderCallInfo?.requestIds && !orderCallInfo.callConversationType) {
      //   for (const ord of this.ordersService.currentOrders.values()) {
      //     if (ord.calls.length) {
      //       const findCallIndex = ord.calls.findIndex((call) => call.callId === orderCallInfo?.callId);
      //       if (findCallIndex !== -1) {
      //         order = ord;
      //         order.calls[findCallIndex] = {... order.calls[findCallIndex], ...orderCallInfo};
      //         this.ordersService.ordersUpdates$.next(order);
      //       }
      //     }
      //   }
      // } else if (!orderCallInfo?.requestId && orderCallInfo.callConversationType === 'INCOMING') {
      //   for (const ord of this.ordersService.currentOrders.values()) {
      //     if (ord.calls.length) {
      //       const findCallIndex = ord.calls.findIndex((call) => call.callId === orderCallInfo?.callId);
      //       if (findCallIndex !== -1) {
      //         order = ord;
      //         order.calls[findCallIndex] = {... order.calls[findCallIndex], ...orderCallInfo};
      //         this.ordersService.ordersUpdates$.next(order);
      //       }
      //     } else {
      //       if (orderCallInfo.clientType === 'PASSENGER' && orderCallInfo.clientId === ord.passenger?.id) {
      //         order = ord;
      //         order.calls = [orderCallInfo];
      //         this.ordersService.ordersUpdates$.next(order);
      //       } else if (orderCallInfo.clientType === 'DRIVER' && orderCallInfo.clientId === ord.cab?.driver?.id) {
      //         order = ord;
      //         order.calls = [orderCallInfo];
      //         this.ordersService.ordersUpdates$.next(order);
      //       }
      //     }
      //   }
    // }
    else {
      orderCallInfo.requestIds.forEach((id) => {
        order = this.currentOrders.get(id);
        if (order) {
          order.calls = [...order.calls, orderCallInfo];
          this.ordersUpdates$.next(order);
        }
      });

    }
  }


}
