import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ICallStatistics} from '../models/global-data.interfaces';

@Injectable({
  providedIn: 'root'
})
export class StatisticService {

  constructor(private http: HttpClient) { }

  getCurrentCallsStatistic(): Observable<ICallStatistics> {
    return this.http.get<ICallStatistics>('call-centre/api/me/call-statistic/count');
  }

}
