import {Component, Input, Output, EventEmitter, ChangeDetectorRef, OnInit} from '@angular/core';
import {Role, UserHistoryInterface} from 'src/app/users/models/user.interface';
import {RolesService} from "../../services/roles.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {finalize, pluck, tap} from "rxjs/operators";
import {DefaultHistoryInterface} from '../../../../shared/interfaces/history.interface';

@UntilDestroy()
@Component({
  selector: 'utax-roles-details',
  templateUrl: './roles-details.component.html',
  styleUrls: ['./roles-details.component.scss']
})
export class RolesDetailsComponent implements OnInit{
  private _role: Role;
  @Input() public set role(value: Role) {
    if (this._role?.id !== value?.id) {
      this.selectedTabIndex = 0;
    }
    this._role = value;
  }
  public get role(): Role {
    return this._role;
  }
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Input() isRolesAllowEdit: boolean;
  @Input() isRolesAllowHistory: boolean;
  public selectedTabIndex = 0;
  public roleHist: DefaultHistoryInterface[];
  public page = 1;
  public lastPage = 10;
  constructor(
    public rolesService: RolesService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.rolesService.selectedRole$
      .pipe(
        tap(role => {
          if (this.selectedTabIndex === 1) {
            this.getHistory(1);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  deleteSelectedRole(): void {
    this.delete.emit();
  }
  editSelectedRole(): void {
    this.edit.emit();
  }

  getHistory($event: number) {
    this.lastPage = 10;
    this.page = 1;
    if ($event === 1 && this.isRolesAllowHistory) {
      this.rolesService.loadingHistoryRoles$.next(true);
      this.rolesService.getHistoryRoles(this.role?.id)
        .pipe(
          tap(res => {
            this.roleHist = res.data;
            this.page = res.meta.current_page;
            this.lastPage = res.meta.last_page;
          }),
          untilDestroyed(this),
          finalize(() => this.rolesService.loadingHistoryRoles$.next(false))
        )
        .subscribe();
    }
  }

  public scrollPage() {
    this.getNextPage(1);
  }

  getNextPage($event: number) {
    if (this.lastPage > this.page) {
      this.rolesService.loadingHistoryRoles$.next(true);
      this.rolesService.getHistoryRoles(this.role.id, this.page + $event)
        .pipe(
          tap((obj) => {
            this.page = obj.meta.current_page;
            this.lastPage = obj.meta.last_page;
          }),
          pluck('data'),
          finalize(() => this.rolesService.loadingHistoryRoles$.next(false)),
          untilDestroyed(this)
        ).subscribe((data: UserHistoryInterface[]) => {
        this.roleHist = this.roleHist.concat(data);
      });
    }
  }

}
