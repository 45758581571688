import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customUnique'
})
export class CustomUniquePipe implements PipeTransform {
  transform(arr: any[], propertyName: string): any {
    const res = arr.reduce((acc, curr) => {
      if (curr && curr[propertyName] && !acc.valuesSet.has(curr[propertyName])) {
        acc.array.push(curr);
        acc.valuesSet.add(curr[propertyName]);
      }

      return acc;
    }, { valuesSet: new Set(), array: [] });

    return res.array;
  }

}
