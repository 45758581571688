import { SplitViewComponent } from './split-view/split-view.component';
import { RemoteWorkDialogComponent } from './remote-work-dialog/remote-work-dialog.component';
import {
  PhoneNumberVerificationDialogComponent
} from './phone-number-verification-dialog/phone-number-verification-dialog.component';

export const components = [SplitViewComponent, RemoteWorkDialogComponent, PhoneNumberVerificationDialogComponent];

export * from './split-view/split-view.component';
export * from './remote-work-dialog/remote-work-dialog.component';
export * from './phone-number-verification-dialog/phone-number-verification-dialog.component';
