import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromContainers from './containers';
import * as fromServices from './services';
import * as fromComponents from './components';
import * as fromPipes from './pipes';

import { MaterialModule } from '../utils/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

const ROUTES: Routes = [
  {
    path: '',
    component: fromContainers.TelephonyComponent
  }
];
@NgModule({
  declarations: [...fromContainers.containers, ...fromComponents.componenets, ...fromPipes.pipes],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    SharedModule,
    RouterModule.forChild(ROUTES),
    ReactiveFormsModule,
    FormsModule,
    KeyboardShortcutsModule
  ],
  exports: [...fromContainers.containers, ...fromComponents.componenets],
  providers: [...fromServices.services]
})
export class TelephonyModule {}
