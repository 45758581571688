import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject } from '@angular/core';

@Component({
  selector: 'utax-save-order-confirm-modal',
  templateUrl: './save-order-confirm-modal.component.html',
  styleUrls: ['./save-order-confirm-modal.component.scss']
})
export class SaveOrderConfirmModalComponent implements OnInit {

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SaveOrderConfirmModalComponent>
  ) { }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close('CANCEL');
  }

  save() {
    this.dialogRef.close('SAVE');
  }
}
