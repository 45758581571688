import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFieldOfService'
})
export class GetFieldOfServicePipe implements PipeTransform {
  transform(serviceId: number, services: any[], field): string {
    if (services && services.length > 0) {
      const service = services.find(item => item.id === serviceId);
      return service ? service[field] : '';
    } else {
      return '';
    }
  }
}
