import { Component, OnInit, Inject } from '@angular/core';
import {mergeMap, switchMap, tap} from 'rxjs/operators';
import {Permission, Role} from 'src/app/users/models/user.interface';
import {Validators, UntypedFormGroup, UntypedFormBuilder, FormControl} from '@angular/forms';
import {of, ReplaySubject} from 'rxjs';
import { RolesService } from '../../services/roles.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {TranslateService} from "@ngx-translate/core";

@UntilDestroy()
@Component({
  selector: 'utax-role-dialog',
  templateUrl: './role-dialog.component.html',
  styleUrls: ['./role-dialog.component.scss']
})
export class RoleDialogComponent implements OnInit {
  form: UntypedFormGroup;

  public permissionsFilterCtrl: FormControl<string> = new FormControl<string>('');
  public filteredPermissions$: ReplaySubject<Permission[]> = new ReplaySubject<Permission[]>(1);
  public savedRoleValue: any;
  public disabledSaveButton = true;

  constructor(
    private fb: UntypedFormBuilder,
    public rolesService: RolesService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<RoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Role
  ) {
    this.buildForm();
    console.log('data', this.data);
  }

  ngOnInit() {
    this.filteredPermissions$.next(this.rolesService.listOfPermissions.slice());
    this.permissionsFilterCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.filterPermissions();
      });
  }

  save(): void {
    const formVal = this.form.value;
    console.log('save', this.form.value);
    if (this.data && this.data.display_name) {
      // role updating
      this.rolesService
        .putRole(this.data.id, formVal.name, formVal.description)
        .pipe(
          // mergeMap(this.deletePermittionsFromRole),
          switchMap(val => {
            if(JSON.stringify(this.data.permissions.map(perm => perm.id)) !== JSON.stringify(formVal.permissions)) {
             return this.rolesService.addPermitionsToRole(this.data.id, formVal.permissions)
          } else {
            return of(val);}
            })
        )
        .subscribe(this.saveSubFunc);
    } else {
      // role creation
      this.rolesService
        .postRole(formVal.name, formVal.description)
        .pipe(mergeMap(createdRole => this.rolesService.addPermitionsToRole(createdRole.id, formVal.permissions)))
        .subscribe(this.saveSubFunc);
    }
  }

  private saveSubFunc = (result: Role) => {
    console.log('result', result);
    this.dialogRef.close(result);
  };

  private deletePermittionsFromRole = updatedRole => {
    const deletePermIds = this.data.permissions.map(perm => perm.id);
    if (deletePermIds && deletePermIds.length > 0) {
      return this.rolesService.removePermitionsFromRole(this.data.id, deletePermIds);
    } else {
      return of(updatedRole);
    }
  };

  private buildForm(): void {
    if (this.data) {
      this.form = this.fb.group({
        name: [this.data.display_name, [Validators.required]],
        description: [this.data.description ? this.data.description : ''],
        permissions: [
          this.data.permissions && this.data.permissions.length > 0 ? this.data.permissions.map(perm => perm.id) : '',
          [Validators.required]
        ]
      });
    } else {
      this.form = this.fb.group({
        name: ['', [Validators.required]],
        description: [''],
        permissions: ['', [Validators.required]]
      });
    }
    this.savedRoleValue = {...this.form.value};
    this.form.valueChanges.pipe(
      tap(val => {
        this.disabledSaveButton = JSON.stringify(val) === JSON.stringify(this.savedRoleValue);
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  protected filterPermissions() {
    if (!this.rolesService.listOfPermissions) {
      return;
    }
    let search = this.permissionsFilterCtrl.value;
    if (!search) {
      this.filteredPermissions$.next(this.rolesService.listOfPermissions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredPermissions$.next(
      this.rolesService.listOfPermissions.filter(permission => {
        return permission.display_name.toLowerCase().includes(search) ||  this.translateService.instant(permission.display_name).toLowerCase().includes(search);
      })
    );
  }

}
