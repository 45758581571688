import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'sortByField'
})
export class SortByFieldPipe implements PipeTransform {
  transform(array: any[], field: 'created_at'): Array<any> {
    switch (field) {
      case 'created_at':
        array.sort((a, b) => {
          return moment.utc(b.created_at).diff(moment.utc(a.created_at));
        });
        return array;

      default:
        return array;
    }
  }
}
