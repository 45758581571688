import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Observable, interval } from 'rxjs';
import * as moment from 'moment-timezone';
import { startWith, map } from 'rxjs/operators';


@Component({
  selector: 'utax-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss']
})
export class TimeoutComponent implements OnInit, OnChanges {

  @Input() icon;
  @Input() expiresAt;

  @Output() timeEnd = new EventEmitter();

  timer: Observable<number>;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.timer = interval(1000).pipe(startWith(0),
      map(() => {
        if (moment.utc(this.expiresAt).diff(moment(), 'seconds') <= 0) {
          this.timeEnd.emit(true);
        } else {
          this.timeEnd.emit(false);
        }
        return moment.utc(this.expiresAt).diff(moment(), 'seconds');
      }));
  }

}
