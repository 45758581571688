import { Pipe, PipeTransform } from '@angular/core';
import { getColorOfCab } from '../utils/status-color';

@Pipe({
  name: 'getColorOfProgressLine'
})
export class GetColorOfProgressLinePipe implements PipeTransform {
  transform(cab, order: any): string {
    return getColorOfCab(cab);
  }
}
