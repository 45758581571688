import { ArchiveApiService } from './../../services/archive-api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'utax-archive-map',
  templateUrl: './archive-map.component.html',
  styleUrls: ['./archive-map.component.scss']
})
export class ArchiveMapComponent implements OnInit {

  constructor(public archiveApiService: ArchiveApiService) { }

  ngOnInit() {
  }

}
