import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const brandingTypesUrl = 'operator/vehicle/branding/types';

@Injectable()
export class BrandingApiService {
  private brandingTypes;
  brandingTypes$ = new BehaviorSubject([]);

  constructor(private http: HttpClient) {}

  loadBrandingTypes(): void {
    if (!this.brandingTypes) {
      this.http.get(brandingTypesUrl).subscribe((res: any) => {
        this.brandingTypes = res.data;
        this.brandingTypes$.next(res.data);
      });
    }
  }
}
