import { HistoryComponent } from './history/history.component';
import { MessageToDriverComponent } from './modals/message-to-driver/message-to-driver.component';
import { ToggleSoundComponent } from './toggle-sound/toggle-sound.component';
import { OkModalComponent } from './modals/ok-modal/ok-modal.component';
import { YesNoModalComponent } from './modals/yes-no-modal/yes-no-modal.component';
import { BlockDriverModalComponent } from './modals/block-driver/block-driver.component';
import { BlockDriverReasonModalComponent } from './modals/block-driver-reason/block-driver-reason.component';
import { KsModalComponent } from './modals/ks-modal/ks-modal.component';
import { WaypointsComponent } from './waypoints/waypoints.component';
import { ButtonComponent } from './button/button.component';
import { TaxiServiceSelectComponent } from './taxi-service-select/taxi-service-select.component';
import { RemoveOrderComponent } from './modals/remove-order/remove-order.component';
import { NotificationComponent } from './notification/notification.component';
import { UnassignCabModalComponent } from './modals/unassign-cab/unassign-cab-modal.component';
import { TimeoutComponent } from './timeout/timeout.component';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { CheckboxValueComponent } from './checkbox-value/checkbox-value.component';
import { TaxiServiceNameComponent } from './taxi-service-name/taxi-service-name.component';
import { AssignCabModalComponent } from './modals/assign-cab-modal/assign-cab-modal.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { CardPayComponent } from './card-pay/card-pay.component';
import { OrderIncreaseTimeComponent } from './order-increase-time/order-increase-time.component';
import { OrderIncreaseCostComponent } from './order-increase-cost/order-increase-cost.component';
import { OrderDetailsInfoComponent } from './order-details-info/order-details-info.component';
import { CustomTranslationFieldComponent } from './custom-translation-field/custom-translation-field.component';
import { CustomTranslationModalComponent } from './modals/custom-translation-modal/custom-translation-modal.component';
import { ServiceSelectComponent } from './service-select/service-select.component';
import { ConfirmDeleteDialogComponent } from './modals/confirm-delete-dialog/confirm-delete-dialog.component';
import { ConfirmDeleteModalComponent } from './modals/confirm-delete-modal/confirm-delete-modal.component';
import {ShowImageDialogComponent} from './show-image-dialog/show-image-dialog.component';
import {InfoModalComponent} from './modals/info-modal/info-modal.component';
import {SelectAllComponent} from "./select-all/select-all.component";
import {HistoryOperatorCommentComponent} from "./history-operator-comment/history-operator-comment.component";
import {
  DriverCarDialogComponent
} from "./driver-car-dialog/driver-car-dialog.component";
import {LottieCustomComponent} from "./lottie-custom/lottie-custom.component";

export const components: any[] = [
  UnassignCabModalComponent,
  TimeoutComponent,
  NotificationComponent,
  RemoveOrderComponent,
  TaxiServiceSelectComponent,
  ButtonComponent,
  CheckboxGroupComponent,
  CheckboxValueComponent,
  WaypointsComponent,
  YesNoModalComponent,
  BlockDriverModalComponent,
  BlockDriverReasonModalComponent,
  KsModalComponent,
  OkModalComponent,
  ToggleSoundComponent,
  TaxiServiceNameComponent,
  HistoryComponent,
  AssignCabModalComponent,
  MessageToDriverComponent,
  ConfirmModalComponent,
  CardPayComponent,
  OrderIncreaseTimeComponent,
  OrderIncreaseCostComponent,
  OrderDetailsInfoComponent,
  CustomTranslationFieldComponent,
  CustomTranslationModalComponent,
  ServiceSelectComponent,
  ConfirmDeleteDialogComponent,
  ConfirmDeleteModalComponent,
  InfoModalComponent,
  ShowImageDialogComponent,
  SelectAllComponent,
  HistoryOperatorCommentComponent,
  DriverCarDialogComponent,
  LottieCustomComponent
];

export * from './service-select/service-select.component';
export * from './order-details-info/order-details-info.component';
export * from './modals/custom-translation-modal/custom-translation-modal.component';
export * from './custom-translation-field/custom-translation-field.component';
export * from './modals/assign-cab-modal/assign-cab-modal.component';
export * from './history/history.component';
export * from './toggle-sound/toggle-sound.component';
export * from './modals/ok-modal/ok-modal.component';
export * from './modals/ks-modal/ks-modal.component';
export * from './modals/yes-no-modal/yes-no-modal.component';
export * from './modals/block-driver/block-driver.component';
export * from './modals/block-driver-reason/block-driver-reason.component';
export * from './waypoints/waypoints.component';
export * from './button/button.component';
export * from './taxi-service-select/taxi-service-select.component';
export * from './modals/remove-order/remove-order.component';
export * from './modals/unassign-cab/unassign-cab-modal.component';
export * from './timeout/timeout.component';
export * from './notification/notification.component';
export * from './checkbox-group/checkbox-group.component';
export * from './checkbox-value/checkbox-value.component';
export * from './taxi-service-name/taxi-service-name.component';
export * from './modals/confirm-modal/confirm-modal.component';
export * from './card-pay/card-pay.component';
export * from './order-increase-time/order-increase-time.component';
export * from './order-increase-cost/order-increase-cost.component';
export * from './modals/confirm-delete-dialog/confirm-delete-dialog.component';
export * from './modals/confirm-delete-modal/confirm-delete-modal.component';
export * from './show-image-dialog/show-image-dialog.component';
export * from './modals/info-modal/info-modal.component';
export * from './select-all/select-all.component';
export * from './history-operator-comment/history-operator-comment.component';
export * from './driver-car-dialog/driver-car-dialog.component';
export * from './lottie-custom/lottie-custom.component';
