import { ErrorHandler, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { HandleEventService } from '../services/handle-event-service.service';
import * as Raven from 'raven-js';
import { environment } from '../../environments/environment';
import { StateService } from '../services';

const { version } = require('../../../package.json');

Raven.config(
  'https://5e794ee815624e0dabc37f1076321d72@crash.analytics.megakit.pro/16',
  {
    release: version,
    environment: environment.production ? 'production' : 'test'
  }
).install();

const NOT_START_AUDIO_SOURCE_ERROR = 'NotReadableError: Could not start audio source';
const PERMISSION_DENIED_ERROR = 'NotAllowedError: Permission denied';
const REQUESTED_DEVICE_NOT_FOUND_ERROR = 'NotFoundError: Requested device not found';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private notification: HandleEventService,
        private translate: TranslateService,
        private state: StateService
    ) {}
    handleError(err: any): void {
        const message = err.message;
        let errorItem;
        if (message.indexOf(NOT_START_AUDIO_SOURCE_ERROR) + 1) {
            errorItem = 'NOT_START_AUDIO_SOURCE_ERROR';
        }
        if (message.indexOf(PERMISSION_DENIED_ERROR) + 1) {
            errorItem = 'PERMISSION_DENIED_ERROR';
        }
        if (message.indexOf(REQUESTED_DEVICE_NOT_FOUND_ERROR) + 1) {
            errorItem = 'REQUESTED_DEVICE_NOT_FOUND_ERROR';
        }

        if (errorItem) {
            this.translate.get(errorItem).subscribe(res => {
                if (res) {
                  this.notification.openSnackBar(res, 3, true);
                }
            });
        }

        Raven.captureException(err, {
            release: version,
            tags: {
                user: this.state?.dumbStore?.user ? this.state.dumbStore.user.name : ''
            }
        });

        console.error(err);
        const exception = err.originalError || err.error || err.message || err;
        Sentry.captureException(exception);
    }
}
