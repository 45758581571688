import { Injectable } from '@angular/core';
import { DispSalaryApiService } from './disp-salary-api.service';
import { BehaviorSubject, EMPTY, forkJoin, Observable} from 'rxjs';
import {
  DispSalarySettingMode,
  DispSalarySettingModel,
  DispSalaryTableMode,
  IDispCategory
} from '../models';
import { QueueModel } from '../../queue/models';
import {filter, finalize, map, skip, switchMap, tap} from 'rxjs/operators';
import { QueueApiService } from '../../queue/services';
import {HandleEventService} from 'src/app/services';
import {ITaxiService} from '../../../../models/global-data.interfaces';
import {GlobalDataService} from '@global-services/global-data.service';

@Injectable()
export class DispSalaryService {

  // DATA
  groups$: BehaviorSubject<ITaxiService[]> = new BehaviorSubject<ITaxiService[]>([]);
  queues$: BehaviorSubject<QueueModel[]> = new BehaviorSubject<QueueModel[]>([]);
  settings$: BehaviorSubject<DispSalarySettingModel[]> = new BehaviorSubject<DispSalarySettingModel[]>([]);
  clientCategories$: BehaviorSubject<IDispCategory[]> = new BehaviorSubject<IDispCategory[]>([]);
  isCategoriesDownloaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  newSettings$: BehaviorSubject<DispSalarySettingModel[]> = new BehaviorSubject<DispSalarySettingModel[]>([]);

  // STATE
  settingMode$: BehaviorSubject<DispSalarySettingMode> = new BehaviorSubject<DispSalarySettingMode>(DispSalarySettingMode.BASIC);
  tableMode$: BehaviorSubject<DispSalaryTableMode> = new BehaviorSubject<DispSalaryTableMode>(DispSalaryTableMode.VIEW);
  selectedGroupUuid$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  selectedSettingUuid$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private dispSalaryApiService: DispSalaryApiService,
    private handleEventService: HandleEventService,
    private queueApiService: QueueApiService,
    private globalDataService: GlobalDataService
  ) { }

  // CONTROL

  init(): void {
    this.getClientCategories().subscribe();
    this.selectedGroupUuid$
      .pipe(
        skip(1),
        switchMap(group => {
          if (group) {
            return this.getQueues(group)
              .pipe(
                switchMap(() => this.getSettings(group))
              );
          } else {
            return this.getSettings();
          }
        })
      )
      .subscribe();
    this.settingMode$
      .pipe(
        tap(() => this.tableMode = DispSalaryTableMode.VIEW),
        switchMap(mode => {
          if (mode === DispSalarySettingMode.INDIVIDUAL) {
            return this.getGroups();
          } else {
            this.selectedGroupUuid$.next('');
            return EMPTY;
          }
        })
      )
      .subscribe();
  }

  save(): void {
    this.putSettings().subscribe(() => this.reload());
  }

  cancel(): void {
    this.newSettings$.next([]);
    this.selectedSettingUuid$.next('');
    this.tableMode = DispSalaryTableMode.VIEW;
  }

  reload(): void {
    if (this.settingMode === DispSalarySettingMode.INDIVIDUAL) {
      this.getSettings(this.selectedGroupUuid$.value).subscribe();
    } else {
      this.getSettings().subscribe();
    }
    this.cancel();
  }

  // SET

  get settingMode(): DispSalarySettingMode {
    return this.settingMode$.getValue();
  }

  set settingMode(mode: DispSalarySettingMode) {
    this.settingMode$.next(mode);
  }

  get tableMode(): DispSalaryTableMode {
    return this.tableMode$.getValue();
  }

  set tableMode(mode: DispSalaryTableMode) {
    this.tableMode$.next(mode);
  }

  // GET

  getGroups(): Observable<ITaxiService[]> {
    return this.globalDataService.getTaxiServices()
      .pipe(
        tap(groups => {
          this.groups$.next(groups);
          if (groups?.length) {
            this.selectedGroupUuid$.next(groups[0]?.id);
          } else {
            this.selectedGroupUuid$.next('');
          }
        })
      );
  }

  getQueues(groupUuid: string): Observable<QueueModel[]> {
    return this.globalDataService.globalTransferList$
      .pipe(
        map((queues) => queues.filter((queue) => queue.taxiServiceId === groupUuid)),
        tap(queues => {
          this.queues$.next(queues);
        })
      );
  }

  getSettings(groupUuid?: string): Observable<DispSalarySettingModel[]> {
    if (groupUuid) {
      return this.dispSalaryApiService.getSalarySettingsByGroupUuuid(100, 0, groupUuid)
        .pipe(
          tap(settings => this.settings$.next(settings))
        );
    } else {
      return this.dispSalaryApiService.getSalarySettings(100, 0)
        .pipe(
          tap(settings => this.settings$.next(settings))
        );
    }
  }

  getClientCategories(): Observable<IDispCategory[]> {
    return this.dispSalaryApiService.getClientCategories()
      .pipe(
        tap(categories => {
          categories = categories.map(category => {
            category.type = `QUEUE_TYPE_${category.type}S`;
            return category;
          });
          return this.clientCategories$.next(categories);
        }),
        finalize(() => this.isCategoriesDownloaded$.next(true))
      );
  }

  addNewSetting(newSetting: DispSalarySettingModel): void {
    const settingIndex = this.newSettings$.value.findIndex(setting => setting.id === newSetting.id);
    if (settingIndex === -1) {
      this.newSettings$.next([...this.newSettings$.value, newSetting]);
    } else {
      const newSettings = [...this.newSettings$.value];
      newSettings[settingIndex] = newSetting;
      this.newSettings$.next(newSettings);
    }
  }

  // POST

  postSetting(newSetting: DispSalarySettingModel): Observable<DispSalarySettingModel> {
    const settings = this.settings$.value;
    const res = settings.every(setting => {
      if (
        (setting.operatorCategoryId === newSetting.operatorCategoryId &&
          (setting.clientCategoryId ? setting.clientCategoryId === newSetting.clientCategoryId : setting.queueId === newSetting.queueId) &&
          setting.isRemoteWork === newSetting.isRemoteWork)
      ) {
        return false;
      }
      return true;
    });
    if (res) {
      return this.dispSalaryApiService.postSalarySetting(newSetting);
    } else {
      this.handleEventService.openSnackBar('UTAX_FRONT_SETTINGS_ALREADY_EXIST');
      throw new Error('UTAX_FRONT_SETTINGS_ALREADY_EXIST');
    }
  }

  // PUT

  putSettings(): Observable<DispSalarySettingModel[]> {
    const settings = this.settings$.value;
    const newSettings = this.newSettings$.value;
    const update: Observable<DispSalarySettingModel>[] = [];

    const acceptArray = newSettings.map(setting => {
      const isSave = settings.every(set => {
        if (setting.id !== set.id) {
          if (
            setting.operatorCategoryId === set.operatorCategoryId &&
            (setting.clientCategoryId ? setting.clientCategoryId === set.clientCategoryId : setting.queueId === set.queueId) &&
            setting.isRemoteWork === set.isRemoteWork
          ) {
            return false;
          }
        }
        return true;
      });
      if (isSave) {
        update.push(this.dispSalaryApiService.putSalarySetting(setting, setting.id));
      }
      return isSave;
    });

    if (acceptArray.every(item => item === true)) {
      return forkJoin(update);
    } else {
      this.handleEventService.openSnackBar('UTAX_FRONT_SETTINGS_ALREADY_EXIST');
      throw new Error('UTAX_FRONT_SETTINGS_ALREADY_EXIST');
    }
  }

}
