import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getEndTimeOfTrip'
})
export class GetEndTimeOfTripPipe implements PipeTransform {

  constructor() { }

  transform(trip): any {
    if (!trip) {
      return 'TRIP_IS_NULL';
    }
    return trip.statuses.find(item => {
      if (item.status === 'canceled') {
        return item;
      }

      if (item.status === 'completed') {
        return item;
      }
    }).time;
  }
}
