import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {PassengerBlacklistModalComponent} from "../passenger-blacklist-modal/passenger-blacklist-modal.component";
import {HandleEventService} from "@global-services/handle-event-service.service";
import {PassengerBlacklistService} from "../../services/passenger-blacklist.service";
import csvDownload from "json-to-csv-export";
import * as moment from "moment";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'utax-passenger-blacklist-filter',
  templateUrl: './passenger-blacklist-filter.component.html',
  styleUrls: ['./passenger-blacklist-filter.component.scss']
})
export class PassengerBlacklistFilterComponent implements OnInit {
  @Output() onValueChanged: EventEmitter<any> = new EventEmitter<any>();

  filterForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private matDialog: MatDialog,
    private handleEventService: HandleEventService,
    public passengerBlacklistService: PassengerBlacklistService,
    public translateService: TranslateService,
  ) {
    this.filterForm = this.fb.group({
      phone: ['']
    });
  }

  ngOnInit(): void {
    // this.filterForm.get('phone').valueChanges
    //   .subscribe((val) => {
    //     this.onValueChanged.emit(val);
    //   });
  }

  public addToBlacklist() {
    this.matDialog.open(PassengerBlacklistModalComponent, {
      panelClass: 'request-activate-dialog-container',
      backdropClass: 'request-dialog-backdrop',
      width: '403px',
      disableClose: true,
      data: {
        phone: this.filterForm.get('phone').value
      }
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.handleEventService.openSnackBar(`PASSENGER_BLACKLIST_SUCCESS_ADD_${result.toUpperCase()}`);
        this.passengerBlacklistService.params$.next(this.passengerBlacklistService.DEFAULT_PARAMS);
        this.passengerBlacklistService.getClients(
          this.passengerBlacklistService.params$.value,
          this.passengerBlacklistService.body$.value
        );
      }
    });
  }

  public pastePhone($event: any) {
    setTimeout(() => {
      this.filterForm.get('phone').setValue($event);
      this.filterForm.updateValueAndValidity();
    }, 50);
  }


  downloadCsv() {
    const dataToConvert = {
      data: this.passengerBlacklistService.clients$.value.map((item) => {
        return {
          'Номер телефону': item.clientPhoneNumber,
          'Оператор': item.operatorName,
          'Дата та Час': moment(item.createdAt).format('DD.MM.YYYY hh:mm'),
          'Стан': item.isClose ? this.translateService.instant('UTAX_BLACKLIST_OUT_BLACKLIST') : this.translateService.instant('UTAX_BLACKLIST_IN_BLACKLIST'),
          'Період блокування': this.translateService.instant('UTAX_' + item.blockType),
          'Коментар': item.comment,
          'Дата розблокування': moment(item.updatedAt).format('DD.MM.YYYY hh:mm'),
          'Хто розблокував':  item.isClose && item.unblockerType === 'CALL_CENTER_SERVICE' ? this.translateService.instant('UTAX_AUTO_UNLOCK_PASSENGER') : item.isClose && item.unblockerType === 'OPERATOR' ? item.unblockerName : ''
        }
      }),
      filename: this.setNameCsvFile(),
      delimiter: ',',
      headers: ['Номер телефону', 'Оператор', 'Дата та Час', 'Стан', 'Період блокування', 'Коментар', 'Дата розблокування', 'Хто розблокував']
    }
    csvDownload(dataToConvert)
  }

  setNameCsvFile(): string {
    let name = 'Номери в ЧС на КЦ, ';
    if(this.passengerBlacklistService.body$.value.phoneNumber) {
      name += `${this.passengerBlacklistService.body$.value.phoneNumber}, `;
    }
    const date = moment().format('DD.MM.YYYY hh:mm');
    name += date;
    return name;
  }
}
