import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {finalize, tap} from 'rxjs/operators';
import {PassengerBlacklistApiService} from '../../services/passenger-blacklist-api.service';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'utax-passenger-blacklist-modal',
  templateUrl: './passenger-blacklist-modal.component.html',
  styleUrls: ['./passenger-blacklist-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PassengerBlacklistModalComponent implements OnInit, AfterViewInit {
  form: UntypedFormGroup;

  typeBlockOptions: string[] = ['ONE_DAY', 'THREE_DAYS', 'FIVE_DAYS', 'WEEK', 'MONTH', 'ONE_YEAR', 'TWO_YEARS', 'THREE_YEARS'];
  public disabledApproveButton: boolean;
  constructor(
    public dialogSelf: MatDialogRef<PassengerBlacklistModalComponent>,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private passengerBlacklistApiService: PassengerBlacklistApiService,
    @Inject(MAT_DIALOG_DATA)
    public data: {phone: string}
  ) {
    this.generateForm();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  cancel(): void {
    this.dialogSelf.close();
  }

  approve(): void {
    this.disabledApproveButton = true;
    const value = this.form.value;
    const data = {
      phoneNumber: value.phoneNumber,
      blockType: value.blockType,
      comment: String(value.comment).replaceAll('/', '')
    };
    this.passengerBlacklistApiService.addPassengerInBlackList(data)
      .pipe(
        tap((resp) => this.dialogSelf.close(resp.blockingClientType)),
        finalize(() => {
          this.disabledApproveButton = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe();

  }

  generateForm(): void {
    this.form = this.fb.group({
      phoneNumber: [this.data.phone, [Validators.required, Validators.minLength(10)]],
      blockType: ['ONE_DAY', [Validators.required]],
      comment: ['', [Validators.maxLength(500)]],
    });
  }

  public pastePhone($event: any) {
    setTimeout(() => {
      this.form.get('phoneNumber').setValue($event);
      this.form.updateValueAndValidity();
    }, 50);
  }

}
