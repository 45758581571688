import { Pipe, PipeTransform } from '@angular/core';
import { getReqIcon } from '../utils/amelia-utils';
import { CabTarget } from 'src/app/interfaces/target.interface';

@Pipe({
  name: 'getReqIcon',
  pure: false
})
export class GetReqIconPipe implements PipeTransform {

  transform(req: any, targets: CabTarget[]): any {
    return getReqIcon(req.type, req.status, targets).name;
  }

}
