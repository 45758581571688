import { UntypedFormArray, ValidatorFn } from '@angular/forms';

export function brandingScalesRepeatValidator(): ValidatorFn {
  return (formArray: UntypedFormArray): { [key: string]: any } | null => {
    const brandingIds = [];
    const formArrayValue = formArray.value;

    formArrayValue.forEach(element => {
      const sameElements = formArrayValue.filter(item => {
        return (
          item.branding_type_id === element.branding_type_id &&
          item.requests_quantity === element.requests_quantity &&
          item.value === element.value
        );
      });
      if (sameElements.length > 1) {
        brandingIds.push(sameElements[0].branding_type_id);
      }
    });

    return brandingIds.length > 0 ? { scaleRepeat: { value: brandingIds } } : null;
  };
}
