import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpaceForCallwayService {
  status: boolean;
  constructor() {
    this.status = localStorage.getItem('callwaySpace') === 'true';
  }

  changeStatus() {
    this.status ? localStorage.setItem('callwaySpace', 'false') : localStorage.setItem('callwaySpace', 'true');
    this.status = !this.status;
  }
}
