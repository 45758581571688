export interface RequestHistoryModel {
  assigned_at: string;
  canceled_at: string;
  completed_at: string;
  confirm_user: { id: string; name: string };
  confirmed_at: string;
  created_at: string;
  driver_rest: DriverRestModel;
  id: string;
  is_active: boolean;
  reject_user: { id: string; name: string };
  rejected_at: string;
  status: string; // 'in_progress', 'confirmed', 'rejected', 'waiting'
  type: RequestHistoryType;
  updated_at: string;
  vehicle_refill: VehicleRefillModel;
  vehicle_washing: VehicleWashingModel;
}

export enum RequestHistoryType {
  start_driver_rest = 'start_driver_rest',
  start_vehicle_washing = 'start_vehicle_washing',
  vehicle_refill = 'vehicle_refill',
  start_working_shift = 'start_working_shift',
  finish_working_shift = 'finish_working_shift',
  cancel_driver_request = 'cancel_driver_request'
}

export interface DriverRestModel {
  address_name: string;
  duration: number;
  finish_user: { id: string; name: string };
  finished_at: string;
  id: string;
  is_active: boolean;
  start_user: { id: string; name: string };
  started_at: string;
  total_duration: number;
  type: DriverRestTypeModel;
}

interface DriverRestTypeModel {
  display_name: string;
  duration: number;
  id: string;
  limit: number;
  type: string;
}

export interface VehicleRefillModel {
  amount: number;
  created_at: string;
  filled_at: string;
  fuel_type: string;
  id: string;
  liters: number;
  mileage: number;
  updated_at: string;
}

export interface VehicleWashingModel {
  address_name: string;
  amount: number;
  assigned_at: string;
  created_at: string;
  duration: number;
  finish_user: { id: string; name: string };
  finished_at: string;
  id: string;
  is_active: boolean;
  is_apply_now: boolean;
  start_user: { id: string; name: string };
  started_at: string;
  updated_at: string;
}
