import { AmeliaService } from '../../services/amelia.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'utax-cab-washing-modal',
  templateUrl: './cab-washing-start-modal.component.html',
  styleUrls: ['./cab-washing-start-modal.component.scss']
})
export class CabWashingStartModalComponent implements OnInit {

  minutesInWaiting: number;
  assignedTime: string;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<CabWashingStartModalComponent>,
              private amelia: AmeliaService) { }

  ngOnInit() {
    console.log('CabWashingStartModalComponent', this.data);
    this.minutesInWaiting = moment().diff(moment.unix(this.data.req.createdAt.seconds), 'minutes');
    if (!this.data.req.payload.isApplyNow) {
      this.assignedTime = moment.unix(this.data.req.payload.assignedAt.seconds).format('HH:mm');
    }
  }

  close() {
    this.dialogRef.close('close');
  }

  cancel() {
    this.amelia.confirmDriverRequest(false, this.data.req.id);
    this.dialogRef.close('cancel');
  }

  confirm() {
    this.amelia.confirmDriverRequest(true, this.data.req.id);
    this.dialogRef.close('confirm');
  }
}
