import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[utaxNumbersOnlyRegex]'
})
export class NumbersOnlyRegexDirective {
  @Input() allowedSymbols = [];
  @Input() customRegExp: RegExp;

  constructor(private _el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;

    const additionalString = this.getAdditionalString(initalValue);
    this._el.nativeElement.value =
      additionalString + initalValue.replace(this.customRegExp ? this.customRegExp : /[^0-9]*/g, '');
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

  private getAdditionalString(initValue): string {
    let additionalString = '';
    if (this.allowedSymbols.length > 0) {
      const haveAllowedSymbol = this.allowedSymbols.some(symbol => symbol === initValue.charAt(0));
      additionalString = haveAllowedSymbol ? initValue.charAt(0) : '';
    }
    return additionalString;
  }
}
