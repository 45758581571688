import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {MyServicesModel} from '../models/my-services.model';

@Injectable({
  providedIn: 'root'
})
export class MyServicesApiService {

  constructor(private httpClient: HttpClient) { }

  // taxiService API
  // GET

  getTaxiServices(): Observable<MyServicesModel[]> {
    return this.httpClient.get<MyServicesModel[]>(`call-centre/api/me/taxi-services`);
  }


}
