import { Store } from '../interfaces/state.interface';
import {BehaviorSubject, Observable, of} from 'rxjs';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Service } from 'protobufjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  public store: BehaviorSubject<any> = new BehaviorSubject(null);
  dumbStore: Store = null;

  sessionId = null;

  telephonyEnterStatus$ = new BehaviorSubject(false);

  public hideCabinetOpenIcon = false;
  public wsStatus$: BehaviorSubject<{ icon: string; info: string }> = new BehaviorSubject({
    icon: 'ws-status-red',
    info: 'update page'
  });


  constructor() {
    this.store.subscribe((data: Store) => {
      this.dumbStore = data;
    });
  }

  // @TODO remove getValueFromStore and use getStoreParamSub
  getValueFromStore(key: 'service' | 'products'): Observable<Array<Service>> {
    return this.store.pipe(
      map(store => store[key]),
      filter(res => res && res.length > 0)
    );
  }

  getStoreParamSub(param: 'service' | 'user' | 'permissions' | 'products' | 'systemSetting'): Observable<any> {
    if (!this.store.getValue()) {
      return of([]);
    }
    return this.store.pipe(pluck(param));
  }

  hasCallCentrePerms(): boolean {
    return this.dumbStore && this.dumbStore?.permissions && this.dumbStore.permissions.some(
      perm =>
        perm.display_name === 'ROLE_CALL_CENTER_OPERATOR' ||
        perm.display_name === 'TAG_CALL_CENTER' ||
        perm.display_name === 'OPERATOR_CALL_CENTER_ACCEPT_CALL'
    );
  }
}
