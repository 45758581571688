import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {ReqViewOrdLiteInterface} from '../models/dispatcher';


@Injectable()
export class OrderatorsInfoService {
  constructor(private http: HttpClient) {}

  public getReqViewOrdLite(): Observable<ReqViewOrdLiteInterface[]> {
    return this.http.get<ReqViewOrdLiteInterface[]>('call-centre/api/me/requests/active');
  }

}
