import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PassengerService {

  constructor(private http: HttpClient) { }



  blockPassengers(body) {
    return this.http.post('operator/passenger/block', body);
  }

  unblockPassengers(body) {
    return this.http.post('operator/passenger/unblock', {id: body});
  }
}
