import { MessageToDriverService } from './message-to-driver.service';
import { ImageService } from './image.service';
import { WaypointsService } from './waypoints.service';
import { SpaceForCallwayService } from './space-for-callway.service';
import { CustomTranslationService } from './custom-translation.service';
import {Client} from './client';
import {VehicleService} from './vehicle.service';
import {HistoryService} from './history.service';
import {ChatApiService} from './chat-api.service';

export const services: any[] = [
  WaypointsService,
  ImageService,
  MessageToDriverService,
  SpaceForCallwayService,
  CustomTranslationService,
  Client,
  VehicleService,
  HistoryService,
  ChatApiService
];

export * from './custom-translation.service';
export * from './message-to-driver.service';
export * from './image.service';
export * from './waypoints.service';
export * from './space-for-callway.service';
export * from './ng-on-destroy';
export * from './client';
export * from './vehicle.service';
export * from './history.service';
export * from './chat-api.service';
