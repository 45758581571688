import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  DispatcherModel,
  StatusesInfoModel,
  DispRemoteWorkReq,
  PutBodyDispCallSettings,
  RemoteRequestRespModel,
  DispHistoryReqordModel,
  IBrigade,
  ICallsStatisticRealtime,
  IOperatorStatisticTaxiServiceCounters,
  FallbackStatusesInfoModel,
  IOperatorStatistic,
  IActiveTaxiServiceIds,
  IAddMultiQueueGroupQuery, IOperatorActivityResponse, ICardBilling
} from '../models';

import { QueueGroupModel } from '../../queue/models';
import {map, mergeMap, pluck, switchMap, tap} from 'rxjs/operators';
import {ITaxiService} from "../../../../models/global-data.interfaces";

const FIRST_CONTACT_SERVICE_ID = '1000050000';
const TRUSKAVETS_SERVICE_ID = '1167282226';

const callsStatisticRealtimeInfo = 'call-centre/api/call-statistic/realtime',
  operatorStatisticTaxiServiceCounters = 'call-centre/api/operator-statistic/taxi-service-counters',
  allQueues = 'call-centre/api/queue/all',
  statusesUrl = 'call-centre/api/operator-statistic/counter',
  allDispatchersUrl = 'call-centre/api/users',
  usersByIds = 'call-centre/api/operators',
  queueDispatchersUrl = 'call-centre/api/users/queue',
  dispatcherRolesUrl = 'call-centre/api/users/groups/allowed',
  dispCallSettings = 'call-centre/api/users/call-settings',
  remoteWorkDispatchers = 'call-centre/api/operators/remote-work',
  brigadesUrl = 'call-centre/api/operator-brigades',
  offRemoteWorkUrl = 'operator/remote-work/requests/revoke',
  allQueueGroupsUrl = 'call-centre/api/queue/group/all',
  dispHistyryUrl = 'call-centre/api/operators',
  allDispatchersFiltered = 'call-centre/api/operators',
  currentRemoteUsers = 'call-centre/api/users/remote/list',
  changeMultiServices = 'operator/accesses/user/services/multi',
  operatorLastActive = 'call-centre/api/mobile-operators/last-active',
  dispatcherControl = 'dispatcher/controls/dispatcher';

const fallbackStatusesUrl = 'call-centre/api/call-statistic/global-count';

@Injectable()
export class DispSettingsApiService {
  constructor(private http: HttpClient) {
  }

  // get
  getAllQueueGroups(): Observable<QueueGroupModel[]> {
    return this.http.get<QueueGroupModel[]>(allQueueGroupsUrl);
  }

  getCallsStatisticRealtimeInfo(): Observable<ICallsStatisticRealtime[]> {
    return this.http.get<ICallsStatisticRealtime[]>(callsStatisticRealtimeInfo);
  }

  getOperatorStatisticTaxiServiceCounters(): Observable<IOperatorStatisticTaxiServiceCounters[]> {
    return this.http.get<IOperatorStatisticTaxiServiceCounters[]>(operatorStatisticTaxiServiceCounters);
  }

  getAllQueue(): Observable<any[]> {
    return this.http.get<any[]>(allQueues);
  }

  getStatusesInfo(): Observable<StatusesInfoModel> {
    return this.http.get<StatusesInfoModel>(statusesUrl);
  }

  getFallbackStatusesInfo(): Observable<FallbackStatusesInfoModel> {
    return this.http.get<FallbackStatusesInfoModel>(fallbackStatusesUrl);
  }

  getAllDispatchers(limit, page): Observable<DispatcherModel[]> {
    return this.http.get<DispatcherModel[]>(allDispatchersUrl, { params: { limit, page, selectStrategy: 'ALL' } });
  }

  getDispatchersByQueueUid(limit, page, queueUid, strategy: 'ONLY_ACTIVE' | 'ALL'): Observable<DispatcherModel[]> {
    return this.http.get<DispatcherModel[]>(`${queueDispatchersUrl}/${queueUid}`, {
      params: { limit, page, selectStrategy: strategy }
    });
  }

  getAllDispatchersFiltered(limit, page, filters: any[], noActivities = false): Observable<any> {
    const filterParams = {};
    filters.map(item => {
      filterParams[item.type] = item.value;
    });
    return this.http.get<any>(allDispatchersFiltered, { params: { ...filterParams, size: limit, page } }).pipe(
      switchMap(resp => {
        if (!noActivities && resp?.content?.length) {
          return this.getOperatorActivities(resp?.content.map( el => el.externalId)).pipe(
            pluck('data'),
            map(activities => {
              resp.content = resp.content.map(el => {
                el.state = activities.find(a => a.id === el.externalId)?.state || 'none';
                return el;
              });
              return resp;
            })
          );
        } else {
          return of(resp);
        }
      }),
    );
  }

  getListOfDispRoles(): Observable<any> {
    return this.http.get(dispatcherRolesUrl);
  }

  getListOfBrigades(): Observable<IBrigade[]> {
    return this.http.get<IBrigade[]>(brigadesUrl);
  }

  getRemoteWorkDispatchers(): Observable<RemoteRequestRespModel[]> {
    return this.http.get<RemoteRequestRespModel[]>(remoteWorkDispatchers, { params: { limit: '10', page: '0' } });
  }

  getDispHistory(userUid: string, page, size): Observable<{content: DispHistoryReqordModel[]}> {
    return this.http.get<{content: DispHistoryReqordModel[]}>(`${dispHistyryUrl}/${userUid}/history`, { params: { page, size } });
  }

  getDispById(uid: string): Observable<any> {
    return this.http.get(`${usersByIds}/${uid}`);
  }

  getCurrentRemoteUsers(page, size): Observable<DispHistoryReqordModel[]> {
    return this.http.get<DispHistoryReqordModel[]>(currentRemoteUsers, { params: { page, size, sort: [] } });
  }

  // post
  createDisp(body): Observable<any> {
    return this.http.post(usersByIds, body);
  }

  // put
  updateDisp(body): Observable<any> {
    return this.http.put(`${usersByIds}/${body.id}`, body);
  }

  updateDispPassword(id: string, password: string): Observable<any> {
    return this.http.put(`${usersByIds}/${id}/password`, { password });
  }

  putGetUsersByIds(requestParams?: any): Observable<any> {
    const params = {};
    for (const key in requestParams) {
      params[key] = String(requestParams[key]);
    }
    return this.http.get(usersByIds, { params });
  }

  putUserCheckbox(id: string, operatorId: string, allow): Observable<any> {
    if (allow) {
      return this.http.post(`call-centre/api/call-lines/${id}/operators?operatorId=${operatorId}`, null);
    } else {
      return this.http.delete(`call-centre/api/call-lines/${id}/operators/${operatorId}`);
    }
  }

  putOffRemoteWork(id: {id: string} | {user_id: string}): Observable<any> {
    const params = new HttpParams({ fromObject: id });
    return this.http.post(offRemoteWorkUrl, null, {params});
  }

  putRemoteWorkReq(requestUid: string, state: DispRemoteWorkReq): Observable<any> {
    return this.http.put(`${remoteWorkDispatchers}/${requestUid}`, { state });
  }

  putDispCallSettings(body: PutBodyDispCallSettings[]): Observable<any> {
    return this.http.put(dispCallSettings, body);
  }

  putQueueGroup(id: string, operatorId: string, allow: boolean, externalGroupId: string, externalUserId: string): Observable<any> {
    if (
      !externalGroupId ||
      externalGroupId === FIRST_CONTACT_SERVICE_ID ||
      externalGroupId === TRUSKAVETS_SERVICE_ID
    ) {
      return this.http.put(`call-centre/api/taxi-services/${id}/operators/${operatorId}?isActive=${allow}`, null);
    }
    if (allow) {
      return this.http.put('operator/accesses/user/services', { user_id: externalUserId, service_id: Number(externalGroupId) })
        .pipe(
          mergeMap(() => {
            return this.http.put(`call-centre/api/taxi-services/${id}/operators/${operatorId}?isActive=${allow}`, null);
          })
        );
    } else {
      return this.http.delete('operator/accesses/user/services', {
        params: new HttpParams({ fromObject: { user_id: externalUserId, service_id: externalGroupId } })
      })
        .pipe(
          mergeMap(() => {
            return this.http.put(`call-centre/api/taxi-services/${id}/operators/${operatorId}?isActive=${allow}`, null);
          })
        );
    }
  }

  addMultiQueueGroup(user_id, service_ids: string[], query?: IAddMultiQueueGroupQuery): Observable<any> {
    if ((service_ids.length === 0 || !user_id) && !query?.override) {
      return of([]);
    }
    let body = {user_id, service_ids};
    if(query) {
      body = {...body, ...query};
    }
    return this.http.put(changeMultiServices, body);
  }

  deleteMultiQueueGroup(user_id, service_ids: string[]): Observable<any> {
    if (service_ids.length === 0 || !user_id) {
      return of([]);
    }
    return this.http.delete(changeMultiServices, { params: { user_id, 'service_ids[]': service_ids } });
  }

  public getMobileOperatorLastActive(): Observable<IOperatorStatistic[]> {
    return this.http.get<IOperatorStatistic[]>(operatorLastActive);
  }

  public activateMoreService(operatorId: string, services: string[]): Observable<ITaxiService[]> {
    return this.http.put<ITaxiService[]>(`${allDispatchersFiltered}/${operatorId}/taxi-services/multi-set`, {activeTaxiServiceIds: services });
  }

  public activateOnlyOneService(id: string, operatorId: string): Observable<void> {
    return this.http.put<void>(`call-centre/api/taxi-services/${id}/operators/${operatorId}/single-active`, null);
  }

  public getOperatorActivities(ids: string[]): Observable<IOperatorActivityResponse> {
    return this.http.post<IOperatorActivityResponse>('operator/call-center/operators/activities', { ids });
  }

  public getDispatcherCards(id: string): Observable<ICardBilling[]> {
    return this.http.get<ICardBilling[]>(`${dispatcherControl}/${id}/cards`);
  }
  public clearDispatcherCards(id: string): Observable<any> {
    return this.http.post<any>(`${dispatcherControl}/${id}/cards/clear`, null);
  }

  public clearDispatcherScaleCalls(id: string): Observable<any> {
    return this.http.post<any>(`${usersByIds}/${id}/tariff-plan/reset-salary-counter`, null);
  }

}
