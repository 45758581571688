import { Component, EventEmitter, Output, Input } from '@angular/core';
import { RolesService } from '../../services/roles.service';
import { Role } from 'src/app/users/models/user.interface';

@Component({
  selector: 'utax-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.scss']
})
export class RolesListComponent {
  @Input() roles;
  displayedColumns: string[] = ['display_name', 'description'];
  @Output() doubleClick: EventEmitter<Role> = new EventEmitter();
  constructor(public rolesService: RolesService) {}

  selectRole(event: Role): void {
    this.rolesService.selectedRole = event;
  }

  onDoubleClick(role: Role): void {
    this.doubleClick.emit(role);
  }
}
