import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {UserModelNew} from '../models';

@Injectable()
export class UserApiService {

  public user$: BehaviorSubject<UserModelNew> = new BehaviorSubject<UserModelNew>(null);

  constructor(private httpClient: HttpClient) {
  }

  // User API
  // GET

  getWhoAmI(): Observable<UserModelNew> {
    return this.httpClient.get<UserModelNew>(`call-centre/api/me/whoami`);
  }

  syncGlobalPhoneNumber(): Observable<void> {
    return this.httpClient.post<void>('call-centre/api/me/sync-global-phone-number', null);
  }
}
