import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AmeliaService } from './../../services/amelia.service';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'utax-cab-refill-modal',
  templateUrl: './cab-refill-modal.component.html',
  styleUrls: ['./cab-refill-modal.component.scss']
})
export class CabRefillModalComponent implements OnInit {
  minutesInWaiting;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<CabRefillModalComponent>,
              private amelia: AmeliaService) { }

  ngOnInit() {
    this.minutesInWaiting = moment().diff(moment.unix(this.data.req.createdAt.seconds), 'minutes');
  }

  cancel() {
    this.amelia.confirmDriverRequest(false, this.data.req.id);
    this.dialogRef.close('cancel');
  }

  confirm() {
    this.amelia.confirmDriverRequest(true, this.data.req.id);
    this.dialogRef.close('confirm');
  }
}
