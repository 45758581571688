import { NotificationsService } from './../../../services/notifications.service';
import { DispOrdersService } from './../../services/disp-orders.service';
import { Component, Input, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { TimezoneService } from 'src/app/services';
import {OrderClass} from '@global-classes/order.class';

@Component({
  selector: 'utax-dispatcher-order-item',
  templateUrl: './dispatcher-order-item.component.html',
  styleUrls: ['./dispatcher-order-item.component.scss', '../../columns.scss']
})
export class DispatcherOrderItemComponent implements OnChanges {
  @Input() order: OrderClass;
  olderThen24h: boolean;

  notificationIcons = {
    sms: {
      pending: 'sms_notification_in_progress',
      processing: 'sms_notification_in_progress',
      waiting: 'sms_notification_in_progress',
      error: 'sms_notification_error',
      canceled: 'sms_notification_error',
      sent: 'sms_notification_sended'
    },
    fcm: {
      pending: 'fcm_notification_in_progress',
      processing: 'fcm_notification_in_progress',
      waiting: 'fcm_notification_in_progress',
      error: 'fcm_notification_error',
      canceled: 'fcm_notification_error',
      sent: 'fcm_notification_sended'
    },
    phone: {
      pending: 'phone_notification_pending',
      processing: 'phone_notification_pending',
      waiting: 'phone_notification_waiting',
      undelivered: 'phone_notification_undelivered',
      error: 'phone_notification_error',
      canceled: 'phone_notification_error',
      sent: 'phone_notification_sent'
    },
    call: {
      pending: 'phone_notification_pending',
      processing: 'phone_notification_pending',
      waiting: 'phone_notification_waiting',
      undelivered: 'phone_notification_undelivered',
      error: 'phone_notification_error',
      canceled: 'phone_notification_error',
      sent: 'phone_notification_sent'
    }
  };

  constructor(
    public dispOrdersService: DispOrdersService,
    public notificationsService: NotificationsService,
    private tzService: TimezoneService
  ) {}

  ngOnChanges() {
    const ago24h = this.tzService.tzToUtc(moment()).subtract(1, 'd');
    this.olderThen24h = moment(this.order.assigned_at).isBefore(ago24h);
  }

  selectOrder() {
    this.dispOrdersService.selectOrder(this.order);
    console.log('PC: Dispatcher -> selectOrder', this.order);
  }

  editOrder() {
    this.dispOrdersService.editOrder();
  }

  hideCallWaySelection(id: string): void {
    this.dispOrdersService.hideCallWaySelection(id);
  }

  isCallWayOrd(id: string): boolean {
    return this.dispOrdersService.callWayOrderIds.some(item => item === id);
  }
}
