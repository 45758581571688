import {PassengerBlacklistTableComponent} from './passenger-blacklist-table/passenger-blacklist-table.component';
import {PassengerBlacklistFilterComponent} from './passenger-blacklist-filter/passenger-blacklist-filter.component';
import {PassengerBlacklistModalComponent} from "./passenger-blacklist-modal/passenger-blacklist-modal.component";
import {
  PassengerBlcklistUnlockModalComponent
} from "./passenger-blcklist-unlock-modal/passenger-blcklist-unlock-modal.component";

export const components: any[] = [
  PassengerBlacklistTableComponent,
  PassengerBlacklistFilterComponent,
  PassengerBlacklistModalComponent,
  PassengerBlcklistUnlockModalComponent,

];

export * from './passenger-blacklist-table/passenger-blacklist-table.component';
export * from './passenger-blacklist-filter/passenger-blacklist-filter.component';
export * from './passenger-blacklist-modal/passenger-blacklist-modal.component';
export * from './passenger-blcklist-unlock-modal/passenger-blcklist-unlock-modal.component';
