import { OrderFormService, OrderModalService } from 'src/app/order-form/services';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { DispOrdersService } from './../../services/disp-orders.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Order } from 'src/app/interfaces/order.interface';
import {Component, Input, AfterViewInit, OnDestroy, ChangeDetectorRef, OnInit} from '@angular/core';
import { HandleEventService } from 'src/app/services/handle-event-service.service';
import { OrdersService } from 'src/app/services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CallDriverModalComponent } from '../call-driver-modal/call-driver-modal.component';
import {switchMap, takeUntil} from 'rxjs/operators';
import {Observable, of, Subject} from 'rxjs';
import { CallWayService } from '../../services';
import { TelephonySharedService } from 'src/app/telephony/services/telephony-shared.service';
import { TelephonyService } from 'src/app/telephony/services';
import {GlobalDataService} from '@global-services/global-data.service';
import {OrderClass} from '@global-classes/order.class';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'utax-dispatcher-details-toolbar',
  templateUrl: './dispatcher-details-toolbar.component.html',
  styleUrls: ['./dispatcher-details-toolbar.component.scss']
})
export class DispatcherDetailsToolbarComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() order: OrderClass;

  shortcuts$: Observable<ShortcutInput[]>;
  extendTimeStatus = false;
  extendCostStatus = false;

  private dialogRef: MatDialogRef<CallDriverModalComponent>;
  private componentDestroyed$ = new Subject();

  constructor(
    public sanitizer: DomSanitizer,
    private dispOrdersService: DispOrdersService,
    public orderFormService: OrderFormService,
    public orderModalService: OrderModalService,
    private handleEventService: HandleEventService,
    private ordersService: OrdersService,
    private dialog: MatDialog,
    private callWayService: CallWayService,
    private telephonyService: TelephonyService,
    private telephonySharedService: TelephonySharedService,
    public globalDataService: GlobalDataService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.shortcutHelper();
  }
  ngAfterViewInit(): void { }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  editOrder(): void {
    this.dispOrdersService.editOrder();
  }

  extendTime(): void {
    this.extendTimeStatus = !this.extendTimeStatus;
    // this.openTimeWindow = true;
  }

  extendCost() {
    const currentOrder = this.ordersService.currentOrders.get(this.order.id);
    if (currentOrder.lock_mode === 'default') {
      const translation = this.translate.instant('UTAX_ORDER_COST_ALREADY_VIEWED', {name: currentOrder.locked_user.name});
      this.handleEventService.openSnackBar(translation);
    } else if (currentOrder.lock_mode === 'normal') {
      const translation = this.translate.instant('UTAX_ORDER_COST_ALREADY_EDITED', {name: currentOrder.locked_user.name});
      this.handleEventService.openSnackBar(translation);
    } else {
      this.orderModalService.lock({ id: this.order.id, mode: 'normal' }).subscribe(() => {
        this.extendCostStatus = !this.extendCostStatus;
      });
    }
  }

  closeTimeWindow(): void {
    this.extendTimeStatus = false;
  }

  closeCostWindow(event) {
    if (event) {
      this.handleEventService.openSnackBar('UTAX_FRONT_ORDER_SAVED');
    }
    this.orderModalService.unlock(this.order.id).subscribe(() => {
      this.order.is_locked = false;
      this.order.locked_user = null;
      this.ordersService.updateOrders([this.order], true);
    });
    this.orderModalService.mode = null;
    this.extendCostStatus = false;
    this.orderFormService.orderFormOpened = false;
    this.orderFormService.fare = null;
    this.orderFormService.order = undefined;
    this.orderFormService.completedRequestsCount = undefined;
    this.orderFormService.bonusBalance = undefined;
    this.orderFormService.addedValue = 0;
  }

  removeOrder(): void {
    this.dispOrdersService.removeOrder();
  }

  assignCab(): void {
    this.dispOrdersService.assignCab();
  }

  completeOrder(): void {
    this.dispOrdersService.completeOrder();
  }

  unassignCab(): void {
    this.dispOrdersService.unassignCab();
  }

  callDriver(): void {
    if (this.order && this.order.cab && this.order.cab.driver) {
      if (this.order.cab.driver.phone_number_list.length > 1) {
        // choose driver phone number
        this.dialogRef = this.dialog.open(CallDriverModalComponent, {
          data: this.order.cab.driver.phone_number_list,
          panelClass: 'call-driver-modal-container'
        });
        this.dialogRef
          .afterClosed()
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(ansv => {
            if (ansv) {
              this.callDriverNumber(ansv);
            }
          });
      } else {
        this.callDriverNumber(this.order.cab.driver.phone_number);
      }
    }
  }

  callPassenger(): void {
    if (this.order) {
      if (this.callWayService.callWaySocketConnected && this.callWayService.useCallWay) {
        (this.order.id) ? window.open('sip:*200*' + this.order.passenger.phone_number + '*' + this.order.id, '_self') : window.open('sip:*200*' + this.order.passenger.phone_number, '_self');
      } else {
        this.telephonySharedService.makeCall(this.order.passenger.phone_number, {
          ...this.order.passenger,
          serviceId: this.order.service_id,
          orderId: this.order.id
        });
      }
    }
  }

  callDriverNumber(phone): void {
    if (this.callWayService.callWaySocketConnected && this.callWayService.useCallWay) {
      (this.order.id) ? window.open('sip:*200*' + phone + '*' + this.order.id, '_self') : window.open('sip:*200*' + phone, '_self')
    } else {
      this.telephonySharedService.makeCall(phone, {
        phone_number: phone,
        name: `${this.order.cab.driver.first_name} ${this.order.cab.driver.last_name} ${this.order.cab.driver.callsign}`,
        comment: null,
        serviceId: this.order.service_id,
        orderId: this.order.id
      });
    }
  }

  public shortcutHelper(): void {
    this.shortcuts$ = this.globalDataService.isNewKeyboardLayout$
      .pipe(
        switchMap(isNewKeyboardLayout => {
          if (isNewKeyboardLayout) {
            return of([
              {
                key: ['del'],
                command: () => {
                  this.removeOrder();
                },
                preventDefault: true
              },
              {
                key: ['home'],
                command: () => {
                  this.callPassenger();
                },
                preventDefault: true
              },
              {
                key: ['f10'],
                command: () => {
                  this.callDriver();
                },
                preventDefault: true
              },
              {
                key: ['f6'],
                command: () => {
                  if (this.order?.status === 'processing' && !this.ordersService.modalOpened) {
                    this.extendCost();
                  }
                },
                preventDefault: true
              },
              {
                key: ['end'],
                command: () => {
                  this.editOrder();
                },
                preventDefault: true
              }
            ]);
          } else {
            return of([
              {
                key: ['del'],
                command: () => {
                  this.removeOrder();
                },
                preventDefault: true
              },
              {
                key: ['f1'],
                command: () => {
                  this.callPassenger();
                },
                preventDefault: true
              },
              {
                key: ['f3'],
                command: () => {
                    this.callDriver();
                },
                preventDefault: true
              },
              {
                key: ['f4'],
                command: () => {
                  this.extendTime();
                },
                preventDefault: true
              },
              {
                key: ['f6'],
                command: () => {
                  if (this.order?.status === 'processing' && !this.ordersService.modalOpened) {
                    this.extendCost();
                  }
                },
                preventDefault: true
              },
              {
                key: ['end'],
                command: () => {
                  this.editOrder();
                },
                preventDefault: true
              }
            ]);
          }
        })
      );
  }
}
