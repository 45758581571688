import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import {GlobalDataService} from '@global-services/global-data.service';

@Component({
  selector: 'utax-disp-settings-edit-history',
  templateUrl: './disp-settings-edit-history.component.html',
  styleUrls: ['./disp-settings-edit-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispSettingsEditHistoryComponent {
  @Input('history')
  set setHistory(history) {
    if (history) {
      console.log(
        '🚀 ~ file: disp-settings-edit-history.component.ts ~ line 13 ~ DispSettingsEditHistoryComponent ~ setsetHistory ~ history',
        history
      );
      this.history = (history as any[]).sort((a, b) => {
        return b.createdAt - a.createdAt;
      }).map(item => {
        return {
          ...item,
          createdAt: moment(item.createdAt).format('HH:mm DD.MM.yyyy')
        };
      });
    } else {
      this.history = [];
    }
  }

  @Output() scroll = new EventEmitter();

  history = [];

  constructor(
    private globalDataService: GlobalDataService
  ) {}

  getService(id: string): any {
    return this.globalDataService.getService(id);
  }

  getCallLine(id: string): any {
    return this.globalDataService.getCallLine(id);
  }

  scrolled(): void {
    this.scroll.emit();
  }
}
