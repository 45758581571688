import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { QueueService } from '../../services';
import { QueueTableMode } from '../../models';

@Component({
  selector: 'utax-queue-table',
  templateUrl: './queue-table.component.html',
  styleUrls: ['./queue-table.component.scss', '../columns.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueueTableComponent implements OnInit {

  readonly queueTableMode = QueueTableMode;

  constructor(public queueService: QueueService) {}

  ngOnInit(): void {}
}
