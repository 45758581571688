import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../utils/material.module';
import {Injector, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import {ClipboardModule} from 'ngx-clipboard';

import * as fromComponents from './components';
import * as fromPipes from './pipes';
import * as fromDirectives from './directives';
import { TimerPipe } from './pipes/timer.pipe';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgPipesModule} from 'ngx-pipes';
import {ImageCropperModule} from "ngx-image-cropper";
import {NgxSpinnerModule} from "ngx-spinner";
import { ChatComponent } from './components/chat/chat.component';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import {LottieComponent} from "ngx-lottie";
import { BlockPassengerReasonComponent } from './components/modals/block-passenger-reason/block-passenger-reason.component';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import { PassengerDebtCancelModalComponent } from './components/modals/passenger-debt-cancel-modal/passenger-debt-cancel-modal.component';
import {MtxPopoverModule} from "@ng-matero/extensions/popover";

@NgModule({
  declarations: [...fromComponents.components, ...fromPipes.pipes, ...fromDirectives.directives, TimerPipe, ChatComponent, ChatMessageComponent, BlockPassengerReasonComponent, PassengerDebtCancelModalComponent],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        KeyboardShortcutsModule,
        ClipboardModule,
        InfiniteScrollModule,
        NgPipesModule,
        ImageCropperModule,
        NgxSpinnerModule,
        NgxMatSelectSearchModule,
        LottieComponent,
        MtxPopoverModule
    ],
  exports: [
    ...fromComponents.components,
    ...fromPipes.pipes,
    ...fromDirectives.directives,
    TimerPipe,
    NgPipesModule,
    ImageCropperModule,
    ChatComponent
  ],
  providers: []
})
export class SharedModule {
  static injector: Injector;
  constructor(injector: Injector) {
    SharedModule.injector = injector;
  }
}
