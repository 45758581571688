import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'utax-cab-request-modal',
  templateUrl: './cab-request-modal.component.html',
  styleUrls: ['./cab-request-modal.component.scss']
})
export class CabRequestModalComponent implements OnInit, OnDestroy {
  img: any;
  private componentDestroyed$ = new Subject();
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CabRequestModalComponent>
  ) {}

  ngOnInit() {
    this.data.img.pipe(takeUntil(this.componentDestroyed$)).subscribe(data => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        this.img = reader.result;
      };
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  send() {
    this.dialogRef.close('CLODED ');
  }

  close() {
    this.dialogRef.close('CLODED');
  }

  cancel() {
    this.dialogRef.close('CLODED');
  }

  confirm() {
    this.dialogRef.close('CLODED');
  }
}
