import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
  name: 'curr'
} )

export class ChangePlaceCurrencyPipe implements PipeTransform {

  transform( item: string | number, args?: any ): string {
    const amoung = parseInt( item.toString() );
    const currency = args == 'UAH' ? '₴' : args;
    return amoung + currency;
  }
}
