import { Component, Input, OnChanges } from "@angular/core";
import { StateService } from '../../../services/state.service';

@Component({
  selector: "utax-taxi-service-name",
  templateUrl: "./taxi-service-name.component.html",
  styleUrls: ["./taxi-service-name.component.scss"]
})
export class TaxiServiceNameComponent implements OnChanges {
  @Input() serviceId;
   service;

  constructor(public stateService: StateService) {}

  ngOnChanges(): void {
    this.stateService.store.subscribe(store => {
      this.service = store.service.find(
        service => service.id == this.serviceId
      );
    });
  }
}
