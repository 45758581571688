import { Injectable } from '@angular/core';
import { merge, interval, EMPTY, Observable, of } from 'rxjs';
import { throttleTime, mergeMap, pluck, filter, map } from 'rxjs/operators';
import { bearing, decode } from '../map/utils/map';
import { HttpParams, HttpClient } from '@angular/common/http';

const cabPolyUrl = 'operator/cab/polyline';

@Injectable({
  providedIn: 'root'
})
export class DriverTrackingService {
  constructor(private http: HttpClient) {}

  getCabGeoInfo(cab, startDate, trigger: Observable<any> = of({})): Observable<{ polyline: string; location: any }> {
    console.log('getCabGeoInfo cab, startDate', cab, cab.id, startDate);

    return merge(trigger, interval(15000)).pipe(
      throttleTime(5000),
      mergeMap(() => {
        return this.getCabLocation(cab.id, startDate);
      }),
      pluck('polyline'),
      filter(poly => !!poly),
      map((poly: string) => {
        cab.polyline = poly;
        try {
          const decodedPoly = decode(poly);
          cab.location = {
            lat: decodedPoly[decodedPoly.length - 1][0],
            lng: decodedPoly[decodedPoly.length - 1][1]
          };
          if (decodedPoly.length > 1) {
            cab.bearing = bearing(
              decodedPoly[decodedPoly.length - 2][0],
              decodedPoly[decodedPoly.length - 2][1],
              decodedPoly[decodedPoly.length - 1][0],
              decodedPoly[decodedPoly.length - 1][1]
            );
          }
        } catch (err) {
          console.log(err);
          cab = null;
        }
        return cab;
      })
    );
  }

  private getCabLocation(cabId, startDate) {
    return cabId
      ? this.http.get(cabPolyUrl, {
          params: new HttpParams({
            fromObject: {
              id: cabId,
              start_date: startDate // this.order.assigned_at
            }
          })
        })
      : EMPTY;
  }
}
