import { Component, OnInit, Optional, Inject, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Queue } from '../../models/dispatcher';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CallWayService } from '../../services';
import { HandleEventService } from 'src/app/services';
import { TelephonyService } from 'src/app/telephony/services';
import {GlobalDataService} from '@global-services/global-data.service';

@Component({
  selector: 'utax-transfer-queue-modal',
  templateUrl: './transfer-queue-modal.component.html',
  styleUrls: ['./transfer-queue-modal.component.scss']
})
export class TransferQueueModalComponent implements OnInit, AfterViewInit, OnDestroy {
  form: UntypedFormGroup;
  queues: any[] = [];
  shortcuts: ShortcutInput[] = [];

  selectedQueue: any;
  fileteredQueues: Queue[] = [];
  staticQueues: Queue[] = [];

  callInAction = false;
  currentQueue: any;
  currentSpecialQueue: any;


  private componentDestroyed$ = new Subject();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { showHelpInfo: boolean; queues: any; permanentalOpen: boolean; transferQueueIds: any },
    public dialogRef: MatDialogRef<TransferQueueModalComponent>,
    private fb: UntypedFormBuilder,
    private callWayService: CallWayService,
    private handleEventService: HandleEventService,
    private telephonyService: TelephonyService,
    public globalDataService: GlobalDataService
  ) {
    this.generateForm();
  }

  ngOnInit(): void {
    this.form.controls.search.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(searchValue => {
      console.log('search Value', searchValue);
      this.filterQueues(searchValue);
    });

    this.queues = [...this.data.queues.filter(queue => queue.transferId !== null)];
    console.log(this.data.queues);
    console.log(this.queues);
    console.log('------------------');
    this.queues.unshift({
      name: 'Водій',
      type: 'driver'
    });
    this.queues.unshift({
      name: 'Перезвон',
      transferId: '101'
    });
    this.queues.push({
      name: 'Бюджет',
      type: 'company'
    });
    // this.fileteredQueues = [...this.queues];
    this.fileteredQueues = [];
    this.queues.forEach(queue => {
      if (!queue.taxiServiceId || queue.isSpecial || queue.type || !this.fileteredQueues.some(item => item?.taxiServiceId && item.taxiServiceId === queue.taxiServiceId)) {
        if (!queue.isSpecial && queue.taxiServiceId) {
          queue.nameService = this.globalDataService.globalTaxiServices$.value.find(item => item.id === queue.taxiServiceId)?.displayName;
        }
        this.fileteredQueues.push(queue);
      }
    });
    this.fileteredQueues = this.sortQueues(this.fileteredQueues);
    console.log(this.fileteredQueues);
    this.staticQueues = [...this.fileteredQueues];

    this.selectQueue(this.queues[0], 0);

    if (this.data.permanentalOpen) {
      this.callWayService.call$.pipe(takeUntil(this.componentDestroyed$)).subscribe((call: any) => {
        if (call && this.data.transferQueueIds.some(item => item === call.queue)) {
          this.callInAction = true;
          this.currentQueue = call.queue;
        }
      });

      this.telephonyService.sessions$.pipe(takeUntil(this.componentDestroyed$)).subscribe(sessions => {
        this.callInAction = Object.values(sessions).some((session: any) => session.callInProgress);
        this.currentQueue = (Object.values(sessions).find((session: any) => session.callInProgress) as any);

        if (this.queues.find( item => item.isSpecial && item.mappingId === this.currentQueue?.incomingPassOrDriver?.serviceId)) {
          this.currentSpecialQueue = this.queues.find( item => item.mappingId === this.currentQueue?.incomingPassOrDriver?.prevQueue);
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: ['f9'],
        command: () => {
          this.changeQueue();
        },
        allowIn: [AllowIn.Input, AllowIn.Select],
        preventDefault: true
      },
      {
        key: ['down'],
        command: () => {
          this.changeSelectedQueue('down');
        },
        allowIn: [AllowIn.Input, AllowIn.Select],
        preventDefault: true
      },
      {
        key: ['up'],
        command: () => {
          this.changeSelectedQueue('up');
        },
        allowIn: [AllowIn.Input, AllowIn.Select],
        preventDefault: true
      },
      {
        key: ['enter'],
        command: () => {
          this.changeQueue();
        },
        allowIn: [AllowIn.Input, AllowIn.Select],
        preventDefault: true
      }
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  changeQueue(): void {
    if (this.selectedQueue.transferId) {
      if (this.data.permanentalOpen) {
        this.transferCall();
        this.callInAction = false;
      }
      this.dialogRef.close(this.selectedQueue);
    } else {
      return;
    }

  }

  selectQueue(item: Queue, i: number): void {
    const queueTrue = this.queueSelectHelper(item);
    this.selectedQueue = {
      ...queueTrue,
      index: i
    };
  }

  sendDirectly(queue, i: number): void {
    const queueTrue = this.queueSelectHelper(queue);
    this.selectedQueue = {
      ...queueTrue,
      index: i
    };
    this.changeQueue();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  private transferCall() {
    if (this.callWayService.callWaySocketConnected && this.callWayService.useCallWay) {
      this.callWayService.callWaySocket$.next({
        cmd: 'call_transfer',
        queue: this.selectedQueue.taxiServiceId
      });
    } else {
      // @TODO change first on sessionId in progress
      this.telephonyService.transferCall('first', this.selectedQueue);
    }
  }

  private changeSelectedQueue(direction: 'down' | 'up'): void {
    if (direction === 'down') {
      const index =
        this.selectedQueue.index + 1 >= this.fileteredQueues.length
          ? this.selectedQueue.index
          : this.selectedQueue.index + 1;
      this.selectQueue(this.fileteredQueues[index], index);
    } else {
      const index = this.selectedQueue.index - 1 < 0 ? 0 : this.selectedQueue.index - 1;
      this.selectQueue(this.fileteredQueues[index], index);
    }
  }

  private filterQueues(searchString: string): void {
    this.fileteredQueues = this.staticQueues.filter(queueitem => {
      return queueitem?.nameService ?  queueitem?.nameService?.toUpperCase().indexOf(searchString.toUpperCase()) > -1 : queueitem?.name?.toUpperCase().indexOf(searchString.toUpperCase()) > -1 ;
    });
    this.selectQueue(this.fileteredQueues[0], 0);
  }

  private sortQueues(queues: Queue[]): Queue[] {
    const result: Queue[] = [];
    const firstElement = queues.find(item => item?.transferId === '101');
    const secondElement = queues.find(item => item?.type === 'driver');
    const lastElement = queues.find(item => item?.type === 'company');
    const lastTwoElement = queues.find(item => item?.isSpecial);
    const lastThreeElement = queues.reverse().find(item => item?.isSpecial);
    const filteredQueues = queues
      .filter(item => item?.transferId !== '101' && item?.type !== 'driver' && item?.type !== 'company' && !item?.isSpecial)
      .sort((a, b) => a?.nameService.localeCompare(b?.nameService));
    if (firstElement) {
      result.push(firstElement);
    }
    if (secondElement) {
      result.push(secondElement);
    }
    if (filteredQueues.length > 0) {
      result.push(...filteredQueues);
    }
    if (lastTwoElement) {
      result.push(lastTwoElement);
    }
    if (lastThreeElement) {
      result.push(lastThreeElement);
    }
    if (lastElement) {
      result.push(lastElement);
    }
    return result;
  }

  private queueSelectHelper(queue: Queue): Queue {
    let queueTrue;
    if (!queue?.taxiServiceId && !queue?.type || queue?.isSpecial) {
      queueTrue = queue;
    } else if (queue?.type) {

      const actualQueue = this.currentSpecialQueue ? this.currentSpecialQueue : this.queues.find(item => item.mappingId === this.currentQueue?.incomingPassOrDriver?.serviceId);
      const actualCallLineType = this.globalDataService.globalCallLines$.value.find(l => l.id === actualQueue?.callLineId)?.type;
      if (actualQueue && (actualCallLineType  === 'INCOMING_FROM_PASSENGER' || actualCallLineType  === 'INCOMING_FROM_NEW_CLIENTS')) {
        const hasActiveReq = false;
        const taxiServiceId = actualQueue?.taxiServiceId;
        const cllLineId = queue.type === 'driver' ?
          this.globalDataService.globalCallLines$.value.find(l => l.type === 'INCOMING_FROM_DRIVERS')?.id :
          this.globalDataService.globalCallLines$.value.find(l => l.type === 'INCOMING_FROM_COMPANY_DRIVERS')?.id;
        queueTrue = this.queues.find(item => item.taxiServiceId === taxiServiceId && hasActiveReq === item.hasActiveRequest && cllLineId === item.callLineId);
      } else {
        return;
      }
    } else {
      const actualQueue = this.currentSpecialQueue ? this.currentSpecialQueue : this.queues.find(item => item.mappingId === this.currentQueue?.incomingPassOrDriver?.serviceId);
      if (!actualQueue) {
        queueTrue = this.queues.find(item => {
          return item.taxiServiceId === queue.taxiServiceId &&
            this.globalDataService.globalCallLines$.value.find(l => l.id === item.callLineId)?.type === 'INCOMING_FROM_NEW_CLIENTS';
        });
      } else {
        const hasActiveReq = actualQueue?.hasActiveRequest;
        const taxiServiceId = queue.taxiServiceId;
        const cllLineId = actualQueue?.callLineId;
        queueTrue = this.queues.find(item => item.taxiServiceId === taxiServiceId && hasActiveReq === item.hasActiveRequest && cllLineId === item.callLineId);
      }
    }
    return queueTrue;
  }

  private generateForm() {
    this.form = this.fb.group({
      search: ['']
    });
  }

  @HostListener('window:keydown', ['$event'])
  hotkeyDown($event) {
    if ($event.keyCode === 38) {
      this.changeSelectedQueue('up'); // arrow up
      $event.preventDefault();
    }
    if ($event.keyCode === 40) {
      // arrow down
      this.changeSelectedQueue('down');
      $event.preventDefault();
    }
    if ($event.keyCode === 27) {
      // esc
      if (this.form.value.search) {
        this.form.controls.search.patchValue('');
      } else {
        this.closeModal();
      }
    }
  }
}
