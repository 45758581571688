import { HandleEventService } from './handle-event-service.service';
import { CabsApiService } from './cabs-api.service';
import { NotificationsService } from './notifications.service';
import { TaxiServicesService } from './taxi-services.service';
import { HistoryService } from './history.service';
import { AddressService } from './address.service';
import { WebsocketService } from './ws.service';
import { StateService } from 'src/app/services/state.service';
import { RmService } from './rm.service';
import { OrdersService } from './orders.service';
import { TimezoneService } from './timezone.service';
import { PaymentService } from './payment.service';
import { DriverTrackingService } from './driver-tracking.service';
import { PhoneNumberService } from './phone-number.service';

export const services: any[] = [
    OrdersService,
    RmService,
    StateService,
    WebsocketService,
    TimezoneService,
    AddressService,
    HistoryService,
    TaxiServicesService,
    NotificationsService,
    CabsApiService,
    PaymentService,
    DriverTrackingService,
    HandleEventService,
    PhoneNumberService,
];

export * from './cabs-api.service';
export * from './notifications.service';
export * from './taxi-services.service';
export * from './history.service';
export * from './address.service';
export * from './orders.service';
export * from './rm.service';
export * from './state.service';
export * from './ws.service';
export * from './timezone.service';
export * from './taxi-services.service';
export * from './payment.service';
export * from './driver-tracking.service';
export * from './handle-event-service.service';
export * from './phone-number.service';
