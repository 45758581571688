import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) { }

  transform(value: any, pattern: string = 'mediumDate', utc: boolean = false) {
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);

    if (utc) {
      return datePipe.transform(moment.utc(value).local().toDate(), pattern);
    } else {
      return datePipe.transform(value, pattern);
    }
  }
}
