import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from '../shared/components';
import { SpaceForCallwayService } from '../shared/services';
import { Router } from '@angular/router';
import {GlobalDataService} from "@global-services/global-data.service";

@Injectable({
  providedIn: 'root'
})
export class HandleEventService {
  public audio = {};
  constructor(
    private snackBar: MatSnackBar,
    private spaceForCallwayService: SpaceForCallwayService,
    private router: Router,
    private globalDataService: GlobalDataService
  ) {}

  openSnackBar(message: any, duractionInSeconds?: number, isError?: boolean) {
    let snackClass;
    this.spaceForCallwayService.status && this.router.url === '/dashboard'
      ? (snackClass = 'snackbar-with-callway')
      : (snackClass = 'snackbar');
    if (isError) {
      snackClass = 'snackbar-error';
    }
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        message
      },
      duration: (duractionInSeconds ? duractionInSeconds : 3) * 1000,
      verticalPosition: 'top',
      panelClass: [snackClass]
    });
  }
  closeSnackBar() {
    this.snackBar.dismiss();
  }

  makeNotifiSound(type: 'amelia' | 'telephony-income' | 'telephony-outcome', id: string) {
    switch (type) {
      case 'amelia':
        if (localStorage.getItem('logistSound') === 'on' || localStorage.getItem('logistSound') === null) {
          this.audio[id] = new Audio();
          this.audio[id].src = '../../../assets/sounds/notifications/amelia_notifi.mp3';
          this.audio[id].load();
          this.audio[id].play();
        }
        break;

      case 'telephony-income':
        if (!this.globalDataService.INCOME_CALL_AUDIO) {
          this.globalDataService.INCOME_CALL_AUDIO = new Audio('../../../assets/sounds/notifications/income-call.wav');
          this.globalDataService.INCOME_CALL_AUDIO.preload = 'auto';
          this.globalDataService.INCOME_CALL_AUDIO.load();
        }
        this.audio[id] = this.globalDataService.INCOME_CALL_AUDIO;
        // this.audio[id].src = '../../../assets/sounds/notifications/income-call.wav';
        // this.audio[id].load();
        document.body.dispatchEvent(new MouseEvent('click'));
        this.audio[id].loop = true;
        this.audio[id].play();
        break;

      case 'telephony-outcome':
        this.audio[id] = new Audio();
        this.audio[id].src = '../../../assets/sounds/notifications/long-outcome-call.mp3';
        this.audio[id].load();
        this.audio[id].loop = true;
        this.audio[id].play();
        break;

      default:
        break;
    }
  }

  pauseSound(id: string): void {
    if (!this.audio[id].paused) {
      this.audio[id].pause();
    }
  }
}
