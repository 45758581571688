import { Pipe, PipeTransform } from '@angular/core';
import { RequestHistoryModel } from '../models/requests-history.model';

@Pipe({
  name: 'wshReqProcessingType'
})
export class WshReqProcessingTypePipe implements PipeTransform {
  transform(record: RequestHistoryModel): { time: string; type: string } {
    const resp = {
      time: '',
      type: ''
    };
    if (record.confirmed_at) {
      resp.time = record.confirmed_at;
      resp.type = 'confirm';
    } else if (record.rejected_at) {
      resp.time = record.rejected_at;
      resp.type = 'reject';
    } else if (record.canceled_at) {
      resp.time = record.canceled_at;
      resp.type = 'cancel';
    }
    return resp;
  }
}
