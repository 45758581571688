import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ImageService } from 'src/app/shared/services';
import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'utax-amelia-img',
  templateUrl: './amelia-img.component.html',
  styleUrls: ['./amelia-img.component.scss']
})
export class AmeliaImgComponent implements OnInit, OnChanges {
  @Input() img;
  @Input() width: number;
  @Input() height: number;

  imgUrl;

  constructor(public imageService: ImageService,
              private dialog: MatDialog,
              private sanitizer: DomSanitizer) { }

  ngOnInit() { }

  ngOnChanges() {
    this.imageService.getAmeliaImageById(this.img.id).subscribe(data => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        this.imgUrl = reader.result;
        this.imgUrl = this.sanitizer.bypassSecurityTrustUrl(this.imgUrl);
      };
    });
  }


  fullScreenImg() {
    // this.dialog.open(ShowImageDialogComponent, <MatDialogConfig>{
    //   data: { headerTemplate: 'QUESTION_ASSIGN', img: this.img }
    // });
  }
}
