import { mergeMap, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CabsApiService } from './../../../../services/cabs-api.service';
import { Service } from './../../../../interfaces/order.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { of, forkJoin, Subject } from 'rxjs';

@Component({
  selector: 'utax-assign-cab-modal',
  templateUrl: './assign-cab-modal.component.html',
  styleUrls: ['./assign-cab-modal.component.scss']
})
export class AssignCabModalComponent implements OnInit, OnDestroy {
  assignCabForm: UntypedFormGroup;

  cabs = [];
  blockedDriversId = [];
  private componentDestroyed$ = new Subject();
  constructor(
    private fb: UntypedFormBuilder,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      orderId: string;
      orderServiceId: string;
      services: Service[];
    },
    public dialogRef: MatDialogRef<AssignCabModalComponent>,
    private cabsApiService: CabsApiService
  ) {}

  ngOnInit() {
    this.buildForm();

    this.assignCabForm.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$),
        debounceTime(400),
        distinctUntilChanged(),
        mergeMap((formValue: { service: number; search: string }) => {
          if (formValue.search.length > 0) {
            const getDrivers = this.cabsApiService.getDrivers({
              service: formValue.service,
              query: formValue.search
            });
            const getCabs = this.cabsApiService.getCabs(formValue.service, formValue.search);
            return forkJoin([getDrivers, getCabs]);
          } else {
            return of('No cabs');
          }
        })
      )
      .subscribe((data: any) => {
        this.blockedDriversId = [];
        this.cabs = data[1].data;
        if (data[0].data) {
          this.blockedDriversId = data[0].data.filter(driver => driver.is_blocked).map(driver => driver.id);
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  buildForm() {
    this.assignCabForm = this.fb.group({
      service: [this.data.orderServiceId],
      search: ['']
    });
  }

  assignCab(cab) {
    this.dialogRef.close({
      cab_id: cab.id,
      service: this.assignCabForm.getRawValue().service,
      id: this.data.orderId
    });
  }
}
