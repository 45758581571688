import { MapRadiusComponent } from './radius/radius.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map/map.component';
import { MapCabComponent } from './cab/cab.component';
import { MapOrderComponent } from './order/order.component';
import { MapControlsComponent } from './controls/controls.component';
import { MapPolyComponent } from './poly/poly.component';
import { MapHomeComponent } from './home/home.component';
import { CabTargetComponent } from './cab-target/cab-target.component';

@NgModule({
  declarations: [
    MapComponent,
    MapCabComponent,
    MapOrderComponent,
    MapRadiusComponent,
    MapControlsComponent,
    MapPolyComponent,
    MapHomeComponent,
    CabTargetComponent
  ],
  imports: [CommonModule],
  providers: [],
  exports: [
    MapComponent,
    MapCabComponent,
    MapOrderComponent,
    MapRadiusComponent,
    MapControlsComponent,
    MapPolyComponent,
    MapHomeComponent,
    CabTargetComponent
  ]
})
export class MapModule {}
