import { Component, OnInit, Optional, Inject } from '@angular/core';
import {FormControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PasswordValidation } from '../../utils/password-validation';
import { Service } from 'src/app/interfaces/order.interface';
import { Role, User } from 'src/app/users/models/user.interface';
import { UsersService } from '../../services';
import {mergeMap, tap} from 'rxjs/operators';
import {forkJoin, of, ReplaySubject} from 'rxjs';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {TranslateService} from "@ngx-translate/core";

@UntilDestroy()
@Component({
  selector: 'utax-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {
  form: UntypedFormGroup;
  types = ['admin', 'operator'];
  loading = false;
  public savedUserValue: any;
  public disabledSaveButton = true;

  public rolesFilterCtrl: FormControl<string> = new FormControl<string>('');
  public filteredRoles$: ReplaySubject<Role[]> = new ReplaySubject<Role[]>(1);

  constructor(
    private fb: UntypedFormBuilder,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      services: Service[];
      roles: Role[];
      user?: User;
    },
    public dialogRef: MatDialogRef<UserDialogComponent>,
    private usersService: UsersService,
    private translateService: TranslateService,
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.filteredRoles$.next(this.data.roles.slice());
    this.rolesFilterCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.filterRoles();
      });
  }

  saveUser(): void {
    this.loading = true;
    const formValue = this.form.value;
    if (this.data.user) {
      // update user
      const userToSave: any = {
        id: this.data.user.id,
        type: formValue.type,
        name: formValue.name
      };
      if (formValue.password) {
        userToSave.password = formValue.password;
      }
      this.usersService
        .putUser(userToSave)
        .pipe(mergeMap(this.editRolesAndServicesReqs))
        .subscribe({
          next: (res: any[]) => {
            if (res.length > 0) {
              this.dialogRef.close({action: this.data.user ? 'update' : 'create', user: res});
            }
          },
          error: err => {
            this.loading = false;
          }
        });
    } else {
      // create user
      this.usersService
        .postUser({ name: formValue.name, password: formValue.password, type: formValue.type })
        .pipe(mergeMap(this.createUserRolesAndServices))
        .subscribe({
          next: (res: any[]) => {
            if (res.length > 0) {
              this.dialogRef.close(res);
            }
          },
          error: err => {
            this.loading = false;
          }
        });
    }
  }

  private createUserRolesAndServices = user => {
    const formValue = this.form.value;
    const reqs = [of(user)];
    if (formValue.roles.length > 0) {
      reqs.push(this.usersService.postRoles(user.id, formValue.roles));
    }
    if (formValue.services.length > 0) {
      reqs.push(this.usersService.putService(user.id, formValue.services));

      // formValue.services.forEach((serviceId: number) => {
      //   reqs.push(this.usersService.putService(user.id, serviceId));
      // });
    }
    return forkJoin(reqs);
  };

  private editRolesAndServicesReqs = user => {
    const formValue = this.form.value;
    const reqs = [of(user)];
    const deleteReqs = [of(user)];
    console.log('this.data.user', this.data.user, formValue);
    if (this.data.user.roles.length > 0) {
      // remove all roles
      // deleteReqs.push(this.usersService.deleteRoles(user.id, this.data.user.roles));
      // add all selected roles
      if(JSON.stringify(formValue.roles) !== JSON.stringify(this.data.user.roles)) {
        reqs.push(this.usersService.postRoles(user.id, formValue.roles));
      }
    }
    if (this.data.user.services.length > 0) {
      // remove services
      let services = [];
      this.data.user.services.forEach(serv => {
        if (!formValue.services.some(item => item === serv)) {
          services.push(serv);
        }
      });
      if (services.length > 0) {
        deleteReqs.push(this.usersService.deleteService(user.id, services));
      }
    }
    if (formValue.services.length > 0) {
      // add services
      let services = [];
      formValue.services.forEach(serv => {
        if (!this.data.user.services.some(item => item === serv)) {
          services.push(serv);
        }
      });
      if (services.length > 0) {
         reqs.push(this.usersService.putService(user.id, services));
      }
    }

    return forkJoin(deleteReqs).pipe(mergeMap(() => forkJoin(reqs)));
  };

  private createForm(): void {
    let user;
    if (this.data && this.data.user) {
      user = this.data.user;
    }
    this.form = this.fb.group(
      {
        name: [user ? user.name : '', [Validators.required, Validators.pattern(/^[a-z0-9_\-]+$/i)]],
        type: [user ? user.type : '', Validators.required],
        roles: [user ? user.roles : [], Validators.required],
        services: [user ? user.services : []],
        password: ['', user ? Validators.nullValidator : Validators.required],
        confirmPassword: ['', user ? Validators.nullValidator : Validators.required]
      },
      {
        validator: PasswordValidation.MatchPassword
      }
    );
    this.savedUserValue = {...this.form.value};
    this.form.valueChanges.pipe(
      tap(val => {
        this.disabledSaveButton = JSON.stringify(val) === JSON.stringify(this.savedUserValue);
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  protected filterRoles() {
    if (!this.data?.roles) {
      return;
    }
    let search = this.rolesFilterCtrl.value;
    if (!search) {
      this.filteredRoles$.next(this.data.roles.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredRoles$.next(
      this.data.roles.filter(role => {
        return role.display_name.toLowerCase().includes(search) ||  this.translateService.instant(role.display_name).toLowerCase().includes(search);
      })
    );
  }
}
