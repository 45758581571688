import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {
  OperatorBlacklistDataContentInterface,
  PassengerBlacklistFullInterface,
} from '../../models/passenger-blacklist.interface';
import {PassengerBlacklistApiService} from "../../services/passenger-blacklist-api.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {tap} from "rxjs/operators";
import {PassengerBlacklistService} from "../../services/passenger-blacklist.service";
import {MatDialog} from "@angular/material/dialog";
import {HandleEventService} from "@global-services/handle-event-service.service";
import {
  PassengerBlcklistUnlockModalComponent
} from "../passenger-blcklist-unlock-modal/passenger-blcklist-unlock-modal.component";


@UntilDestroy()
@Component({
  selector: 'utax-passenger-blacklist-table',
  templateUrl: './passenger-blacklist-table.component.html',
  styleUrls: ['./passenger-blacklist-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PassengerBlacklistTableComponent implements OnInit {
  @Input() set items(data) {
    this.selectedRowEdit = -1;
    this.clients = data;
    if (data && data.length) {
      this.dataSource = new MatTableDataSource(data);
    } else {
      this.dataSource = new MatTableDataSource([]);
    }
    this.cd.detectChanges();
  }
  @Input() preloader: boolean;

  displayedColumns: string[] = ['clientPhoneNumber', 'operatorName', 'createdAt', 'status', 'blockType', 'comment', 'updatedAt', 'unblockOperatorName', 'actions'];
  dataSource: MatTableDataSource<any>;
  selectedRowEdit = -1;

  public services: any[] = [];
  public store: any;
  public operatorsFilter = [];
  public operators: OperatorBlacklistDataContentInterface[] = [];
  public selectedAllOperators: boolean;
  public statuses = [{onlyBlocked: true, name: 'UTAX_ONLY_BLOCKED_STATUS'}, {onlyBlocked: false, name: 'UTAX_ONLY_NOT_BLOCKED_STATUS'}];
  public statusesFilter = [];
  public selectedAllStatuses: boolean;
  public clients: PassengerBlacklistFullInterface[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private passengerBlacklistApiService: PassengerBlacklistApiService,
    private passengerBlacklistService: PassengerBlacklistService,
    private matDialog: MatDialog,
    private handleEventService: HandleEventService
  ) {
  }

  ngOnInit() {
    this.getOperatorsForBlacklist();
  }

  private getOperatorsForBlacklist(): void {
    this.passengerBlacklistApiService.getOperatorsForBlacklist()
      .pipe(
        tap((data) => {
          this.operators = data.content;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }


  filterTable(typeFilter?: string) {
    if (typeFilter === 'operator') {
      this.passengerBlacklistService.body$.next({...this.passengerBlacklistService.body$.value, operatorIds: this.operatorsFilter.map(item => item.id)});
    } else if (typeFilter === 'status') {
      const active = this.statusesFilter.length === 1 ? this.statusesFilter[0].onlyBlocked : null;
      if (active !== null) {
        this.passengerBlacklistService.body$.next({...this.passengerBlacklistService.body$.value, activeBlock: this.statusesFilter[0].onlyBlocked});
      } else {
        const value = this.passengerBlacklistService.body$.value;
        delete value.activeBlock;
        this.passengerBlacklistService.body$.next(value);

      }
    }
    this.selectedAllOperators = this.operatorsFilter.length === this.operators.length;
    this.passengerBlacklistService.params$.next(this.passengerBlacklistService.DEFAULT_PARAMS);
    this.passengerBlacklistService.getClients(
      this.passengerBlacklistService.params$.value,
      this.passengerBlacklistService.body$.value
    );
  }


  selectAll(typeFilter: string): void {
    if (typeFilter === 'operator') {
      this.operatorsFilter = this.operators;
      this.passengerBlacklistService.body$.next({...this.passengerBlacklistService.body$.value, operatorIds: this.operators.map(item => item.id)});
    } else if (typeFilter === 'status') {
      this.statusesFilter = this.statuses;
      const value = this.passengerBlacklistService.body$.value;
      delete value.activeBlock;
      this.passengerBlacklistService.body$.next(value);
    }
    this.filterTable();
  }

  deselectAll(typeFilter: string): void {
    if (typeFilter === 'operator') {
      this.operatorsFilter = [];
      const value = this.passengerBlacklistService.body$.value;
      delete value.operatorIds;
      this.passengerBlacklistService.body$.next(value);
    } else if (typeFilter === 'status') {
      this.statusesFilter = [];
      const value = this.passengerBlacklistService.body$.value;
      delete value.activeBlock;
      this.passengerBlacklistService.body$.next(value);
    }
    this.filterTable();
  }

  public removeFromBlacklist(id: string) {
    this.matDialog.open(PassengerBlcklistUnlockModalComponent, {
      panelClass: 'request-activate-dialog-container',
      backdropClass: 'request-dialog-backdrop',
      width: '376px',
      disableClose: true,
      data: {
        id
      }
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.passengerBlacklistService.params$.next(this.passengerBlacklistService.DEFAULT_PARAMS);
        this.passengerBlacklistService.getClients(
          this.passengerBlacklistService.params$.value,
          this.passengerBlacklistService.body$.value
        );
        this.handleEventService.openSnackBar('PASSENGER_BLACKLIST_SUCCESS_REMOVE');
      }
    });
  }

  scrollPage() {
    if (this.passengerBlacklistService.params$.value.page < this.passengerBlacklistService.totalPage$.value) {
      this.passengerBlacklistService.params$.next({...this.passengerBlacklistService.params$.value, page: this.passengerBlacklistService.params$.value.page + 1});
      this.passengerBlacklistService.getClients(
        this.passengerBlacklistService.params$.value,
        this.passengerBlacklistService.body$.value,
        true
      );
    }
  }
}

// document.getElementById('table').scrollTop = 0;
