import { LogistOrdersService } from 'src/app/logist/services/logist-orders.service';
import { Subject, Observable, interval } from 'rxjs';
import { LogistCabsService } from './../../services/logist-cabs.service';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { AmeliaService } from '../../services';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { getReqIcon, getReqMessage } from '../../utils/amelia-utils';
import { DriverPartner } from '../../models/partner';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { PartnersModalComponent } from '../../components';
import { HandleEventService } from 'src/app/services';
import * as moment from 'moment';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'utax-logist-statuses',
  templateUrl: './logist-statuses.component.html',
  styleUrls: ['./logist-statuses.component.scss']
})
export class LogistStatusesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() cab;
  img;

  currentShift;
  currentRequests = [];

  shifts;
  requests;
  private rests;
  restObj = {};
  restTypes = [];

  cabAutomationEnabled;
  cabIsAutomationPaused;
  cabAutomationPausedAt;

  loading = false;

  orderInProgress$: Observable<any>;
  changeIndicator = 0;
  private dialogRef: MatDialogRef<PartnersModalComponent>;
  private dialogConfig = new MatDialogConfig();

  private componentDestroyed$ = new Subject();

  constructor(
    public logistCabsService: LogistCabsService,
    public logistOrdersService: LogistOrdersService,
    public amelia: AmeliaService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private handleEventService: HandleEventService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.orderInProgress$ = this.logistOrdersService.currentOrderInProgress$.pipe(
      distinctUntilChanged((prev, curr) => prev.status === curr.status)
    );
    this.amelia.workingShifts$.pipe(takeUntil(this.componentDestroyed$)).subscribe(shifts => {
      this.shifts = shifts;
      this.processingShiftsAndReqs();
    });
    this.amelia.activeDriverRequests$.pipe(takeUntil(this.componentDestroyed$)).subscribe(requests => {
      this.requests = requests;
      this.processingShiftsAndReqs();
    });
    this.amelia.finishedDriverRest$.pipe(takeUntil(this.componentDestroyed$)).subscribe(rests => {
      this.rests = _.cloneDeep(rests);
      this.processingShiftsAndReqs();
    });
    this.amelia.restTypes$.pipe(takeUntil(this.componentDestroyed$)).subscribe(restsTypes => {
      this.restTypes = restsTypes;
    });
    interval(10000)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.changeIndicator++;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.processingShiftsAndReqs();
    this.cabAutomationEnabled = this.cab.is_automation_enabled;
    this.cabIsAutomationPaused = this.cab.is_automation_paused;
    this.cabAutomationPausedAt = this.cab.automation_paused_at;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  showDriverPartners(partners: DriverPartner[]): void {
    this.dialogConfig.data = {
      partners: partners.filter(driver => driver.callsign !== this.cab.callsign)
    };
    this.dialogConfig.panelClass = 'driver-partners-modal-container';
    this.dialogConfig.backdropClass = 'driver-partners-modal-backdrop';

    this.dialogRef = this.dialog.open(PartnersModalComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(data => {
      this.dialogConfig = {};
    });
  }

  processingShiftsAndReqs(): void {
    if (this.shifts) {
      this.currentShift = this.shifts.find(shift => shift.cabId === this.cab.id);
      if (this.requests && this.currentShift) {
        this.currentRequests = this.requests
          .filter(req => req.wshId === this.currentShift.id)
          .map(req => {
            return {
              ...req,
              message: getReqMessage(req, this.amelia.restTypes),
              icon: getReqIcon(req.type, req.status)
            };
          });
      }
    }

    if (this.rests) {
      this.restTypes = _.cloneDeep(this.restTypes);
    }
    this.cdRef.detectChanges();
  }

  cabAutomationChange(event): void {
    if (event.checked) {
      this.logistCabsService.enableCabAutomation(this.cab.id).subscribe(() => {
        this.cabAutomationEnabled = true;
      });
    } else {
      this.logistCabsService.disableCabAutomation(this.cab.id).subscribe(() => {
        this.cabAutomationEnabled = false;
      });
    }
  }

  automationPause(cab: any, enablePause: boolean): void {
    this.loading = true;
    if (enablePause) {
      this.logistCabsService.pauseAutomationCabPause(cab.id).subscribe({
        next: () => {
          this.loading = false;
          this.handleEventService.openSnackBar('UTAX_FRONT_AUTOMATION_PAUSED');
          this.cabIsAutomationPaused = true;
          this.cabAutomationPausedAt = moment();
        },
        error: error => {
          this.loading = false;
        }
      });
    } else {
      this.logistCabsService.resumeAutomationCabPause(cab.id).subscribe({
        next: () => {
          this.loading = false;
          this.handleEventService.openSnackBar('UTAX_FRONT_AUTOMATION_RESUMED');
          this.cabIsAutomationPaused = false;
          this.cabAutomationPausedAt = null;
        },
        error: error => {
          this.loading = false;
        }
      });
    }
  }
}
