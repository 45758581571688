import { AmeliaService } from './../../services/amelia.service';
import { ImageService } from 'src/app/shared/services';
import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'utax-cab-start-shift-modal',
  templateUrl: './cab-start-shift-modal.component.html',
  styleUrls: ['./cab-start-shift-modal.component.scss']
})
export class CabStartShiftModalComponent implements OnInit {
  mainImage;
  minutesInWaiting;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CabStartShiftModalComponent>,
    private imageService: ImageService,
    private amelia: AmeliaService
  ) {}

  ngOnInit() {
    this.mainImage = this.data.req.session.images[0];

    this.minutesInWaiting = moment().diff(moment.unix(this.data.req.createdAt.seconds), 'minutes');
  }

  close() {
    this.dialogRef.close('close');
  }

  cancel() {
    this.amelia.confirmDriverRequest(false, this.data.req.id);
    this.dialogRef.close('cancel');
  }

  confirm() {
    this.amelia.confirmDriverRequest(true, this.data.req.id);
    this.dialogRef.close('confirm');
  }

  setMainImage(img) {
    this.mainImage = img;
  }
}
