import { ArchiveApiService } from './../../services/archive-api.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'utax-archive-table-item',
  templateUrl: './archive-table-item.component.html',
  styleUrls: ['./archive-table-item.component.scss', '../../containers/columns.scss']
})
export class ArchiveTableItemComponent implements OnInit {
  @Input() record;

  constructor(public archiveApiService: ArchiveApiService) { }

  ngOnInit() { }

}
