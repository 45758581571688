import { OperatorAccountPeriod } from './operator-account.model';

export interface BalanceModel {
  balance: number;
  withdrawal_balance: number;
}

export interface BalanceStatisticModel {
  data: BalanceStatisticDataModel;
  meta: BalanceStatisticMetaModel;
}

export interface BalanceStatisticDataModel {
  call_record_count: number;
  incoming_from_driver_call_record_count: number;
  incoming_from_passenger_call_record_count: number;
  incoming_from_new_client_call_record_count: number;
  incoming_from_global_new_client_call_record_count: number;
  call_record_amount: number;
  bonus_amount: number;
  penalty_amount: number;
}

export interface BalanceStatisticMetaModel {
  start_date: string;
  end_date: string;
}

export enum BalanceStatisticPeriod {
  CURRENT = 'current',
  PREVIOUS = 'prev',
}

export interface BalanceTransaction {
  id: string;
  type: string;
  operation: 'add' | 'sub';
  amount: number;
  balance: number;
  created_at: string;
  call_record_type: string;
}

export function balanceStatisticPeriodToOperatorAccountPeriod(period: BalanceStatisticPeriod): OperatorAccountPeriod {
  switch (period) {
    case BalanceStatisticPeriod.CURRENT:
      return OperatorAccountPeriod.PER_CURRENT_PERIOD;
    case BalanceStatisticPeriod.PREVIOUS:
      return OperatorAccountPeriod.PER_PREVIOUS_PERIOD;
  }
}
