
export const environment = {
  production: false,
  config: {
    http: 'https://dev-miranda',
    ws: 'wss://dev-miranda',
    domain: '.megakit.pro',
    client: 'client',
    addresses: 'https://addresses.dev-miranda.megakit.pro/geocode',
    passenger: 'https://pass.dev-miranda.megakit.pro',
    oldAddresses: 'https://address.dev-miranda.megakit.pro/search',
    estimate: 'https://estimator.dev-miranda.megakit.pro/v2/operator',
    whiteFront: 'https://dev-miranda.megakit.pro/stats/#/',
    tileServer: 'https://tile.utaxcloud.net/{z}/{x}/{y}.png',
    payment: 'https://dev-miranda.megakit.pro:22223',
    ameliaHttp: 'https://amelia.dev-miranda.megakit.pro/api',
    dispatcher: 'https://dispapp.dev-miranda.megakit.pro/dispatcher',
    dispatcherManage: 'https://dispapp.dev-miranda.megakit.pro/manage',
    octobusHttp: 'https://octobus.dev-miranda.megakit.pro/octobus',
    routingMachine: 'https://rm.utaxcloud.net',
    amelia: 'wss://amelia.dev-miranda.megakit.pro',
    callCentre: 'https://callee.dev-miranda.megakit.pro',
    wsCallCentre: 'wss://callee.dev-miranda.megakit.pro',
    octobus: 'wss://octobus.dev-miranda.megakit.pro/octobus/v1/dispatcher/ws',
    voipBaseUrls: [
      'voiptest.megakit.pro'
    ],
    callCenterBaseUrls: [
      'callee.dev-miranda.megakit.pro'
    ],
    voipPort: '8089',
    registrationServer: 'https://utax-cloud.dev-miranda.megakit.pro',
    mediaServer: 'https://dev-miranda.megakit.pro/client',
  },
  liteVersion: false
};
