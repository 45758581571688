import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

const newDriverBonusUrl = 'operator/driver-starter-bonuses';

@Injectable({
  providedIn: 'root'
})
export class NewDriverBonusesService {
  constructor(private http: HttpClient) {}

  getListOfBonuses(serviceId: number): Observable<any> {
    return this.http.get(newDriverBonusUrl, { params: { service_id: `${serviceId}` } }).pipe(pluck('data'));
  }

  postBonus(bonus: { service_id: number; name: string; value: number; tariff_plan_ids: string[] }): Observable<any> {
    return this.http.post(newDriverBonusUrl, bonus).pipe(pluck('data'));
  }

  putBonus(bonus): Observable<any> {
    return this.http.put(newDriverBonusUrl, bonus).pipe(pluck('data'));
  }

  deleteBonus(id: string): Observable<any> {
    return this.http.delete(newDriverBonusUrl, { params: { id } });
  }
}
