import { DispatcherFiltersComponent } from './dispatcher-filters/dispatcher-filters.component';
import { DispatcherDetailsComponent } from './dispatcher-details/dispatcher-details.component';
import { DispatcherOrdersListComponent } from './dispatcher-orders-list/dispatcher-orders-list.component';
import { DispatcherComponent } from './dispatcher/dispatcher.component';
import { ServicesSliderComponent } from './services-slider/services-slider.component';

export const containers: any[] = [
  DispatcherComponent,
  DispatcherOrdersListComponent,
  DispatcherDetailsComponent,
  DispatcherFiltersComponent,
  ServicesSliderComponent
];

export * from './services-slider/services-slider.component';
export * from './dispatcher-filters/dispatcher-filters.component';
export * from './dispatcher-details/dispatcher-details.component';
export * from './dispatcher-orders-list/dispatcher-orders-list.component';
export * from './dispatcher/dispatcher.component';
