import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/utils/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { RouterModule, Routes } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { AuthGuard } from 'src/app/utils/auth.guard';
import { RemoteWorkGuard } from 'src/app/utils/remote-work.guard';

import * as fromContainers from './containers';
import * as fromComponents from './components';
import * as fromServices from './services';
import * as fromPipes from './pipes';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CREATE_DISP_MODAL_TOKEN } from './utils/create-disp-settings-modal-token';
import { DispSettingsCreateDispModalComponent } from './components';
import {DispSettingFullGuard} from "../../../guards/disp-setting-full.guard";
import {DispSettingShortGuard} from "../../../guards/disp-setting-short.guard";

const ROUTES: Routes = [
  {
    path: '',
    component: fromContainers.DispSettingsComponent,
    children: [
      {
        path: '',
        redirectTo: 'disp',
        pathMatch: 'full'
      },
      {
        path: 'disp',
        component: fromContainers.DispSettingsDispComponent,
        canActivate: [AuthGuard, RemoteWorkGuard, DispSettingShortGuard]
      },
      {
        path: 'edit',
        component: fromContainers.DispSettingsEditComponent,
        canActivate: [AuthGuard, RemoteWorkGuard, DispSettingFullGuard]
      },
      {
        path: 'remote',
        component: fromContainers.DispSettingsRemoteComponent,
        canActivate: [AuthGuard, RemoteWorkGuard, DispSettingFullGuard]
      }
    ]
  }
];

@NgModule({
  declarations: [...fromContainers.containers, ...fromComponents.components, ...fromPipes.pipes],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    SharedModule,
    ReactiveFormsModule,
    KeyboardShortcutsModule,
    FormsModule,
    RouterModule.forChild(ROUTES),
    NgxMaskModule.forChild(),
    InfiniteScrollModule
  ],
  providers: [
    ...fromServices.services,
    {
      provide: CREATE_DISP_MODAL_TOKEN,
      useValue: DispSettingsCreateDispModalComponent
    }
  ]
})
export class DispSettingsModule {}
