import { HttpClient, HttpParams } from '@angular/common/http';
import { Address } from './../interfaces/address.interface';
import {Subject, Observable, of, BehaviorSubject} from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {pluck, mergeMap, map, tap, finalize} from 'rxjs/operators';
import { CityCanSearchAddress } from '../interfaces/state.interface';
import * as _ from 'lodash';
import {GlobalDataService} from "@global-services/global-data.service";

const removePassAddressUrl = 'operator/passenger-addresses/rank';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  addresses: Address[] = [];
  addresses$: Subject<Address[]> = new Subject<Address[]>();
  popularAddresses$: Subject<Address[]> = new Subject<Address[]>();
  public searchingProcess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  city;
  cityObj;

  constructor(
    private http: HttpClient,
    private globalDataService: GlobalDataService
  ) {}

  setCity(allServices, selectedService) {
    this.city = allServices.filter(item => +selectedService === item.id).map(item => {
      this.cityObj = item;
      return item.city;
    })[0];
  }

  // @TODO NEED TO REFACTOR
  setAddresses(
    query: string,
    lang: string,
    isIntercity: boolean,
    userId: string,
    addressType?,
    citiesWithAddresses?: CityCanSearchAddress[],
    streetId?: string,
    additionalType?: string
  ) {
    const isNewSearchMethod = true;
    if (this.city.use_native_search || isIntercity) {
      let params = new HttpParams({
        fromObject: {
          city: isIntercity ? 'intercity' : this.city.key,
          lang,
          query,
          user_id: `P${userId}`
        }
      });

      if (addressType !== 'all') {
        params = params.append('address_type', addressType);
      }

      if (isNewSearchMethod) {
        params = params.appendAll({
          template:  'universal',
          user_type: 'operator'
        });
        if (additionalType && addressType === 'street' && !streetId) {
          params = params.append('address_type', additionalType);
        }
        if (streetId) {
          params = params.append('street_id', streetId);
        }
      }




      this.http
        .get<any>(
           isNewSearchMethod ? environment.config.addresses : environment.config.oldAddresses,
          { params })
        .pipe(
          map((obj) => isNewSearchMethod ? [...obj.data, ...obj.suggestions] : obj.data),
          finalize(() => this.searchingProcess$.next(false))
        )
        .subscribe((rawAddresses: any) => {
          this.addresses = rawAddresses.map(item => {
            return {
              name: isIntercity ? 'мм ' + item.display_name : item.display_name,
              lat: item.location.latitude,
              lng: item.location.longitude,
              id: null,
              idForRemove: item.id,
              relation_id: item.address_id,
              address_id: item.address_id,
              is_user_address: item.is_user_address,
              intercity: item.city_key === 'intercity',
              street_number: item?.street_number,
              address_type: item?.address_type,
              street_id: item?.street_id,
            };
          });

          if (isIntercity) {
            this.addresses = [...this.filterAddressSearch(citiesWithAddresses, query), ...this.addresses];
          }
          this.addresses$.next(this.addresses);
        });
    } else {
      this.http.get(`operator/address/search/${this.city.id}?lang=${lang}&query=` + query).subscribe((data: any) => {
        this.addresses = data.data.map(item => {
          let name = '';
          // let name_old = '';
          let street = '';
          let streetName = '';
          let house = '';
          let place = '';
          let placeName = '';
          let area = '';

          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < item.components.length; i++) {
            const obj = item.components[i];
            if (obj.type === 'address_type') {
            } else if (obj.type === 'place' || obj.type === 'place_name') {
              if (obj.type === 'place') {
                place += obj.name;
              } else {
                placeName += obj.name;
              }
            } else if (obj.type === 'address_name') {
              name += obj.name + ' ';
            } else if (obj.type === 'street_name' || obj.type === 'street') {
              if (!!name && obj.type === 'street_name') {
                streetName += obj.name; // []
              } else if (!name) {
                obj.type === 'street' ? (street += obj.name) : (streetName += obj.name);
              }
            } else if (obj.type === 'area') {
              area = ' (' + obj.name + '), ';
            } else if (obj.type === 'house_type' || obj.type === 'house_name') {
              house += obj.name + ' ';
            }
          }
          name += streetName + ' ' + street + area + ' ' + house;
          if (place) {
            name += placeName + ' (' + place + ')';
          }
          return {
            name,
            lat: item.location.coordinates[1],
            lng: item.location.coordinates[0],
            id: item.id
          };
        });
        this.addresses$.next(this.addresses);
      });
    }
  }

  removePassAddress(passengerId: string, addressId: string): Observable<boolean> {
    return this.http
      .delete(removePassAddressUrl, { params: { passenger_id: passengerId, address_id: addressId } })
      .pipe(mergeMap(() => of(true)));
  }

  private filterAddressSearch(array: any[], query: string): any[] {
    return _.cloneDeep(
      (array || []).filter(queueitem => {
        if (queueitem.id === this.city.id) {
          return false;
        }
        return queueitem.name.toUpperCase().indexOf(query.toUpperCase()) > -1;
      })
    );
  }
}
