import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { HeaderToolsHandlerService } from 'src/app/navigation/services';
import { Role } from 'src/app/users/models/user.interface';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RoleDialogComponent } from '../../components';
import { YesNoModalComponent } from 'src/app/shared/components';
import { Subject } from 'rxjs';
import { RolesService } from '../../services/roles.service';
import {takeUntil, tap} from 'rxjs/operators';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import {StateService} from "@global-services/state.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'utax-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('controlMenu') controlMenu: TemplateRef<any>;
  roles: Role[];

  shortcuts: ShortcutInput[] = [];
  dialogRef: MatDialogRef<RoleDialogComponent | YesNoModalComponent>;
  private componentDestroyed$ = new Subject();
  public isPermission = false;
  public isRolesAllowEdit = false;
  public isRolesAllowHistory = false;

  constructor(
    private headerToolsHandlerService: HeaderToolsHandlerService,
    public rolesService: RolesService,
    private stateService: StateService,
    private dialog: MatDialog
  ) {
    this.rolesService.getListOfRoles().subscribe(roles => {
      this.roles = roles;
      this.rolesService.selectedRole = roles[0];
    });
    this.rolesService.getListOfPermissions();
  }

  ngOnInit(): void {
    this.stateService.getStoreParamSub('permissions').pipe(
      tap(permissions => this.isPermission = !!permissions.length),
      tap(permissions => {
        this.isRolesAllowEdit = permissions.some(permission => permission.name === 'operator_roles_update');
        this.isRolesAllowHistory = permissions.some(permission => permission.name === 'operator_roles_history_view');
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  ngAfterViewInit(): void {
    this.headerToolsHandlerService.template.next({
      controlMenu: this.controlMenu,
      source: 'users'
    });

    this.shortcuts.push(
      {
        key: ['del'],
        command: () => {
          this.deleteRole();
        },
        preventDefault: true
      },
      {
        key: ['ins'],
        command: () => {
          this.openCreationDialog();
        },
        preventDefault: true
      },
      {
        key: ['home'],
        command: () => {
          this.openEditRoleDialog();
        },
        preventDefault: true
      }
    );
  }

  openCreationDialog(): void {
    const config = new MatDialogConfig();
    config.panelClass = 'request-activate-dialog-container';
    config.backdropClass = 'request-dialog-backdrop';
    config.width =  '442px';
      this.dialogRef = this.dialog.open(RoleDialogComponent, config);
    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(roleRes => {
        if (roleRes) {
          this.roles = [...this.roles, roleRes];
          this.rolesService.selectedRole = roleRes;
        }
      });
  }

  openEditRoleDialog(roleToEdit?: Role): void {
    if (!this.isRolesAllowEdit) {
      return;
    }
    const role = roleToEdit ? roleToEdit : this.rolesService.selectedRole;
    const config = new MatDialogConfig();
    config.data = role;
    config.panelClass = 'request-activate-dialog-container';
    config.backdropClass = 'request-dialog-backdrop';
    config.width =  '442px';
    this.dialogRef = this.dialog.open(RoleDialogComponent, config);
    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(roleRes => {
        if (roleRes) {
          this.roles = this.roles.map(item => {
            if (item.id === roleRes.id) {
              return roleRes;
            } else {
              return item;
            }
          });
          this.rolesService.selectedRole = roleRes;
          this.rolesService.selectedRole$.next(roleRes);
        }
      });
  }

  editRole(): void {
    if (this.isRolesAllowEdit) {
      this.openEditRoleDialog(this.rolesService.selectedRole);
    }
  }

  deleteRole(): void {
    this.dialogRef = this.dialog.open(YesNoModalComponent, {
      data: { title: 'UTAX_FRONT_DELETE_ROLE', extra: this.rolesService.selectedRole.display_name },
      panelClass: 'yes-no-modal-container'
    });

    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(res => {
        if (res && res === 'YES') {
          this.rolesService.deleteRole().subscribe(() => {
            this.roles = this.roles.filter(role => role.id !== this.rolesService.selectedRole.id);
          });
        }
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }
}
