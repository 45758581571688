import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import * as fromContainers from './containers';
import * as fromComponents from './components';
import * as fromServices from './services';

import { MaterialModule } from '../../../utils/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';

const ROUTES = [
  {
    path: '',
    component: fromContainers.SalaryModifierComponent
  }
];

@NgModule({
  declarations: [...fromContainers.containers, ...fromComponents.components],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    SharedModule,
    ReactiveFormsModule,
    KeyboardShortcutsModule,
    FormsModule,
    RouterModule.forChild(ROUTES),
    NgxMaskModule.forChild()
  ],
  providers: [...fromServices.services]
})
export class SalaryModifierModule {
}
