import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {OrderClass} from '@global-classes/order.class';

@Component({
  selector: 'utax-card-pay',
  templateUrl: './card-pay.component.html',
  styleUrls: ['./card-pay.component.scss']
})
export class CardPayComponent implements OnChanges {
  @Input() order: OrderClass;
  @Input() amount: number;
  @Input() overdraft = 0;
  paidByCard = 0;
  paidByCardProcessing = 0;
  paidByCash = 0;
  paidByBonus = 0;
  paidByPhone = 0;

  constructor() {}

  ngOnChanges() {
    if (this.order) {
      this.paidByCard = 0;
      this.paidByCash = 0;
      this.paidByBonus = 0;
      this.paidByPhone = 0;
      this.paidByCardProcessing = 0;
      if (this.order?.passenger_overdraft?.amount) {
        this.overdraft = this.order.passenger_overdraft.amount;
      }
      if (this.order.is_card_payment || this.order.payment_type === 'phone') {
        let cashlessSum = 0;
        if (this.order.trip) {
          cashlessSum = (this.order.trip.invoices || [])
            .filter(invioce => invioce.status === 'hold_approved')
            .map(invoice => invoice.amount)
            .reduce((a, b) => {
              return a + b;
            }, 0);
          this.paidByCardProcessing = (this.order.trip.invoices || [])
            .filter(invioce => invioce.status === 'hold_processing')
            .map(invoice => invoice.amount)
            .reduce((a, b) => {
              return a + b;
            }, 0);
          if (this.amount) {
            this.paidByCash = this.amount - cashlessSum - this.paidByCardProcessing;
          } else if (this.order.fare.amount + this.overdraft > cashlessSum) {
            this.paidByCash = this.order.fare.amount + this.overdraft - cashlessSum - this.paidByCardProcessing;
          } else {
            this.paidByCash = 0;
          }
        } else {
          cashlessSum = this.amount || this.order.fare.amount;
          if (!this.paidByCash) {
            cashlessSum += this.overdraft;
          }
        }
        if (this.order.is_card_payment) {
          this.paidByCard = cashlessSum;
        } else {
          this.paidByPhone = cashlessSum;
        }
      } else if (this.order.payment_type === 'bonus') {
        this.paidByBonus = this.order.paid_amount;
        if (this.order.fare.amount - this.order.paid_amount > 0) {
          this.paidByCash = this.order.fare.amount - this.order.paid_amount;
        } else {
          this.paidByCash = 0;
        }
      }
    }
  }
}
