import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
  transform(str: string): string {
    const lowerCased = str.toLowerCase();
    return lowerCased.charAt(0).toUpperCase() + lowerCased.slice(1);
  }
}
