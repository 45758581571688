import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISalaryModifierSlot } from '../models';
import { SalaryModifierApiService } from './salary-modifier-api.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class SalaryModifierService {

  public salaryModifiers$: BehaviorSubject<ISalaryModifierSlot[]> = new BehaviorSubject<ISalaryModifierSlot[]>(null);

  public newModifier$: BehaviorSubject<ISalaryModifierSlot[]> = new BehaviorSubject<ISalaryModifierSlot[]>([]);

  constructor(private salaryModifierApiService: SalaryModifierApiService) {
  }


  // INIT

  public init(): void {
    this.getSalaryMultipliers().subscribe();
  }


  // GET

  public getSalaryMultipliers(): Observable<ISalaryModifierSlot[]> {
    return this.salaryModifierApiService.getSalaryMultipliers()
      .pipe(
        tap(multipliers => this.salaryModifiers$.next(multipliers))
      );
  }

  removeSalaryModifierSlot(smSlotId: string): void {
    const slotIndex = this.salaryModifiers$.value.findIndex(slot => slot.id === smSlotId);
    if (slotIndex >= 0) {
      const newSlots = [...this.salaryModifiers$.value];
      newSlots.splice(slotIndex, 1);
      this.salaryModifiers$.next(newSlots);
    }
  }


}
