import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedTime',
  pure: false
})
export class LocalizedTimePipe implements PipeTransform {

  constructor(private translateService: TranslateService) { }

  transform(value: any) {
    const time = {
      day: Math.floor(value / (1000 * 60 * 60 * 24)),
      hour: Math.floor((value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minute: Math.floor((value % (1000 * 60 * 60)) / (1000 * 60))
    };
    const result = Object.keys(time).reduce((acc, key) => {
      if (time[key]) {
        acc.push(`${time[key]} ${this.getTranslateByValue(time[key], key)}`);
      }
      return acc;
    }, []);
    return result?.length ? result.join(' ') : '0';
  }

  private getTranslateByValue(value: number, key: string): string {
    const translate = {
      day: this.translateService.instant('LOCALIZE_TIME_DAY'),
      daysLow: this.translateService.instant('LOCALIZE_TIME_DAYS_LOW'),
      days: this.translateService.instant('LOCALIZE_TIME_DAYS'),
      hour: this.translateService.instant('LOCALIZE_TIME_HOUR'),
      hoursLow: this.translateService.instant('LOCALIZE_TIME_HOURS_LOW'),
      hours: this.translateService.instant('LOCALIZE_TIME_HOURS'),
      minute: this.translateService.instant('LOCALIZE_TIME_MINUTE'),
      minutesLow: this.translateService.instant('LOCALIZE_TIME_MINUTES_LOW'),
      minutes: this.translateService.instant('LOCALIZE_TIME_MINUTES'),
    };

    if (value === 1) {
      return translate[key];
    } else if (value === 2 || value === 3 || value === 4) {
      return translate[`${key}sLow`];
    } else {
      return translate[`${key}s`];
    }
  }
}
