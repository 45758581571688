import { Injectable } from '@angular/core';
import {AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import * as moment from 'moment';

export function adultAgeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;

    if (!value || value.length < 8) {
      return null;
    }

    const dateValue = moment(value, 'DDMMYYYY', true).add(18, 'years');
    const dateCurrent = moment();

    const isValid = dateValue.isValid() ?
      dateValue.isBefore(dateCurrent, 'day') &&
      dateValue.isAfter(dateCurrent.subtract(100, 'years'), 'day')
      : false;

    return !isValid ? {dateIncorrect: true} : null;
  };
}

@Injectable()
export class BirthDayValidator {
  constructor() {}

  static regExp(c: UntypedFormControl): { [key: string]: any } {
    const dateRegEx = new RegExp(/(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/);
    // console.log( dateRegEx.test( c.value ) ? null : { date: true } );
    return dateRegEx.test(c.value) ? null : { date: true };
  }

  static birthVal(c: UntypedFormControl): { [key: string]: any } {
    if (c.value) {
      const currentDate = moment(new Date());
      const birthDay = moment(c.value, 'DD.MM.YYYY', true).add(18, 'years');
      const isValid = birthDay.isValid() ?
        birthDay.isBefore(currentDate, 'day') &&
        birthDay.isAfter(currentDate.subtract(100, 'years'), 'day')
        : false;
      return !isValid ? {birth: true} : null;
    }
  }
}
