import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CustomTimeFormat'
})
export class CustomTimeFormatPipe implements PipeTransform {

  transform(value: any): any {
    const res = value < -100 ? '100+' : value;
    return res;
  }

}
