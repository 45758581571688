import {Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, HostBinding} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { debounceTime, filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { DispatcherModel, ICallsStatisticRealtime, IOperatorStatisticTaxiServiceCounters } from '../../models';
import { DispSettingsService } from '../../services';
import { DispSettingsApiService } from '../../services/disp-settings-api.service';
import {GlobalDataService} from '@global-services/global-data.service';

@Component({
  selector: 'utax-disp-settings-disp',
  templateUrl: './disp-settings-disp.component.html',
  styleUrls: ['./disp-settings-disp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispSettingsDispComponent implements OnInit, OnDestroy {
  @HostBinding('class.shortView') isShortView = false;
  operatorStatisticTaxiServiceCounters$: Observable<IOperatorStatisticTaxiServiceCounters[]>;
  callsStatisticRealtime$: Observable<ICallsStatisticRealtime[]>;
  subscription$: Subscription;
  dispatchers = [];
  dispatchersUpdate = [];
  allQueues = [];
  sortParam = {type: 'sort', value: 'name'};
  selectedTaxiServiceId: string;
  selectedServiceId: string;
  queryValue = '';
  private periodOperatorStatisticUpdate = 5000;
  private periodCallsStatisticUpdate = 5000;
  private periodOperatorFilteredUpdate = 5000;

  private componentDestroyed$ = new Subject();
  constructor(
    private fb: UntypedFormBuilder,
    private dispSettingsApiService: DispSettingsApiService,
    private changeDetectorRef: ChangeDetectorRef,
    public dispSettingsService: DispSettingsService,
    public globalDataService: GlobalDataService
  ) {
    this.isShortView = !this.dispSettingsService.isFullView$.value && !this.dispSettingsService.isExtendedView$.value;
  }

  get dispatchersFormArray(): UntypedFormArray {
    if (this.dispSettingsService.form) {
      return this.dispSettingsService.form.controls.dispatchers as UntypedFormArray;
    }
  }

  ngOnInit(): void {
    this.dispSettingsService.selectedQueueId$.next('all');
    if (this.dispSettingsService.isFullView$.value || this.dispSettingsService.isExtendedView$.value) {
      this.initStatistic();
    }
    this.dispSettingsService.editFilterForm$
      .pipe(
        filter(form => Boolean(form)),
        mergeMap((form: UntypedFormGroup) => {
          return form.valueChanges
            .pipe(
              map(() => form)
            );
        }),
        debounceTime(300),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(filters => {
        if (filters.value.hasOwnProperty('name')) {
          this.queryValue = filters.value.name;
          this.getDispatchersData();
        }
      });

    this.dispSettingsService.selectedQueueId$
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(id => Boolean(id))
      )
      .subscribe(id => {
        if (this.subscription$) {
          this.dispatchers = [];
          this.subscription$.unsubscribe();
        }
        this.selectedServiceId = id;
        this.getDispatcheers(id);
      });
  }

  private initStatistic(): void {
    this.operatorStatisticTaxiServiceCounters$ = timer(0, this.periodOperatorStatisticUpdate).pipe(
      takeUntil(this.componentDestroyed$),
      mergeMap(() => this.dispSettingsApiService.getOperatorStatisticTaxiServiceCounters())
    );

    this.callsStatisticRealtime$ = timer(0, this.periodCallsStatisticUpdate).pipe(
      takeUntil(this.componentDestroyed$),
      mergeMap(() => this.dispSettingsApiService.getCallsStatisticRealtimeInfo())
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  getTaxiServices(servicesIds: string[], activeTaxiServiceIds: string[]): any {
    const services = JSON.parse(JSON.stringify(this.globalDataService.globalTaxiServices$.value));
    const newServices = services
      .filter(s => servicesIds.find(ser => ser === s.id))
      .map(s => {
        if (activeTaxiServiceIds.findIndex(k => k === s.id) > -1) {
          s.active = true;
        } else {
          s.active = false;
        }
        s.loading = false;
        return s;
      }) || [];
    return newServices;
  }

  getActiveCallLines(callLineIds) {
    const lines = JSON.parse(JSON.stringify(this.globalDataService.globalCallLines$.value));
    const data = {};
    lines.forEach(s => {
      const key = s.type.toLowerCase();
      data[key] = {};
      if (callLineIds.findIndex(k => k === s.id) > -1) {
        data[key].active = true;
      } else {
        data[key].active = false;
      }
      data[key].id = s.id;
      data[key].loading = false;
    });
    return data;
  }

  sorted(value): void {
    this.sortParam = {type: 'sort', value};
    this.getDispatchersData();
  }

  getDispatcheers(id: string): void {
    this.selectedTaxiServiceId = id;
    const filters: any[] = [
      { type: 'onlyActiveWorkShift', value: true },
    ];
    if (id !== 'all') {
      filters.push({type: 'taxiServiceId', value: id});
    }

    this.subscription$ = timer(0, this.periodOperatorFilteredUpdate).pipe(
      takeUntil(this.componentDestroyed$),
      mergeMap(() => {
        return this.dispSettingsApiService.getAllDispatchersFiltered(3000, 0,
          [
            this.sortParam,
            ...filters,
            {type: 'query', value: this.queryValue}]);
      })
    ).subscribe((response) => {
      this.setDispatchers(response, id);
    });
  }

  selectQueue(id): void {
    this.dispSettingsService.selectQueue(id);
  }

  checkboxUpdate({id, callLineId, name, allow, index}): void {
    this.updateCheckBox(name, allow, true, index);

    this.dispSettingsApiService.putUserCheckbox(callLineId, id, allow)
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        res => {
          this.updateCheckBox(name, allow, false, index);
        }, err => {
          this.updateCheckBox(name, allow, false, index);
        }
      );
  }

  queueGroupUpdate({ uuid, active, groupId, externalGroupId, dispIndex, externalId }): void {
    this.updateServiceValue(groupId, active, true, dispIndex);

    this.dispSettingsApiService.putQueueGroup(groupId, uuid, active, externalGroupId, externalId)
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        res => { this.updateServiceValue(groupId, active, false, dispIndex); },
        err => { this.updateServiceValue(groupId, !active, false, dispIndex); }
      );
  }

  remoteUpdate({ uid, index }): void {
    this.updateCheckBox('remote', false, true, index);

    this.dispSettingsApiService.putOffRemoteWork({user_id: uid})
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        () => {
          this.updateCheckBox('remote', false, false, index);
          this.dispatchersUpdate = this.dispatchersUpdate.filter(disp => disp.uuid !== uid);
          this.dispatchers = this.dispatchersUpdate;
          this.changeDetectorRef.detectChanges();
        },
        () => {
          this.updateCheckBox('remote', true, false, index);
        }
      );
  }

  dispatchersUpdated(data: DispatcherModel[]): void {
    this.dispatchersUpdate = data;
  }

  private generateForm(disps): void {
    this.dispSettingsService.form = this.fb.group({
      dispatchers: this.fb.array([])
    });

    disps.forEach(disp => {
      (this.dispSettingsService.form.controls.dispatchers as UntypedFormArray).push(this.dispFormGen(disp));
    });
  }

  private dispFormGen(disp?: DispatcherModel): UntypedFormGroup {
    const dispForm = this.fb.group({
      id: [disp ? disp.id : ''],
      name: [disp ? disp.name : ''],
      roles: [disp ? disp.roleIds : ''],
      brigade: [disp ? disp.brigadeId : 0],
      status: [disp ? disp.status.displayStatus : 'INACTIVE'],
      services: this.fb.array([]),
      allow_calls_from_drivers: [disp ? disp.allow_calls_from_drivers : false],
      allow_calls_from_new_clients: [disp ? disp.allow_calls_from_new_clients : false],
      allow_calls_from_passengers: [disp ? disp.allow_calls_from_passengers : false]
    });

    disp.taxiServiceIds.forEach(service => {
      (dispForm.controls.services as UntypedFormArray).push(
        this.fb.group({
          name: service,
          active: false,
          id: service
        })
      );
    });

    // mocked data @TODO REMOVE
    (dispForm.controls.services as UntypedFormArray).push(
      this.fb.group({
        name: 'KYIV 838',
        active: true,
        uuid: 112233445566
      })
    );
    (dispForm.controls.services as UntypedFormArray).push(
      this.fb.group({
        name: 'LVIV 838',
        active: false,
        uuid: 112233445567
      })
    );
    (dispForm.controls.services as UntypedFormArray).push(
      this.fb.group({
        name: 'ODESSA 838',
        active: true,
        uuid: 112233445568
      })
    );

    return dispForm;
  }

  private updateServiceValue(
    id: string,
    active: boolean,
    loading: boolean,
    index: number
  ): void {
    this.dispatchers = JSON.parse(JSON.stringify(
      this.dispatchers
        .map((disp, i) => {
          if (i === index) {
            disp.taxiServices = disp.taxiServices
              .map(service => {
                if (service.id === id) {
                  service.active = active;
                  service.loading = loading;
                }
                return service;
              });
          }
          return disp;
        })
    ));
    this.changeDetectorRef.detectChanges();
  }

  private updateCheckBox(
    key: string,
    active: boolean,
    loading: boolean,
    index: number
  ): void {
    this.dispatchers = this.dispatchers
      .map((disp, i) => {
        if (i === index) {
          disp.activeCallLines[key].active = active;
          disp.activeCallLines[key].loading = loading;
        }
        return disp;
      });
    this.changeDetectorRef.detectChanges();
  }

  private setNewStatuses(disps: any[]): void {
    this.dispatchers = this.dispatchers.map(disp => {
      const findDisp = disps.find(d => d.id === disp.id);
      if (findDisp) {
        disp.status = findDisp.status;
        disp.state = findDisp?.state;
      }
      return disp;
    });
  }

  private setDispatchers(response, id, isSorted?: boolean): void {
    let disps = response.content;
    disps = disps.map(disp => {
      let callLines = this.getActiveCallLines(disp?.callLineIds);
      callLines = Object.assign({}, {
        ...callLines,
        remote: {
          loading: false,
          active: (disp.status.remoteWorkStatus === 'REMOTELY' ? true : false)
        }
      });
      return {
        ...disp,
        activeCallLines: callLines,
        taxiServices: this.getTaxiServices(disp?.taxiServiceIds, disp.activeTaxiServiceIds)
      };
    });

    const activeDisps = disps.filter(a => a.activeTaxiServiceIds.find(i => i === id));
    const inactiveDisps = disps.filter(a => !a.activeTaxiServiceIds.find(i => i === id));
    disps = [...activeDisps, ...inactiveDisps];

    if (this.dispatchers.length === 0 || isSorted || this.selectedTaxiServiceId === 'all') {
      this.dispatchersUpdate = disps;
      this.dispatchers = this.dispatchersUpdate;
    } else {
      this.setNewStatuses(disps);
    }

    this.generateForm(disps);
    this.changeDetectorRef.detectChanges();
  }

  private getDispatchersData(): void {
    const filters: any[] = [
      { type: 'onlyActiveWorkShift', value: true },
    ];
    if (this.selectedTaxiServiceId !== 'all') {
      filters.push({ type: 'taxiServiceId', value: this.selectedTaxiServiceId });
    }
    this.dispSettingsApiService.getAllDispatchersFiltered(3000, 0,
      [
        this.sortParam,
        ...filters,
        {type: 'query', value: this.queryValue}
      ])
      .subscribe((response) => {
      this.setDispatchers(response, this.selectedTaxiServiceId, true);
    });
  }
}
