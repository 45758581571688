import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PhoneNumberService {

  private patterns: RegExp[] = [
    /^\+?38(0\d{9})$/,
    /^(0\d{9})$/,
  ];

  public getFormattedPhoneNumber(phoneNumber: string): string {
    phoneNumber = phoneNumber.trim();
    phoneNumber = phoneNumber.replace(' ', '');

    for (const pattern of this.patterns) {
      const matches = phoneNumber.match(pattern);
      if (matches) {
        return `+38${matches[1]}`;
      }
    }

    return phoneNumber;
  }

  public getShortFormattedPhoneNumber(phoneNumber: string): string {
    phoneNumber = phoneNumber.trim();
    phoneNumber = phoneNumber.replace(' ', '');

    for (const pattern of this.patterns) {
      const matches = phoneNumber.match(pattern);
      if (matches) {
        return matches[1];
      }
    }

    return phoneNumber;
  }
}
