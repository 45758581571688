import { GetDistPipe } from './get-dist.pipe';
import { GetLastAddressFromCabPipe } from './get-last-address-from-cab.pipe';
import { GetTripOfCabPipe } from './get-trip-of-cab.pipe';
import { GetColorOfProgressLinePipe } from './get-color-of-progress-line.pipe';
import { GetStatusOfRequestsPipe } from './get-status-of-requests.pipe';
import { GetStatusOfCabPipe } from './get-status-of-cab.pipe';
import { GetReqIconPipe } from './get-req-icon.pipe';
import { EtherTimePipe } from './ether-time.pipe';
import { IsRobotReqPipe } from './is-robot-req.pipe';
import { HasRestsPipe } from './has-rests.pipe';
import { GetRestInfoPipe } from './get-rest-info.pipe';
import { GetTargetIconPipe } from './get-target-icon.pipe';
import { HasOngoingPausesPipe } from './has-ongoing-pauses.pipe';
import { HasServiceReqsPipe } from './has-service-reqs.pipe';
import { WshReqProcessingTypePipe } from './wsh-req-processing-type.pipe';
import { WshReqConfirmedTypePipe } from './wsh-req-confirmed-type.pipe';

export const pipes: any[] = [
  EtherTimePipe,
  GetReqIconPipe,
  GetStatusOfCabPipe,
  GetStatusOfRequestsPipe,
  GetColorOfProgressLinePipe,
  GetTripOfCabPipe,
  GetLastAddressFromCabPipe,
  GetDistPipe,
  IsRobotReqPipe,
  HasRestsPipe,
  GetRestInfoPipe,
  GetTargetIconPipe,
  HasOngoingPausesPipe,
  HasServiceReqsPipe,
  WshReqProcessingTypePipe,
  WshReqConfirmedTypePipe
];

export * from './wsh-req-confirmed-type.pipe';
export * from './wsh-req-processing-type.pipe';
export * from './has-service-reqs.pipe';
export * from './has-ongoing-pauses.pipe';
export * from './get-target-icon.pipe';
export * from './get-rest-info.pipe';
export * from './has-rests.pipe';
export * from './is-robot-req.pipe';
export * from './get-dist.pipe';
export * from './get-last-address-from-cab.pipe';
export * from './get-trip-of-cab.pipe';
export * from './get-color-of-progress-line.pipe';
export * from './get-status-of-requests.pipe';
export * from './get-status-of-cab.pipe';
export * from './get-req-icon.pipe';
export * from './ether-time.pipe';
