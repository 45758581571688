import { Component, OnInit, OnDestroy, Optional, Inject } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WebsocketService } from 'src/app/services';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'utax-card-payment',
  templateUrl: './card-payment-modal.component.html',
  styleUrls: ['./card-payment-modal.component.scss']
})
export class CardPaymentModalComponent implements OnInit, OnDestroy {
  showSpinner = true;
  message = 'WAIT_FOR_HOLD_APPROVE';
  order: any = {};
  amount;
  connection;

  successRes = ['hold_approved', 'hold_declined', 'hold_expired', 'captured'];

  private componentDestroyed$ = new Subject();
  constructor(private ws: WebsocketService, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.order = this.data.order;
    this.amount = this.data.amount;
    this.connection = this.ws.openConnection('miranda');
    if (this.order.payment_type === 'card') {
      this.connection
        .on('invoice')
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(res => {
          if (res.data.request_id === this.order.id) {
            this.message = res.data.status;
            this.showSpinner = !(this.successRes.indexOf(this.message) + 1);
            if (res.data) {
              if (this.order?.trip?.invoices) {
                const index = this.order.trip.invoices.findIndex(invoice => invoice.id === res.data.id);
                if (index !== -1) {
                  this.order.trip.invoices[index] = res.data;
                } else {
                  this.order.trip.invoices.push(res.data);
                }
              } else {
                this.order.trip.invoices = [res.data];
              }
            }
          }
        });
    } else if (['apple_pay', 'google_pay'].includes(this.order.payment_type)) {
      this.message = 'hold_declined';
      this.showSpinner = false;
    }

    timer(20000)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.message = 'error';
        this.showSpinner = false;
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }
}
