import { Injectable } from '@angular/core';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HandleEventService } from '../services/handle-event-service.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {InfoModalComponent} from '../shared/components/modals/info-modal/info-modal.component';

const REBINDING_ERROR_CAUSE = 'The queue does not belong to the queue group';
const REBINDING_ERROR_CAUSE_NEW = 'INVALID_QUEUE';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private dialogRef: MatDialogRef<InfoModalComponent>;
  constructor(private notification: HandleEventService,
              private translate: TranslateService,
              private dialog: MatDialog
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((response: any, caught) => {
        if (response instanceof HttpErrorResponse) {
          if (
            (response.status === 400 && !!~req.url.indexOf('v2/operator')) ||
            req.url === 'http://192.168.1.24:8080/queues'
          ) {
            if (response.error.error.includes('cannot get start point')) {
               this.notification.openSnackBar('UTAX_INVALID_WAYPOINT_START', 5);
            } else if (response.error.error.includes('No route found')) {
               this.notification.openSnackBar('INVALID_POLYLINE', 5);
            }
            return throwError(response);
          } else if ((response.status === 400 || response.status === 401) && (!!~req.url.indexOf('octobus') || req.url.includes('dispatcher/oauth/refresh'))) {
            return throwError(response);
          } else if (response.status === 422 &&
            !!response.url.indexOf('operator/login') &&
            response?.error?.errors?.otp) {
            return throwError(response);
          } else if (response.status === 400 && response.error?.code === 'active_fallback_debt_exists') {
            this.dialogRef = this.dialog.open(InfoModalComponent, {
              data: {
                title: `UTAX_${response.error.code.toUpperCase()}_TITLE`,
                description: `UTAX_${response.error.code.toUpperCase()}_DESCRIPTION`
              },
              backdropClass: 'request-dialog-backdrop',
              panelClass: 'request-reject-dialog-container',
              width: '440px'
            });
            return throwError(response);
          } else {
            const error = this.getError(response, req.url);
            // delete after fix
            if (response.error.error !== 'Unauthorized' && !(response.url.includes('/octobus/v1/dispatcher/ws') && response.status === 0)) {
              this.translate.get(error).subscribe(res => {
                if (res) {
                  console.log('🚀 this.translate.get ~ res', res, response);
                  this.notification.openSnackBar(`error ${response.status}:${res}`, 5);
                }
              });
            }
            return throwError(response);
          }
        }
      })
    );
  }

  getError(response, url) {
    if (url.indexOf('http') === -1) {
      return null;
    }
    if (response.status === 0 || response.status === 500) {
      if (response.error && response.error.cause) {
        if (response.error.cause === REBINDING_ERROR_CAUSE) {
          return 'UTAX_REBINDING_REQUEST_ERROR';
        }
        return response.error.cause;
      }
      return 'Недоступен сервер ' + url.slice(0, url.lastIndexOf('/'));
    }
    if (
      response.error.status === 400 &&
      response.error.type === REBINDING_ERROR_CAUSE_NEW
    ) {
      return 'UTAX_REBINDING_REQUEST_ERROR';
    }
    if (response.error.errors && Object.keys(response.error.errors).length > 0) {
      let errorStr = '';
      for (const error in response.error.errors) {
        errorStr += response.error.errors[error] + ' ';
      }
      console.log('errorstr', errorStr);

      return errorStr.toUpperCase();
    }
    if (response.error.code) {
      return String(response.error.code).toUpperCase();
    }
    return response.error.type ? response.error.type : response.statusText;
  }
}
