import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISalaryModifierSlot } from '../models';

const SALARY_MULTIPLIER_URL = 'call-centre/api/salary-multipliers';

@Injectable()
export class SalaryModifierApiService {

  constructor(private http: HttpClient) {
  }


  public getSalaryMultipliers(): Observable<ISalaryModifierSlot[]> {
    return this.http.get<ISalaryModifierSlot[]>(SALARY_MULTIPLIER_URL);
  }

  public postSalaryMultiplier(multiplierSlot: ISalaryModifierSlot): Observable<ISalaryModifierSlot> {
    return this.http.post<ISalaryModifierSlot>(SALARY_MULTIPLIER_URL, multiplierSlot);
  }

  public putSalaryMultiplier(multiplierSlot: ISalaryModifierSlot): Observable<ISalaryModifierSlot> {
    return this.http.put<ISalaryModifierSlot>(`${SALARY_MULTIPLIER_URL}/${multiplierSlot.id}`, multiplierSlot);
  }

  public deleteSalaryMultiplier(id: string): Observable<void> {
    return this.http.delete<void>(`${SALARY_MULTIPLIER_URL}/${id}`);
  }
}
