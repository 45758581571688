import { Injectable } from '@angular/core';
import { TaxiServicesService } from './taxi-services.service';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {Observable, of, throwError} from 'rxjs';
import { YesNoModalComponent } from '../shared/components';
import { HttpClient } from '@angular/common/http';

const assignCabUrl = 'operator/requests/reserve',
  newAssignCabUrl = 'operator/requests/enqueue';

@Injectable({
  providedIn: 'root'
})
export class GeneralOrdersApiService {
  public isNewOrder = false;
  constructor(private http: HttpClient, private dialog: MatDialog, private taxiServices: TaxiServicesService) {}

  assignCab(obj: { cab_id: string; id: string; service: number }, ignoreBalance?) {
    return this.http
      .post(
        (this.taxiServices.servicesSettings[obj.service].sending_round_enabled === '1'
          ? newAssignCabUrl
          : assignCabUrl) + (ignoreBalance ? '?ignore_balance=1' : ''),
        obj
      )
      .pipe(
        catchError(err => {
          if (err.error.code === /*`queued_request_already_exists`*/ `not_enough_balance`) {
            return this.dialog
              .open(YesNoModalComponent, {
                data: {
                  title: 'UTAX_FRONT_NOT_ENOUGH_BALANCE_OF_CAB_APPROVE'
                },
                panelClass: 'yes-no-modal-container'
              })
              .afterClosed()
              .pipe(
                filter(result => result === 'YES'),
                mergeMap(() => this.assignCab(obj, true))
              );
          } else {
            console.log(err);
            return throwError(err);
          }
        })
      );
  }

  public markCreatingRequestStart(): Observable<any> {
    return this.http.put('operator/requests/creating/start', {});
  }

  public markCreatingRequestFinish(): Observable<any> {
    return this.http.put('operator/requests/creating/finish', {});
  }


}
