import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { LogistCabsService } from '../../services/logist-cabs.service';
import { OrderApiService } from '../../services';
import { Map } from 'leaflet';
import * as L from 'leaflet';
import { merge } from 'rxjs';
import { Subject } from 'rxjs';
import { DriverTrackingService } from 'src/app/services';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Service } from 'src/app/interfaces/order.interface';
import { serviceScale } from 'src/app/map/utils/map';

@Component({
  selector: 'utax-logist-map',
  templateUrl: './logist-map.component.html',
  styleUrls: ['./logist-map.component.scss']
})
export class LogistMapComponent implements OnInit, OnDestroy {
  @Input() autoscale: boolean;
  @Input() showAllDrivers: boolean;
  @Input() showDriverTrip: boolean;
  @Input() services: Service[];
  @Input() selectedTabIndex$: Subject<any>;
  @Output() toggleChange = new EventEmitter();
  minAgo = 30;
  // startTime;
  optionsEvent: Subject<any> = new Subject();
  sub;
  cab;
  polyline;

  selectedCab;

  componentDestroyed$ = new Subject();

  constructor(
    public logistCabsService: LogistCabsService,
    public ordersApiService: OrderApiService,
    private cd: ChangeDetectorRef,
    private driverTrackingService: DriverTrackingService
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  changeToggle(event: any, type: string): void {
    this.toggleChange.emit({
      type,
      state: event.checked
    });
  }

  onTimeAgoChanges() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.logistCabsService.selectedCab && this.logistCabsService.selectedCab.id !== 'all') {
      this.sub = this.driverTrackingService
        .getCabGeoInfo(
          this.logistCabsService.selectedCab,
          moment()
            .subtract(this.minAgo, 'minutes')
            .toISOString(),
          merge(this.ordersApiService.selectedOrder$, this.logistCabsService.clickedCab$)
        )
        .subscribe(res => {
          this.cab = res;
          this.polyline = res.polyline;
        });
    }
  }

  // @TODO REFACTOR
  private selectedCabScaling(map: Map, resEvent: any): void {
    if (this.autoscale && this.logistCabsService.selectedCab.location) {
      // autoscale true, scale to cab
      map.setView(
        [this.logistCabsService.selectedCab.location.lat, this.logistCabsService.selectedCab.location.lng],
        20
      );
    } else if (this.ordersApiService.selectedOrder && resEvent && resEvent.product) {
      // means order selected
      // autoscale false, scale to order
      const waypoints = [...this.ordersApiService.selectedOrder.waypoints];
      const bounds = L.latLngBounds(waypoints);
      if (bounds.isValid()) {
        map.fitBounds(bounds);
      }
    } else if (resEvent && resEvent.id) {
      // service selected
      // autoscale false, scale to city if order null
      serviceScale(
        map,
        this.services.find(s => s.id === this.logistCabsService.selectedServiceId)
      );
    } else if (resEvent === 1) {
      // tab with map selected (1 index of map tab in logist details)
      serviceScale(
        map,
        this.services.find(s => s.id === this.logistCabsService.selectedServiceId)
      );
    }
  }
  // @TODO REFACTOR
  scale = (map: Map) => {
    merge(
      this.ordersApiService.selectedOrder$,
      this.logistCabsService.visibleCabsSub$,
      this.logistCabsService.clickedCab$,
      this.optionsEvent,
      this.selectedTabIndex$
    )
      .pipe(takeUntil(this.componentDestroyed$), distinctUntilChanged(), debounceTime(100))
      .subscribe(res => {
        this.cd.markForCheck();
        if (this.showAllDrivers) {
          const cabLocations = [];
          this.logistCabsService.activeCabs.forEach(cab => {
            if (cab.service_id === this.logistCabsService.selectedServiceId) {
              cabLocations.push(cab.location);
            }
          });
          const bounds = L.latLngBounds(cabLocations);
          if (bounds.isValid() && cabLocations.length > 0) {
            map.fitBounds(bounds);
          } else if (cabLocations.length === 0 && (res.id || res === 1)) {
            // service or tab selected when no active cabs
            serviceScale(
              map,
              this.services.find(s => s.id === this.logistCabsService.selectedServiceId)
            );
          }
          return;
        }
        if (this.ordersApiService.selectedOrder && res && res.product) {
          // order selected event
          const waypoints = [...res.waypoints];
          if (this.autoscale) {
            waypoints.push(this.logistCabsService.selectedCab.location);
          }
          const bounds = L.latLngBounds(waypoints);
          if (bounds.isValid()) {
            map.fitBounds(bounds);
          }
          return;
        }
        if (this.logistCabsService.selectedCab) {
          this.selectedCabScaling(map, res);
          return;
        }
        if (res && res.callsign) {
          // cab selected event
          map.setView(
            [this.logistCabsService.selectedCab.location.lat, this.logistCabsService.selectedCab.location.lng],
            20
          );
        }
      });
  };
}
