import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const ameliaImageUrl = 'operator/images';

@Injectable({
  providedIn: 'root'
})

export class ImageService {
  settings;

  constructor(private http: HttpClient) {
  }


  getImageById(id: number): Observable<Blob> {
    return this.http.get(`images/id/${id}`, { responseType: 'blob' });
  }

  getAmeliaImageById(id: string): Observable<any> {
    return this.http.get(`AUTH/${environment.config.ameliaHttp}/${ameliaImageUrl}/${id}`, { responseType: 'blob' });
  }

  // IMAGE UPLOADING
  // fileSelected(event, type, isPersonalPhoto: boolean, index?) {
  //   let file = event.target.files[0];

  //   let fd = this.objectToFormData({
  //     images: [{
  //       file: file,
  //       type: type
  //     }]
  //   });
  //   this.http.post(this.client.getUploadImgUrl(), fd).subscribe((res: any) => {
  //     if (!isPersonalPhoto) {
  //       this.documentIds.push(res.data[0].id);
  //     } else {
  //       this.personalPhotoId = res.data[0].id;
  //       this.personalPhoto$.next(this.personalPhotoId);
  //     }
  //   });
  // }

  // FORMATING OBJECT TO FORMDATA
  // objectToFormData(obj, form?, namespace?) {
  //   let fd = form || new FormData();
  //   let formKey;
  //   for (let property in obj) {
  //     if (obj.hasOwnProperty(property)) {
  //       if (namespace) {
  //         formKey = `${namespace}[${property}]`;
  //       } else {
  //         formKey = property;
  //       }
  //       // if the property is an object, but not a File,
  //       // use recursivity.
  //       if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
  //         this.objectToFormData(obj[property], fd, formKey);
  //       } else {
  //         // if it's a string or a File object
  //         fd.append(formKey, obj[property]);
  //       }

  //     }
  //   }
  //   return fd;
  // }
}
