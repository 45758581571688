import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'utax-fines-tab',
  templateUrl: './fines-tab.component.html',
  styleUrls: ['./fines-tab.component.scss']
})
export class FinesTabComponent implements OnInit {
  @Input() form: UntypedFormGroup;

  controlNames = {
    penaltyOfferCancel: {
      enable: 'should_penalty_offer_cancel',
      value: 'penalty_offer_cancel_value',
      type: 'penalty_offer_cancel_type'
    },
    penaltyOfferReject: {
      enable: 'should_penalty_offer_reject',
      value: 'penalty_offer_reject_value',
      type: 'penalty_offer_reject_type'
    },
    penaltyTripCancel: {
      enable: 'should_penalty_trip_cancel',
      value: 'penalty_trip_cancel_value',
      type: 'penalty_trip_cancel_type'
    },
    penaltyCardTripCancel: {
      enable: 'should_penalty_card_payment_trip_cancel',
      value: 'penalty_card_payment_trip_cancel_value',
      type: 'penalty_card_payment_trip_cancel_type'
    }
  };

  constructor() {}

  ngOnInit(): void {}
}
