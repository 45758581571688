import { PhoneNumberDirective } from './phone-number.directive';
import { NumbersOnlyDirective } from './numbers-only.directive';
import {ItnValidatorDirective} from './itn-validator.directive';
import {MaxLengthDirective} from './max-length.directive';
import {TimeMaskDirective} from './time-mask.directive';
import {NumbersOnlyRegexDirective} from './numbers-only-regex.directive';

export const directives: any[] = [
  PhoneNumberDirective,
  NumbersOnlyDirective,
  ItnValidatorDirective,
  MaxLengthDirective,
  TimeMaskDirective,
  NumbersOnlyRegexDirective
];

export * from './phone-number.directive';
export * from './numbers-only.directive';
export * from './itn-validator.directive';
export * from './max-length.directive';
export * from './time-mask.directive';
export * from './numbers-only-regex.directive';
