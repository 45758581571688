import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { TariffFormsGenerationService, DriverTariffsService } from '../../services';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { YesNoModalComponent } from 'src/app/shared/components';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

@Component({
  selector: 'utax-payment-scales',
  templateUrl: './payment-scales.component.html',
  styleUrls: ['./payment-scales.component.scss']
})
export class PaymentScalesComponent implements OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() selectedPaymentIndex: number;
  @Input() operation: 'sub' | 'add';

  private dialogRef: MatDialogRef<YesNoModalComponent>;
  private componentDestroyed$: Subject<any> = new Subject();

  constructor(
    private tariffFormsService: TariffFormsGenerationService,
    private dialog: MatDialog,
    private driverTariffsService: DriverTariffsService
  ) {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  get regularPaymentsArray(): UntypedFormArray {
    if (this.form) {
      return this.form.controls.regular_payments as UntypedFormArray;
    }
  }

  get scaleFormArray(): UntypedFormArray {
    return (this.regularPaymentsArray.controls[this.selectedPaymentIndex] as UntypedFormGroup) &&
    (this.regularPaymentsArray.controls[this.selectedPaymentIndex] as UntypedFormGroup).controls.scales as UntypedFormArray;
  }

  addNewScale(): void {
    this.tariffFormsService.addEmptyFormToScalesFormArray(this.selectedPaymentIndex, this.operation);
  }

  removeScales(i: number): void {
    const scaleId = this.scaleFormArray.controls[i].value.id;
    if (scaleId) {
      const config = new MatDialogConfig();
      config.data = {
        title: 'UTAX_FRONT_DO_YOU_REALY_WANT_TO_REMOVE?'
      };
      config.panelClass = 'yes-no-modal-container';
      this.dialogRef = this.dialog.open(YesNoModalComponent, config);
      this.dialogRef
        .afterClosed()
        .pipe(
          takeUntil(this.componentDestroyed$),
          mergeMap(data => {
            if (data === 'YES') {
              return this.driverTariffsService.deleteScale(scaleId);
            } else {
              return of();
            }
          })
        )
        .subscribe((res: any) => {});
    } else {
      this.scaleFormArray.removeAt(i);
    }
  }
}
