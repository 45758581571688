import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'utax-telephony-menu-button',
  templateUrl: './telephony-menu-button.component.html',
  styleUrls: ['./telephony-menu-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TelephonyMenuButtonComponent {
  @Input() transfer: boolean;
  @Input() isConference: boolean;
  @Input() userConnected: boolean;
  @Output() transferEvent = new EventEmitter();
  @Output() conferenceEvent = new EventEmitter();
  @Output() openSettings = new EventEmitter();
  @Output() closeShift = new EventEmitter();
  @Output() startShift = new EventEmitter();
  @Output() lastCallReplay = new EventEmitter();
  menuClosed = true;
  isLastCall = false;
  isDev = !environment.production;

  constructor() {
    this.isLastCall = !!sessionStorage.getItem('lastCall');
  }
}
