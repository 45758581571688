import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HeaderToolsHandlerService } from '../../../../../navigation/services';
import { QueueService } from '../../services';
import {GlobalDataService} from '@global-services/global-data.service';

@Component({
  selector: 'utax-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueueComponent implements OnInit, AfterViewInit, OnDestroy {
  public id: string;
  private componentDestroyed$ = new Subject();

  @ViewChild('controlMenu') controlMenu: TemplateRef<any>;

  constructor(
    private queueService: QueueService,
    private headerToolsHandlerService: HeaderToolsHandlerService,
    private globalDataService: GlobalDataService,
  ) { }

  ngOnInit(): void {
    this.globalDataService.globalTaxiServices$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(ids => {
        if (!this.id && ids[0]) {
          this.id = ids[0].id;
          this.queueService.selectedGroupUuid$.next(this.id);
          this.queueService.init(this.id);
        }
      });
  }

  ngAfterViewInit(): void {
    this.headerToolsHandlerService.template.next({
      controlMenu: this.controlMenu,
      source: 'call-centre/cabinet'
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
