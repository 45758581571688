import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TelephonyService } from './telephony.service';

@Injectable({
  providedIn: 'root'
})
export class TelephonySharedService {
  // service for using telephony from other modules

  incomeCalls$: BehaviorSubject<any> = new BehaviorSubject(false);
  liteDispatcherMarginCompensation$: BehaviorSubject<number> = new BehaviorSubject(0);
  constructor(private telephonyService: TelephonyService) {
    this.telephonyService.sessions$
      .subscribe(sessions => {
        const sessionsValue = Object.values(sessions);
        if (!sessionsValue?.length) {
          this.incomeCalls$.next(null);
        }
      });
  }

  makeCall(phoneNumber, user): void {
    this.telephonyService.makeCall(phoneNumber, user);
  }

  disconnect(): void {
    this.telephonyService.disconnect();
  }
}
