import { HeaderToolsHandlerService } from '../../../navigation/services';
import { AuthService } from '../../services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { RemoteWorkService } from '../../../cabinet/services';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'utax-authentification',
  templateUrl: './authentification.component.html',
  styleUrls: ['./authentification.component.scss'],
  animations: [
    trigger('twoFactor', [
      state('show', style({
        display: 'flex',
        opacity: 1
      })),
      state('hide', style({
        transform: 'translateX(-100%)',
        opacity: 0,
        display: 'none'
      })),
      transition('show => hide', animate('600ms ease-out')),
      transition('hide => show', animate('600ms ease-in'))
    ]),
    trigger('mainInputs', [
      state('show', style({
        backgroundColor: '#E5F1F9'
      })),
      state('hide', style({
        backgroundColor: '#fff'
      })),
      transition('show => hide', animate('600ms ease-out')),
      transition('hide => show', animate('600ms ease-in'))
    ]),
    trigger('spinner', [
      state('show', style({
        display: 'block'
      })),
      state('hide', style({
        display: 'none'
      }))
    ]),
    trigger('mainButton', [
      state('show', style({
        display: 'none'
      })),
      state('hide', style({
        display: 'block'
      }))
    ])
  ]
})
export class AuthentificationComponent implements OnInit, OnDestroy, AfterViewInit {
  authForm: UntypedFormGroup;
  authentication$: Subscription;

  isShownTwoFactor = false;

  errors = '';

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private remoteWorkService: RemoteWorkService,
    private cookieService: CookieService,
    private spinner: NgxSpinnerService,
    private headerToolsHandlerService: HeaderToolsHandlerService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.authForm = this.fb.group({
      name: ['', [Validators.required]],
      password: ['', [Validators.required]],
      otp: ['']
    });
  }

  ngAfterViewInit(): void {
    this.headerToolsHandlerService.template.next({
      controlMenu: '',
      source: 'login'
    });
  }

  ngOnDestroy() {
    this.authentication$.unsubscribe();
  }

  login() {
    const cardItnArr = localStorage.getItem('custom.savedcarditn');
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.deleteAll();
    localStorage.setItem('custom.savedcarditn', cardItnArr);
    this.authentication$ = this.authService.authenticate(this.authForm.value)
      .subscribe(data => {
      console.log('PC: AuthentificationComponent -> login -> data', data);
      if (data.error) {
        if (data.error?.errors?.otp !== undefined) {
          // enter two-factor
          this.isShownTwoFactor = true;
          this.authForm.controls.otp.setValidators([Validators.required, Validators.minLength(6)]);
          this.cdr.detectChanges();
        }
        if (data.error?.errors?.name || data.error?.errors?.password) {
          this.errors = `Login or password wrong `;
        }

      }
    }, error => {
      console.log(error);
    });
  }

  get showTwoFactor() {
    return this.isShownTwoFactor ? 'show' : 'hide';
  }
}
