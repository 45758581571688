import { StateService } from './../../../../services/state.service';
import { MessageToDriverService } from './../../../services/message-to-driver.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'utax-message-to-driver',
  templateUrl: './message-to-driver.component.html',
  styleUrls: ['./message-to-driver.component.scss']
})
export class MessageToDriverComponent implements OnInit, OnDestroy {
  input = '';
  driversList = [];
  selectedDrivers = [];
  services = [];
  service;
  removable = true;
  message = '';
  filteredDrivers = [];
  LIST_SIZE = 20;
  currentLength = 20;
  groups = [];
  selectedGroups = [];
  selectAll = 'selectAll';
  selectedGroups$ = new BehaviorSubject([]);
  isDisabledSendBtn = false;
  confirmDialogRef: MatDialogRef<ConfirmModalComponent>;

  mesMaxSize = 1024;
  private componentDestroyed$ = new Subject();
  constructor(
    public dialogRef: MatDialogRef<MessageToDriverComponent>,
    public messageToDriverService: MessageToDriverService,
    private stateService: StateService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.stateService.store.pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.services = res.service;
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  loadDrivers() {
    this.selectedDrivers = [];
    this.selectedGroups = [];
    this.messageToDriverService.getDrivers(this.service).subscribe(res => {
      this.driversList = res.map(driver => {
        driver.vehicle_groups = driver.vehicle_groups || [{id: 0, name: 'Базовая автоколонна'}];
        return driver;
      });

      const tmpArr = [];

      [].concat(...res.map(driver => driver.vehicle_groups).flat()
        .filter(item => {
          if (!tmpArr.some(tempItem => tempItem.id === item.id)) {
            tmpArr.push(item);
          }
          return tmpArr.indexOf(item.id) === -1;
        }));
      this.groups = tmpArr;

      this.currentLength = this.LIST_SIZE;
      this.rebuildList();
      this.selectedGroups$.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
        this.selectedDrivers = this.driversList.filter(driver =>
          driver.vehicle_groups.find(group => this.selectedGroups.find(el => el.id === group.id))
        );
      });
    });
  }

  getFullName(driver) {
    return `${driver.callsign} ${driver.last_name} ${driver.first_name}`;
  }

  remove(driver) {
    this.selectedDrivers = this.selectedDrivers.filter(el => el.id !== driver.id);

    console.log('3 -> this.selectedDrivers', this.selectedDrivers);
  }

  scroll() {
    this.currentLength = Math.min(this.currentLength + this.LIST_SIZE, this.driversList.length);
    this.rebuildList();
  }

  rebuildList() {
    const selectedDrivers = this.selectedDrivers;
    this.driversList = this.driversList.map(el => {
      return {
        ...el,
        showOption:
          ~el.callsign.search(this.input) || ~el.first_name.search(this.input) || ~el.last_name.search(this.input)
      };
    });
    this.selectedGroups = [];
    setTimeout(() => {
      this.selectedDrivers = [...selectedDrivers];
    }, 50);
  }

  sendData() {
    this.isDisabledSendBtn = true;
    this.messageToDriverService
      .sendMessage(
        this.selectedDrivers.map(el => {
          return { receiver_id: el.id };
        }),
        this.message,
        this.service,
        Array.from(new Set(
          this.selectedGroups
            .concat(this.selectedDrivers.map(el => el.vehicle_groups).flat())
            .filter(el => el.id)
            .map(el => el.id)
        ))
      )
      .subscribe(res => {
        this.isDisabledSendBtn = false;
        this.dialogRef.close();
      });
  }

  send() {
    if (this.selectedDrivers.length > 4) {
      this.confirmDialogRef = this.dialog.open(ConfirmModalComponent, {
        data: { title: this.selectedDrivers.length },
        disableClose: true,
        panelClass: 'confirm-modal-container'
      });
      this.confirmDialogRef.afterClosed().subscribe(data => {
        if (data === 'YES') {
          this.sendData();
        }
        if (data === 'NO') {
          return null;
        }
      });
    } else {
      this.sendData();
    }
  }

  selectAllChange() {
    if (this.selectedGroups.find(el => el === this.selectAll)) {
      this.selectedGroups = [...this.groups, this.selectAll];
      this.selectedDrivers = this.driversList;
    } else {
      this.selectedGroups = [];
      this.selectedDrivers = [];
    }
  }

  selectGroup(): void {
    if (this.selectedGroups.some(group => group === this.selectAll)) {
      this.selectedGroups = this.selectedGroups.filter(item => item !== this.selectAll);
    } else if (this.selectedGroups.length === this.groups.length) {
      this.selectedGroups = [...this.selectedGroups, this.selectAll];
    }
  }

  driverClick(driver: any): void {
    if (!this.selectedDrivers.some(item => item.id === driver.id)) {
      this.selectedGroups = this.selectedGroups.filter(group => {
        return !driver.groups.some(item => item === group);
      });
      this.selectGroup();
    }
  }

  getSelectCaption(selectedGroups: Array<any>) {
    if (selectedGroups === []) {
      return 'SELECT_DRIVERS_BY_GROUP';
    }
    if (~selectedGroups.indexOf(this.selectAll)) {
      return this.selectAll.toUpperCase();
    }
    return selectedGroups.map(group => group?.name).join(', ');
  }

  groupsSelectionChange(event) {
    this.selectedGroups$.next([]);
  }

  sendToAll() {
    this.messageToDriverService.sendMessageToAll(this.service, this.message).subscribe(es => {
      this.dialogRef.close();
    });
  }
}
