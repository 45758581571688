import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getAddressPlaceholder'
})
export class GetAddressPlaceholderPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(indexOfAddress: any, addressesLength?: number): any {
    switch (indexOfAddress) {
      case 0:
        return this.translate.instant('START_ADDRESS');
      case addressesLength - 1:
        return this.translate.instant('END_ADDRESS');
      default:
        return `${indexOfAddress + 1}. ${this.translate.instant('MIDDLE_ADDRESS')}`;
    }
  }
}
