import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'utax-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit {
  @Input() totalUsersNumber: number;

  form: UntypedFormGroup = new UntypedFormGroup({
    search: new UntypedFormControl()
  });

  private componentDestroyed$ = new Subject();
  @Output() private search: EventEmitter<string> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {
      this.search.emit(value.search);
    });
  }
}
