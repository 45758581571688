import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {of, Subject} from 'rxjs';
import {
  DispatcherModel,
  DispatcherWorkStatus,
  DispCallStatus,
  DispRole,
  IAddMultiQueueGroupQuery,
  IBrigade
} from '../../../models';
import {DispSettingsApiService, DispSettingsService} from '../../../services';
import {
  isFitInBrigades,
  isFitInRoles, isIncomingCompanyDriverFilter,
  isIncomingDriverFilter,
  isName,
  isQueueGroupFit,
  isStatusFit
} from '../../../utils/table-filters';
import {GlobalDataService} from '@global-services/global-data.service';
import {IRole, ITaxiService} from '../../../../../../models/global-data.interfaces';
import {MatSort} from '@angular/material/sort';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {catchError, switchMap, tap} from "rxjs/operators";


const FIRST_CONTACT_ID = '1000050000';
const DROGOBYCH_ID = '2933999283'; // 3341312913
const TRUSKAVETS_ID = '1167282226'; // 2067800749

@UntilDestroy()
@Component({
  selector: 'utax-disp-settings-disp-table',
  templateUrl: './disp-settings-disp-table.component.html',
  styleUrls: ['./disp-settings-disp-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispSettingsDispTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  @Input() dispatchersUpdate: DispatcherModel[] = [];
  @Input('dispatchers')
  set setDispatchers(dispatchers: DispatcherModel[]) {
    this.dispatchers = (dispatchers && dispatchers.map((disp, i) => {
      return {
        ...disp,
        brigade: this.getBrigade(disp.brigadeId)?.name,
        hideActivationAllServices: !disp.roleIds.some(roleId => this.getRoleCheck(roleId, 'ROLE_DISPATCHER'))
      };
    })) || [];
    this.filterTable();
  }
  @Input('roles')
  set setRoles(roles: IRole[]) {
    this.roles = roles;
    this.rolesFilter = this.roles.map(role => role.id);
  }
  @Input('queueGroups')
  set setQueueGroups(queueGroups: ITaxiService[]) {
    this.queueGroups = queueGroups;
    this.queueGroupsFilter = this.queueGroups.map(group => group.id);
    this.selectedAll = (this.queueGroupsFilter.length === this.queueGroups.length);
  }
  @Input('brigades')
  set setBrigades(brigades: IBrigade[]) {
    this.brigades = brigades;
    this.brigadesFilter = this.brigades;
  }
  @Output() checkboxUpdate = new EventEmitter();
  @Output() remoteUpdate = new EventEmitter();
  @Output() queueGroupUpdate = new EventEmitter();
  @Output() updateDispatchers = new EventEmitter();
  @Output() onSorted = new EventEmitter();


  dataSource;
  sortParams: any;
  dispatchers: DispatcherModel[];
  filterForm;
  displayedColumns: string[] = [
    'status',
    'name',
    'state',
    'global_user_name',
    'roles',
    'brigade',
    'services',
    'allow_calls_from_drivers',
    'allow_calls_from_company_drivers',
    'allow_calls_from_passengers',
    'allow_calls_from_new_clients',
    'is_remoute'
  ];
  dispStatuses = DispatcherWorkStatus;
  callStatuses = DispCallStatus;

  statusesFilter = ['FREE', 'INACTIVE', 'TALKING', 'DIALED'];
  rolesFilter = [];
  roles = [];
  queueGroups = [];
  queueGroupsFilter = [];
  brigades = [];
  brigadesFilter = [];
  selectedAll: boolean = false;
  selectedAllBrigades = false;
  preventSingleClick = false;
  dbClickTimer = null;
  public incomingDriverFilter: 'all' | 'only' | 'without' = 'all';
  public incomingCompanyDriverFilter: 'all' | 'only' | 'without' = 'all';

  private componentDestroyed$ = new Subject();

  constructor(
    public dispSettingsService: DispSettingsService,
    public dispSettingsApiService: DispSettingsApiService,
    public globalDataService: GlobalDataService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  getBrigade(id): any {
    return this.globalDataService.globalBrigades$.value.find(v => v.id === id);
  }

  getRole(id): string {
    return this.globalDataService.globalRoles$.value.find(v => v.id === id)?.name;
  }

  getRoleCheck(id, role): boolean {
    return this.globalDataService.globalRoles$.value.find(v => v.id === id)?.permissions.some(p => p.type === role);
  }

  serviceClick(service, serviceIndex: number, dispIndex: number): void {
    this.preventSingleClick = false;
    this.dbClickTimer = setTimeout( () => {
      if (!this.preventSingleClick) {
        const findIndex = this.dispatchers.findIndex(disp => disp.id === this.dispatchersUpdate[dispIndex].id);
        this.queueGroupUpdate.emit({
          uuid: this.dispatchersUpdate[dispIndex].id,
          externalId: this.dispatchersUpdate[dispIndex].externalId,
          active: !this.dispatchersUpdate[dispIndex].taxiServices[serviceIndex].active,
          groupId: this.dispatchersUpdate[dispIndex].taxiServices[serviceIndex].id,
          externalGroupId: this.dispatchersUpdate[dispIndex].taxiServices[serviceIndex].externalId,
          dispIndex: findIndex
        });
      }
    }, 300);

  }

  checkboxChange(callLine: any, name: string, event: any, index: number): void {
    this.dispatchersUpdate[index].loading = true;
    const findIndex = this.dispatchers.findIndex(disp => disp.id === this.dispatchersUpdate[index].id);
    this.checkboxUpdate.emit({
      id: this.dispatchersUpdate[index].id,
      callLineId: callLine.id,
      name,
      allow: event.checked,
      index: findIndex
    });
  }

  remoteChange(event: any, index: number): void {
    if (event.checked === false) {
      this.dispatchersUpdate[index].loading = true;
      const findIndex = this.dispatchers.findIndex(disp => disp.id === this.dispatchersUpdate[index].id);
      this.remoteUpdate.emit({
        uid: this.dispatchersUpdate[index].externalId,
        index: findIndex
      });
    }
  }

  selectAll(): void {
    this.queueGroupsFilter = this.queueGroups.map(item => item.id);
    this.filterTable();
  }

  deselectAll(): void {
    this.queueGroupsFilter = [];
    this.filterTable();
  }

  selectAllBrigades(): void {
    this.brigadesFilter = this.brigades.map(item => item);
    this.filterTable();
  }

  deselectAllBrigades(): void {
    this.brigadesFilter = [];
    this.filterTable();
  }

  sortData(sort: any): any {
    if (sort) {
      this.onSorted.emit([
        `${(sort.active === 'global_user_name') ? 'name' : sort.active},${sort.direction}`
      ]);
    }
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  filterTable(): void {
    const newData = [];
    this.dispatchers.forEach((disp: DispatcherModel) => {
      let fit = true;
      if (this.brigadesFilter.length !== this.brigades.length) {
        fit = isFitInBrigades(disp, this.brigadesFilter);
      }
      if (fit && this.rolesFilter.length !== this.roles.length) {
        fit = isFitInRoles(disp, this.rolesFilter);
      }
      if (fit && this.statusesFilter.length !== 4) {
        fit = isStatusFit(disp, this.statusesFilter);
      }
      if (fit && this.queueGroupsFilter.length !== this.queueGroups.length) {
        fit = isQueueGroupFit(disp, this.queueGroupsFilter);
      }
      if (fit && this.filterForm?.value?.name) {
        fit = isName(disp, this.filterForm.value.name);
      }
      if (fit && this.incomingDriverFilter !== 'all') {
        fit = isIncomingDriverFilter(disp, this.incomingDriverFilter);
      }
      if (fit && this.incomingCompanyDriverFilter !== 'all') {
        fit = isIncomingCompanyDriverFilter(disp, this.incomingCompanyDriverFilter);
      }

      if (fit) {
        newData.push(disp);
      }
    });

    this.updateDispatchers.emit(newData);
    this.dispatchersUpdate = newData;
    this.dataSource = new MatTableDataSource(newData);
    this.dataSource.sort = this.sort;
    this.selectedAll = this.queueGroupsFilter.length === this.queueGroups.length;
    this.selectedAllBrigades = this.brigadesFilter.length === this.brigades.length;
    this.changeDetectorRef.detectChanges();
  }

  changeIncomingDriverFilter() {
    if (this.incomingDriverFilter === 'all') {
      this.incomingDriverFilter = 'only';
    } else if (this.incomingDriverFilter === 'only') {
      this.incomingDriverFilter = 'without';
    } else {
      this.incomingDriverFilter = 'all';
    }
    this.filterTable();
  }
  changeIncomingCompanyDriverFilter() {
    if (this.incomingCompanyDriverFilter === 'all') {
      this.incomingCompanyDriverFilter = 'only';
    } else if (this.incomingCompanyDriverFilter === 'only') {
      this.incomingCompanyDriverFilter = 'without';
    } else {
      this.incomingCompanyDriverFilter = 'all';
    }
    this.filterTable();
  }

  public activateAllServices(disp: any) {
    if (!disp.hideActivationAllServices) {
      const previousServiceActive = disp.taxiServices.filter(s => s.active || !s.isInternal).map(s => s.externalId);
      const newServiceActive = disp.taxiServices.filter(s => !s.isInternal).map(s => s.externalId);
      this.dispSettingsApiService.addMultiQueueGroup(
        disp.externalId,
        newServiceActive.filter(s => (+s !== +FIRST_CONTACT_ID && +s !== +TRUSKAVETS_ID && +s)),
        {
          override: true,
          strict: false,
          strict_unbind: false
        }
      ).pipe(
        switchMap((services) => {
          const serviceIds = disp.taxiServices
            .filter(s => s.isInternal || services.data.some(service => +service.id === +s.externalId) || !s.externalId && s.mappingExternalId)
            .map(s => s.id);
          return this.dispSettingsApiService.activateMoreService(disp.id, serviceIds)
            .pipe(
              tap((activeServices) => {
                const taxiServiceIds = activeServices.map(s => s.id);
                disp.taxiServices.forEach(service => {
                  service.active = taxiServiceIds.includes(service.id);
                });
                this.filterTable();
              }),
              catchError((err) => {
                return this.dispSettingsApiService.addMultiQueueGroup(
                  disp.externalId,
                  previousServiceActive.filter(s => (+s !== +FIRST_CONTACT_ID && +s !== +TRUSKAVETS_ID && +s)),
                  {
                    override: true,
                    strict: false,
                    strict_unbind: false
                  });
              })
            );
        }),
        untilDestroyed(this)
      ).subscribe();
    }
  }

  activateOnlyOneServices(disp: any, service) {
    this.preventSingleClick = true;
    clearTimeout(this.dbClickTimer);
    this.dbClickTimer = null;
    if (!disp.hideActivationAllServices) {
      const previousServiceActive = disp.taxiServices.filter(s => s.active || !s.isInternal).map(s => s.externalId);
      const newServiceActive = service.isInternal ? null : [service.externalId];
      of(newServiceActive).pipe(
        switchMap((newService) => {
            return this.dispSettingsApiService.addMultiQueueGroup(
              disp.externalId,
              newService ? newService.filter(s => (+s !== +FIRST_CONTACT_ID && +s !== +TRUSKAVETS_ID && +s)): [],
              {
                override: true,
                strict: true,
                strict_unbind: true
              }
            );
        }),
        switchMap((services) => {
          let serviceIds = disp.taxiServices
            .filter(s => services.data.some(service => +service.id === +s.externalId))
            .map(s => s.id);
          if(!serviceIds.includes(service.id)) {
            serviceIds.push(service.id);
          }
          return this.dispSettingsApiService.activateMoreService(disp.id, serviceIds)
            .pipe(
              tap((activeServices) => {
                const taxiServiceIds = activeServices.map(s => s.id);
                disp.taxiServices.forEach(service => {
                  service.active = taxiServiceIds.includes(service.id);
                });
                this.filterTable();
              }),
              catchError((err) => {
                return this.dispSettingsApiService.addMultiQueueGroup(
                  disp.externalId,
                  previousServiceActive.filter(s => (+s !== +FIRST_CONTACT_ID && +s !== +TRUSKAVETS_ID && +s)),
                  {
                    override: true,
                    strict: true,
                    strict_unbind: true
                  });
              })
            );
        }),
        untilDestroyed(this)
      ).subscribe();
    }
  }
}
