import { Pipe, PipeTransform } from '@angular/core';
import { BrandingType } from '../models/branding.model';

@Pipe({
  name: 'scaleErrorHandle'
})
export class ScaleErrorHandlePipe implements PipeTransform {
  transform(brandingTypeIds: string[], brandingTypes: BrandingType[]): string {
    if (brandingTypeIds && brandingTypeIds.length > 0) {
      const typeId = brandingTypeIds[0];
      const brandingType = brandingTypes.find(type => type.id === typeId);
      return brandingType ? brandingType.name : null;
    } else {
      return null;
    }
  }
}
