import { Injectable } from '@angular/core';
import { WebSocketSubject } from 'rxjs/webSocket';
import { WebsocketService } from '../../services';

@Injectable()
export class TelephonyWsService {

  constructor(private websocketService: WebsocketService) { }

  connectOperatorStatus(): any {
    return this.websocketService.openConnection('callCenter');
  }
}
