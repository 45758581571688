import { BlockDriverReasonModalComponent } from './../block-driver-reason/block-driver-reason.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { TaxiServicesService } from 'src/app/services/taxi-services.service';
import { mergeMap, debounceTime, distinctUntilChanged, pluck } from 'rxjs/operators';
import { CabsApiService } from 'src/app/services/cabs-api.service';
import { of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { GetBlockReasonService } from 'src/app/dispatcher/services/get-block-reason.service';

@Component({
  selector: 'utax-block-driver',
  templateUrl: './block-driver.component.html',
  styleUrls: ['./block-driver.component.scss']
})
export class BlockDriverModalComponent implements OnInit {
  choseCabForm: UntypedFormGroup;
  cabs = [];
  drivers: Array<any>;
  services: any;
  serviceId: any;

  constructor(
    public sanitizer: DomSanitizer,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private taxiServicesService: TaxiServicesService,
    private cabsApiService: CabsApiService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BlockDriverModalComponent>,
    public BlockDriverReasonRef: MatDialogRef<BlockDriverReasonModalComponent>,
    private getBlockReasonService: GetBlockReasonService
  ) {
    this.services = this.taxiServicesService.TaxiServicesList;
  }

  ngOnInit() {
    this.buildForm();

    this.choseCabForm.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        mergeMap((formValue: { service: number; search: string }) => {
          if (formValue.search.length > 0) {
            this.serviceId = formValue.service;
            return this.cabsApiService.getDrivers({
              service: formValue.service,
              query: formValue.search
            });
          } else {
            return of('No cabs');
          }
        })
      )
      .subscribe((obj: any) => {
        if (obj.data) {
          this.drivers = obj.data;
        }
      });
  }

  blockDriverReason(obj) {
    if (!obj.is_blocked) {
      const data = {
        service: this.serviceId,
        id: obj.id
      };

      this.getBlockReasonService.getRemoveDriverReason().subscribe((res: any) => {
        const config = new MatDialogConfig();
        config.data = {
          ...data,
          reasons: res.data
        };

        config.panelClass = 'message-to-driver-modal-container';
        this.getblockDriverReason(config);
      });
    }
  }

  getblockDriverReason(config) {
    this.BlockDriverReasonRef = this.dialog.open(BlockDriverReasonModalComponent, config);
    this.BlockDriverReasonRef.afterClosed().subscribe(data => {
      if (data.id) {
        this.getBlockReasonService.driverBlock(data).subscribe(
          (res: any) => {
            this.dialogRef.close(true);
          },
          err => {
            console.log(err);
          }
        );
      }
      this.BlockDriverReasonRef = null;
    });
  }

  buildForm() {
    this.choseCabForm = this.fb.group({
      service: [this.services[0].id],
      search: ['']
    });
  }

  close() {
    this.dialogRef.close();
  }
  public selectService() {
    this.drivers = [];
    this.choseCabForm.controls.search.setValue('')
  }
}
