import { HandleEventService } from '../../../services/handle-event-service.service';
import { OrderFormService } from './../../services/order-form.service';
import { ApiOrderFormService } from './../../services/api-order-form.service';
import { Component, OnInit, Input, AfterViewInit, OnDestroy, Output, HostListener, EventEmitter } from '@angular/core';
import { YesNoModalComponent } from '../../../shared/components';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {catchError, filter, finalize, mergeMap, takeUntil, tap} from 'rxjs/operators';
import { OrderFormComponent } from '../../containers';
import { SaveOrderConfirmModalComponent } from '../save-order-confirm-modal/save-order-confirm-modal.component';
import {of, timer, Subject, interval, throwError} from 'rxjs';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { OrderModalService } from '../../services';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {OrdersService} from '@global-services/orders.service';
import {DispOrdersService} from "../../../dispatcher/services";

@Component({
  selector: 'utax-order-form-main-buttons',
  templateUrl: './order-form-main-buttons.component.html',
  styleUrls: ['./order-form-main-buttons.component.scss']
})
export class OrderFormMainButtonsComponent implements OnInit, AfterViewInit, OnDestroy {
  dialogRef: MatDialogRef<YesNoModalComponent | SaveOrderConfirmModalComponent>;
  @Input() saveOrderEvent$: Subject<any>;

  shortcuts: ShortcutInput[] = [];

  duplicationNumber = 0;

  timerString: string;
  timerEnding = false;
  saveLoading = false;
  duplicateLoading = false;
  driveDelLoading = false;
  private timeToLockInMilisec = 0;

  private componentDestroyed$ = new Subject();
  private actionYesNoModal = '';

  constructor(
    private ordFormApi: ApiOrderFormService,
    private dialog: MatDialog,
    private orderDialog: MatDialogRef<OrderFormComponent>,
    public orderFormService: OrderFormService,
    private handleEventService: HandleEventService,
    public orderModalService: OrderModalService,
    private translateService: TranslateService,
    public ordersService: OrdersService,
    private dispOrdersService: DispOrdersService,
  ) {}

  ngOnInit() {
    this.orderFormService.disableSaveButtons$.next(false);
    this.saveOrderEvent$.pipe(takeUntil(this.componentDestroyed$)).subscribe(data => {
      if (data) {
        this.save();
      }
    });
  }

  enableEdit() {
    this.orderModalService.lock(
      { id: this.orderFormService.order.id, mode: 'normal' },
      this.ordersService.currentOrders.get(this.orderFormService.order.id)
    ).subscribe((res) => {
      if (res) {
        this.timeToLockInMilisec = this.orderFormService.getTimeToLock();

        interval(1000)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(data => {
            if (this.timeToLockInMilisec > 0) {
              this.timeToLockInMilisec = this.timeToLockInMilisec - 1000;
              if (this.timeToLockInMilisec < 60000) {
                this.timerEnding = true;
              }
            }
            this.timerString = this.millisToMinutesAndSeconds(this.timeToLockInMilisec);
          });

        timer(this.orderFormService.getTimeToLock())
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(() => {
            const translation = this.translateService.instant('UTAX_ORDER_EDIT_ALLOWED_TIME', {time: this.orderFormService.getTimeToLock() / 60000});
            this.handleEventService.openSnackBar(translation);
            this.orderFormService.disableSaveButtons$.next(true);
            this.orderFormService.generalForm.disable();
          });
      }
    });
  }

  saveOrder() {
    if (!this.saveLoading) {
      this.save();
      this.saveLoading = true;
    }
  }

  save(closeForm = true, duplication?: boolean) {
    if (this.orderFormService.disableSaveButtons$.value) {
      return;
    }
    const order = this.orderFormService.order;
    // open confirm modal if amount was changed and it`s editing
    if (
      order &&
      this.orderFormService.fare &&
      order.fare.amount !== this.orderFormService.amount &&
      !duplication &&
      !this.ordFormApi.yesNoModalOpened
    ) {
      this.ordFormApi.yesNoModalOpened = true;
      const overdraft = this.orderFormService?.order?.passenger_overdraft?.amount || 0;
      this.dialogRef = this.ordFormApi.openYesNoModal(order.fare.amount, this.orderFormService.amount, overdraft);
      let waitForHold = false;

      this.dialogRef
        .afterClosed()
        .pipe(
          mergeMap(modalInfo => {
            this.ordFormApi.yesNoModalOpened = false;

            if (modalInfo === 'SAVE') {
              const isCard = order && order.trip && order.is_card_payment;

              if (isCard && this.orderFormService.amount > order.fare.amount) {
                this.ordFormApi.openCardPayFrom(order, this.orderFormService.amount + overdraft);
                waitForHold = true;
              }
              return this.ordFormApi.saveOrder();
            } else {
              this.saveLoading = false;
              return of();
            }
          })
        )
        .subscribe(
          res => {
            this.handleEventService.openSnackBar('UTAX_FRONT_ORDER_SAVED');
            if (closeForm && !waitForHold && this.orderDialog) {
              this.orderDialog?.close(res);
            }
          },
          err => {
            this.saveLoading = false;
            console.log('TCL: OrderFormMainButtonsComponent -> save -> err', err);
          }
        );
    } else {
      if (duplication) {
        this.orderFormService.order = null;
      }
      this.ordFormApi.saveOrder(duplication)
        .pipe(
          tap(res => console.log('resa', res)),
          catchError(err => {
            this.saveLoading = false;
            this.duplicateLoading = false;
            this.orderFormService.disableSaveButtons$.next(false);
            console.log('TCL: OrderFormMainButtonsComponent -> save -> err', err);
            return throwError(err);
          })
        )
        .subscribe(
        res => {
          if (res === 'closed') {
            this.orderFormService.order = order;
            this.saveLoading = false;
            this.duplicateLoading = false;
          } else {
            if (duplication) {
              ++this.duplicationNumber;
              this.orderFormService.order = order;
              this.handleEventService.openSnackBar(`UTAX_FRONT_DUPLICATED_ORDER_NUMBER_${this.duplicationNumber}`);
              this.duplicateLoading = false;
            } else {
              this.saveLoading = false;
              this.handleEventService.openSnackBar('UTAX_FRONT_ORDER_SAVED');
            }
          }
          if (closeForm && this.orderDialog) {
            this.orderDialog?.close(res);
          }
        },
        err => {
          this.saveLoading = false;
          this.duplicateLoading = false;
          console.log('TCL: OrderFormMainButtonsComponent -> save -> err', err);
        }
      );
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: ['f9'],
        command: e => {
          if (this.orderModalService.mode === 'default') {
            this.enableEdit();
          } else if (!this.orderFormService.disableSaveButtons$.value &&
            this.orderFormService.overdraftIncluded$.value && this.orderFormService.generalForm.valid) {
            this.saveOrder();
          }
        },
        allowIn: [AllowIn.Input, AllowIn.Select],
        preventDefault: true
      },
      {
        key: ['esc'],
        command: e => {
          this.cancel();
        },
        allowIn: [AllowIn.Input, AllowIn.Select],
        preventDefault: true
      }
    );
  }

  ngOnDestroy(): void {
    this.ordersService.delDriverFromOrderStatus$.next('allowed');
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  duplicate() {
    if (!this.duplicateLoading) {
      this.save(false, true);
      this.duplicateLoading = true;
    }
  }

  unassignCab(): void {
    this.ordersService.delDriverFromOrderStatus$.next('processing');
    this.dispOrdersService.unassignCab();
  }

  cancel() {
    const addressesFormArray = (this.orderFormService.generalForm.controls.addresses as UntypedFormGroup).controls
      .addresses as UntypedFormArray;
    const closeWithoutModal = !addressesFormArray.value[0].name || !addressesFormArray.value[1].name;

    if (this.orderFormService.generalForm.untouched || closeWithoutModal) {
      if (this.orderDialog) {
        this.orderDialog?.close();
      }
    } else {
      this.dialogRef = this.dialog.open(YesNoModalComponent, {
        data: { title: 'CLOSE_ORDER_FORM' },
        panelClass: 'yes-no-modal-container'
      });
      this.dialogRef
        .afterClosed()
        .pipe(
          tap(action => this.actionYesNoModal = action),
          filter(e => e === 'YES')
        )
        .subscribe(res => {
          if (this.orderDialog) {
            this.orderDialog?.close();
          }
        });
    }
  }

  private millisToMinutesAndSeconds(millis: number): string {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ':' + (+seconds < 10 ? '0' : '') + seconds;
  }

  @HostListener('window:beforeunload', ['$event'])
  public beforeunloadHandler($event) {
    if (this.orderFormService.order && this.orderFormService.order.id && this.orderModalService.mode !== 'default') {
      this.orderModalService.unlock(this.orderFormService.order.id).subscribe(() => {
        this.ordFormApi.closeOrderForm$.next(true);
      });
      $event.returnValue = 'Are you sure?';
    }
  }

  @HostListener('window:keydown', ['$event'])
  hotkeyDown($event) {
    if ($event.keyCode === 27 &&
      this.actionYesNoModal !== 'NO-ESC' &&
      (!this.dialogRef || this.dialogRef && this.dialogRef.getState() === 2)
    ) { // esc
      this.cancel();
    }
    this.actionYesNoModal = '';
  }
}
