import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/services';
import { filter } from 'rxjs/operators';
import * as momentTz from 'moment-timezone';

const timezoneUrl = 'operator/services/timezones';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  constructor(
    private http: HttpClient,
    private authService: AuthService
    ) {}

  timezones = {};

  loadTimezones() {
    this.authService.authState.pipe(filter(state => state)).subscribe(() =>
      this.http.get(timezoneUrl).subscribe((res: any) => {
        res.data.forEach(el => {
          this.timezones[el.service_id] = el.timezone;
        });
      })
    );
  }

  private getTimezone() {
    return this.timezones[+localStorage.getItem('service')] || 'Europe/Kiev';
  }

  utcToTz(time) {
    return momentTz
      .utc(time)
      .tz(this.getTimezone());
  }

  tzToUtc(time) {
    return momentTz
      .tz(this.getTimezone())
      .tz('UTC');
  }
}
