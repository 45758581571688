import {HttpClient} from '@angular/common/http';
import {StateService} from '../../services';
import {Client} from '../../shared/services';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';


@Injectable()
export class TariffLoadService {
  constructor(
    private http: HttpClient,
    private state: StateService,
    private client: Client
  ) { }

  getTariffs(service): Observable<any> {
    return this.http.get(this.client.getTariffPlanListUrl({ service_id: service }));
  }

}
