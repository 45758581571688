import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { MaterialModule } from './../utils/material.module';
import { MapModule } from '../map/map.module';
import { SharedModule } from '../shared/shared.module';

// services
import * as fromServices from './services';

// containers
import * as fromContainers from './containers';

// components
import * as fromComponents from './components';

// pipes
import * as fromPipes from './pipes';

import { NgxMaskModule } from 'ngx-mask';
import { GetReqIconHistoryPipe } from './pipes/get-req-icon-history.pipe';

@NgModule({
    declarations: [...fromComponents.components, ...fromContainers.containers, ...fromPipes.pipes, GetReqIconHistoryPipe],
    imports: [
        MaterialModule,
        MapModule,
        CommonModule,
        TranslateModule,
        ScrollingModule,
        ReactiveFormsModule,
        SharedModule,
        FormsModule,
        KeyboardShortcutsModule,
        NgxMaskModule.forChild()
    ],
    providers: [...fromServices.services]
})
export class LogistModule {}
