import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getWaypoint'
})
export class GetWaypointPipe implements PipeTransform {

  constructor() { }

  transform(waypoints, place): any {
    return waypoints.filter(point => {
      return point.type === place;
    })[0].name;
  }
}
