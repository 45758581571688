import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HeaderToolsHandlerService } from '../../../../../navigation/services';
import { DispSalaryService } from '../../services';

@Component({
  selector: 'utax-disp-salary',
  templateUrl: './disp-salary.component.html',
  styleUrls: ['./disp-salary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispSalaryComponent implements OnInit, AfterViewInit {

  @ViewChild('controlMenu') controlMenu: TemplateRef<any>;

  constructor(private dispSalaryService: DispSalaryService,
              private headerToolsHandlerService: HeaderToolsHandlerService) { }

  ngOnInit(): void {
    this.dispSalaryService.init();
  }

  ngAfterViewInit(): void {
    this.headerToolsHandlerService.template.next({
      controlMenu: this.controlMenu,
      source: 'call-centre/cabinet'
    });
  }
}
