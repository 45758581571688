import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[utaxMaxLength]'
})
export class MaxLengthDirective {
  @Input() utaxMaxLength;
  constructor() {}

  @HostListener('keydown', ['$event']) onKeydown(event) {
    const value = event.target.value;
    const maxLength = parseInt(this.utaxMaxLength);
    const keycode = event.which || event.keycode;
    const allowedKeycodes = [8, 13, 46, 37, 38, 39, 40];
    const keyCodeIndex = allowedKeycodes.indexOf(keycode);
    if (keyCodeIndex !== -1){
      return;
    }
    if (value.length > maxLength - 1) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
