import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'utax-logist-service-filters',
  templateUrl: './logist-service-filters.component.html',
  styleUrls: ['./logist-service-filters.component.scss']
})
export class LogistServiceFiltersComponent implements OnInit {
  @Input() services: any[];
  @Input() cabs: any[];
  @Input() selectedServiceId: number;
  @Input() visibleCabs: any[];
  @Output() selectService: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  select(event): void {
    this.selectService.emit(event);
  }
}
