import { CabTarget } from 'src/app/interfaces/target.interface';

export function getRequestTypeString(requestType: number): string {
  let requestTypeString;

  switch (requestType) {
    case 0:
      requestTypeString = 'START_WORKING_SHIFT';
      break;
    case 1:
      requestTypeString = 'FINISH_WORKING_SHIFT';
      break;
    case 2:
      requestTypeString = 'VEHICLE_REFILL';
      break;
    case 3:
      requestTypeString = 'START_VEHICLE_WASHING';
      break;
    case 4:
      requestTypeString = 'FINISH_VEHICLE_WASHING';
      break;
    case 5:
      requestTypeString = 'STORE_DRIVER_EXPENSE';
      break;
    case 6:
      requestTypeString = 'START_DRIVER_REST';
      break;

    default:
      requestTypeString = 'NO_REQUESTS';
      break;
  }

  return requestTypeString;
}

interface RequestIcon {
  name: string;
  color: string;
}

export function getReqIcon(reqType: number, reqStatus: string, targets?: CabTarget[]): RequestIcon {
  const icon = {
    name: '',
    color: 'primary'
  };
  switch (reqType) {
    case 0:
      icon.name = 'open_shift';
      break;

    case 1:
      icon.name = 'finish_shift';
      break;

    case 2:
      if ((targets || []).some(target => target.type === 'fuel_station' && target.is_reached)) {
        icon.name = 'refill_req_crosed';
      } else {
        icon.name = 'refill_req';
      }
      icon.color = 'warn';
      break;

    case 3:
      if ((targets || []).some(target => target.type === 'washer' && target.is_reached)) {
        icon.name = 'washing_req_crosed';
      } else {
        icon.name = 'washing_req';
      }
      icon.color = 'warn';
      // console.log('icon case 3', icon);
      break;
    case 6:
      icon.name = 'coffee';
      if (reqStatus === 'waiting') {
        icon.color = 'primary';
      }
      break;
    case 7:
      icon.name = 'cancel';
      icon.color = 'warn';
      break;

    default:
      console.log('UNKNOWN REQ TYPE getReqIcon');
      break;
  }
  return icon;
}

export function getReqMessage(req, restTypes): any {
  const message = {
    text: '',
    restType: ''
  };
  switch (req.type) {
    case 0: // start
      message.text = 'START_WORKING_SHIFT_REQ';
      break;

    case 1: // finish
      message.text = 'FINISH_WORKING_SHIFT_REQ';
      break;

    case 2: // refill
      if (req.status === 'waiting') {
        message.text = 'VEHICLE_REFILL_REQ';
      }
      if (req.status === 'confirmed') {
        message.text = 'VEHICLE_REFILL_CONFIRMED_REQ';
      }
      break;

    case 3: // washing
      switch (req.status) {
        case 'waiting':
          message.text = 'VEHICLE_WASHING_REQ'; // требуется мойка
          break;

        case 'confirmed':
          message.text = `VEHICLE_WASHING_REQ_CONFIRMED`;
          break;

        case 'in_progress':
          message.text = ``;
          break;
        case 'waiting_cancel':
          message.text = 'VEHICLE_WASHING_WAITING_CANCEL';
          break;

        default:
          message.text = 'VEHICLE_WASHING_DEFAULT amelia-utils';
          break;
      }
      break;
    case 6: // rest
      message.text = 'REST_REQ';
      restTypes.find(rest => {
        if (rest.id === req.payload.typeId) {
          if (req.status === 'confirmed') {
            message.text = `${message.text}_COMFIRMED`;
          } else if (req.status === 'in_progress') {
            message.text = ``; // ${message.text}_IN_PROGRESS
          } else {
            message.text = `${message.text} (${rest.displayName})`;
            message.restType = rest;
          }
        }
      });
      break;

    case 7: // cancel
      message.text = 'REQ_CANCEL';
      break;

    default:
      console.log('UNKNOWN REQ TYPE getReqMessage');
      break;
  }
  return message;
}
