import { CabManualStartFinishShiftModalComponent } from './../cab-manual-finish-shift-modal/cab-manual-start-finish-shift-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { LogistCabsService } from './../../services/logist-cabs.service';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnChanges,
  AfterViewInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, Subject } from 'rxjs';
import * as _ from 'lodash';
import { SortOption, ClusterSort } from 'src/app/utils/cluster-sort';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'utax-drivers-list',
  templateUrl: './drivers-list.component.html',
  styleUrls: ['./drivers-list.component.scss']
})
export class DriversListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Output() close = new EventEmitter();
  @ViewChild('snav') snav: MatSidenav;
  @Input() toggleSnav: boolean;
  filterValue = '';
  displayedColumns: string[] = ['callsign', 'time', 'address', 'endTime'];
  dataSource: MatTableDataSource<any[]>;
  sortEvent$ = new Subject();

  sortOptions: SortOption<any>[] = [
    {
      priority: 1,
      order: 1,
      chracteristic: cab => cab.isFromVisible && cab.callsign,
      comparator: (a, b) => +a.callsign - +b.callsign
    },
    {
      priority: 2,
      order: 2,
      chracteristic: cab => !cab.isFromVisible && cab.callsign,
      comparator: (a, b) => +a.callsign - +b.callsign
    }
  ];

  private componentDestroyed$ = new Subject();
  constructor(private logistCabsService: LogistCabsService, private cd: ChangeDetectorRef, private dialog: MatDialog) {}

  ngOnInit() {
    combineLatest([this.logistCabsService.visibleCabsSub$, this.logistCabsService.disabledCabsSub, this.sortEvent$])
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((cabs: any[]) => {
        const visibleCabs = cabs[0].map(cab => {
          return { ...cab, isFromVisible: true };
        });
        const disCabs = this.logistCabsService.disabledCabs.map(cab => {
          return { ...cab, isFromVisible: false };
        });
        const uniqCabsToTable = _.uniqBy([...visibleCabs, ...disCabs], 'id');
        this.dataSource = new MatTableDataSource(ClusterSort(uniqCabsToTable, this.sortOptions));
        this.applyFilter();
        this.cd.detectChanges();
      });

    this.sortEvent$.next(true);
  }

  ngOnChanges(): void {
    if (this.toggleSnav) {
      this.snav.toggle();
    }
  }

  applyFilter() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {
    this.snav.closedStart.pipe(takeUntil(this.componentDestroyed$)).subscribe(data => {
      this.close.emit('closed');
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  selectCab(row) {
    this.logistCabsService.selectCab(row);
    console.log('row', row);
  }

  sortDrivers(event) {
    if (event.active === 'callsign') {
      if (event.direction === 'desc') {
        // по возрастанию
        this.sortOptions[0].comparator = (a, b) => +b.callsign - +a.callsign;
        this.sortOptions[1].comparator = (a, b) => +b.callsign - +a.callsign;
        this.sortEvent$.next(true);
      }
      if (event.direction === 'asc') {
        // по убыванию
        this.sortOptions[0].comparator = (a, b) => +a.callsign - +b.callsign;
        this.sortOptions[1].comparator = (a, b) => +a.callsign - +b.callsign;
        this.sortEvent$.next(true);
      }
    }
    console.log('SORT EVENT', event);
  }

  openShiftModal(cab) {
    this.dialog.open(CabManualStartFinishShiftModalComponent, {
      data: {
        cab,
        title: 'MANUAL_START_SHIFT',
        finishShift: false
      },
      panelClass: 'manual-start-finish-shift-modal-container'
    });
  }
}
