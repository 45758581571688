import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, Input, OnChanges, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CabManualStartFinishShiftModalComponent } from '../cab-manual-finish-shift-modal/cab-manual-start-finish-shift-modal.component';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';

@Component({
  selector: 'utax-details-toolbar',
  templateUrl: './details-toolbar.component.html',
  styleUrls: ['./details-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailsToolbarComponent implements OnInit, OnChanges {
  @Input() cab;
  @Input('workingShifts')
  set workingShiftsSet(workingShifts) {
    this.workingShifts = workingShifts;
    this.loading = false;
  }
  @Output() private toggleHomeModeEvent = new EventEmitter();
  shortcuts: ShortcutInput[] = [];
  workingShift;
  workingShifts;
  loading = false;

  constructor(public sanitizer: DomSanitizer, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.shortcuts.push(
      {
        key: ['f3'],
        command: e => {
          this.doCall();
        },
        allowIn: [AllowIn.Input, AllowIn.Select],
        preventDefault: true
      },
      {
        key: ['f12'],
        command: e => {
          if (!this.dialog.openDialogs.length) {
            this.openCompleteShiftModal();
          }
        },
        allowIn: [AllowIn.Input, AllowIn.Select],
        preventDefault: true
      }
    );
  }

  ngOnChanges(): void {
    this.setWS();
  }

  openCompleteShiftModal() {
    this.dialog.open(CabManualStartFinishShiftModalComponent, {
      data: {
        cab: this.cab,
        title: 'MANUAL_COMPLETE_SHIFT',
        finishShift: true
      },
      panelClass: 'manual-start-finish-shift-modal-container'
    });
  }

  doCall() {
    window.location.href = 'sip:' + this.cab.driver.phone_number;
  }

  toggleHomeMode(): void {
    if (this.workingShift) {
      this.loading = true;
      this.toggleHomeModeEvent.emit({
        wsh_id: this.workingShift.id,
        is_force_allowed: !this.workingShift.isParkingForceAllowed
      });
    }
  }

  private setWS(): void {
    if (this.cab && this.workingShifts && this.workingShifts.length > 0) {
      this.workingShift = (this.workingShifts || []).find(shift => shift.cabId === this.cab.id);
    }
  }
}
