import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {StateService} from "../services";
import {map, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PassengersGuard implements CanActivate {
  public isPermission = false;

  constructor(private stateService: StateService,
              private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.stateService.getStoreParamSub('permissions').pipe(
      tap(permissions => this.isPermission = !!permissions.length),
      map(permissions => permissions.some(perm => perm.name === 'operator_passenger_statistic_view' || perm.name === 'operator_passenger_list_readonly')),
      tap((hasPerm) => !hasPerm && this.isPermission ? this.router.navigate(['/']) : null),
    );
  }

}
