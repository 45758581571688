import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../auth/services';
import { Injectable, OnDestroy } from '@angular/core';
import { WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';
import { SubscriptionLike, Observable, Observer, Subject, interval, BehaviorSubject } from 'rxjs';
import { share, distinctUntilChanged, takeWhile, filter, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as pako from 'pako';
import * as moment from "moment";
import {ChatListenerObjectInterface} from "../dispatcher/models/dispatcher";
/*interface IWsMessage<T> {
  header: {
    type: string,
    action: string,
    sub_id?: string
  };
  data: T;
}*/

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements OnDestroy {
  public enableGzip = 1;
  connections: {
    amelia: WsConnection | any;
    miranda: WsConnection | any;
    callCenter: WsConnection | any;
    octobus: WsConnection | any;
  } = {
    amelia: {
      isConnected: new BehaviorSubject(false),
      close: () => {},
      lastUpdate: new BehaviorSubject(new Date())
    },
    miranda: {
      isConnected: new BehaviorSubject(false),
      close: () => {},
      lastUpdate: new BehaviorSubject(new Date())
    },
    callCenter: {
      isConnected: new BehaviorSubject(false),
      close: () => {},
      lastUpdate: new BehaviorSubject(new Date())
    },
    octobus: {
      isConnected: new BehaviorSubject(false),
      close: () => {},
      lastUpdate: new BehaviorSubject(new Date())
    }
  };

  constructor(private authService: AuthService,
              private cookieService: CookieService) {
    authService.authState.pipe(filter(state => !state)).subscribe(() => {
      this.connections.amelia.close();
      this.connections.miranda.close();
      this.connections.callCenter.close();
      this.connections.octobus.close();
    });
  }

  openConnection(server: 'amelia' | 'miranda' | 'callCenter' | 'octobus', subFunc?) {
    if (!this.connections[server].websocket$) {
      let wsUrl: string;
      let token;

      if (location.hostname === 'localhost' || location.hostname.startsWith('192.168')) {
        token = localStorage.getItem('token');
      } else if (server === 'octobus') {
        token = localStorage.getItem('octobusToken');
      } else {
        token = this.cookieService.get('token');
      }
      switch (server) {
        case 'amelia':
          wsUrl = environment.config.amelia;
          break;
        case 'miranda':
          wsUrl = `${environment.config.ws}${environment.config.domain}`;
          break;
        case 'callCenter':
          wsUrl = `${environment.config.wsCallCentre}/ws/operator`;
          if (location.hostname === 'localhost' || location.hostname.startsWith('192.168')) {
            token = localStorage.getItem('callCenterToken');
          } else {
            token = this.cookieService.get('callCenterToken');
          }
          break;
        case 'octobus':
          wsUrl = environment.config.octobus;
          break;
      }
      // if(!token) return;
      this.connections[server] = new WsConnection(
        wsUrl,
        () => this.authService.logout(),
        server,
        token,
        this.connections[server].isConnected,
        this.connections[server].lastUpdate,
        subFunc,
        this.cookieService,
        this.authService,
        this.enableGzip
      );
    }
    return this.connections[server];
  }

  /* closeConnection(connection:WsConnection){
    connection.close();
    delete this.connections[connection.protocol];
  }*/

  ngOnDestroy() {
    console.log('TCL: ngOnDestroy -> ngOnDestroy');
    for (const key in this.connections) {
      this.connections[key].close();
      // delete this.connections[key];
    }
  }
}

class WsConnection {
  private config: WebSocketSubjectConfig<any>;
  token: string;
  public protocol: 'amelia' | 'miranda' | 'callCenter' | 'octobus';

  private websocketSub: SubscriptionLike;
  private statusSub: SubscriptionLike;

  private reconnection$: Observable<number>;
  private websocket$: WebSocketSubject<any>;
  private connection$: Observer<boolean>;
  private wsMessages$: Subject<any>;

  private reconnectInterval: number;
  private reconnectAttempts: number;
  private pingPongInterval = 30000;
  private pingPongSub: SubscriptionLike;
  private pingPongCalleeSub: SubscriptionLike;
  private pong: boolean;
  private pongCallee: boolean;
  public isConnected: BehaviorSubject<boolean>;
  public lastUpdate: BehaviorSubject<Date> = new BehaviorSubject(new Date());
  public lastUpdateCallee: BehaviorSubject<Date> = new BehaviorSubject(new Date());

  private subIds = new Set();
  private logout;
  private subscriptions = [];

  private subFunc;
  private cookieService;
  private authService;
  private enableGzip = 1;

  status: Observable<boolean> = new Subject();

  private connect(): void {
    console.log('connect()', this.protocol);
    if (!this.config.protocol || this.config.protocol === 'callCenter') {
      let token;
      if (location.hostname === 'localhost' || location.hostname.startsWith('192.168')) {
        token = localStorage.getItem('callCenterToken');
      } else {
        token = this.cookieService.get('callCenterToken');
      }
      this.config.url = `${environment.config.wsCallCentre}/ws/operator?access_token=${token}`;
    } else if (this.config.protocol === 'octobus') {
      const token = localStorage.getItem('octobusToken');
      this.config.url = `${environment.config.octobus}?token=${token}`;
    }
    this.websocket$ = new WebSocketSubject(this.config);
    this.websocket$.subscribe(
      message => {
        this.wsMessages$.next(message);
        // sub if amelia
      },
      (error: any) => {
        console.log('TCL: WsConnection -> error', error);
        if (!this.websocket$) {
          this.isConnected.next(false);
          if (error?.currentTarget?.url.includes('octobus')) {
            this.authService.octobusUpdateSubject$.next(true);
          }
          // run reconnect if errors
          this.reconnect();
        }
      }
    );

    this.pong = true;
    this.pongCallee = true;

    if (this.protocol === 'callCenter') {
      console.log('callCenter');
      this.on('PONG').subscribe(() => {
        this.lastUpdateCallee.next(new Date());
        this.pongCallee = true;
      });
      if (this.pingPongCalleeSub) {
        this.pingPongCalleeSub.unsubscribe();
      }
      this.pingPongCalleeSub = interval(this.pingPongInterval)
        .pipe(
          filter(() => {
            return !!this.websocket$;
          })
        )
        .subscribe(() => {
          if (!this.pongCallee) {
            this.error();
            return;
          }
          this.pongCallee = false;
          this.sendSync({type: 'PING'});
          this.isConnected.next(true);
        });
    }

    if (this.protocol === 'miranda') {
      const pongSub = this.on('pong').subscribe(() => {
        this.lastUpdate.next(new Date());
        this.pong = true;
      });
      if (this.pingPongSub) {
        this.pingPongSub.unsubscribe();
      }
      this.pingPongSub = interval(this.pingPongInterval)
        .pipe(
          filter(() => {
            return !!this.websocket$;
          })
        )
        .subscribe(() => {
          if (!this.pong) {
            this.error();
            return;
          }
          this.pong = false;
          this.sendSync({ header: { type: 'ping' } });
        });
    }
    this.isConnected.next(true);

    if (this.protocol === 'amelia') {
      console.log('SUB AMELIA');
      this.sendSync(this.subFunc());
    }
  }

  error() {
    if (this.websocket$) {
      this.websocket$.error({ code: 3500, reason: 'pingPongError' });
    }
  }

  constructor(wsUrl, logout, protocol, token, isConnected, lastUpdate, subFunc, cookieService, authService, enableGzip = 1) {
    this.wsMessages$ = new Subject<any>();
    this.logout = logout;
    this.token = token;
    this.protocol = protocol;
    this.isConnected = isConnected;
    this.lastUpdate = lastUpdate;
    this.subFunc = subFunc;
    this.cookieService = cookieService;
    this.authService = authService;
    this.reconnectInterval = 5000; // pause between connections
    this.reconnectAttempts = 15; // number of connection attempts
    this.enableGzip = enableGzip;

    this.config = {
      url: protocol === 'callCenter' ? `${wsUrl}?access_token=${token}` : wsUrl + `/ws/operator?gzip=${this.enableGzip}&access_token=${token}`,
      protocol: protocol === 'callCenter' ? null : protocol,
      binaryType: 'arraybuffer',
      serializer: e => {
        if (protocol === 'amelia') {
          return e;
        } else if (protocol === 'miranda' || protocol === 'octobus') {
          if (!environment.production) {
            console.log(`${protocol === 'miranda' ? '%cM%ci%cr%ca%cn%cd%ca%c↑%c' : '%cO%cc%ct%co%cb%cu%cs%c↑%c'}(${moment().format('HH:mm:ss:SSS')})`,
              'font-size: 20px; color: blue;',
              'font-size: 20px; color: lightblue;',
              'font-size: 20px; color: lightgreen;',
              'font-size: 20px; color: green',
              'font-size: 20px; color: yellow;',
              'font-size: 20px; color: orange',
              'font-size: 20px; color: red',
              'font-size: 20px; color: green',
              'font-size: 20px; color: purple'
            );
            console.dir(e);
          }
          return this.enableGzip ? pako.gzip(JSON.stringify(e)) : JSON.stringify(e);
        } else {
          return JSON.stringify(e);
        }
      },
      deserializer: e => {
        if (protocol === 'amelia') {
          return new Uint8Array(e.data);
        } else if (protocol === 'miranda' || protocol === 'octobus') {
          const unpacked = this.enableGzip ? JSON.parse(pako.ungzip(e.data, { to: 'string' })) : JSON.parse(e.data);
          if (!environment.production) {
            console.log(`${protocol === 'miranda' ? '%cM%ci%cr%ca%cn%cd%ca%c↓%c' : '%cO%cc%ct%co%cb%cu%cs%c↓%c'}(${moment().format('HH:mm:ss:SSS')})`,
              'font-size: 20px; color: blue;',
              'font-size: 20px; color: lightblue;',
              'font-size: 20px; color: lightgreen;',
              'font-size: 20px; color: green',
              'font-size: 20px; color: yellow;',
              'font-size: 20px; color: orange',
              'font-size: 20px; color: red',
              'font-size: 20px; color: red',
              'font-size: 20px; color: purple'
            );
            console.log(unpacked);
          }
          return unpacked;
        } else {
          return JSON.parse(e.data);
        }
      },
      closeObserver: {
        next: (event: CloseEvent) => {
          console.log('CLLLLLLLLLOOOOOOOOSSSSSSEEEEEE!!!!!!!!!!!!!!');
          this.isConnected.next(false);
          this.websocket$ = null;
          this.connection$.next(false);
        }
      },
      openObserver: {
        next: () => {
          this.connection$.next(true);
        }
      }
    };

    // connection status
    this.status = new Observable<boolean>(observer => {
      this.connection$ = observer;
    }).pipe(share(), distinctUntilChanged());

    this.statusSub = this.status.subscribe(isConnect => {
      this.isConnected.next(isConnect);
      if (!this.reconnection$ && typeof isConnect === 'boolean' && !isConnect) {
        this.reconnect();
        console.log('status sub reconnect');
      }
    });

    this.websocketSub = this.wsMessages$.subscribe(null, (error: ErrorEvent) => {
      console.error('WebSocket error!', error);
      this.reconnect();
    });
    this.connect();
  }

  /*
   * reconnect if not connecting or errors
   * */
  private reconnect(): void {
    if (this.reconnection$) {
      return;
    }
    if (this.pingPongSub) {
      this.pingPongSub.unsubscribe();
    }
    this.reconnection$ = interval(this.reconnectInterval).pipe(
      takeWhile((v, index) => index < this.reconnectAttempts && !this.websocket$)
    );

    this.reconnection$.subscribe(
      () => {
        this.connect();
        this.subscriptions.forEach(sub => {

          this.send(sub);
        });
      },
      null,
      () => {
        // Subject complete if reconnect attemts ending
        this.reconnection$ = null;

        if (!this.websocket$) {
          this.wsMessages$.complete();
          this.connection$.complete();
        }
      }
    );
  }

  /*
   * on message event
   * */
  on(event: string): Observable<any> {
    if (event) {
      return this.wsMessages$.pipe(
        filter(msg => {
          if (msg.header?.status === 'UNAUTHORIZED') {
            this.logout();
          }

          return (msg.header?.type === event || msg?.type === event);
        })
      );
    }
  }

  chatConnect(data: any) {
    this.send(data);
  }

  onBinary(): Observable<any> {
    return this.wsMessages$.pipe(
      map(event => {
        this.lastUpdate.next(new Date());
        return event;
      })
    );
  }

  createSub(sub) {
    this.send(sub);
    this.subscriptions.push(sub);
  }

  sendSync(msg) {
    if (this.websocket$) {
      this.websocket$.next(msg);
    }
  }

  send(data: any = {}): void {
    this.isConnected
      .pipe(
        filter(status => {
          if (this.protocol) {
          }

          return status;
        }),
        take(1)
      )
      .subscribe(() => {
        this.websocket$.next(data as any);
      });
  }

  close(): void {
    console.log('TCL: close', this.protocol);
    this.reconnection$ = new Subject();
    if (this.websocket$) {
      this.websocket$.complete();
    }
    this.websocket$ = null;
  }
}
