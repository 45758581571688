import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { ApiOrderFormService } from './../../services/api-order-form.service';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {AddedKmModalObjInterface, Fare} from './../../../interfaces/order.interface';
import { OrderFormService } from './../../services/order-form.service';
import {
  Component,
  Input,
  AfterViewInit,
  ViewChild,
  OnDestroy,
  AfterContentChecked,
  ChangeDetectorRef, ElementRef, OnInit
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { OrderModalService } from '../../services';
import {GlobalDataService} from '@global-services/global-data.service';
import {switchMap, tap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AddDistanceModalComponent} from "../add-distance-modal/add-distance-modal.component";
import {MatDialog} from "@angular/material/dialog";
import * as moment from "moment";

@UntilDestroy()
@Component({
  selector: 'utax-order-form-estimate',
  templateUrl: './order-form-estimate.component.html',
  styleUrls: ['./order-form-estimate.component.scss']
})
export class OrderFormEstimateComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  @ViewChild('payment') private payment: MatSelect;
  @ViewChild('inputCost') private inputCost: ElementRef;
  @Input('fare')
  set fareAddedValue(fare: Fare) {
    if (fare) {
      if (!this.fareId || this.fareId !== fare.id) {
        // this.paidTimeAmount = fare.paid_times ? (fare.paid_times.paid_waiting_time_amount + fare.paid_times.paid_time_amount) : 0;
        // this.amount = (fare.amount ? fare.amount : 0) + this.paidTimeAmount;
        this.amount = fare.amount ? fare.amount : 0;
        this.onlyAmount = fare.amount ? fare.amount : 0;
        this.orderFormService.amount = this.amount;
        this.addedValue = fare.added_value ? fare.added_value : 0;
        this.canByPayedByBonuses = this.orderFormService && this.orderFormService.bonusBalance > fare.amount;
        this.amountInputWidth = fare.amount !== undefined ? fare.amount.toString().length : 1;
        this.checkAfterEstimate();
      }
      this.fareId = fare.id;
    }
  }
  addedValue: number;
  amount: number;
  fareId: string;
  canByPayedByBonuses = false;
  amountInputWidth: number;
  shortcuts$: Observable<ShortcutInput[]>;
  public debtAdded = false;
  public ignoreDebt = false;
  public debtState: 'debt' | 'debt-added' | 'overpayment' | 'no-debt' = 'no-debt';
  public editMode = false;
  private componentDestroyed$ = new Subject();
  public onlyAmount: number;
  // public paidTimeAmount: number;
  public isOpen = false;
  public addedKmModalObj: AddedKmModalObjInterface;

  constructor(
    public orderFormService: OrderFormService,
    public orderModalService: OrderModalService,
    public apiOrdFormService: ApiOrderFormService,
    private cdr: ChangeDetectorRef,
    public globalDataService: GlobalDataService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.shortcutHelper();
    this.editMode = this.orderModalService.mode === 'default';
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  addedValueChange(event: number, isCalculation = false) {
    if (!isCalculation) {
      this.addedKmModalObj = null;
      this.orderFormService.addDistance$.next(null);
    }
    let addedval = 0;
    if (this.orderFormService.fare.value + event >= 0 && this.orderFormService.fare.value + event <= 999999) {
      addedval = event;
    } else if (this.orderFormService.fare.value + event > 999999) {
      addedval = 999999 - this.orderFormService.fare.value;
    } else if (this.orderFormService.fare.value + event < 0) {
      addedval = -this.orderFormService.fare.value;
    }

    this.addedValue = +addedval;
    // if (this.debtAdded && this.orderFormService.overdraftBalance$.value) {
    //   this.amount = this.orderFormService.fare.value +
    //     this.addedValue +
    //     this.paidTimeAmount +
    //     this.orderFormService.overdraftBalance$.value;
    //   this.onlyAmount = this.orderFormService.fare.value + this.addedValue;
    //   this.orderFormService.amount = this.onlyAmount;
    // } else {
    //   this.amount = this.orderFormService.fare.value + this.addedValue + this.paidTimeAmount;
    //   this.onlyAmount = this.amount;
    //   this.orderFormService.amount = this.amount;
    // }
    if (this.debtAdded && this.orderFormService.overdraftBalance$.value) {
      this.amount = this.orderFormService.fare.value + this.addedValue + this.orderFormService.overdraftBalance$.value;
      this.onlyAmount = this.orderFormService.fare.value + this.addedValue;
      this.orderFormService.amount = this.onlyAmount;
    } else {
      this.amount = this.orderFormService.fare.value + this.addedValue;
      this.onlyAmount = this.amount;
      this.orderFormService.amount = this.amount;
    }
    this.orderFormService.addedValue = this.addedValue;
    this.amountInputWidth = this.amount.toString().length;
    this.checkDebt();
  }

  focusRiseCost() {
    if (this.addedValue === 0) {
      this.addedValue = undefined;
    }
  }

  mainSummChange(event: string): void {
    let summ = '';
    if (+event >= 0 && +event <= 999999) {
      summ = event;
    } else if (+event > 999999) {
      summ = '999999';
    } else if (+event < 0) {
      summ = '0';
    }

    this.amount = +summ;
    this.onlyAmount = this.debtState === 'debt-added' ? +summ - this.orderFormService.overdraftBalance$.value : +summ;
    this.orderFormService.amount = this.onlyAmount;
    this.addedValue = this.amount - this.orderFormService.fare.value - (this.debtState === 'debt-added' ? this.orderFormService.overdraftBalance$.value : 0);
    this.orderFormService.addedValue = this.addedValue;
    this.amountInputWidth = summ.length;
    this.checkDebt();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  public shortcutHelper(): void {
    this.shortcuts$ = this.globalDataService.isNewKeyboardLayout$
      .pipe(
        switchMap(isNewKeyboardLayout => {
          if (isNewKeyboardLayout) {
            return of([]);
          } else {
            return of([{
              key: 'f6',
              command: () => {
                if (this.orderFormService.fare) {
                  this.payment.focus();
                  this.payment.open();
                }
              },
              allowIn: [AllowIn.Input, AllowIn.Select],
              preventDefault: true
            }]);
          }
        })
      );
  }



  private checkAfterEstimate() {
    if (this.editMode) {
      this.debtAdded = true;
      this.orderFormService.overdraftIncluded$.next(this.debtAdded);
      this.setDebtAddedState();
    } else {
      if (!this.debtAdded) {
        this.debtAdded = !this.orderFormService.overdraftBalance$.value;
      }
      this.ignoreDebt = !!!this.orderFormService.overdraftBalance$.value;
      if (!this.orderFormService.overdraftBalance$.value) {
        this.orderFormService.overdraftIncluded$.next(true);
      } else if (!this.debtAdded || this.debtState !== 'debt-added') {
        this.orderFormService.overdraftIncluded$.next(false);
        this.debtAdded = false;
        this.debtState = 'debt';
      } else {
        this.orderFormService.overdraftIncluded$.next(true);
        this.setDebtAddedState();
      }
    }
    this.addedKmModalObj = null;
    this.orderFormService.addDistance$.next(null);
  }

  private checkDebt() {
    if (this.orderFormService.overdraftBalance$.value && this.debtAdded) {
      // this.orderFormService.overdraftIncluded$.next(this.amount - this.paidTimeAmount >= this.orderFormService.overdraftBalance$.value);
      this.orderFormService.overdraftIncluded$.next(this.amount >= this.orderFormService.overdraftBalance$.value);
    }
    this.cdr.detectChanges();
  }

  public changeDebtState() {
    this.debtAdded = !this.debtAdded;
    this.orderFormService.overdraftIncluded$.next(this.debtAdded);
    if (this.debtAdded) {
      this.setDebtAddedState();
    } else {
      this.debtState = 'debt';
      this.amount = this.amount - this.orderFormService.overdraftBalance$.value;
      this.onlyAmount = this.amount;
      this.orderFormService.amount = this.amount;
      this.addedValue = this.amount - this.orderFormService.fare.value;
      this.orderFormService.addedValue = this.addedValue;
      this.amountInputWidth = String(this.amount).length;
    }

  }

  private setDebtAddedState() {
    this.debtState = 'debt-added';
    this.amount += this.orderFormService.overdraftBalance$.value;
    this.orderFormService.amount = this.amount - this.orderFormService.overdraftBalance$.value;
    // this.onlyAmount = this.amount - this.orderFormService.overdraftBalance$.value - this.paidTimeAmount;
    // this.addedValue = this.amount - this.orderFormService.fare.value - this.orderFormService.overdraftBalance$.value - this.paidTimeAmount;
    this.onlyAmount = this.amount - this.orderFormService.overdraftBalance$.value;
    this.addedValue = this.amount - this.orderFormService.fare.value - this.orderFormService.overdraftBalance$.value;
    this.orderFormService.addedValue = this.addedValue;
    this.amountInputWidth = String(this.amount).length;
    this.cdr.detectChanges();
  }

  public changeAmount(inputtedSum: string) {
    const sumNumber = +inputtedSum;
    let summ;
    if (sumNumber >= 0 && sumNumber <= 999999) {
      summ = sumNumber;
    } else if (sumNumber > 999999) {
      summ = 999999;
    } else if (sumNumber < 0) {
      summ = 0;
    }
    if (this.debtAdded) {
      // this.onlyAmount = summ;
      // this.amount = this.onlyAmount + this.orderFormService.overdraftBalance$.value + this.paidTimeAmount;
      // this.orderFormService.amount = this.onlyAmount;
      // this.addedValue = this.amount - this.orderFormService.fare.value - this.orderFormService.overdraftBalance$.value - this.paidTimeAmount;
      // this.orderFormService.addedValue = this.addedValue;
      // this.amountInputWidth = String(this.amount).length;
      // this.checkDebt();
      this.onlyAmount = summ;
      this.amount = this.onlyAmount + this.orderFormService.overdraftBalance$.value;
      this.orderFormService.amount = this.onlyAmount;
      this.addedValue = this.amount - this.orderFormService.fare.value - this.orderFormService.overdraftBalance$.value;
      this.orderFormService.addedValue = this.addedValue;
      this.amountInputWidth = String(this.amount).length;
      this.checkDebt();
    } else {
      this.mainSummChange(inputtedSum);
    }
  }

  public convertSecToMin(sec: number): string {
    return moment.utc(moment.duration(sec, 'seconds').asMilliseconds()).format('m.ss');

  }

  public openAddDistanceModal() {
      return this.dialog.open(AddDistanceModalComponent, {
    data: {
      title: 'UTAX_ADD_KM_TO_CALCULATION',
      product: this.orderFormService.order.product,
      oldAmount: this.addedKmModalObj ? this.amount - this.addedKmModalObj.difference_amount : this.amount,
      oldAddedValue: this.addedKmModalObj ? (this.addedValue - this.addedKmModalObj.difference_amount) : this.addedValue || 0,
      surge_multiplier: this.orderFormService.order.fare.surge_multiplier,
    },
    panelClass: 'request-activate-dialog-container',
    width: '442px'
  })
        .afterClosed()
        .pipe(
          tap((result) => {
            if (result) {
              console.log(result);
              this.addedKmModalObj = result;
              this.orderFormService.addDistance$.next(this.addedKmModalObj.kmInfo);
              this.addedValueChange(this.addedKmModalObj.oldAddedValue + this.addedKmModalObj.difference_amount, true);
            }
          }),
          untilDestroyed(this))
        .subscribe();
  }
}
