import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'utax-call-driver-modal',
  templateUrl: './call-driver-modal.component.html',
  styleUrls: ['./call-driver-modal.component.scss']
})
export class CallDriverModalComponent implements OnInit {
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any[],
    private dialogSelf: MatDialogRef<CallDriverModalComponent>
  ) {}

  ngOnInit(): void {}

  chooseNumber(numb: string): void {
    this.dialogSelf.close(numb);
  }
}
