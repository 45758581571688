import * as moment from 'moment';

export function getColorOfCab(cab) {
  let color = 'red';
  let hasReqInProgress = false;
  let hasOrdInProgress = false;

  if (cab.requests && cab.requests.length > 0) {
    cab.requests.forEach(req => {
      if (req.status === 'in_progress') {
        switch (req.type) {
          case 3: // washing
          case 6: // rest
            hasReqInProgress = true;
            color = 'yellow';
            break;
          default:
            break;
        }
      }
    });
  }
  if (!hasReqInProgress && cab.order && cab.order.trip !== null) {
    if (
      cab.order.status === 'accepted' ||
      cab.order.status === 'arriving' ||
      cab.order.status === 'waiting'
    ) {
      color = 'green';
      hasOrdInProgress = true;
    } else {
      color = 'yellow';
      hasOrdInProgress = true;
    }
  }
  if (
    !hasOrdInProgress &&
    !hasReqInProgress &&
    cab.trip &&
    (cab.trip.completed_at || cab.trip.canceled_at)
  ) {
    color = 'red';
  }

  return color;
}

export function getProgressWidth(color, cab, currentOrder) {
  let progressWidth;
  const now = moment.now();
  switch (color) {
    case 'red':
      let lastEndOfTrip;

      if (cab.trip && cab.trip.completed_at) {
        lastEndOfTrip = moment.utc(cab.trip.completed_at).valueOf();
      } else if (cab.trip.canceled_at) {
        lastEndOfTrip = moment.utc(cab.trip.completed_at).valueOf();
      }
      const difference = now - lastEndOfTrip;
      const resultInMinutes = Math.round(difference / 60000);

      if (resultInMinutes < 20) {
        progressWidth = resultInMinutes * 5;
      } else {
        progressWidth = 100;
      }
      break;

    case 'yellow':
      if (currentOrder && currentOrder.fare) {
        const minutesInTrip = Math.round(currentOrder.fare.route.duration / 60);
        const picketUpAtDate = moment.utc(cab.trip.picked_up_at).valueOf();
        const alreadyInTrip = (now - picketUpAtDate) / 60 / 1000;

        if (alreadyInTrip / (minutesInTrip / 100) > 100) {
          progressWidth = 100;
        } else {
          progressWidth = alreadyInTrip / (minutesInTrip / 100);
        }
      }

      break;

    case 'green':
      const arrivingAt = moment.utc(cab.trip.arriving_at).valueOf();
      const acceptedAt = moment.utc(cab.trip.accepted_at).valueOf();
      const minutesToClient = (arrivingAt - acceptedAt) / 60 / 1000;
      const alrearyInTripToClient = (now - acceptedAt) / 60 / 1000;

      if (alrearyInTripToClient / (minutesToClient / 100) > 100) {
        progressWidth = 100;
      } else {
        progressWidth = alrearyInTripToClient / (minutesToClient / 100);
      }

      break;
  }
  return progressWidth;
}
