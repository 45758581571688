import {
  Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'urlify'
})

export class UrlifyPipe implements PipeTransform {

  transform(text: any): any {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlRegex, (url: string) => {
      return '<a class="accent-color" href="' + url + '" target="_blank">' + `${url.substring(0, url.indexOf('/', 10))}` + '...' + '</a>';
    });
  }
}
