import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

const driversSearchUrl = 'operator/driver/all',
  messageUrl = 'operator/messages/bulk/send',
  messageToAllUrl = 'operator/messages/all/send';

@Injectable({
  providedIn: 'root'
})
export class MessageToDriverService {
  drivers = {};

  constructor(private http: HttpClient) {}

  getDrivers(service): Observable<any[]> {
    const BS = new BehaviorSubject([]);
    const driversList = this.drivers[service];
    if (driversList) {
      BS.next(driversList);
    } else {
      this.http
        .get(driversSearchUrl, {
          params: new HttpParams({ fromObject: { service_id: service } })
        })
        .subscribe((res: any) => {
          const validDrivers = res.data.filter(
            el => !!el.callsign && !!el.first_name && !!el.last_name
          );
          this.drivers[service] = validDrivers;
          BS.next(validDrivers);
        });
    }
    return BS;
  }

  sendMessage(drivers, message, service, groups = null) {
    return this.http.post(messageUrl, {
      drivers,
      msg: message,
      priority: 1,
      service_id: service,
      vehicle_group_ids: groups
    });
  }

  sendMessageToAll(service, message): any {
    return this.http.post(messageToAllUrl, {
      service_id: service,
      msg: message,
      priority: 1
    });
  }
}
