import {Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'utax-unassign-cab-modal',
  templateUrl: './unassign-cab-modal.component.html',
  styleUrls: ['./unassign-cab-modal.component.scss']
})
export class UnassignCabModalComponent implements OnInit {
  reason = null;
  comment = null;
  showHelpMessage = false;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<UnassignCabModalComponent>
  ) { }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

  send(): void {
    if (!this.reason) {
      this.showHelpMessage = true;
      return;
    }

    const data = {
      id: this.data.id,
      reason: this.reason,
      comment: this.comment
    };

    this.dialogRef.close(data);
  }
}

