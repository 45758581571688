import { LogistOrdersService } from 'src/app/logist/services/logist-orders.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getLastAddressFromCab'
})
export class GetLastAddressFromCabPipe implements PipeTransform {
  constructor(private logistOrdersService: LogistOrdersService) {}

  transform(cab): any {
    let trip;
    let currentOrder;
    if (cab.trips.length === 0) {
      trip = cab.trip;
    } else {
      trip = cab.trips.find(item => item.is_current);
    }

    if (cab.trip && trip) {
      currentOrder = this.logistOrdersService.ordersList.find(
        ord => ord.trip_id === trip.id
      );
    }
    if (currentOrder === undefined) {
      return 'HAVE_NOT_CURRENT_ORDER';
    } else {
      return currentOrder.waypoints.find(point => point.type === 'end').name;
    }
  }
}
