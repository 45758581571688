export function millisToMinutesAndSeconds(millis: number): string  {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ':' + (+seconds < 10 ? '0' : '') + seconds;
}

export function secondsToMinutesAndSeconds(seconds: number): string  {
  const minutes = Math.floor(seconds / 60);
  const secondsInTime = (seconds % 60).toFixed(0);
  return minutes + ':' + (+secondsInTime < 10 ? '0' : '') + secondsInTime;
}
