import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface IConfirmDeleteButtons {
  remove: { title: string };
  cancel: { title: string };
}

@Component({
  selector: 'utax-confirm-delete-modal',
  templateUrl: './confirm-delete-modal.component.html',
  styleUrls: ['./confirm-delete-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDeleteModalComponent implements OnInit, AfterViewInit {

  @ViewChild('btnYes', {static: true}) btnYes: MatButton;
  @ViewChild('btnNo', {static: true}) btnNo: MatButton;

  shortcuts: ShortcutInput[] = [];


  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: { title: string; extra?: string; buttons?: IConfirmDeleteButtons },
              public dialogRef: MatDialogRef<ConfirmDeleteModalComponent>) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: ['left'],
        command: e => {
          this.changeBtnToNo();
        },
        allowIn: [AllowIn.Input, AllowIn.Select],
        preventDefault: false
      },
      {
        key: ['right'],
        command: e => {
          this.changeBtnToYes();
        },
        allowIn: [AllowIn.Input, AllowIn.Select],
        preventDefault: false
      }
    );
  }

  confirm(): void {
    this.dialogRef.close('REMOVE');
  }

  changeBtnToNo(): void {
    this.btnNo.focus();
  }

  changeBtnToYes(): void {
    this.btnYes.focus();
  }

}
