export interface WithdrawalState {
  enabled: boolean;
  min_amount: number;
  max_amount: number;
  max_request_per_day: number;
  require_tin: boolean;
}

export enum WithdrawalReceiptStatus {
  UNDEFINED = 'undefined',
  PROCESSING = 'processing',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export interface WithdrawalReceiptCard {
  id: string;
  masked_card: string;
}

export interface WithdrawalReceipt {
  id: string;
  status: WithdrawalReceiptStatus;
  amount: number;
  created_at: string;
  updated_at: string;
  card: WithdrawalReceiptCard;
}

export interface WithdrawalTransaction {
  id: string;
  type: string;
  operation: 'add' | 'sub';
  amount: number;
  balance: number;
  created_at: string;
  withdrawal_receipt: WithdrawalReceipt;
}

export interface WithdrawalRequest {
  id: string;
  status: string;
  verification_code: string;
}
