import {
  Address,
  Fare,
  Order,
  OrderCab, OrderCallDispatcherStatusInterface,
  PassengerData,
  PassengerOverDraftInterface,
  Product, ReceiverInterface
} from '../interfaces/order.interface';

export class OrderClass implements Order {
  get plugin_request(): any {
    return this._plugin_request;
  }

  set plugin_request(value: any) {
    this._plugin_request = value;
  }
  get is_incity(): boolean {
    return this._is_incity;
  }

  set is_incity(value: boolean) {
    this._is_incity = value;
  }

  constructor(order: Order) {
    this._city_id = order?.city_id || null;
    this._id = order?.id || null;
    this._waypoints = order?.waypoints || null;
    this._trip = order?.trip || null;
    this._status = order?.status;
    this._start_comment = order?.start_comment || null;
    this._service_id = order?.service_id || null;
    this._service = order?.service || null;
    this._reserver_by = order?.reserver_by || null;
    this._queued_requests = order?.queued_requests || null;
    this._product = order?.product || null;
    this._payment_type = order?.payment_type || null;
    this._passengers_count = order?.passengers_count || null;
    this._passenger_overdraft = order?.passenger_overdraft || null;
    this._passenger = order?.passenger || null;
    this._pass_comment = order?.pass_comment || null;
    this._paid_amount = order?.paid_amount || null;
    this._offers = order?.offers || null;
    this._offer = order?.offer || null;
    this._notifications = order?.notifications || null;
    this._locked_user = order?.locked_user;
    this._is_visible_vehicle_user = order?.is_visible_vehicle_user || null;
    this._is_visible = order?.is_visible || null;
    this._is_sending = order?.is_sending || null;
    this._is_reserved = order?.is_reserved || null;
    this._is_preorder = order?.is_preorder || false;
    this._is_plugin_status = order?.is_plugin_status || null;
    this._is_intercity = order?.is_intercity || false;
    this._is_incity = order?.is_incity || false;
    this._is_incomplete = order?.is_incomplete || false;
    this._is_holding = order?.is_holding || null;
    this._is_from_app = order?.is_from_app || null;
    this._is_exchange = order?.is_exchange || null;
    this._is_card = order?.is_card || null;
    this._is_by_the_city = order?.is_by_the_city || false;
    this._cab = order?.cab || null;
    this._fare = order?.fare || null;
    this._approximatelyFeed = order?.approximatelyFeed || null;
    this._assignedBy = order?.assignedBy || null;
    this._assigned_at_moment = order?.assigned_at_moment || null;
    this._assigned_at = order?.assigned_at || null;
    this._disp_comment = order?.disp_comment || null;
    this._features = order?.features || null;
    this._highlights = order?.highlights || null;
    this._is_active_order = order?.is_active_order || null;
    this._is_call_order = order?.is_call_order || null;
    this._is_card_payment = order?.is_card_payment || null;
    this._is_locked = order?.is_locked || null;
    this._locked_by = order?.locked_by || null;
    this._operator_id = order?.operator_id || null;
    this._phone_number = order?.phone_number || null;
    this._product_id = order?.product_id || null;
    this._searched_at = order?.searched_at || null;
    this._sending_at = order?.sending_at || null;
    this._timeLeft = order?.timeLeft || null;
    this._totalTime = order?.totalTime || null;
    this._trip_id = order?.trip_id || null;
    this._updated_at = order?.updated_at || null;
    this._visible_preorder = order?.visible_preorder || null;
    this._plugin_request = order?.plugin_request || null;
    this._created_at = order?.created_at || null;
    this._lock_mode = order?.lock_mode || null;
    this._accountingPassengerOverdraft = order?.accountingPassengerOverdraft || -1;
    this._receiver = order?.receiver || null;
    this._calls = order?.calls || [];
    this._last_discussion_message = order?.last_discussion_message || null;
  }

  get cab(): OrderCab {
    return this._cab;
  }

  set cab(value: OrderCab) {
    this._cab = value;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }
  get waypoints(): Address[] {
    return this._waypoints;
  }

  set waypoints(value: Address[]) {
    this._waypoints = value;
  }
  get trip(): any {
    return this._trip;
  }

  set trip(value: any) {
    this._trip = value;
  }
  get status(): string {
    return this._status;
  }

  set status(value: string) {
    this._status = value;
  }
  get start_comment(): any {
    return this._start_comment;
  }

  set start_comment(value: any) {
    this._start_comment = value;
  }
  get service_id(): number {
    return this._service_id;
  }

  set service_id(value: number) {
    this._service_id = value;
  }
  get service(): any {
    return this._service;
  }

  set service(value: any) {
    this._service = value;
  }
  get reserver_by(): string {
    return this._reserver_by;
  }

  set reserver_by(value: string) {
    this._reserver_by = value;
  }
  get queued_requests(): any[] {
    return this._queued_requests;
  }

  set queued_requests(value: any[]) {
    this._queued_requests = value;
  }
  get product(): Product {
    return this._product;
  }

  set product(value: Product) {
    this._product = value;
  }
  get payment_type(): string {
    return this._payment_type;
  }

  set payment_type(value: string) {
    this._payment_type = value;
  }
  get passengers_count(): number {
    return this._passengers_count;
  }

  set passengers_count(value: number) {
    this._passengers_count = value;
  }
  get passenger_overdraft(): PassengerOverDraftInterface | null {
    return this._passenger_overdraft;
  }

  set passenger_overdraft(value: PassengerOverDraftInterface | null) {
    this._passenger_overdraft = value;
  }
  get passenger(): PassengerData {
    return this._passenger;
  }

  set passenger(value: PassengerData) {
    this._passenger = value;
  }
  get pass_comment(): string {
    return this._pass_comment;
  }

  set pass_comment(value: string) {
    this._pass_comment = value;
  }
  get paid_amount(): any {
    return this._paid_amount;
  }

  set paid_amount(value: any) {
    this._paid_amount = value;
  }
  get offers(): any[] {
    return this._offers;
  }

  set offers(value: any[]) {
    this._offers = value;
  }
  get offer(): any {
    return this._offer;
  }

  set offer(value: any) {
    this._offer = value;
  }
  get notifications(): any[] {
    return this._notifications;
  }

  set notifications(value: any[]) {
    this._notifications = value;
  }
  get locked_user(): any {
    return this._locked_user;
  }

  set locked_user(value: any) {
    this._locked_user = value;
  }
  get is_visible_vehicle_user(): boolean {
    return this._is_visible_vehicle_user;
  }

  set is_visible_vehicle_user(value: boolean) {
    this._is_visible_vehicle_user = value;
  }
  get is_visible(): boolean {
    return this._is_visible;
  }

  set is_visible(value: boolean) {
    this._is_visible = value;
  }
  get is_sending(): boolean {
    return this._is_sending;
  }

  set is_sending(value: boolean) {
    this._is_sending = value;
  }
  get is_reserved(): boolean {
    return this._is_reserved;
  }

  set is_reserved(value: boolean) {
    this._is_reserved = value;
  }
  get is_preorder(): boolean {
    return this._is_preorder;
  }

  set is_preorder(value: boolean) {
    this._is_preorder = value;
  }
  get is_plugin_status(): boolean {
    return this._is_plugin_status;
  }

  set is_plugin_status(value: boolean) {
    this._is_plugin_status = value;
  }
  get is_intercity(): boolean {
    return this._is_intercity;
  }

  set is_intercity(value: boolean) {
    this._is_intercity = value;
  }
  get is_incomplete(): boolean {
    return this._is_incomplete;
  }

  set is_incomplete(value: boolean) {
    this._is_incomplete = value;
  }
  get is_holding(): boolean {
    return this._is_holding;
  }

  set is_holding(value: boolean) {
    this._is_holding = value;
  }
  get is_from_app(): boolean {
    return this._is_from_app;
  }

  set is_from_app(value: boolean) {
    this._is_from_app = value;
  }
  get is_exchange(): boolean {
    return this._is_exchange;
  }

  set is_exchange(value: boolean) {
    this._is_exchange = value;
  }
  get is_card(): boolean {
    return this._is_card;
  }

  set is_card(value: boolean) {
    this._is_card = value;
  }
  get is_by_the_city(): boolean {
    return this._is_by_the_city;
  }

  set is_by_the_city(value: boolean) {
    this._is_by_the_city = value;
  }
  get hold_user(): any {
    return this._hold_user;
  }

  set hold_user(value: any) {
    this._hold_user = value;
  }
  get hold_type(): any {
    return this._hold_type;
  }

  set hold_type(value: any) {
    this._hold_type = value;
  }
  get hold_expires_at(): any {
    return this._hold_expires_at;
  }

  set hold_expires_at(value: any) {
    this._hold_expires_at = value;
  }
  get hold_by(): any {
    return this._hold_by;
  }

  set hold_by(value: any) {
    this._hold_by = value;
  }
  get has_destination(): boolean {
    return this._has_destination;
  }

  set has_destination(value: boolean) {
    this._has_destination = value;
  }
  get fare(): Fare {
    return this._fare;
  }

  set fare(value: Fare) {
    this._fare = value;
  }
  get extra_bonus_value(): number {
    return this._extra_bonus_value;
  }

  set extra_bonus_value(value: number) {
    this._extra_bonus_value = value;
  }
  get costPerKm(): number {
    return this._costPerKm;
  }

  set costPerKm(value: number) {
    this._costPerKm = value;
  }
  get created_at(): string {
    return this._created_at;
  }

  set created_at(value: string) {
    this._created_at = value;
  }
  get city_id(): number {
    return this._city_id;
  }

  set city_id(value: number) {
    this._city_id = value;
  }
  get bonus_value(): number {
    return this._bonus_value;
  }

  set bonus_value(value: number) {
    this._bonus_value = value;
  }
  get assigned_at(): string {
    return this._assigned_at;
  }

  set assigned_at(value: string) {
    this._assigned_at = value;
  }
  get visible_preorder(): boolean {
    return this._visible_preorder;
  }

  set visible_preorder(value: boolean) {
    this._visible_preorder = value;
  }
  get updated_at(): string {
    return this._updated_at;
  }

  set updated_at(value: string) {
    this._updated_at = value;
  }
  get trip_id(): string {
    return this._trip_id;
  }

  set trip_id(value: string) {
    this._trip_id = value;
  }
  get totalTime(): any {
    return this._totalTime;
  }

  set totalTime(value: any) {
    this._totalTime = value;
  }
  get timeLeft(): any {
    return this._timeLeft;
  }

  set timeLeft(value: any) {
    this._timeLeft = value;
  }
  get sending_at(): string {
    return this._sending_at;
  }

  set sending_at(value: string) {
    this._sending_at = value;
  }
  get searched_at(): string {
    return this._searched_at;
  }

  set searched_at(value: string) {
    this._searched_at = value;
  }
  get product_id(): string {
    return this._product_id;
  }

  set product_id(value: string) {
    this._product_id = value;
  }
  get phone_number(): string {
    return this._phone_number;
  }

  set phone_number(value: string) {
    this._phone_number = value;
  }
  get operator_id(): string {
    return this._operator_id;
  }

  set operator_id(value: string) {
    this._operator_id = value;
  }
  get locked_by(): string {
    return this._locked_by;
  }

  set locked_by(value: string) {
    this._locked_by = value;
  }
  get is_locked(): boolean {
    return this._is_locked;
  }

  set is_locked(value: boolean) {
    this._is_locked = value;
  }
  get is_card_payment(): boolean {
    return this._is_card_payment;
  }

  set is_card_payment(value: boolean) {
    this._is_card_payment = value;
  }
  get is_call_order(): boolean {
    return this._is_call_order;
  }

  set is_call_order(value: boolean) {
    this._is_call_order = value;
  }
  get is_active_order(): boolean {
    return this._is_active_order;
  }

  set is_active_order(value: boolean) {
    this._is_active_order = value;
  }
  get highlights(): any[] {
    return this._highlights;
  }

  set highlights(value: any[]) {
    this._highlights = value;
  }
  get features(): any[] {
    return this._features;
  }

  set features(value: any[]) {
    this._features = value;
  }
  get disp_comment(): string {
    return this._disp_comment;
  }

  set disp_comment(value: string) {
    this._disp_comment = value;
  }
  get assigned_at_moment(): moment.Moment {
    return this._assigned_at_moment;
  }

  set assigned_at_moment(value: moment.Moment) {
    this._assigned_at_moment = value;
  }
  get assignedBy(): string {
    return this._assignedBy;
  }

  set assignedBy(value: string) {
    this._assignedBy = value;
  }
  get approximatelyFeed(): any {
    return this._approximatelyFeed;
  }

  set approximatelyFeed(value: any) {
    this._approximatelyFeed = value;
  }

  get lock_mode(): string {
    return this._lock_mode;
  }

  set lock_mode(value: string) {
    this._lock_mode = value;
  }
  get accountingPassengerOverdraft(): number {
    return this._accountingPassengerOverdraft;
  }
  set accountingPassengerOverdraft(value: number) {
    this._accountingPassengerOverdraft = value;
  }

  get receiver(): ReceiverInterface {
    return this._receiver;
  }

  set receiver(value: ReceiverInterface) {
    this._receiver = value;
  }

  get calls(): OrderCallDispatcherStatusInterface[] {
    return this._calls;
  }

  set calls(value: OrderCallDispatcherStatusInterface[]) {
    this._calls = value;
  }

  get last_discussion_message(): string {
    return this._last_discussion_message;
  }

  set last_discussion_message(value: string) {
    this._last_discussion_message = value;
  }


  private _assigned_at: string;
  private _bonus_value: number;
  private _city_id: number;
  private _costPerKm: number;
  private _created_at: string;
  private _extra_bonus_value: number;
  private _fare: Fare;
  private _has_destination: boolean;
  private _hold_by: any;
  private _hold_expires_at: any;
  private _hold_type: any;
  private _hold_user: any;
  private _id?: string;
  private _is_by_the_city: boolean;
  private _is_card: boolean;
  private _is_exchange: boolean;
  private _is_from_app: boolean;
  private _is_holding: boolean;
  private _is_incomplete: boolean;
  private _is_intercity: boolean;
  private _is_plugin_status: boolean;
  private _is_preorder: boolean;
  private _is_reserved: boolean;
  private _is_sending: boolean;
  private _is_visible: boolean;
  private _is_visible_vehicle_user: boolean;
  private _locked_user: any;
  private _notifications: any[];
  private _offer: any;
  private _offers: any[];
  private _paid_amount: any;
  private _pass_comment: string;
  private _passenger: PassengerData;
  private _passenger_overdraft: PassengerOverDraftInterface | null;
  private _passengers_count: number;
  private _payment_type: string;
  private _product: Product;
  private _queued_requests: any[];
  private _reserver_by: string;
  private _service: any;
  private _service_id: number;
  private _start_comment: any;
  private _status: string;
  private _trip: any;
  private _waypoints: Address[];
  private _cab?: OrderCab;
  private _approximatelyFeed: any;
  private _assignedBy: string;
  private _assigned_at_moment: moment.Moment;
  private _disp_comment: string;
  private _features: any[];
  private _highlights: any[];
  private _is_active_order: boolean;
  private _is_call_order: boolean;
  private _is_card_payment: boolean;
  private _is_locked: boolean;
  private _locked_by: string;
  private _operator_id: string;
  private _phone_number: string;
  private _product_id: string;
  private _searched_at: string;
  private _sending_at: string;
  private _timeLeft: any;
  private _totalTime: any;
  private _trip_id: string;
  private _updated_at: string;
  private _visible_preorder: boolean;
  private _is_incity: boolean;
  private _plugin_request: any;
  private _lock_mode: string;
  private _accountingPassengerOverdraft: number;
  private _receiver: ReceiverInterface;
  private _calls: OrderCallDispatcherStatusInterface[];
  private _last_discussion_message: string;

  get passengerHasOverdraft(): boolean {
    return this.passenger_overdraft && this.passenger_overdraft.amount > 0;
  }
}
