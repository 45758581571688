import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'utax-branding-tab-brandings-list',
  templateUrl: './branding-tab-brandings-list.component.html',
  styleUrls: ['./branding-tab-brandings-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrandingTabBrandingsListComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() selectedIndex: number;
  @Output() edit: EventEmitter<number> = new EventEmitter();
  @Output() save: EventEmitter<number> = new EventEmitter();
  @Output() delete: EventEmitter<number> = new EventEmitter();
  @Output() addBrandingType = new EventEmitter();
  @Output() selectBrandingType: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    console.log('form', this.form);
  }

  get brandingTypesFormArray(): UntypedFormArray {
    return this.form.controls.types as UntypedFormArray;
  }
}
