import {Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import {UntypedFormGroup, UntypedFormArray, Validators, FormBuilder} from '@angular/forms';
import { TariffFormsGenerationService, DriverTariffsService } from '../../services';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { YesNoModalComponent } from 'src/app/shared/components';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {Commission} from "../../models/tariff.model";

@Component({
  selector: 'utax-commissions-list',
  templateUrl: './commissions-list.component.html',
  styleUrls: ['./commissions-list.component.scss']
})
export class CommissionsListComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  private dialogRef: MatDialogRef<YesNoModalComponent>;
  constructor(
    private cdr: ChangeDetectorRef,
    private tariffFormsGenerationService: TariffFormsGenerationService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private driverTariffsService: DriverTariffsService
  ) {}

  ngOnInit(): void {}

  get commissionsFormArray(): UntypedFormArray {
    return this.form.controls.commissions as UntypedFormArray;
  }

  onRemoveCommission(i: number): void {
    const commId = this.commissionsFormArray.controls[i].value.id;
    if (commId) {
      const config = new MatDialogConfig();
      config.data = {
        title: 'UTAX_FRONT_DO_YOU_REALY_WANT_TO_REMOVE?'
      };
      config.panelClass = 'yes-no-modal-container';
      this.dialogRef = this.dialog.open(YesNoModalComponent, config);
      this.dialogRef
        .afterClosed()
        .pipe(
          mergeMap(data => {
            if (data === 'YES') {
              return this.driverTariffsService.deleteCommission(commId);
            } else {
              return of();
            }
          })
        )
        .subscribe((res: any) => {
          if (res) {
            this.commissionsFormArray.removeAt(i);
            this.tariffFormsGenerationService.removeInsideScale$.next(commId);
            this.cdr.detectChanges();
          }
        });
    } else {
      this.commissionsFormArray.removeAt(i);
    }
  }

  addEmptyForm(): void {
    this.commissionsFormArray.push(this.commissionControls());
  }
  private commissionControls(el?: Commission): UntypedFormGroup {
    return this.fb.group({
      is_enabled: true,
      requests_quantity: [
        el ? el.requests_quantity : '',
        Validators.compose([Validators.required, Validators.min(0), Validators.max(10000)])
      ],
      value: [
        el ? el.value : '',
        Validators.compose([Validators.required, Validators.min(0), Validators.max(100)])
      ],
      id: [el ? el.id : '']
    });
  }

  disableFields(event, index: number): void {
    if (event.checked) {
      this.commissionsFormArray.controls[index].enable();
    } else {
      (this.commissionsFormArray.controls[index] as UntypedFormGroup).controls.requests_quantity.disable();
      (this.commissionsFormArray.controls[index] as UntypedFormGroup).controls.value.disable();
    }
  }
}
