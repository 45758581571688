import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { daysOfWeek, paymentsPeriods, intervalTypes } from '../../utils/consts';
import { TariffFormsGenerationService, DriverTariffsService } from '../../services';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { YesNoModalComponent } from 'src/app/shared/components';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'utax-regular-payments-tab',
  templateUrl: './regular-payments-tab.component.html',
  styleUrls: ['./regular-payments-tab.component.scss']
})
export class RegularPaymentsTabComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() operation: 'sub' | 'add';

  paymentsPeriods;
  intervalTypes;
  daysOfWeek;

  selectedPaymentIndex = 0;

  paymentsType = ['all', 'sending'];

  private dialogRef: MatDialogRef<YesNoModalComponent>;

  constructor(
    private tariffFormsService: TariffFormsGenerationService,
    private dialog: MatDialog,
    private driverTariffsService: DriverTariffsService
  ) {
    this.daysOfWeek = daysOfWeek;
    this.paymentsPeriods = paymentsPeriods;
    this.intervalTypes = intervalTypes;
  }

  ngOnInit(): void {}

  get regularPaymentsArray(): UntypedFormArray {
    return this.form.controls.regular_payments as UntypedFormArray;
  }

  addNewPayment(): void {
    this.tariffFormsService.addEmptyFormToRegularPaymentsFormArray(this.operation);
  }

  select(index: number): void {
    this.selectedPaymentIndex = index;
  }

  setFormValueField(event: { key: string; value: string }, name: string, index: number): void {
    (this.regularPaymentsArray.controls[index] as UntypedFormGroup).controls[name].patchValue(event.key);
    (this.regularPaymentsArray.controls[index] as UntypedFormGroup).markAllAsTouched();
  }

  onRemovePayment(index: number): void {
    const paymentId = this.regularPaymentsArray.controls[index].value.id;
    if (paymentId) {
      const config = new MatDialogConfig();
      config.data = {
        title: 'UTAX_FRONT_DO_YOU_REALY_WANT_TO_REMOVE_REGULAR_PAYMENT?'
      };
      config.panelClass = 'yes-no-modal-container';
      this.dialogRef = this.dialog.open(YesNoModalComponent, config);
      this.dialogRef
        .afterClosed()
        .pipe(
          mergeMap(data => {
            if (data === 'YES') {
              return this.driverTariffsService.deleteRegularPayment(paymentId);
            } else {
              return of();
            }
          })
        )
        .subscribe((res: any) => {
          this.select(0);
          this.regularPaymentsArray.removeAt(index);
        });
    } else {
      this.regularPaymentsArray.removeAt(index);
    }
  }
}
