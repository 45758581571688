import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  DismissalApplicationCommentBodyInterface, DismissalApplicationInterface,
  DismissalApplicationRequestInterface,
  DismissalApplicationResponseInterface,
  DismissalApplicationTransactionsInterface,
  DismissalReasonApproveInterface,
  DismissalReasonInterface
} from '../interfaces/dismissal-application.interface';
import {pluck} from "rxjs/operators";

@Injectable()
export class DismissalApplicationService {

  private dismissalUrl = 'operator/drivers/deletion-requests';
  private lastTransactionsUrl = 'operator/drivers/account';
  private firedReasonUrl = 'operator/dictionary/driver-fired-reasons';

  constructor(private http: HttpClient) {
  }


  public getDismissalApplicationList(req: DismissalApplicationRequestInterface): Observable<DismissalApplicationResponseInterface> {
    let params = new HttpParams();
    for (const key in req) {
      if (req[key]) {
        if (key === 'query') {
          params = params.append('query', req.query.replace('+', ''));
        } else if (Array.isArray(req[key])) {
          req[key].forEach((p) => {
            params = params.append(key, String(p));
          });
        } else {
          params = params.append(key, String(req[key]));
        }
      }
    }
    // params = params.append('size', '20');
    return this.http.get<DismissalApplicationResponseInterface>(`${this.dismissalUrl}`, {params});
  }

  public getFiredReasonList(service: number): Observable<DismissalReasonInterface> {
    return this.http.get<DismissalReasonInterface>(`${this.firedReasonUrl}`, {params: {service: String(service)}});
  }

  public approveDismissalApplication(req: DismissalReasonApproveInterface): Observable<any> {
    let params = new HttpParams();
    for (const key in req) {
      if (req[key] != null) {
        params = params.append(key, String(req[key]));
      }
    }
    return this.http.post<any>(`${this.dismissalUrl}/approve`, {}, {params});
  }

  public rejectDismissalApplication(id: string): Observable<any> {
    return this.http.post<any>(`${this.dismissalUrl}/reject`, {}, {params: {id}});
  }

  public getLastTransactions(id: string): Observable<DismissalApplicationTransactionsInterface> {
    return this.http.get<DismissalApplicationTransactionsInterface>(`${this.lastTransactionsUrl}/last-transactions`, {params: {id}});
  }

  public postComment(service: number, body: DismissalApplicationCommentBodyInterface): Observable<DismissalApplicationInterface> {
    return this.http.post<DismissalApplicationInterface>(
      `${this.dismissalUrl}/comment`,
      body,
    {headers: {'X-Taxi-Service': service.toString()}}
    ).pipe(pluck('data'));
  }


}
