import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  Component,
  OnInit,
  Optional,
  Inject,
  HostListener,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef, ChangeDetectorRef, OnDestroy
} from '@angular/core';
import {ImageCroppedEvent, ImageTransform, LoadedImage} from 'ngx-image-cropper';
import {ImageInterface} from "../../../autocolumn/interfaces/image.interface";
import {DomSanitizer} from "@angular/platform-browser";
import {ImageHubService} from "../../../autocolumn/services/image-hub.service";
import {DRIVER_IMAGES_EMPTY, VEHICLE_IMAGES_EMPTY} from "../../../autocolumn/utils/empty-images";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {tap} from "rxjs/operators";
import {NgxSpinnerService} from "ngx-spinner";

function rotate(srcBase64, degrees, callback) {
  const canvas = document.createElement('canvas');
  const ctx    = canvas.getContext('2d');
  const image  = new Image();

  image.onload = () => {
    canvas.width  = degrees % 180 === 0 ? image.width : image.height;
    canvas.height = degrees % 180 === 0 ? image.height : image.width;

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(degrees * Math.PI / 180);
    ctx.drawImage(image, image.width / -2, image.height / -2);

    callback(canvas.toDataURL());
  };

  image.src = srcBase64;
}

@UntilDestroy()
@Component({
  selector: 'app-show-images-dialog',
  templateUrl: './show-image-dialog.component.html',
  styleUrls: ['./show-image-dialog.component.scss']
})
export class ShowImageDialogComponent implements OnInit, OnDestroy {


  selectedImageIndex = 0;
  canvasRotation = 0;
  rotation = 0;
  transform: ImageTransform = {};
  scale = 1;

  @ViewChild('inputImage') inputImage: ElementRef;
  activeColor = 'green';
  baseColor = '#ccc';
  overlayColor = 'rgba(255,255,255,0.5)';
  images: ImageInterface[] = [];

  dragging = false;
  loaded = false;
  imageLoaded = false;
  imageSrc = '';
  private currentLoadedImage: LoadedImage;
  private dateNow = Date.now();

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ShowImageDialogComponent>,
              private cdr: ChangeDetectorRef,
              public sanitizer: DomSanitizer,
              private dialog: MatDialog,
              private imageHubService: ImageHubService,
              private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit() {
    this.init();
    this.imageHubService.renewPhoto$
      .pipe(
        tap(() => {
          this.init(true);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private init(skipSelect = false) {
    this.images = this.data.vehicle_id ? VEHICLE_IMAGES_EMPTY : DRIVER_IMAGES_EMPTY;
    if ( !skipSelect) {
      this.images = this.images.map(image => {
        image.url = this.transformUrl(this.data.images.find(img => img.type === image.type)?.url, image.type === 'driver_avatar') || '';
        return image;
      });
    } else {
      this.images = this.images.map(image => {
        image.url = this.data.vehicle_id ?
          this.transformUrl(this.imageHubService.imageVehicleGallery$.value.find(img => img.type === image.type)?.url, image.type === 'driver_avatar') || '' :
          this.transformUrl(this.imageHubService.imageDriverGallery$.value.find(img => img.type === image.type)?.url, image.type === 'driver_avatar') || '';
        return image;
      });
    }
    if (!skipSelect) {
      this.selectedImageIndex = this.images.findIndex(image => image.type === this.data.type);
    }
    this.canvasRotation = 0;
    this.rotation = 0;
    this.spinner.hide();
  }

  rotateRight() {
    this.canvasRotation--;
    this.rotation = this.rotation === -270 ? 0 : this.rotation - 90;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  moveImage(direction: 'left' | 'right'): void {
    this.resetImage();
    if (direction === 'left' && this.selectedImageIndex > 0) {
      this.selectedImageIndex--;
    }
    if (direction === 'right' && this.selectedImageIndex < this.images.length - 1) {
      this.selectedImageIndex++;
    }
  }

  selectImage(index): void {
    this.resetImage();
    this.selectedImageIndex = index;
  }

  // upload drag and drop

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  handleInputChange(e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    const pattern = /[^\s]+(.*?).(jpg|jpeg|png|JPG|JPEG|PNG)$/;
    const reader = new FileReader();

    if (!file?.type?.match(pattern)) {
      alert('invalid format');
      return;
    }
    this.loaded = false;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
    this.addImagesToHub(file);
  }

  _handleReaderLoaded(e) {
    const reader = e.target;
    this.imageSrc = reader.result;
    this.loaded = true;
    this.cdr.detectChanges();
  }

  checkImage(event: MouseEvent, forceEdit?: boolean) {
    if (this.images[this.selectedImageIndex].url && !forceEdit) {
      event.preventDefault();
    }
    if (forceEdit || !this.images[this.selectedImageIndex].url) {
      this.inputImage.nativeElement.click(event);
    }
  }

  private addImagesToHub(file: Blob) {
    if (this.data.vehicle_id) {
      this.imageHubService.vehiclesImageHub.next(
        {vehicle_id: this.data.vehicle_id, image: {file, type: this.images[this.selectedImageIndex].type}}
      );
    } else {
      this.imageHubService.driverImageHub.next({file, type: this.images[this.selectedImageIndex].type});
    }
  }


  //


  @HostListener('window:keydown', ['$event'])
  hotkeyHandler($event) {
    switch ($event.keyCode) {
      case 37: {
        $event.stopPropagation();
        this.moveImage('left');
        break;
      }

      case 39: {
        $event.stopPropagation();
        this.moveImage('right');
        break;
      }

      default: {
        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.images = [];
    this.imageHubService.imageVehicleGallery$.next([]);
    this.imageHubService.imageDriverGallery$.next([]);
  }

  imageCropped($event: ImageCroppedEvent) {

  }

  imageLoad($event: LoadedImage) {
    this.currentLoadedImage = $event;
  }

  saveImage() {
    this.spinner.show();
    if (this.rotation) {
      this.toDataURL(
        this.transformUrl(this.images[this.selectedImageIndex].url, this.images[this.selectedImageIndex].type === 'driver_avatar', true),
        (dataUrl) => {
          rotate(dataUrl, this.rotation, (base64) => {
            fetch(base64)
              .then(res => res.blob())
              .then(blob => {
                const pattern = /[^\s]+(.*?).(jpg|jpeg|png|JPG|JPEG|PNG)$/;
                if (!blob?.type?.match(pattern)) {
                  alert('invalid format');
                  return;
                } else {
                  const type = blob.type.split('/')[1];
                  const file = new File(
                    [blob],
                    `new_photo.${type}`,
                    { type: blob.type });
                  this.addImagesToHub(file);
                }
              });
          });
        }
      );
    }
  }

  private transformUrl(url: string, isAvatar: boolean, isRotate = false): string {
    if (!url) {
      return '';
    } else {
      if (isRotate) {
        let searchRotateStr = '';
        if (url.includes('_m', -10)) {
          searchRotateStr = '_m';
        }
        const lastIndexRotate = url.lastIndexOf(searchRotateStr);
        if (lastIndexRotate === -1) {
          return url;
        }
        if (isAvatar) {
          return url.substring(0, lastIndexRotate) + '' + url.substring(lastIndexRotate + 2);
        } else {
          return url.substring(0, lastIndexRotate) + '_o' + url.substring(lastIndexRotate + 2);
        }
      }
      let searchStr = '';
      if (url.includes('_o', -10)) {
        searchStr = '_o';
      } else if (url.includes('_m', -10)) {
        searchStr = '_m';
      } else if (url.includes('_s', -10)) {
        searchStr = '_s';
      } else if (url.includes('_t', -10)) {
        searchStr = '_t';
      }
      const lastIndex = url.lastIndexOf(searchStr);
      if (lastIndex === -1) {
        return url;
      }
      if (isAvatar) {
        return url.substring(0, lastIndex) + '' + url.substring(lastIndex + 2) + `?dates=${this.dateNow}`;
        } else {
        return url.substring(0, lastIndex) + '_m' + url.substring(lastIndex + 2);
        }
    }
  }

  toDataURL(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
}

