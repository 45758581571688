import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import * as L from 'leaflet';
import {gridLayer, Map} from 'leaflet';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { MapControlsComponent } from '../controls/controls.component';
import 'leaflet.gridlayer.googlemutant';
import {StateService} from "@global-services/state.service";


@Component({
  selector: 'utax-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  @Input() ScaleFn: (map: Map) => void;
  @Input() center;
  @ViewChild('map', { static: true }) mapEl: ElementRef;
  control: MapControlsComponent;
  map: Map;
  map$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  onDashboardPage: boolean;

  constructor(private stateService: StateService) {}

  ngOnInit() {
    let tileLayer;
    if (this.stateService.dumbStore?.systemSetting?.maps_google_maps_enable === '1') {
      tileLayer = L.gridLayer.googleMutant({
        type: 'roadmap',
        attribution: 'UTAX'
      });
    } else {
      tileLayer = L.tileLayer(environment.config.tileServer, {
        attribution: 'UTAX'
      });
    }

    this.map = L.map(this.mapEl.nativeElement).setView(
      [50.4516886, 30.5056551],
      10
    );
    this.map.invalidateSize();
    tileLayer.addTo(this.map);
    this.map$.next(true);

    this.ScaleFn(this.map);
  }
}

