import { BehaviorSubject } from 'rxjs';
import { Notification } from '../interfaces/order.interface';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { WebsocketService } from './ws.service';
import { OrdersService } from './orders.service';
import {OrderClass} from "@global-classes/order.class";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  notifications = {};
  notifications$: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(private ws: WebsocketService, private ordersService: OrdersService) {
  }

  enableService() {
    this.ordersService.currentOrdersUpdates$.subscribe(orders => {
      const notifications = {};

      for (const order of orders.values()) {
        notifications[order.id] = {
          phone: this.takeLastOfChanel(order.notifications, 'phone'),
          call: this.takeLastOfChanel(order.notifications, 'call', order),
          sms: this.takeLastOfChanel(order.notifications, 'sms'),
          fcm: this.takeLastOfChanel(order.notifications, 'fcm')
        };
      }

      this.notifications = notifications;
      this.notifications$.next(notifications);
    });

    this.ordersService.ordersDeletions$.subscribe(id => {
      delete this.notifications[id];
    });

    const mirandaConnection = this.ws.openConnection('miranda');

    mirandaConnection.on('req_notification').subscribe(msg => {
      const notifi = msg.data;
      if (!this.notifications[notifi.request_id]) {
        this.notifications[notifi.request_id] = {};
      }
      const currentOrder =  this.ordersService.currentOrders.get(notifi.request_id);
      const allowNotificationTypes = !currentOrder || !currentOrder.cab ? ['accepted', 'extend_cab_search', 'cancel_cab_search'] : ['accepted'];
      if (!allowNotificationTypes.includes(notifi?.type)) {
        return;
      }
      this.notifications[notifi.request_id][notifi.channel] = notifi;
      this.notifications$.next(this.notifications);
    });
  }

  private takeLastOfChanel(notifications: Notification[], chanel: string, order?: OrderClass) {
    const allowNotificationTypes = !order || !order.cab ? ['accepted', 'extend_cab_search', 'cancel_cab_search'] : ['accepted'];
    const chanelNoti = notifications.filter((notif: Notification) => {
      return allowNotificationTypes.includes(notif.type) && notif.channel === chanel;
    });
    if (chanelNoti && chanelNoti.length > 0) {
      chanelNoti.sort((a, b) => {
        return moment(b.queued_at).diff(a.queued_at);
      });
      return chanelNoti[0];
    } else {
      return null;
    }
  }
}
