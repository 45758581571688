import { DispOrdersService } from './../../services/disp-orders.service';
import { Map } from 'leaflet';
import { of, Subject, timer } from 'rxjs';
import { Order } from './../../../interfaces/order.interface';
import {Component, OnInit, Input, OnChanges, OnDestroy, AfterViewInit, ViewChild} from '@angular/core';
import * as L from 'leaflet';
import {distinctUntilChanged, takeUntil, tap} from 'rxjs/operators';
import { DriverTrackingService } from 'src/app/services/driver-tracking.service';
import * as _ from 'lodash';
import {createMarker, decode} from 'src/app/map/utils/map';
import * as moment from 'moment';
import {OrderClass} from '@global-classes/order.class';
import {LocationService} from "../../../shared/services/location.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@Component({
  selector: 'utax-dispatcher-details-map',
  templateUrl: './dispatcher-details-map.component.html',
  styleUrls: ['./dispatcher-details-map.component.scss']
})
export class DispatcherDetailsMapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() order: OrderClass;
  orderUpdated$ = new Subject();
  polyline = '';
  cab;
  sub;

  private componentDestroyed$ = new Subject();

  constructor(private dispOrdersService: DispOrdersService,
              private driverTrackingService: DriverTrackingService,
              private locationService: LocationService
  ) {

  }

  ngOnInit() {

  }

  ngOnChanges() {
    this.cab = null;
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.order?.cab) {
      let startTime;
      if (this.order.is_preorder && !moment.utc(this.order.assigned_at).isBefore()) {
        startTime = this.order.updated_at;
      } else {
        startTime = this.order.assigned_at;
      }

      this.sub = this.driverTrackingService
        .getCabGeoInfo(this.order.cab, startTime)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(res => {
          this.cab = res;
          this.polyline = res ? res.polyline : '';
        });
    }
  }

  scale = (map: Map) => {
    // first scale of selected route
    map.invalidateSize();
    const bound = L.latLngBounds(this.getBoundsForMap(this.dispOrdersService.selectedOrder));

    if (bound.isValid()) {
      map.fitBounds(bound);
    }

    // is route changed scale to it
    this.dispOrdersService.selectedOrder$
      .pipe(takeUntil(this.componentDestroyed$))
      .pipe(distinctUntilChanged((prewOrder, currOrder) => prewOrder?.id === currOrder?.id))
      .subscribe((order: Order) => {
        map.invalidateSize();
        const bounds = L.latLngBounds(this.getBoundsForMap(order));

        if (bounds.isValid()) {
          map.fitBounds(bounds);
        }
      });
  };

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  private getBoundsForMap(order): any[] {
    const points = _.cloneDeep(order?.waypoints);
    if (order?.cab) {
      points.push(order?.cab.location);
    }
    if (points) {
      points.push(L.latLngBounds(decode(order?.fare.route.polyline)));
      if (this.locationService.passengerLocation$.value) {
        const locationArr = this.locationService.passengerLocation$.value.split(',');
        points.push(L.latLngBounds([{lat: +locationArr[0], lng: +locationArr[1]}]));
      }
      if (this.locationService.driverLocation$.value) {
        const locationArr = this.locationService.driverLocation$.value.split(',');
        points.push(L.latLngBounds([{lat: +locationArr[0], lng: +locationArr[1]}]));
      }
    }
    return points;
  }
}
