import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'utax-archive-details-info',
  templateUrl: './archive-details-info.component.html',
  styleUrls: ['./archive-details-info.component.scss']
})
export class ArchiveDetailsInfoComponent implements OnInit {
  @Input() record;

  constructor() { }

  ngOnInit() {

  }

}
