import {Component, Inject, OnInit, ViewChild, Optional} from '@angular/core';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {DriverService} from '../../../autocolumn/services';
import {VehicleService} from '../../services';
import {Subject} from 'rxjs';
import {MatSelect} from '@angular/material/select';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CabsApiService} from "@global-services/cabs-api.service";

@Component({
  selector: 'app-driver-car-dialog',
  templateUrl: './driver-car-dialog.component.html',
  styleUrls: ['./driver-car-dialog.component.scss']
})
export class DriverCarDialogComponent implements OnInit {

  taxiServices;
  items: Array<any>;
  service = null;
  templateName = 'CARS';
  typeSearch;
  @ViewChild('serviceTrack', {read: MatSelect}) serviceTrack: MatSelect;
  private searchTerms = new Subject<string>();
  public isInit = true;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DriverCarDialogComponent>,
              private cabsApiService: CabsApiService,
              private driverService: DriverService,
              private vehicleService: VehicleService) {
    this.service = data.service;
    this.typeSearch = data.type;
  }

  ngOnInit() {

    this.searchTerms.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((data) => {
        if (this.typeSearch === 'vehicle') {
          return this.vehicleService.getVehicles({
            service: this.service,
            query: data
          });
        } else if (this.typeSearch === 'whitelist') {
          return this.cabsApiService.getCabs(this.data.service, data);
        } else {
          return this.driverService.getDrivers({
            service: this.service,
            query: data
          });
        }
      })
    ).subscribe(obj => {
      this.isInit = false;
      this.items = obj['data'];
    });
  }

  query(term: string) {
    if (term !== '') {
      this.searchTerms.next(term);
    }
  }

  setItem(item) {
    this.dialogRef.close(item);
  }

}
