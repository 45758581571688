import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import * as fromContainers from './containers';
import * as fromComponents from './components';
import * as fromServices from './services';

import { MaterialModule } from 'src/app/utils/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { NgxMaskModule } from 'ngx-mask';

const ROUTES: Routes = [
  {
    path: '',
    component: fromContainers.QueueComponent
  }
];

@NgModule({
  declarations: [...fromContainers.containers, ...fromComponents.components],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    SharedModule,
    ReactiveFormsModule,
    KeyboardShortcutsModule,
    FormsModule,
    RouterModule.forChild(ROUTES),
    NgxMaskModule.forChild()
  ],
  providers: [...fromServices.services]
})
export class QueueModule {}
