import {Component, OnInit, ChangeDetectionStrategy, Optional, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'utax-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoModalComponent implements OnInit {

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InfoModalComponent>
  ) { }

  ngOnInit() {
  }

  sendOk() {
    this.dialogRef.close('OK');
  }

}
