import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RmService {
  constructor(private http: HttpClient) {}

  routeTable(sources, destinations) {
    const routeUrl = `${environment.config.routingMachine}/table/v1/driving/${[
      ...sources,
      ...destinations
    ]
      .map(loc => this.locToString(loc))
      .join(';')}?sources=${this.getIndexes(
      sources,
      0
    )}&destinations=${this.getIndexes(
      destinations,
      sources.length
    )}&annotations=distance&generate_hints=false`;
    return this.http.get(routeUrl);
  }

  private getIndexes(array, shift): string {
    return Array.apply(null, { length: array.length })
      .map(Number.call, Number)
      .map(el => el + shift)
      .join(';');
  }

  private locToString(loc) {
    return `${loc.lng},${loc.lat}`;
  }
}
