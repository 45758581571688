import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAddresses'
})
export class FilterAddressesPipe implements PipeTransform {

  transform(addresses: any[], filterBy: string): unknown {
    switch (filterBy) {
      case 'interAddress':
        return (addresses || []).filter(address => !address.key);
        break;

      default:
        return addresses;
        break;
    }
  }

}
