import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getStatusOfRequests'
})
export class GetStatusOfRequestsPipe implements PipeTransform {
  transform(requests: any, ...args: any[]): any {
    let status = '';
    if (requests && requests.length > 0) {
      requests.forEach(req => {
        if (req.status === 'in_progress') {
          switch (req.type) {
            case 3: // washing
              status = 'washing';
              // colorAndStatusOfCab.color = 'yellow';
              break;

            case 6: // rest
              status = 'rest';
              // colorAndStatusOfCab.color = 'yellow';
              break;

            default:
              break;
          }
        }
      });
    }
    return status;
  }
}
