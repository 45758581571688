import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SalaryModifierApiService, SalaryModifierService } from '../../services';
import { daysOfWeekModifier, periodTypeModifier } from '../../../utils/const';
import { ISalaryModifierDateTimeHelper, ISalaryModifierSlot } from '../../models';
import * as moment from 'moment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ConfirmDeleteModalComponent } from '../../../../../shared/components';
import { mapTo, switchMap, takeUntil } from 'rxjs/operators';
import { merge, of, Subject, Subscription } from 'rxjs';
import { HandleEventService } from '../../../../../services';
import {Sort} from '@angular/material/sort';
import {MatSelectChange} from "@angular/material/select";


@Component({
  selector: 'utax-salary-modifier-table',
  templateUrl: './salary-modifier-table.component.html',
  styleUrls: ['./salary-modifier-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalaryModifierTableComponent implements OnInit, OnDestroy {

  @Input() salaryModifiers: ISalaryModifierSlot[];
  @ViewChild(MatTable) table: MatTable<any>;
  private sub: Subscription = new Subscription();
  private changesUnsubscribe = new Subject();


  public displayedColumns: string[] = ['name', 'periodType', 'startAt', 'endAt', 'coefficient', 'saving'];
  public dataSource;
  public modifierSlotFormArray: UntypedFormArray;
  public periodTypes;
  public dayOfWeek: any[];
  public activeElementId: number = null;
  public changeElementIndex: number = null;
  private dialogConfig: MatDialogConfig = {
    data: {
      title: 'CONFIRMATION_DELETE_MODIFIER_MD',
      extra: null,
      buttons: {
        remove: {title: 'DELETE'},
        cancel: {title: 'CANCEL'}
      }
    },
    autoFocus: false,
    panelClass: 'delete-confirmation-modal-container'
  };
  public mask = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
  };
  private currentSort: Sort = null;


  constructor(private fb: UntypedFormBuilder,
              private salaryModifierService: SalaryModifierService,
              private salaryModifierApiService: SalaryModifierApiService,
              private dialog: MatDialog,
              private cdr: ChangeDetectorRef,
              private handleEventService: HandleEventService
  ) {
  }


  ngOnInit(): void {
    this.periodTypes = periodTypeModifier;
    this.dayOfWeek = daysOfWeekModifier;
    this.buildModifierSlotForm();
  }

  private buildModifierSlotForm() {
    this.modifierSlotFormArray = this.fb.array([]);
    for (const salaryModifier of this.salaryModifiers) {
      const modifierSlotFormGroup = this.convertSalaryModifierToForm(salaryModifier);
      this.modifierSlotFormArray.push(modifierSlotFormGroup);
    }
    this.dataSource = this.modifierSlotFormArray.controls;
    this.sub.add(this.changesUnsubscribe);
    this.watchToChange();
  }

  private convertedSRuleToDate(sRuleString?: string): ISalaryModifierDateTimeHelper {
    if (!sRuleString) {
      return {
        type: 'date'
      };
    } else if (sRuleString.length < 40) {
      const sRuleDay = sRuleString
        .slice(
          sRuleString.indexOf('{') + 1,
          sRuleString.indexOf('}')
        )
        .split(',')
        .map(day => this.dayOfWeek.find((el) => el.id === +day));
      const sRuleTime = sRuleString.slice(
        sRuleString.lastIndexOf('{') + 1,
        sRuleString.lastIndexOf('}')
      ).split('-');
      return {
        type: 'day',
        days: sRuleDay,
        startDateTime: sRuleTime[0],
        endDateTime: sRuleTime[1],
      };
    } else if (sRuleString.length > 40 && this.checkSRule(sRuleString)) {
      const dayOfWeekISO = [this.dayOfWeek[this.dayOfWeek.length - 1], ...this.dayOfWeek];
      dayOfWeekISO.pop();
      const sRuleArr = sRuleString.split(', ')
        .map((sRuleEl) => {
          return sRuleEl.slice(
            sRuleEl.indexOf('{') + 1,
            sRuleEl.indexOf('}'))
            .split(',')
            .map(day => +day);
        }).flat();
      const sRuleDay = this.dayOfWeek.filter(day => sRuleArr.some((el) => el === day.id));
      const sRuleTime = sRuleString.slice(
        sRuleString.lastIndexOf('{') + 1,
        sRuleString.lastIndexOf('}')
      ).split('-');
      return {
        type: 'day',
        days: sRuleDay,
        startDateTime: sRuleTime[0],
        endDateTime: sRuleTime[1],
      };
    } else if (sRuleString.length > 85) {
      const dayOfWeekISO = [this.dayOfWeek[this.dayOfWeek.length - 1], ...this.dayOfWeek];
      dayOfWeekISO.pop();
      const sRuleArr = sRuleString.split(', ');
      let startDay;
      const endDay = [];
      let sRuleDay;
      let sRuleTimeStart;
      let sRuleTimeEnd;
      const sRuleDays = sRuleArr.map((stringRule) => {
        return stringRule.slice(
          stringRule.indexOf('{') + 1,
          stringRule.indexOf('}')
        );
      });
      let uniq = sRuleDays.filter(a => sRuleDays.filter(b => b === a).length === 1);
      uniq = uniq.map((day) => {
        return sRuleArr.find((el) => el.indexOf(`w{${day}}`) !== -1);
      });
      uniq.forEach((period) => {
        if (period.indexOf('23:59-23:59') !== -1 || period.indexOf('-23:59') !== -1) {
          startDay = +period.slice(
            period.indexOf('{') + 1,
            period.indexOf('}')
          );
          sRuleTimeStart = period.slice(
            period.lastIndexOf('{') + 1,
            period.lastIndexOf('}')
          ).split('-')[0];
        } else {
          endDay.push(+period.slice(
            period.indexOf('{') + 1,
            period.indexOf('}')
          ));
          sRuleTimeEnd = period.slice(
            period.lastIndexOf('{') + 1,
            period.lastIndexOf('}')
          ).split('-')[1];
        }
      });
      sRuleDay = [...new Set(sRuleDays)]
        .map(el => +el)
        .filter(el => !endDay.includes(el))
        .map(day => this.dayOfWeek.find((el) => el.id === day));

      return {
        type: 'day',
        days: sRuleDay,
        startDateTime: sRuleTimeStart,
        endDateTime: sRuleTimeEnd,
      };
    } else {
      let error = null;
      const sRuleArr = sRuleString.split(', ');
      const sRuleDayStart = sRuleString
        .slice(
          sRuleString.indexOf('{') + 1,
          sRuleString.indexOf('}')
        )
        .split(',')
        .map(day => this.dayOfWeek.find((el) => el.id === +day))[0];
      const sRuleTimeStart = sRuleString
        .slice(
          sRuleString.indexOf('{', sRuleString.indexOf('}')) + 1,
          sRuleString.indexOf('}', sRuleString.indexOf('}') + 1)
        )
        .split('-');
      const sRuleTimeEnd = sRuleString
        .slice(
          sRuleString.lastIndexOf('{') + 1,
          sRuleString.lastIndexOf('}')
        )
        .split('-');
      const sRuleDayEnd = sRuleString
        .slice(
          sRuleString.indexOf('{', sRuleString.lastIndexOf('w')) + 1,
          sRuleString.indexOf('}', sRuleString.lastIndexOf('w'))
        )
        .split(',')
        .map(day => this.dayOfWeek.find((el) => el.id === +day))[0];

      if (sRuleArr.length === 2) {
        error = !(sRuleDayStart.id + 1 === sRuleDayEnd.id || sRuleDayStart.id === 6 && sRuleDayEnd.id === 0);
        if (!!!(sRuleDayStart.id + 1 === sRuleDayEnd.id || sRuleDayStart.id === 6 && sRuleDayEnd.id === 0) && (sRuleTimeStart[1] !== '23:59' || sRuleTimeEnd[0] !== '00:00')) {
          error = true;
        }
        if (error) {
          return {isError: error};
        }
      }
      return {
        type: 'solid',
        startDate: sRuleDayStart,
        endDate: sRuleDayEnd,
        startDateTime: sRuleTimeStart[0],
        endDateTime: sRuleTimeEnd[1],
      };
    }
  }

  private checkSRule(sRuleString?: string) {
    const sRuleArr = sRuleString.split(', ')
      .map((sRuleEl) => {
        return sRuleEl.slice(
          sRuleEl.lastIndexOf('{') + 1,
          sRuleEl.lastIndexOf('}'))
          .split('-');
      });
    const setSRule = new Set(sRuleArr.flat()).size;
    return setSRule === 2;
  }

  private convertedDateToSRule(element: any): string {
    const dayOfWeekISO = [this.dayOfWeek[this.dayOfWeek.length - 1], ...this.dayOfWeek];
    dayOfWeekISO.pop();
    if (element.periodType === 'day') {
      const startDateTime = moment(element.startDateTime, 'hh:mm');
      const endDateTime = moment(element.endDateTime, 'hh:mm');
      if (startDateTime.isAfter(endDateTime)) {
        const selectedDays = element.days.map(day => day.id);
        const finalArr = [];
        selectedDays.sort();
        let dayArr = [];
        selectedDays.forEach((day) => {
          if (!dayArr.length) {
            dayArr.push(day);
          } else if (dayArr[dayArr.length - 1] === day - 1) {
            dayArr.push(day);
          } else {
            finalArr.push(`"~w{${dayArr.join()}};t{${element.startDateTime}-${element.endDateTime}}~"`);
            dayArr = [];
            dayArr.push(day);
          }
        });
        finalArr.push(`"~w{${dayArr.join()}};t{${element.startDateTime}-${element.endDateTime}}~"`);
        return `[${finalArr.join(', ')}]`;
      } else {
        return `["~w{${element.days.map(day => day.id).join()}};t{${element.startDateTime}-${element.endDateTime}}~"]`;
      }
    } else {
      if (element.startDay.id === element.endDay.id) {
        return `["~w{${element.startDay.id}};t{${element.startDateTime}-${element.endDateTime}}~"]`;
      } else {
        let additionalDays;
        if (element.startDay.id < element.endDay.id) {
          additionalDays = dayOfWeekISO
            .filter(day => day.id > element.startDay.id && day.id < element.endDay.id)
            .map(day => day.id)
            .join();
        } else {
          const firstPart = dayOfWeekISO
            .slice(element.startDay.id + 1)
            .map(day => day.id);
          const secondPart = dayOfWeekISO
            .slice(0, element.endDay.id)
            .map(day => day.id);
          additionalDays = [...firstPart, ...secondPart].join();
        }
        return additionalDays ? `["~w{${element.startDay.id}};t{${element.startDateTime}-23:59}~", "~w{${additionalDays}}~", "~w{${element.endDay.id}};t{00:00-${element.endDateTime}}~"]` :
          `["~w{${element.startDay.id}};t{${element.startDateTime}-23:59}~", "~w{${element.endDay.id}};t{00:00-${element.endDateTime}}~"]`;
      }
    }
  }

  private convertDateToISOString(currentTime: string, currentDate): string {
    const strHour = +currentTime.substring(0, 2);
    const strMinute = +currentTime.substring(3);
    const strDateTime = moment(currentDate).set({hour: strHour, minute: strMinute});
    return strDateTime.toISOString();
  }

  public addData() {
    if (this.dataSource[0]?.controls?.id?.value !== null || !this.dataSource.length) {
      const modifierSlotFormGroup = this.fb.group({
        id: null,
        name: [null, [Validators.required]],
        periodType: [null, [Validators.required]],
        coefficient: [null, [Validators.required, Validators.pattern('^(?!0\\d)\\d*(\\.\\d+)?$')]],
        days: [],
        startDateTime: [null, [Validators.required]],
        endDateTime: [null, [Validators.required]],
        startDay: [],
        endDay: [],
        startDate: [null],
        endDate: [null]
      });
      this.dataSource.unshift(modifierSlotFormGroup);
      this.table.renderRows();
      this.selectSlot(0);
    }
  }

  public saveSalaryModifierSlot(element) {
    const submitForm = element.value;
    const salaryModifierSlot = this.convertFormToSalaryModifier(submitForm);
    if (submitForm.periodType === 'solid') {
      if (submitForm.startDay.id === submitForm.endDay.id) {
        const startTime = moment(submitForm.startDateTime, 'hh:mm');
        const endTime = moment(submitForm.endDateTime, 'hh:mm');
        if (!startTime.isBefore(endTime)) {
          this.handleEventService.openSnackBar('MULTIPLIER_VALUE_INCORRECT_MD');
          return;
        }
      }
    } else if (submitForm.periodType === 'date') {
      const startDate = moment(salaryModifierSlot.startDateTime);
      const endDate = moment(salaryModifierSlot.endDateTime);
      if (startDate.isSameOrAfter(endDate)) {
        this.handleEventService.openSnackBar('MULTIPLIER_VALUE_INCORRECT_MD');
        return;
      }
    }

    if (this.salaryModifiers.filter((slot) => slot.id !== submitForm.id).some((slot) => slot.name === submitForm.name)) {
      this.handleEventService.openSnackBar('MULTIPLIER_NAME_INCORRECT_MD');
      return;
    }

    if (submitForm.id) {
      this.sub.add(
        this.salaryModifierApiService.putSalaryMultiplier(salaryModifierSlot).subscribe((changedSlot) => {
          const changedIndex = this.salaryModifiers.findIndex(salaryModifier => salaryModifier.id === changedSlot.id);
          this.salaryModifiers[changedIndex] = changedSlot;
          this.changeElementIndex = null;
          this.selectSlot(null);
          this.handleEventService.openSnackBar('MULTIPLIER_WAS_CHANGED_MD');
          if (this.currentSort) {
            this.customSort(this.currentSort);
          } else {
            this.watchToChange();
          }
        }));
    } else {
      this.sub.add(
        this.salaryModifierApiService.postSalaryMultiplier(salaryModifierSlot).subscribe((createdSlot) => {
          this.salaryModifiers.push(createdSlot);
          this.dataSource[0].controls.id.setValue(createdSlot.id);
          const addedSlot = this.dataSource.splice(0, 1);
          this.dataSource.push(...addedSlot);
          this.table.renderRows();
          this.selectSlot(null);
          // this.watchToChange();
          this.handleEventService.openSnackBar('MULTIPLIER_WAS_ADDED_MD');
          if (this.currentSort) {
            this.customSort(this.currentSort);
          } else {
            this.watchToChange();
          }
        }));
    }
  }

  public selectSlot(index: number | null) {
    if (typeof this.changeElementIndex === 'number' && this.changeElementIndex !== index) {
      this.setInitialValueInSlot(this.changeElementIndex);
    }
    if (index !== 0 && !this.dataSource[0].controls.id.value) {
      this.dataSource.splice(0, 1);
      this.table.renderRows();
      this.activeElementId = index - 1;
    } else {
      this.activeElementId = index;
    }
    this.cdr.detectChanges();
  }

  public changePeriodType($event: MatSelectChange, i: number) {
    this.dataSource[i].get('days').clearValidators();
    this.dataSource[i].get('startDay').clearValidators();
    this.dataSource[i].get('endDay').clearValidators();
    this.dataSource[i].get('startDate').clearValidators();
    this.dataSource[i].get('endDate').clearValidators();
    if ($event.value === 'day') {
      this.dataSource[i].get('days').setValidators(Validators.required);
    } else if ($event.value === 'solid') {
      this.dataSource[i].get('startDay').setValidators(Validators.required);
      this.dataSource[i].get('endDay').setValidators(Validators.required);
    } else {
      this.dataSource[i].get('startDate').setValidators(Validators.required);
      this.dataSource[i].get('endDate').setValidators(Validators.required);
    }
    this.dataSource[i].patchValue({
      coefficient: null,
      days: [],
      startDateTime: null,
      endDateTime: null,
      startDay: [],
      endDay: [],
      startDate: null,
      endDate: null
    });
  }

  public openDeleteModal(id: string, i: number) {
    const dialogRef = this.dialog.open(ConfirmDeleteModalComponent, this.dialogConfig);
    const afterClosed = dialogRef.afterClosed()
      .pipe(
        switchMap((result) => result ? this.salaryModifierApiService.deleteSalaryMultiplier(id) : of('CANCEL'))
      )
      .subscribe((result) => {
        if (!result) {
          this.dataSource.splice(this.dataSource.findIndex((form) => form.controls.id.value === id), 1);
          this.salaryModifiers = this.salaryModifiers.filter((slot) => slot.id !== id);
          this.salaryModifierService.removeSalaryModifierSlot(id);
          this.watchToChange();
          this.table.renderRows();
          this.handleEventService.openSnackBar('MULTIPLIER_WAS_DELETED_MD');
        }
      });
    this.sub.add(afterClosed);
  }

  private convertFormToSalaryModifier(submittedForm: any): ISalaryModifierSlot {
    const salaryModifierSlot: ISalaryModifierSlot = {
      name: submittedForm.name,
      multiplier: submittedForm.coefficient
    };
    submittedForm.id ? salaryModifierSlot.id = submittedForm.id : null;
    if (submittedForm.periodType === 'date') {
      salaryModifierSlot.startDateTime = this.convertDateToISOString(submittedForm.startDateTime, submittedForm.startDate);
      salaryModifierSlot.endDateTime = this.convertDateToISOString(submittedForm.endDateTime, submittedForm.endDate);
    } else {
      salaryModifierSlot.timeRule = this.convertedDateToSRule(submittedForm);
    }
    return salaryModifierSlot;
  }

  private convertSalaryModifierToForm(salarySlot: ISalaryModifierSlot): UntypedFormGroup {
    let dateTimeHelper;
    if (salarySlot?.timeRule && (salarySlot.startDateTime || salarySlot?.endDateTime)) {
      dateTimeHelper = {isError: true};
    } else {
      dateTimeHelper = this.convertedSRuleToDate(salarySlot?.timeRule);
    }
    const modifierSlotFormGroup = this.fb.group({
      id: [salarySlot.id],
      name: [salarySlot.name, [Validators.required]],
      periodType: [dateTimeHelper?.isError ? '???????' : dateTimeHelper.type, [Validators.required]],
      coefficient: [salarySlot.multiplier, [Validators.required, Validators.pattern('^(?!0\\d)\\d*(\\.\\d+)?$')]],
      days: [],
      startDateTime: [null, [Validators.required]],
      endDateTime: [null, [Validators.required]],
      startDay: [],
      endDay: [],
      startDate: [null],
      endDate: [null]
    });
    if (dateTimeHelper?.type === 'day') {
      modifierSlotFormGroup.get('days').setValue(dateTimeHelper.days);
      modifierSlotFormGroup.get('startDateTime').setValue(dateTimeHelper.startDateTime);
      modifierSlotFormGroup.get('endDateTime').setValue(dateTimeHelper.endDateTime);
    } else if (dateTimeHelper?.type === 'solid') {
      modifierSlotFormGroup.get('startDay').setValue(dateTimeHelper.startDate);
      modifierSlotFormGroup.get('endDay').setValue(dateTimeHelper.endDate);
      modifierSlotFormGroup.get('startDateTime').setValue(dateTimeHelper.startDateTime);
      modifierSlotFormGroup.get('endDateTime').setValue(dateTimeHelper.endDateTime);
    } else if (dateTimeHelper?.type === 'date') {
      modifierSlotFormGroup.get('startDate').setValue(salarySlot.startDateTime);
      modifierSlotFormGroup.get('endDate').setValue(salarySlot.endDateTime);
      modifierSlotFormGroup.get('startDateTime').setValue(new Date(salarySlot.startDateTime).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      }));
      modifierSlotFormGroup.get('endDateTime').setValue(new Date(salarySlot.endDateTime).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      }));
    }
    return modifierSlotFormGroup;
  }

  private setInitialValueInSlot(index: number): void {

    const initialSlotValue = this.convertSalaryModifierToForm(this.salaryModifiers[index]).value;
    this.dataSource[index].get('days').clearValidators();
    this.dataSource[index].get('startDay').clearValidators();
    this.dataSource[index].get('endDay').clearValidators();
    this.dataSource[index].get('startDate').clearValidators();
    this.dataSource[index].get('endDate').clearValidators();
    if (initialSlotValue.periodType === 'day') {
      this.dataSource[index].get('days').setValidators(Validators.required);
    } else if (initialSlotValue.periodType === 'solid') {
      this.dataSource[index].get('startDay').setValidators(Validators.required);
      this.dataSource[index].get('endDay').setValidators(Validators.required);
    } else {
      this.dataSource[index].get('startDate').setValidators(Validators.required);
      this.dataSource[index].get('endDate').setValidators(Validators.required);
    }
    this.dataSource[index].patchValue({
      name: initialSlotValue.name,
      coefficient: initialSlotValue.coefficient,
      periodType: initialSlotValue.periodType,
      days: initialSlotValue.days,
      startDateTime: initialSlotValue.startDateTime,
      endDateTime: initialSlotValue.endDateTime,
      startDay: initialSlotValue.startDay,
      endDay: initialSlotValue.endDay,
      startDate: initialSlotValue.startDate,
      endDate: initialSlotValue.endDate
    });
    this.changeElementIndex = null;
  }

  private watchToChange(): void {
    this.changesUnsubscribe.next();
    merge(...this.modifierSlotFormArray.controls.map((control, index) =>
      control.valueChanges.pipe(
        takeUntil(this.changesUnsubscribe),
        mapTo(index)))).subscribe((index) => {
          this.changeElementIndex = index;
    });
  }

  ngOnDestroy(): void {
    this.currentSort = null;
    this.sub.unsubscribe();
  }

  customSort($event: Sort) {
    this.currentSort = $event;
    if (this.changeElementIndex && this.changeElementIndex > -1) {
      this.setInitialValueInSlot(this.changeElementIndex);
    }
    // this.changeElementIndex = null;
    // this.activeElementId = null;
    this.initialSort($event);
    switch ($event.direction) {
      case 'desc':
        this.modifierSlotFormArray.controls.sort((a, b) => {
          if (a.get('name').value > b.get('name').value) {
            return -1;
          }
          if (a.get('name').value < b.get('name').value) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        this.modifierSlotFormArray.controls.sort((a, b) => {
          if (a.get('name').value < b.get('name').value) {
            return -1;
          }
          if (a.get('name').value > b.get('name').value) {
            return 1;
          }
          return 0;
        });
        break;
    }
    this.watchToChange();
    this.table.renderRows();
    this.cdr.detectChanges();
  }

  private initialSort($event: Sort): void {
    switch ($event.direction) {
      case 'desc':
        this.salaryModifiers.sort((a, b) => {
          if (a.name > b.name) {
            return -1;
          }
          if (a.name < b.name) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        this.salaryModifiers.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        break;
    }
  }
}
