import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortPhoneNumb'
})
export class ShortPhoneNumberPipe implements PipeTransform {

  constructor() { }

  transform(phone: string): any {
    if (phone[0] === '+') {
      return phone.slice(3);
    }
    return phone;
  }
}
