import {distinctUntilChanged, skip, tap} from 'rxjs/operators';
import { AddressService } from './../../../services/address.service';
import { ApiOrderFormService } from './../../services/api-order-form.service';
import { Service } from './../../../interfaces/order.interface';
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import { OrderFormService, OrderModalService } from '../../services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {GlobalDataService} from '@global-services/global-data.service';
import {StateService} from "@global-services/state.service";
import {OrderClass} from "../../../classes/order.class";

@Component({
  selector: 'utax-basic-order-info-form',
  templateUrl: './basic-order-info-form.component.html',
  styleUrls: ['./basic-order-info-form.component.scss']
})
export class BasicOrderInfoFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('productSelect')
  private productSelect: MatSelect;
  @ViewChild('clientPhoneNumber')
  private clientPhoneNumber: ElementRef;
  @ViewChild('contactPhoneNumber')
  private contactPhoneNumber: ElementRef;
  @ViewChild('clientName') private clientName: ElementRef;
  @Input() services: Service[];
  @Input() products: any;
  @Input() order: OrderClass;
  @Input() private callWayOpen;
  @Input() queue;
  @Input() taxiServices;
  @Input() allProducts;
  @Output() public manualChangedTariff: EventEmitter<boolean> = new EventEmitter<boolean>();

  basicForm: UntypedFormGroup;

  taxiServiceChanged$: Subject<boolean> = new Subject();
  private componentDestroyed$: Subject<boolean> = new Subject();

  shortcuts: ShortcutInput[] = [];

  taxiServiceId: number;

  public viewDelivery = false;
  public operatorPassengerInfoUpdatePermission: boolean;
  public currentUserDeliveryType: 'sender' | 'recipient' = 'sender';
  public operatorPassengerDeliveryPaidPermission: boolean;
  public isOverdraft: boolean;

  constructor(
    public orderFormService: OrderFormService,
    private apiOrdFormService: ApiOrderFormService,
    private addressService: AddressService,
    private orderModalService: OrderModalService,
    public globalDataService: GlobalDataService,
    public stateService: StateService,
    public fb: FormBuilder
  ) {}

  @Input('form')
  set showStatus(form) {
    this.basicForm = form;
  }

  ngOnInit() {
    if (this.orderModalService.mode === 'default') {
      setTimeout(() => {
        this.orderFormService.generalForm.disable();
        if ((this.orderFormService.generalForm.get('basic') as UntypedFormGroup).get('receiver').get('phone_number').value) {
          this.viewDelivery = this.checkDeliveryTariff(this.basicForm.get('tariff').value);

        }
      });
      this.orderModalService.mode$.pipe(takeUntil(this.componentDestroyed$)).subscribe(mode => {
        if (mode === 'normal') {
          this.orderFormService.generalForm.enable();
          (this.orderFormService.generalForm.get('basic') as UntypedFormGroup).get('taxiService').disable();
          (this.orderFormService.generalForm.get('basic') as UntypedFormGroup).get('clientPhone').disable();
          (this.orderFormService.generalForm.get('type') as UntypedFormGroup).get('isPreorder').disable();
        }
      });
    }
    if (this.callWayOpen) {
      let service;
      if (this.queue?.productId) {
        const tariff = this.allProducts.find((product) => product.id === this.queue.productId);
        service = this.services.find((serv) => serv.id === tariff.service_id);
      } else {
        const serviceInCallCenter = this.taxiServices.find(s => s.id === this.queue?.taxiServiceId);
        service = this.services.find(s => s.id === +serviceInCallCenter.mappingExternalId);
        const defaultTariff = this.products[service.id].find(prod => prod.is_default);
        const productId = defaultTariff ? defaultTariff.id : this.products[service.id][0].id;
        this.basicForm.controls.tariff.setValue(productId);
        this.checkDeliveryTariff(productId)
      }
      this.basicForm.get('taxiService').patchValue(service.id);
    }
    this.apiOrdFormService.setPassenger(this.orderModalService.mode);
    this.addressService.setCity(this.services, this.orderFormService.generalForm.value.basic.taxiService);
    this.operatorPassengerDeliveryPaidPermission =  this.stateService.dumbStore?.systemSetting?.requests_choose_who_pay_for_shipping_for_operator_enable === '1';

    // if taxiService changed, set city and close subb above
    this.basicForm
      .get('taxiService')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$), distinctUntilChanged())
      .subscribe((taxiServiceId: any) => {
        const service = this.services.find(s => s.id === taxiServiceId);
        if (service) {
          this.orderFormService.intercityMinKM = service.settings.find(set => set.key === 'requests_intercity_default_product_min_km')?.value;
        }
        this.taxiServiceChanged$.next(true);
        this.addressService.addresses$.next();
        this.addressService.setCity(this.services, taxiServiceId);
        if (this.orderFormService.generalForm.controls.addresses.dirty) {
          this.orderFormService.clearForm();
        }
        const serviceId = this.basicForm.controls.taxiService.value;
        if (this.basicForm.controls.tariff.status === 'VALID' && !this.order) {
          const defaultTariff = this.queue?.productId ?
            this.allProducts.find((product) => product.id === this.queue?.productId) :
            this.products[serviceId].find(prod => prod.is_default);
          const productId = defaultTariff ? defaultTariff.id : this.products[serviceId][0].id;
          this.basicForm.controls.tariff.setValue(productId);
          this.checkDeliveryTariff(productId);
        }
        this.apiOrdFormService.passengerId = undefined;
        this.setPassenger();
      });

    this.tariffChangesSub();
    const service = this.services.find(s => s.id === this.basicForm.get('taxiService').value);
    if (service) {
      this.orderFormService.intercityMinKM = service.settings.find(set => set.key === 'requests_intercity_default_product_min_km')?.value;
    }
    this.stateService.getStoreParamSub('permissions').subscribe(permissions => {
      this.operatorPassengerInfoUpdatePermission = permissions.some(p => p.name === 'operator_passenger_info_update');
    });

    this.orderFormService.overdraftBalanceObservable$
      .pipe(
        tap((amount) => {
          this.isOverdraft = amount && amount > 0;
        }),
        takeUntil(this.componentDestroyed$))
      .subscribe();
  }

  ngAfterViewInit() {
    if (this.globalDataService.isNewKeyboardLayout$.value) {
      this.shortcuts.push({
          key: ['f8'],
          command: () => {
            this.productSelect.focus();
            this.productSelect.open();
          },
          allowIn: [AllowIn.Input, AllowIn.Select],
          preventDefault: true
        },
        {
          key: ['f2'],
          command: () => {
            this.clientPhoneNumber.nativeElement.focus();
          },
          allowIn: [AllowIn.Input, AllowIn.Select],
          preventDefault: true
        }
      );
    } else {
      this.shortcuts.push({
          key: ['f4'],
          command: () => {
            this.productSelect.focus();
            this.productSelect.open();
          },
          allowIn: [AllowIn.Input, AllowIn.Select],
          preventDefault: true
        },
        {
          key: ['f2'],
          command: () => {
            this.clientPhoneNumber.nativeElement.focus();
          },
          allowIn: [AllowIn.Input, AllowIn.Select],
          preventDefault: true
        },
        {
          key: ['f3'],
          command: () => {
            this.clientName.nativeElement.focus();
          },
          allowIn: [AllowIn.Input, AllowIn.Select],
          preventDefault: true
        },
        {
          key: ['f8'],
          command: () => {
            this.contactPhoneNumber.nativeElement.focus();
          },
          allowIn: [AllowIn.Input, AllowIn.Select],
          preventDefault: true
        });
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  setPassenger() {
    this.apiOrdFormService.setPassenger(this.orderModalService.mode);
  }

  private tariffChangesSub(): void {
    if (this.order) {
      this.basicForm
        .get('tariff')
        .valueChanges.pipe(takeUntil(this.componentDestroyed$), distinctUntilChanged(), skip(1))
        .subscribe((val) => {
          this.orderFormService.addedValue = 0;
          this.viewDelivery = this.checkDeliveryTariff(val);
        });
    } else {
      this.basicForm
        .get('tariff')
        .valueChanges.pipe(takeUntil(this.componentDestroyed$), distinctUntilChanged())
        .subscribe((val) => {
          this.orderFormService.addedValue = 0;
          this.viewDelivery = this.checkDeliveryTariff(val);
        });
    }
  }
  private checkDeliveryTariff(tariffId: string) {
    const tariff = this.allProducts.find((product) => product.id === tariffId);
    const returnData = tariff && tariff.type === 'delivery';
    if (returnData) {
      this.basicForm
        .get('receiver')?.get('phone_number')?.addValidators([Validators.required, Validators.pattern('[+]?38[0-9]{10}')]);
      this.basicForm
        .get('receiver')?.get('is_sender')?.addValidators([Validators.required]);
      this.basicForm
        .get('receiver')?.get('is_sender_pay')?.addValidators([Validators.required]);
      if (!this.operatorPassengerDeliveryPaidPermission) {
        this.basicForm.get('receiver')?.get('is_sender_pay')?.setValue(false, {emitEvent: false});
      }
      this.basicForm
        .get('receiver')?.get('is_sender')?.valueChanges
        .pipe(
          tap((val) => {
            if (val === false && this.isOverdraft) {
              this.basicForm.get('receiver')?.get('is_sender_pay')?.setValue(true, {emitEvent: false});
            }
          }),
          takeUntil(this.componentDestroyed$)
        )
        .subscribe();
    } else {
      this.basicForm
        .get('receiver')?.get('phone_number')?.clearValidators();
      this.basicForm
        .get('receiver')?.get('is_sender')?.clearValidators();
      this.basicForm
        .get('receiver')?.get('is_sender_pay')?.clearValidators();
      this.basicForm.get('receiver').reset({name: '', phone_number: null, is_sender: null, is_sender_pay: null}, {emitEvent: false});
      this.viewDelivery = false;
    }
    this.basicForm.get('receiver')?.get('phone_number')?.updateValueAndValidity();
    this.basicForm.get('receiver')?.get('is_sender')?.updateValueAndValidity();
    this.basicForm.get('receiver')?.get('is_sender_pay')?.updateValueAndValidity();
    return returnData;
  }

  public manualChangeTariff($event: MatSelectChange): void {
    this.manualChangedTariff.emit(true);
  }
}
