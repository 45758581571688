import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataModel, PaginationModel } from '../../shared/models/data.model';
import {
  BalanceModel,
  BalanceStatisticModel,
  BalanceStatisticPeriod,
  BalanceTransaction
} from '../models/balance.models';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BalanceApiService {

  constructor(private httpClient: HttpClient) {
  }

  getBalance(): Observable<BalanceModel> {
    return this.httpClient.get<DataModel<BalanceModel>>('operator/balance')
      .pipe(pluck('data'));
  }

  getStatistic(period: BalanceStatisticPeriod): Observable<BalanceStatisticModel> {
    return this.httpClient.get<BalanceStatisticModel>('operator/balance/statistic', {
      params: {
        period: period.toString(),
      }
    });
  }

  getTransactions(page: number, pageSize: number): Observable<PaginationModel<BalanceTransaction[]>> {
    return this.httpClient.get<PaginationModel<BalanceTransaction[]>>('operator/balance/history', {
      params: {
        page: page.toString(),
        per_page: pageSize.toString(),
      }
    });
  }
}
