import {DispatcherModel, DispatcherWorkStatus, DispCallStatus} from '../models';


export function isFitInBrigades(disp: DispatcherModel, brigadesFilter: any[]): boolean {
  return brigadesFilter.some(brigade => brigade.name === disp.brigade);
}

export function isFitInRoles(disp: DispatcherModel, rolesFilter: any[]): boolean {
  return disp.roleIds.some((grp: any) => {
    return rolesFilter.some(roleId => roleId === grp);
  });
}

export function isName(disp: DispatcherModel, term: string): boolean {
  return disp.name.toLocaleLowerCase().includes(term.toLocaleLowerCase()) ||
    disp.displayName.toLocaleLowerCase().includes(term.toLocaleLowerCase());
}

export function isIncomingDriverFilter(disp: DispatcherModel, term: string): boolean {
  if (term === 'only') {
    return disp.activeCallLines.incoming_from_drivers.active === true;
  } else if (term === 'without') {
    return disp.activeCallLines.incoming_from_drivers.active === false;
  }
}

export function isIncomingCompanyDriverFilter(disp: DispatcherModel, term: string): boolean {
  if (term === 'only') {
    return disp.activeCallLines.incoming_from_company_drivers.active === true;
  } else if (term === 'without') {
    return disp.activeCallLines.incoming_from_company_drivers.active === false;
  }
}


export function isQueueGroupFit(disp: DispatcherModel, queueGroupsFilter: any[]): boolean {
  let isFit = false;

  queueGroupsFilter.forEach(filterGroupUuid => {
    if (!isFit && disp.taxiServiceIds.some(group => group === filterGroupUuid)) {
      isFit = true;
    }
  });
  return isFit;
}

export function isStatusFit(disp: DispatcherModel, statusesFilter: any[]): boolean {
  let isFit = false;
  const dispStatuses = DispatcherWorkStatus;
  const callStatuses = DispCallStatus;

  (statusesFilter || []).forEach(status => {
    if (!isFit && status === 'FREE') {
      if (disp.status?.displayStatus === callStatuses.FREE) {
        isFit = true;
      }
    } else if (!isFit && status === 'INACTIVE') {
      if (
        disp.status?.displayStatus === dispStatuses.INACTIVE ||
        disp.status?.displayStatus === dispStatuses.PAUSED
      ) {
        isFit = true;
      }
    } else if (!isFit && status === 'remote') {
      if (disp.status?.remoteWorkStatus === dispStatuses.REMOTELY) {
        isFit = true;
      }
    } else if (!isFit && status === 'TALKING') {
      if (disp.status?.displayStatus === callStatuses.TALKING) {
        isFit = true;
      }
    } else if (!isFit && status === 'DIALED') {
      if (disp.status?.displayStatus === callStatuses.DIALED) {
        isFit = true;
      }
    }
  });
  return isFit;
}
