import { DriversListComponent } from './drivers-list/drivers-list.component';
import { CabCancelReqModalComponent } from './cab-cancel-req-modal/cab-cancel-req-modal.component';
import { CabStatusesComponent } from './cab-statuses/cab-statuses.component';
import { CabRestStartModalComponent } from './cab-rest-start-modal/cab-rest-start-modal.component';
import { CabWashingStartModalComponent } from './cab-washing-start-modal/cab-washing-start-modal.component';
import { CabRefillModalComponent } from './cab-refill-modal/cab-refill-modal.component';
import { AmeliaImgComponent } from './amelia-img/amelia-img.component';
import { RequestPanelComponent } from './request-panel/request-panel.component';
import { CabFinishShiftModalComponent } from './cab-finish-shift-modal/cab-finish-shift-modal.component';
import { CabStartShiftModalComponent } from './cab-start-shift-modal/cab-start-shift-modal.component';
import { CabRequestModalComponent } from './cab-request-modal/cab-request-modal.component';
import { DetailsToolbarComponent } from './details-toolbar/details-toolbar.component';
import { CabComponent } from './cab/cab.component';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { LogistMapComponent } from './logist-map/logist-map.component';
import { LogistOrderComponent } from './logist-order/logist-order.component';
import { StatusTimerComponent } from './status-timer/status-timer.component';
import { CabManualStartFinishShiftModalComponent } from './cab-manual-finish-shift-modal/cab-manual-start-finish-shift-modal.component';
import { PartnersModalComponent } from './partners-modal/partners-modal.component';
import { PauseTimerComponent } from './pause-timer/pause-timer.component';
import { LogistServiceFiltersComponent } from './logist-service-filters/logist-service-filters.component';
import { RequestsHistoryComponent } from './requests-history/requests-history.component';

export const components: any[] = [
  CabComponent,
  OrdersListComponent,
  LogistMapComponent,
  LogistOrderComponent,
  DetailsToolbarComponent,
  CabRequestModalComponent,
  CabStartShiftModalComponent,
  CabFinishShiftModalComponent,
  RequestPanelComponent,
  AmeliaImgComponent,
  CabRefillModalComponent,
  CabWashingStartModalComponent,
  CabRestStartModalComponent,
  CabStatusesComponent,
  CabCancelReqModalComponent,
  StatusTimerComponent,
  CabManualStartFinishShiftModalComponent,
  DriversListComponent,
  PartnersModalComponent,
  PauseTimerComponent,
  LogistServiceFiltersComponent,
  RequestsHistoryComponent
];

export * from './requests-history/requests-history.component';
export * from './logist-service-filters/logist-service-filters.component';
export * from './pause-timer/pause-timer.component';
export * from './partners-modal/partners-modal.component';
export * from './drivers-list/drivers-list.component';
export * from './cab-cancel-req-modal/cab-cancel-req-modal.component';
export * from './cab-statuses/cab-statuses.component';
export * from './cab-rest-start-modal/cab-rest-start-modal.component';
export * from './cab-washing-start-modal/cab-washing-start-modal.component';
export * from './cab-refill-modal/cab-refill-modal.component';
export * from './amelia-img/amelia-img.component';
export * from './request-panel/request-panel.component';
export * from './cab-start-shift-modal/cab-start-shift-modal.component';
export * from './cab-finish-shift-modal/cab-finish-shift-modal.component';
export * from './cab-request-modal/cab-request-modal.component';
export * from './details-toolbar/details-toolbar.component';
export * from './cab/cab.component';
export * from './orders-list/orders-list.component';
export * from './logist-map/logist-map.component';
export * from './logist-order/logist-order.component';
export * from './status-timer/status-timer.component';
export * from './cab-manual-finish-shift-modal/cab-manual-start-finish-shift-modal.component';
