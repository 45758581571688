import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CacheService} from "../../../services/cache.service";
import {Client} from "../../../services";
import {catchError} from "rxjs/operators";
import {of} from "rxjs";
import {PassengerService} from "../../../services/passenger.service";

@Component({
  selector: 'utax-block-passenger-reason',
  templateUrl: './block-passenger-reason.component.html',
  styleUrls: ['./block-passenger-reason.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockPassengerReasonComponent implements OnInit {
  reason = null;
  reasons = [];
  comment = '';
  in_progress = false;
  err = {
    status: false,
    msg: null
  };

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<BlockPassengerReasonComponent>,
              private cache: CacheService,
              private passengerService: PassengerService,
              private cdr: ChangeDetectorRef,
              private client: Client) {
  }

  ngOnInit() {
    this.cache.getValue(this.client.getBlockReasonsUrl, {type: 'passenger_block'}).subscribe(res => {
      this.reasons = res;
      this.reason = this.reasons[0] ? this.reasons[0].id : '';
      this.cdr.detectChanges();
    });
  }

  passengerBlock() {
    this.in_progress = true;
    this.passengerService.blockPassengers({
      id: this.data.id,
      reason_id: this.reason,
      comment: this.comment
    }).pipe(
      catchError(data => {
        this.in_progress = false;
        return of(data);
      })
    ).subscribe(res => {
      if (!res.error) {
        this.dialogRef.close(true);
      }
      this.in_progress = false;
    });
  }

}
