import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

const { version } = require('../package.json');

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://fb5e5178c6ce4c339c2862edb092541b@crash.analytics.megakit.pro/17',
  ignoreErrors: [
    'Non-Error exception captured',
    'AbortError: The play() request',
    'Error: Invalid session state Establishing'
  ],
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        'localhost',
        'https://testdisp.utaxcloud.net',
        'https://balanced.utaxcloud.net',
        'https://utax.utaxcloud.net',
        'https://rm.utaxcloud.net'
      ],
      routingInstrumentation: Sentry.routingInstrumentation
    })
  ],
  release: version,
  tracesSampleRate: 1.0,
  environment: environment.production ? 'production' : 'development'
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
