import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallCenterSystemSettingsService {

  private systemSettingsUrl = 'call-centre/api/system-settings/';

  constructor(private http: HttpClient) { }

  public getSystemSetting(parameterKey: string): Observable<string> {
    return this.http.get<string>(`${this.systemSettingsUrl}${parameterKey}`);
  }

  public putSystemSetting(parameterKey: string, value: string): Observable<void> {
    return this.http.put<void>(`${this.systemSettingsUrl}${parameterKey}`, {}, {params: {value}});
  }

}
