import {ChangeDetectionStrategy, Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AddedKmModalObjInterface, Product} from "../../../interfaces/order.interface";
import {FormBuilder, FormGroup} from "@angular/forms";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {tap} from "rxjs/operators";


interface AddDistanceModalDataInterface {
  title: string;
  product: Product;
  oldAmount: number;
  oldAddedValue: number;
  surge_multiplier: number;
}
@UntilDestroy()
@Component({
  selector: 'utax-add-distance-modal',
  templateUrl: './add-distance-modal.component.html',
  styleUrls: ['./add-distance-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AddDistanceModalComponent implements OnInit {
  // public km = 0;
  // public intercityKm = 0;
  public nearIntercityKm = 0;
  public newAmount = 0;
  private returnObj: AddedKmModalObjInterface = {};
  public distanceForm: FormGroup;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: AddDistanceModalDataInterface,
    public dialogRef: MatDialogRef<AddDistanceModalComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.distanceForm = this.fb.group({
        km: [0],
        intercityKm: [0]
    });
    this.distanceForm.valueChanges
        .pipe(
            tap(() => this.calculateAmount(null)),
            untilDestroyed(this)
        )
        .subscribe();
  }


  public calculateAmount($event: any) {
    // this.km = +this.km ? +this.km : 0;
    // this.intercityKm = +this.intercityKm ? +this.intercityKm : 0;
    let sum = 0;
    if (+this.distanceForm.value?.km) {
      this.returnObj.incity_amount = Math.round(+this.distanceForm.value?.km * this.data.product.cost_per_km * this.data.surge_multiplier);
      sum += this.returnObj.incity_amount;
    }
    if (+this.distanceForm.value?.intercityKm) {
      if (+this.distanceForm.value?.intercityKm <= this.data.product.near_intercity_kms) {
        this.nearIntercityKm = +this.distanceForm.value?.intercityKm;
        this.returnObj.intercity_amount = 0;
        this.returnObj.near_intercity_amount = Math.round(+this.distanceForm.value?.intercityKm * this.data.product.near_intercity_cost_per_km);
        sum += Math.round(this.returnObj.near_intercity_amount);
      } else {
        this.nearIntercityKm = this.data.product.near_intercity_kms;
        this.returnObj.near_intercity_amount = Math.round(this.data.product.near_intercity_kms * this.data.product.near_intercity_cost_per_km);
        this.returnObj.intercity_amount = Math.round((+this.distanceForm.value?.intercityKm - this.data.product.near_intercity_kms) * this.data.product.intercity_cost_per_km);
        sum += (this.returnObj.intercity_amount + this.returnObj.near_intercity_amount);
      }
    }
    this.newAmount = this.data.oldAmount + sum;
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.returnObj = {
      ...this.returnObj,
      oldAddedValue: this.data.oldAddedValue,
      kmInfo: {
        km: +this.distanceForm.value?.km,
        near_intercity_km: this.nearIntercityKm,
        intercity_km: +this.distanceForm.value?.intercityKm > this.data.product.near_intercity_kms ? +this.distanceForm.value?.intercityKm - this.data.product.near_intercity_kms : 0,
      },
      amount: this.newAmount,
      difference_amount: this.newAmount - this.data.oldAmount
    };
    this.dialogRef.close(this.returnObj);
  }

}
