import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { TimezoneService } from 'src/app/services';

@Pipe({
  name: 'locTz'
})
export class LocTzPipe implements PipeTransform {
  constructor(private tzService: TimezoneService) {}

  transform(time, service, format?): any {
    if (this.tzService.timezones[service]) {
      let howManyAdd = 0;
      if (moment.utc(time).isDST()) {
        howManyAdd++;
      }
      return moment
        .utc(time).add(howManyAdd, 'hours')
        .tz(this.tzService.timezones[service])
        .format(format ? format : 'HH:mm');
    }
  }
}
