import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PassengerPutInterface} from "../../../../passengers/interfaces/passengers-statistic.interface";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import { finalize, tap} from "rxjs/operators";
import {DebtService} from "../../../../debt-passenger/services/debt.service";
import {DebtInterface} from "../../../../debt-passenger/interfaces/debt.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@UntilDestroy()
@Component({
  selector: 'utax-passenger-debt-cancel-modal',
  templateUrl: './passenger-debt-cancel-modal.component.html',
  styleUrls: ['./passenger-debt-cancel-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PassengerDebtCancelModalComponent implements OnInit {

  public disabledApproveButton: boolean;
  public cancelPassengerDebtForm: FormGroup;


  constructor(
    public dialogSelf: MatDialogRef<PassengerDebtCancelModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: DebtInterface,
    private debtService: DebtService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.generateForm();
  }

  approve() {
    this.disabledApproveButton = true;
    this.debtService.cancelledDebtPassenger({
      id: this.data.id,
      comment: this.cancelPassengerDebtForm.get('comment').value
    })
      .pipe(
        tap(() => this.dialogSelf.close(true)),
        finalize(() => {
          this.disabledApproveButton = false;
          this.cdr.detectChanges();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  generateForm(): void {
    this.cancelPassengerDebtForm = this.fb.group({
      comment: [null, [Validators.maxLength(255)]]
    });
  }

}
