import { ScrollingModule } from '@angular/cdk/scrolling';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from './../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MapModule } from './../map/map.module';
import { MaterialModule } from './../utils/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

// components
import * as fromComponents from './components';

// services
import * as fromServices from './services';

// containers
import * as fromContainers from './containers';

// pipes
import * as fromPipes from './pipes';
import { CallDriverModalComponent } from './components/call-driver-modal/call-driver-modal.component';
import { TelephonyModule } from '../telephony/telephony.module';
import {LottieComponent} from "ngx-lottie";

@NgModule({
    declarations: [
        ...fromComponents.components,
        ...fromContainers.containers,
        ...fromPipes.pipes,
        CallDriverModalComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        MapModule,
        TranslateModule,
        SharedModule,
        ReactiveFormsModule,
        KeyboardShortcutsModule,
        FormsModule,
        MatDialogModule,
        ScrollingModule,
        TelephonyModule,
        LottieComponent
    ],
    providers: [
        ...fromServices.services,
        {
            provide: MatDialogRef,
            useValue: {}
        }
    ]
})
export class DispatcherModule {}
