import { AmeliaService } from '../../services/amelia.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject } from '@angular/core';

@Component({
  selector: 'utax-cab-manual-start-finish-shift-modal',
  templateUrl: './cab-manual-start-finish-shift-modal.component.html',
  styleUrls: ['./cab-manual-start-finish-shift-modal.component.scss']
})
export class CabManualStartFinishShiftModalComponent implements OnInit {
  mileage;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { cab: any, title: string, finishShift: boolean},
    public dialogRef: MatDialogRef<CabManualStartFinishShiftModalComponent>,
    private amelia: AmeliaService
  ) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close('close');
  }

  confirm() {
    if (this.data.finishShift) {
      this.amelia.closeShift(this.data.cab.id, this.mileage);
    } else {
      this.amelia.openShift(this.data.cab.id, this.mileage);
    }
    this.dialogRef.close('confirm');
  }
}
