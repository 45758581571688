import { StateService } from './../../services/state.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTaxiServiceString'
})
export class GetTaxiServiceStringPipe implements PipeTransform {
  constructor(private stateService: StateService) {}

  transform(value: any): any {
    const service = this.stateService.dumbStore.service.find(
      serv => serv.id === value
    );
    return service ? service.city.name : '';
  }
}
