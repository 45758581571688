import { DriverTariffsService } from './driver-tariffs.service';
import { TariffFormsGenerationService } from './tariff-forms-generation.service';
import { NewDriverBonusesService } from './new-driver-bonuses.service';
import { ScheduledBonusesService } from './scheduled-bonuses.service';
import { BrandingApiService } from './branding-api.service';

export const services: any[] = [
  DriverTariffsService,
  TariffFormsGenerationService,
  NewDriverBonusesService,
  ScheduledBonusesService,
  BrandingApiService
];
export * from './new-driver-bonuses.service';
export * from './scheduled-bonuses.service';
export * from './driver-tariffs.service';
export * from './tariff-forms-generation.service';
