import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  QueueGroupModel,
  QueueModel,
  QueueOptionModel,
  QueuePresetModel,
  QueuePresetType,
  QueuePriorityModel
} from '../models';

@Injectable()
export class QueueApiService {
  constructor(private httpClient: HttpClient) {}

  // GET

  getGroupByTaxiServiceUuid(taxiServiceUuid: string): Observable<QueueGroupModel> {
    const params = new HttpParams().set('taxiServiceUuid', taxiServiceUuid);
    return this.httpClient.get<QueueGroupModel>('call-centre/api/queue/group', { params });
  }

  getGroupByUuid(uuid: string): Observable<QueueGroupModel> {
    return this.httpClient.get<QueueGroupModel>(`call-centre/api/queue/group/${uuid}`);
  }

  getGroups(): Observable<QueueGroupModel[]> {
    return this.httpClient.get<QueueGroupModel[]>('call-centre/api/queue/group/all');
  }

  getQueue(uuid: string): Observable<QueueModel> {
    return this.httpClient.get<QueueModel>(`call-centre/api/queue/${uuid}`);
  }

  getQueues(limit: number, page: number, groupUuid?: string, isInit?: boolean): Observable<QueueModel[]> {
    let params = new HttpParams().append('limit', String(limit)).append('page', String(page));
    if (groupUuid && !isInit) {
      params = params.append('taxiServiceId', groupUuid);
    }
    return this.httpClient.get<QueueModel[]>('call-centre/api/queues', { params });
  }

  getOption(uuid: string): Observable<QueueOptionModel> {
    return this.httpClient.get<QueueOptionModel>(`call-centre/api/queue/options/${uuid}`);
  }

  getOptions(): Observable<QueueOptionModel[]> {
    return this.httpClient.get<QueueOptionModel[]>('call-centre/api/queue/options/list');
  }

  getPresetByType(type: QueuePresetType): Observable<QueuePresetModel> {
    const params = new HttpParams().set('type', type);
    return this.httpClient.get<QueuePresetModel>('call-centre/api/queue/presets', { params });
  }

  getPresetByUuid(uuid: string): Observable<QueuePresetModel> {
    return this.httpClient.get<QueuePresetModel>(`call-centre/api/queue/presets/${uuid}`);
  }

  getPresetTypes(): Observable<string[]> {
    return this.httpClient.get<string[]>(`call-centre/api/queue/presets/types`);
  }

  getPriority(uuid: string): Observable<QueuePriorityModel> {
    return this.httpClient.get<QueuePriorityModel>(`call-centre/api/queue/options/${uuid}`);
  }

  getPrioritys(): Observable<QueuePriorityModel[]> {
    return this.httpClient.get<QueuePriorityModel[]>('call-centre/api/queue/priority/list');
  }

  // POST

  postGroup(group: QueueGroupModel): Observable<QueueGroupModel> {
    return this.httpClient.post<QueueGroupModel>('call-centre/api/queue/group', group);
  }

  postQueue(queue: QueueModel): Observable<QueueModel> {
    return this.httpClient.post<QueueModel>('call-centre/api/queues', queue);
  }

  updateQueues(data): Observable<QueueModel[]> {
    return this.httpClient.post<QueueModel[]>('call-centre/api/queues/batch', data);
  }

  postPreset(preset: QueuePresetModel): Observable<QueuePresetModel> {
    return this.httpClient.post<QueuePresetModel>('call-centre/api/queue/presets', preset);
  }

  postOption(option: QueueOptionModel): Observable<QueueOptionModel> {
    return this.httpClient.post<QueueOptionModel>('call-centre/api/queue/options', option);
  }

  postPriority(priority: QueuePriorityModel): Observable<QueuePriorityModel> {
    return this.httpClient.post<QueuePriorityModel>('call-centre/api/queue/priority', priority);
  }

  // PUT

  putGroup(group: QueueGroupModel, uuid: string): Observable<QueueGroupModel> {
    return this.httpClient.put<QueueGroupModel>(`call-centre/api/queue/group/${uuid}`, group);
  }

  putQueue(queue: QueueModel, uuid: string): Observable<QueueModel> {
    return this.httpClient.put<QueueModel>(`call-centre/api/queues/${uuid}`, queue);
  }

  putPreset(preset: QueuePresetModel, uuid: string): Observable<QueuePresetModel> {
    return this.httpClient.post<QueuePresetModel>(`call-centre/api/queue/presets/${uuid}`, preset);
  }

  putOption(option: QueueOptionModel, uuid: string): Observable<QueueOptionModel> {
    return this.httpClient.put<QueueOptionModel>(`call-centre/api/queue/options/${uuid}`, option);
  }

  putPriority(priority: QueuePriorityModel, uuid: string): Observable<QueuePriorityModel> {
    return this.httpClient.put<QueuePriorityModel>(`call-centre/api/queue/priority/${uuid}`, priority);
  }
}
