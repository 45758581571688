import { Component, OnInit, OnChanges, Input, OnDestroy } from '@angular/core';
import { createPoly } from '../utils/map';

@Component({
  selector: 'utax-map-poly',
  template: ''
})
export class MapPolyComponent implements OnInit, OnChanges, OnDestroy {
  @Input() polyline: any;
  @Input() mapComponent;
  @Input() options;
  map;
  polylineObj;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(): void {
    this.map = this.mapComponent.map;
    this.clear();
    this.polylineObj = createPoly(this.polyline, this.map, this.options);
  }

  ngOnDestroy() {
    this.clear();
  }

  clear() {
    if (this.polylineObj) {
      this.map.removeLayer(this.polylineObj);
      this.polylineObj = null;
    }
  }
}
