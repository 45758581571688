import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {finalize, tap} from "rxjs/operators";
import {DriverTariffsService} from "../../services";

@Component({
  selector: 'utax-commission-reduction-remove-dialog',
  templateUrl: './commission-reduction-remove-dialog.component.html',
  styleUrls: ['./commission-reduction-remove-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommissionReductionRemoveDialogComponent implements OnInit {
  public disabledApproveButton: boolean;


  constructor(
    public dialogSelf: MatDialogRef<CommissionReductionRemoveDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { id: string },
    private driverTariffsService: DriverTariffsService
  ) { }

  ngOnInit(): void {
  }

  approve() {
    this.disabledApproveButton = true;
    this.driverTariffsService.deleteScale(this.data.id)
      .pipe(
        tap(() => this.dialogSelf.close(this.data.id)),
        finalize(() => {
          this.disabledApproveButton = false;
        })
      )
      .subscribe();
  }

}
