import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { HeaderToolsHandlerService } from '../../../navigation/services';
import {
  RemoteWorkOperatorStatus,
  RemoteWorkRequestStatus,
  UserModelNew
} from '../../models';
import { OperatorApiService, RemoteWorkApiService, RemoteWorkService, UserApiService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { RemoteWorkDialogComponent } from '../../components';
import {delay, delayWhen, filter, map, retryWhen, switchMap, takeUntil, takeWhile} from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgOnDestroy } from '../../../shared/services';
import { AuthService } from '../../../auth/services';
import { Router } from '@angular/router';
import { BalanceModel } from '../../models/balance.models';
import { BalanceService } from '../../services/balance.service';
import {environment} from '../../../../environments/environment';
import {MyServicesModel} from '../../models/my-services.model';
import {ICategory} from '../../../models/global-data.interfaces';
import {GlobalDataService} from '@global-services/global-data.service';

@Component({
  selector: 'utax-cabinet',
  templateUrl: './cabinet.component.html',
  styleUrls: ['./cabinet.component.scss'],
  providers: [NgOnDestroy],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CabinetComponent implements OnInit, AfterViewInit {

  readonly remoteWorkOperatorStatus = RemoteWorkOperatorStatus;
  readonly remoteWorkRequestStatus = RemoteWorkRequestStatus;

  @ViewChild('controlMenu') controlMenu: TemplateRef<any>;
  @ViewChild('controlBalance') controlBalance: TemplateRef<any>;

  user: UserModelNew;
  services: MyServicesModel[];
  category: ICategory;
  roles: string;
  balance$: Observable<BalanceModel>;
  environment = environment;

  constructor(
    private headerToolsHandlerService: HeaderToolsHandlerService,
    private operatorApiService: OperatorApiService,
    private userApiService: UserApiService,
    private matDialog: MatDialog,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private remoteWorkApiService: RemoteWorkApiService,
    private ngOnDestroy$: NgOnDestroy,
    private authService: AuthService,
    private router: Router,
    public remoteWorkService: RemoteWorkService,
    private balanceService: BalanceService,
    private globalService: GlobalDataService
  ) {
  }

  ngOnInit(): void {
    this.balance$ = this.balanceService.balance$.asObservable();

    this.userApiService.getWhoAmI()
      .pipe(
        delay(100)
      )
      .subscribe(user => {
        this.user = user;
        this.roles = this.globalService.getRole(user.roleIds).join(', ');
        this.balanceService.refreshBalance();
        this.userApiService.user$.next(user);
        this.changeDetectorRef.detectChanges();
      });

    this.remoteWorkService.remoteWorkState$
      .pipe(
        filter(state => state?.last_remote_work_request?.status === RemoteWorkRequestStatus.processing),
        switchMap(state => timer(0, 20000).pipe(map(() => state))),
        takeUntil(this.ngOnDestroy$),
        takeWhile(state => this.remoteWorkService.remoteWorkState$.value?.last_remote_work_request?.status
          === RemoteWorkRequestStatus.processing),
        switchMap(state => this.remoteWorkApiService.getRemoteWorkRequestStatus(state.last_remote_work_request.id)),
        filter(state => state.status !== RemoteWorkRequestStatus.processing),
        retryWhen(errors =>
          errors.pipe(
            delayWhen(val => timer(10000))
          )
        )
      )
      .subscribe(data => {
        this.authService.authState.next(true);
        this.openResponseDialog(data.status);
      });

    this.remoteWorkService.remoteWorkState$
      .pipe(
        filter(state => state?.last_remote_work_request?.status === RemoteWorkRequestStatus.revoked),
        takeUntil(this.ngOnDestroy$)
      )
      .subscribe(() => {
        this.openResponseDialog(RemoteWorkRequestStatus.revoked);
      });
  }

  ngAfterViewInit(): void {
    this.headerToolsHandlerService.template.next({
      controlMenu: this.controlMenu,
      source: 'call-centre/cabinet'
    });
    this.headerToolsHandlerService.templateBalance.next({
      controlBalance: this.controlBalance,
      source: 'call-centre/cabinet'
    });
  }

  sendRemoteWorkRequest(): void {
    this.remoteWorkApiService.postRemoteWorkCreateRequest()
      .subscribe(() => this.remoteWorkService.init().subscribe());
  }

  openResponseDialog(response: RemoteWorkRequestStatus): void {
    const dialogRef = this.matDialog.open(RemoteWorkDialogComponent, {
      panelClass: 'utax-remote-work-dialog',
      disableClose: true,
      data: response
    });
    dialogRef.afterClosed().subscribe(data => {
      if (typeof data === 'boolean') {
        if (data) {
          this.sendRemoteWorkRequest();
        } else {
          this.startWork();
        }
      }
    });
  }

  startWork() {
    if (environment.liteVersion) {
      this.remoteWorkService.workState$.next(true);
    }
    this.router.navigateByUrl('dashboard');
  }
}
