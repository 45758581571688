import { BehaviorSubject, Subject, forkJoin, of, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { mergeMap } from 'rxjs/operators';

const archiveUrl = 'operator/requests/archive',
  recordHistoryUrl = 'operator/history/request',
  historyCaseUrl = 'operator/history/causer';

@Injectable({
  providedIn: 'root'
})
export class ArchiveApiService {
  options: any = {
    sort: 'desc',
    page: 1,
    per_page: 20,
    start_date: moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD HH:mm:ss'),
    finish_date: moment().format('YYYY-MM-DD HH:mm:ss')

    /* search: {
       phone_number: '',
       driver_phone_number: '',
       callsign: '',
       start_point: '',
       end_point: ''
     },*/
  };

  loading = true;
  query = '';
  orders = [];
  lastPage = 100;

  history$: BehaviorSubject<any> = new BehaviorSubject([]);

  checkBoxes = {
    input: {
      telephony: { icon: 'call-end', hotkey: 'F7', status: false },
      app: { icon: 'smartphone', hotkey: 'F7', status: false }
    },
    type: {
      preorder: { icon: 'time-back', hotkey: 'F8', status: false },
      current: { icon: 'lightning', hotkey: 'F8', status: false }
    },
    status: {
      completed: { icon: 'check-circle', hotkey: 'F9', status: false },
      canceled: { icon: 'remove-circle', hotkey: 'F9', status: false }
    },
    trip_source: {
      magnet: { icon: 'magnet', hotkey: 'F10', status: false },
      sending: { icon: 'phone-in-arrow', hotkey: 'F10', status: false },
      ether: { icon: 'wifi', hotkey: 'F10', status: false },
      none: { icon: 'wifi', hotkey: 'F10', status: false }
    },
    payment_type: {
      cash: { icon: 'cash', hotkey: 'F11', status: false },
      bonus: { icon: 'cashless', hotkey: 'F11', status: false }
    },
    forward: {
      exclusive: { icon: 'strange-driver', hotkey: 'F12', status: false },
      exchange: { icon: 'wifi-with-arrow', hotkey: 'F12', status: false }
    }
    /*{
      problem: { icon: 'problem', hotkey: 'F9', status: false }
    }*/
  };

  selectedRecord: any;

  constructor(private http: HttpClient) {}

  togglCheckbox(event, key) {
    this.checkBoxes[key][Object.keys(event)[0]].status = Object.values(event)[0];
    this.getArchiveList({ page: 1 });
  }

  selectRecord(record): void {
    this.selectedRecord = record;
    this.createProcessedHistory();
  }

  getArchiveList(newOptions: any = {}) {
    this.loading = true;
    if (newOptions.page == 1) {
      this.orders = [];
    }
    for (const key in newOptions) {
      this.options[key] = newOptions[key];
      if (!this.options[key]) {
        delete this.options[key];
      }
    }
    for (const groupKey in this.checkBoxes) {
      let allDisabled = false;
      for (const optionKey in this.checkBoxes[groupKey]) {
        allDisabled = allDisabled || this.checkBoxes[groupKey][optionKey].status;
      }
      for (const optionKey in this.checkBoxes[groupKey]) {
        this.options[`${groupKey}[${optionKey}]`] = +!allDisabled || +this.checkBoxes[groupKey][optionKey].status;
      }
    }
    return this.http.get(archiveUrl, { params: new HttpParams({ fromObject: this.options }) }).subscribe((res: any) => {
      this.loading = false;
      if (this.options.page == 1) {
        this.orders = res.data;
      } else {
        this.orders = [...this.orders, ...res.data];
      }
      this.lastPage = res.meta.last_page;
    });
  }

  private createProcessedHistory(): void {
    this.getRecordHistory()
      .pipe(
        mergeMap((res: any) => {
          const requests = [];
          res.data.forEach(element => {
            requests.push(this.getHistoryCase(element.id));
          });
          requests.push(of(res.data));
          return forkJoin(requests);
        })
      )
      .subscribe((res: any) => {
        const cases = [];
        res.forEach(element => {
          if (element.data) {
            cases.push(element.data);
          }
        });
        const history = res[res.length - 1].map((item: any, index: number) => {
          return { ...item, ...cases[index], service_id: localStorage.getItem('service') };
        });
        this.history$.next(history);
      });
  }

  recountDates(period: string) {
    let startDate;
    let finishDate;
    switch (period) {
      case 'today':
        startDate = moment().subtract(1, 'days');
        finishDate = moment();
        break;

      case 'yesterday':
        startDate = moment()
          .subtract(1, 'days')
          .startOf('day');
        finishDate = moment()
          .subtract(1, 'days')
          .endOf('day');
        break;

      case 'week':
        startDate = moment().subtract(1, 'week');
        finishDate = moment();
        break;

      case 'month':
        startDate = moment().subtract(1, 'month');
        finishDate = moment();
        break;

      case '3months':
        startDate = moment().subtract(3, 'month');
        finishDate = moment();
        break;

      default:
        startDate = moment().subtract(1, 'days');
        finishDate = moment();
        break;
    }
    return {
      startDate: startDate.format('YYYY-MM-DD HH:mm:ss'),
      finishDate: finishDate.format('YYYY-MM-DD HH:mm:ss')
    };
  }

  getRecordHistory(): Observable<any> {
    return this.http.get(recordHistoryUrl, { params: new HttpParams({ fromObject: { id: this.selectedRecord.id } }) });
  }

  getHistoryCase(id): Observable<any> {
    return this.http.get(historyCaseUrl, { params: new HttpParams({ fromObject: { id } }) });
  }
}
