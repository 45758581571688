import { CommissionTabComponent } from './commission-tab/commission-tab.component';
import { CurrencySelectComponent } from './currency-select/currency-select.component';
import { CommissionScaleComponent } from './commission-scale/commission-scale.component';
import { CommissionsListComponent } from './commissions-list/commissions-list.component';
import { FinesTabComponent } from './fines-tab/fines-tab.component';
import { RegularPaymentsTabComponent } from './regular-payments-tab/regular-payments-tab.component';
import { PaymentScalesComponent } from './payment-scales/payment-scales.component';
import { DriverBonusesTabComponent } from './driver-bonuses-tab/driver-bonuses-tab.component';
import { NewDriverBonusesTabComponent } from './new-driver-bonuses-tab/new-driver-bonuses-tab.component';
import { CompensationTabComponent } from './compensation-tab/compensation-tab.component';
import { BrandingTabComponent } from './branding-tab/branding-tab.component';
import { BrandingTabBrandingsListComponent } from './branding-tab-brandings-list/branding-tab-brandings-list.component';
import { BrandingTabScalesListComponent } from './branding-tab-scales-list/branding-tab-scales-list.component';
import { NewStocksTabComponent } from './new-stocks-tab/new-stocks-tab.component';
import {CommissionReductionTabComponent} from './commission-reduction/commission-reduction.component';
import {CommissionReductionDialogComponent} from './commission-reduction-dialog/commission-reduction-dialog.component';
import {
  CommissionReductionRemoveDialogComponent
} from './commission-reduction-remove-dialog/commission-reduction-remove-dialog.component';

export const components: any[] = [
  CommissionTabComponent,
  CurrencySelectComponent,
  CommissionScaleComponent,
  CommissionsListComponent,
  FinesTabComponent,
  RegularPaymentsTabComponent,
  NewStocksTabComponent,
  PaymentScalesComponent,
  DriverBonusesTabComponent,
  NewDriverBonusesTabComponent,
  CompensationTabComponent,
  BrandingTabComponent,
  BrandingTabBrandingsListComponent,
  BrandingTabScalesListComponent,
  CommissionReductionTabComponent,
  CommissionReductionDialogComponent,
  CommissionReductionRemoveDialogComponent
];

export * from './branding-tab-scales-list/branding-tab-scales-list.component';
export * from './branding-tab-brandings-list/branding-tab-brandings-list.component';
export * from './branding-tab/branding-tab.component';
export * from './compensation-tab/compensation-tab.component';
export * from './new-driver-bonuses-tab/new-driver-bonuses-tab.component';
export * from './driver-bonuses-tab/driver-bonuses-tab.component';
export * from './payment-scales/payment-scales.component';
export * from './fines-tab/fines-tab.component';
export * from './regular-payments-tab/regular-payments-tab.component';
export * from './new-stocks-tab/new-stocks-tab.component';
export * from './commissions-list/commissions-list.component';
export * from './commission-scale/commission-scale.component';
export * from './currency-select/currency-select.component';
export * from './commission-tab/commission-tab.component';
export * from './commission-reduction/commission-reduction.component';
export * from './commission-reduction-dialog/commission-reduction-dialog.component';
export * from './commission-reduction-remove-dialog/commission-reduction-remove-dialog.component';
