export interface OperatorAccountAccountStatisticModel {
  balance: number;
  performance: OperatorAccountPerformanceModel;
  time_in_work: number;
}

export interface OperatorAccountWorkStatisticModel {
  calls_missed: number;
  time_in_ready_state: number;
  time_in_talk: number;
  time_in_walked_away_state: number;
  time_in_work: number;
}

export interface OperatorAccountCallStatisticModel {
  calls_accepted: number;
  calls_missed: number;
  calls_paid: number;
  date: Date;
}

//New
export interface OperatorAccountAccountStatisticModelNEW {
  receivedCallsCount: number;
  acceptedCallsCount: number;
  rejectedCallsCount: number;
  missedCallsCount: number;
  paidCallsCount: number;
  workDuration: number;
  totalAmount: number;
}

export interface OperatorAccountCallStatisticChartModel {
  name: string;
  series: {name: Date, value: number}[];
}

export interface OperatorAccountPerformanceModel {
  calls_accepted: number;
  done_orders: number;
}

export enum OperatorAccountPeriod {
  // PER_CURRENT_MONTH = 'PER_CURRENT_MONTH',
  // PER_LAST_MONTH = 'PER_LAST_MONTH',
  // PER_LAST_WORK_SHIFT = 'PER_LAST_WORK_SHIFT',
  PER_CURRENT_PERIOD = 'CURRENT_PERIOD',
  PER_PREVIOUS_PERIOD = 'PREVIOUS_PERIOD',
}

export enum OperatorStatusNew {
  FREE = 'FREE',
  PAUSED = 'PAUSED',
  TALKING = 'TALKING'
}

export interface OperatorStatusAndDuration {
  status: OperatorStatusNew;
  seconds: number;
}
