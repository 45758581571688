import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  QueueModel,
  QueueOptionModel,
  QueuePriorityModel,
  QueueTableMode
} from '../models';
import { QueueApiService } from './queue-api.service';
import { skip, switchMap, tap } from 'rxjs/operators';
import {HandleEventService } from '../../../../services';
import {GlobalDataService} from '@global-services/global-data.service';

@Injectable()
export class QueueService {

  // DATA
  // groups$: BehaviorSubject<QueueGroupModel[]> = new BehaviorSubject<QueueGroupModel[]>([]);
  queues$: BehaviorSubject<QueueModel[]> = new BehaviorSubject<QueueModel[]>([]);
  allQueues$: BehaviorSubject<QueueModel[]> = new BehaviorSubject<QueueModel[]>([]);
  options$: BehaviorSubject<QueueOptionModel[]> = new BehaviorSubject<QueueOptionModel[]>([]);
  prioritys$: BehaviorSubject<QueuePriorityModel[]> = new BehaviorSubject<QueuePriorityModel[]>([]);

  queuesForPost$: BehaviorSubject<QueueModel[]> = new BehaviorSubject<QueueModel[]>([]);
  queuesForPut$: BehaviorSubject<QueueModel[]> = new BehaviorSubject<QueueModel[]>([]);

  // STATE
  tableMode$: BehaviorSubject<QueueTableMode> = new BehaviorSubject<QueueTableMode>(QueueTableMode.VIEW);
  selectedGroupUuid$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private queueApiService: QueueApiService,
    private handleEventService: HandleEventService,
    private globalDataService: GlobalDataService,
  ) { }

  // CONTROL

  init(taxiServiceId: string): void {
    this.selectedGroupUuid$
      .pipe(
        skip(1),
        switchMap(group => {
          if (group) {
            return this.getQueues(group);
          } else {
            return this.getQueues();
          }
        })
      )
      .subscribe();
    this.getQueues(taxiServiceId, true).subscribe(data => {
      this.allQueues$.next(data);
    });
  }

  save(): void {
    this.requestQueues()
      .subscribe(
        () => this.reload(),
        error => {
          let message: string = error.error.cause;
          message = message.replace(
            /queueServiceGroup=\[.*?\]/,
            uuid => this.findGroupByUuid(uuid).displayName
          );
          this.handleEventService.openSnackBar(message);
        }
      );
  }

  reload(): void {
    this.cancel();
    this.getQueues(this.selectedGroupUuid$.value).subscribe();
  }

  cancel(): void {
    this.queuesForPost$.next([]);
    this.queuesForPut$.next([]);
    this.tableMode = QueueTableMode.VIEW;
  }

  // SET

  set tableMode(mode: QueueTableMode) {
    this.tableMode$.next(mode);
  }

  // GET

  getQueues(uuid?: string, isInit?: boolean): Observable<QueueModel[]> {
    return this.queueApiService.getQueues(100, 0, uuid, isInit)
      .pipe(
        tap(data => {
          const queues = data.filter(q => q.taxiServiceId === uuid);
          this.queues$.next(queues);
          return of(data);
        })
      );
  }

  getOptions(): Observable<QueueOptionModel[]> {
    return this.queueApiService.getOptions()
      .pipe(
        tap(options => this.options$.next(options))
      );
  }

  getPrioritys(): Observable<QueuePriorityModel[]> {
    return this.queueApiService.getPrioritys()
      .pipe(
        tap(prioritys => this.prioritys$.next(prioritys))
      );
  }

  // POST PUT

  // куйгуыеQueues(): Observable<QueueModel> {
  //   return of<QueueModel>(null);
  // }

  requestQueues(): Observable<any> {
    if (this.queuesForPut$.value?.length || this.queuesForPost$.value?.length) {
      return this.queueApiService.updateQueues({
        update: this.queuesForPut$.value.length ? this.queuesForPut$.value : [],
        create: this.queuesForPost$.value[0] ? [this.queuesForPost$.value[0]] : []
      });
    }
    return of<any>({ upadate: [], create: []});
  }

  // FIND

  findGroupByUuid(uuid: string): any {
    if (uuid) {
      return this.globalDataService.globalTaxiServices$.value.find(group => group.id === uuid);
    }
  }

  findOptionByUuid(uuid: string): QueueOptionModel {
    if (uuid) {
      return this.options$.value.find(option => option.uuid === uuid);
    }
  }

  findPriorityByUuid(uuid: string): QueuePriorityModel {
    if (uuid) {
      return this.prioritys$.value.find(priority => priority.uuid === uuid);
    }
  }

  // ADD QUEUE

  addQueuesForPost(newQueue: QueueModel): void {
    const queueIndex = this.queuesForPost$.value.findIndex(queue => queue.id === newQueue.id);
    if (queueIndex === -1) {
      this.queuesForPost$.next([...this.queuesForPost$.value, newQueue]);
    } else {
      const newQueues = [...this.queuesForPost$.value];
      newQueues[queueIndex] = newQueue;
      this.queuesForPost$.next(newQueues);
    }
  }

  addQueuesForPut(newQueue: QueueModel): void {
    const queueIndex = this.queuesForPut$.value.findIndex(queue => queue.id === newQueue.id);
    if (queueIndex === -1) {
      this.queuesForPut$.next([...this.queuesForPut$.value, newQueue]);
    } else {
      const newQueues = [...this.queuesForPut$.value];
      newQueues[queueIndex] = newQueue;
      this.queuesForPut$.next(newQueues);
    }
  }

  removeQueuesForPost(newQueue: QueueModel): void {
    const queueIndex = this.queuesForPost$.value.findIndex(queue => queue.id === newQueue.id);
    if (queueIndex >= 0) {
      const newQueues = [...this.queuesForPost$.value];
      newQueues.splice(queueIndex, 1);
      this.queuesForPost$.next(newQueues);
    }
  }

  removeQueuesForPut(newQueue: QueueModel): void {
    const queueIndex = this.queuesForPut$.value.findIndex(queue => queue.id === newQueue.id);
    if (queueIndex >= 0) {
      const newQueues = [...this.queuesForPut$.value];
      newQueues.splice(queueIndex, 1);
      this.queuesForPut$.next(newQueues);
    }
  }
}
