import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import {Tariff, Commission, CommissionScale} from '../models/tariff.model';
import { RegularPayments, Scales } from '../models/payment.model';
import { getConvertedExpression } from 'src/app/shared/utils/convert-time-expression';
import { PeriodicBonus } from '../models/bonus.model';
import {BehaviorSubject, Subject} from "rxjs";
import {
  DriverActivityCategoryInterface
} from "../../configuration/driver-activity-category/interfaces/driver-activity-category.interface";

@Injectable()
export class TariffFormsGenerationService {
  generalForm: UntypedFormGroup;
  public setScaleIntervals$: Subject<void> = new Subject<void>();
  public allowSaveScaleIntervals = true;
  public tariff: BehaviorSubject<Tariff> = new BehaviorSubject<Tariff>(null);
  public tariffs$: BehaviorSubject<Tariff[]> = new BehaviorSubject<Tariff[]>([]);
  public selectedTariffScale$: BehaviorSubject<CommissionScale> = new BehaviorSubject<CommissionScale>(null);
  public selectedServiceId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public activityCategories$: BehaviorSubject<DriverActivityCategoryInterface[]> = new BehaviorSubject<DriverActivityCategoryInterface[]>([]);
  public selectedTabIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public openEditTariffScales$: Subject<boolean> = new Subject<boolean>();
  public updateCreateTariffScale$: Subject<any> = new Subject<any>();
  public removeScale$: Subject<string> = new Subject<string>();
  public removeInsideScale$: Subject<string> = new Subject<string>();

  private paymentTypes = ['all', 'sending'];
  private paymentAmountTypes = ['total', 'unit'];
  private paymentsPeriods = [
    {
      value: 'DAILY',
      id: 'daily'
    },
    {
      value: 'WEEKLY',
      id: 'weekly'
    }
  ];

  private intervalTypes = [
    {
      value: 'SOLID_INTERVAL',
      id: 'solid_interval'
    },
    {
      value: 'CERTAIN_TIME',
      id: 'certain_time'
    }
  ];

  constructor(private fb: UntypedFormBuilder) {}

  get subRegularPaymentsFormArray(): UntypedFormArray {
    return (this.generalForm.controls.subRegularPayments as UntypedFormGroup).controls.regular_payments as UntypedFormArray;
  }

  get addRegularPaymentsFormArray(): UntypedFormArray {
    return (this.generalForm.controls.addRegularPayments as UntypedFormGroup).controls.regular_payments as UntypedFormArray;
  }

  get addPeriodicBonusesFormArray(): UntypedFormArray {
    return (this.generalForm.controls.periodicBonuses as UntypedFormGroup).controls.bonuses as UntypedFormArray;
  }

  addEmptyFormToCommissionsArray(): void {
    ((this.generalForm.controls.commission as UntypedFormGroup).controls.commissions as UntypedFormArray).push(
      this.commissionControls()
    );
  }

  addEmptyFormToPeriodicBonusesArray(): void {
    this.addPeriodicBonusesFormArray.push(this.buildPeriodicBonuses({is_completed: false}));
  }

  addEmptyFormToRegularPaymentsFormArray(operation): void {
    if (operation === 'sub') {
      this.subRegularPaymentsFormArray.push(this.buildPaymentsFormControls());
    } else if (operation === 'add') {
      this.addRegularPaymentsFormArray.push(this.buildPaymentsFormControls(null, operation));
    }
  }

  addEmptyFormToScalesFormArray(regPaymentIndex: number, operation: 'sub' | 'add'): void {
    let paymentFormGroup;
    if (operation === 'sub') {
      paymentFormGroup = this.subRegularPaymentsFormArray.controls[regPaymentIndex] as UntypedFormGroup;
    } else if (operation === 'add') {
      paymentFormGroup = this.addRegularPaymentsFormArray.controls[regPaymentIndex] as UntypedFormGroup;
    }
    const scalesFormArray = paymentFormGroup.controls.scales as UntypedFormArray;
    scalesFormArray.push(this.initScalesFormControls());
  }

  createGeneralDriverTariffForm(tariff?: Tariff, bonuses?: PeriodicBonus[]): UntypedFormGroup {
    return this.fb.group({
      id: [tariff ? tariff.id : ''],
      commission: this.commissionTabForm(tariff),
      fines: this.finesTabForm(tariff),
      compensation: this.compensationTabFrom(tariff),
      subRegularPayments: this.regPaymentsTabForm(tariff, 'sub'),
      addRegularPayments: this.regPaymentsTabForm(tariff, 'add'),
      periodicBonuses: this.regPeriodicBonusesForm(bonuses),
    });
  }

  private commissionTabForm(tariff?: Tariff): UntypedFormGroup {
    return this.fb.group({
      name: [tariff && tariff.id ? tariff.name : '', Validators.required],
      recommended_balance: [tariff && tariff.id ? tariff.recommended_balance : '', [Validators.max(100000)]],
      commission: this.fb.group({
        is_commission_enabled: [tariff ? tariff.is_commission_enabled : true],
        commission_value: [tariff ? tariff.commission_value : 0],
        commission_type: [tariff ? tariff.commission_type : 'natural']
      }),
      distribution: this.fb.group({
        is_commission_offer_enabled: [tariff ? tariff.is_commission_offer_enabled : true],
        commission_offer_value: [tariff ? tariff.commission_offer_value : 0],
        commission_offer_type: [tariff ? tariff.commission_offer_type : 'natural']
      }),
      intercity: this.fb.group({
        is_commission_intercity_enabled: [tariff ? tariff.is_commission_intercity_enabled : false],
        commission_intercity_value: [tariff ? tariff.commission_intercity_value : 0],
        commission_intercity_type: [tariff ? tariff.commission_intercity_type : 'natural']
      }),
      commission_app_request: this.fb.group({
        is_commission_app_request_enabled: [tariff ? tariff.is_commission_app_request_enabled : false],
        commission_app_request_value: [tariff ? tariff.commission_app_request_value : 0],
        commission_app_request_type: [tariff ? tariff.commission_app_request_type : 'natural']
      }),
      hiddenMode: this.fb.group({
        is_commission_hidden_mode_enabled: [tariff ? tariff.is_commission_hidden_mode_enabled : false],
        commission_hidden_mode_type: [tariff ? tariff.commission_hidden_mode_type : 'natural'],
        commission_hidden_mode_value: [tariff ? tariff.commission_hidden_mode_value : 0]
      }),
      hiddenSendingMode: this.fb.group({
        is_commission_hidden_mode_sending_enabled: [tariff ? tariff.is_commission_hidden_mode_sending_enabled : false],
        commission_hidden_mode_sending_value: [tariff ? tariff.commission_hidden_mode_sending_value : 0],
        commission_hidden_mode_sending_type: [tariff ? tariff.commission_hidden_mode_sending_type : 'natural']
      }),
      commissionCashlessMode: this.fb.group({
        is_commission_cashless_enabled: [tariff ? tariff.is_commission_cashless_enabled : false],
        commission_cashless_value: [tariff ? tariff.commission_cashless_value : 0],
        commission_cashless_type: [tariff ? tariff.commission_cashless_type : 'natural']
      }),
      is_additional_commission_scale_enabled: [tariff ? tariff.is_additional_commission_scale_enabled : false],
      use_bonus_value: [tariff ? tariff.use_bonus_value : true],
      bonus_value: [{ value: tariff ? tariff.bonus_value : 0, disabled: tariff ? !tariff.use_bonus_value : false }],
      bonus_offer_value: [
        { value: tariff ? tariff.bonus_offer_value : 0, disabled: tariff ? !tariff.use_bonus_value : false }
      ],
      should_block_ether: [tariff ? tariff.should_block_ether : true],
      ether_min_balance: [
        { value: tariff ? tariff.ether_min_balance : 0, disabled: tariff ? !tariff.should_block_ether : false }
      ],
      debt_limit: [tariff ? tariff.debt_limit : 0],
      // commission_scale_value_type: [tariff ? tariff.commission_scale_value_type : 'sending'],
      // commission_scale_type: [tariff ? tariff.commission_scale_type : 'current_week'],
      // commission_scale_start_day: [tariff && tariff.commission_scale_start_day_of_week ? tariff.commission_scale_start_day_of_week : 1],
      // commission_scale_intervals: [tariff ? tariff.commission_scale_intervals : ''],
      // commission_scale_request_type: [tariff ? tariff.commission_scale_request_type : 'sending'],
      // commission_scale_request_visibility: [tariff ? tariff.commission_scale_request_visibility : 'all'],
      // commissions: this.generateCommissionsFormArray(tariff)
    });
  }

  private finesTabForm(tariff?: Tariff): UntypedFormGroup {
    return this.fb.group({
      penaltyOfferCancel: this.fb.group({
        should_penalty_offer_cancel: [tariff ? tariff.should_penalty_offer_cancel : true],
        penalty_offer_cancel_value: [tariff ? tariff.penalty_offer_cancel_value : 0],
        penalty_offer_cancel_type: [tariff ? tariff.penalty_offer_cancel_type : 'natural']
      }),
      penaltyOfferReject: this.fb.group({
        should_penalty_offer_reject: [tariff ? tariff.should_penalty_offer_reject : true],
        penalty_offer_reject_value: [tariff ? tariff.penalty_offer_reject_value : 0],
        penalty_offer_reject_type: [tariff ? tariff.penalty_offer_reject_type : 'natural']
      }),
      penaltyTripCancel: this.fb.group({
        should_penalty_trip_cancel: [tariff ? tariff.should_penalty_trip_cancel : true],
        penalty_trip_cancel_value: [tariff ? tariff.penalty_trip_cancel_value : 0],
        penalty_trip_cancel_type: [tariff ? tariff.penalty_trip_cancel_type : 'natural']
      }),
      penaltyCardTripCancel: this.fb.group({
        should_penalty_card_payment_trip_cancel: [tariff ? tariff.should_penalty_card_payment_trip_cancel : true],
        penalty_card_payment_trip_cancel_value: [tariff ? tariff.penalty_card_payment_trip_cancel_value : 0],
        penalty_card_payment_trip_cancel_type: [tariff ? tariff.penalty_card_payment_trip_cancel_type : 'natural']
      })
    });
  }

  private compensationTabFrom(tariff: Tariff): UntypedFormGroup {
    return this.fb.group({
      is_arrival_bonus_enabled: [tariff ? tariff.is_arrival_bonus_enabled : false],
      arrival_bonus_min_distance: [tariff ? tariff.arrival_bonus_min_distance : 0, [Validators.max(100000)]],
      arrival_bonus_max_distance: [tariff ? tariff.arrival_bonus_max_distance : 0],
      arrival_bonus_value: [tariff ? tariff.arrival_bonus_value : 0],
      arrival_bonus_base_value: [tariff ? tariff.arrival_bonus_base_value : 0],
      arrival_bonus_queue_type: [tariff ? tariff.arrival_bonus_queue_type : 'all']
    });
  }

  private regPeriodicBonusesForm(bonuses?: PeriodicBonus[]): UntypedFormGroup {
    const regPeriodBonusFormArray = this.fb.array([]);

    ((bonuses && bonuses.length > 0) ? bonuses : []).forEach((bonus: any) => {
      regPeriodBonusFormArray.push(this.buildPeriodicBonuses(bonus));
    });
    return this.fb.group({
      bonuses: regPeriodBonusFormArray
    });
  }

  private buildPeriodicBonuses(el?: PeriodicBonus): UntypedFormGroup {
    return this.fb.group({
      name: [{value: el && el.name ? el.name : '', disabled: el.is_completed}, Validators.compose([Validators.required])],
      amount: [{value: el ? el.amount : null, disabled: el.is_completed}, Validators.compose([Validators.required, Validators.min(0)])],
      requests_quantity: [{value: el ? el.requests_quantity : '', disabled: el.is_completed}, Validators.compose([Validators.required, Validators.min(0)])],
      type: [{value: this.getPeriodicBonusTypes(el), disabled: el.is_completed}, Validators.required],
      loc_name: [el && el.loc_name ? el.loc_name : null, Validators.required],
      loc_description: [el && el.loc_description ? el.loc_description : null, Validators.required],
      loc_conditions: [el && el.loc_conditions ? el.loc_conditions : null, Validators.required],
      loc_result_positive: [el && el.loc_result_positive ? el.loc_result_positive : null, Validators.required],
      loc_result_negative: [el && el.loc_result_negative ? el.loc_result_negative : null, Validators.required],
      start_time: [
        { value:el && el.start_time ? el.start_time.slice(0,5) : '00:00', disabled: el.is_completed},
        Validators.compose([
          Validators.maxLength(5),
          Validators.required,
          Validators.pattern('^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9])|(23:?00))')
        ])
      ],
      end_time: [
        { value: el && el.end_time ? el.end_time.slice(0,5) : '23:59', disabled: el.is_completed},
        Validators.compose([
          Validators.maxLength(5),
          Validators.required,
          Validators.pattern('^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9])|(23:?00))')
        ])
      ],
      start_date: [{value: el && el.start_date ? el.start_date : null, disabled: el.is_completed}, Validators.required],
      end_date: [{ value: el && el.end_date ? el.end_date : null, disabled: el.is_completed}, Validators.required],
      id: el && el.id ? el.id : null,
      tariff_plan_id: el && el.tariff_plan_id ? el.tariff_plan_id : null,
    });
  }

  private getPeriodicBonusTypes(el: PeriodicBonus): string[] {
    const types = [];
    if (el) {
      if (el.include_request_type_all) {
        types.push('all')
      }
      if (el.include_request_type_automat) {
        types.push('automat')
      }
      if (el.include_request_type_sending) {
        types.push('sending')
      }
    }
    return types;
  }

  private regPaymentsTabForm(tariff?: Tariff, regType?: string): UntypedFormGroup {
    const regPaymentsFormArray = this.fb.array([]);

    ((tariff && tariff.regular_payments) || []).forEach((payment: RegularPayments) => {
      if (payment.operation === regType) {
        regPaymentsFormArray.push(this.buildPaymentsFormControls(payment, regType));
      }
    });
    if (regPaymentsFormArray.length === 0) {
      regPaymentsFormArray.push(this.buildPaymentsFormControls(null, regType));
    }
    return this.fb.group({
      regular_payments: regPaymentsFormArray
    });
  }

  private buildPaymentsFormControls(el?: RegularPayments, regType?: string): UntypedFormGroup {
    const convertedExpression = getConvertedExpression(el);
    const form = this.fb.group({
      name: [el && el.name ? el.name : '', Validators.compose([Validators.required])],
      amount: [el ? el.amount : '', Validators.compose([Validators.required, Validators.min(0)])],
      type: [el && el.type ? el.type : this.paymentTypes[0], Validators.required],
      amount_type: [el && el.amount_type ? el.amount_type : this.paymentAmountTypes[0], Validators.required],
      days: [convertedExpression.days_of_week],
      start_time: [
        convertedExpression.start_time,
        Validators.compose([
          Validators.maxLength(5),
          Validators.required,
          Validators.pattern('^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9])|(23:?00))')
        ])
      ],
      end_time: [
        convertedExpression.end_time,
        Validators.compose([
          Validators.maxLength(5),
          Validators.required,
          Validators.pattern('^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9])|(23:?00))')
        ])
      ],
      period: [el && el.period ? el.period : this.paymentsPeriods[0].id, Validators.required],
      interval_type: [el && convertedExpression.interval_type ? convertedExpression.interval_type : this.intervalTypes[0].id],
      start_day: [el && convertedExpression.start_day ? convertedExpression.start_day : 0],
      end_day: [el && convertedExpression.end_day ? convertedExpression.end_day : 0],
      id: el && el.id ? el.id : null,
      scales: this.buildScalesFormArray(el)
    });

    if (regType === 'add') {
      form.addControl('loc_name', new UntypedFormControl(el && el.loc_name ? el.loc_name : null, Validators.required));
    }
    return form;
  }

  private buildScalesFormArray(el: RegularPayments): UntypedFormArray {
    const scalesFormArray = this.fb.array([]);
    ((el && el.scales) || []).forEach(scale => {
      scalesFormArray.push(this.initScalesFormControls(scale));
    });
    if (scalesFormArray.length === 0) {
      scalesFormArray.push(this.initScalesFormControls());
    }
    return scalesFormArray;
  }

  private initScalesFormControls(scale?: Scales) {
    return this.fb.group({
      is_enabled: [scale && scale.is_enabled ? scale.is_enabled : false],
      requests_quantity: [
        scale && scale.requests_quantity ? scale.requests_quantity : 0,
        Validators.compose([Validators.required, Validators.min(0)])
      ],
      value: [scale && scale.value ? scale.value : 0, Validators.compose([Validators.required, Validators.min(0)])],
      id: scale && scale.id ? scale.id : null,
      regular_payment_id: scale && scale.regular_payment_id ? scale.regular_payment_id : null
    });
  }

  private generateCommissionsFormArray(tariff: Tariff): UntypedFormArray {
    const commissions: Commission[] = tariff ? tariff.commissions : [];
    if (commissions && commissions.length > 0) {
      const commissionsFormArray = this.fb.array([]);
      commissions.forEach((comm: Commission) => {
        commissionsFormArray.push(this.commissionControls(comm));
      });
      return commissionsFormArray;
    } else {
      return this.fb.array([this.commissionControls()]);
    }
  }

  private commissionControls(el?: Commission): UntypedFormGroup {
    return this.fb.group({
      is_enabled: [el ? el.is_enabled : false],
      requests_quantity: [
        { value: el ? el.requests_quantity : 0, disabled: (el && el.is_enabled ? false : true) },
        Validators.compose([Validators.required, Validators.min(0), Validators.max(10000)])
      ],
      value: [
        { value: el ? el.value : 0, disabled: (el && el.is_enabled ? false : true) },
        Validators.compose([Validators.required, Validators.min(0), Validators.max(100)])
      ],
      id: [el ? el.id : '']
    });
  }
}
