import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'utax-archive-search',
  templateUrl: './archive-search.component.html',
  styleUrls: ['./archive-search.component.scss']
})
export class ArchiveSearchComponent implements OnInit {

  @Output() switchSearch = new EventEmitter();

  constructor() { }

  false = false;

  checked = {
    phone_number: false,
    driver_phone_number: false,
    callsign: false,
    start_point: false,
    end_point: false
  };

  ngOnInit() {
  }

  search(property) {
    this.checked[property] = !this.checked[property];
    this.switchSearch.emit(this.checked);
  }

}
