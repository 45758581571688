import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { HeaderToolsHandlerService } from '@global-navigation/services';
import { SalaryModifierService } from '../../services';
import {CallCenterSystemSettingsService} from '../../../../../shared/services/call-center-system-settings.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {catchError, tap} from 'rxjs/operators';
import {throwError} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'utax-salary-modifier',
  templateUrl: './salary-modifier.component.html',
  styleUrls: ['./salary-modifier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalaryModifierComponent implements OnInit, AfterViewInit {
  @ViewChild('controlMenu') controlMenu: TemplateRef<any>;
  public showPriority = false;
  public priorityEnabled = false;
  private priorityParam = 'OPERATOR_BRIGADE_SCHEDULED_PRIORITY_ENABLED';


  constructor(
    private headerToolsHandlerService: HeaderToolsHandlerService,
    public salaryModifierService: SalaryModifierService,
    public callCenterSystemSettingsService: CallCenterSystemSettingsService
  ) {
  }

  ngOnInit(): void {
    this.getSalaryModifierSetting();
    this.salaryModifierService.init();
  }

  ngAfterViewInit(): void {
    this.headerToolsHandlerService.template.next({
      controlMenu: this.controlMenu,
      source: 'call-centre/salary-modifier'
    });
  }
  private getSalaryModifierSetting(): void {
    this.callCenterSystemSettingsService
      .getSystemSetting(this.priorityParam)
      .pipe(
        tap(res => {
          this.priorityEnabled = !!!+res;
          this.showPriority = true;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public changePriority(checked: boolean): void {
    this.callCenterSystemSettingsService
      .putSystemSetting(this.priorityParam, checked ? '0' : '1')
      .pipe(
        tap(() => this.priorityEnabled = checked),
        catchError((err) => {
          this.priorityEnabled = !checked;
          return throwError(err);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
