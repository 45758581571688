import {DriverApplicationsService} from './driver-applications.service';
import {TariffLoadService} from './tariff-load.service';
import {MediaService} from './media.service';
import {DriverService} from './driver.service';
import {BrandingApiService} from './branding-api.service';
import {DriverDialogService} from './driver-dialog.service';
import {DismissalApplicationService} from './dismissal-application.service';
import {AutocolumnService} from './autocolumn.service';

export const services = [
  DriverApplicationsService,
  TariffLoadService,
  MediaService,
  DismissalApplicationService,
  DriverService,
  BrandingApiService,
  DriverDialogService,
  AutocolumnService
];

export * from './driver-applications.service';
export * from './tariff-load.service';
export * from './media.service';
export * from './dismissal-application.service';
export * from './driver.service';
export * from './branding-api.service';
export * from './driver-dialog.service';
export * from './autocolumn.service';

