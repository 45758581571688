import { Subject, interval } from 'rxjs';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { OrderFormService } from '../../services';
import { takeUntil } from 'rxjs/operators';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { TaxiServicesService } from 'src/app/services';
import * as moment from 'moment';
import {environment} from '@global-environments/environment';
import {OrderClass} from '@global-classes/order.class';

@Component({
  selector: 'utax-order-type-form',
  templateUrl: './order-type-form.component.html',
  styleUrls: ['./order-type-form.component.scss']
})
export class OrderTypeFormComponent implements OnInit, OnDestroy, AfterViewInit {
  public environment = environment;
  @Input() order: OrderClass;

  @ViewChild('timePicker') private timePicker: any;

  typeForm: UntypedFormGroup;
  currentTime: Date = new Date();
  minTime: Date = new Date();

  shortcuts: ShortcutInput[] = [];

  private componentDestroyed$ = new Subject();

  constructor(public orderFormService: OrderFormService, private taxiServicesService: TaxiServicesService) {
    interval(4000)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        this.currentTime = new Date();
      });
  }

  @Input('form')
  set showStatus(form) {
    this.typeForm = form;
  }

  ngOnInit() {
    this.typeForm.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(formValue => {
      const preordError = this.validatePreorder(formValue);
      this.typeForm.controls.preorderedDate.setErrors(preordError);
      this.typeForm.controls.preorderedTime.setErrors(preordError);
    });
    this.typeForm.get('isPreorder').valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(isPreorder => {
        this.orderFormService.preorderMessageActive$.next(isPreorder);
    });
  }

  ngAfterViewInit() {
    this.shortcuts.push({
      key: ['f12'],
      command: e => {
        if (!this.typeForm.controls.isPreorder.disabled) {
          const isPreord = (this.orderFormService.generalForm.controls.type as UntypedFormGroup).getRawValue().isPreorder;
          (this.orderFormService.generalForm.controls.type as UntypedFormGroup).controls.isPreorder.patchValue(!isPreord);

          if ((this.orderFormService.generalForm.controls.type as UntypedFormGroup).controls.isPreorder.value) {
            setTimeout(() => {
              this.timePicker.nativeElement.focus();
            }, 20);
          }
        }
      },
      allowIn: [AllowIn.Input, AllowIn.Select],
      preventDefault: true
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  fireTabEvent(event) {
    event.preventDefault();
    this.orderFormService.focusOnFirstAddress$.next(true);
  }

  private validatePreorder(formValue): ValidationErrors {
    if (!formValue.isPreorder || (this.order && this.order.cab)) {
      return null;
    }

    if (formValue.isPreorder && formValue.preorderedTime.length < 4) {
      return {
        formatError: {
          message: 'UTAX_FRONT_INVALID_FORMAT'
        }
      };
    }

    if (formValue.isPreorder) {
      const preorderedTime = formValue.preorderedTime.replace(':', '.');
      const preorderedDate = formValue.preorderedDate;
      const serviceId = (this.orderFormService.generalForm.controls.basic as UntypedFormGroup).getRawValue().taxiService;
      const minTimeInMinutes = this.taxiServicesService.servicesSettings[serviceId].requests_preorder_min_time;
      const maxTimeInHours = this.taxiServicesService.servicesSettings[serviceId].requests_preorder_max_time;
      const preordMoment = moment(preorderedDate)
        .hours(preorderedTime.split('.')[0])
        .minutes(preorderedTime.split('.')[1]);
      const diffInHours = preordMoment.diff(moment(), 'hours');
      const diffInMinutes = preordMoment.diff(moment(), 'minutes');

      if (diffInHours > -1 && diffInHours < maxTimeInHours && diffInMinutes >= minTimeInMinutes) {
        // valid time
        return null;
      } else if (diffInMinutes < minTimeInMinutes) {
        // min time validation
        return {
          preorderTimeMinInvalid: {
            message: minTimeInMinutes
          }
        };
      } else {
        // max time validation
        return {
          preorderTimeMaxInvalid: {
            message: maxTimeInHours
          }
        };
      }
    }
  }
}
