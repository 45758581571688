import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Client} from './client';
import {Observable} from "rxjs";
import {DriverHistoryInterface} from '../interfaces/history.interface';

@Injectable({providedIn: 'root'})
export class HistoryService {

  constructor(private client: Client, private http: HttpClient) {
  }

  getHistoryOrder(obj) {
    return this.http.get(this.client.getHistoryOrderUrl(obj));
  }

  getHistoryDriver(obj): Observable<DriverHistoryInterface> {
    return this.http.get<DriverHistoryInterface>(this.client.getHistoryDriverUrl(obj));
  }

  getHistoryCauser(obj) {
    return this.http.get(this.client.getHistoryCauserUrl(obj));
  }
}
