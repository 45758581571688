import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[utaxPhoneNumber]'
})
export class PhoneNumberDirective {
  @Input('phoneData') input;
  @Input() isShortPhoneData = false;
  @Output() pastedChange: EventEmitter<string> = new EventEmitter<string>();
  index = true;

  constructor(private el: ElementRef) {
    this.el.nativeElement.maxLength = this.isShortPhoneData ? 9 : 13;

    if (!this.el.nativeElement.value.length && !this.isShortPhoneData) {
      this.el.nativeElement.value = '+380_________';
    } else if (!this.el.nativeElement.value.length && this.isShortPhoneData) {
      this.el.nativeElement.value = '_________';
    }
  }

  @HostListener('input', ['$event'])
  InputDate(event) {
    switch (event.inputType) {
      case 'insertText': {
        if (event.target.value.match(/^\d$/)) {
          event.target.value = this.isShortPhoneData ? event.target.value : '+380' + event.target.value;
        } else if (event.target.value.match(/^\+380$/) !== null) {}
        break;
      }
      case 'deleteContentBackward': {
        break;
      }
    }
  }

  @HostListener('paste', ['$event'])
  InputPaste(event) {
    const clipboardData = event.clipboardData;
    let pastedText: string = clipboardData.getData('text')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '')
      .replaceAll(' ', '');
    if (this.isShortPhoneData) {
      pastedText = pastedText.replace('+', '');
      const isNumber = !isNaN(+pastedText);
      if (isNumber) {
        if (pastedText.length <= 9) {
          event.target.value = pastedText;
        } else {
          pastedText = pastedText.startsWith('0') ? pastedText.slice(1) : pastedText;
          event.target.value = pastedText.substring(0, 9);
        }
      } else {
        event.target.value = '';
      }
    } else {
      if (pastedText.length === 12 && pastedText.charAt(0) !== '+') {
        event.target.value = '+';
      } else if (pastedText.length === 10 && pastedText.charAt(0) === '0') {
        event.target.value = '+38';
      } else {
        event.target.value = '';
      }
      event.target.value += pastedText;
    }
    this.pastedChange.emit(event.target.value);
  }

  @HostListener('focus', ['$event'])
  FocusDate() {
    if (this.index && this.el.nativeElement.value.length <= 3) {
      this.el.nativeElement.value = !this.isShortPhoneData ? '+380' : '';
    }
    this.index = false;
  }
}
