import { Inject, Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {
  DispatcherFilterModel,
  DispatcherFilterType,
  DispatcherModel,
  DispCaterogy,
  IBrigade,
  PutBodyDispCallSettings
} from '../models';
import { DispSettingsApiService } from './disp-settings-api.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReactiveFormUtil } from '../../../../shared/utils/reactive-form.util';
import { CREATE_DISP_MODAL_TOKEN } from '../utils/create-disp-settings-modal-token';
import { ComponentType } from '@angular/cdk/portal';
import {GlobalDataService} from '@global-services/global-data.service';
import {map} from 'rxjs/operators';
import {ICallLine, IQueueStrategy, IRole} from '../../../../models/global-data.interfaces';

const ROLE_ADMIN = 'Администратор';

@Injectable()
export class DispSettingsService {
  form: UntypedFormGroup;
  remoteForm: UntypedFormGroup;
  allDispatchers$: BehaviorSubject<DispatcherModel[]> = new BehaviorSubject([]);
  queueDispatchers$: BehaviorSubject<DispatcherModel[]> = new BehaviorSubject([]);
  selectedQueueId$: BehaviorSubject<string> = new BehaviorSubject('');
  categories$: BehaviorSubject<DispCaterogy[]> = new BehaviorSubject([]);
  roles$: BehaviorSubject<IRole[]> = new BehaviorSubject([]);
  brigades$: BehaviorSubject<IBrigade[]> = new BehaviorSubject([]);
  queueGroups$: BehaviorSubject<IQueueStrategy[]> = new BehaviorSubject([]);
  callLines$: BehaviorSubject<ICallLine[]> = new BehaviorSubject([]);
  editFilterForm$: BehaviorSubject<UntypedFormGroup> = new BehaviorSubject(null);
  isFullView$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isExtendedView$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private limitDispatchers = 20;
  pageDispatchers = 1;
  constructor(
    private dispSettingsApiService: DispSettingsApiService,
    private globalDataService: GlobalDataService,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    @Inject(CREATE_DISP_MODAL_TOKEN) private dialogComponent: ComponentType<any>,
  ) {}

  get dispatcherdFormArray(): UntypedFormArray {
    if (this.form) {
      return this.form.controls.dispatchers as UntypedFormArray;
    }
  }

  get remoteRequestFormArray(): UntypedFormArray {
    if (this.remoteForm) {
      return this.remoteForm.controls.requests as UntypedFormArray;
    }
  }

  save(): void {
    const body = this.getBodyToSave();
    this.dispSettingsApiService.putDispCallSettings(body).subscribe();
  }

  loadAllDispatchers(filters, noActivities = false): void {
    this.dispSettingsApiService
      .getAllDispatchersFiltered(this.limitDispatchers, this.pageDispatchers, filters, noActivities)
      .subscribe(data => {
        const disps = data.content;
        // const disps = data.content.filter(disp => !disp.groups.find(group => group.loc_name === ROLE_ADMIN));
        if (this.pageDispatchers === 1) {
          this.allDispatchers$.next(disps);
        } else {
          this.allDispatchers$.next([...this.allDispatchers$.value, ...disps]);
        }
      });
  }

  prepearFilters(form): DispatcherFilterModel[] {
    const dirtyValues = ReactiveFormUtil.getDirtyValues(form);

    return Object.keys(dirtyValues)
      .reduce((acc, key) => {
        switch (key) {
          case 'name':
            acc.push({
              type: DispatcherFilterType.NAME,
              value: dirtyValues.name
            });
            break;
          case 'queueGroups':
            acc.push({
              type: DispatcherFilterType.QUEUE_GROUPS,
              value: dirtyValues.queueGroups
            });
            break;
          case 'brigades':
            acc.push({
              type: DispatcherFilterType.BRIGADE,
              value: dirtyValues.brigades
            });
            break;
          case 'roles':
            acc.push({
              type: DispatcherFilterType.ROLES,
              value: dirtyValues.roles
            });
            break;
        }
        return acc;
      }, []);
  }

  loadLists(): void {
    // this.dispSettingsApiService.getListOfBrigades()
    //   .subscribe(brigades => {
        const categories = this.globalDataService.globalCategories$.asObservable();
        const roles = this.globalDataService.globalRoles$.asObservable();
        const queueGroups = this.globalDataService.globalQueueStrategies$.asObservable();
        const brigades = this.globalDataService.globalBrigades$.asObservable();
        const lines = this.globalDataService.globalCallLines$.asObservable();
        combineLatest(categories, roles, queueGroups, brigades, lines)
          .pipe(
            map((data) => {
              return {
                categories: data[0],
                roles: data[1],
                queueGroups: data[2],
                brigades: data[3],
                lines: data[4]
              };
            })
          )
          // tslint:disable-next-line:no-shadowed-variable
          .subscribe( ({categories, roles, queueGroups, brigades, lines}) => {
            this.categories$.next(categories);
            this.roles$.next(roles);
            this.queueGroups$.next(queueGroups);
            this.brigades$.next(brigades);
            this.callLines$.next(lines);
            this.generageEditFilterForm(roles, brigades, queueGroups);
          });

        // this.categories$.next(categories);
        // this.roles$.next(roles);
        // this.brigades$.next(brigades);
        // this.queueGroups$.next(queueGroups);

      // });
  }

  openDispModal(disp?) {
    const config = new MatDialogConfig();
    config.data = {
      roles: this.roles$.value,
      categories: this.categories$.value,
      brigades: this.brigades$.value,
      queueGroups: this.globalDataService.globalTaxiServices$.value,
      callLines: this.callLines$.value,
    };
    if (disp) {
      config.data.user = disp;
    }
    config.panelClass = 'create-disp-modal-container';
    config.height = '100vh';
    config.width = '50vw';
    config.disableClose = true;
    return this.dialog.open(this.dialogComponent, config);
  }

  selectQueue(id): void {
    this.selectedQueueId$.next(id);
  }

  private generageEditFilterForm(roles, brigades, queueGroups): void {
    this.editFilterForm$.next(
      this.fb.group({
        roles: [roles.map(role => role.uuid)],
        brigades: [brigades],
        queueGroups: [queueGroups.map(group => group.uuid)],
        name: ['']
      })
    );
    this.editFilterForm$.value.updateValueAndValidity();
  }

  private getBodyToSave(): PutBodyDispCallSettings[] {
    const body = [];
    this.dispatcherdFormArray.controls.forEach((dispGroup: UntypedFormGroup) => {
      if (dispGroup.touched) {
        const dispValue = dispGroup.value;
        body.push({
          allow_calls_from_new_clients: dispValue.allow_calls_from_new_clients,
          allow_calls_from_passengers: dispValue.allow_calls_from_passengers,
          allow_calls_from_drivers: dispValue.allow_calls_from_drivers,
          user_uid: dispValue.uuid,
          queue_service_group_ids: (dispValue.services || []).filter(serv => serv.active).map(serv => serv.uuid)
        });
      }
    });
    return body;
  }
}
