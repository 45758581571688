import { YesNoModalComponent } from '../../shared/components';
import { HandleEventService, NotificationsService, StateService, TaxiServicesService } from '../../services';
import { OrderFormComponent } from 'src/app/order-form/containers';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {Order, OrderCallDispatcherStatusInterface, OrdersMap} from '../../interfaces/order.interface';
import {DispatcherFilters, ReqViewOrdLiteInterface} from '../models/dispatcher';
import { OrdersService } from 'src/app/services';
import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, of, Subject } from 'rxjs';
import * as _ from 'lodash';
import { filterFunctions } from '../utils/disp-util';
import {bufferTime, debounceTime, delay, filter, map, mergeMap, switchMap, take, tap} from 'rxjs/operators';
import { AssignCabModalComponent, RemoveOrderComponent } from 'src/app/shared/components';
import { OrderModalService } from 'src/app/order-form/services';
import { GeneralOrdersApiService } from 'src/app/services/general-orders-api.service';
import * as moment from 'moment';
import escapeStringRegexp from '../../utils/regexp';
import {environment} from '@global-environments/environment';
import {OrderClass} from '../../classes/order.class';

@Injectable({
  providedIn: 'root'
})
export class DispOrdersService {

  private updateBufferTime = 2000;
  private forceUpdateTimeout = 60000;

  private sortingOrder = ['callsign', 'clientPhone', 'address', 'driverPhone', 'number'];

  public filterLiteSearchStr$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  filters: DispatcherFilters;
  filtersOrdersLength$: BehaviorSubject<any> = new BehaviorSubject({});
  filteredOrders$ = new Subject();
  requiredViewOrdersLite$: BehaviorSubject<ReqViewOrdLiteInterface[]> = new BehaviorSubject([]);


  private workerUpdates$ = new Subject();
  private forceUpdates$ = new BehaviorSubject(true);

  private ordersMap: OrdersMap = new Map<string, OrderClass>();
  filteredOrders: OrderClass[] = [];
  deletedOrderId: string = null;

  selectedOrder: OrderClass;
  selectedOrder$: Subject<OrderClass> = new Subject();

  ordersByService$: Subject<object> = new Subject();

  public callWayOrderIds: string[] = [];
  public previousCallWayOrderIds: string[] = null;

  modalOpened = false;

  private dialogRef: MatDialogRef<OrderFormComponent | RemoveOrderComponent | AssignCabModalComponent | YesNoModalComponent>;
  private dialogConfig: MatDialogConfig = new MatDialogConfig();

  private scrollIndexSubject = new Subject<any>();
  scrollIndexObservable$ = this.scrollIndexSubject.asObservable();

  constructor(
    private ordersService: OrdersService,
    private notificationsService: NotificationsService,
    private stateService: StateService,
    private dialog: MatDialog,
    private handleEventService: HandleEventService,
    private taxiServicesService: TaxiServicesService,
    private orderModalService: OrderModalService,
    private generalOrdersApiService: GeneralOrdersApiService,
  ) {
  }

  setCallWayOrderIds(ids: string[]): void {
    const prevOrderIds = this.callWayOrderIds;
    this.callWayOrderIds = ids;

    for (const id of prevOrderIds) {
      const order = this.ordersMap.get(id);
      if (order && !this.filterOrder(order)) {
        this.ordersMap.delete(id);
      }
    }

    for (const id of ids) {
      const order = this.ordersService.currentOrders.get(id);
      if (order) {
        this.ordersMap.set(id, order);
      }
    }

    this.workerUpdates$.next('setCallWayOrderIds');
  }

  hideCallWaySelection(id: string): void {
    this.setCallWayOrderIds(this.callWayOrderIds.filter(item => item !== id));
  }

  clearCallWayOrders(): void {
    this.setCallWayOrderIds([]);
  }

  selectCallWayOrd(direction: 'up' | 'down'): void {
    if (this.callWayOrderIds.length > 0) {
      const nowSelectedIndex = this.callWayOrderIds.indexOf(this.selectedOrder?.id);
      let idOrderToSelect = '';
      if (direction === 'down') {
        if ((nowSelectedIndex + 1) >= this.callWayOrderIds.length) {
          // last selected
          idOrderToSelect = this.callWayOrderIds[0];
        } else {
          idOrderToSelect = this.callWayOrderIds[nowSelectedIndex + 1];
        }
      } else if ('up') {
        if (nowSelectedIndex === 0) {
          idOrderToSelect = this.callWayOrderIds[this.callWayOrderIds.length - 1];
        } else {
          idOrderToSelect = this.callWayOrderIds[nowSelectedIndex - 1];
        }
      }
      if (idOrderToSelect) {
        this.selectOrder(this.ordersService.currentOrders.get(idOrderToSelect), true);
      }
    }
  }

  private countOrdersByServices() {
    const ordersCountByService = {};

    for (const order of this.ordersService.currentOrders.values()) {
      if (order.status !== 'completed' && order.status !== 'canceled') {
        const serviceID = order.service_id;
        if (serviceID in ordersCountByService) {
          ordersCountByService[serviceID] += 1;
        } else {
          ordersCountByService[serviceID] = 1;
        }
      }
    }

    this.ordersByService$.next(ordersCountByService);
  }

  private countOrdersByFilters() {
    if (!this.filters) {
      return;
    }

    const filters = _.cloneDeep(this.filters);
    const orders = Array.from(this.ordersService.currentOrders.values())
      .filter(order => filterFunctions.taxiService(order, this.filters));

    for (const itemKey in filters.checkBoxes) {
      if (itemKey) {
        for (const filterKey in filters.checkBoxes[itemKey]) {
          if (filterKey) {
            filters.checkBoxes[itemKey][filterKey].status = false;
          }
        }
        for (const filterKey in filters.checkBoxes[itemKey]) {
          if (filterKey) {
            filters.checkBoxes[itemKey][filterKey].status = true;
            filters.checkBoxes[itemKey][filterKey].ordersLength = orders.filter((order: Order) => {
              return filterFunctions[itemKey](order, filters);
            }).length;
            filters.checkBoxes[itemKey][filterKey].status = false;
          }
        }
      }
    }

    this.filtersOrdersLength$.next(filters);
  }

  // TODO: disable service
  enableService() {
    this.ordersService.createSubscription();
    this.notificationsService.enableService();

    this.stateService.getStoreParamSub('user')
      .subscribe(user => {
        if (this.filters) {
          this.filters.additionalFilters.myOrders.userId = user.data.id;
        }
      });

    merge(
      this.workerUpdates$.pipe(
        map(type => `workerUpdates: ${type}`),
      ),
      this.forceUpdates$.pipe(
        switchMap(() => of('forcedUpdate').pipe(delay(this.forceUpdateTimeout))),
      ),
      this.ordersService.ordersInstantUpdates$.pipe(
        map(() => 'ordersInstantUpdates'),
      ),
      merge(
        this.ordersService.ordersUpdates$.pipe(map(order => order.id)),
        this.ordersService.ordersDeletions$.pipe(tap((id) => {
          if (environment.liteVersion && id) {
            this.requiredViewOrdersLite$.next(this.requiredViewOrdersLite$.value.filter(item => item.requestId !== id));
          }
        })),
      )
        .pipe(
          bufferTime(this.updateBufferTime),
          filter(ids => ids.length > 0),
          map(() => 'ordersUpdates'),
        ),
    )
      .pipe(
        debounceTime(100),
      )
      .subscribe(() => {
        this.forceUpdates$.next(true);
        this.countOrdersByServices();
        this.countOrdersByFilters();
        this.filteredOrders = this.postFilterOrders(this.ordersMap);
        this.filteredOrders$.next(true);
      });

    this.ordersService.currentOrdersUpdates$
      .subscribe(ordersMap => {
        this.ordersMap = this.filterOrdersMap(ordersMap);
        this.selectFirstOrder();
        this.workerUpdates$.next('currentOrdersUpdates');
      });

    this.ordersService.ordersUpdates$
      .subscribe((order: OrderClass) => {
        if (this.filterOrder(order)) {
          this.ordersMap.set(order.id, order);
          if (this.selectedOrder === null) {
            // this.selectOrder(order, true);
          } else if (order.id === this.selectedOrder?.id) {
            this.selectOrder(order);
          }
        } else {
          this.ordersMap.delete(order.id);
          if (order.id === this.selectedOrder?.id) {
            this.selectFirstOrder();
          }
        }
      });

    this.ordersService.ordersDeletions$
      .subscribe((id: string) => {
        this.ordersMap.delete(id);
        if (id === this.selectedOrder?.id) {
          this.deletedOrderId = id;

          this.selectFirstOrder(true);
        }
      });

    this.filteredOrders$
      .subscribe(() => {
        const indexes: { [key: string]: number } = {};

        this.filteredOrders.forEach((order, index) => {
          indexes[order.id] = index;
        });

        if (this.callWayOrderIds.length > 0) {
          this.callWayOrderIds.sort((a, b) => indexes[a] - indexes[b]);
        }
        if (this.selectedOrder == null && this.deletedOrderId === null && this.filteredOrders.length > 0 ||
          this.selectedOrder && (!Number.isInteger(indexes[this.selectedOrder?.id]) && this.selectedOrder?.id !== this.deletedOrderId) ||
          this.callWayOrderIds.length &&
          (!this.previousCallWayOrderIds ||
            this.previousCallWayOrderIds &&
            JSON.stringify(this.callWayOrderIds) !== JSON.stringify(this.previousCallWayOrderIds)) ) {
          this.selectFirstOrder();
        } else if (this.selectedOrder && this.deletedOrderId && this.selectedOrder.id === this.deletedOrderId) {
          this.selectedOrder = null;
          this.selectedOrder$.next(null);
        } else if (!this.selectedOrder && this.deletedOrderId) {
          this.deletedOrderId = null;
        }
        this.previousCallWayOrderIds = this.callWayOrderIds.length > 0 ? this.callWayOrderIds : null;
      });
  }

  private filterOrdersMap(ordersMap: OrdersMap): OrdersMap {
    const entries: [string, OrderClass][] = Array.from(ordersMap.entries()).filter(([, order]) => this.filterOrder(order));

    return new Map<string, OrderClass>(entries);
  }

  private filterOrder(order: Order): boolean {
    if (!this.filters) {
      return true;
    }

    if (this.callWayOrderIds.indexOf(order.id) !== -1) {
      return true;
    }

    const viewOrder = filterFunctions.taxiService(order, this.filters)
      && filterFunctions.input(order, this.filters)
      && filterFunctions.type(order, this.filters)
      && filterFunctions.payment_type(order, this.filters)
      && filterFunctions.trip_source(order, this.filters)
      && filterFunctions.self_order(order, this.filters)
      && filterFunctions.isMyOrder(order, this.filters);

    if (environment.liteVersion && !viewOrder) {
      return this.requiredViewOrdersLite$.value.some(item => item.requestId === order.id);
    } else {
      return viewOrder;
    }
  }

  private postFilterOrders(ordersMap: OrdersMap): OrderClass[] {
    const now = moment();

    return Array.from(ordersMap.values())
      .filter(order => {
        if (order.is_preorder && order.assigned_at_moment && order.status === 'processing') {
          const minutesToStart = order.assigned_at_moment.diff(now, 'minute');
          order.visible_preorder =
            minutesToStart < +this.taxiServicesService.servicesSettings[order.service_id].requests_preorder_sending &&
            minutesToStart > 0;
        } else {
          order.visible_preorder = false;
        }

        this.applySearchFilter(order);

        order.is_call_order = this.callWayOrderIds.indexOf(order.id) !== -1;

        return order.highlights.length > 0 || order.is_call_order;
      })
      .sort((a: Order, b: Order) => {
        let comparableA;
        let comparableB;

        if (a.is_call_order) {
          comparableA = `5-${a.created_at}`;
        } else if (a.is_holding) {
          comparableA = `4-${a.created_at}`;
        } else if (a.visible_preorder) {
          comparableA = `3-${a.assigned_at}`;
        } else if (!a.visible_preorder && a.is_preorder) {
          comparableA = `1-${a.assigned_at}`;
        } else {
          comparableA = `2-${a.created_at}`;
        }

        if (b.is_call_order) {
          comparableB = `5-${b.created_at}`;
        } else if (b.is_holding) {
          comparableB = `4-${b.created_at}`;
        } else if (b.visible_preorder) {
          comparableB = `3-${b.assigned_at}`;
        } else if (!b.visible_preorder && b.is_preorder) {
          comparableB = `1-${b.assigned_at}`;
        } else {
          comparableB = `2-${b.created_at}`;
        }

        return comparableB.localeCompare(comparableA);
      });
  }

  private applySearchFilter(order: Order): void {
    const searchText = this.filters.search.replace('+', '');
    if (searchText.length > 0) {
      const check = new RegExp(escapeStringRegexp(searchText), 'gi');
      order.highlights = [];
      if (order.cab !== null) {
        if (order.cab.driver.callsign && order.cab.driver.callsign === searchText) {
          order.highlights.push('callsign');
        }
      }
      if (searchText.length >= 4 && /^\d{4}/.test(searchText) && !environment.liteVersion) {
        if (check.test(order.cab?.driver.phone_number)) {
          order.highlights.push('driverPhone');
        }
        if (check.test(order.passenger.phone_number)) {
          order.highlights.push('clientPhone');
        }
        if (check.test(order?.receiver?.phone_number)) {
          order.highlights.push('receiverPhone');
        }
        if (check.test(order?.phone_number)) {
          order.highlights.push('contactPhone');
        }
      }
      if (searchText.length >= 10 && /^\d{10}/.test(searchText) && environment.liteVersion) {
        if (check.test(order.cab?.driver.phone_number)) {
          order.highlights.push('driverPhone');
        }
        if (check.test(order.passenger.phone_number)) {
          order.highlights.push('clientPhone');
        }
        if (check.test(order?.receiver?.phone_number)) {
          order.highlights.push('receiverPhone');
        }
        if (check.test(order?.phone_number)) {
          order.highlights.push('contactPhone');
        }
      }
      if (!this.filters.remoted && !environment.liteVersion) {
        order.waypoints.forEach(obj => {
          if (check.test(obj.name)) {
            order.highlights.push('address');
          }
        });
      }
      if (searchText.length >= 4 && environment.liteVersion) {
        order.waypoints.forEach(obj => {
          if (check.test(obj.name)) {
            order.highlights.push('address');
          }
        });
      }
      if (
        order.cab &&
        order.cab.vehicle &&
        order.cab.vehicle.number &&
        order.cab.vehicle.number.replace(/\D/g, '') === searchText
      ) {
        order.highlights.push('number');
      }
      order.highlights = order.highlights.sort((a, b) => {
        return this.sortingOrder.indexOf(a) - this.sortingOrder.indexOf(b);
      });
    } else {
      order.highlights = [''];
    }
  }

  selectFirstOrder(notScroll?: boolean) {
    if (this.filteredOrders.length > 0 && !notScroll) {
      this.selectOrder(this.filteredOrders[0], true);
    } else if (this.filteredOrders.length > 0 && notScroll) {
      this.selectOrder(this.filteredOrders[0]);
    } else {
      this.selectedOrder = null;
      this.selectedOrder$.next(null);
    }
  }

  selectOrder(order: OrderClass, scroll: boolean = false) {
    if (order) {
      this.selectedOrder = order;
      this.selectedOrder$.next(order);
      if (scroll) {
        this.scrollToOrder(order);
      }
    }
  }

  public scrollToOrder(order: Order, retry: boolean = true): void {
    const index = this.filteredOrders.findIndex(item => item.id === order.id);
    if (index !== -1) {
      this.scrollIndexSubject.next(index);
    } else if (retry) {
      this.filteredOrders$.pipe(take(1)).subscribe(() => this.scrollToOrder(order, false));
    }
  }

  setFilters(filters, serviceToggle?: boolean) {
    this.filters = filters.filters;

    if (this.stateService?.dumbStore?.user) {
      this.filters.additionalFilters.myOrders.userId = this.stateService.dumbStore.user.data.id;
    }

    this.ordersMap = this.filterOrdersMap(this.ordersService.currentOrders);
    this.workerUpdates$.next('setFilters');
  }

  editOrder(order?) {
    let editedOrder;
    if (order) {
      editedOrder = order;
    } else {
      editedOrder = _.cloneDeep(this.selectedOrder);
    }
    if (editedOrder) {
      this.orderModalService.editOrder(editedOrder);
    }
  }

  removeOrder() {
    if (this.selectedOrder) {
      this.ordersService.getRemoveOrderReason(this.selectedOrder.id).subscribe((res: any) => {
        const config = new MatDialogConfig();
        config.data = {
          reasons: res.data,
          id: this.selectedOrder.id
        };
        config.panelClass = 'remove-order-modal-container';
        config.width = '40vw';

        this.dialogRef = null;
        this.modalOpened = true;
        this.dialogRef = this.dialog.open(RemoveOrderComponent, config);
        this.dialogRef.afterClosed().subscribe(data => {
          if (data && data.id) {
            this.ordersService.removeOrderReq(data).subscribe(
              (response: any) => {
                this.ordersService.markDeleted(response.data.id);
                this.workerUpdates$.next('removeOrder');
                this.modalOpened = false;
                this.handleEventService.openSnackBar('ORDER_CANCELED_SUCCESSFULLY', 3);
              },
              err => {
                this.modalOpened = false;
                this.handleEventService.openSnackBar(err.message, 3);
              }
            );
          }
          this.modalOpened = false;
          this.dialogRef = null;
        });
      });
    }
  }

  assignCab() {
    if (this.selectedOrder) {
      const config = new MatDialogConfig();
      config.data = {
        orderId: this.selectedOrder.id,
        orderServiceId: this.selectedOrder.service_id,
        services: this.stateService.dumbStore.service
      };
      config.panelClass = 'assign-cab-modal-container';

      this.dialogRef = this.dialog.open(AssignCabModalComponent, config);
      this.dialogRef
        .afterClosed()
        .pipe(
          mergeMap(data => {
            return this.generalOrdersApiService.assignCab(data);
          })
        )
        .subscribe({
          next: val => {
            this.handleEventService.openSnackBar('CAB_ASSIGNED');
            this.dialogRef = null;
          },
          error: err => {
            if (err.error?.code === 'not_enough_balance') {
              this.handleEventService.openSnackBar('UTAX_FRONT_NOT_ENOUGH_BALANCE_OF_CAB');
            }
            if (err.error?.code === 'request_already_reserved') {
              this.handleEventService.openSnackBar('UTAX_FRONT_ORDER_ALREADY_RESERVED');
            }
            if (err.error?.code === 'working_shift_is_not_active') {
              this.handleEventService.openSnackBar('UTAX_FRONT_CAB_SHIFT_IS_NOT_ACTIVE');
            }
            this.dialogRef = null;
          }
        });
    }
  }

  holdUnholdOrder() {
    if (this.selectedOrder) {
      let isHoldString = '';
      this.selectedOrder.is_holding ? (isHoldString = 'unhold') : (isHoldString = 'hold');
      this.ordersService.holdUnholdOrder(this.selectedOrder.id, isHoldString).subscribe({
        next: res => {
          this.selectedOrder.is_holding = !this.selectedOrder.is_holding;
          this.ordersService.updateOrders([this.selectedOrder], true);
          this.selectOrder(this.selectedOrder);
        },
        error: err => {
          console.error('holdUnholdOrder despatcher error', err);
        }
      });
    }
  }

  completeOrder() {
    this.dialogConfig.data = {
      title: 'UTAX_FRONT_DO_YOU_REALY_WANT_TO_COMPLETE_ORDER'
    };
    this.dialogConfig.panelClass = 'yes-no-modal-container';
    this.dialogRef = this.dialog.open(YesNoModalComponent, this.dialogConfig);
    this.dialogRef
      .afterClosed()
      .pipe(
        mergeMap(data => {
          if (data === 'YES') {
            return this.ordersService.tripComplete(this.selectedOrder.trip_id);
          } else {
            return of();
          }
        })
      )
      .subscribe((res: any) => {
        if (res && res.data) {
          this.ordersService.markDeleted(res.data.id);
          this.workerUpdates$.next('completeOrder');
        }
        this.dialogConfig = {};
        this.dialogRef = null;
      });
  }

  unassignCab() {
    this.ordersService.unassignCab(this.selectedOrder.trip_id, this.selectedOrder.cab.callsign);
  }

}
