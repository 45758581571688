import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Injectable} from '@angular/core';
import {DriverService} from './driver.service';
import {BirthDayValidator} from '../../shared/utils/birthday.validator';
import {HandleEventService} from '../../services';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class DriverDialogService {
  private driversVehiclesLengthSource = new BehaviorSubject<number>(0);
  driversVehiclesLength = this.driversVehiclesLengthSource.asObservable();
  private carSaveEventSource = new BehaviorSubject<boolean>(false);
  carSaveEvent = this.carSaveEventSource.asObservable();
  private saveVehicleSource = new BehaviorSubject<number>(0);
  saveVehicle = this.saveVehicleSource.asObservable();

  constructor(
    private driverService: DriverService,
    private fb: UntypedFormBuilder,
    private handleEventService: HandleEventService
  ) {
  }


  convertCarFormToVehicle(carInfo: any, carOrgDri: any, oldCarNumber: string): any {

    const el = {
      ...carInfo,
      ...carOrgDri,
      country_id: carInfo.country_id,
      brand_id: carInfo.brand_id,
      model_id: carInfo.model_id,
      color_id: carInfo.color_id,
      body_type_id: carInfo.body_type_id,
    };
    if (!el.is_electric) {
      delete el.electric_battery_capacity;
      delete el.electric_tariff;
    }
    return el;
  }

  emitDriverWithNotification(isDriverEditing: boolean, driver: any) {
    if (isDriverEditing) {
      this.driverService.emitEditingDriver(driver['data']);
      this.handleServiceEvent('UTAX_DRIVER_CHANGED_SUCCESS');
    } else {
      this.driverService.emitCreatedNewUser(driver['data']);
      this.handleServiceEvent('UTAX_DRIVER_CREATED_SUCCESS');
    }
  }

  getCarInfoGroup(data, service: number, permisions: string[], isActivePerm: {name: string, display_name: string}) {
    if (data) {
      const carBodyType: any = data.body_type
        ? data.body_type.id
        : '';

      const carColor = data.color
        ? data.color.id
        : '';

      const carCRCountry = data.country
        ? data.country.id
        : '';

      return this.fb.group({
        gps_device_id: [
          { value: data.gps_device_id, disabled: this.isGpsDisabled(data.gps_device_id, permisions) }
        ],
        branding_type_id: [data.branding_type?.id || ''],
        branding_period: [data.branding_period || null],
        brand_id: [data.brand.id, Validators.required],
        model_id: [data.model.id, Validators.required],
        max_pass: [data.max_pass, Validators.required],
        body_type_id: [carBodyType, Validators.required],
        color_id: [carColor, Validators.required],
        country_id: [carCRCountry, Validators.required],
        number: [data.number, [Validators.required, Validators.maxLength(10), Validators.minLength(1), Validators.pattern('[A-Za-zА-Яа-яіІїЇєЄ0-9]*')]],
        year: [
          data.year,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(4),
            Validators.min(1950),
            Validators.max(new Date().getFullYear())
          ]
        ],
        service_id: [service, Validators.required],
        vin: [data?.vin],
        driver_ids: [data.drivers ? data.drivers.map(el => el.id) : []],
        vehicle_group_ids: [data.vehicle_groups ? data.vehicle_groups.map(el => el.id)[0] : ''],
        vehicle_class_ids: [data.vehicle_classes ? data.vehicle_classes.map(el => el.id) : []],
        id: [data.id],
        add_info: [data?.add_info],
        is_electric: [Boolean(data?.is_electric) || false],
        electric_tariff: [data?.electric_tariff, data?.is_electric ? [Validators.required] : []],
        electric_battery_capacity: [data?.electric_battery_capacity, data?.is_electric ? [Validators.required] : []],
        salary_percent: [data?.salary_percent],
        amortization_value: [data?.amortization_value],
      });
    } else {
      return this.fb.group({
        gps_device_id: [''],
        branding_type_id: [''],
        branding_period: [null],
        brand_id: ['', Validators.required],
        model_id: ['', Validators.required],
        max_pass: ['', Validators.required],
        body_type_id: ['', Validators.required],
        color_id: ['', Validators.required],
        country_id: ['', Validators.required],
        number: [null,  [Validators.required, Validators.maxLength(10), Validators.minLength(1), Validators.pattern('[A-Za-zА-Яа-яіІїЇєЄ0-9]*')]],
        year: [
          null,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(4),
            Validators.min(1950),
            Validators.max(new Date().getFullYear())
          ]
        ],
        service_id: [service, Validators.required],
        vin: [null],
        driver_ids: [[]],
        vehicle_group_ids: [''],
        vehicle_class_ids: [[]],
        id: [null],
        add_info: [null],
        is_electric: [Boolean(data?.is_electric) || false],
        electric_tariff: [data?.electric_tariff, data?.is_electric ? [Validators.required] : []],
        electric_battery_capacity: [data?.electric_battery_capacity, data?.is_electric ? [Validators.required] : []],
        salary_percent: [null],
        amortization_value: [0],
      });
    }
  }

  private isGpsDisabled(value: string, permisions: string[]): boolean {
    if (!value && permisions.some(item => item === 'operator_management_gps_device_id_create')) {
      return false;
    }
    if (permisions.some(item => item === 'operator_management_gps_device_id_update')) {
      return false;
    }
    return true;
  }

  public generateDriverForm() {
    return this.fb.group({
      last_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
      first_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
      middle_name: ['', [Validators.maxLength(30)]], // ?
      is_deaf: [false],
      tariff_plan_id: ['', [Validators.required]],
      callsign: [
        '',
        [Validators.minLength(1), Validators.maxLength(6), Validators.pattern('[0-9]+'), Validators.required]
      ],
      birth_date: ['', [Validators.required, BirthDayValidator.regExp, BirthDayValidator.birthVal]],
      tax_number: ['', [Validators.pattern('^[0-9]{10}$')]],
      sex: ['male', [Validators.required]],
      address: ['', Validators.maxLength(100)],
      add_info: ['', Validators.maxLength(300)],
      phone_numbers: this.fb.array([this.buildPhones()]),
      vehicle_ids: this.fb.array([]),
      work_time: ['', Validators.maxLength(50)],
      is_merchant_enabled: [false],
      personal_trips_enabled: [false],
      personal_trips_distance_limit: [null],
      is_electric: [false],
      electric_tariff: [null],
      electric_battery_capacity: [null]
    });
  }

  buildPhones(item?: any): UntypedFormGroup {
    return this.fb.group({
      phone: [
        item,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern('[0-9]{3}[- ]?[0-9]{3}[- ]?[0-9]{2}[- ]?[0-9]{2}')
        ]
      ]
    });
  }

  private handleServiceEvent(msg) {
    this.handleEventService.openSnackBar(msg);
  }

  emittDriversVehicleslength(length: number) {
    return this.driversVehiclesLengthSource.next(length);
  }

}
