import { Pipe, PipeTransform } from '@angular/core';
import { secondsToMinutesAndSeconds } from 'src/app/utils/time-transforms';

@Pipe({
  name: 'getRestInfo'
})
export class GetRestInfoPipe implements PipeTransform {

  transform(restType: any, shift: any, allRests): string {
    let times = 0;
    let seconds = 0;

    allRests.forEach(rest => {
      if (rest.wshId === shift.id) {
        if (rest.typeId === restType.id) {
          console.log('PC: GetRestInfoPipe -> transform -> rest', rest);
          times++;
          if (rest.totalDuration) {
            seconds += rest.totalDuration;
          }
        }
      }
    });

    return `${secondsToMinutesAndSeconds(seconds)} мин (${times})`;
  }

}
