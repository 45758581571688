import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dist'
})
export class DistancePipe implements PipeTransform {
  transform(item: number, type?: string) {
    if (item === 0) {
      return '0.01';
    }
    if (type === 'km') {
      return (item / 1000).toFixed(2);
    } else {
      if (item) {
        return item.toFixed(2);
      }
    }
  }
}
