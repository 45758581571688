import { OrderFormService } from './../../services/order-form.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'utax-order-form-map-info-panel',
  templateUrl: './order-form-map-info-panel.component.html',
  styleUrls: ['./order-form-map-info-panel.component.scss']
})
export class OrderFormMapInfoPanelComponent implements OnInit {

  constructor(public orderFormService: OrderFormService) { }

  ngOnInit() {
  }

}
