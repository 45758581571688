import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UsersService } from '../../services';
import { Role, User } from 'src/app/users/models/user.interface';
import { Service } from 'src/app/interfaces/order.interface';
import * as moment from 'moment';

@Component({
  selector: 'utax-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  @Input() selectedUser: User;
  roles: Role[] = [];
  userRolesValue: string[] = [];
  users: User[] = [];
  filteredUsers: User[];
  services: Service[] = [];
  userServicesValue: number[] = [];
  userTypes = [{ value: 'admin' }, { value: 'operator' }];
  userTypesValue = ['admin', 'operator'];
  searchFieldValue = '';
  displayedColumns: string[] = ['name', 'created_at', 'type', 'roles', 'services']; // 'roles', 'services'

  @Output() userSelected: EventEmitter<User> = new EventEmitter();
  @Output() editUser: EventEmitter<User> = new EventEmitter();
  constructor(private usersService: UsersService) {}

  @Input('servicesInp')
  set servicesInput(services: Service[]) {
    this.services = services;
    if (services) {
      // this.userServicesValue = services.map(service => service.id);
    }
  }

  @Input('rolesInp')
  set rolesInput(roles: Role[]) {
    this.roles = roles;
    if (roles) {
      // this.userRolesValue = this.roles.map(role => role.id);
    }
  }

  @Input('usersInp')
  set usersInput(users: User[]) {
    this.users = users;
    if (users) {
      this.filterUsers();
    }
  }

  ngOnInit(): void {}

  sortData(event: { active: string; direction: string }): void {
    switch (event.active) {
      case 'name':
        if (event.direction === 'asc') {
          this.users = [...this.users.sort((a, b) => a.name.localeCompare(b.name))];
        } else {
          this.users = [...this.users.sort((a, b) => b.name.localeCompare(a.name))];
        }
        break;

      case 'created_at':
        if (event.direction === 'asc') {
          this.users = [...this.users.sort((a, b) => moment(b.created_at).diff(a.created_at))];
        } else {
          this.users = [...this.users.sort((a, b) => moment(a.created_at).diff(b.created_at))];
        }
        break;

      default:
        break;
    }

    this.filterUsers();
  }

  filterUsers(users?: any): void {
    this.filteredUsers = [
      ...this.users
        .filter((user: User) => this.userTypesValue.some(type => user.type === type)) // type filtering
        .filter(this.roleFilterFunc)
        .filter(this.serviceFilterFunc)
        .filter(this.searchFilterFunc)
    ];

    console.log(this.filteredUsers.length);
    console.log('length==============');
    console.log('length==============');
    console.log('length==============');
  }

  search(event): void {
    this.searchFieldValue = event;
    this.filterUsers();
  }

  selectUser(user: User): void {
    this.userSelected.emit(user);
  }

  doubleClick(user: User): void {
    this.editUser.emit(user);
  }

  private userTypeFilterFunc = (user): boolean => {
    // (user: User) => this.userTypesValue.some(type => user.type === type)
    return true;
  }

  private searchFilterFunc = (user): boolean => {
    if (this.searchFieldValue === '') {
      return true;
    } else {
      return user.name && user.name.toLowerCase().includes(this.searchFieldValue.toLowerCase());
    }
  };

  private serviceFilterFunc = (user: User): boolean => {
    let haveService = false;
    if (this.userServicesValue.length) {
      user.services.forEach(service => {
        if (this.userServicesValue.some(serviceId => serviceId === service)) {
          haveService = true;
        }
      });
    } else {
      return true;
    }
    return haveService;
  };

  private roleFilterFunc = (user: User): boolean => {
    // role filtering
    let haveRole = false;
    if (this.userRolesValue.length) {
      user.roles.forEach(role => {
        if (this.userRolesValue.some(roleVal => roleVal === role)) {
          haveRole = true;
        }
      });
    } else {
      haveRole = true
    }
    return haveRole;
  };
}
