import { LogistOrdersService } from 'src/app/logist/services/logist-orders.service';
import { Pipe, PipeTransform } from '@angular/core';
import { calcDist } from '../utils/dist-utils';

@Pipe({
  name: 'getDist'
})
export class GetDistPipe implements PipeTransform {
  constructor(private logistOrdersService: LogistOrdersService) {}

  transform(cab: any): any {
    let currentOrder;
    if (cab.trip) {
      currentOrder = this.logistOrdersService.ordersList.find(
        ord => ord.trip_id === cab.trip.id
      );
    }
    if (currentOrder) {
      return (
        calcDist(
          cab.location,
          currentOrder.waypoints.find(point => point.type === 'end')
        )
          .toString()
          .slice(0, 5) + ' km'
      );
    } else {
      return 'CANT_CALC_DIST';
    }
  }
}
