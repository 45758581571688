import { TelephonyButtonsComponent } from './telephony-buttons/telephony-buttons.component';
import { TelephonyMenuButtonComponent } from './telephony-menu-button/telephony-menu-button.component';
import { TelephonyPauseButtonComponent } from './telephony-pause-button/telephony-pause-button.component';
import { TelephonySettingsModalComponent } from './telephony-settings-modal/telephony-settings-modal.component';
import { TelephonySyncButtonComponent } from './telephony-sync-button/telephony-sync-button.component';

export const componenets = [
  TelephonySettingsModalComponent,
  TelephonyButtonsComponent,
  TelephonySyncButtonComponent,
  TelephonyMenuButtonComponent,
  TelephonyPauseButtonComponent
];

export * from './telephony-pause-button/telephony-pause-button.component';
export * from './telephony-sync-button/telephony-sync-button.component';
export * from './telephony-menu-button/telephony-menu-button.component';
export * from './telephony-buttons/telephony-buttons.component';
export * from './telephony-settings-modal/telephony-settings-modal.component';
