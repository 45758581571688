import { Pipe, PipeTransform } from '@angular/core';
import { RequestHistoryModel } from '../models/requests-history.model';

@Pipe({
  name: 'wshReqConfirmedType'
})
export class WshReqConfirmedTypePipe implements PipeTransform {
  transform(record: RequestHistoryModel): any {
    if (record.vehicle_washing) {
      return record.vehicle_washing;
    } else if (record.vehicle_refill) {
      return record.vehicle_refill;
    } else if (record.driver_rest) {
      return record.driver_rest;
    }
    return null;
  }
}
