import { StateService } from 'src/app/services';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTaxiServiceName'
})
export class GetTaxiServiceNamePipe implements PipeTransform {

  constructor(private stateService: StateService) {}

  transform(value: any): any {
    const service = this.stateService.dumbStore.service.find(serv => serv.id === value);
    return service && service.name;
  }

}
