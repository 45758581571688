import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { CanActivate } from '@angular/router';
import { map } from 'rxjs/operators';
import { StateService } from '../services/state.service';
import { AuthService } from '../auth/services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private stateService: StateService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.authState.pipe(
      map((authState: boolean) => {
        if (authState) {
          this.authService.onAuth = () => this.router.navigate([state.url]);
        } else {
          this.router.navigate(['/login']);
        }
        return authState;
      })
    );
  }
}
