import { OrderFormService } from './../../services/order-form.service';
import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import * as L from 'leaflet';
import { Map } from 'leaflet';
import { DriverTrackingService, StateService } from 'src/app/services';
import { decode } from 'src/app/map/utils/map';
import { Router } from '@angular/router';
import {debounceTime, distinctUntilChanged, takeUntil, tap} from 'rxjs/operators';
import { CallWayService } from 'src/app/dispatcher/services';
import * as moment from 'moment';
import {OperatorCommentApiService} from '../../../shared/services/operator-comment-api.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {OperatorCommentService} from '../../../shared/services/operator-comment.service';
import {ApiOrderFormService} from "../../services";

@UntilDestroy()
@Component({
  selector: 'utax-order-form-map',
  templateUrl: './order-form-map.component.html',
  styleUrls: ['./order-form-map.component.scss']
})
export class OrderFormMapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() order;
  @Input() orderId: string;
  @Input() $service: Observable<any>;
  onDashboardPage: boolean;
  orderUpdated$ = new BehaviorSubject(null);
  poly = '';
  driverTrack = '';
  cab;
  public expandedCommentPanel = false;
  public permissionOperatorComment: boolean;
  private componentDestroyed$ = new Subject();

  constructor(
    public orderFormService: OrderFormService,
    public orderFormApiService: ApiOrderFormService,
    public stateService: StateService,
    private driverTrackingService: DriverTrackingService,
    private router: Router,
    public callWayService: CallWayService,
    private operatorCommentApiService: OperatorCommentApiService,
    public operatorCommentService: OperatorCommentService
  ) {
    this.stateService.getStoreParamSub('permissions')
      .pipe(
        tap((permissions) => this.permissionOperatorComment = permissions.some(p => p.name === 'operator_request_discuss')),
        untilDestroyed(this)
      )
      .subscribe();
  }

  ngOnInit() {
    this.getCommentList();
    if (this.orderFormService.order && this.orderFormService.order.status !== 'processing') {
      let startTime;
      if (
        this.orderFormService.order.is_preorder &&
        !moment.utc(this.orderFormService.order.assigned_at).isBefore()
        // (this.orderFormService.order.status === 'accepted' || this.orderFormService.order.status === 'waiting')
      ) {
        startTime = this.orderFormService.order.updated_at;
      } else {
        startTime = this.orderFormService.order.assigned_at;
      }
      this.driverTrackingService
        .getCabGeoInfo(this.orderFormService.order.cab, startTime)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(res => {
          this.cab = res;
          this.driverTrack = res && res.polyline ? res.polyline : null;
        });
    }

    this.router.events.pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.router.url === '/dashboard' ? (this.onDashboardPage = true) : (this.onDashboardPage = false);
    });
  }

  ngOnChanges() {
    this.orderUpdated$.next(this.order);
  }

  ngOnDestroy(): void {
    this.orderFormApiService.fullPassenger = null;
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  scale = (map: Map) => {
    merge(of(+localStorage.getItem('service')), this.$service)
      .pipe(takeUntil(this.componentDestroyed$), distinctUntilChanged())
      .subscribe(service => {
        const serv = this.stateService.dumbStore.service.find(s => s.id === service);
        if (serv && !this.order.id && serv.city.boundary) {
          this.poly = serv.city.boundary.polyline;
          const bounds = L.latLngBounds(decode(this.poly));
          if (bounds.isValid()) {
            map.fitBounds(bounds);
          }
        }
      });
    this.orderUpdated$.pipe(takeUntil(this.componentDestroyed$), debounceTime(400)).subscribe(() => {
      let bounds;
      if (this.order.waypoints && this.order.fare) {
        bounds = L.latLngBounds([...this.order.waypoints, L.latLngBounds(decode(this.order.fare.route.polyline))]);
      } else {
        bounds = L.latLngBounds(this.order.waypoints);
      }
      if (bounds.isValid()) {
        map.fitBounds(bounds);
      }
    });
  };


  private getCommentList(): void {
    if (this.permissionOperatorComment && this.orderId) {
      this.operatorCommentApiService.getOperatorComments(this.order.id, this.order.service_id)
        .pipe(
          tap((res) => {
            this.operatorCommentService.currentOrderComments$.next(res.data);
          }),
          untilDestroyed(this)
        )
        .subscribe();
    }
  }
}
