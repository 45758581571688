import { CookieService } from 'ngx-cookie-service';

let oldToken;

export default function getToken(cookieService: CookieService, bearer = false) {
  let token;
  if (location.hostname === 'localhost' || location.hostname.startsWith('192.168')) {
    token = localStorage.getItem('token');
  } else {
    token = cookieService.get('token');
  }
  if (token && oldToken && token !== oldToken) {
    location.reload();
  }
  oldToken = token;
  if (bearer) {
    return token ? `Bearer ${token}` : '';
  } else {
    return token || '';
  }
}
