import { Pipe, PipeTransform } from '@angular/core';
import { Product } from 'src/app/interfaces/order.interface';

@Pipe({
  name: 'filterProducts'
})
export class FilterProductsPipe implements PipeTransform {
  transform(pruducts: Product[], serviceId): Product[] {
    return pruducts
      .filter((product: Product) => {
        return product.service_id === serviceId;
      })
      .sort((a, b) => {
        return a.display_order - b.display_order;
      });
  }
}
