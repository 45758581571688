import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  OperatorAccountAccountStatisticModelNEW,
  OperatorAccountCallStatisticModel,
  OperatorAccountPeriod,
  OperatorStatusAndDuration
} from '../models';
import {TariffOperatorInfoInterface} from "../models/dispatcher.models";

@Injectable()
export class OperatorApiService {

  constructor(private httpClient: HttpClient) { }

  // Operator Account API
  // GET

  getAccountStatisticNew(period: OperatorAccountPeriod): Observable<OperatorAccountAccountStatisticModelNEW> {
    const params = new HttpParams().set('periodType', period);
    return this.httpClient.get<OperatorAccountAccountStatisticModelNEW>('call-centre/api/me/statistic/summary', { params });
  }

  getAccountCallsStatistics(dateFrom: number, dateTo: number): Observable<OperatorAccountCallStatisticModel[]> {
    const params = new HttpParams()
      .append('dateFrom', dateFrom.toString())
      .append('dateTo', dateTo.toString());
    return this.httpClient.get<OperatorAccountCallStatisticModel[]>('call-centre/api/operators/account/calls-statistics', { params });
  }

  getOperatorTariffInfo(): Observable<TariffOperatorInfoInterface> {
    return this.httpClient.get<TariffOperatorInfoInterface>('call-centre/api/me/cabinet-view');
  }

  getAccountWorkStatisticNew(period: OperatorAccountPeriod): Observable<OperatorStatusAndDuration[]> {
    const params = new HttpParams().set('periodType', period);
    return this.httpClient.get<OperatorStatusAndDuration[]>('call-centre/api/me/statistic/statuses/duration', { params });
  }
}
