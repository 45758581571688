import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';
import { TimezoneService } from '../../../services';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'utax-status-timer',
  template: '<p><b>{{ minutes }} {{ "MIN" | translate }}</b> {{ status | translate }}</p>',
  styleUrls: ['status-timer.component.scss']
})
export class StatusTimerComponent implements OnChanges {
  @Input() cab;
  @Input() shift;
  @Input() order;
  @Input() changeIndicator;

  minutes = 0;
  status;

  constructor(private tzService: TimezoneService) {}

  ngOnChanges(changes: SimpleChanges) {
    const order: any = this.getOrder(changes);
    const cabTrip = this.cab.trips.length === 0 ? this.cab.trip : this.cab.trips[0];

    if (cabTrip && (cabTrip.completed_at || cabTrip.canceled_at)) {
      let shiftStartDiff = 0;
      if (this.shift && this.shift.startedAt) {
        shiftStartDiff = moment().diff(moment.unix(this.shift.startedAt.seconds), 'minutes');
      }
      // if trip completed or canceled
      if (cabTrip.completed_at) {
        // completed
        const completedAt = this.tzService.utcToTz(cabTrip.completed_at);
        this.minutes = moment().diff(completedAt, 'minutes');
      } else {
        // canceled
        const canceledAt = this.tzService.utcToTz(cabTrip.canceled_at);
        this.minutes = moment().diff(canceledAt, 'minutes');
      }
      if (shiftStartDiff < this.minutes) {
        this.minutes = shiftStartDiff;
      }
      this.status = 'CAB_FREE';
    } else if (
      cabTrip &&
      (cabTrip.status === 'accepted' || cabTrip.status === 'arriving') &&
      cabTrip.accepted_at &&
      !(order && (order.status === 'waiting' || order.status === 'in_progress'))
    ) {
      // if driver accept or arrive to client (info from cab)
      const acceptedAt = this.tzService.utcToTz(cabTrip.accepted_at);
      this.minutes = moment().diff(acceptedAt, 'minutes');
      this.status = 'ORDER_ACCEPTED';
    } else if (order && order.status === 'waiting' && order.trip.arrived_at) {
      const arrivedAt = this.tzService.utcToTz(order.trip.arrived_at);
      this.minutes = moment().diff(arrivedAt, 'minutes');
      this.status = 'WAITING';
    } else if (order && order.status === 'in_progress' && order.trip.picked_up_at) {
      const pickedUpAt = this.tzService.utcToTz(order.trip.picked_up_at);
      this.minutes = moment().diff(pickedUpAt, 'minutes');
      this.status = 'IN_PROGRESS';
    } else if (order && (order.status === 'accepted' || order.status === 'arriving')) {
    }
  }

  private getOrder(changes: SimpleChanges): any {
    if (changes.order && changes.order.currentValue && changes.order.previousValue) {
      const currentValue = changes.order.currentValue;
      const previousValue = changes.order.previousValue;
      if (currentValue.status !== 'accepted' || currentValue.id === previousValue.id) {
        return currentValue;
      } else {
        return previousValue;
      }
    } else {
      return this.order;
    }
  }
}
