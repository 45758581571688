import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'utax-call-centre',
  templateUrl: './call-centre.component.html',
  styleUrls: ['./call-centre.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallCentreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
