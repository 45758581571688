import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import {
  Bundle,
  CustomLanguage,
  CustomTranslationService,
  NotCreatedTranslationItem,
  TranslationItem,
  TranslationObj
} from 'src/app/shared/services';

@Component({
  selector: 'utax-custom-translation-modal',
  templateUrl: './custom-translation-modal.component.html',
  styleUrls: ['./custom-translation-modal.component.scss']
})
export class CustomTranslationModalComponent implements OnInit {
  form: UntypedFormGroup;

  translationObj: TranslationObj;
  listOfBundles: Bundle[] = [];

  editing = false;

  private messages = '';
  private translate: any;

  constructor(
    translate: TranslateService,
    public dialogRef: MatDialogRef<CustomTranslationModalComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      fieldType: 'mat-input' | 'mat-textarea';
      title: string;
      value: string;
      fromSettings?: { label: string; rules: string };
      bundleNames?: string[];
      notLockedValue?: string;
    },
    private fb: UntypedFormBuilder,
    private customTranslationService: CustomTranslationService
  ) {
    this.customTranslationService.getListOfBundles().subscribe(bundles => {
      this.filterBundles(bundles, this.data.bundleNames, this.data.fromSettings);
      this.createForm();
    });
    this.translate = translate;
  }

  get translationFormArray(): UntypedFormArray {
    return this.form.controls.translations as UntypedFormArray;
  }

  ngOnInit() {}

  save(): void {
    if (this.editing) {
      // update translations
      this.updateTranslationsReq().subscribe((results: any[]) => {
        this.updateLocalTranslationObj(results);
        this.customTranslationService.updateTranslations(this.translationObj);
        this.dialogRef.close(this.translationObj);
      });
    } else {
      // create translations
      this.createTranslationReq().subscribe((saveResult: TranslationObj) => {
        this.customTranslationService.translations.push(saveResult);
        this.dialogRef.close(saveResult);
      });
    }
  }

  private filterBundles(bundles, bundleFilterNames: string[], fromSettings: { label: string; rules: string }) {
    let bundleNames = [];

    if (fromSettings && fromSettings.rules) {
      const rules: string[] = fromSettings.rules.split('|');
      rules.forEach(rule => {
        const splitedRule = rule.split(':');
        if (splitedRule[0] === 'loc_bundle_type') {
          bundleNames = splitedRule[1].split(',');
        }
      });
    } else {
      bundleNames = bundleFilterNames;
    }

    if (bundleNames && bundleNames.length > 0) {
      this.listOfBundles = bundles.filter(bundle => {
        return this.hasBundleByName(bundleNames, bundle.name);
      });
    } else {
      this.listOfBundles = bundles;
    }
  }

  private hasBundleByName(bundleNames: string[], bundleName: string): boolean {
    return bundleNames.some(bName => bName === bundleName);
  }

  private createTranslationReq(): Observable<any> {
    const values: NotCreatedTranslationItem[] = this.translationFormArray.value.map(itemValue => {
      return {
        bundle_ids: this.form.value.bundles,
        locale_id: itemValue.locId,
        value: itemValue.value
      };
    });

    return this.customTranslationService.postTranslation(values);
  }

  private updateTranslationsReq(): Observable<Array<{ id: string; value: string; bundle_ids: string[] }>> {
    const reqs: Array<Observable<{
      id: string;
      value: string;
      bundle_ids: string[];
    }>> = this.translationFormArray.value.map(itemValue => {
      return this.customTranslationService.putTranslation({
        id: itemValue.id,
        value: itemValue.value,
        bundle_ids: this.form.value.bundles
      });
    });

    return forkJoin(reqs);
  }

  private createForm(): void {
    this.form = this.fb.group({
      translations: this.fb.array([]),
      bundles: [this.listOfBundles.map(bund => bund.id), [Validators.required]]
    });

    if (this.data.value) {
      this.translationObj = this.customTranslationService.translations.find(
        transObj => transObj.key === this.data.value
      );

      if (this.translationObj) {
        this.form.controls.bundles.setValue(this.translationObj.translations[0].bundle_ids);
      }
    }

    this.customTranslationService.langs.forEach((lang: CustomLanguage) => {
      const value: { id: string; value: string } = this.getValueByLangOfTransObj(lang.id);
      if (value.id) {
        this.editing = true;
      }
      this.translationFormArray.push(
        this.fb.group({
          lang: [lang.name],
          value: [value.value, [Validators.required]],
          locId: [lang.id],
          id: [value.id]
        })
      );
    });
  }

  private updateLocalTranslationObj(newTransValues): void {
    this.translationObj.translations = this.translationObj.translations.map((transItem: TranslationItem) => {
      return {
        ...transItem,
        value: newTransValues.find(newVal => newVal.id === transItem.id).value,
        bundle_ids: newTransValues[0].bundle_ids
      };
    });
  }

  private getValueByLangOfTransObj(langId: string): { id: string; value: string } {
    let value = { id: '', value: this.data.notLockedValue ? this.data.notLockedValue : '' };
    let translateItem: TranslationItem;

    if (this.translationObj) {
      translateItem = this.translationObj.translations.find(trans => trans.locale_id === langId);
      if (translateItem) {
        value = {
          value: translateItem.value,
          id: translateItem.id
        };
      }
    }

    return value;
  }
}
