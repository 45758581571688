import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'processStatus'
})
export class ProcessStatusPipe implements PipeTransform {
  transform(statuses: any): boolean {
    // isPaused
    return Object.values(statuses).some(
      (service: { status: string }) => service.status !== 'success-unpause' // && service.status !== 'success-enter'
    );
  }
}
