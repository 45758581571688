import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class WaypointsService {

  constructor() { }

  sortWaypoints(waypoints) {
    if (waypoints) {
      return waypoints.sort((a, b) => a.ord - b.ord);
    }
  }

}
