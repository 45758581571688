import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

const { version } = require('../../../package.json');

export class TranslateLSLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  public getTranslation(lang: string): Observable<any> {
    const translations = localStorage.getItem(`${lang}---${version}`);

    if (translations) {
      return of(JSON.parse(translations));
    } else {
      return this.http.get(`ui/translations?lang=${lang}`).pipe(
        tap(translation => {
          // find old translations and remove them
          for (const key of Object.keys(localStorage)) {
            if (key.split('---')[0] === lang) {
              localStorage.removeItem(key);
            }
          }
          // setting new translations
          localStorage.setItem(`${lang}---${version}`, JSON.stringify(translation));
        })
      );
    }
  }
}
