import { LogistOrdersService } from './logist-orders.service';
import { LogistCabsService } from './logist-cabs.service';
import { OrderApiService } from './order-api.service';
import { AmeliaService } from './amelia.service';
import { SendingService } from './sending.service';
import { AmeliaApiService } from './amelia-api.service';

export const services: any[] = [
  LogistCabsService,
  LogistOrdersService,
  OrderApiService,
  AmeliaService,
  SendingService,
  AmeliaApiService
];

export * from './amelia-api.service';
export * from './logist-cabs.service';
export * from './logist-orders.service';
export * from './order-api.service';
export * from './amelia.service';
export * from './sending.service';
