import { AmeliaService } from './../../services/amelia.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'utax-cab-finish-shift-modal',
  templateUrl: './cab-finish-shift-modal.component.html',
  styleUrls: ['./cab-finish-shift-modal.component.scss']
})
export class CabFinishShiftModalComponent implements OnInit {
  minutesInWaiting;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CabFinishShiftModalComponent>,
    private amelia: AmeliaService
  ) {}

  ngOnInit() {
    this.minutesInWaiting = moment().diff(
      moment.unix(this.data.req.createdAt.seconds),
      'minutes'
    );
  }

  close() {
    this.dialogRef.close('close');
  }

  cancel() {
    console.log('finish cancel');
    this.amelia.confirmDriverRequest(false, this.data.req.id);
    this.dialogRef.close('cancel');
  }

  confirm() {
    console.log('start confirm');
    this.amelia.confirmDriverRequest(true, this.data.req.id);
    this.dialogRef.close('confirm');
  }
}
