import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { TranslationObj } from '../../services';
import { CustomTranslationModalComponent } from '../modals/custom-translation-modal/custom-translation-modal.component';

interface TemplateSettings {
  type?: 'mat-textarea' | 'mat-input';
  maxRows?: number;
  minRows?: number;
  placeholder?: string;
  className?: string;
}

@Component({
  selector: 'utax-custom-translation-field',
  templateUrl: './custom-translation-field.component.html',
  styleUrls: ['./custom-translation-field.component.scss']
})
export class CustomTranslationFieldComponent implements OnDestroy {
  @Input() fieldValue: string;
  @Input() locField: string;
  @Input() title: string;
  @Input() bundleNames: string[];
  @Input() notLockedValue: string;
  @Input() templateSettings: TemplateSettings = { type: 'mat-input' };
  @Input() appearance: 'outline' | 'fill' | 'standard' | 'legacy' | 'none' = 'legacy';

  private dialogRef: MatDialogRef<CustomTranslationModalComponent>;
  private dialogConfig: MatDialogConfig = new MatDialogConfig();

  private componentDestroyed$ = new Subject();

  @Output() private changeValueField: EventEmitter<{ key: string; value: string }> = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  clickOnField(): void {
    this.dialogConfig.data = {
      fieldType: this.templateSettings.type,
      value: this.locField ? this.locField : this.fieldValue,
      title: this.title,
      bundleNames: this.bundleNames,
      notLockedValue: this.notLockedValue
    };
    this.dialogConfig.width = '60vw';
    this.dialogConfig.panelClass = 'custom-translation-modal-container';

    this.dialogRef = this.dialog.open(CustomTranslationModalComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe((data: TranslationObj) => {
      if (data && data.key) {
        this.changeValueField.emit({
          key: data.key,
          value: data.translations[0].value
        });
      }
    });
  }
}
