import { DispOrdersApiService } from '../../../dispatcher/services/disp-orders-api.service';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'utax-order-increase-cost',
  templateUrl: './order-increase-cost.component.html',
  styleUrls: ['./order-increase-cost.component.scss']
})
export class OrderIncreaseCostComponent implements OnInit {
  @Input() order: any;
  @ViewChild('fareInput', { static: true }) fareInput: any;
  addedValue: number;
  amount: number;

  @Output() private closeWindow = new EventEmitter<boolean>();

  constructor(private orderApiService: DispOrdersApiService, private eRef: ElementRef) {}

  ngOnInit() {
    this.amount = this.order.fare.amount;
    this.addedValue = this.order.fare.added_value;
    this.fareInput.nativeElement.focus();
  }

  amountChange(event): void {
    this.addedValue = event - this.order.fare.value;
  }

  @HostListener('document:keyup', ['$event'])
  hotkeyUpHandler($event) {
    switch ($event.keyCode) {
      case 13: {
        // enter
        $event.preventDefault();
        this.save();
        break;
      }

      case 27: {
        // escape
        $event.preventDefault();
        this.closeWindow.emit(false);
        break;
      }
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeWindow.emit(false);
    }
  }

  save(): void {
    if (this.order.fare.added_value !== this.addedValue) {
      this.orderApiService
        .addValue({
          added_value: this.addedValue + '',
          id: this.order.fare.id,
          notify: false,
          with_request_update: false
        })
        .subscribe((res: any) => {
          res.error ? this.closeWindow.emit(res.error) : this.closeWindow.emit(true);
        });
    } else {
      this.closeWindow.emit(false);
    }
  }

  close(): void {
    this.closeWindow.emit(false);
  }
}
