import { DispatcherFilters, ServiceFilter } from './../models/dispatcher';
import { Order } from './../../interfaces/order.interface';
import * as _ from 'lodash';

export const filterFunctions = {
  taxiService: (order: Order, filters: DispatcherFilters) => {
    // taxi service filtering
    return !!filters.services.find((serv: ServiceFilter) => {
      return serv.status && serv.id === order.service_id;
    });
  },
  input: (order: Order, filters: DispatcherFilters) => {
    if (filters.checkBoxes.input.telephony.status === filters.checkBoxes.input.app.status) {
      return true;
    }

    if (filters.checkBoxes.input.telephony.status) {
      return !order.is_from_app;
    }

    if (filters.checkBoxes.input.app.status) {
      return order.is_from_app;
    }
  },
  type: (order: Order, filters: DispatcherFilters): boolean => {
    // type
    if (filters.checkBoxes.type.utax_front_preorder.status === filters.checkBoxes.type.current.status) {
      return true;
    }

    if (filters.checkBoxes.type.utax_front_preorder.status) {
      return order.is_preorder;
    }

    if (filters.checkBoxes.type.current.status) {
      return !order.is_preorder;
    }
  },
  payment_type: (order: Order, filters: DispatcherFilters): boolean => {
    // payment type (cash, bonus, card)
    if (
      filters.checkBoxes.payment_type.cash.status &&
      filters.checkBoxes.payment_type.utax_front_card.status &&
      filters.checkBoxes.payment_type.phone.status &&
      filters.checkBoxes.payment_type.bonus.status
    ) {
      return true;
    }
    if (
      !filters.checkBoxes.payment_type.cash.status &&
      !filters.checkBoxes.payment_type.utax_front_card.status &&
      !filters.checkBoxes.payment_type.phone.status &&
      !filters.checkBoxes.payment_type.bonus.status
    ) {
      return true;
    }
    let letItPass = false;
    switch (order.payment_type) {
      case 'cash':
        letItPass = filters.checkBoxes.payment_type.cash.status;
        break;
      case 'bonus':
        letItPass = filters.checkBoxes.payment_type.bonus.status;
        break;
      case 'card':
      case 'apple_pay':
      case 'google_pay':
        letItPass = filters.checkBoxes.payment_type.utax_front_card.status;
        break;
      case 'phone':
        letItPass = filters.checkBoxes.payment_type.phone.status;
        break;
      default:
        break;
    }
    return letItPass;
  },
  trip_source: (order: Order, filters: DispatcherFilters): boolean => {
    // status waiting,
    if (
      filters.checkBoxes.trip_source.utax_front_on_address.status &&
      filters.checkBoxes.trip_source.search.status &&
      filters.checkBoxes.trip_source.utax_front_accepted.status &&
      filters.checkBoxes.trip_source.utax_front_in_progress
        .status /* &&
      filters.checkBoxes.trip_source.utax_front_completed.status*/
    ) {
      return true;
    }
    if (
      !filters.checkBoxes.trip_source.utax_front_on_address.status &&
      !filters.checkBoxes.trip_source.search.status &&
      !filters.checkBoxes.trip_source.utax_front_accepted.status &&
      !filters.checkBoxes.trip_source.utax_front_in_progress
        .status /* &&
      !filters.checkBoxes.trip_source.utax_front_completed.status*/
    ) {
      return true;
    }

    let letItPass = false;
    switch (order.status) {
      case 'processing':
        // search
        letItPass = filters.checkBoxes.trip_source.search.status;
        break;
      case 'accepted':
        // utax_front_accepted

        letItPass = filters.checkBoxes.trip_source.utax_front_accepted.status;
        break;
      case 'waiting':
        // utax_front_on_address
        letItPass = filters.checkBoxes.trip_source.utax_front_on_address.status;
        break;
      case 'in_progress':
        // utax_front_in_progress
        letItPass = filters.checkBoxes.trip_source.utax_front_in_progress.status;
        break;

      case 'completed':
        // utax_front_completed
        letItPass = true; // filters.checkBoxes.trip_source.utax_front_completed.status;
        break;
      default:
        break;
    }
    return letItPass;
  },
  self_order: (order: Order, filters: DispatcherFilters) => {
    if (filters.checkBoxes.self_order.utax_front_my_orders.status) {
      return order.operator_id === filters.additionalFilters.myOrders.userId;
    } else {
      return true;
    }
  },
  isMyOrder: (order: Order, filters: DispatcherFilters) => {
    // is my order
    if (filters.additionalFilters.myOrders.status) {
      return order.operator_id === filters.additionalFilters.myOrders.userId;
    } else {
      return true;
    }
  }
};
