import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';
import { RequestHistoryModel, RequestHistoryType } from '../../models/requests-history.model';

@Component({
  selector: 'utax-requests-history',
  templateUrl: './requests-history.component.html',
  styleUrls: ['./requests-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestsHistoryComponent implements OnInit, OnChanges {
  @Input() requestsHistory: RequestHistoryModel[];
  @Input() serviceId: number;
  requestTypes = RequestHistoryType;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {}
}
