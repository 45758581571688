import { Pipe, PipeTransform } from '@angular/core';
import { Role } from '../../models/user.interface';

@Pipe({
  name: 'getRoleDisplayName'
})
export class GetRoleDisplayNamePipe implements PipeTransform {
  transform(id: string, roles: Role[]): string {
    const role = roles.find(item => item.id === id);
    return role ? role.display_name : '';
  }
}
