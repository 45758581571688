import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { QueueGroupModel } from 'src/app/call-centre/modules/queue/models';
import {DispRole, IBrigade} from '../../../models';
import {GlobalDataService} from '@global-services/global-data.service';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'utax-disp-settings-edit-table',
  templateUrl: './disp-settings-edit-table.component.html',
  styleUrls: ['./disp-settings-edit-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispSettingsEditTableComponent implements AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() selectedId: string;
  @Input('dispatchers') set setDispatchers(dispatchers: any[]) {
    if ( dispatchers && dispatchers?.length > 0 && !this.selectedId) {
      this.selectedId = dispatchers[0].id;
      this.select.emit(dispatchers[0].id);
    }
    dispatchers = dispatchers.map(disp => {
      return {
        ...disp,
        taxiServices: disp.taxiServiceIds.map(sId => {
          const service = this.getService(sId);
          return {
            ...service,
            active: !!disp.activeTaxiServiceIds.find(id => id === sId),
          };
        })
      };
    });
    this.dispatchers = dispatchers;
    this.statisDispathers = [...dispatchers];
    this.dataSource = new MatTableDataSource(dispatchers);
  }
  @Input('filterForm') set setfilterForm(filterForm) {
    this.filterForm = filterForm;
    this.isSelectedCheck();
  }
  @Input('queueGroups') set setQueueGroups(queueGroups: QueueGroupModel[]) {
    this.queueGroups = this.getServices();
    // this.queueGroups = queueGroups;
    this.isSelectedCheck();
  }
  @Input() roles: DispRole[];
  @Input() brigades: IBrigade[];
  @Output() edit = new EventEmitter();
  @Output() select: EventEmitter<string> = new EventEmitter();
  @Output() scroll: EventEmitter<any> = new EventEmitter();
  @Output() toggleSelected: EventEmitter<any> = new EventEmitter();
  @Output() toggleSelectedBrigades: EventEmitter<any> = new EventEmitter();
  @Output() onSorted: EventEmitter<any> = new EventEmitter();
  dataSource;
  displayedColumns = ['name', 'global_user_name', 'brigade', 'roles', 'queue_service_groups'];

  filterForm: UntypedFormGroup;
  queueGroups: QueueGroupModel[];
  selectedAll = false;
  selectedAllBrigades = false;
  dispatchers: any;
  statisDispathers: any[];

  constructor(
    private globalDataService: GlobalDataService,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  sortData(sort: any): any {
    this.onSorted.emit([
      `name,${sort.direction}`
    ]);
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getRoles(roleIds: string[]): any {
    return this.globalDataService.getRole(roleIds)?.join(', ');
  }

  getBrigade(brigadeId): any {
    return this.globalDataService.getBrigade(brigadeId).name;
  }

  getService(id: string): any {
    return this.globalDataService.getService(id);
  }

  getServices(): any {
    return this.globalDataService.globalTaxiServices$.value;
  }

  onSelectAll(): void {
    const uuids = this.queueGroups.map((item: any) => item.id);
    this.filterForm.patchValue({ queueGroups: uuids });
    this.isSelectedCheck();
    this.toggleSelected.emit(this.filterForm);
  }

  onDeselectAll(): void {
    this.filterForm.patchValue({queueGroups: []});
    this.isSelectedCheck();
    this.toggleSelected.emit(this.filterForm);
  }

  isSelectedCheck(): void {
    this.selectedAll = (this.queueGroups?.length === this.filterForm?.value?.queueGroups?.length);
  }

  onSelectAllBrigades(): void {
    const brigades = this.brigades.map((item: any) => item.id);
    this.filterForm.patchValue({ brigades });
    this.isSelectedCheckBrigades();
    this.toggleSelectedBrigades.emit(this.filterForm);
  }

  onDeselectAllBrigades(): void {
    this.filterForm.patchValue({brigades: []});
    this.isSelectedCheckBrigades();
    this.toggleSelectedBrigades.emit(this.filterForm);
  }

  isSelectedCheckBrigades(): void {
    this.selectedAllBrigades = (this.brigades?.length === this.filterForm?.value?.brigades?.length);
  }

  editClick(row): void {
    this.edit.emit(row);
  }

  selectClick(event: any): void {
    this.select.emit(event.id);
  }

  scrollPage(): void {
    this.scroll.emit();
  }
}
