import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {
  ChatHistoryDataInterface,
  ChatHistoryDataUserInterface,
  ChatHistoryInterface,
  ChatHistoryNewMessageInterface,
  ChatHistoryOldMessageInterface
} from "../interfaces/chat.interface";

@Injectable({providedIn: 'root'})
export class ChatApiService {

  public chatNewMessage$: Subject<ChatHistoryDataInterface[]> = new Subject<ChatHistoryDataInterface[]>();
  public chatOldMessages$: Subject<ChatHistoryDataInterface[]> = new Subject<ChatHistoryDataInterface[]>();
  public lastKick$: BehaviorSubject<'driver' | 'passenger'> = new BehaviorSubject<'driver' | 'passenger'>(null);
  public currentChatId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(private http: HttpClient) {

  }

  public getChatHistoryForOrder(id: string): Observable<ChatHistoryInterface> {
    return this.http.get<ChatHistoryInterface>('operator/requests/chat', {params: {id}});
  }



}
