import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

// containers
import * as fromContainers from './containers';

import * as fromServices from './services';

@NgModule({
  imports: [CommonModule, HttpClientModule, FormsModule, ReactiveFormsModule, TranslateModule, NgxSpinnerModule],
  declarations: [...fromContainers.containers],
  exports: [...fromContainers.containers],
  providers: [...fromServices.services]
})
export class AuthModule {}
