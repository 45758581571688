import { WaypointsService } from './../../services/waypoints.service';
import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';

@Component({
  selector: 'utax-waypoints',
  templateUrl: './waypoints.component.html',
  styleUrls: ['./waypoints.component.scss']
})
export class WaypointsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() waypoints;
  @Input() entranceNumber: string;
  @Input() isFromApp: boolean;

  points;
  isShowMoreButton = true;

  constructor(public waypointsService: WaypointsService) {}

  ngOnInit() {
    this.points = this.waypointsService.sortWaypoints(this.waypoints);
  }

  ngOnChanges(): void {
    this.showLess(0);
  }

  ngOnDestroy() {
    this.points = [];
  }

  showLess(index) {
    if (index === 0) {
      this.isShowMoreButton = true;
    }
  }

  showMore() {
    this.isShowMoreButton = false;
  }

  canItBeShown(i: number) {
    if (i === 0 || i === this.waypoints.length - 1 || this.waypoints.length === 2 || this.waypoints.length === 3) {
      return true;
    }
    if (this.isShowMoreButton) {
      return false;
    } else {
      return true;
    }
  }
}
