import { Pipe, PipeTransform } from '@angular/core';
import { StateService } from 'src/app/services';

@Pipe({
  name: 'getProductName'
})
export class GetProductNamePipe implements PipeTransform {

  constructor(private state: StateService) { }

  transform(productId: string): any {
    let currentProduct;
    if (this.state.dumbStore.products) {
      currentProduct = this.state.dumbStore.products.find(item => item.id === productId);
    }
    return currentProduct ? currentProduct.name : '';
  }

}
