import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ArchiveApiService } from './../../services/archive-api.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'utax-archive-tools',
  templateUrl: './archive-tools.component.html',
  styleUrls: ['./archive-tools.component.scss']
})
export class ArchiveToolsComponent implements OnInit {
  searchForm: UntypedFormGroup;
  dateValue;

  startDate = moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');
  finishDate = moment().format('YYYY-MM-DD HH:mm:ss');

  period;

  periods = [
    { text: 'TODAY', value: 'today' },
    { text: 'YESTERDAY', value: 'yesterday' },
    { text: 'LAST_WEEK', value: 'week' },
    { text: 'LAST_MONTH', value: 'month' },
    { text: 'LAST_THREE_MONTHES', value: '3months' }
  ];

  constructor(public archiveApiService: ArchiveApiService, private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.searchForm = this.fb.group({
      search: [''],
      period: [''],
      dateFrom: [''],
      dateTo: ['']
    });
  }

  clean(event) { }

  find(event) { }

  updateDateFilters() {
    this.archiveApiService.getArchiveList({
      page: 1,
      start_date: this.startDate,
      finish_date: this.finishDate
    });
  }

  checkboxToggled(event, key) {
    this.archiveApiService.togglCheckbox(event, key);
  }

  hotkeyCompare(a, b) {
    if (a[Object.keys(a)[0]].hotkey > b[Object.keys(b)[0]].hotkey) { return 1; } else { return -1; }
  }

  selectTimePeriod(e) {
    this.startDate = this.archiveApiService.recountDates(e.value).startDate;
    this.finishDate = this.archiveApiService.recountDates(e.value).finishDate;

    this.updateDateFilters();
  }

}

