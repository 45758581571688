export function normalizePhoneNumber(phoneNumber: string): string {
  let phone = ``;
  if (phoneNumber && phoneNumber.length > 0) {
    phone = phoneNumber
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/-/g, '')
      .slice(-10);
  } else {
    phone = phoneNumber;
  }
  return phone;
}
