import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, pluck } from 'rxjs/operators';
import * as _ from 'lodash';

const orderHistoryUrl = 'operator/history/request',
  causerUrl = 'operator/history/causer',
  statusesHistoryUrl = 'operator/trips/statuses',
  requestsHistoryUrl = 'operator/trips/statuses/request';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  orderHistory$ = new BehaviorSubject([]);
  cache = {};

  constructor(private http: HttpClient) {}

  getOrderHistory(orderId: string, tripId: string, forceUpdate?): void {
    if (forceUpdate) {
      this.forceUpdate(orderId, tripId);
    } else {
      this.getReqHistoryOfOrder(orderId, tripId);
    }
  }

  forceUpdate(orderId: string, tripId: string): void {
    this.orderHistoryReq(orderId, tripId).subscribe((res: any) => {
      this.orderHistory$.next(res);
      this.cache[orderId] = res;
    });
  }

  getCauser(historyItemId: string) {
    return this.http.get(causerUrl, {
      params: new HttpParams({ fromObject: { id: historyItemId } })
    });
  }

  private getReqHistoryOfOrder(orderId: string, tripId: string): void {
    if (this.cache[orderId]) {
      this.orderHistory$.next(this.cache[orderId]);
    } else {
      this.orderHistoryReq(orderId, tripId).subscribe((res: any) => {
        this.orderHistory$.next(res);
        if (Object.keys(this.cache).length > 5000) {
          this.cache = {};
        }
        this.cache[orderId] = res;
      });
    }
  }

  private orderHistoryReq(id: string, tripId: string): Observable<any> {
    if (tripId) {
      return forkJoin([
        this.generalOrderHistoryReq(id),
        this.statusChangesOrderHistoryReq(tripId),
        this.requestsOrderHistoryReq(id)
      ]).pipe(map((res: any[]) => {
        return _.flatten(res)
      }));
    } else {
      return forkJoin([this.generalOrderHistoryReq(id), this.requestsOrderHistoryReq(id)]).pipe(
        map((res: any[]) => {
          return _.flatten(res)
        })
      );
    }
  }

  private generalOrderHistoryReq(id: string): Observable<any> {
    return this.http.get(orderHistoryUrl, { params: { id } }).pipe(pluck('data'));
  }

  private statusChangesOrderHistoryReq(id: string): Observable<any> {
    return this.http.get(statusesHistoryUrl, { params: { id } }).pipe(pluck('data'));
  }

  private requestsOrderHistoryReq(orderId: string): Observable<any> {
    return this.http.get(requestsHistoryUrl, { params: { request_id: orderId } }).pipe(
      pluck('data'),
      map((res: any[]) => {
        return res.map(item => {
          return item.statuses.map(status => {
            return { ...status, driver_callsign: item.driver_callsign };
          });
        });
      }),
      map(res => _.flatten(res))
    );
  }
}
