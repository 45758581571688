import { OrdersService } from 'src/app/services';
import { LogistOrdersService } from './../../services/logist-orders.service';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, OnChanges, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { OrderApiService, LogistCabsService } from '../../services';
import { YesNoModalComponent } from 'src/app/shared/components';
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'utax-cab-statuses',
  templateUrl: './cab-statuses.component.html',
  styleUrls: ['./cab-statuses.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CabStatusesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() cab: any;
  @Input() shift: any;
  @Input() order: any;

  cabTrip;

  showAcceptRejectButtons = true;

  acceptTitle = 'ACCEPT_ORDER_FOR_DRIVER';
  cancelTitle = 'CANCEL_ORDER_FOR_DRIVER';
  arrivedTitle = 'DRIVER_ARRIVED';
  pickUpTitle = 'PICK_UP_PASSENGER_FOR_DRIVER';
  completeTitle = 'COMPLETING_ORDER_FOR_DRIVER';
  acceptWorkTitle = 'DRIVER_WILL_WORK_WITH_ORDER';

  changeIndicator = 0;

  private dialogRef: MatDialogRef<YesNoModalComponent>;
  private dialogConfig = new MatDialogConfig();

  private componentDestroyed$ = new Subject();
  constructor(
    private orderApiService: OrderApiService,
    private dialog: MatDialog,
    private logistOrdersService: LogistOrdersService,
    private ordersservice: OrdersService,
    public logistCabsService: LogistCabsService
  ) {}

  ngOnInit(): void {
    this.logistCabsService.visibleCabsSub$.pipe(takeUntil(this.componentDestroyed$)).subscribe(cabs => {
      const update = cabs.find(cab => cab.id === this.cab.id);
      if (update) {
        this.cab = update;
      }
    });
    interval(10000)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this.changeIndicator++);
  }

  ngOnChanges(): void {
    this.cabTrip = this.cab.trips.length === 0 ? this.cab.trip : this.cab.trips[0];
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  accept(): void {
    this.dialogConfig.data = {
      title: this.acceptTitle
    };
    this.dialogConfig.panelClass = 'yes-no-modal-container';
    this.ordersservice.modalOpened = true;
    this.dialogRef = this.dialog.open(YesNoModalComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(data => {
      if (data === 'YES') {
        this.orderApiService.acceptOrder(this.cab.offer.id).subscribe((res: any) => {
          this.showAcceptRejectButtons = false;
          this.logistOrdersService.changeOrderStatus(this.cab.offer.request_id, 'accepted');
        });
      }
      this.dialogConfig = {};
      this.ordersservice.modalOpened = false;
    });
  }

  reject(): void {
    this.dialogConfig.data = {
      title: this.cancelTitle
    };
    this.dialogConfig.panelClass = 'yes-no-modal-container';
    this.ordersservice.modalOpened = true;
    this.dialogRef = this.dialog.open(YesNoModalComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(data => {
      if (data === 'YES') {
        this.orderApiService.rejectOrder(this.cab.offer.id).subscribe((res: any) => {
          this.showAcceptRejectButtons = false;
          this.logistOrdersService.changeReservedStatus(this.cab.offer.request_id);
        });
      }
      this.dialogConfig = {};
      this.ordersservice.modalOpened = false;
    });
  }

  tripDequeue(): void {
    this.dialogConfig.data = {
      title: this.acceptWorkTitle
    };
    this.dialogConfig.panelClass = 'yes-no-modal-container';
    this.ordersservice.modalOpened = true;
    this.dialogRef = this.dialog.open(YesNoModalComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(data => {
      if (data === 'YES') {
        this.orderApiService.tripDequeue(this.cab.order.trip.id).subscribe();
      }
      this.dialogConfig = {};
      this.ordersservice.modalOpened = false;
    });
  }

  arrived() {
    this.dialogConfig.data = {
      title: this.arrivedTitle
    };
    this.dialogConfig.panelClass = 'yes-no-modal-container';
    this.ordersservice.modalOpened = true;
    this.dialogRef = this.dialog.open(YesNoModalComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(data => {
      if (data === 'YES') {
        this.orderApiService.tripArrived(this.cab.order.trip.id).subscribe();
        if (this.order) {
          this.logistOrdersService.changeOrderStatus(this.order.id, 'waiting');
        }
      }
      this.dialogConfig = {};
      this.ordersservice.modalOpened = false;
    });
  }

  pickUp() {
    this.dialogConfig.data = {
      title: this.pickUpTitle
    };
    this.dialogConfig.panelClass = 'yes-no-modal-container';
    this.ordersservice.modalOpened = true;
    this.dialogRef = this.dialog.open(YesNoModalComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(data => {
      if (data === 'YES') {
        this.orderApiService.tripPickUp(this.cab.order.trip.id).subscribe();
        if (this.order) {
          this.logistOrdersService.changeOrderStatus(this.order.id, 'in_progress');
        }
      }
      this.dialogConfig = {};
      this.ordersservice.modalOpened = false;
    });
  }

  complete() {
    this.dialogConfig.data = {
      title: this.completeTitle
    };
    this.dialogConfig.panelClass = 'yes-no-modal-container';
    this.ordersservice.modalOpened = true;
    this.dialogRef = this.dialog.open(YesNoModalComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(data => {
      if (data === 'YES') {
        this.ordersservice.tripComplete(this.cab.order.trip.id).subscribe();
        if (this.order) {
          this.logistOrdersService.changeOrderStatus(this.order.id, 'completed');
        }
      }
      this.dialogConfig = {};
      this.ordersservice.modalOpened = false;
    });
  }
}
