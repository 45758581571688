import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasRests'
})
export class HasRestsPipe implements PipeTransform {

  transform(restType: any, shift: any, allRests): boolean {
    return allRests.some(rest => rest.wshId === shift.id && restType.id === rest.typeId);
  }

}
