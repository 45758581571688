import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'utax-split-view',
  templateUrl: './split-view.component.html',
  styleUrls: ['./split-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplitViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}
