import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, timer } from 'rxjs';
import {delayWhen, map, mergeMap, retryWhen, takeUntil, tap} from 'rxjs/operators';
import { HeaderToolsHandlerService } from 'src/app/navigation/services';
import { DispSettingsCreateDispModalComponent } from '../../components';
import { DispSettingsApiService, DispSettingsService } from '../../services';
import {FallbackStatusesInfoModel, IOperatorStatistic} from '../../models';
import {HandleEventService} from "@global-services/handle-event-service.service";
import * as moment from 'moment';
import {StateService} from "@global-services/state.service";
import {GlobalDataService} from "@global-services/global-data.service";

@Component({
  selector: 'utax-disp-settings',
  templateUrl: './disp-settings.component.html',
  styleUrls: ['./disp-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispSettingsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('controlMenu') controlMenu: TemplateRef<any>;
  statusesStatistic$: Observable<any>;
  fallbackStatusesStatistic$: Observable<FallbackStatusesInfoModel>;
  mobileOperatorsStatistic$: Observable<IOperatorStatistic[]>;

  private dialogRef: MatDialogRef<DispSettingsCreateDispModalComponent>;
  private componentDestroyed$ = new Subject();

  constructor(
    private headerToolsHandlerService: HeaderToolsHandlerService,
    private dispSettingsApiService: DispSettingsApiService,
    public dispSettingsService: DispSettingsService,
    private handleEventService: HandleEventService,
    private globalDataService: GlobalDataService,
    private stateService: StateService
  ) {
    this.stateService.getStoreParamSub('permissions').pipe(
      map((permissions) => {
        this.dispSettingsService.isExtendedView$.next(permissions?.some(p => p.name === 'operator_dispatcher_tab_extended_view'));
        return permissions.some(p => p.name === 'operator_dispatcher_tab_full_view');
      }),
      tap((hasPermission) => this.dispSettingsService.isFullView$.next(hasPermission)),
    ).subscribe();
  }

  ngOnInit() {
    this.dispSettingsService.loadLists();
    if (this.dispSettingsService.isFullView$.value || this.dispSettingsService.isExtendedView$.value) {
      this.initStatistic();
    } else {
      this.statusesStatistic$ = timer(0, 5000).pipe(
        takeUntil(this.componentDestroyed$),
        mergeMap(() => this.dispSettingsApiService.getStatusesInfo()),
        map((statuses) => {
          statuses.brigades = statuses.brigades.filter(b => this.globalDataService.globalBrigades$.value.some(gb => gb.id === b.id && gb.isOnlyOperators));
          return statuses;
        }), // some logic
        retryWhen(errors =>
          errors.pipe(
            delayWhen(val => timer(5000))
          )
        )
      );
    }
  }

  private initStatistic(): void {
    this.statusesStatistic$ = timer(0, 5000).pipe(
      takeUntil(this.componentDestroyed$),
      mergeMap(() => this.dispSettingsApiService.getStatusesInfo()),
      retryWhen(errors =>
        errors.pipe(
          delayWhen(val => timer(5000))
        )
      )
    );
    this.fallbackStatusesStatistic$ = timer(0, 5000).pipe(
      takeUntil(this.componentDestroyed$),
      mergeMap(() => this.dispSettingsApiService.getFallbackStatusesInfo()),
      retryWhen(errors =>
        errors.pipe(
          delayWhen(val => timer(5000))
        )
      )
    );

    this.mobileOperatorsStatistic$ = timer(0, 30000).pipe(
      takeUntil(this.componentDestroyed$),
      mergeMap(() => this.dispSettingsApiService.getMobileOperatorLastActive()),
      retryWhen(errors =>
        errors.pipe(
          delayWhen(val => timer(30000))
        )
      )
    );
  }

  ngAfterViewInit(): void {
    this.headerToolsHandlerService.template.next({
      controlMenu: this.controlMenu,
      source: 'call-centre/disp-settings'
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  create(): void {
    this.dialogRef = this.dispSettingsService.openDispModal();
    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(res => {
        if (res) {
          this.handleEventService.openSnackBar('UTAX_FRONT_USER_CREATED_SUCCESS', 1);
          this.dispSettingsApiService.updateDisp(res).subscribe(response => {
            this.dispSettingsService.allDispatchers$.next(
              this.dispSettingsService.allDispatchers$.value.map(disp => {
                return response.id === disp.id ? response : disp;
              })
            );
          });
        }
      });
  }

  public checkDurationPauseOperator(duration: number): boolean {
    const checkDuration = moment()
      .isBetween(
        moment().startOf('day').add(6, 'hour'),
        moment().startOf('day').add(23, 'hour'))
      ? 420 : 3600;
    return  duration > checkDuration;
  }

}
