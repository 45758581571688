import { YesNoModalComponent } from './../../../shared/components/modals/yes-no-modal/yes-no-modal.component';
import { CabCancelReqModalComponent } from './../cab-cancel-req-modal/cab-cancel-req-modal.component';
import { interval, Subject } from 'rxjs';
import { CabRestStartModalComponent } from './../cab-rest-start-modal/cab-rest-start-modal.component';
import { CabWashingStartModalComponent } from './../cab-washing-start-modal/cab-washing-start-modal.component';
import { CabRefillModalComponent } from './../cab-refill-modal/cab-refill-modal.component';
import { CabStartShiftModalComponent } from './../cab-start-shift-modal/cab-start-shift-modal.component';
import { CabFinishShiftModalComponent } from './../cab-finish-shift-modal/cab-finish-shift-modal.component';
import { CabRequestModalComponent } from './../cab-request-modal/cab-request-modal.component';
import { Component, Input, OnChanges, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { AmeliaService } from '../../services';

@Component({
  selector: 'utax-request-panel',
  templateUrl: './request-panel.component.html',
  styleUrls: ['./request-panel.component.scss']
})
export class RequestPanelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() activeReqs;
  @Input() activeShift;
  @Input() cab;

  requestTypes: string[] = [];

  reqTimers = {};

  dialogRef: MatDialogRef<
    | CabRequestModalComponent
    | CabFinishShiftModalComponent
    | CabStartShiftModalComponent
    | CabRefillModalComponent
    | CabWashingStartModalComponent
    | CabRestStartModalComponent
    | CabCancelReqModalComponent
    | YesNoModalComponent
  >;
  dialogConfig = new MatDialogConfig();

  private componentDestroyed$ = new Subject();

  constructor(private dialog: MatDialog, private cd: ChangeDetectorRef, private amelia: AmeliaService) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.activeReqs.forEach(req => {
      if (req.driverRest || req.vehicleWashing) {
        this.timer(req, 'req');
      }
    });
    if (this.cab && this.cab.pauses) {
      this.cab.pauses.forEach(pause => {
        if (pause.status === 1) {
          // active pause
          this.timer(pause, 'pause');
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  openModal(req): void {
    console.log('Req OPEN MODAL', req);

    if (req.status === 'confirmed' || req.status === 'in_progress') {
    } else {
      switch (req.type) {
        case 0: // 'START_WORKING_SHIFT':
          this.openStartWorkingShiftModal(req);
          break;

        case 1: // 'FINISH_WORKING_SHIFT':
          this.openFinishWorkingShift(req);
          break;

        case 2: // 'VEHICLE_REFILL':
          this.openVehicleRefill(req);
          break;

        case 3: // 'START_VEHICLE_WASHING':
          this.openStartVehicleWashing(req);
          break;

        case 5: // 'STORE_DRIVER_EXPENSE':
          console.log('STORE_DRIVER_EXPENSE');
          break;

        case 6: // 'START_DRIVER_REST':
          console.log('START_DRIVER_REST');
          this.openStartRestModal(req);
          break;

        case 7: // 'CANCEL_DRIVER_REQUEST':
          this.openCancelReqModal(req);
          break;

        default:
          this.openStartWorkingShiftModal(req);
          console.log('openModal default case');
          break;
      }
    }
  }

  private openCancelReqModal(req) {
    const reqWhichCanceling = this.activeReqs.find(actReq => actReq.id === req.payload.requestId);
    this.dialogConfig.data = {
      req,
      reqWhichCanceling,
      callsign: this.cab.callsign,
      title: 'CANCEL_REQUEST'
    };
    this.dialogConfig.width = '40vw';
    this.dialogConfig.height = '20vh';
    this.dialogConfig.panelClass = 'start-rest-modal-container';

    this.dialogRef = this.dialog.open(CabCancelReqModalComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(data => {
      console.log('DIALOG CLOSED cancel req', data);
      this.dialogConfig = {};
    });
  }

  private openStartRestModal(req): void {
    this.dialogConfig.data = {
      req,
      callsign: this.cab.callsign,
      title: 'START_REST_REQUEST'
    };
    this.dialogConfig.width = '30vw';
    this.dialogConfig.height = '20vh';
    this.dialogConfig.panelClass = 'start-rest-modal-container';

    this.dialogRef = this.dialog.open(CabRestStartModalComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(data => {
      console.log('DIALOG CLOSED rest start', data);
      this.dialogConfig = {};
    });
  }

  private openStartWorkingShiftModal(req): void {
    this.dialogConfig.data = {
      req,
      callsign: this.cab.callsign,
      title: 'START_WORKING_SHIFT_REQUEST',
      startMileage: this.activeShift.startMileage
    };
    this.dialogConfig.width = '75vw';
    this.dialogConfig.height = '95vh';
    this.dialogConfig.panelClass = 'start-shift-modal-container';

    this.dialogRef = this.dialog.open(CabStartShiftModalComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(data => {
      console.log('DIALOG CLOSED StartWorkingShiftModal', data);
      this.dialogConfig = {};
    });
  }

  private openFinishWorkingShift(req): void {
    this.dialogConfig.data = {
      req,
      callsign: this.cab.callsign,
      title: 'FINISH_WORKING_SHIFT_REQUEST',
      startMileage: this.activeShift.startMileage
    };
    this.dialogConfig.width = '75vw';
    this.dialogConfig.height = '81vh';
    this.dialogConfig.panelClass = 'finish-shift-modal-container';

    this.dialogRef = this.dialog.open(CabFinishShiftModalComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(data => {
      console.log('DIALOG CLOSED FinishWorkingShift', data);
      this.dialogConfig = {};
    });
  }

  private openVehicleRefill(req): void {
    this.dialogConfig.data = {
      req,
      callsign: this.cab.callsign,
      title: 'REFILL_REQUEST'
    };
    this.dialogConfig.width = '30vw';
    this.dialogConfig.height = '20vh';
    this.dialogConfig.panelClass = 'refill-modal-container';

    this.dialogRef = this.dialog.open(CabRefillModalComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(data => {
      console.log('DIALOG CLOSED refill', data);
      this.dialogConfig = {};
    });
  }

  private openStartVehicleWashing(req): void {
    this.dialogConfig.data = {
      req,
      callsign: this.cab.callsign,
      title: 'START_WASHING_REQUEST'
    };
    this.dialogConfig.width = '75vw';
    this.dialogConfig.height = '75vh';
    this.dialogConfig.panelClass = 'start-washing-modal-container';

    this.dialogRef = this.dialog.open(CabWashingStartModalComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(data => {
      console.log('DIALOG CLOSED WASHING START', data);
      this.dialogConfig = {};
    });
  }

  timer(req, type: string) {
    if (req.driverRest) {
      interval(1000)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(time => {
          this.updateTimer(req.id, req.driverRest.startedAt.seconds);
        });
    } else if (req.vehicleWashing) {
      interval(1000)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(time => {
          this.updateTimer(req.id, req.vehicleWashing.startedAt.seconds);
        });
    }
    if (type === 'pause') {
      interval(1000)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(() => {
          this.updateTimer(req.id, req.startedAt.seconds);
        });
    }
  }

  start(req) {
    console.log('TCL: RequestPanelComponent -> start -> req', req);
    switch (req.type) {
      // washing
      case 3:
        this.dialogConfig.data = {
          title: 'ARE_YOU_SURE_THAT_YOU_WANT_START_WASHING'
        };
        this.dialogConfig.panelClass = 'yes-no-modal-container';
        this.dialogRef = this.dialog.open(YesNoModalComponent, this.dialogConfig);
        this.dialogRef.afterClosed().subscribe(data => {
          if (data === 'YES') {
            this.amelia.startWashing(req.id);
          }
          this.dialogConfig = {};
        });
        break;
      // rest
      case 6:
        this.dialogConfig.data = {
          title: 'ARE_YOU_SURE_THAT_YOU_WANT_TO_START_REST'
        };
        this.dialogConfig.panelClass = 'yes-no-modal-container';
        this.dialogRef = this.dialog.open(YesNoModalComponent, this.dialogConfig);
        this.dialogRef.afterClosed().subscribe(data => {
          if (data === 'YES') {
            this.amelia.startRest(req.id);
          }
          this.dialogConfig = {};
        });
        break;

      default:
        break;
    }
  }

  stop(req) {
    switch (req.type) {
      // washing
      case 3:
        this.dialogConfig.data = {
          title: 'ARE_YOU_SURE_THAT_YOU_WANT_TO_COMPLETE_WASHING'
        };
        this.dialogConfig.panelClass = 'yes-no-modal-container';
        this.dialogRef = this.dialog.open(YesNoModalComponent, this.dialogConfig);
        this.dialogRef.afterClosed().subscribe(data => {
          if (data === 'YES') {
            this.amelia.completeWashing(req.vehicleWashing.id);
          }
          this.dialogConfig = {};
        });
        break;
      // rest
      case 6:
        this.dialogConfig.data = {
          title: 'ARE_YOU_SURE_THAT_YOU_WANT_TO_COMPLETE_REST'
        };
        this.dialogConfig.panelClass = 'yes-no-modal-container';
        this.dialogRef = this.dialog.open(YesNoModalComponent, this.dialogConfig);
        this.dialogRef.afterClosed().subscribe(data => {
          if (data === 'YES') {
            this.amelia.completeRest(req.driverRest.id);
          }
          this.dialogConfig = {};
        });
        break;

      default:
        break;
    }
  }

  cancel(req) {
    console.log('TCL: RequestPanelComponent -> stop -> req', req);
    switch (req.type) {
      // washing
      case 3:
        this.dialogConfig.data = {
          title: 'ARE_YOU_SURE_THAT_YOU_WANT_TO_CANCEL_WASHING'
        };
        this.dialogConfig.panelClass = 'yes-no-modal-container';
        this.dialogRef = this.dialog.open(YesNoModalComponent, this.dialogConfig);
        this.dialogRef.afterClosed().subscribe(data => {
          if (data === 'YES') {
            this.amelia.cancelWashing(req.vehicleWashing.id);
          }
          this.dialogConfig = {};
        });
        break;
      // rest
      case 6:
        this.dialogConfig.data = {
          title: 'ARE_YOU_SURE_THAT_YOU_WANT_TO_COMPLETE_REST'
        };
        this.dialogConfig.panelClass = 'yes-no-modal-container';
        this.dialogRef = this.dialog.open(YesNoModalComponent, this.dialogConfig);
        this.dialogRef.afterClosed().subscribe(data => {
          if (data === 'YES') {
            this.amelia.completeRest(req.driverRest.id);
          }
          this.dialogConfig = {};
        });
        break;

      default:
        break;
    }
  }

  private updateTimer(id: string, reqSeconds): void {
    const minutes = moment().diff(moment.unix(reqSeconds), 'minutes');
    let seconds = moment()
      .diff(moment.unix(reqSeconds).add(minutes, 'minutes'), 'seconds')
      .toString();
    if (seconds.length === 1) {
      seconds = `0${seconds}`;
    }
    this.reqTimers[id] = `${minutes}:${seconds}`;
  }
}
