import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FallbackStatusesInfoModel, StatusesInfoModel } from '../../models';
import {GlobalDataService} from '@global-services/global-data.service';
import {DispSettingsService} from "../../services";

@Component({
  selector: 'utax-disp-settings-controls-panel',
  templateUrl: './disp-settings-controls-panel.component.html',
  styleUrls: ['./disp-settings-controls-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispSettingsControlsPanelComponent implements OnInit {
  public statusesLocal: StatusesInfoModel;
  @Input() set statuses(val: StatusesInfoModel) {
    this.statusesLocal = this.transformBrigades(val);
  }
  @Input() fallbackStatuses: FallbackStatusesInfoModel;
  @Input() mode: string;
  @Input() filterForm: UntypedFormGroup;
  @Output() create = new EventEmitter();
  @Output() search: EventEmitter<string> = new EventEmitter();
  toggle;
  @Input() isFullView: boolean;
  @Input() isExtendedView!: boolean | null;
  constructor(private router: Router,
              private globalDataService: GlobalDataService,
              public dispSettingsService: DispSettingsService
  ) {}

  ngOnInit(): void {
    this.toggle = this.router.url.split('/').pop();
  }

  createClick(): void {
    this.create.emit();
  }

  private transformBrigades(val: StatusesInfoModel) {
    const statuses = val;
    const brigades = this.globalDataService.globalBrigades$.getValue();
    if (statuses) {
      statuses.brigades = statuses?.brigades?.map((brigade) => {
        brigade.name = brigades.find((b) => b.id === brigade.id)?.name;
        return brigade;
      });
    }
    return statuses;
  }
}
