import { Component, OnChanges, OnDestroy, Input } from '@angular/core';
import { Tariff } from '../../models/tariff.model';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject, of } from 'rxjs';
import { NewDriverBonusesService } from '../../services';
import { HandleEventService } from 'src/app/services';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { YesNoModalComponent } from 'src/app/shared/components';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'utax-new-driver-bonuses-tab',
  templateUrl: './new-driver-bonuses-tab.component.html',
  styleUrls: ['./new-driver-bonuses-tab.component.scss']
})
export class NewDriverBonusesTabComponent implements OnChanges, OnDestroy {
  @Input() service: number; // selected service
  @Input() tariff: Tariff; // selected driver tariff obj
  @Input() listOfTariffs: Tariff[];

  form: UntypedFormGroup;
  bonusesListFormArray: UntypedFormArray;
  sortedListOfTariffs: Tariff[];
  selectedBonusIndex: number;
  bonusesList: any[] = [];
  private dialogRef: MatDialogRef<YesNoModalComponent>;
  private componentDestroyed$: Subject<any> = new Subject();
  constructor(
    private fb: UntypedFormBuilder,
    private newDriverBonusesService: NewDriverBonusesService,
    private handleEventService: HandleEventService,
    private dialog: MatDialog
  ) {
    this.bonusesListFormArray = this.fb.array([this.initControls()]);
    this.initForm();
  }

  ngOnChanges(): void {
    this.sortedListOfTariffs = [...this.listOfTariffs];
    this.sortedListOfTariffs.sort((a, b) => {
      return this.tariff && a.id !== this.tariff.id ? 1 : -1;
    });
    if (this.service) {
      this.newDriverBonusesService.getListOfBonuses(this.service).subscribe(bonuses => {
        this.bonusesList = bonuses;
        this.clearFormArray();
        this.buildBonusesFormArray(bonuses);
      });
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  saveBonus(index: number): void {
    const bonusValue = this.bonusesListFormArray.value[index];
    if (bonusValue.id) {
      // update
      this.newDriverBonusesService
        .putBonus({
          id: bonusValue.id,
          name: bonusValue.name,
          value: bonusValue.value,
          tariff_plan_ids: bonusValue.tariff_plan_ids
        })
        .subscribe(data => {
          this.bonusesList = [
            ...this.bonusesList.map(item => {
              return item.id === data.id ? data : item;
            })
          ];
          this.clearFormArray();
          this.buildBonusesFormArray(this.bonusesList);
          this.handleEventService.openSnackBar('NEW_DRIVER_BONUS_UPDATED');
        });
    } else {
      // create
      this.newDriverBonusesService
        .postBonus({
          service_id: this.service,
          name: bonusValue.name,
          value: bonusValue.value,
          tariff_plan_ids: bonusValue.tariff_plan_ids
        })
        .subscribe(data => {
          this.bonusesList = [...this.bonusesList, data];
          this.clearFormArray();
          this.buildBonusesFormArray(this.bonusesList);
          this.handleEventService.openSnackBar('NEW_DRIVER_BONUS_CREATED');
        });
    }
  }

  get bonusesArray() {
    return this.form.controls.bonuses as UntypedFormArray;
  }

  deleteBonus(index: number): void {
    const bonusId = this.bonusesListFormArray.controls[index].value.id;
    if (bonusId) {
      const config = new MatDialogConfig();
      config.data = {
        title: 'UTAX_FRONT_REMOVE_BONUS?'
      };
      config.panelClass = 'yes-no-modal-container';
      this.dialogRef = this.dialog.open(YesNoModalComponent, config);
      this.dialogRef
        .afterClosed()
        .pipe(
          mergeMap(data => {
            if (data === 'YES') {
              return this.newDriverBonusesService.deleteBonus(bonusId);
            } else {
              return of();
            }
          })
        )
        .subscribe((res: any) => {
          this.bonusesList = [...this.bonusesList.filter(item => item.id !== bonusId)];
          this.clearFormArray();
          this.buildBonusesFormArray(this.bonusesList);
          this.select(this.bonusesList.length - 1);
          this.handleEventService.openSnackBar('NEW_DRIVER_BONUS_DELETED');
        });
    } else {
      this.bonusesArray.removeAt(index);
    }
  }

  select(index: number): void {
    this.selectedBonusIndex = index;
  }

  addNewBonuse(): void {
    this.bonusesListFormArray.push(this.initControls());
    this.select(this.bonusesListFormArray.length - 1);
  }

  private buildBonusesFormArray(data): void {
    if (data && this.bonusesListFormArray) {
      data.forEach((el, i) => {
        this.bonusesListFormArray.setControl(i, this.initControls(el));
      });
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      bonuses: this.bonusesListFormArray
    });
  }

  private initControls(el?): UntypedFormGroup {
    return this.fb.group({
      id: [el ? el.id : null],
      name: [el ? el.name : ''],
      value: [el ? el.value : '', Validators.required],
      tariff_plan_ids: [el ? el.tariff_plan_ids : [''], Validators.required]
    });
  }

  private clearFormArray(): void {
    if (this.bonusesListFormArray) {
      this.bonusesListFormArray.controls = [];
      this.bonusesListFormArray.setControl(0, this.initControls());
    }
  }
}
