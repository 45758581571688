import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasOngoingPauses'
})
export class HasOngoingPausesPipe implements PipeTransform {

  transform(cab: any): boolean {
    return (cab.pauses || []).some(pause => pause.status === 1);
  }

}
