import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { timer, Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'utax-pause-timer',
  templateUrl: './pause-timer.component.html',
  styleUrls: ['./pause-timer.component.scss']
})
export class PauseTimerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() cab;
  @Input() cabAutomationPausedAt;
  minutes = 0;

  private componentDestroyed$ = new Subject();

  constructor() {}

  ngOnInit(): void {
    if (this.cabAutomationPausedAt) {
      this.minutes = moment().diff(moment.utc(this.cabAutomationPausedAt), 'minutes');
    }
    if (this.cab.is_automation_paused && this.cabAutomationPausedAt) {
      interval(2000)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(() => {
          this.minutes = moment().diff(moment.utc(this.cabAutomationPausedAt), 'minutes');
        });
    }
  }

  ngOnChanges(): void {}

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }
}
