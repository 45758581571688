import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../utils/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

// containers
import * as fromContainers from './containers';

// components
import * as fromComponents from './components';

// services
import * as fromSerivces from './services';

@NgModule({
  declarations: [...fromContainers.containers, ...fromComponents.components],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    RouterModule,
    SharedModule
  ],
  exports: [...fromContainers.containers, ...fromComponents.components],
  providers: [...fromSerivces.services]
})
export class NavigationModule {}
