export function calcDist(firstPoint, secondPoint) {
  const R = 6371; // km
  function toRad(Value) {
    return (Value * Math.PI) / 180;
  }
  const dLat = toRad(secondPoint.lat - firstPoint.lat);
  const dLng = toRad(secondPoint.lng - firstPoint.lng);
  const lat1 = toRad(firstPoint.lat);
  const lat2 = toRad(secondPoint.lat);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
}
