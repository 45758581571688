import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';

@Component({
  selector: 'utax-order-debt-info',
  templateUrl: './order-debt-info.component.html',
  styleUrls: ['./order-debt-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDebtInfoComponent implements OnInit {

  @Input() public debtSum: number;
  @Input() public orderSum: number;
  @Input() public debtState: 'debt' | 'debt-added' | 'overpayment' | 'no-debt' = 'no-debt';

  constructor() { }

  ngOnInit(): void {}

}
