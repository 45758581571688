import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DispSettingsApiService, DispSettingsService } from '../../services';
import { RemoteWorkApiService } from '../../../../../cabinet/services';
import { delayWhen, map, retryWhen, switchMap, takeUntil } from 'rxjs/operators';
import { forkJoin, Observable, of, Subject, timer } from 'rxjs';
import {GlobalDataService} from '@global-services/global-data.service';

@Component({
  selector: 'utax-disp-settings-remote',
  templateUrl: './disp-settings-remote.component.html',
  styleUrls: ['./disp-settings-remote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispSettingsRemoteComponent implements OnInit, OnDestroy {
  remoteRequests: any[] = [];
  sortParam = {type: 'sort', value: ['name,']};

  private componentDestroyed$ = new Subject();

  constructor(
    public dispSettingsService: DispSettingsService,
    private dispSettingsApiService: DispSettingsApiService,
    private remoteWorkApiService: RemoteWorkApiService,
    private globalDataService: GlobalDataService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    timer(0, 5000)
      .pipe(
        takeUntil(this.componentDestroyed$),
        switchMap(() => this.getTableData()),
      )
      .subscribe(
        ([dispRemote, dispRequest, users]) => {
          this.remoteRequests = this.getRemoteRequestData(dispRemote.content, dispRequest.content, users);
          this.changeDetectorRef.detectChanges();
        }
      );
  }

  public sortDisp(params: string[]): void {
    this.sortParam = {type: 'sort', value: params};
    this.getTableData()
      .pipe(
        takeUntil(this.componentDestroyed$),
      )
      .subscribe(([dispRemote, dispRequest, users]) => {
        this.remoteRequests = this.getRemoteRequestData(dispRemote.content, dispRequest.content, users);
        this.changeDetectorRef.detectChanges();
      });
  }

  getTaxiServices(servicesIds: string[], activeTaxiServiceIds: string[]): any {
    const services = JSON.parse(JSON.stringify(this.globalDataService.globalTaxiServices$.value));
    const newServices = services
      .filter(s => servicesIds.find(ser => ser === s.id))
      .map(s => {
        if (activeTaxiServiceIds.findIndex(k => k === s.id) > -1) {
          s.active = true;
        } else {
          s.active = false;
        }
        s.loading = false;
        return s;
      }) || [];
    return newServices;
  }

  getActiveCallLines(callLineIds) {
    const lines = JSON.parse(JSON.stringify(this.globalDataService.globalCallLines$.value));
    const data = {};
    lines.forEach(s => {
      const key = s.type.toLowerCase();
      data[key] = {};
      if (callLineIds.findIndex(k => k === s.id) > -1) {
        data[key].active = true;
      } else {
        data[key].active = false;
      }
      data[key].id = s.id;
      data[key].loading = false;
    });
    return data;
  }

  getRemoteRequestData(dispRemote: any[], dispRequest: any[], users: any[]): any {
    dispRemote = dispRemote.filter(disp => !users.find(user => user.user.id === disp.externalId));

    const response = [...dispRequest, ...dispRemote];
    return response.map(disp => {
      return {
        ...disp,
        remoteWork: users.find(user => user.user.id === disp.externalId),
        activeCallLines: this.getActiveCallLines(disp?.callLineIds),
        remote: (disp.status.remoteWorkStatus === 'REMOTELY' ? true : false),
        taxiServices: this.getTaxiServices(disp?.taxiServiceIds, disp.activeTaxiServiceIds)
      };
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  updateData(): void {
    this.remoteWorkApiService.getRemoteWorkList()
      .pipe(
        takeUntil(this.componentDestroyed$),
        map(data => {
          const userIds = data.reduce((acc, request) => {
            request.created_at = request.created_at + 'Z';
            acc.push(request.user.id);
            return acc;
          }, []);
          return {data, userIds};
        }),
        switchMap(({data, userIds}) => forkJoin([this.dispSettingsApiService.putGetUsersByIds({sort: this.sortParam.value, onlyRemotely: true}), of(userIds), of(data)])),
        switchMap(([dispRemote, userIds, data]) => forkJoin([of(dispRemote), this.dispSettingsApiService.putGetUsersByIds({sort: this.sortParam.value, externalId: userIds}), of(data)])),
      )
      .subscribe(([dispRemote, dispRequest, users]) => {
        this.remoteRequests = this.getRemoteRequestData(dispRemote.content, dispRequest.content, users);
        this.changeDetectorRef.detectChanges();
      });
  }

  checkboxUpdate({id, callLineId, name, allow, index}): void {
    this.updateCheckBox(name, allow, true, index);

    this.dispSettingsApiService.putUserCheckbox(callLineId, id, allow)
      .pipe(
        takeUntil(this.componentDestroyed$),
      )
      .subscribe(
        () => {
          this.updateCheckBox(name, allow, false, index);
        },
        () => {
          this.updateCheckBox(name, !allow, false, index);
        }
      );
  }

  queueGroupUpdate({ uuid, active, groupId, externalGroupId, dispIndex, externalId }): void {
    this.updateServiceValue(groupId, active, true, dispIndex);

    this.dispSettingsApiService.putQueueGroup(groupId, uuid, active, externalGroupId, externalId)
      .pipe(
        takeUntil(this.componentDestroyed$),
      )
      .subscribe(
        () => {
          this.updateServiceValue(groupId, active, false, dispIndex);
        },
        () => {
          this.updateServiceValue(groupId, !active, false, dispIndex);
        }
      );
  }

  remoteUpdate({ uid, index }): void {
    this.remoteWorkApiService.postRemoteWorkRevoke({user_id: uid})
      .pipe(
        takeUntil(this.componentDestroyed$),
      )
      .subscribe(
        () => {
          this.updateData();
        },
        () => {
          this.updateData();
        }
      );
  }

  approveRemoteWork(id: string): void {
    this.remoteWorkApiService.postRemoteWorkApprove(id)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this.updateData());
  }

  rejectRemoteWork(id: string): void {
    this.remoteWorkApiService.postRemoteWorkReject(id)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this.updateData());
  }

  private updateServiceValue(
    id: string,
    active: boolean,
    loading: boolean,
    index: number
  ): void {
    this.remoteRequests = JSON.parse(JSON.stringify(
      this.remoteRequests
        .map((disp, i) => {
          if (i === index) {
            disp.taxiServices = disp.taxiServices
              .map(service => {
                if (service.id === id) {
                  service.active = active;
                  service.loading = loading;
                }
                return service;
              });
          }
          return disp;
        })
    ));
    this.changeDetectorRef.detectChanges();
  }

  private updateCheckBox(
    key: string,
    active: boolean,
    loading: boolean,
    index: number
  ): void {
    this.remoteRequests = this.remoteRequests
      .map((disp, i) => {
        if (i === index) {
          disp.activeCallLines[key].active = active;
          disp.activeCallLines[key].loading = loading;
        }
        return disp;
      });
    this.changeDetectorRef.detectChanges();
  }

  private getTableData(): Observable<any> {
    return this.remoteWorkApiService.getRemoteWorkList().pipe(
      map(data => {
        const userIds = data.reduce((acc, request) => {
          request.created_at = request.created_at + 'Z';
          acc.push(request.user.id);
          return acc;
        }, []);
        return {data, userIds};
      }),
      switchMap(({data, userIds}) => forkJoin([this.dispSettingsApiService.putGetUsersByIds({sort: this.sortParam.value, onlyRemotely: true}), of(userIds), of(data)])),
      switchMap(([dispRemote, userIds, data]) => forkJoin([of(dispRemote), this.dispSettingsApiService.putGetUsersByIds({sort: this.sortParam.value, externalId: userIds}), of(data)])),
      retryWhen(errors =>
        errors.pipe(
          delayWhen(val => timer(10000))
        )
      )
    );
  }
}
