import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class NgOnDestroy extends Subject<null> implements OnDestroy {
  ngOnDestroy() {
    this.next(null);
    this.complete();
  }
}

// import { NgOnDestroy } from 'app/core/services';
// @Component({
//   providers: [NgOnDestroy]
// })
// export class Component {
//   constructor(private ngOnDestroy$: NgOnDestroy) {}
//   function() {
//     Observable
//       .pipe(takeUntil(this.ngOnDestroy$))
//       .subscribe()
//   }
// }
