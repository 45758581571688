import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {StateService} from "../../services";
import {map, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UsersInsideGuard implements CanActivate {
  public isPermission = false;
  private allowedPermissions = ['operator_users_view_any', 'operator_users_update'];

  constructor(private stateService: StateService,
              private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.stateService.getStoreParamSub('permissions').pipe(
      tap(permissions => this.isPermission = !!permissions.length),
      map(permissions => permissions.some(permission => this.allowedPermissions.includes(permission.name))),
      tap((hasPerm) => !hasPerm && this.isPermission ? this.router.navigate(['/']) : null),
    );
  }
}
