import { Pipe, PipeTransform } from '@angular/core';
import { CabTarget } from 'src/app/interfaces/target.interface';

@Pipe({
  name: 'isRobotReq',
  pure: false
})
export class IsRobotReqPipe implements PipeTransform {
  transform(targets: CabTarget[], req?: any): boolean {
    if (!targets) {
      return false;
    }
    if (targets && targets.length === 0) {
      return false;
    }
    if (req && (req.type === 2 || req.type === 3)) {
      // washing or refill req type
      return targets.some(target => target.type === 'fuel_station' || target.type === 'washer');
    }
    return null;
  }
}
