export interface QueueGroupModel {
  name: string;
  uuid: string;
  external_uuid?: string;
  first_contact?: boolean;
}

export interface QueueModel {
  name: string;
  id?: string;
  transferBack?: boolean;
  hasActiveRequest?: boolean;
  transferId?: string;
  taxiServiceId?: string;
  callLineId?: string;
  strategyId?: string;
  priority?: number;
  maxWaitingTime?: number;
  productId?: string;
  createdAt?: Date | string;
  updatedAt?: Date | string;
}

export interface QueuePresetModel {
  presets: {
    announce: string;
    announce_frequency: number;
    announce_holdtime: string;
    announce_position: string;
    announce_position_limit: number;
    announce_round_seconds: number;
    announce_to_first_user: QueuePresetNoYes;
    autofill: QueuePresetNoYes;
    autopause: QueuePresetAllNoYes;
    autopausebusy: QueuePresetNoYes;
    autopausedelay: number;
    autopauseunavail: QueuePresetNoYes;
    context: string;
    defaultrule: string;
    joinempty: string;
    leavewhenempty: string;
    maxlen: number;
    memberdelay: number;
    membergosub: string;
    membermacro: string;
    min_announce_frequency: number;
    monitor_format: string;
    monitor_type: string;
    musiconhold: string;
    penaltymemberslimit: number;
    periodic_announce: string;
    periodic_announce_frequency: number;
    queue_callerannounce: string;
    queue_callswaiting: string;
    queue_holdtime: string;
    queue_minute: string;
    queue_minutes: string;
    queue_quantity1: string;
    queue_quantity2: string;
    queue_reporthold: string;
    queue_seconds: string;
    queue_thankyou: string;
    queue_thereare: string;
    queue_youarenext: string;
    random_periodic_announce: QueuePresetNoYes;
    relative_periodic_announce: QueuePresetNoYes;
    reportholdtime: QueuePresetNoYes;
    retry: number;
    ringinuse: QueuePresetNoYes;
    servicelevel: number;
    setinterfacevar: QueuePresetNoYes;
    setqueueentryvar: QueuePresetNoYes;
    setqueuevar: QueuePresetNoYes;
    strategy: QueuePresetStrategy;
    timeout: number;
    timeoutpriority: string;
    timeoutrestart: QueuePresetNoYes;
    weight: number;
    wrapuptime: number;
  };
  type: QueuePresetType;
  uuid: string;
}

export interface QueueOptionModel {
  algorithm: QueueOptionAlgorithm;
  max_operators: number;
  slow_start_delay: number;
  stickness: QueueOptionStickness;
  uuid: string;
}

export interface QueuePriorityModel {
  name: string;
  priority: number;
  uuid: string;
}

export interface QueueServiceModel {
  active: boolean;
  name: string;
  uuid: string;
  external_uuid: string;
}

export enum QueueCallType {
  INCOMING_FROM_DRIVERS = 'INCOMING_FROM_DRIVERS',
  INCOMING_FROM_NEW_CLIENTS = 'INCOMING_FROM_NEW_CLIENTS',
  INCOMING_FROM_PASSENGER = 'INCOMING_FROM_PASSENGER',
  INTERNAL = 'INTERNAL',
  // CALL_FROM_DRIVERS = 'CALL_FROM_DRIVERS',
  // CALL_FROM_NEW_CLIENTS = 'CALL_FROM_NEW_CLIENTS',
  // REGULAR_CALL = 'REGULAR_CALL',
  // SYSTEM_CALL = 'SYSTEM_CALL'
}

export enum QueueOrderStatus {
  ACTIVE_REQUEST = 'ACTIVE_REQUEST',
  NO_ACTIVE_REQUEST = 'NO_ACTIVE_REQUEST'
}

export enum QueueOptionAlgorithm {
  FEWEST_CALLS = 'FEWEST_CALLS',
  LEAST_RECENT = 'LEAST_RECENT',
  LINEAR = 'LINEAR',
  RANDOM = 'RANDOM',
  RING_ALL = 'RING_ALL',
  RR_MEMORY = 'RR_MEMORY',
  RR_ORDERED = 'RR_ORDERED',
  W_RANDOM = 'W_RANDOM'
}

export enum QueuePresetType {
  CLIENTS_WITHOUT_REQUEST = 'CLIENTS_WITHOUT_REQUEST',
  CLIENTS_WITH_REQUEST = 'CLIENTS_WITH_REQUEST',
  DEFAULT = 'DEFAULT',
  DRIVERS_WITHOUT_REQUEST = 'DRIVERS_WITHOUT_REQUEST',
  DRIVERS_WITH_REQUEST = 'DRIVERS_WITH_REQUEST',
  FIRST_TIME = 'FIRST_TIME',
  GLOBAL_FIRST_TIME = 'GLOBAL_FIRST_TIME'
}

export enum QueuePresetStrategy {
  FEWESTCALLS = 'FEWESTCALLS',
  LEASTRECENT = 'LEASTRECENT',
  LINEAR = 'LINEAR',
  RANDOM = 'RANDOM',
  RINGALL = 'RINGALL',
  RRMEMORY = 'RRMEMORY',
  RRORDERED = 'RRORDERED',
  WRANDOM = 'WRANDOM'
}

export enum QueuePresetNoYes {
  NO = 'NO',
  YES = 'YES'
}

export enum QueuePresetAllNoYes {
  ALL = 'ALL',
  NO = 'NO',
  YES = 'YES'
}

export enum QueueOptionStickness {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED'
}

export enum QueueTableMode {
  VIEW,
  EDIT,
  CREATE
}
