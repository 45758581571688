import { Pipe, PipeTransform } from '@angular/core';

interface DisObj {
  distance: number;
  polyline: string;
}

@Pipe({
  name: 'summDistances'
})
export class SummDistancesPipe implements PipeTransform {
  transform(disObjs: DisObj[]): number {
    const result = (disObjs || []).reduce((acc: any, curr: any) => {
      return acc + curr.distance;
    }, 0);

    return result;
  }
}
