import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'getDiffInTimeBetween'
})
export class GetDiffInTimeBetweenPipe implements PipeTransform {
  transform(firstTime: string, secondTime: string, format: 'minutes'): unknown {
    if (firstTime && secondTime) {
      return moment(firstTime).diff(secondTime, format);
    } else {
      return '-';
    }
  }
}
