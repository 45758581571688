import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {OperatorCommentService} from "../../services/operator-comment.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {tap} from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: 'utax-history-operator-comment',
  templateUrl: './history-operator-comment.component.html',
  styleUrls: ['./history-operator-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryOperatorCommentComponent implements OnInit {
  public isNewMessage: boolean;
  private isInit = false;
  public elem: any;
  private _selectedId: string;
  @Input() public set selectedId(value: string) {
    this._selectedId = value;
    this.init();
  }
  get selectedId(): string {
    return this._selectedId;
  }

  constructor(
    public operatorCommentService: OperatorCommentService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

  }

  private init() {
    this.isInit = false;
    this.isNewMessage = false;
    this.cdr.detectChanges();
    this.operatorCommentService.currentOrderComments$.pipe(
      tap((comments) => {
        setTimeout(() => {
          this.elem = document.getElementsByClassName('mat-tab-body-content')[2];
          if (this.elem && !this.isInit && comments.length) {
            this.elem.scrollTop = this.elem.scrollHeight;
            this.isInit = true;
          } else if(comments.length) {
            if (this.elem && this.elem.scrollTop + this.elem.offsetHeight !== this.elem.scrollHeight) {
              this.isNewMessage = true;
              this.cdr.detectChanges();
              this.scrollingContent();
            }
          }
        }, 0);
      }),
      untilDestroyed(this)
    ).subscribe();
  }


 public scrollingContent(): void {
   const onScrollToBottom = document.getElementsByClassName('message-item-comment');
   if (!onScrollToBottom?.length) { return; }
   const index = onScrollToBottom.length - 1;

   const io = new IntersectionObserver((entries) => {
     const { isIntersecting } = entries[0];
     if (isIntersecting) {
       this.isNewMessage = false;
       this.cdr.detectChanges();
     }

   }, {threshold: 1, root: this.elem,  rootMargin: '0px 0px -50px 0px'});

   io.observe(onScrollToBottom[index]);
  }

  scrollToBottom() {
    this.elem.scrollTo({
      top: this.elem.scrollHeight,
      behavior: 'smooth',
    });
    this.isNewMessage = false;
    this.cdr.detectChanges();
  }
}
