import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PassengerBlacklistComponent } from './containers';


// containers
import * as fromContainers from './containers';

// components
import * as fromComponents from './components';
import {MaterialModule} from "../../../utils/material.module";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../../../shared/shared.module";
import {RouterModule, Routes} from "@angular/router";
import {NgxMaskModule} from "ngx-mask";
import {InfiniteScrollModule} from "ngx-infinite-scroll";


const ROUTES: Routes = [
  {
    path: '',
    component: PassengerBlacklistComponent
  }
];

@NgModule({
  declarations: [
    ...fromContainers.containers,
    ...fromComponents.components,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    RouterModule.forChild(ROUTES),
    NgxMaskModule.forChild(),
    InfiniteScrollModule
  ]
})
export class PassengerBlacklistModule { }
