export interface BaseRemoteWorkModel {
  id: string;
  status: RemoteWorkRequestStatus;
}

export interface RemoteWorkStateModel {
  status: RemoteWorkOperatorStatus;
  is_remote_allowed: boolean;
  last_remote_work_request: LastRemoteWorkModel;
}

export interface LastRemoteWorkModel extends BaseRemoteWorkModel {
  created_at: Date | string;
  updated_at: Date | string;
}

export interface RemoteWorkRequestModel extends BaseRemoteWorkModel {
  created_at: Date | string;
  ip: string;
  user: RemoteWorkUserModel;
}

export interface RemoteWorkUserModel {
  id: string;
  name: string;
}

export enum RemoteWorkOperatorStatus {
  ready = 'ready',
  remote_work_request_required = 'remote_work_request_required',
  not_allowed = 'not_allowed',
}

export enum RemoteWorkRequestStatus {
  revoked = 'revoked',
  rejected = 'rejected',
  approved = 'approved',
  processing = 'processing'
}
