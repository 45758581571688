import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PassengerBlacklistApiService} from "../../services/passenger-blacklist-api.service";
import {UntilDestroy} from "@ngneat/until-destroy";
import {finalize, tap} from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: 'utax-passenger-blcklist-unlock-modal',
  templateUrl: './passenger-blcklist-unlock-modal.component.html',
  styleUrls: ['./passenger-blcklist-unlock-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PassengerBlcklistUnlockModalComponent implements OnInit {
  public disabledApproveButton: boolean;


  constructor(
    public dialogSelf: MatDialogRef<PassengerBlcklistUnlockModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { id: string },
    private passengerBlacklistApiService: PassengerBlacklistApiService
  ) { }

  ngOnInit(): void {
  }

  approve() {
    this.disabledApproveButton = true;
    this.passengerBlacklistApiService.unlockPassenger(this.data.id)
      .pipe(
        tap(() => this.dialogSelf.close(true)),
        finalize(() => {
          this.disabledApproveButton = false;
        })
      )
      .subscribe();
  }
}
