import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'utax-archive-details-toolbar',
  templateUrl: './archive-details-toolbar.component.html',
  styleUrls: ['./archive-details-toolbar.component.scss']
})
export class ArchiveDetailsToolbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
