import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'getDiffInTime'
})
export class GetDiffInTimePipe implements PipeTransform {

  transform(seconds: any, changeIndicator: number): any {
    const hours = moment().diff(moment.unix(seconds), 'hours');
    const minutes = moment().diff(moment.unix(seconds).add(hours, 'hours'), 'minutes');
    return `${hours} ч ${minutes} мин`;
  }

}
