import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DispSalarySettingModel, DispSalarySettingActivityModel, IDispCategory } from '../models';

const GET_SALARY_URL = 'call-centre/api/salary-settings';
const GET_CLIENT_CATEGORIES = 'call-centre/api/client-categories';
const CREATE_SALARY_URL = 'call-centre/api/salary-settings';
const UPDATE_SALARY_URL = 'call-centre/api/salary-settings';

@Injectable()
export class DispSalaryApiService {

  constructor(private httpClient: HttpClient) {}

  // GET

  getClientCategories(): Observable<IDispCategory[]> {
    return this.httpClient.get<IDispCategory[]>(GET_CLIENT_CATEGORIES);
  }

  getSalarySettings(limit: number, page: number): Observable<DispSalarySettingModel[]> {
    return this.httpClient.get<DispSalarySettingModel[]>(GET_SALARY_URL);
  }

  getSalarySettingsByGroupUuuid(limit: number, page: number, groupUuid: string): Observable<DispSalarySettingModel[]> {
    return this.httpClient
      .get<DispSalarySettingModel[]>(`call-centre/api/salary-settings?taxiServiceId=${groupUuid}`);
  }

  getSalarySettingsActivity(limit: number, page: number, salarySettingsUid: string): Observable<DispSalarySettingActivityModel[]> {
    const params = new HttpParams()
      .append('limit', String(limit))
      .append('page', String(page));
    return this.httpClient
      .get<DispSalarySettingActivityModel[]>(`call-centre/api/salary-settings/activity/${salarySettingsUid}`, { params });
  }

  // POST

  postSalarySetting(setting: DispSalarySettingModel): Observable<DispSalarySettingModel> {
    return this.httpClient.post<DispSalarySettingModel>(CREATE_SALARY_URL, setting);
  }

  // PUT

  putSalarySetting(setting: DispSalarySettingModel, salarySettingsUid: string): Observable<DispSalarySettingModel> {
    return this.httpClient.put<DispSalarySettingModel>(`${UPDATE_SALARY_URL}/${salarySettingsUid}`, setting);
  }

  // DELETE

  deleteSalarySetting(salarySettingsUid: string): Observable<void> {
    return this.httpClient
      .delete<void>(`call-centre/api/salary-settings/${salarySettingsUid}`);
  }
}
