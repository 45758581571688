import { HistoryService } from '@global-services/history.service';
import { Component, Input, ChangeDetectorRef, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import {OrderClass} from '@global-classes/order.class';
import {utc} from "moment";

@Component({
  selector: 'utax-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnChanges, OnDestroy {
  @Input() order: OrderClass;

  history: any[] = [];
  private oldOrder = { id: null, updatedAt: '' };

  private componentDestroyed$ = new Subject();

  constructor(private historyService: HistoryService, private cd: ChangeDetectorRef) {
    this.historyService.orderHistory$.pipe(takeUntil(this.componentDestroyed$)).subscribe(history => {
      // this.history = _.uniqWith(history, (current, next) => current.id === next.id);
      this.history = history.filter((item) => !item?.status ||
        item?.status && (item.status !== 'accepted' && item.status !== 'canceled') && item?.driver_callsign);
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    // rewrite to distinctUntillChanged
    if (this.order) {
      this.historyService.forceUpdate(this.order.id, this.order.trip_id);
      this.oldOrder = { id: this.order.id, updatedAt: this.order.updated_at };
    } else {
      this.history = [];
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  getCauser(history, i) {
    if (!history.causer && history[i].type) {
      this.historyService.getCauser(history[i].id).subscribe((res: any) => {
        this.history[i].causer = res.data;
        this.cd.detectChanges();
      });
    } else {
      this.history[i].causer = {callsign: this.history[i].driver_callsign};
    }
  }

  protected readonly utc = utc;
  protected readonly isNaN = isNaN;
}
