import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getStatusOfCab'
})
export class GetStatusOfCabPipe implements PipeTransform {

  transform(cab: any): any {
    let status = '';
    if (cab.requests && cab.requests.length > 0) {
      cab.requests.forEach(req => {
        if (req.status === 'in_progress') {
          switch (req.type) {
            case 3: // washing
              status = 'washing';
              break;

            case 6: // rest
              status = 'rest';
              break;

            default:
              break;
          }
        }
      });
    }
    if (cab.order) {
      status = cab.order.status;
    }
    return status;
  }

}
