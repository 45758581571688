import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByPredicate'
})
export class SortByPredicatePipe implements PipeTransform {
  transform<U extends any>(nodes: U[] = [],
                           predicate: (a: U, b: U) => number): U[] | undefined {
    return nodes ? nodes.sort((a, b) => predicate(a, b)) : undefined;
  }
}
