import { BasicUserModel } from '../../../../cabinet/models';

export interface IDispCategory {
  id: string;
  type: string;
  name?: string;
}

export interface DispSalarySettingBasicModel {
  amount: number;
  category: DispSalarySettingCategory;
  queue_type: DispSalaryQueueType;
  queue_uid?: string;
  remote: boolean;
  required_call_time: number;
}

export interface DispSalarySettingModel {
  id: string;
  operatorCategoryId: string;
  clientCategoryId: string;
  minTalkDuration: 0;
  isRemoteWork: boolean;
  amount: number;
  queueId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface DispSalarySettingActivityModel {
  from_state: Value;
  localized_description: string;
  salary_settings: DispSalarySettingModel;
  to_state: Value;
  type: DispSalarySettingActivityType;
  user: BasicUserModel;
}

interface Value {
  value: {
    [key: string]: string;
  };
}

export enum DispSalarySettingCategory {
  JUNIOR = 'JUNIOR',
  REGULAR = 'REGULAR',
  SENIOR = 'SENIOR'
}

export enum DispSalaryQueueType {
  drivers = 'drivers',
  new_clients = 'new_clients',
  // new_clients_global = 'new_clients_global',
  passengers = 'passengers'
}

export enum DispSalarySettingActivityType {
  CHANGE_AMOUNT = 'CHANGE_AMOUNT',
  CHANGE_CATEGORY = 'CHANGE_CATEGORY',
  CHANGE_REMOTE = 'CHANGE_REMOTE',
  CHANGE_REQUIRED_CALL_TIME = 'CHANGE_REQUIRED_CALL_TIME',
  CREATE = 'CREATE',
  DELETE = 'DELETE'
}

export enum DispSalarySettingMode {
  INDIVIDUAL,
  BASIC
}

export enum DispSalaryTableMode {
  VIEW,
  EDIT,
  ADD
}
