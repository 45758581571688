import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// containers
import * as fromContainers from './containers';
import * as fromModules from './index';

import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../utils/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { UsersPageComponent } from './containers';
import { UsersComponent } from './users/containers';
import { RolesComponent } from './roles/containers';
import { SharedModule } from '../shared/shared.module';
import {UsersInsideGuard} from "./guards/users-inside.guard";
import {RolesInsideGuard} from "./guards/roles-inside.guard";

const ROUTES: Routes = [
  {
    path: '',
    component: UsersPageComponent,
    children: [
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full'
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [UsersInsideGuard]
      },
      {
        path: 'roles',
        component: RolesComponent,
        canActivate: [RolesInsideGuard]
      }
    ]
  }
];

@NgModule({
  declarations: [...fromContainers.containers],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    RouterModule.forChild(ROUTES),
    ...fromModules.modules
  ]
})
export class UsersPageModule {}
