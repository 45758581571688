import { StateService } from 'src/app/services/state.service';
import { HeaderToolsHandlerService } from './../../../navigation/services/header-tools-handler.service';
import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { ArchiveApiService } from '../../services';

@Component({
  selector: 'utax-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit, AfterViewInit {
  @ViewChild('controlMenu') controlMenu: TemplateRef<any>;

  constructor(private headerToolsHandlerService: HeaderToolsHandlerService,
              public archiveApiService: ArchiveApiService,
              public stateService: StateService) { }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.headerToolsHandlerService.template.next(this.controlMenu);
  }

  setTaxiService(event) {
    localStorage.setItem('service', event);
    this.archiveApiService.getArchiveList({ service: event });
  }
}
